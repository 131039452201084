import { call } from "../../store"
import { printData, printMessage } from "../../providers/remoteHQ"
import { storedItem } from "../../store/storedItem"
import { messageLevels } from "../types"
import { fileUploadSequencer } from "../multiFileUploader"
import { fileUpload } from "./fileUpload"
import { attachmentImageURL } from "../URLRewrites"

export class attachment extends storedItem {
	_MultiFileUploader: fileUploadSequencer | null = null
	zoomOnSelect() {
		return false
	}
	willStoreEdit(): boolean {
		if (this._MultiFileUploader) {
			this._MultiFileUploader.uploadAll()
			return false
			// will be continued by callback
		}
		// no uploader - so continue
		return true
	}

	addFileUpload = (theUpload: fileUpload) => {
		theUpload.setForItem(this)
		this.fileUploader.addFileUpload(theUpload)
		this._dirty = true
	}

	addImageURL = (theURL: string) => {
		const theImages: string[] = this.getValue("image_links") || []
		if (!theImages.includes(theURL)) {
			theImages.push(theURL)
			this.setValue(theImages, "image_links")
		}
	}

	get imageURL() {
		const theImage: string = this.getValue("filepath")
		return attachmentImageURL(theImage)
	}

	get imageThumb() {
		const theImage: string = this.getValue("filepath")
		return attachmentImageURL(theImage)
	}

	get fileUploader() {
		if (!this._MultiFileUploader) {
			this._MultiFileUploader = new fileUploadSequencer()
			this._MultiFileUploader.addCompletionCallback(this.storeEditPrepared)
		}
		return this._MultiFileUploader
	}
	storeEditPrepared = () => {
		if (this._MultiFileUploader) {
			const theURLs = this._MultiFileUploader?.getUploadedFileURLs()
			printData(theURLs, messageLevels.debug, "theURLs in blockFeature storeEdit Prepared")
			if (theURLs) {
				theURLs.forEach((item) => {
					this.addImageURL(item)
				})
			}
		}
		super.storeEditPrepared()
	}

	getParent() {
		return this.getRelatedItems("forest-blocks", this.getValue("consent_number"), "consent_number", "==", true)[0]
	}
	getRegionISO = () => {
		if (this._type && this._type?.objectRegionProperty) {
			return (this as any)[this._type.objectRegionProperty]
		}
		return this.getValue("iso") || this.getParent()?.getRegionISO() || "NZL"
	}
	get forestblock() {
		if (this.getValue("forest_block_id")) {
			return this.getRelatedItem("forest-blocks", this.getValue("forest_block_id"), "uuid")
		}
		return this.getRelatedItem("forest-blocks", this.getValue("consent_number"), "consent_number")
	}

	get forest_block_uuid() {
		let theBlockID = this.forestblock?.getValue("forest_block_id")
		if (theBlockID && theBlockID !== undefined) {
			return this.forestblock?.getValue("forest_block_id")
		}
		theBlockID = this.forestblock?.getValue("uuid")
		return theBlockID
	}

	levelName() {
		return this.getTypeName()
	}

	displayName(): string {
		const localID = this.getValue("local_id")
		if (localID && localID !== undefined) {
			return this.friendlyDisplayName + " " + localID
		} else {
			return this.friendlyDisplayName
		}
	}

	// displayName() {

	// let type = 'Unknown';
	// 	if (this.getValue("type") !== null && this.getValue("type") !== undefined && this.getValue("type") !== "" && this.getValue("type") !== "null"  && this.getTypeName() === "water-controls") {
	//   type = this.getValue("type");

	// 	}

	// 	 return  this.getTypeName() === "water-controls" ? 'Water Control' + ( ' (' + type +')' ) : this.friendlyDisplayName;
	// };

	highlightOnMap = (): void => {}
	clearHighlightOnMap = (): void => {}
	selectOnMap = (skipSetState?: boolean): void => {
		printMessage("feature block select on map", messageLevels.debug)
		call("setLevelByItem", this, skipSetState)
	}
	static styleByPriority = () => {}
	static styleByCompliance = () => {}
}
