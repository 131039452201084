import React, { Component } from "react"
import { Button, Menu, Sidebar } from "semantic-ui-react"

import { getGlobalState, registerOnChange, removeOnChange, requestLoad } from "../../../store"
import { storedItem } from "../../../store/storedItem"
import NotifyFormComponent from "./form"
import "./style.css"

interface CallbackFn {
	(): any
}

interface INotifySidebarComponentProps {
	visible: boolean
	hideSidebar?: CallbackFn
}
interface INotifySidebarComponentState {
	isFullWidth: boolean
	notification: storedItem | null
}

class NotifySidebarComponent extends Component<INotifySidebarComponentProps, INotifySidebarComponentState> {
	state = {
		isFullWidth: false,
		notification: getGlobalState("NES-CF-Edit_Item") ?? null,
	}

	componentDidMount() {
		registerOnChange("forest-notification", this.dataChanged)
		requestLoad("forest-notification")
	}
	componentWillUnmount() {
		removeOnChange("forest-notification", this.dataChanged)
	}
	dataChanged = () => {}

	buttonStyle() {
		return { position: "absolute", right: 0, top: 10, borderRadius: 0, padding: 8 }
	}

	renderNotifyComponent = () => {
		return <NotifyFormComponent hideSidebar={this.props.hideSidebar} />
	}
	render() {
		return (
			<Sidebar
				id="notice-layer"
				as={Menu}
				vertical
				animation="overlay"
				width="very wide"
				visible={this.props.visible}
				className="hide-overflow-x"
				style={this.state.isFullWidth ? { width: "calc(100vw - 58px", minWidth: 473 } : {}}
			>
				{this.renderNotifyComponent()}
				<Button floated="right" style={this.buttonStyle()} onClick={() => this.setState({ isFullWidth: !this.state.isFullWidth })}>
					{this.state.isFullWidth ? "<< View Map" : "Expand >>"}
				</Button>
			</Sidebar>
		)
	}
}

export default NotifySidebarComponent
