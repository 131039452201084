import { storedItem } from "../storedItem"
import React, { Component } from "react"
import { FormPage } from "./FormPage"
import { Container, Item, Header } from "semantic-ui-react"

import { FormPageItemProps, MultipageFormProps } from "./index"
import ItemContext from "./ItemContext"

export class MultipageForm extends Component<MultipageFormProps> {
	state = {
		step: 0,
	}
	nextPage = () => {
		this.goToPage(this.state.step + 1)
		// if (this.state.step < this.props.pages.length) {
		// 	this.setState({ step: this.state.step + 1 });
		// }
	}
	CancelFlow = () => {
		this.props.onCancel()
	}
	previousPage = () => {
		this.goToPage(this.state.step - 1)
		// if (this.state.step > 0) {
		// 	this.setState({ step: this.state.step - 1 });
		// }
	}

	goToPage = (page: number) => {
		if (page >= 0 && page < this.props.pages.length) {
			this.setState({ step: page })
		}
	}

	renderPage = (page: FormPageItemProps, index: number) => {
		if (this.props.item && this.props.item !== null) {
			return (
				<FormPage
					item={this.props.item}
					title={page.title}
					fields={page.fields}
					container={this.props.container}
					pages={this.props.pages.length}
					page={page}
					index={index}
					active={this.state.step === index}
					onBack={this.previousPage}
					onNext={this.nextPage}
					onCancel={this.CancelFlow}
					onSubmit={this.props.onSubmit}
					setDirty={this.props.setDirty}
					displayMode={this.props.displayMode}
				>
					{" "}
				</FormPage>
			)
		}
	}

	renderPages = () => {
		if (this.props.item) {
			return this.props.pages.map((page: any, idx: any) => {
				return this.renderPage(page, idx)
			})
		}
	}

	render() {
		if (this.props.item) {
			return (
				<>
					<Container style={{ marginBottom: 10 }}>
						<Item style={{ border: 0 }}>
							<Header as="h3" style={{ margin: "0 100px 0 0" }}>
								{this.props.title}
							</Header>
						</Item>
						{this.props.item && this.renderPages()}
					</Container>
				</>
			)
		} else {
			return null
		}
	}
}
