import React from "react"
import { Button } from "semantic-ui-react"

import { getUIConfig, registerforStateChanges } from "../../store/index"

import "./styles.css"

interface NotificationButtonProps {
	isAllRead?: boolean
}

export default class NotificationButton extends React.Component<NotificationButtonProps, {}> {
	state = {
		loggedIn: false,
	}
	componentDidMount() {
		registerforStateChanges(this.userChanged)
	}

	userChanged = (type: string, key: string, data: any) => {
		if (key === "loginState") {
			this.setState({ loggedIn: data.getValue("isLoggedIn") })
		}
	}

	render() {
		if (!this.state.loggedIn) return null
		return (
			<div id="notification-button">
				<Button circular icon="bell" />
				{this.props.isAllRead ? null : (
					<div className="unread" style={getUIConfig ? { backgroundColor: getUIConfig?.("clientUI")?.brandColor } : {}} />
				)}
			</div>
		)
	}
}
