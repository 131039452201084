/**
 * AudioPlayer Props
 * @extends {Omit<WaveSurferOptions, 'container'>}
 * @param {string} id - The id of the audio player
 * @param {boolean} [playButton] - Whether to show the play button or not
 * @param {Object} playButtonProps - Props to pass to the play button
 * @param {Function} [onPlay] - Callback function that is called when the play button is clicked
 */

/**
 * @component
 * @example
 * <AudioPlayer
        cursorColor="#fff"
        height={50}
        barGap={2}
        url="/assets/perfect-night.mp3"
        id="perfect-night"
        waveColor="#fff"
        progressColor="rgba(255, 255, 255, 0.5)"
        playButton
    />
 */

import React, { useEffect, useState } from "react"
import { Icon } from "semantic-ui-react"
import WaveSurfer, { WaveSurferOptions } from "wavesurfer.js"

export interface AudioPlayerProps extends Omit<WaveSurferOptions, "container"> {
	id: string
	playButton?: boolean
	playButtonProps?: Icon["props"]
	onPlay?: (isPlaying: boolean) => void
}

export const AudioPlayer = (props: AudioPlayerProps) => {
	const { id, playButton, playButtonProps, onPlay: _onPlay, ...other } = props
	const [isPlaying, setIsPlaying] = useState(false)
	const [waveSurfer, setWaveSurfer] = useState<WaveSurfer>()

	useEffect(() => {
		const waveSurfer = WaveSurfer.create({
			container: `#wave-${id}`,
			...other,
		})
		waveSurfer.on("click", () => {
			!!playButton || waveSurfer.play()
		})
		setWaveSurfer(waveSurfer)
	}, [])

	const onPlay = () => {
		if (isPlaying) {
			waveSurfer?.pause()
		} else {
			waveSurfer?.play()
		}
		_onPlay?.(isPlaying)
		setIsPlaying(!isPlaying)
	}

	return (
		<div style={{ position: "relative" }}>
			<div id={`wave-${id}`} />
			{playButton ? (
				<Icon
					name={isPlaying ? "pause" : "play"}
					circular
					color="black"
					{...playButtonProps}
					style={{
						backgroundColor: "#fff",
						position: "absolute",
						top: "50%",
						left: "50%",
						zIndex: 2,
						marginTop: "-1em",
						marginLeft: "-1em",
						cursor: "pointer",
						...(playButtonProps?.style || {}),
					}}
					onClick={onPlay}
				/>
			) : null}
		</div>
	)
}
