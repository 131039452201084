import React, { Component, ReactNode } from "react"
import { Button, Form, Item, Segment } from "semantic-ui-react"

import { IQueryParams, messageLevels } from "../../types"
import { storedItem } from "../../storedItem"
import { FormBlock } from "../FormBlock"
import { FormItemComponent } from "./Items/FormItemComponent"
import { FormFieldProperties, validateField } from ".."
import { printMessage } from "../../../providers/remoteHQ"
import { FormPage } from "../FormPage"
import { ReactFormBlock } from "./FormBlock"

export class ReactFormPage extends FormPage {
	state = {
		n: 0,
	}

	updateFormData = (field: FormFieldProperties, value: any) => {
		let valid = true
		if (field.validator) {
			const theError = validateField(field.validator, value, field.name, field.errorMessage || "")
			if (theError !== field.error) {
				this.setState({ n: this.state.n + 1 })
			}
			if (theError) {
				valid = false
				field.error = theError
			}
		}
		if (valid) {
			this.setValue(value, field.name)
			this.setState({ n: this.state.n + 1 })
		}
	}

	renderField(field: FormFieldProperties, index: number) {
		switch (field.type.toLowerCase()) {
			case "block":
				return (
					<ReactFormBlock
						item={this.props.item}
						field={field}
						key={index}
						index={index}
						displayMode={this.props.displayMode}
						setDirty={this.setDirty}
						setValue={this.setValue}
						container={this.props.container}
						page={this.props.page}
					></ReactFormBlock>
				)

			default:
				return (
					<FormItemComponent
						item={this.props.item}
						field={field}
						key={index}
						updateFormData={this.updateFormData}
						index={field.name}
						displayMode={this.props.displayMode}
						setDirty={this.setDirty}
						setValue={this.setValue}
						container={this.props.container}
						page={this.props.page}
						onCancel={this.props.onCancel}
					></FormItemComponent>
				)
		}
	}
	renderFields = () => {
		return this.props.fields.map((field, idx) => {
			return this.renderField(field, idx)
		})
	}

	renderLeftButton = () => {
		return (
			<Button disabled={this.props.index < 1} primary floated="left" onClick={() => this.props.onBack()}>
				Prev
			</Button>
		)
	}

	renderRightButton = () => {
		if (this.props.index === 0) {
			return (
				<Button primary floated="right" onClick={() => this.props.onNext()}>
					Next
				</Button>
			)
		} else if (this.props.index + 1 < this.props.pages) {
			return (
				<Button primary floated="right" onClick={() => this.props.onNext()}>
					Next
				</Button>
			)
		} else if (this.props.displayMode !== true) {
			return null
		}
	}

	setValue = (value: any, forKey: string) => {
		const theItem: storedItem | null | undefined = this.props.item
		if (theItem && theItem !== undefined) {
			theItem.setValue(value, forKey)
			theItem.validationForKey(forKey)

			this.setDirty()
		}
	}

	setDirty = () => {
		this.setState({ n: this.state.n + 1 })
		if (this.props.setDirty) {
			printMessage("setDirty in FormPageComponent", messageLevels.verbose)

			this.props.setDirty()
		}
	}

	handleChange = (e: any) => {
		this.setValue(e.target.value, "description")
	}

	render() {
		if (this.props.item && this.props.item !== null) {
			return (
				<Segment inverted basic>
					<Form style={{ display: this.props.active ? "block" : "none" }}>
						<div>{this.renderFields()}</div>
						<Item
							style={{
								display: "flex",
								justifyContent: "space-between",
								paddingLeft: 0,
								paddingRight: 0,
								position: "sticky",
								background: "#363D4D",
								bottom: 0,
							}}
						>
							{this.props.page.type === "submit" ? null : this.renderLeftButton()}
							{this.props.page.type === "submit" ? null : this.props.renderMiddleButtons?.()}
							{this.props.page.type === "submit" ? null : this.renderRightButton()}
						</Item>
					</Form>
				</Segment>
			)
		}
		return null
	}
}
