import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import Amplify, { Auth, Hub } from "aws-amplify"
import { RouterProvider, Router, Route, RootRoute, redirect, Outlet } from "@tanstack/react-router"
import App from "./App"
import Login from "./components/Login/LoginPage"
import LoadingPage from "./components/LoadingPage"
import ErrorPage from "./components/ErrorPage"
import {
	getConfigAsync,
	getGlobalState,
	loadTables,
	registerSingleUserItem,
	requestLoad,
	userChanged,
	setGlobalState,
	isNgatiApa,
} from "./store"
import { setMessageLevel } from "./providers/remoteHQ"
import { messageLevels } from "./store/types"
import { getServerUrlFromDomain } from "./helpers/commonFunc"
import * as Cognito from "./providers/cognito"

import "./index.css"
import Favicon from "./utilities/Favicon"

setMessageLevel(messageLevels.verbose)

const Root = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [error, setError] = useState(false)

	async function handleLogin(session) {
		userChanged(session.idToken.payload)
		setGlobalState("loginState", { isLoggedIn: true, accessToken: session.getAccessToken().getJwtToken() })
	}

	useEffect(() => {
		const initializeApp = async () => {
			try {
				setGlobalState("loginState", { isLoggedIn: false, accessToken: null })

				const serverUrl = process.env.REACT_APP_PROXY ?? getServerUrlFromDomain(window.location.host)

				const config = await getConfigAsync(serverUrl)
				Amplify.configure(config.Auth)
				registerSingleUserItem("user-profile", false, false, null, config.invokeUrl + config.UserInfoURL)
				await Cognito.onLoginStateChange()
				await loadTables(config.invokeUrl, config.storeIOURL)
				requestLoad(
					"forms",
					() => null,
					() => setGlobalState("isFormsLoaded", true),
				)

				Hub.listen("auth", async ({ payload: { event, data } }) => {
					switch (event) {
						case "signIn":
						case "cognitoHostedUI":
							try {
								const userToken = await getToken()
								handleLogin(userToken)
							} catch (error) {
								// Handle getToken() error
								console.error("Error during login:", error)
							}
							break
						case "signOut":
							// Handle sign out event
							setGlobalState("loginState", { isLoggedIn: false, accessToken: null })
							break
						case "signIn_failure":
						case "cognitoHostedUI_failure":
							// Handle sign-in failure event
							break
						default:
							break
					}
				})
				setIsLoading(false)
			} catch (error) {
				console.error("Error initializing the app:", error)
				setIsLoading(false)
				setError(true)
			}
		}

		initializeApp()
	}, [])

	if (isLoading) {
		return <LoadingPage />
	} else if (error) {
		return <ErrorPage />
	}

	const rootRoute = new RootRoute({
		component: () => (
			<>
				<Favicon />
				<Outlet />
			</>
		),
	})

	const appRoute = new Route({
		getParentRoute: () => rootRoute,
		path: "/",
		beforeLoad: async () => {
			if (isNgatiApa() && !getGlobalState("loginState")?.getValue("isLoggedIn")) {
				throw redirect({
					to: "/login",
				})
			}
		},
		component: App,
	})

	const loginRoute = new Route({
		getParentRoute: () => rootRoute,
		path: "/login",
		beforeLoad: async () => {
			if (!isNgatiApa() || getGlobalState("loginState")?.getValue("isLoggedIn")) {
				throw redirect({
					to: "/",
				})
			}
		},
		component: Login,
	})

	const routeTree = rootRoute.addChildren([appRoute, loginRoute])
	const router = new Router({ routeTree })

	return (
		<React.StrictMode>
			<RouterProvider router={router} />
		</React.StrictMode>
	)
}

ReactDOM.render(<Root />, document.getElementById("root"))
