import { mediaItem } from "./mediaItem"

export class mediaCollectionItem extends mediaItem {
	get pathName() {
		return this.getValue("filename")
	}
	get URL() {
		return null
	}
	get isUploaded() {
		return true
	}
	fileType = (): string | null => {
		const theName = this.getValue("filename")
		const extension = theName.split(".").pop()
		if (extension.length > 1 && extension.length < 5) {
			return extension.toLowerCase()
			// this.setValue(extension.toLowerCase(),"type");
		}
		return null
	}
}
