import React from "react"
import { Segment, Divider } from "semantic-ui-react"

import { FieldLabel } from "./FieldLabel"
import { FormItemComponentProps } from "../.."
import { Text } from "@react-pdf/renderer"

export class DisplayTextComponent extends React.Component<FormItemComponentProps> {
	render() {
		const { field, item } = this.props
		return (
			<>
				<FieldLabel item={item} field={field} container={this.props.container} />
				<Text>{item?.getValue(field.name)}</Text>
			</>
		)
	}
}
