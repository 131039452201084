import { storedItem } from "../storedItem"
import { storeType } from "../storeType"
import { forestBlock } from "./forestBlock"
import { blockFeature } from "./blockFeature"
import { region } from "./region"
import { layerGroup } from "./layerGroup"
import { mapLayer } from "./mapLayer"
import { mapSource } from "./mapSource"
import { mapDataFeedSource } from "./mapDataFeedSource"
import { mapLayerSet } from "./mapLayerSet"
import { forestNotification } from "./forestNotification"
import { panoramaFeature } from "./panoramaFeature"
import { fileUpload } from "./fileUpload"
import { waterControl } from "./waterControl"
import { optionList } from "./optionList"
import { ReportItem } from "./report"
import { attachment } from "./attachment"
import { user } from "./user"
import { authority } from "./authority"
import { skidSite } from "./skidSite"
import { mediaCollectionItem } from "./MediaCollectionItem"
import { task } from "./Task"
import { forestOwner } from "./forest"
import { appMode } from "./Mode"
import { catchment } from "./catchment"
import { mapMultiLayer } from "./mapMultiLayer"
import { mapHighlightLayer } from "./mapHighlightLayer"
import { mapControl } from "./mapControl"
import { mapSelectedLayer } from "./mapSelectedLayer"
import { mapDynamicTileSource } from "./mapDynamicTileSource"
import { placeName } from "./placeName"
import { nzaaArchaeologicalSite } from "./nzaaArchaeologicalSite"

export function classInit(objectType: storeType, data: any, doNotStore?: boolean): storedItem {
	// printMessage(objectType.name,messageLevels.error)
	switch (objectType.name) {
		case "authorities":
			return new authority(objectType, data, doNotStore)
		case "forest-blocks":
			return new forestBlock(objectType, data, doNotStore)
		case "forest-notification":
			return new forestNotification(objectType, data, doNotStore)
		case "forest-monitoring-reports":
			return new ReportItem(objectType, data, doNotStore)
		case "boundaries":
			return new region(objectType, data, doNotStore)
		case "catchments-4":
		case "catchments-5":
		case "catchments-6":
			return new catchment(objectType, data, doNotStore)
		case "layer-groups":
			return new layerGroup(objectType, data, doNotStore)
		case "map-layers":
			if (data) {
				switch (data.dataType) {
					case "multi":
						return new mapMultiLayer(objectType, data, doNotStore)
					case "set":
						return new mapLayerSet(objectType, data, doNotStore)
					case "highlight":
						return new mapHighlightLayer(objectType, data, doNotStore)
					case "selected":
						return new mapSelectedLayer(objectType, data, doNotStore)
					default:
						return new mapLayer(objectType, data, doNotStore)
				}
			} else {
				return new mapLayer(objectType, data, doNotStore)
			}
		case "map-controls":
			return new mapControl(objectType, data, doNotStore)
		case "forest-monitoring-report-attachments":
			return new attachment(objectType, data, doNotStore)
		case "map-sources":
			if (data) {
				switch (data.type) {
					case "dataFeed":
						return new mapDataFeedSource(objectType, data, doNotStore)
					case "dynamicTiles":
						return new mapDynamicTileSource(objectType, data, doNotStore)

					default:
						return new mapSource(objectType, data, doNotStore)
				}
			} else {
				return new mapSource(objectType, data, doNotStore)
			}
		case "consented-skid-sites":
		case "as-built-skid-sites":
			return new skidSite(objectType, data, doNotStore)
		case "areas-of-excellence":
		case "areas-of-failure":
		case "areas-of-concern":
		case "consented-tracks":
		case "as-built-tracks":
		case "consented-roads":
		case "as-built-roads":
		case "streams":
		case "consented-stream-crossings":
		case "as-built-stream-crossings":
		case "quarries":
			return new blockFeature(objectType, data, doNotStore)
		case "tasks":
			return new task(objectType, data, doNotStore)
		case "water-controls":
			return new waterControl(objectType, data, doNotStore)
		case "general-panoramas":
		case "aerial-panoramas":
		case "ground-panoramas":
			return new panoramaFeature(objectType, data, doNotStore)
		case "file-upload":
			return new fileUpload(objectType, data, doNotStore)
		case "officers-list":
		case "management-company-list":
		case "forest-manager-list":
		case "contractors-list":
			return new optionList(objectType, data, doNotStore)
		case "user-profile":
			return new user(objectType, data, doNotStore)
		case "media-collection":
			return new mediaCollectionItem(objectType, data, doNotStore)
		case "forest-ownership":
			return new forestOwner(objectType, data, doNotStore)
		case "modes":
			return new appMode(objectType, data, doNotStore)
		case "place-names":
			return new placeName(objectType, data, doNotStore)
		case "nzaa-archaeological-sites":
			return new nzaaArchaeologicalSite(objectType, data, doNotStore)
	}
	return new storedItem(objectType, data, doNotStore)
}
