import React, { Component } from "react"
import { Label, Segment } from "semantic-ui-react"

import { printData, printMessage } from "../../../providers/remoteHQ"
import { isStoredItem } from "../.."
import { parseQueries, storedItem } from "../../storedItem"
import { IParsedQuery, messageLevels } from "../../types"
import { FormItemComponent } from "./Items/FormItemComponent"
import { FormFieldProperties, validateField } from "../index"
import { FormBlock } from "../FormBlock"

// export interface FormBlockProps {
// 	item: storedItem;
// 	index: number;
// 	field: FormFieldProperties;
// 	updateFormData?: any;
// 	errors?: string[];
// 	displayMode?: boolean;
// 	setValue?: any;
// 	setDirty?: any;
// 	changed?: boolean;
// }
export interface FormBlockState {
	subItem: storedItem
	visible: boolean
	queryParsed: IParsedQuery[]
	n: number
}

export class ReactFormBlock extends FormBlock {
	state = {
		subItem: this.props.item,
		visible: false,
		queryParsed: [],
		n: 0,
	}
	componentDidMount() {
		if (this.props.field.name && this.props.field.name.length > 0) {
			const theValue = this.props.item?.getValue(this.props.field.name)
			if (theValue && isStoredItem(theValue)) {
				this.setState({ subItem: theValue })
			}
		}
		this.prepareConditional()
		this.updateVisible()
	}
	prepareConditional = () => {
		const theConditional = this.props.field.conditional
		if (theConditional && theConditional.queries && theConditional.queries.length > 0) {
			const theParsedQueries = parseQueries(theConditional.queries)
			this.setState({ queryParsed: theParsedQueries })
			printData(theParsedQueries, messageLevels.debug, "Parsed queries in prepareConditional")
		}
	}
	checkShouldShow = (): boolean => {
		if (this.state.queryParsed) {
			printData(this.props.field.conditional?.searchOrMode ?? false, messageLevels.debug, "SearchORMode in checkShouldShow")
			const isIn = this.props.item?.matchItem(this.state.queryParsed, this.props.field.conditional?.searchOrMode ?? false)
			printData(isIn, messageLevels.debug, "isIn in checkShouldShow")
			if (isIn !== null) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}
	updateVisible = () => {
		this.setState({ visible: this.checkShouldShow() })
	}
	updateFormData = (field: FormFieldProperties, value: any) => {
		let valid = true
		if (field.validator) {
			const theError = validateField(field.validator, value, field.name, field.errorMessage || "")
			if (theError !== field.error) {
				this.setState({ n: this.state.n + 1 })
			}
			if (theError) {
				valid = false
				field.error = theError
			}
		}
		if (valid) {
			if (this.state.subItem) {
				this.state.subItem.setValue(value, field.name)
			}

			this.setState({ n: this.state.n + 1 })
		}
	}
	setValue = (value: any, forKey: string) => {
		const theItem: storedItem | null | undefined = this.props.item
		if (theItem && theItem !== undefined) {
			printMessage("setting value: " + value, messageLevels.debug)
			theItem.setValue(value, forKey)

			if (this.props.setDirty) {
				this.props.setDirty()
			}
		}
	}
	setDirty = () => {
		if (this.props.setDirty) {
			this.props.setDirty()
		}
	}
	renderField(field: FormFieldProperties, index: number) {
		switch (field.type.toLowerCase()) {
			case "block":
				return (
					<ReactFormBlock
						item={this.state.subItem}
						field={field}
						key={index}
						index={index}
						setDirty={this.setDirty}
						container={this.props.container}
						page={this.props.page}
					></ReactFormBlock>
				)

			default:
				return (
					<FormItemComponent
						item={this.state.subItem}
						field={field}
						key={index}
						updateFormData={this.updateFormData}
						index={index}
						setDirty={this.setDirty}
						container={this.props.container}
						page={this.props.page}
					></FormItemComponent>
				)
		}
	}
	renderFields = () => {
		return this.props.field.subfields?.map((subfield, idx) => {
			return this.renderField(subfield, idx)
		})
	}

	renderHeading(field: FormFieldProperties) {
		const error = this.errorForKey(field.name)
		let theValue = this.props.item?.getValue(field.name)
		if (field.customGetHeader && this.props.item) {
			theValue = field.customGetHeader(this.props.item, field)
		}
		return (
			<h3>
				{field.label}
				<span style={{ color: "red" }}>{field.mandatory ? " *" : ""}</span>
				{theValue && theValue}
				{error ? (
					<Label basic color="red" pointing="right" prompt>
						{error}
					</Label>
				) : null}
			</h3>
		)
	}

	errorForKey = (forKey: string): any => {
		const theItem: storedItem | null | undefined = this.props.item
		if (theItem && theItem !== undefined) {
			return theItem.validationErrorForKey(forKey)
		}
		return null
	}

	render() {
		const isShow = this.checkShouldShow()
		const error = this.errorForKey(this.props.field.name)
		if (this.state.visible && isShow) {
			return (
				<div className={error ? "error" : ""}>
					{this.props.field.label ? this.renderHeading(this.props.field) : null}
					<Segment inverted basic className={"form-block"}>
						{this.renderFields()}
					</Segment>
				</div>
			)
		} else {
			return null
		}
	}
}
