import React from "react"
import moment from "moment"
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer"

import variables from "../../../variables"
import { printData, printMessage } from "../../../providers/remoteHQ"
import { messageLevels } from "../../../store/types"
import { skidSite } from "../../../store/classes/skidSite"
import { blockFeature } from "../../../store/classes/blockFeature"
import { task } from "../../../store/classes/Task"
import { getURLAndRotation, getURLAndRotationCached, imageFinalURL } from "../../../store/URLRewrites"
import { ImageUpright } from "../../../store/Forms/PDF/Items/ImageUpright"

// eslint-disable-next-line
const cover = require("./report-cover-min.jpeg")

const { skidComplianceOptions: compliance, skidSiteQuestions } = variables

// Font.register({ family: 'Helvetica', src: source });

const forestryMapKey = [
	{
		src: "/images/map/maroon-icon@0.75x.png",
		key: "Tasks",
	},
	{
		src: "/images/map/green-icon@0.75x.png",
		key: "Area of Excellence",
	},
	{
		src: "/images/map/orange-icon@0.75x.png",
		key: "Area of Concern",
	},
	{
		src: "/images/map/red-icon@0.75x.png",
		key: "Area of Failure",
	},
	{
		src: "/images/map/yellow-icon@0.75x.png",
		key: "General Panoramic Image",
	},
	{
		src: "/images/map/dark-blue-icon@0.75x.png",
		key: "Aerial Panoramic Image",
	},
	{
		src: "/images/map/light-green-icon@0.75x.png",
		key: "Ground Panoramic Image",
	},
	{
		src: "/images/map/light-blue-icon@0.75x.png",
		key: "Stream Crossings",
	},
	{
		src: "/images/map/dark-purple-icon@0.75x.png",
		key: "Quarry",
	},
	{
		src: "/images/map/planned-and-consented-skid-sites-sample.png",
		key: "Planned and Consented Skid Sites",
	},
	{
		src: "/images/map/as-built-skid-sites-sample.png",
		key: "As Built Skid Sites",
	},
	{
		src: "/images/map/planned-and-consented-roads-sample.png",
		key: "Planned and Consented Roads",
	},
	{
		src: "/images/map/planned-and-consented-tracks-sample.png",
		key: "Planned and Consented Tracks",
	},
	{
		src: "/images/map/as-built-roads-sample.png",
		key: "As Built Roads",
	},
	{
		src: "/images/map/as-built-tracks-sample.png",
		key: "As Built Tracks",
	},
	{
		src: "/images/map/water-controls-sample.png",
		key: "Water Controls",
	},
]

const itemsPerPage = {
	tasks: 3,
	aoes: 4,
}

export const chunk = (arr: any, chunkSize = 1, cache: any[] = []) => {
	const tmp: any[] = [...arr]
	if (chunkSize <= 0) return cache
	while (tmp.length) cache.push(tmp.splice(0, chunkSize))
	return cache
}

type ImageDescription = {
	key: any
	src: string
	description: string
}

interface ReportPDFGenProps {
	data: any
}
const getSrc = (data: string) => {
	return imageFinalURL(data)
}

const emptyImageDescArray: ImageDescription[] = []
class ReportPdfGenClass extends React.Component<ReportPDFGenProps> {
	state = {
		aoeImages: null,
	}

	pageNumber = 1

	componentDidMount() {
		this.getAoesRotated()
	}

	styles = StyleSheet.create({
		page: {
			fontSize: 9,
		},
		row: {
			flexDirection: "row",
		},
		flexCenter: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
	})

	getAoesRotated = () => {
		const { data } = this.props
		const aoes: blockFeature[] = data.areasOfExcellence

		const aoeImages: ImageDescription[] = (aoes || [])
			.map((item: blockFeature, i: number) => {
				return item.imageURLs.map((imageLink) => {
					return {
						key: item.primaryKey().toString() + "-" + i,
						src: imageLink.toString(),
						description: item.getValue("description"),
					}
				})
			})
			.flat()
		this.setState({ aoeImages })
	}
	pageFooter = () => {
		const thePage = this.pageNumber
		this.pageNumber += 1
		if (thePage > 1) {
			return (
				<View
					style={{
						width: "100%",
						height: "14",
						fontFamily: "Helvetica",
						fontSize: 10,
						alignItems: "flex-end",
						paddingHorizontal: ".3cm",
						position: "absolute",
						top: "97vh",
					}}
				>
					<Text style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}>
						<span style={{ fontStyle: "italic" }}>Page {thePage}</span>
					</Text>
				</View>
			)
		}
	}
	renderSkid = (theSkid: skidSite) => {
		const attachments = theSkid.attachments
		const imageLinks = theSkid.imageStringorObjects || []

		const statusCode = theSkid.getValue("status")
		const statusDefinition = theSkid.statusDefinition || "Not Assessed"
		const statusColor = theSkid.statusColor
		const statusTextColor = statusCode === "TNC" ? "black" : "white"
		const totalImages = imageLinks.length + attachments.length
		const imageSize = totalImages === 1 ? "100%" : "49%"
		const imageHeight = totalImages === 1 ? "100%" : "96%"
		return (
			<Page size="A4" style={this.styles.page}>
				<View
					style={[
						this.styles.row,
						{
							width: "100%",
							height: "7vh",
							backgroundColor: statusColor.code || "blue",
							color: statusTextColor,
							fontSize: 15,
							alignItems: "center",
							paddingHorizontal: "1cm",
						},
					]}
				>
					<Text>
						{theSkid.displayName()}: {statusDefinition}
					</Text>
				</View>
				<View style={[this.styles.row, { width: "100%", height: "50vh", flexWrap: "wrap" }]}>
					{imageLinks.map((image, i) => {
						if (i < 4) {
							return (
								<View
									style={[
										this.styles.flexCenter,
										{ width: imageSize, height: imageSize, overflow: "hidden" },
										totalImages !== 1 && i % 2 === 0 ? { marginRight: "2%" } : {},
										i > 1 ? { marginTop: "2%" } : {},
									]}
									key={image.toString()}
								>
									<ImageUpright
										src={image}
										rotateStyle={{ margin: "1cm" }}
										style={{
											height: imageHeight,
											width: "auto",
											objectFit: "contain",
										}}
									/>
								</View>
							)
						}
					})}
					{imageLinks.length < 4
						? attachments.map((item, i) => {
								if (i + imageLinks.length < 4) {
									printData(item.imageURL?.toString(), messageLevels.verbose)
									return (
										<View
											style={[
												this.styles.flexCenter,
												{
													width: imageSize,
													height: imageSize,
												},
												totalImages !== 1 && i % 2 === 0 ? { marginRight: "2%" } : {},
												i > 1 ? { marginTop: "2%" } : {},
											]}
										>
											<ImageUpright
												src={item.imageURL?.toString()}
												style={{
													height: "100%",
													position: "absolute",
													objectFit: "contain",
												}}
											/>
										</View>
									)
								}
						  })
						: null}
				</View>
				<View
					style={[
						this.styles.row,
						{
							width: "100%",
							height: "7vh",
							backgroundColor: "#000",
							color: "#fff",
							fontSize: 15,
							alignItems: "center",
							paddingHorizontal: "1cm",
						},
					]}
				>
					<Text style={{ width: "50%", textAlign: "center" }}>Skid Description</Text>
					<Text style={{ width: "50%", textAlign: "center" }}>Skid Site Assessment</Text>
				</View>
				<View style={[this.styles.row, { width: "100%", height: "36vh" }]}>
					<View
						style={[
							{
								backgroundColor: "#d9d9d9",
								paddingVertical: "15mm",
								paddingHorizontal: "5mm",
								width: "50%",
								height: "100%",
							},
						]}
					>
						<Text>{theSkid.getValue("description")}</Text>
					</View>
					<View
						style={[
							{
								paddingVertical: "3mm",
								paddingHorizontal: "5mm",
								width: "50%",
								height: "100%",
							},
						]}
					>
						{Object.entries(skidSiteQuestions).map(([key, question]) => {
							const complianceDefinition = theSkid.definitionForStatusField(key)
							const complianceColor = theSkid.colorForStatusField(key)
							// const code = Object.values(compliance).find(obj => obj.definition === item.getValue(key))?.color?.code || '#000'
							return (
								<>
									<View
										style={{
											marginBottom: question.length > 65 ? "8mm" : "5mm",
										}}
									>
										<Text>{question}</Text>
									</View>
									<View style={{ marginBottom: "5mm" }}>
										<Text style={{ color: complianceColor.code }}>{complianceDefinition}</Text>
									</View>
								</>
							)
						})}
					</View>
				</View>
				{this.pageFooter()}
			</Page>
		)
	}
	renderSkids = (theSkids: skidSite[]) => {
		{
			printMessage("start skids", messageLevels.verbose)
		}
		return theSkids.map((skid) => {
			return this.renderSkid(skid)
		})
	}
	renderAOEs = () => {
		const aoeImages = this.state.aoeImages
		if (!aoeImages) return null
		printMessage("start aoes", messageLevels.verbose)
		return (
			<>
				{chunk(aoeImages || [], itemsPerPage.aoes).map((pageItems: ImageDescription[], i) => {
					return (
						<Page size="A4" style={this.styles.page} key={"aoes" + i}>
							<View
								style={[
									this.styles.row,
									{
										width: "100%",
										height: "7vh",
										backgroundColor: "#000",
										color: "#fff",
										fontSize: 15,
										alignItems: "center",
										paddingHorizontal: "1cm",
										marginBottom: "0.5cm",
									},
								]}
							>
								<Text>Areas of Excellence {i > 0 && "(cont.)"}</Text>
							</View>
							<View
								style={[
									this.styles.row,
									{
										width: "100%",
										height: "85vh",
										flexWrap: "wrap",
										justifyContent: "space-between",
										fontSize: 13,
										paddingHorizontal: "1cm",
									},
								]}
							>
								{pageItems.map((item: ImageDescription) => {
									if (item.src && item.src !== undefined) {
										return (
											<View style={{ width: "48%", height: "48%" }} key={item.key}>
												<Text style={{ paddingVertical: "2mm" }}>{item.description}</Text>
												<View style={[this.styles.flexCenter, { width: "100%", height: "100%" }]}>
													<ImageUpright
														src={item.src}
														style={{
															height: "auto",
															width: "100%",
															objectFit: "contain",
														}}
													></ImageUpright>
												</View>
											</View>
										)
									}
								})}
								{[...Array(itemsPerPage.aoes - pageItems.length)].map((_, i) => {
									return <View style={{ width: "48%", height: "48%" }} key={"fill" + i} />
								})}
							</View>
							{this.pageFooter()}
						</Page>
					)
				})}
			</>
		)
	}
	renderTask = (item: task) => {
		const imageLinks = item.imageURLs
		const imageLink = imageLinks.length > 0 ? imageLinks[0].toString() : null
		const geojson = JSON.parse(item.getValue("geojson") || "{}").coordinates
		printData(imageLink, messageLevels.debug, "imageLink")

		return (
			<View
				style={[
					this.styles.row,
					{
						width: "100%",
						height: "22%",
						justifyContent: "space-evenly",
						marginBottom: "2mm",
					},
				]}
				key={item.primaryKey()}
			>
				<View style={{ width: "40%" }}>
					<View style={[this.styles.row, { fontSize: 13, justifyContent: "space-between", marginBottom: "2mm" }]}>
						<Text style={{ fontWeight: "bold" }}>{item.displayName()}</Text>
						<Text>Due: {item.getDateFormatted("due_date", "DD MMMM yyyy", "No date assigned")}</Text>
					</View>
					<Text>{item.getValue("description") || "No description available"}</Text>
					<Text>
						Location: {geojson[1]}, {geojson[0]}
					</Text>
				</View>
				<View style={[this.styles.flexCenter, { width: "50%", height: "100%" }]}>
					{imageLink && <ImageUpright src={imageLink} style={{ height: "100%", position: "absolute" }} />}
					{/* {imageLink && 
          <Image style={{height: '100%', position: 'absolute'}} src={imageLink} />} */}
				</View>
			</View>
		)
	}
	renderTasks = () => {
		const tasks = this.props.data.tasks
		printMessage("start tasks", messageLevels.verbose)
		return chunk(tasks || [], itemsPerPage.tasks).map((pageItems: task[], i) => {
			printData(pageItems, messageLevels.debug, "imageLink")
			return (
				<Page size="A4" style={this.styles.page} key={"tasks " + i}>
					<View
						style={[
							this.styles.row,
							{
								width: "100%",
								height: "7vh",
								backgroundColor: "#000",
								color: "#fff",
								fontSize: 15,
								alignItems: "center",
								paddingHorizontal: "1cm",
							},
						]}
					>
						<Text>Tasks required to achieve compliance {i > 0 && "(cont.)"}</Text>
					</View>
					<View
						style={[
							{
								flexDirection: "column",
								width: "100%",
								height: "90vh",
								flexWrap: "wrap",
								justifyContent: "space-evenly",
							},
						]}
					>
						{pageItems.map((item: task) => {
							return this.renderTask(item)
						})}
						{[...Array(itemsPerPage.tasks - pageItems.length)].map((_, i) => {
							return <View style={[this.styles.row, { width: "100%", height: "22%" }]} key={"space" + i} />
						})}
					</View>
					{this.pageFooter()}
				</Page>
			)
		})
	}
	renderMapPage(map: any, title: string, bkgndColour: string) {
		const pageOrientation = map.orientation ? "landscape" : "portrait"
		return (
			<Page size="A4" orientation={pageOrientation} style={this.styles.page}>
				<View
					style={[
						this.styles.row,
						{
							width: "100%",
							height: "7vh",
							backgroundColor: bkgndColour,
							color: "#fff",
							fontSize: 15,
							alignItems: "center",
							paddingHorizontal: "1cm",
						},
					]}
				>
					<Text>{title}</Text>
				</View>
				<View style={{ height: "93vh", width: "100%" }}>
					{map && <ImageUpright style={{ height: "100%", position: "absolute" }} src={getSrc(map.url)} />}
				</View>
				{this.pageFooter()}
			</Page>
		)
	}
	render() {
		const { data } = this.props

		const block = data.forest_block
		const details = data.details

		// const aoes: blockFeature[] = data.areasOfExcellence;
		const skidSites = data.skidSites
		// const tasks:task[] = data.tasks;
		const maps = data.maps || {}
		const report_config = data.report_config
		this.pageNumber = 1
		return (
			<Document title={details.title || "Forest report"}>
				<>
					<Page size="A4" style={this.styles.page}>
						<View style={[{ height: "30%", position: "relative", color: "white" }]}>
							<View style={[this.styles.flexCenter, { width: "100%", height: "100%", overflow: "hidden" }]}>
								<ImageUpright style={{ width: "100%", position: "absolute" }} src={cover} />
							</View>
							<View
								style={[
									this.styles.flexCenter,
									{
										position: "absolute",
										width: "40vw",
										height: "20%",
										backgroundColor: "white",
									},
								]}
							>
								<ImageUpright
									style={{ transform: "scale(0.6)" }}
									src={{
										uri: process.env.PUBLIC_URL + "/media/remotehq-logo-colour.png",
										method: "GET",
										headers: { "Cache-Control": "no-cache" },
										body: "",
									}}
								/>
							</View>
							<View style={{ position: "absolute", bottom: "1cm", left: "1cm" }}>
								<Text style={{ fontSize: 13, marginBottom: "0.3cm" }}>{moment().format("DD MMMM YYYY")}</Text>
								<Text
									style={{
										fontWeight: "extrabold",
										fontSize: 30,
										marginBottom: "0.3cm",
									}}
								>
									{block?.forest_name}
								</Text>
								<Text style={{ fontSize: 15 }}>{"Forestry Monitoring Report"}</Text>
							</View>
						</View>
						<View style={{ height: "70vh", flexDirection: "row" }}>
							<View style={[this.styles.flexCenter, { width: "56vw", height: "100%", overflow: "hidden" }]}>
								{maps.context && maps.context.url && (
									<ImageUpright style={{ height: "auto", width: "100%" }} src={getSrc(maps.context.url)} />
								)}
							</View>
							<View style={{ width: "44vw" }}>
								<View
									style={[
										this.styles.row,
										this.styles.flexCenter,
										{
											backgroundColor:
												compliance[details.compliance_status || block.compliance_status || "NA"]?.color?.code,
											height: "10%",
											fontSize: 15,
										},
									]}
								>
									<Text style={{ marginRight: "2mm" }}>Status:</Text>
									<Text
										style={{
											color: (details.compliance_status || block.compliance_status) === "TNC" ? "black" : "white",
										}}
									>
										{compliance[details.compliance_status || block.compliance_status || "NA"].definition}
									</Text>
								</View>
								<View
									style={{
										paddingHorizontal: "1cm",
										paddingVertical: "1.5cm",
										borderBottom: "2 dotted #000",
									}}
								>
									<View style={[this.styles.row, { marginBottom: "2mm" }]}>
										<Text style={{ width: "50%", fontWeight: "bold" }}>Consent/Notification Number:</Text>
										<Text style={{ width: "50%" }}>{block.consent_number}</Text>
									</View>
									<View style={[this.styles.row, { marginBottom: "2mm" }]}>
										<Text style={{ width: "50%", fontWeight: "bold" }}>Monitoring Officer:</Text>
										<Text style={{ width: "50%" }}>{data.details.monitoring_officer}</Text>
									</View>
									<View style={[this.styles.row, { marginBottom: "2mm" }]}>
										<Text style={{ width: "50%", fontWeight: "bold" }}>Forest Manager: </Text>
										<Text style={{ width: "50%" }}>{data.details.contact_person || block.Manager || "Unknown"}</Text>
									</View>
									<View style={[this.styles.row, { marginBottom: "2mm" }]}>
										<Text style={{ width: "50%", fontWeight: "bold" }}>Earthworks Contractor:</Text>
										<Text style={{ width: "50%" }}>
											{data.details.earthworks_contractor || block.earthworks_contractor || "Unknown"}
										</Text>
									</View>
									<View style={[this.styles.row, { marginBottom: "2mm" }]}>
										<Text style={{ width: "50%", fontWeight: "bold" }}>Harvesting Contractor:</Text>
										<Text style={{ width: "50%" }}>
											{data.details.harvesting_contractor || block.harvesting_contractor || "Unknown"}
										</Text>
									</View>
									<View style={[this.styles.row, { marginBottom: "2mm" }]}>
										<Text style={{ width: "50%", fontWeight: "bold" }}>Inspection Date:</Text>
										<Text style={{ width: "50%" }}>{data.details.inspection_date}</Text>
									</View>
									<View style={[{ marginBottom: "2mm" }]}>
										<Text style={{ width: "100%", fontWeight: "bold" }}>Proximity to waterways:</Text>
									</View>
									<View style={[{ marginBottom: "2mm" }]}>
										<Text style={{ width: "100%" }}>{data.details.proximity_to_waterways}</Text>
									</View>
								</View>
								<View
									style={{
										padding: "1cm",
										fontSize: 13,
										borderBottom: "2 dotted #000",
									}}
								>
									<View style={[this.styles.row, { marginBottom: "2mm", color: "green" }]}>
										<View
											style={{
												height: 13,
												width: 13,
												backgroundColor: "green",
												marginRight: "5mm",
											}}
										/>
										<Text style={{ fontWeight: "bold", flexGrow: 2 }}>Areas of Excellence</Text>
										<Text>{details.areas_of_excellence_count}</Text>
									</View>
									<View style={[this.styles.row, { marginBottom: "2mm", color: "orange" }]}>
										<View
											style={{
												height: 13,
												width: 13,
												backgroundColor: "orange",
												marginRight: "5mm",
											}}
										/>
										<Text style={{ fontWeight: "bold", flexGrow: 2 }}>Areas of Concern</Text>
										<Text>{details.areas_of_concern_count}</Text>
									</View>
									<View style={[this.styles.row, { marginBottom: "2mm", color: "red" }]}>
										<View
											style={{
												height: 13,
												width: 13,
												backgroundColor: "red",
												marginRight: "5mm",
											}}
										/>
										<Text style={{ fontWeight: "bold", flexGrow: 2 }}>Areas of Failure</Text>
										<Text>{details.areas_of_failure_count}</Text>
									</View>
									<View style={[this.styles.row, { marginBottom: "2mm", color: "#991F23" }]}>
										<View
											style={{
												height: 13,
												width: 13,
												backgroundColor: "#991F23",
												marginRight: "5mm",
											}}
										/>
										<Text style={{ fontWeight: "bold", flexGrow: 2 }}>Tasks</Text>
										<Text>{details.tasks_count}</Text>
									</View>
									{/* <Text style={{ fontSize: 7, marginTop: '2mm' }}>* Compliance rating is at any given time dont take this for granted and a general disclaimer that removes Council from any legal actions if something happens in the future</Text> */}
								</View>
								<View
									style={[
										this.styles.flexCenter,
										{
											position: "absolute",
											width: "100%",
											height: "15%",
											bottom: 0,
										},
									]}
								>
									<ImageUpright
										style={{ transform: "scale(0.6)" }}
										src={process.env.PUBLIC_URL + block.authorityItem?.colour_logo_url?.split("public")[1]}
									/>
								</View>
							</View>
						</View>
						{this.pageFooter()}
					</Page>
					{printMessage("start page 2", messageLevels.verbose)}
					<Page size="A4" style={this.styles.page}>
						<View style={{ height: "30vh", flexDirection: "row" }}>
							<View
								style={{
									width: "100vw",
									backgroundColor: "#d9d9d9",
									padding: "7mm",
								}}
							>
								<Text
									style={[
										{
											color: "#417FE9",
											textAlign: "center",
											fontSize: 13,
											marginBottom: "5mm",
										},
									]}
								>
									Status of work and area inspected:
								</Text>
								<Text>{data.details.status_of_work}</Text>
							</View>
						</View>
						<View style={{ height: "60vh", flexDirection: "row" }}>
							<View
								style={{
									width: "50vw",
									backgroundColor: "#d9d9d9",
									padding: "7mm",
								}}
							>
								<Text
									style={[
										{
											color: "#417FE9",
											textAlign: "center",
											fontSize: 13,
											marginBottom: "5mm",
										},
									]}
								>
									Please Note
								</Text>
								<Text
									style={{
										fontSize: 10,
										fontWeight: "extrabold",
										marginBottom: "5mm",
										textDecoration: "underline",
									}}
								>
									{report_config.monitoring_fees.title}
								</Text>
								{Object.keys(report_config.monitoring_fees)
									.filter((key) => key.includes("p"))
									.map((_, i) => {
										return (
											<Text style={{ marginBottom: "5mm" }} key={i}>
												{report_config.monitoring_fees[`p${i + 1}`]}
											</Text>
										)
									})}
								<Text
									style={{
										fontSize: 10,
										fontWeight: "extrabold",
										marginBottom: "5mm",
										textDecoration: "underline",
									}}
								>
									{report_config.privacy_statement.title}
								</Text>
								{Object.keys(report_config.privacy_statement)
									.filter((key) => key.includes("p"))
									.map((_, i) => {
										return (
											<Text style={{ marginBottom: "5mm" }} key={i}>
												{report_config.privacy_statement[`p${i + 1}`]}
											</Text>
										)
									})}
							</View>
							<View style={[{ width: "50vw", padding: "1cm", backgroundColor: "#000" }]}>
								<Text
									style={[
										{
											color: "#417FE9",
											textAlign: "center",
											fontSize: 13,
											marginBottom: "15mm",
										},
									]}
								>
									Forestry Map Key
								</Text>
								{forestryMapKey.map((item) => (
									<View
										style={[
											this.styles.row,
											{
												alignItems: "center",
												height: "1cm",
												marginBottom: "2mm",
												position: "relative",
											},
										]}
										key={item.key}
									>
										<View
											style={{
												position: "absolute",
												top: 0,
												left: 0,
												width: "2cm",
											}}
										>
											<ImageUpright
												src={process.env.PUBLIC_URL + item.src}
												style={{
													marginRight: "8mm",
													height: "0.7cm",
													objectFit: "contain",
												}}
											/>
										</View>
										<Text
											style={{
												fontWeight: "bold",
												fontSize: 12,
												color: "#fff",
												textAlign: "center",
												marginLeft: "2cm",
											}}
										>
											{item.key}
										</Text>
									</View>
								))}
							</View>
						</View>
						<View
							style={{
								height: "10vh",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-evenly",
								fontSize: 8,
							}}
						>
							<View style={{ width: "25%" }}>
								<Text>{block.authorityItem?.council_name}</Text>
								<Text>{block.authorityItem?.address}</Text>
							</View>
							<View style={{ width: "40%" }}>
								<ImageUpright
									style={{ transform: "scale(0.6)" }}
									src={{
										uri: process.env.PUBLIC_URL + block.authorityItem?.colour_logo_url?.split("public")[1],
										method: "GET",
										headers: { "Cache-Control": "no-cache" },
										body: "",
									}}
								/>
							</View>
							<View style={{ width: "25%" }}>
								<View style={[this.styles.row, { justifyContent: "space-between" }]}>
									<Text>Telephone:</Text>
									<Text>{block.authorityItem?.formattedPhoneNumber}</Text>
								</View>
								{block.authorityItem?.fax ? (
									<View style={[this.styles.row, { justifyContent: "space-between" }]}>
										<Text>Fax:</Text>
										<Text>{block.authorityItem?.formattedFaxNumber}</Text>
									</View>
								) : null}
								<View style={[this.styles.row, { justifyContent: "space-between" }]}>
									<Text>Email:</Text>
									<Text>{block.authorityItem?.email}</Text>
								</View>
							</View>
						</View>
						{this.pageFooter()}
					</Page>
					{this.renderAOEs()}

					{this.renderTasks()}
					{printMessage("start maps", messageLevels.verbose)}
					{maps.task && maps.task.url && this.renderMapPage(maps.task, "Task Map", "#3878EB")}
					{maps.consentedVsBuilt &&
						maps.consentedVsBuilt.url &&
						this.renderMapPage(maps.consentedVsBuilt, "Consented vs As Built Map", "#3878EB")}
					{maps.skidSite && maps.skidSite.url && this.renderMapPage(maps.skidSite, "Skid Site Map", "#3878EB")}

					{this.renderSkids(skidSites)}
					{printMessage("end of document", messageLevels.verbose)}
				</>
			</Document>
		)
	}
}

export default ReportPdfGenClass
