import { blockFeature } from "./blockFeature"
import variables from "../../variables"
import { keyNotSet } from "../helpers"
import { printData, printMessage } from "../../providers/remoteHQ"
import { messageLevels } from "../types"

const { skidComplianceOptions: compliance } = variables

export class skidSite extends blockFeature {
	get statusDefinition() {
		return this.definitionForStatusField("status")
	}

	get statusColor() {
		return this.colorForStatusField("status")
	}

	getDefaultComplianceObject(field: string) {
		const theCode = this.getValue(field)
		return theCode ? this.complianceObjectForStatusField(field) : null
	}

	complianceObjectForStatusField(field: string) {
		let theCode = this.getValue(field)
		if (!theCode) {
			theCode = "NA"
		}
		let theEntry = compliance[theCode]

		if (!theEntry) {
			if (theCode.length > 2) {
				theEntry = compliance[Object.values(compliance).find((obj) => obj.definition === theCode)?.KEY || "NA"]
			}
		}
		return theEntry
	}
	definitionForStatusField(field: string) {
		const theEntry = this.complianceObjectForStatusField(field)

		return theEntry.definition
	}
	codeForStatusField(field: string) {
		const theEntry = this.complianceObjectForStatusField(field)

		return theEntry.KEY
	}
	colorForStatusField(field: string) {
		const theEntry = this.complianceObjectForStatusField(field)

		return theEntry.color
	}
	labelColorForStatusField(
		field: string,
	):
		| "orange"
		| "yellow"
		| "olive"
		| "green"
		| "teal"
		| "blue"
		| "violet"
		| "purple"
		| "pink"
		| "brown"
		| "grey"
		| "black"
		| "red"
		| undefined {
		const theEntry = this.complianceObjectForStatusField(field)

		return theEntry.color.name
	}
	validForSave = (): boolean => {
		if (keyNotSet(this, "geojson")) return false
		const questions = variables.skidSiteQuestions
		let valid = true
		Object.keys(questions).forEach((key) => {
			if (keyNotSet(this, key)) {
				valid = false
			}
		})
		return valid
	}

	validationErrorForKey = (key: string): any => {
		const questions = variables.skidSiteQuestions
		if (Object.keys(questions).includes(key)) {
			return keyNotSet(this, key)
		}
		switch (key) {
			case "geojson":
				return keyNotSet(this, key)

			default:
				return null
		}
	}
}
