import React from "react"
import { LngLat } from "mapbox-gl"
import { LabelProps, SemanticShorthandItem } from "semantic-ui-react"

import PinPlace from "./PinPlace"
import { printData, printMessage } from "../providers/remoteHQ"
import { messageLevels } from "../store/types"

interface PinPlaceGeoJSONPointProps {
	getValue?: any
	setValue?: any
	initialLocation?: any
	edit?: boolean
	hideDisplay?: boolean
	hidePin?: boolean
	pinColor?: string
	error?: boolean | string | null | SemanticShorthandItem<LabelProps>
	popupMessage?: string
}

interface PinPlaceGeoJSONPointState {
	lnglat: LngLat | null
	hasError: boolean
}

export default class PinPlaceGeoJSONPoint extends React.Component<PinPlaceGeoJSONPointProps, PinPlaceGeoJSONPointState> {
	state: PinPlaceGeoJSONPointState = {
		hasError: false,
		lnglat: this.geoJSONToLngLat(this.props.initialLocation),
	}

	// componentDidMount() {
	// const initialLocation = this.props.initialLocation;
	// let locationObject = initialLocation;

	// if(initialLocation) {
	//     const theLoc = this.geoJSONToLngLat(initialLocation);
	//     if(theLoc) {
	//         this.setState({
	//             lnglat: theLoc,
	//         });
	//     }

	// }
	// };

	geoJSONToLngLat(geo: any) {
		if (typeof geo === "string") {
			try {
				geo = JSON.parse(geo)
			} catch (e) {
				geo = null
			}
		}
		if (!geo) {
			return null
		}
		printData(geo, messageLevels.debug, "locationObject")
		const theType = geo.type
		printData(theType, messageLevels.debug, "theType")
		if (theType && theType.toLowerCase() === "point") {
			const theCoords = geo.coordinates
			if (theCoords) {
				printData(theCoords, messageLevels.debug, "theCoords")
				const theLngLat = new LngLat(theCoords[0], theCoords[1])
				printData(theLngLat, messageLevels.debug, "theLngLat")
				return theLngLat
			}
		}
		return null
	}

	lngLatTogeoJSON = (lngLat: LngLat) => {
		// ToDo Consstruct and return a geoJSON object
		const feature: {
			type: string
			coordinates: any
		} = {
			type: "Point",
			coordinates: [lngLat.lng, lngLat.lat],
		}
		let featureJSON = null
		if (feature) {
			try {
				featureJSON = JSON.stringify(feature)
			} finally {
				return featureJSON
			}
		}

		return null
	}

	setValue = (lngLat: LngLat) => {
		if (lngLat) {
			this.setState({
				lnglat: lngLat,
			})
		}
		if (this.props.setValue) {
			const theNewGeoJSON = this.lngLatTogeoJSON(lngLat)
			printData(theNewGeoJSON, messageLevels.debug, "theNewGeoJSON in setValue in PinGeoJSON")
			this.props.setValue(theNewGeoJSON)
		}
	}
	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}
	componentDidCatch(error: any, errorInfo: any) {
		// You can also log the error to an error reporting service
		printMessage("error in PinPlaceGeoJSONPoint", messageLevels.error)
		printData(error, messageLevels.error)
		printData(errorInfo, messageLevels.error)
	}

	render() {
		let lngLat = this.state.lnglat
		if (!this.props.edit) {
			lngLat = this.geoJSONToLngLat(this.props.initialLocation)
		}
		if (this.state.hasError) {
			return null
		}
		printData(lngLat, messageLevels.debug, "lngLat")
		printData(this.props.edit, messageLevels.debug, "this.props.edit")
		return (
			<PinPlace
				edit={this.props.edit}
				hidePin={this.props.hidePin}
				initialLocation={lngLat}
				pinColor={this.props.pinColor}
				error={this.props.error}
				setValue={this.setValue}
				hideDisplay={this.props.hideDisplay}
				popupMessage={this.props.popupMessage}
			></PinPlace>
		)
	}
}
