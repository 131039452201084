import React from "react"
import { Label, Menu, Segment } from "semantic-ui-react"

import { call, isStoredItem, registerforStateChanges, removeStateChanges } from "../../../../store"
import { dataFeed, getDataFeedByName } from "../../../../store/dataFeed"
import { storedItem } from "../../../../store/storedItem"
import { forestOwner } from "../../../../store/classes/forest"
import "./styles.css"
import FeedListComponent from "../../../../mapsight/FeedListComponent"
import ListComponent from "../../../../mapsight/ListComponent"
import { messageLevels, sortDirection, sortMethod } from "../../../../store/types"
import { printData } from "../../../../providers/remoteHQ"

export interface ForestSearchState {
	dataFeed: dataFeed
	level: any
	regions: any
	loggedIn: boolean
}
interface ForestSearchProps {
	visible: boolean
	onSelect: () => any
}

// export default class Catchment extends React.Component<CatchmentProps, CatchmentState> {
export default class ForestSearchList extends React.Component<ForestSearchProps, ForestSearchState> {
	state = {
		dataFeed: getDataFeedByName("forest-ownership", true),
		level: null,
		regions: null,
		loggedIn: false,
	}

	componentDidMount() {
		registerforStateChanges(this.userChanged)

		this.state.dataFeed.setupSource("forest-ownership", {})
	}

	componentWillUnmount() {
		removeStateChanges(this.userChanged)
		removeStateChanges(this.dataLoaded)
	}

	dataLoaded() {}

	stateChanged = (type: string, key?: string, data?: storedItem) => {
		// if (key === "level") {
		// 	this.setState({ level: data });
		// } else if (key === "regions") {
		// 	this.setState({ regions: data });
		// } else
	}

	userChanged = (type: string, key: string, data: any) => {
		if (key === "loginState") {
			this.setState({ loggedIn: data.getValue("isLoggedIn") })
		}
	}

	handleClick = (item: storedItem) => {
		item.select()
		// printData(item, messageLevels.verbose, "clicked item");
	}

	forestCellFormatter = (item: storedItem, index: any, hovered: boolean) => {
		const theForest = item as forestOwner
		if (!theForest) return null
		if (!isStoredItem(theForest)) return null

		const complianceStatus = theForest.getComplianceStatus()
		const name = theForest.displayName()
		const area = parseFloat(theForest.getValue("area_ha")).toFixed(2)
		const forestId = theForest.getValue("forest_id")
		return (
			<Menu.Item
				link
				key={`block_${theForest.primaryKey()}`}
				onMouseOver={() => call("setRolloverByItem", theForest)}
				onMouseLeave={() => call("setRolloverByItem", null)}
				onClick={() => {
					theForest.selectOnMap()
					this.props.onSelect()
				}}
				active={theForest.isSelected() || hovered}
			>
				#{forestId} {name}
				<small>&nbsp;({area}ha)</small>
				{theForest.getValue("plant_year") ? <small>&nbsp;({theForest.getValue("plant_year")})</small> : null}
				<Label color={complianceStatus.color.name}>{theForest.getValue("status") || "Planting"}</Label>
			</Menu.Item>
		)
	}

	regionCellFormatter = (item: storedItem) => {
		// const theName = item.getValue("english_name") + " - " + item.getValue("maori_name");
		const theName = item.getValue("english_name")
		const theRegion = isStoredItem(item) ? item.getRegionISO() : "NZL"
		const theQuery = "getRegionISO === " + item.getRegionISO()
		// printData(theQuery, messageLevels.verbose, "theQuery");
		return (
			<>
				<FeedListComponent
					key={"region" + theRegion}
					dataFeedName="forest-ownership"
					queryParams={{
						queries: [theQuery],
						sort: {
							sortField: "area_ha",
							sortDirection: sortDirection.descending,
							sortMethod: sortMethod.numeric,
						},
					}}
					cellFormatter={this.forestCellFormatter}
					globalHover
					header={theName}
				/>
			</>
		)
	}

	render() {
		return (
			<>
				<Segment inverted>
					<ListComponent
						dataSourceName="boundaries"
						queryParams={{
							queries: ["iso_subdivision_category === region"],
							sort: {
								sortField: "english_name",
							},
						}}
						cellFormatter={this.regionCellFormatter}
					></ListComponent>
				</Segment>
			</>
		)
	}
}
