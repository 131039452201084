import React from "react"
import { FormItemComponentProps } from "../.."
import { Text } from "@react-pdf/renderer"
export class FieldLabel extends React.Component<FormItemComponentProps> {
	render() {
		const { field, item } = this.props
		const error = item?.validationErrorForKey(field.name)
		const classes = "fieldLabel" + (field.mandatory ? " required" : "") + (error ? " error" : "")
		// const color = undefined;
		// if(error ) {
		//     return (
		//         <span style={{color: "red"}}>
		//             {field.label}
		//         </span>
		//     )
		// } else {
		return (
			<>
				{/* <div className={ classes}> */}
				<Text>{field.label}</Text>
				{/* </div> */}
			</>
		)
		// }
	}
}
