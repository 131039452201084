import React, { Component } from "react"
import { Checkbox, Container, Icon, Label, Popup } from "semantic-ui-react"
import DatePicker from "react-datepicker"
import moment from "moment"

import { storedItem } from "../../storedItem"
import { printData } from "../../../providers/remoteHQ"
import { messageLevels } from "../../types"
import { FormItemComponentProps } from ".."

export class CheckboxDateRangeItem extends Component<FormItemComponentProps> {
	state = {
		dateRange: "",
		checked: false,
	}

	getChecked = () => {
		const theCheck = this.props.item?.getValue(this.props.field.name + "{checked}")
		if (theCheck === undefined) {
			return false
		}
		return theCheck
	}

	setChecked = (value: boolean | undefined) => {
		this.setValue(value, this.props.field.name + "{checked}")
		this.setState({ checked: value })
	}

	setDateRange = (dateRange: string) => {
		this.setValue(dateRange, this.props.field.name + "{dates}")
	}

	getDateRange = (index?: number) => {
		const theDateRange = this.props.item?.getValue(this.props.field.name + "{dates}")
		if (!theDateRange) return ""
		if (index !== undefined) {
			const dateRange = theDateRange.split(" - ")
			return dateRange[index] === "null" ? null : new Date(dateRange[index])
		}
		return theDateRange
	}

	setValue = (value: any, forKey: string) => {
		if (this.props.setValue) {
			printData(value, messageLevels.verbose, "Value")
			this.props.setValue(value, forKey)
			return
		}
		const theItem: storedItem | null | undefined = this.props.item
		if (theItem && theItem !== undefined && forKey && forKey !== "") {
			printData(value, messageLevels.verbose, "Value")
			theItem.setValue(value, forKey)

			if (this.props.setDirty) {
				this.props.setDirty()
			}
		}
	}

	render() {
		const { field, error } = this.props
		const { checked } = this.state
		return (
			<Container className={`checkbox-date-range-item`} style={{ padding: 0, paddingBottom: checked ? "10px" : 0 }}>
				<Checkbox
					label={
						<label style={{ float: "left" }}>
							{field.label}
							<span style={{ color: "gray", fontSize: "0.8em", marginLeft: "5px" }}>{field.description}</span>
						</label>
					}
					checked={this.getChecked()}
					onChange={(e, data) => this.setChecked(data.checked)}
				/>
				<Popup
					pinned
					position="top right"
					offset={[10, 0]}
					content={field.tooltip}
					trigger={<Icon style={{ float: "right" }} name="question circle" />}
				/>
				{checked ? (
					<DatePicker
						selectsRange
						isClearable
						startDate={this.getDateRange(0)}
						endDate={this.getDateRange(1)}
						placeholderText="Start Date - End Date"
						dateFormat="dd MMMM yyyy"
						onChange={([start, end]) => {
							const startDate = start ? moment(start).format("DD MMMM yyyy") : null
							const endDate = end ? moment(end).format("DD MMMM yyyy") : null
							const dateRange = start || end ? `${startDate} - ${endDate}` : ""
							this.setDateRange(dateRange)
						}}
					/>
				) : null}
				{error ? (
					<Label basic color="red" pointing="right" prompt>
						{error}
					</Label>
				) : null}
			</Container>
		)
	}
}

export default CheckboxDateRangeItem
