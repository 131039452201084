import React from "react"
import { Grid, Icon, Segment } from "semantic-ui-react"

interface ViewProps {
	visible: boolean
	block: any
}

export default class View extends React.Component<ViewProps, {}> {
	state = {}

	render() {
		const { visible, block } = this.props
		return (
			<div id="info-view" style={!visible ? { display: "none" } : {}}>
				{block.three_d_model_filepath && (
					<Segment inverted basic style={{ paddingTop: 0 }}>
						Click the link to open the 3D Model
					</Segment>
				)}

				<Segment inverted basic>
					<Grid columns={3}>
						{[block.three_d_model_filepath].map((path, i) => {
							if (!path) return null
							return (
								<Grid.Row as="a" href={path} target="_blank">
									<Grid.Column width="2">
										<Icon name={"street view"} size="large" />
									</Grid.Column>
									<Grid.Column width="12">3D Model {i + 1}</Grid.Column>
									<Grid.Column width="2" style={{ paddingLeft: 0 }}>
										<Icon name="eye" size="large" />
									</Grid.Column>
								</Grid.Row>
							)
						})}

						{!block.three_d_model_filepath && (
							<Segment inverted basic style={{ paddingTop: 0 }}>
								Sorry there are no 3D Models available
							</Segment>
						)}
					</Grid>
				</Segment>
			</div>
		)
	}
}
