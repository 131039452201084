import React from "react"
import { Header, Menu } from "semantic-ui-react"

import { getStoreTypeByName, getUIConfig, registerforStateChanges, removeStateChanges } from "../store"
import { storedItem } from "../store/storedItem"
import { storeType } from "../store/storeType"
import { printData, printMessage } from "../providers/remoteHQ"
import { IQueryParams, messageLevels } from "../store/types"
import { compareArrays, compareKeyArrays } from "../store/dataFeed"

interface ListComponentProps {
	dataSourceName: string
	queryParams?: IQueryParams
	header?: string
	noWrapper?: boolean
	cellFormatter?: Function
	globalHover?: boolean
	onLoad?: Function
}

interface ListComponentState {
	dataSource: storeType | null
	listKeys: string
	regions: string[]
	selected: any
	hover: string[]
}
const emptyStoredArray: storedItem[] = []
const emptyArray: any[] = []
export default class ListComponent extends React.Component<ListComponentProps, ListComponentState> {
	state = {
		dataSource: getStoreTypeByName(this.props.dataSourceName || "forest-blocks", true),
		listKeys: "",
		regions: [],
		selected: null,
		hover: emptyArray,
		uiConfig: getUIConfig("defaultSidebar"),
	}
	data = emptyStoredArray
	cellFormatterInUse: Function = this.props.cellFormatter || (() => {})

	dataLoaded = (type: string) => {
		this.setState({ listKeys: "" })
		if (type === (this.props.dataSourceName || "forest-blocks")) {
			printMessage("List updated for: " + type, messageLevels.verbose)
			this.getData()
		}
	}
	stateChanged = (type: string, key?: string, data?: storedItem) => {
		if (key === "regions") {
			if (data) {
				// printData(data, messageLevels.error, "Region update in listcomponent")
				const theNewRegions = data.getValue()
				if (!compareArrays(this.state.regions, theNewRegions)) {
					this.setState({ regions: data.getValue() }, () => {
						this.getData()
					})
				}
			}
		}
		if (this.props.globalHover && key === "hovers") {
			const value: any[] | null = data?.getValue()
			const theListKeys: string[] = this.state.listKeys.split(",")
			if (value && Array.isArray(value)) {
				const theItems: string[] = []

				const theDataSourceName = this.props.dataSourceName

				value.forEach((item) => {
					const theKey: string = item.primaryKey().toString()

					if (theListKeys.includes(theKey)) {
						theItems.push(theKey)
					}
				})

				if (theItems.length > 0) {
					if (!compareKeyArrays(this.state.hover, theItems)) {
						this.setState({ hover: theItems })
					}
				} else {
					this.setState({ hover: emptyArray })
				}
			} else {
				this.setState({ hover: emptyArray })
			}
		}
	}

	componentDidMount() {
		if (!this.props.cellFormatter) {
			this.cellFormatterInUse = this.defaultCellFormatter
		}

		if (this.state.dataSource) {
			printMessage("Datasource:" + this.state.dataSource.name, messageLevels.verbose)
			this.state.dataSource.requestLoad(this.onLoad, this.dataLoaded)
		}
		registerforStateChanges(this.stateChanged)
	}

	componentWillUnmount() {
		removeStateChanges(this.stateChanged)
	}

	onLoad = () => {
		this.props.onLoad && this.props.onLoad()
		this.getData()
	}

	getData() {
		const theDataSource = this.state.dataSource
		if (theDataSource) {
			const theData = theDataSource.matchingItems(this.props.queryParams || {})
			const dataCopy = theData?.map((element) => element.primaryKey().toString()).join(",")

			if (dataCopy && this.state.listKeys !== dataCopy) {
				this.data = theData || []
			}
		}
	}

	defaultCellFormatter = (item: storedItem) => {
		return (
			<Menu.Item>
				Primary Key: {item.primaryKey()}
				<br />
				Region: {item.getRegionISO()}
				<br />
				Name: {item.getValue("forest_name")}
				<br />
			</Menu.Item>
		)
	}

	renderItem = (item: storedItem) => this.cellFormatterInUse(item)

	renderHeader = () => {
		const fontColor = this.state.uiConfig?.fontColor
		if (this.props.header && this.data && this.data.length > 0) {
			return <Header style={{ color: fontColor }}>{this.props.header}</Header>
		}
	}

	renderItems = (items: storedItem[]) => {
		const hovers: any[] = this.state.hover

		return (
			<>
				{items.map((item, index) => {
					const hovered = hovers.includes(item.primaryKey())
					return this.cellFormatterInUse(item, index, hovered)
				})}
			</>
		)
	}

	render() {
		if (this.props.noWrapper) {
			return (
				<>
					{this.renderHeader()}
					{this.renderItems(this.data || [])}
				</>
			)
		}
		return (
			<>
				<Menu size="large" vertical fluid={true} borderless={true} inverted={true} className="with-labels">
					{this.renderHeader()}
					{this.renderItems(this.data || [])}
				</Menu>
			</>
		)
	}
}
