import { blockFeature } from "./blockFeature"

export class waterControl extends blockFeature {
	// labels
	displayName() {
		let type = this.getValue("type")

		if (!type || type === undefined || type.length === 0 || type === "null") {
			return this.friendlyDisplayName
		}
		return "Water Control (" + type + ")"
	}

	// Relationships

	// Event handling
	mapClick = (e: any): void => {}
}
