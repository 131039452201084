import React, { useCallback, useEffect, useState } from "react"
import { ModalProps, Modal as _Modal } from "semantic-ui-react"

import "./styles.css"
import { getClassNamePrefix, setGlobalState } from "../../store"

interface IModal extends ModalProps {
	handleClose?: () => void
	handleOpen?: () => void
}

export const CustomModal: React.FC<IModal> = ({ children, handleClose, handleOpen, ...props }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)

	useEffect(() => {
		document.addEventListener("keydown", escFunction, false)

		return () => {
			document.removeEventListener("keydown", escFunction, false)
		}
	}, [])

	const _handleOpen = () => {
		setGlobalState("modalActive", true)
		setIsOpen(true)
		handleOpen?.()
	}

	const _handleClose = () => {
		setGlobalState("modalActive", false)
		setIsOpen(false)
		handleClose?.()
	}

	const escFunction = useCallback((event: any) => {
		if (event.key === "Escape") {
			_handleClose()
		}
	}, [])

	return (
		<_Modal
			className={`${getClassNamePrefix()} general-modal`}
			closeIcon
			open={isOpen}
			onClose={_handleClose}
			onOpen={_handleOpen}
			{...props}
		>
			{children}
		</_Modal>
	)
}
