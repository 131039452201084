const complianceStatus: any = {
	C: {
		color: "green",
		name: "check circle",
	},
	NC: {
		color: "orange",
		name: "remove circle",
	},
	SNC: {
		color: "red",
		name: "remove circle",
	},
	TNC: {
		color: "yellow",
		name: "exclamation circle",
	},
	RWR: {
		color: "purple",
		name: "exclamation circle",
	},
	NA: {
		color: "blue",
		name: "window minimize",
	},
}

export default complianceStatus
