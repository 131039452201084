import moment from "moment"

import { messageLevels } from "../../store/types"
import {
	call,
	getAllStoreTypes,
	getGlobalState,
	getPropertyArrayFromItemArray,
	getStoreTypeByName,
	instanceNewItem,
	isStoredItem,
	registerforStateChanges,
	setGlobalState,
} from "../../store"
import { printData, printMessage } from "../../providers/remoteHQ"
import { storedItem } from "../../store/storedItem"
import { storeType } from "../../store/storeType"
import { mapSource } from "./mapSource"
import { LayerConfig, Type } from "../../mapsight/LayerConfig"
import { throttle } from "../../helpers/commonFunc"

export class mapLayer extends storedItem implements LayerConfig {
	_additionalFilter: any[]
	_levelFilters: any[]
	_isEditing = false
	_watchedGlobalStateItems: any[]
	_dim: number
	_visibility: boolean
	_opacity: number

	constructor(type: storeType, data?: any, doNotStore?: boolean) {
		super(type, data, doNotStore)
		this._additionalFilter = []
		this._levelFilters = []
		this._watchedGlobalStateItems = []
		this._dim = 1
		this._visibility = this.defaultVisibility
		this._opacity = this.getValue("paint")?.[`${this.getValue("dataType")}-opacity`] ?? 100
	}

	getParent(): storedItem | null {
		const parentID = this.getValue("parentID")
		if (!parentID) {
			return null
		}
		return this.getRelatedItem("map-layers", parentID, "id", "==", true)
	}

	get childLayerNames() {
		const childLayers = this.getValue("children")
		if (Array.isArray(childLayers)) {
			return childLayers
		}
		if (childLayers) {
			const childLayerArray = childLayers.split(",")
			return childLayerArray
		}
		return []
	}

	get childLayers(): mapLayer[] {
		const theSublayers: mapLayer[] = []
		const mapLayers = getStoreTypeByName("map-layers")
		if (mapLayers) {
			this.childLayerNames.forEach((item: string) => {
				const theLayer = mapLayers.itemByKey(item)
				if (theLayer) {
					theSublayers.push(theLayer as mapLayer)
				}
			})
		}

		return theSublayers
	}
	registerForChanges() {
		registerforStateChanges(this.stateChanged)
	}

	stateChanged = async (type: string, key: string, data: any) => {
		if (this._watchedGlobalStateItems.includes(key)) {
			setTimeout(() => this.setLevelFilters())
		}
	}
	toggleVisibility() {
		if (!this.isDisabled()) {
			const theVisibility = !this.currentVisibility()
			this.setVisibility(theVisibility)
		}
	}

	disableLayer() {
		this.setValue(true, "_disabled")
		call("setLayerVisibility", this.primaryKey(), false)
		this.childLayers.forEach((element) => {
			element.disableLayer()
		})
	}

	enableLayer() {
		this.setValue(false, "_disabled")
		call("setLayerVisibility", this.primaryKey(), this.currentVisibility())
		this.childLayers.forEach((element) => {
			element.setValue(false, "_disabled")
		})
	}

	setEnableLayer(enable: boolean) {
		if (enable) {
			this.enableLayer()
		} else {
			this.disableLayer()
		}
	}
	isDisabled() {
		return this.getValue("_disabled") || false
	}
	storedVisibility() {
		if (this.getValue("_visibility") !== undefined) {
			return this.getValue("_visibility")
		}
		return this.defaultVisibility
	}

	currentVisibility(): boolean {
		return this.getValue("_visibility")
	}

	setVisibility(visibililty: boolean) {
		if (!this.isDisabled()) {
			call("setLayerVisibility", this.primaryKey(), visibililty)
			this.childLayers.forEach((element) => {
				element.setVisibility(visibililty)
			})
		}
		this.setValue(visibililty, "_visibility")
	}

	canView = () => {
		printMessage("checking authority", messageLevels.debug)
		return this.checkReadAuthority()
	}

	canEdit = () => {
		if (!(this.getValue("isEditable") || false)) {
			return false
		}

		return this.checkCreateAuthority()
	}

	canAdd = () => {
		if (!(this.getValue("isEditable") || false)) {
			return false
		}
		// ToDo remove this as it's only meant to be in place till development sorted.

		// const addableLayers: string[] = [ "areas-of-concern", "areas-of-failure", "areas-of-excellence", "tasks", "quarries" , "as-built-stream-crossings", "as-built-skid-sites"];
		// const addableLayers: string[] = [  "tasks","areas-of-excellence", "areas-of-concern", "areas-of-failure","as-built-skid-sites","as-built-stream-crossings"];
		// if (addableLayers.indexOf(this.primaryKey()) < 0 ) {
		// 	return false;
		// }
		printMessage("checking authority", messageLevels.debug)
		return this.checkCreateAuthority()
	}

	enableEdit = () => {
		// TODO  Check against permissions for user, use checkAuthority below
		//checkAuthority(actions.update, this.objectType, item.primaryKey(), item.getRegionISO());

		if (this.getValue("_isEditing") === undefined) {
			this.setEditing(false)
		}
	}

	isEditing = (): boolean => {
		return call("isLayerEditing", this)
	}

	allowsOpacity = (): boolean => {
		const dataType = this.getValue("dataType")
		if (dataType && dataType === "symbol") {
			return false
		}
		return true
	}

	currentOpacity = (): number => {
		const dataType = this.getValue("dataType")
		if (dataType && dataType === "symbol") {
			return 1
		}
		return this._opacity
	}

	setOpacity(opacity: number) {
		this.childLayers.forEach((element) => {
			element.setOpacity(opacity)
		})
		const dataType = this.getValue("dataType")
		if (dataType && dataType === "symbol") {
			return
		}
		this.setValue(opacity, "_opacity")
		call("setLayerOpacity", this.primaryKey(), opacity * this._dim)
	}

	dimTo = (dim: number) => {
		const theOpacity = this.currentOpacity()

		this._dim = dim
		if (theOpacity) {
			this.setOpacity(theOpacity)
		} else {
			this.setOpacity(1.0)
		}
	}

	get dimLevel(): number {
		return this._dim
	}

	get defaultVisibility() {
		const theLayout = this.getValue("layout")
		if (theLayout) {
			if (theLayout.visibility) {
				if (theLayout.visibility === "visible") {
					return true
				} else {
					return false
				}
			}
		}
		return true
	}

	resetToDefault() {
		if (this.defaultVisibility) {
			this.show()
		} else {
			this.hide()
		}
	}

	clickableInCurrentLevel = (): boolean => {
		if (!this.getValue("clickableLevels")) {
			if (typeof this.getValue("clickAction") !== "undefined") {
				return true
			}
			return false
		}

		const found = this.matchesLevelList(this.getValue("clickableLevels"))

		return found
	}

	showInCurrentLevel(): boolean {
		if (!this.getValue("showInLevels")) {
			return false
		}

		const theLevel = getGlobalState("level")?.getValue()

		if (!theLevel) {
			return false
		}
		const found = this.matchesLevelList(this.getValue("showInLevels"))

		return found
	}

	hideInCurrentLevel(): boolean {
		if (!this.getValue("hideInLevels")) {
			return false
		}

		const theLevel = getGlobalState("level")?.getValue()

		if (!theLevel) {
			return false
		}
		const found = this.matchesLevelList(this.getValue("hideInLevels"))

		return found
	}

	handleDimInCurrentLevel() {
		const dimInLevels = this.getValue("dimInLevels")
		if (!dimInLevels) {
			return
		}
		const { levels, dimTo } = dimInLevels

		if (!levels) {
			return
		}
		const found = this.matchesLevelList(levels)
		if (found) {
			this.dimTo(dimTo)
		} else {
			this.dimTo(1.0)
		}
	}

	get clickActive(): boolean {
		return (this as any)._clickActive
	}
	set clickActive(value: boolean) {
		;(this as any)._clickActive = value
	}
	get cursorActive(): string {
		return (this as any)._cursorActive
	}
	set cursorActive(value: string) {
		;(this as any)._cursorActive = value
	}
	matchesLevelList = (levelList: string[]): boolean => {
		let matched = false
		const theLevel = getGlobalState("level")?.getValue()
		if (!theLevel) {
			return false
		}
		levelList.forEach((element) => {
			if (element === "*") {
				matched = true
			} else if (element.endsWith("*")) {
				const theMatch = element.substring(0, element.length - 1)
				if (theLevel.startsWith(theMatch)) {
					matched = true
				}
			} else {
				if (element === theLevel) {
					matched = true
				}
			}
		})
		printMessage("Matched: " + matched, messageLevels.debug)
		return matched
	}
	setupForLevel() {
		this.setLevelFilters()
		this.enableOnSelectionHandler()
		this.dimOnSelectionHandler()
		this.handleDimInCurrentLevel()
		this.setBoundsForSelection()
		const inLevel = this.clickableInCurrentLevel()
		if (this.clickActive !== inLevel) {
			printMessage("Layer click status doesnt match level", messageLevels.debug)
			if (inLevel) {
				printMessage("Layer click status should be on", messageLevels.debug)
				const theReturn = call("layerAddClick", this)
				this.clickActive = theReturn
				;(this as any)._cursorActive = this.getValue("cursor")
			} else {
				printMessage("Layer click status should be off", messageLevels.debug)
				const theReturn = call("layerRemoveClick", this)
				if (theReturn) {
					this.clickActive = false
				}
				const newReturn = call("layerRemoveCursor", this)
				if (newReturn) {
					this.cursorActive = ""
				}
			}
			if (this.showInCurrentLevel() && !this.hideInCurrentLevel()) {
				this.show()
			} else if (this.hideInCurrentLevel() && !this.showInCurrentLevel()) {
				this.hide()
			}
		}

		if (this.showInCurrentLevel() && !this.hideInCurrentLevel()) {
			this.show()
		} else if (this.hideInCurrentLevel() && !this.showInCurrentLevel()) {
			this.hide()
		}
		if (this.getValue("oneOffToggle") === true) {
			this.show()
		}
		if (this.getValue("oneOffToggle") === false) {
			this.hide()
		}
		this.setValue(null, "oneOffToggle")
	}

	get sourceExists(): boolean {
		const theSource = this.sourceItem
		if (!theSource) {
			return false
		}
		return theSource.isOnMap
	}

	get isOnMap(): boolean {
		return (this as any)._isOnMap
	}
	set isOnMap(value: boolean) {
		;(this as any)._isOnMap = value
	}
	get mapLayer() {
		return 1
	}

	checkReadAuthority(): boolean {
		// ToDo - get object type and get authority to read it in current region
		const dataType = this.dataSource

		if (dataType && dataType !== undefined && dataType !== null) {
			const theAuthority = dataType.checkReadAuthority()

			return theAuthority
		}
		return true
	}

	checkCreateAuthority(): boolean {
		// ToDo - get object type and get authority to create it in current region
		const dataType = this.dataSource

		if (dataType && dataType !== undefined && dataType !== null) {
			printData(dataType, messageLevels.debug, "dataType in checkCreateAuthority in mapLayer")
			const theAuthority = dataType.checkCreateAuthority()
			printData(theAuthority, messageLevels.debug, "theAuthority in checkCreateAuthority in mapLayer")
			return theAuthority
		}

		return true
	}

	checkUpdateAuthority(): boolean {
		// ToDo - get object type and get authority to update it in current region
		const dataType = this.dataSource
		if (dataType && dataType !== undefined && dataType !== null) {
			return dataType.checkUpdateAuthority()
		}
		return true
	}

	checkDeleteAuthority(): boolean {
		// ToDo - get object type and get authority to create it in current region
		const dataType = this.dataSource
		if (dataType && dataType !== undefined && dataType !== null) {
			return dataType.checkDeleteAuthority()
		}
		return true
	}

	get sourceItem(): mapSource {
		return this.getRelatedItems("map-sources", this.getValue("source"), "id")[0] as mapSource
	}
	get dataSource(): storeType | null {
		const objectType = this.getValue("objectType")
		if (objectType && objectType !== undefined) {
			return getStoreTypeByName(objectType) ?? null
		}
		return null
	}

	hide() {
		this.setVisibility(false)
	}
	show() {
		this.setVisibility(true)
	}

	getFeatureItem = (e: any) => {
		const features = e.features

		printMessage(`Map hover in mapLayer: ${this.dataSource}`, messageLevels.debug)
		const mapFeatureKey = this.getValue("mapFeatureKey") || this.getType()?.primaryKeyProperty

		if (mapFeatureKey) {
			const theDataType = this.dataSource
			if (theDataType && typeof theDataType !== "undefined") {
				printData(features, messageLevels.debug)

				const theFeatureKey = features[0].properties[mapFeatureKey]
				printMessage("theFeatureKey: " + theFeatureKey, messageLevels.debug)
				if (theFeatureKey && typeof theFeatureKey !== "undefined") {
					const item = theDataType.itemByKey(theFeatureKey || "")
					return item
				}
			}
		}
	}

	mapMouseLeave = (e: any): void => {
		setGlobalState("hovers", [])
	}

	mapMouseEnter = (e: any): void => {
		const item = this.getFeatureItem(e)
		if (item) {
			item.mapMouseEnter(e)
		}
	}

	mapClick = throttle((e: any): void => {
		const item = this.getFeatureItem(e)
		// Clear cache for all storeTypes
		Object.values(getAllStoreTypes()).forEach((storeType) => storeType?.matchingItems?.clear())
		if (item) {
			item.mapClick(e)
		}
	}, 100)

	setFilter(filter: any[] | null) {
		this.childLayers.forEach((element) => {
			element.setFilter(filter)
		})
		printData(filter, messageLevels.debug, "Setting Filter on " + this.primaryKey())
		call("setLayerFilter", this.primaryKey(), filter)
	}

	setPaint(paint: any[] | null) {
		this.childLayers.forEach((element) => {
			element.setPaint(paint)
		})
		printData(paint, messageLevels.debug, "Setting Filter on " + this.primaryKey())
		call("setLayerPaint", this.primaryKey(), paint)
	}

	setAdditionalFilter = (newFilter: any[]) => {
		this.childLayers.forEach((element) => {
			element.setAdditionalFilter(newFilter)
		})
		this._additionalFilter = newFilter

		this.setFilters()
	}

	currentSingleSelection() {
		const selected = getGlobalState("selected")?.getValue()
		if (selected && selected !== undefined && selected.length > 0) {
			return selected[0]
		}
		return null
	}
	valueByParameterFromSelection(parameter: string) {
		const selected = this.currentSingleSelection()
		if (selected) {
			const theValue = selected.getValue(parameter)
			if (theValue && theValue !== undefined) {
				return theValue
			}
		} else {
			return undefined
		}
	}

	enableOnSelectionHandler() {
		let enable = true
		const enableOnSelectionValue = this.getValue("enableOnSelection")
		if (enableOnSelectionValue) {
			const { parameter, comparison, value } = enableOnSelectionValue

			const theValue = this.valueByParameterFromSelection(parameter)
			printMessage(parameter + " " + comparison + " " + theValue, messageLevels.debug)
			if (theValue !== undefined) {
				switch (comparison) {
					case "exists":
						if (!theValue || theValue === undefined) {
							enable = false
						} else if (typeof theValue === "string" && theValue.length < 1) {
							enable = false
						} else if (Array.isArray(theValue) && theValue.length < 1) {
							enable = false
						}
						break
					case "===":
						enable = theValue === value
						break
					case "true":
						enable = theValue
						break
					case "false":
						enable = !theValue
						break
				}
			} else {
				enable = false
			}
		}
		printMessage("Enable: " + enable + " for " + this.primaryKey(), messageLevels.debug)
		this.setEnableLayer(enable)
	}

	dimOnSelectionHandler() {
		const dimOnSelectionValue = this.getValue("dimOnSelection")
		if (dimOnSelectionValue) {
			let willDim = true
			let theDim
			const { parameter, comparison, value, dimTo } = dimOnSelectionValue

			const theValue = this.valueByParameterFromSelection(parameter)
			printMessage(parameter + " " + comparison + " " + theValue, messageLevels.debug)
			if (theValue !== undefined) {
				switch (comparison) {
					case "exists":
						if (!theValue || theValue === undefined) {
							willDim = false
						} else if (typeof theValue === "string" && theValue.length < 1) {
							willDim = false
						} else if (Array.isArray(theValue) && theValue.length < 1) {
							willDim = false
						}
						break
					case "===":
						willDim = theValue === value
						break
					case "true":
						willDim = theValue
						break
					case "false":
						willDim = !theValue
						break
				}
			} else {
				willDim = false
			}
			theDim = willDim ? dimTo : 1.0
			printMessage("Dim: " + willDim + " for " + this.primaryKey(), messageLevels.debug)

			this.dimTo(theDim)
		}
	}

	setBoundsForSelection() {
		const boundsOnSelectionParameter = this.getValue("boundsOnSelection")
		const theValue = this.valueByParameterFromSelection(boundsOnSelectionParameter)
		if (theValue !== undefined) {
			printMessage("SetBounds: " + theValue + " for " + this.primaryKey(), messageLevels.debug)
			const theSourceLayer = this.sourceItem
			theSourceLayer.setBounds(theValue)
		}
	}

	setLevelFilters() {
		this.childLayers.forEach((element) => {
			element.setLevelFilters()
		})
		const filterToRegionMode = this.getValue("filterToRegionMode")
		const filters: any[] = []
		const mlevel = messageLevels.debug + 1
		printData(filterToRegionMode, mlevel, "regions on " + this.primaryKey())
		if (filterToRegionMode) {
			const theRegions = getGlobalState("regions")?.getValue() || []
			printData(theRegions, mlevel, "filterToRegionMode on " + this.primaryKey())
			const showInRegions = filterToRegionMode === "show"
			const hasRegions =
				theRegions &&
				(theRegions.length > 1 ||
					(theRegions.length === 1 &&
						theRegions[0] !== "NZL" &&
						theRegions[0] !== "NZ-SI" &&
						theRegions[0] !== "NZ-NI" &&
						theRegions[0] !== undefined))
			printData(hasRegions, mlevel, "hasRegions on " + this.primaryKey())
			const noRegionMode = this.getValue("noRegionMode")
			const noRegionShow = noRegionMode === "show"
			const noRegionHide = noRegionMode === "hide"
			printData(noRegionMode, mlevel, "noRegionMode on " + this.primaryKey())
			printData(noRegionShow, mlevel, "noRegionShow on " + this.primaryKey())
			printData(noRegionHide, mlevel, "noRegionHide on " + this.primaryKey())
			if (hasRegions) {
				filters.push(["match", ["get", this._type?.objectRegionProperty || "iso"], theRegions, showInRegions, !showInRegions])
			} else if (noRegionHide) {
				filters.push(["match", ["get", this._type?.objectRegionProperty || "iso"], [""], false, false])
			} else if (noRegionShow) {
				filters.push(["match", ["get", this._type?.objectRegionProperty || "iso"], [""], true, true])
			}
		}
		const filterGlobalStates: string[] = []

		printData(filters, mlevel, " filters after region " + this.primaryKey())
		const filterLevels = this.getValue("filterToLevels")
		printData(filterLevels, mlevel, "level filters in " + this.primaryKey())
		if (filterLevels && filterLevels !== undefined) {
			const selected = getGlobalState("selected")?.getValue()
			if (selected && selected !== undefined && selected.length > 0) {
				printData(filterLevels, mlevel, "level filters in " + this.primaryKey())
				const levelSelected = getGlobalState("level")?.getValue()
				let isDisabled = false
				filterLevels.forEach((element: any) => {
					const disableIfState = element.disableIfState
					printData(disableIfState, mlevel, "disableIfState in " + this.primaryKey())
					isDisabled = false
					if (disableIfState) {
						filterGlobalStates.push(disableIfState)
						const disableState = getGlobalState(disableIfState)?.getValue()
						if (disableState) {
							isDisabled = true
						}
					}
					if (!isDisabled) {
						const { level, type, selectedKey, featureKey, keyType } = element
						let theKey = selected[0]?.getValue(selectedKey)
						printMessage("Level: " + level, mlevel)
						printMessage("Type: " + type, mlevel)
						printMessage("keyType: " + keyType, mlevel)
						printMessage("selectedKey: " + selectedKey, mlevel)
						printData(theKey, mlevel, "Value: ")
						printMessage("featureKey: " + featureKey, mlevel)
						printMessage("levelSelected: " + levelSelected, mlevel)
						if (Array.isArray(theKey) && isStoredItem(theKey[0])) {
							const valueKeys = selectedKey.split(".")
							const lastKey = valueKeys[valueKeys.length - 1]
							if (lastKey) {
								printMessage("Extracting values ", mlevel)
								printData(lastKey, mlevel, "lastKey")
								theKey = getPropertyArrayFromItemArray(theKey, lastKey)
							}
						}
						const inArrayMode = keyType?.toString().endsWith("[]")

						if (theKey && theKey !== undefined && this.matchesLevelList([level])) {
							printMessage("Level match: ", mlevel)
							printData(theKey, mlevel, "theKey")
							const showInLevel = type === "show"
							if (inArrayMode) {
								if (theKey && theKey.length > 0) {
									filters.push(["==", ["in", theKey, ["get", featureKey || "iso"]], showInLevel])
								} else {
									filters.push(["match", ["get", featureKey || "iso"], [""], !showInLevel, !showInLevel])
								}
							} else {
								if (theKey && theKey.length > 0) {
									filters.push(["match", ["get", featureKey || "iso"], theKey, showInLevel, !showInLevel])
								} else {
									filters.push(["match", ["get", featureKey || "iso"], [""], !showInLevel, !showInLevel])
								}
							}
						}
					}
				})
			}
		}
		this._watchedGlobalStateItems = filterGlobalStates
		printData(filterGlobalStates, mlevel, "filterGlobalStates in " + this.primaryKey())
		printData(filters, mlevel, "level filters in " + this.primaryKey())
		this._levelFilters = [...filters]

		this.setFilters()
	}

	setFilters = () => {
		this.childLayers.forEach((element) => {
			element.setLevelFilters()
		})
		const filters: any[] = [...this._levelFilters]

		if (this._additionalFilter.length > 0) {
			this._additionalFilter.forEach((item) => {
				filters.push(item)
			})
		}
		if (this.getValue("baseFilter")) {
			filters.push(this.getValue("baseFilter"))
		}
		let filterList: any[] = []

		if (filters.length > 1) {
			filterList = ["all", ...filters]
		} else if (filters.length > 0) {
			filterList = filters[0]
		}
		printData(filterList, messageLevels.debug, "Filter in " + this.primaryKey())
		if (filterList.length > 0) {
			this.setFilter(filterList)
		} else {
			this.setFilter(null)
		}
	}

	addToMap = () => {
		if (!this.isOnMap) {
			call("addLayer", this)
			this.childLayers.forEach((layer) => layer.addToMap())
			this.registerForChanges()
		}
	}

	get layerIndex() {
		return (this as any)._layerIndex
	}

	set layerIndex(index) {
		;(this as any)._layerIndex = index
	}

	addToMapAtPrevious = () => {
		if (!this.isOnMap) {
			call("addLayerAtPrevious", this)
			this.childLayers.forEach((layer) => layer.addToMap())
			this.registerForChanges()
		}
	}
	removeFromMap = () => {
		call("removeLayer", this)
		this.isOnMap = false
		this.childLayers.forEach((layer) => layer.removeFromMap())
	}

	setLevelCompliance = () => {
		this.childLayers.forEach((element) => {
			element.setLevelCompliance()
		})
		call("setLayerColor", this.primaryKey(), [
			"case",
			["==", ["get", "compliance_status"], "NA"],
			"#0074FF",
			["==", ["get", "compliance_status"], "C"],
			"#21ba45",
			["==", ["get", "compliance_status"], "TNC"],
			"#ffff00",
			["==", ["get", "compliance_status"], "NC"],
			"#ff8000",
			["==", ["get", "compliance_status"], "SNC"],
			"#ff0000",
			["==", ["get", "compliance_status"], "RWR"],
			"#904EE3",
			"#0074E0",
		])
	}
	setLevelCompletion = () => {
		this.childLayers.forEach((element) => {
			element.setLevelCompletion()
		})
		const currentDateCount = moment().diff(moment("1970-01-01"), "days")
		call("setLayerColor", this.primaryKey(), [
			"case",
			["==", ["has", "end_day"], false], // end_day is null or empty
			"#fff",
			[">=", ["-", currentDateCount, ["number", ["get", "end_day"]]], 2920], //8 year
			"#fff5f0",
			[">=", ["-", currentDateCount, ["number", ["get", "end_day"]]], 2555], //7 year
			"#fee0d2",
			[">=", ["-", currentDateCount, ["number", ["get", "end_day"]]], 2190], //6 year
			"#fcbba1",
			[">=", ["-", currentDateCount, ["number", ["get", "end_day"]]], 1825], //5 year
			"#fc9272",
			[">=", ["-", currentDateCount, ["number", ["get", "end_day"]]], 1460], //4 year
			"#fb6a4a",
			[">=", ["-", currentDateCount, ["number", ["get", "end_day"]]], 1095], //3 year
			"#ef3b2c",
			[">=", ["-", currentDateCount, ["number", ["get", "end_day"]]], 730], //2 year
			"#cb181d",
			[">=", ["-", currentDateCount, ["number", ["get", "end_day"]]], 365], // 1 year
			"#99000d",
			[">=", ["-", ["number", ["get", "end_day"]], currentDateCount], 0], //current operation
			"#2185d0",
			"#2185d0",
		])
	}

	setLevelPriority = () => {
		this.childLayers.forEach((element) => {
			element.setLevelPriority()
		})
		call("setLayerColor", this.primaryKey(), [
			"case",
			["==", ["get", "priority"], "High"], // high
			"#ff0000",
			["==", ["get", "priority"], "Medium"], //medium
			"#ffff00",
			["==", ["get", "priority"], "Low"], // low
			"#21ba45",
			"#000",
		])
	}

	setDefaultColor = () => {
		this.childLayers.forEach((element) => {
			element.setDefaultColor()
		})
		const colorPropertyName = this.layerStyleType + "-color"
		call("setLayerColor", this.primaryKey(), this.getValue("paint")[colorPropertyName])
	}

	layerStyleType = () => {
		let layerType = this.getValue("dataType")
		if (layerType === "symbol") {
			layerType = "icon"
		}
		return layerType
	}

	setDisplayMode = (visualiseBy: string) => {
		switch (visualiseBy.toLowerCase()) {
			case "compliance":
				this.setLevelCompliance()
				break
			case "completion":
				this.setLevelCompletion()
				break
			case "priority":
				this.setLevelPriority()
				break
			default:
				this.setDefaultColor()
		}
	}

	highlightOnMap = (): void => {}
	clearHighlightOnMap = (): void => {}
	selectOnMap = (): void => {}

	addItem = (): storedItem | null => {
		const dataType = this.dataSource

		if (dataType && dataType !== undefined && dataType !== null) {
			const theItem = instanceNewItem(dataType.name)

			return theItem
		}
		return null
	}
}
