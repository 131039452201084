import { storedItem } from "../../store/storedItem"
import { printMessage } from "../../providers/remoteHQ"
import { messageLevels } from "../types"

export class mediaItem extends storedItem {
	get pathName() {
		return this.getValue("destination_path")
	}
	get URL(): string | Buffer | { data: Buffer; format: "png" | "jpg" } | null {
		return null
	}
	get dataObject(): {} | null {
		printMessage("dataObject in mediaItem", messageLevels.debug)
		return null
	}
	get isUploaded(): boolean {
		return true
	}
	displayName() {
		return this.getValue("filename") || "File"
	}

	get displayRotation(): number {
		return 0
	}

	fileType = (): string | null => {
		const theName = this.getValue("filename")
		const extension = theName.split(".").pop()
		if (extension.length > 1 && extension.length < 5) {
			return extension.toLowerCase()
			// this.setValue(extension.toLowerCase(),"type");
		}
		return null
	}
}
