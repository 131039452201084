import React from "react"
import { Grid, Icon, Segment, SemanticICONS } from "semantic-ui-react"
import { forestBlock } from "../../classes/forestBlock"

import { isStoredItem } from "../../"

interface DocumentsProps {
	visible: boolean
	block: forestBlock
}

const fileTypes: { [key: string]: SemanticICONS } = {
	pdf: "file pdf",
	jpg: "file image",
	png: "file image",
}

export default class Documents extends React.Component<DocumentsProps, {}> {
	state = {}

	render() {
		const { visible, block } = this.props
		const supportingDocuments = block.supportingDocumentURLs || []
		if (!isStoredItem(block)) {
			return null
		}
		return (
			<div id="info-documents" style={!visible ? { display: "none" } : {}}>
				{supportingDocuments.length > 0 && (
					<Segment inverted basic style={{ paddingTop: 0 }}>
						Click the link to download the supporting document
					</Segment>
				)}
				<Segment inverted basic>
					<Grid columns={3}>
						{supportingDocuments.map((path: URL) => {
							const pathString = path.toString()
							const tmp = pathString.split("/")
							const name = decodeURIComponent(tmp[tmp.length - 1]).replaceAll("+", " ")
							const tmp2 = name.split(".")
							const type = tmp2[tmp.length - 1]
							return (
								<Grid.Row as="a" href={pathString} target="_blank" alt={name} title={name}>
									<Grid.Column width="2">
										<Icon name={fileTypes[type] || "file"} size="large" />
									</Grid.Column>
									<Grid.Column width="12" className="filename">
										{name}
									</Grid.Column>
									<Grid.Column width="2">
										<Icon name="download" size="large" />
									</Grid.Column>
								</Grid.Row>
							)
						})}
						{supportingDocuments.length === 0 && (
							<Segment inverted basic style={{ paddingTop: 0 }}>
								Sorry, there are no supporting documents available
							</Segment>
						)}
					</Grid>
				</Segment>
			</div>
		)
	}
}
