import React from "react"
import { Button, Form, GridRow, Header, Item, Label, Segment } from "semantic-ui-react"
import ImageUploading, { ImageListType } from "react-images-uploading"

import { blockFeature } from "../classes/blockFeature"
import { dateStripTimezone, isStoredItem, registerOnChange, removeOnChange } from "../"
import { printData, printMessage } from "../../providers/remoteHQ"
import { messageLevels } from "../types"
import "react-datepicker/dist/react-datepicker.css"
import ImageList from "../components/Image/ImageListComponent"
import Status from "../components/StatusComponent"
import { newFileUploaderFromImageListItem } from "../FileHandling"
import { StoredItemEditView, StoredItemInfoView } from "./StoredItem"
import { ItemViewProps } from "./Types"
import ImageUploadOverLay from "../../components/ImageUploadOverlay"

export class BlockFeatureEditView extends StoredItemEditView {
	maxNumber = 10
	setImages = (imageArray: []) => {
		this.setState({ images: imageArray })
	}

	imageChange = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
		// const index = addUpdateIndex?.map((i) => i)[0];
		// This prohibits multiple of the same images being uploaded.
		// const isDuplicate = !!(
		// 	index && this.state.images.filter((i: any) => i.dataURL === imageList[index]["dataURL"]).length
		// );
		// const isDuplicate = false;

		printData(imageList, messageLevels.debug, "imageList in onChange")

		imageList.forEach((image) => {
			const theUpload = newFileUploaderFromImageListItem({
				...image,
				name: image.file?.name,
				lastModified: image.file?.lastModified,
				size: image.file?.size,
				type: image.file?.type,
			})
			printData({ theUpload }, messageLevels.debug, "individual image")
			if (this.props.editingItem) {
				;(this.props.editingItem as blockFeature).addFileUpload(theUpload)
				theUpload.requestStep("readData")
			}
		})
		this.setDirty()
	}

	removeImage = (theImage: string | URL): boolean => {
		const didDelete = (this.props.editingItem as blockFeature).removeImage(theImage.toString())
		if (didDelete) {
			printMessage("Did delete", messageLevels.debug)
			this.setDirty()
		}
		return didDelete
	}

	handleChange = (e: any) => {
		this.setValue(e.target.value, "description")
	}

	handleDropdownChange = (e: any, d: any) => {
		this.setValue(d.value, "description")
	}

	handleDateChange = (date: Date | null) => {
		const theDate = dateStripTimezone(date)
		this.setValue(theDate, "due_date")
	}

	handleGeoChange = (geoJSON: any) => {
		printData(geoJSON, messageLevels.debug, "geojson in task")
		this.setValue(geoJSON, "geojson")
	}

	clearEditingItem = () => {
		// call("clearEditingState");
	}
	theDueDate = () => {
		const theDate = this.props.editingItem?.getValue("due_date")
		if (typeof theDate === "string") {
			const dateObject = new Date(theDate)
			printData(dateObject, messageLevels.debug, "dateObject in theDueDate")
			return new Date(theDate)
		}

		return theDate
	}

	renderImageUpload(children?: JSX.Element) {
		if (!this.props.editingItem) {
			return null
		}
		const { isDragging } = this.state
		const imageLinks = (this.props.editingItem as blockFeature).imageURLs || []
		let dragTimeout: string | number | NodeJS.Timeout | undefined
		return (
			<ImageUploading multiple value={[]} onChange={this.imageChange} maxNumber={this.maxNumber}>
				{({ onImageUpload, dragProps }) => (
					<div
						style={{ minHeight: "calc(100vh - 118px)", width: "100%" }}
						{...dragProps}
						onDragEnter={(e) => {
							e.preventDefault()
							clearTimeout(dragTimeout)
							this.setState({ isDragging: true })
						}}
						onDragLeave={(e) => {
							e.preventDefault()
							e.stopPropagation()
							dragTimeout = setTimeout(() => this.setState({ isDragging: false }), 1500)
						}}
						onDrop={(e) => {
							e.preventDefault()
							e.stopPropagation()
							this.setState({ isDragging: false })
							if (imageLinks.length >= this.maxNumber) return
							dragProps.onDrop(e)
						}}
					>
						<div style={{ pointerEvents: isDragging ? "none" : "unset" }}>
							{children}
							{imageLinks.length ? (
								<Item.Group divided>
									<Item>
										<Segment inverted basic>
											<Item.Group divided>
												<ImageList
													imageLinks={imageLinks}
													hidePlaceholder={true}
													edit={true}
													removeImage={this.removeImage}
												/>
											</Item.Group>
										</Segment>
									</Item>
								</Item.Group>
							) : null}
							<GridRow>
								<Segment inverted basic>
									<Button
										content={imageLinks.length >= this.maxNumber ? "Maximum Reached" : "Click or Drop Images here"}
										color={imageLinks.length >= this.maxNumber ? "grey" : "blue"}
										disabled={imageLinks.length >= this.maxNumber}
										onClick={onImageUpload}
										style={{ marginBottom: "8px" }}
									/>
								</Segment>
							</GridRow>
						</div>
						<ImageUploadOverLay isDragging={isDragging} isMaximum={imageLinks.length >= this.maxNumber} />
					</div>
				)}
			</ImageUploading>
		)
	}

	render() {
		const item = this.props.editingItem as blockFeature

		if (!item || !isStoredItem(item)) {
			return <></>
		}

		return (
			<>
				<Form>
					<Segment inverted basic>
						<Header as="h2" inverted>
							{item && item.displayName()}
						</Header>
					</Segment>
				</Form>
			</>
		)
	}
}

export class BlockFeatureInfoView extends StoredItemInfoView<ItemViewProps> {
	render() {
		const { selectedItem } = this.props

		if (!selectedItem || !isStoredItem(selectedItem)) {
			return <></>
		}

		return (
			<>
				<Segment inverted basic className="basic-info">
					<Header as="h2" inverted>
						{selectedItem?.displayName()}
					</Header>
					<Status item={selectedItem}></Status>
					{selectedItem?.getValue("status") ? (
						<Label horizontal className="compliance-status" color={"orange"}>
							{selectedItem?.getValue("status")}
						</Label>
					) : null}
				</Segment>
			</>
		)
	}
}
