import { mapLayer } from "./mapLayer"
import { storeType } from "../storeType"
import { getStoreTypeByName } from "../"
import { printMessage } from "../../providers/remoteHQ"
export class mapLayerSet extends mapLayer {
	// toggleVisibility() {
	// 	if (!this.isDisabled()) {

	// 		const theVisibility = !this.currentVisibility();
	// 		this.setValue(theVisibility, "_visibility");
	// 		// call on sublayers;

	// 	}
	// }

	// disableLayer() {
	// 	this.setValue(true, "_disabled");
	// 	this.childLayers.forEach(element => {
	// 		element.disableLayer()
	// 	});
	// }

	// enableLayer() {
	// 	this.setValue(false, "_disabled");
	// 	this.childLayers.forEach(element => {
	// 		element.setValue(false, "_disabled");
	// 	});
	// }

	// currentOpacity = (): number => {
	// 	const dataType = this.getValue("dataType");
	// 	if (dataType && dataType === "symbol") {
	// 		return 1;
	// 	}
	// 	const theOpacity = this.getValue("storedOpacity") || this.getValue("opacity");
	// 	// printData(theOpacity, messageLevels.verbose, "currentOpacity in " + this.primaryKey());
	// 	return theOpacity;
	// }

	// get sourceExists(): boolean {
	// 	const theSource = this.sourceItem;
	// 	if (!theSource) {
	// 		return false;
	// 	}
	// 	return theSource.isOnMap;
	// }

	get isOnMap(): boolean {
		return (this as any)._isOnMap
	}

	set isOnMap(value: boolean) {
		;(this as any)._isOnMap = value
	}
	get mapLayer() {
		return 1
	}

	// getFeatureItem = (e: any) => {
	// 	const features = e.features;

	// 	printMessage(`Map hover in mapLayer: ${this.dataSource}`, messageLevels.debug);
	// 	const mapFeatureKey = this.getValue("mapFeatureKey") || this.getType()?.primaryKeyProperty;

	// 	if (mapFeatureKey) {
	// 		const theDataType = this.dataSource;
	// 		if (theDataType && typeof theDataType !== "undefined") {
	// 			printData(features, messageLevels.debug);

	// 			const theFeatureKey = features[0].properties[mapFeatureKey];
	// 			printMessage("theFeatureKey: " + theFeatureKey, messageLevels.debug);
	// 			if (theFeatureKey && typeof theFeatureKey !== "undefined") {
	// 				const item = theDataType.itemByKey(theFeatureKey || "");
	// 				return item;
	// 			}
	// 		}
	// 	}
	// }

	setFilter(filter: any[] | null) {
		this.childLayers.forEach((element) => {
			element.setFilter(filter)
		})
	}

	setPaint(paint: any[] | null) {
		this.childLayers.forEach((element) => {
			element.setPaint(paint)
		})
	}

	setAdditionalFilter = (newFilter: any[]) => {
		this.childLayers.forEach((element) => {
			element.setAdditionalFilter(newFilter)
		})
	}

	// currentSingleSelection() {
	// 	const selected = getGlobalState("selected")?.getValue();
	// 	if(selected && selected !== undefined && selected.length > 0) {
	// 		return selected[0];
	// 	}
	// 	return null;
	// }
	valueByParameterFromSelection(parameter: string) {
		const selected = this.currentSingleSelection()
		if (selected) {
			const theValue = selected.getValue(parameter)
			if (theValue && theValue !== undefined) {
				return theValue
			}
		} else {
			return undefined
		}
	}

	// enableOnSelectionHandler() {
	// 	let enable = true;
	// 	const enableOnSelectionValue = this.getValue("enableOnSelection");
	// 	if (enableOnSelectionValue) {
	// 		const {parameter, comparison, value} = enableOnSelectionValue;
	// 		// const selected = this.currentSingleSelection()

	// 		const theValue = this.valueByParameterFromSelection(parameter);
	// 		printMessage(parameter + " " + comparison + " " + theValue,messageLevels.debug)
	// 		if(theValue !== undefined) {
	// 			switch (comparison) {
	// 				case "exists":
	// 					if(!theValue || theValue === undefined) {
	// 						enable = false;
	// 					}
	// 					else if(typeof theValue === "string" && theValue.length < 1) {
	// 						enable = false;
	// 					} else if(Array.isArray(theValue) && theValue.length < 1) {
	// 						enable = false;
	// 					}
	// 					break;
	// 				case "===":
	// 					enable = (theValue === value);
	// 					break;
	// 				case "true":
	// 					enable = theValue;
	// 					break;
	// 				case "false":
	// 					enable = !theValue;
	// 					break;

	// 			}
	// 		} else {
	// 			enable = false;
	// 		}

	// 	}
	// 	printMessage("Enable: "+ enable+ " for " +this.primaryKey(), messageLevels.debug );
	// 	this.setEnableLayer(enable);
	// }

	// setBoundsForSelection() {
	// 	const boundsOnSelectionParameter = this.getValue("boundsOnSelection");
	// 	const theValue = this.valueByParameterFromSelection(boundsOnSelectionParameter);
	// 	if(theValue !== undefined) {
	// 		printMessage("SetBounds: "+ theValue+ " for " +this.primaryKey(), messageLevels.debug );
	// 		const theSourceLayer = this.sourceItem;
	// 	 	theSourceLayer.setBounds( theValue);
	// 	}
	// }

	setLevelFilters() {
		this.childLayers.forEach((element) => {
			element.setLevelFilters()
		})
	}

	setLevelCompliance = () => {
		this.childLayers.forEach((element) => {
			element.setLevelCompliance()
		})
	}
	setLevelCompletion = () => {
		this.childLayers.forEach((element) => {
			element.setLevelCompletion()
		})
	}

	setLevelPriority = () => {
		this.childLayers.forEach((element) => {
			element.setLevelPriority()
		})
	}

	setDefaultColor = () => {
		this.childLayers.forEach((element) => {
			element.setDefaultColor()
		})
	}

	layerStyleType = () => {
		let layerType = this.getValue("dataType")
		if (layerType === "symbol") {
			layerType = "icon"
		}
		return layerType
	}

	highlightOnMap = (): void => {}
	clearHighlightOnMap = (): void => {}
	selectOnMap = (): void => {}
}
