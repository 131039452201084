import * as React from "react"
import { Button, Icon, Modal } from "semantic-ui-react"

import { registerforStateChanges, removeStateChanges, setGlobalState } from "../store"

interface IUnsavedItems {
	items: Array<any>
}

// TODO this modal should tell the user what items they have not saved
const SaveEditModal = () => {
	const [open, setOpen] = React.useState(false)
	const [unsavedItems, setUnsavedItems] = React.useState<IUnsavedItems>({
		items: [],
	})

	React.useEffect(() => {
		registerforStateChanges(handleOpenChange)

		return () => {
			removeStateChanges(handleOpenChange)
		}
	})

	const handleOpenChange = (type: string, key: string, val: any) => {
		if (key === "editngModalActive") {
			setOpen(val.getValue())
		}

		if (key === "unsavedEditingFeatures") {
			setUnsavedItems({ items: val.getValue() })
		}
	}

	const handleClose = () => {
		setGlobalState("editngModalActive", false)
		setOpen(false)
	}

	const handleOpen = () => {
		setGlobalState("editngModalActive", true)
		setOpen(true)
	}

	return (
		<Modal basic size="small" onClose={() => handleClose()} onOpen={() => handleClose()} open={open}>
			<Modal.Header>You Have {unsavedItems.items.length} Unsaved Changes</Modal.Header>
			<Modal.Content>
				<Modal.Actions>
					<Button basic color="red" inverted onClick={() => handleClose()}>
						<Icon name="remove" /> Discard Changes
					</Button>
					<Button color="green" inverted onClick={() => handleOpen()}>
						<Icon name="checkmark" /> Save Changes
					</Button>
				</Modal.Actions>
			</Modal.Content>
		</Modal>
	)
}

export default SaveEditModal
