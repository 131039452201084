import { printMessage } from "../../providers/remoteHQ"
import { storedItem } from "../storedItem"
import { CallbackHandler } from "../storeType"
import { messageLevels } from "../types"

export interface ISelectionItem {
	item: storedItem
	index: number
	selected: boolean
}

export class SelectionItemList {
	items: selectionItem[] = []
	changeCount: number = 0
	selectionDefault: boolean = false

	// TODO insert list update subscribers.
	// build list update handlers
	callbacks: CallbackHandler = new CallbackHandler("SelectionItemList")

	constructor(items: storedItem[], defaultValue?: boolean) {
		// const theDefault:boolean = (defaultValue !== null && defaultValue !== undefined && defaultValue === true);
		if (defaultValue !== undefined) {
			this.selectionDefault = defaultValue
		}
		if (items) {
			items.forEach((element) => {
				this.addItem(element)
			})
		}
	}

	get length() {
		return this.items.length
	}

	addItem(newItem: storedItem) {
		const item: selectionItem = new selectionItem(newItem, this.items.length, this.selectionDefault ?? false)
		this.items.push(item)
	}

	mergeItems = (newItems: storedItem[]) => {
		const existingKeys = this.allPrimaryKeys()

		newItems.forEach((item) => {
			if (!existingKeys.includes(item.primaryKey)) {
				this.addItem(item)
			}
		})
		this.changeCount += 1
	}

	selectedFlagsArray = (): boolean[] => {
		const theFlags: Array<boolean> = []
		this.items.forEach((element) => {
			theFlags.push(element.selected)
		})
		return theFlags
	}

	selectedFlagsString = (): string => {
		const theFlags: Array<string> = []
		this.items.forEach((element) => {
			theFlags.push(element.selected ? "1" : "0")
		})
		const theFlagsString = theFlags.join(",")
		return theFlagsString
	}

	selectedStoredItems = (): storedItem[] => {
		const itemArray: storedItem[] = []
		this.items.forEach((element) => {
			if (element.selected) {
				itemArray.push(element.item)
			}
		})
		return itemArray
	}

	selectedPrimaryKeys = (): any[] => {
		const itemArray: any[] = []
		this.items.forEach((element) => {
			if (element.selected) {
				itemArray.push(element.item.primaryKey())
			}
		})
		return itemArray
	}

	allPrimaryKeys = (): any[] => {
		const itemArray: any[] = []
		this.items.forEach((element) => {
			itemArray.push(element.item.primaryKey())
		})
		return itemArray
	}

	keepChangesOnSelected = (): storedItem[] => {
		const itemArray: storedItem[] = []
		this.items.forEach((element) => {
			if (element.selected) {
				const theNewItem = element.item.keepChanges()
				if (theNewItem) {
					element.item = theNewItem
				}
				itemArray.push(element.item)
			}
		})
		this.callbacks.doEventCallbacks("", null)
		return itemArray
	}

	keepChangesOnAll = (): storedItem[] => {
		const itemArray: storedItem[] = []
		this.items.forEach((element) => {
			const theNewItem = element.item.keepChanges()
			if (theNewItem) {
				element.item = theNewItem
			}
			itemArray.push(element.item)
		})
		this.callbacks.doEventCallbacks("", null)
		return itemArray
	}

	keepChangesOnSelectedMaintainEdit = (): storedItem[] => {
		const itemArray: storedItem[] = []
		this.items.forEach((element) => {
			if (element.selected) {
				const theNewItem = element.item.keepChanges()
				if (theNewItem) {
					const editItem = theNewItem.editCopy()
					if (editItem && theNewItem) {
						element.item = editItem
					} else {
						element.item = theNewItem
					}
				}
				itemArray.push(element.item)
			}
		})
		this.callbacks.doEventCallbacks("", null)
		return itemArray
	}
	keepChangesOnAllMaintainEdit = (): storedItem[] => {
		const itemArray: storedItem[] = []
		this.items.forEach((element) => {
			const theNewItem = element.item.keepChanges()
			if (theNewItem) {
				const editItem = theNewItem.editCopy()
				if (editItem && theNewItem) {
					element.item = editItem
				} else {
					element.item = theNewItem
				}
			}
			itemArray.push(element.item)
		})
		this.callbacks.doEventCallbacks("", null)
		return itemArray
	}

	deselectedStoredItems = (): storedItem[] => {
		const itemArray: storedItem[] = []
		this.items.forEach((element) => {
			if (!element.selected) {
				itemArray.push(element.item)
			}
		})
		return itemArray
	}
	selectedItems = (): selectionItem[] => {
		const itemArray: selectionItem[] = []
		this.items.forEach((element) => {
			if (element.selected) {
				itemArray.push(element)
			}
		})
		return itemArray
	}
	deselectedItems = (): selectionItem[] => {
		const itemArray: selectionItem[] = []
		this.items.forEach((element) => {
			if (!element.selected) {
				itemArray.push(element)
			}
		})
		return itemArray
	}
	toggle(item: selectionItem) {
		item.toggle()
		this.callbacks.doEventCallbacks("toggle", item)
		this.changeCount += 1
	}
}
export class selectionItem implements ISelectionItem {
	item: storedItem
	index = 0
	selected = false

	constructor(item: storedItem, index: number, selected: boolean) {
		this.item = item
		this.index = index
		this.selected = selected
	}
	toggle = () => {
		printMessage("Toggling " + this.index + " from  " + this.selected, messageLevels.debug)
		this.selected = !this.selected
	}
}
