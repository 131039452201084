import { Map } from "mapbox-gl"

import { mapLayer } from "../store/classes/mapLayer"

export interface IPulse {
	steps?: number
	minWidth?: number
	maxOpacity?: number
	widthMultiplier: number
	opacityMultiplier: number
	widthKey?: string
	opacityKey?: string
	key?: string[] | string
	ms?: number
}

export interface Pulse {
	id: string
	stop: () => void
	start: () => void
	setOpacity: (opacity: number) => void
	setWidth: (width: number) => void
}

export const createPulse = (map: Map, layer: mapLayer): Pulse => {
	let step = 0
	const {
		steps: totalSteps = 14,
		minWidth: defaultWidth = 0,
		maxOpacity: defaulOpacity = 0.5,
		widthMultiplier,
		opacityMultiplier,
		widthKey = "circle-stroke-width",
		opacityKey = "circle-stroke-opacity",
		key = null,
		ms = 100,
	} = layer.getValue("pulse") as IPulse
	const getMinOpacity = () => maxOpacity * opacityMultiplier
	const getMaxWidth = () => minWidth * widthMultiplier

	let maxOpacity = defaulOpacity
	let minOpacity = getMinOpacity()

	let minWidth = defaultWidth
	let maxWidth = getMaxWidth()

	const sizePerStep = (maxWidth - minWidth) / totalSteps
	const getOpacityPerStep = () => (maxOpacity - minOpacity) / totalSteps
	const getPulseWidth = () => Math.round((minWidth + step * sizePerStep) * 100) / 100
	const getPulseOpacity = () => Math.round((maxOpacity - step * getOpacityPerStep()) * 100) / 100
	let interval: string | number | NodeJS.Timer | undefined | null
	const pulse = () => {
		if (step === totalSteps) step = 0
		step += 1
		map.setPaintProperty(layer.getValue("id"), widthKey, [
			"interpolate",
			["linear"],
			key,
			0,
			minWidth,
			1,
			maxWidth < 0 ? Math.max(getPulseWidth(), maxWidth) : Math.min(getPulseWidth(), maxWidth),
		])
		map.setPaintProperty(layer.getValue("id"), opacityKey, [
			"interpolate",
			["linear"],
			key,
			0,
			maxOpacity,
			1,
			Math.max(getPulseOpacity(), minOpacity),
		])
	}
	const start = async () => {
		await map.once("idle")
		interval = setInterval(pulse, ms)
	}

	const setOpacity = (opacity: number) => {
		if (opacity < 0) opacity = 0
		minOpacity = Math.min(opacity, getMinOpacity())
		maxOpacity = opacity
	}

	const setWidth = (width: number) => {
		if (width < 0) width = 0
		maxWidth = Math.max(width, getMaxWidth())
		minWidth = width
	}

	const stop = () => {
		if (interval) clearInterval(interval)
		interval = null
	}

	start()

	return {
		id: layer.getValue("id"),
		stop,
		start,
		setOpacity,
		setWidth,
	}
}
