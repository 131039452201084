import React from "react"
import {
	Button,
	Checkbox,
	Divider,
	Dropdown,
	DropdownItemProps,
	Form,
	Grid,
	Header,
	Icon,
	Label,
	LabelProps,
	Loader,
	Menu,
	Popup,
	Segment,
	SemanticShorthandItem,
	Sidebar,
} from "semantic-ui-react"
import DatePicker from "react-datepicker"
import moment from "moment"

import Select from "../../../components/Select"
import variables, { VISUALISE_BY } from "../../../variables"
import "../range-slider.css"
import ListComponent from "../../../mapsight/ListComponent"
import FeedListComponent from "../../../mapsight/FeedListComponent"
import {
	call,
	getGlobalState,
	getStateValue,
	isStoredItem,
	itemByKey,
	registerforStateChanges,
	removeStateChanges,
	requestLoad,
	setGlobalState,
} from "../../../store"
import { storedItem } from "../../../store/storedItem"
import { forestBlock } from "../../../store/classes/forestBlock"
import { printData, printMessage } from "../../../providers/remoteHQ"
import { IQueryParams, ISortParams, messageLevels, sortDirection, sortMethod } from "../../../store/types"
import { getDataFeedByName } from "../../../store/dataFeed"
import FeedMapComponent from "../../../mapsight/FeedMapComponent"
import SearchBar from "../../../components/SearchBar"

import "react-datepicker/dist/react-datepicker.css"

import LayerGroupAccordion from "../../../mapsight/LayerGroupAccordion"
import RangeSlider from "../../../components/Slider"
import { mapLayer } from "../../../store/classes/mapLayer"

const { skidComplianceOptions: compliance, CompletionPeriods: completion, Priority: priority } = variables
interface SearchLayerProps {
	visible: boolean
	onSelect: () => any
}

interface SearchLayerState {
	visualiseBy: string
	sortByName: boolean
	sortAsc: boolean
	filterDropdownOpen: number
	layerOpacity: number
	textToFind: string
	isShowFilter: boolean
	dateRange: string
	regions: storedItem | undefined | null
	level: storedItem | undefined | null
	completionStates: any[]
	complianceFilter: any[]
	completionFilter: any[]
	managementCo: any
	manager: any
	aoc: any[]
	aoe: any[]
	aof: number
	aocLoaded: boolean
	aoeLoaded: boolean
	priorityFilter: any[]
	loggedIn: boolean
	datePickerRange: (Date | null)[]
	isLoading: boolean
}

interface FilterArgs {
	name: string
	source: typeof compliance | typeof completion | typeof priority
	renderLabel?: (arg: any) => SemanticShorthandItem<LabelProps>
	onClick?: (e?: any, data?: any) => void
	checked: (key: string) => boolean
	customKeyValue?: { [key: string]: any }
}

export default class SearchLayer extends React.Component<SearchLayerProps, SearchLayerState> {
	state = {
		visualiseBy: "Compliance",
		sortByName: true,
		sortAsc: true,
		filterDropdownOpen: 0,
		layerOpacity: 100,
		textToFind: "",
		isShowFilter: false,
		dateRange: "",
		regions: null,
		level: null,
		completionStates: [],
		complianceFilter: Object.keys(compliance),
		completionFilter: Object.keys(completion),
		managementCo: [],
		manager: [],
		aoc: [],
		aoe: [],
		aof: 0,
		aocLoaded: false,
		aoeLoaded: false,
		priorityFilter: Object.keys(priority),
		loggedIn: getGlobalState("loginState")?.getValue("isLoggedIn"),
		datePickerRange: [null, null],
		isLoading: true,
	}
	queryParams: IQueryParams = {
		stringParams: ["consent_number", "forest_name"],
		stringToFind: this.state.textToFind,
		sort: {
			sortField: "",
			sortDirection: this.state.sortAsc ? sortDirection.ascending : sortDirection.descending,
		},
	}
	dataFeed = getDataFeedByName("forest-blocks", true)
	loadedFromHash = false
	dataLoaded = (type: string, key?: string, data?: storedItem) => {
		printMessage("Loaded: " + type, messageLevels.verbose)
		if (type === "areas-of-concern/retrieve") {
			if (this.dataFeed) {
				this.dataFeed.clearIncomingValues()
				this.setState({ aocLoaded: true })
			}
		} else if (type === "areas-of-excellence/retrieve") {
			if (this.dataFeed) {
				this.dataFeed.clearIncomingValues()
				this.setState({ aoeLoaded: true })
			}
		}
	}
	stateChanged = (type: string, key?: string, data?: storedItem) => {
		if (key === "level") {
			this.setState({ level: data })
		} else if (key === "regions") {
			this.setState({ regions: data })
		}
		if (key === "searchLayerState") {
			this.retreiveFromHash(data)
		}
		if (key === "loginState") {
			this.setState({ loggedIn: data?.getValue("isLoggedIn") })
		}
	}

	componentDidMount() {
		registerforStateChanges(this.userChanged)
		registerforStateChanges(this.stateChanged)
		requestLoad("areas-of-concern", undefined, this.dataLoaded)
		requestLoad("areas-of-excellence", undefined, this.dataLoaded)
		this.dataFeed.setupSource("forest-blocks", {})

		this.setState({ layerOpacity: this.getOpacity() })
		this.getForestBlockProperties(false)
		this.retreiveFromHash()
	}

	componentWillUnmount() {
		removeStateChanges(this.userChanged)
	}

	retreiveFromHash = (data?: any) => {
		const isFromHash = getGlobalState("isFromHash")?.getValue()
		let state = data?.getValue() || getGlobalState("searchLayerState")?.getValue()
		if (isFromHash && !this.loadedFromHash && typeof state === "string") {
			this.loadedFromHash = true
			state = JSON.parse(state)
			this.setState(state, this.getForestBlockProperties)
		}
	}

	userChanged = (type: string, key: string, data: any) => {
		if (key === "loginState") {
			this.setState({ loggedIn: data.getValue("isLoggedIn") })
		}
	}

	updateVisualiseBy = (visualiseBy: string) => {
		this.setState({ visualiseBy })
	}

	updateSearchFilter = (value: string) => {
		this.queryParams.stringToFind = value
		this.getForestBlockProperties()
	}

	setFilterDropdownOpen = (filterDropdownOpen: number) => {
		this.setState({ filterDropdownOpen })
	}

	setLayerOpacity = (layerOpacity: number) => {
		this.setState({ layerOpacity })
		call("setPulseOpacity", "forest-blocks-pulse", layerOpacity / 100)
		call("setPulseOpacity", "forest-blocks-centroids-pulse", layerOpacity / 100)
		call("setLayerOpacity", "forest-blocks-fill", ["interpolate", ["linear"], ["zoom"], 6, 0, 8, layerOpacity / 300])
		call("setLayerOpacity", "forest-blocks-line", layerOpacity / 100)
		call("setLayerOpacity", "forest-blocks-centroids", layerOpacity / 100)
	}

	dropDownValuesFromIncomingData(key: string) {
		const theValues: string[] = this.dataFeed.valuesIncoming(key)
		const theOptions: DropdownItemProps[] = []
		theValues.forEach((value) => {
			theOptions.push({
				key: value,
				text: value,
				value: value,
			})
		})
		return theOptions
	}

	updateComplianceStates() {}
	getForestBlockProperties = (saveToStore = true) => {
		const sort: ISortParams = this.queryParams.sort || {}

		sort.sortField = this.state.sortByName ? "forest_name" : "consent_number"
		sort.sortDirection = this.state.sortAsc ? sortDirection.ascending : sortDirection.descending
		sort.sortMethod = this.state.sortByName ? sortMethod.alpha : sortMethod.numeric

		this.queryParams.sort = sort
		this.queryParams.queries = []
		const { complianceFilter, completionFilter, priorityFilter, dateRange } = this.state
		if (this.state.completionStates.length > 0) {
			this.queryParams.queries.push(
				"compliance_status IN [" +
					this.state.completionStates
						.filter(function (val) {
							return typeof val === "string"
						})
						.join(",") +
					"]",
			)
		}
		if (dateRange) {
			const dates = dateRange.split(" - ")
			printData(dates, messageLevels.debug, "Date range")
			this.queryParams.queries.push(`end_date BETWEEN [${dates}]`)
		}
		if (complianceFilter.length > 0) {
			const tmpComplianceFilter: any[] = [...complianceFilter]
			const complianceFilterNoNull = tmpComplianceFilter
				.filter((val) => {
					return val !== null && val.length > 0
				})
				.join(",")
			if (complianceFilterNoNull.length > 0) {
				this.queryParams.queries.push("getComplianceStatusCode IN [" + complianceFilterNoNull + "]")
			}
		}
		if (completionFilter.length > 0) {
			const age = completionFilter.map((filter) => {
				switch (filter) {
					case "WP":
						return -1
					case "CO":
						return 0
					default:
						return filter.split("")[1]
				}
			})
			this.queryParams.queries.push(`age IN [${age}]`)
		}
		if (priorityFilter.length > 0) {
			const tmpPriorityFilter: any[] = [...priorityFilter]

			const tmpPriorityFilterNoNull = tmpPriorityFilter
				.filter(function (val) {
					return val !== null && val.length > 0
				})
				.join(",")
			if (tmpPriorityFilterNoNull.length > 0) {
				this.queryParams.queries.push("getPriorityCode IN [" + tmpPriorityFilterNoNull + "]")
			}
		}
		if (this.state.managementCo.length) {
			printData(this.state.managementCo, messageLevels.verbose, "this.state.managementCo")
			this.queryParams.queries.push(`management_company IN ${this.state.managementCo}`)
		}
		if (this.state.manager.length) {
			printData(this.state.manager, messageLevels.verbose, "this.state.manager")
			this.queryParams.queries.push(`Manager IN ${this.state.manager}`)
		}
		if (this.state.aoe.length) {
			const theFilter: any[] = ["match", ["get", "description"], this.state.aoe, true, false]

			;(itemByKey("map-layers", "areas-of-excellence-search") as mapLayer)?.setFilter(theFilter)
			;(itemByKey("map-layers", "areas-of-excellence-search") as mapLayer)?.show()
		} else {
			;(itemByKey("map-layers", "areas-of-excellence-search") as mapLayer)?.hide()
		}
		if (this.state.aoc.length) {
			const theFilter: any[] = ["match", ["get", "description"], this.state.aoc, true, false]

			;(itemByKey("map-layers", "areas-of-concern-search") as mapLayer)?.setFilter(theFilter)
			;(itemByKey("map-layers", "areas-of-concern-search") as mapLayer)?.show()
		} else {
			;(itemByKey("map-layers", "areas-of-concern-search") as mapLayer)?.hide()
		}
		if (this.state.aof && this.state.aof > 0) {
			;(itemByKey("map-layers", "areas-of-failure-search") as mapLayer)?.show()
		} else {
			;(itemByKey("map-layers", "areas-of-failure-search") as mapLayer)?.hide()
		}
		printData(this.queryParams, messageLevels.verbose)
		this.dataFeed.interactiveSettings(this.queryParams)
		saveToStore && setGlobalState("searchLayerState", JSON.stringify(this.state))
	}

	renderVisualiseBy = () => {
		// TODO: add auth
		const { visualiseBy, sortByName, sortAsc, loggedIn } = this.state
		const isAuthenticated = loggedIn
		return (
			<>
				<Header as="h4">View Interative Blocks as</Header>
				<Menu compact inverted borderless>
					<Form.Group inline>
						{VISUALISE_BY.map((item, i) => {
							if (item.needAuth && !isAuthenticated) return null
							return (
								<Checkbox
									className="visualise-by blue-radio"
									radio
									key={`visualisedBy_${i}`}
									label={item.name}
									checked={visualiseBy === item.name}
									onClick={() => this.updateVisualiseBy(item.name)}
								/>
							)
						})}
					</Form.Group>
					<Menu id="sort-by-toggle" className="right" compact inverted borderless>
						<Popup
							trigger={
								<Menu.Item
									// icon
									color={"blue"}
									active={sortByName}
									onClick={() =>
										this.setState(
											{
												sortByName: true,
												sortAsc: sortByName ? !sortAsc : true,
											},
											() => this.getForestBlockProperties(),
										)
									}
								>
									<Icon name={sortByName && sortAsc ? "sort alphabet up" : "sort alphabet down"} />
								</Menu.Item>
							}
							content="Sort by block name"
						/>
						<Popup
							trigger={
								<Menu.Item
									// icon
									active={!sortByName}
									color={"blue"}
									onClick={() =>
										this.setState(
											{
												sortByName: false,
												sortAsc: sortByName ? true : !sortAsc,
											},
											() => this.getForestBlockProperties(),
										)
									}
								>
									<Icon name={!sortByName && sortAsc ? "sort numeric up" : "sort numeric down"} />
								</Menu.Item>
							}
							content="Sort by consent number"
						/>
					</Menu>
				</Menu>
			</>
		)
	}

	renderFilterCheckBlock = ({ name, source, renderLabel, onClick, checked }: FilterArgs) => {
		const { visualiseBy } = this.state
		return (
			<>
				<Header as="h4">Filter Block {name}</Header>
				<Divider />
				<Grid columns={2} padded>
					{Object.values(source).map((option) => {
						return (
							<Grid.Column key={option.KEY.toLowerCase()} style={{ padding: 0 }}>
								<Checkbox
									onClick={onClick}
									checked={checked(option.KEY)}
									value={option.KEY}
									label={
										<Dropdown.Item
											className={`filter-checkbox${visualiseBy === name ? "" : " colourless"}`}
											label={renderLabel ? renderLabel(option) : { color: option.color.name }}
											text={option.definition}
										/>
									}
								/>
							</Grid.Column>
						)
					})}
				</Grid>
			</>
		)
	}
	renderFilterDropdownBlock = (
		name: string,
		options: DropdownItemProps[],
		onChange: any,
		selected: any,
		icon?: string,
		multiple?: boolean,
	) => {
		if (icon) {
			return (
				<Select
					label={
						<label>
							<Icon name={icon as any} />
							{name}{" "}
						</label>
					}
					placeholder="-"
					name={name}
					options={options}
					onChange={(e, data) => onChange(data.value)}
					value={selected}
					multiple={multiple}
				/>
			)
		}
		return (
			<Select
				label={<label>{name}</label>}
				placeholder="-"
				name={name}
				options={options}
				onChange={(e, data) => onChange(data.value)}
				value={selected}
				multiple={multiple}
			/>
		)
	}

	renderComplianceFilter = () => {
		return this.renderFilterCheckBlock({
			name: "Compliance",
			source: compliance,
			checked: (key) => this.state.complianceFilter.includes(key),
			onClick: (e, data) => {
				const { complianceFilter } = this.state
				const newFilter = [...complianceFilter]
				if (data.checked) {
					newFilter.push(data.value)
				} else {
					newFilter.splice(newFilter.indexOf(data.value), 1)
				}
				this.setState({ complianceFilter: newFilter }, this.getForestBlockProperties)
			},
		})
	}
	renderLayerControls = () => {
		return <LayerGroupAccordion location="block-search" />
	}

	renderCompletionFilter = () => {
		return this.renderFilterCheckBlock({
			name: "Completion",
			source: completion,
			renderLabel: (option) => <div className="ui label" style={{ backgroundColor: option.color.code }}></div>,
			checked: (key) => this.state.completionFilter.includes(key),
			onClick: (e, data) => {
				const { completionFilter } = this.state
				const newFilter = [...completionFilter]
				if (data.checked) {
					newFilter.push(data.value)
				} else {
					newFilter.splice(newFilter.indexOf(data.value), 1)
				}
				this.setState({ completionFilter: newFilter }, this.getForestBlockProperties)
			},
		})
	}

	renderPriorityFilter = () => {
		return (
			<Segment inverted basic>
				{this.renderFilterCheckBlock({
					name: "Priority",
					source: priority,
					checked: (key) => this.state.priorityFilter.includes(key),
					onClick: (e, data) => {
						const { priorityFilter } = this.state
						const newFilter = [...priorityFilter]
						if (data.checked) {
							newFilter.push(data.value)
						} else {
							newFilter.splice(newFilter.indexOf(data.value), 1)
						}
						this.setState({ priorityFilter: newFilter }, this.getForestBlockProperties)
					},
				})}
			</Segment>
		)
	}

	renderDateRange = () => {
		const { datePickerRange } = this.state
		return (
			<>
				<DatePicker
					selectsRange
					isClearable
					startDate={datePickerRange[0]}
					endDate={datePickerRange[1]}
					placeholderText="Start Date - End Date"
					dateFormat="dd MMMM yyyy"
					onChange={([start, end]) => {
						const startDate = start ? moment(start).format("DD MMMM yyyy") : null
						const endDate = end ? moment(end).format("DD MMMM yyyy") : null
						const dateRange = start || end ? `${startDate} - ${endDate}` : ""
						this.setState({ dateRange, datePickerRange: [start, end] }, this.getForestBlockProperties)
					}}
				/>
			</>
		)
	}
	renderManagementCoFilter = () => {
		const managementCoOptions = this.dropDownValuesFromIncomingData("management_company")

		return (
			<>
				{this.renderFilterDropdownBlock(
					"Management Company",
					managementCoOptions,
					this.managementCoChange,
					this.state.managementCo,
					undefined,
					true,
				)}
			</>
		)
	}
	renderManagerFilter = () => {
		const managerOptions = this.dropDownValuesFromIncomingData("Manager")

		return (
			<Segment inverted basic>
				{this.renderFilterDropdownBlock("Manager", managerOptions, this.managerChange, this.state.manager, undefined, true)}
			</Segment>
		)
	}
	renderAOCFilter = () => {
		const aocOptions = this.dropDownValuesFromIncomingData("areasOfConcern.description")

		return (
			<Segment inverted basic>
				{this.renderFilterDropdownBlock("Areas Of Concern", aocOptions, this.aocChange, this.state.aoc, undefined, true)}
			</Segment>
		)
	}
	renderAOEFilter = () => {
		const aoeOptions = this.dropDownValuesFromIncomingData("areasOfExcellence.description")

		return (
			<Segment inverted basic>
				{this.renderFilterDropdownBlock("Areas Of Excellence", aoeOptions, this.aoeChange, this.state.aoe, undefined, true)}
			</Segment>
		)
	}
	renderAOFFilter = () => {
		return (
			<Segment inverted basic>
				<Checkbox
					onClick={this.aofChange}
					checked={this.state.aof !== 0}
					label={
						<Dropdown.Item
							className={`filter-checkbox colourless`}
							label={<div className="ui label"></div>}
							text={"Areas of Failure"}
						/>
					}
					value={"aof"}
				/>
			</Segment>
		)
	}
	managerChange = (value: any) => {
		this.setState({ manager: value }, () => this.getForestBlockProperties())
	}
	managementCoChange = (value: any) => {
		this.setState({ managementCo: value }, () => this.getForestBlockProperties())
	}
	aocChange = (value: any) => {
		this.setState({ aoc: value }, () => this.getForestBlockProperties())
	}
	aoeChange = (value: any) => {
		this.setState({ aoe: value }, () => this.getForestBlockProperties())
	}
	aofChange = (value: any) => {
		const currentState = this.state.aof === 1
		const newState = currentState ? 0 : 1

		this.setState({ aof: newState }, () => this.getForestBlockProperties())
	}

	renderFilter = () => {
		const { loggedIn } = this.state
		const isAuthenticated = loggedIn
		const isAreaFilterEnabled = false // disabling area filters due to unreliability

		return (
			<>
				<Segment basic>{this.renderDateRange()}</Segment>
				<Segment inverted basic>
					{this.renderVisualiseBy()}
				</Segment>
				<Segment inverted basic>
					{this.renderComplianceFilter()}
				</Segment>
				<Segment inverted basic>
					{this.renderCompletionFilter()}
				</Segment>
				{isAuthenticated && this.renderPriorityFilter()}
				<Segment inverted basic>
					{this.renderManagementCoFilter()}
				</Segment>

				{isAuthenticated && this.renderManagerFilter()}

				{isAreaFilterEnabled && isAuthenticated && this.renderAOCFilter()}
				{isAreaFilterEnabled && isAuthenticated && this.renderAOEFilter()}
				{isAreaFilterEnabled && isAuthenticated && this.renderAOFFilter()}
				<Segment inverted basic>
					{this.renderLayerControls()}
					<Header as="h4">Transparency Range Slider</Header>
					<Divider />
					<RangeSlider min={1} max={100} value={this.getOpacity()} onChange={this.setLayerOpacity} minDistance={1} />
				</Segment>
			</>
		)
	}

	getOpacity = (): number => {
		const theOpacity = call("getLayerOpacity", "forest-blocks-fill")
		const forestBlocksFillOpacityMultiplier = 3
		let opacityNumber = 100

		if (Array.isArray(theOpacity) && theOpacity[0] === "interpolate") {
			opacityNumber = theOpacity[theOpacity.length - 1] * 100 * forestBlocksFillOpacityMultiplier
		} else {
			opacityNumber = parseFloat(theOpacity) * 100 * forestBlocksFillOpacityMultiplier
		}

		return Math.round(opacityNumber)
	}

	cellFormatter = (item: storedItem, index: any, hovered: boolean) => {
		const theBlock = item as forestBlock
		const complianceStatus = theBlock.getComplianceStatus()
		return (
			<Menu.Item
				link
				key={`block_${theBlock.primaryKey()}`}
				onMouseOver={() => call("setRolloverByItem", theBlock)}
				onMouseLeave={() => call("setRolloverByItem", null)}
				onClick={() => {
					theBlock.selectOnMap()
					this.props.onSelect()
				}}
				active={theBlock.isSelected() || hovered}
			>
				{theBlock.getValue("forest_name")}
				<small>&nbsp;({theBlock.formattedConsentNum()})</small>
				<small>
					&nbsp;(
					{theBlock.getValue("end_date") ? moment(theBlock.getValue("end_date")).format("yyyy") : "Unknown"})
				</small>
				<Label color={complianceStatus.color.name}>{complianceStatus.definition}</Label>
			</Menu.Item>
		)
	}

	regionCellFormatter = (item: storedItem) => {
		const theName = item.getValue("english_name")
		const theRegion = isStoredItem(item) ? item.getRegionISO() : "NZL"
		const theQuery = "getRegionISO === " + theRegion
		return (
			<FeedListComponent
				header={theName}
				key={"region" + theRegion}
				dataFeedName="forest-blocks"
				queryParams={{ queries: [theQuery] }}
				cellFormatter={this.cellFormatter}
				globalHover
			/>
		)
	}

	render() {
		const { isShowFilter, isLoading } = this.state
		return (
			<Sidebar id="search-layer" animation="overlay" icon="labeled" width="very wide" visible={this.props.visible}>
				{isLoading ? <Loader active /> : null}
				<SearchBar active={this.props.visible} onChange={(e, { value }) => this.updateSearchFilter(value)} />
				<Segment inverted basic>
					<Button id="filter-toggle" primary onClick={() => this.setState({ isShowFilter: !isShowFilter })}>
						<Icon name={isShowFilter ? "angle up" : "angle down"} />
						9 filters
						<Icon name="sliders horizontal" />
					</Button>
				</Segment>
				{isShowFilter && this.renderFilter()}
				<Segment inverted basic className="search-layer-blocks">
					<Header as="h4">{"Blocks"}</Header>
					<Divider />
					<ListComponent
						dataSourceName="boundaries"
						queryParams={{
							queries: ["iso_subdivision_category === region"],
							sort: {
								sortField: "english_name",
							},
						}}
						onLoad={() => setTimeout(() => this.setState({ isLoading: false }), 3000)}
						cellFormatter={this.regionCellFormatter}
					/>
				</Segment>
				<FeedMapComponent
					key={"mapHandler"}
					dataFeedName="forest-blocks"
					castAs="string"
					mapFilters={[
						{ layerName: "forest-blocks-line", property: "consent_number" },
						{ layerName: "forest-blocks-fill", property: "consent_number" },
						{ layerName: "forest-blocks-centroids", property: "consent_number" },
						{
							layerName: "forest-blocks-centroids-pulse",
							property: "consent_number",
						},
					]}
					visualiseBy={this.state.visualiseBy}
					queryParams={{}}
				/>
			</Sidebar>
		)
	}
}
