import { blockFeature } from "./blockFeature"
import { printData } from "../../providers/remoteHQ"
import { messageLevels } from "../types"
import { keyNotSet } from "../helpers"

export class task extends blockFeature {
	defaultValues(): { [id: string]: any } {
		const superValues = super.defaultValues()
		const allValues = { status: "pending", ...superValues }
		printData(allValues, messageLevels.debug, "allValues in default values")
		return allValues
	}

	validForSave = (): boolean => {
		if (keyNotSet(this, "geojson")) return false
		if (keyNotSet(this, "description")) return false
		if (keyNotSet(this, "due_date")) return false
		return true
	}

	validationErrorForKey = (key: string): any => {
		switch (key) {
			case "due_date":
			case "description":
			case "geojson":
				return keyNotSet(this, key)

			default:
				return null
		}
	}
}
