import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { Icon } from "semantic-ui-react"

import { getClassNamePrefix } from "../.."

interface ImageLightboxProps {
	images: string[]
	isOpen: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	currentIndex: number
	setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
	transition?: string
	index?: number
	description?: string
	themeColor?: string
}

const ImageLightbox: React.FC<ImageLightboxProps> = ({ images, isOpen, setOpen, currentIndex, setCurrentIndex, themeColor }) => {
	const iconStyle = {
		width: "40px",
		height: "40px",
		position: "fixed",
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		color: themeColor || "white",
	}

	const closeLightbox = () => {
		setOpen(false)
	}

	const goToPrevious = () => {
		setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1))
	}

	const goToNext = () => {
		setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1))
	}

	useEffect(() => {
		const handleKeyPress = (event: KeyboardEvent) => {
			if (event.key === "ArrowLeft") {
				goToPrevious()
			} else if (event.key === "ArrowRight") {
				goToNext()
			}
		}

		window.addEventListener("keydown", handleKeyPress)

		return () => {
			window.removeEventListener("keydown", handleKeyPress)
		}
	}, [])

	return (
		<>
			{isOpen &&
				ReactDOM.createPortal(
					<>
						<div
							className={getClassNamePrefix()}
							style={{
								...(iconStyle as React.CSSProperties),
								top: "50%",
								left: "50px",
								transform: "translate(-50%, -50%)",
								zIndex: 9999,
							}}
							onClick={goToPrevious}
						>
							<Icon size="large" name="arrow left" style={{ color: iconStyle.color }} />
						</div>
						<div
							style={{
								...(iconStyle as React.CSSProperties),
								top: "50%",
								right: "50px",
								transform: "translate(-50%, -50%)",
								zIndex: 9999,
							}}
							onClick={goToNext}
						>
							<Icon size="large" name="arrow right" style={{ color: iconStyle.color }} />
						</div>
						<div
							style={{
								...(iconStyle as React.CSSProperties),
								top: "50px",
								right: "50px",
								transform: "translate(-50%, -50%)",
								zIndex: 9999,
							}}
							onClick={closeLightbox}
						>
							<Icon size="large" name="close" style={{ color: iconStyle.color }} />
						</div>
						<div
							style={{
								position: "fixed",
								top: 0,
								left: 0,
								width: "100%",
								height: "100%",
								backgroundColor: "rgba(0, 0, 0, 0.8)",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								zIndex: 9900,
								transition: "opacity 0.7s ease-in-out",
							}}
							onClick={closeLightbox}
						>
							<img
								src={images[currentIndex]}
								alt={`Image ${currentIndex}`}
								style={{
									maxWidth: "70vw",
									maxHeight: "70vh",
								}}
							/>
							<div style={{ color: iconStyle.color, paddingTop: "5px" }}>{currentIndex + 1 + "/" + images.length}</div>
						</div>
					</>,
					document.body,
				)}
		</>
	)
}

export default ImageLightbox
