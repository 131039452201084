import React from "react"

import { panoramaFeature } from "../../../store/classes/panoramaFeature"

import "./styles.css"
import { Modal } from "semantic-ui-react"

import { CustomModal } from "../../CustomModal"

interface IPanoramaFeatureModalProps {
	feature: panoramaFeature
	handleClose: () => void
}

export const PanoramaFeatureModal = ({ feature, handleClose }: IPanoramaFeatureModalProps) => {
	const imageLinks: URL[] = feature.imageLinks() || []
	let panoramaLink = ""
	if (imageLinks.length > 0) {
		const imageLink = imageLinks[0]
		panoramaLink = imageLink.toString()
	}

	return (
		<CustomModal handleClose={handleClose} open>
			<Modal.Header>
				<h3>{feature.friendlyDisplayName}</h3>
			</Modal.Header>
			<Modal.Content>
				<iframe title={panoramaLink} frameBorder={0} className="content-iframe" src={panoramaLink}></iframe>
			</Modal.Content>
		</CustomModal>
	)
}
