import React, { useEffect, useState } from "react"
import { Button, Form, Image, Message } from "semantic-ui-react"

import * as Cognito from "../../providers/cognito"
import { getGlobalState, getUIConfig, isNgatiApa, registerforStateChanges, removeStateChanges } from "../../store"
import { messageLevels } from "../../store/types"
import { printMessage } from "../../providers/remoteHQ"

const DuotoneBackground: React.FC = () => {
	const containerStyle: React.CSSProperties = {
		position: "fixed",
		height: "100%",
		width: "100%",
		top: 0,
		left: 0,
		backgroundColor: "#FFBF3F",
		display: "flex",
		flex: "1 1 100%",
		overflow: "hidden",
		padding: "0px",
		opacity: "0.8",
	}

	const imgStyle: React.CSSProperties = {
		filter: "grayscale(100%) contrast(1) blur(0px)",
		flex: "1 0 100%",
		height: "100%",
		maxWidth: "100%",
		mixBlendMode: "multiply",
		objectFit: "cover",
		opacity: "0.30",
		position: "relative",
		width: "100%",
	}

	const overlayStyle: React.CSSProperties = {
		backgroundColor: "#FF9A27",
		bottom: 0,
		left: 0,
		right: 0,
		top: 0,
		height: "100%",
		mixBlendMode: "lighten",
		position: "absolute",
		width: "100%",
		zIndex: 1,
	}

	return (
		<div style={containerStyle} className="img-wrapper">
			<div style={overlayStyle} />
			<img style={imgStyle} src="images/ngati-apa-login.jpg" alt="Ngāti Apa Ki te Rā tō" />
		</div>
	)
}

const Login = (): JSX.Element => {
	const [username, setUsername] = useState<string>("")
	const [password, setPassword] = useState<string>("")
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [loginSuccess, setLoginSuccess] = useState<boolean>(false)
	const [loggedIn, setLoggedIn] = useState<boolean>(getGlobalState("loginState")?.getValue("isLoggedIn"))
	const [error, setError] = useState<string>("")
	const clientUI = getUIConfig("clientUI")

	const onLoginStateLoaded = (type: string, key: string, data: any): void => {
		if (key === "loginState") {
			console.log("onLoginStateLoaded", data)
			const loginState = data.getValue("isLoggedIn")
			if (loginState !== loggedIn && loginState) {
				setLoggedIn(true)
			}
		}
	}

	useEffect(() => {
		registerforStateChanges(onLoginStateLoaded)
		return () => {
			removeStateChanges(onLoginStateLoaded)
		}
	}, [])

	useEffect(() => {
		if (loggedIn || loginSuccess || !isNgatiApa()) {
			// TODO: Use useNavigate when issue with map mounting issue is fixed.
			setTimeout(() => {
				window.location.href = "/"
			}, 1000)
		}
	}, [loginSuccess, loggedIn])

	const onLogin = (): void => {
		setIsLoading(true)
		Cognito.login(username, password).then(onLoginSuccess, onLoginFail)
	}

	const onLoginSuccess = (): void => {
		setIsLoading(false)
		setLoginSuccess(true)
		setError("")
	}

	const onLoginFail = (err: any): void => {
		printMessage("error logging in " + err, messageLevels.error)
		setIsLoading(false)
		setError(err.message ?? "Unknown error")
	}

	const containerStyle: React.CSSProperties = {
		position: "fixed",
		top: 0,
		left: 0,
		zIndex: 21,
		height: "100vh",
		width: "100vw",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		justifyItems: "center",
		flexDirection: "column",
	}

	const inputStyle: React.CSSProperties = {
		padding: 0,
		border: "solid 1px #FFC700",
	}
	return (
		<>
			<DuotoneBackground />
			<div style={containerStyle}>
				<div
					style={{
						zIndex: 1,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Image style={{ width: "190px", marginBottom: "40px" }} src="/images/ngati-apa-login-2.png" alt="Ngati Apa Logo" />
					<Form
						style={{ width: "300px", display: "flex", flexDirection: "column", alignItems: "center" }}
						error={!!error}
						inverted
						onSubmit={onLogin}
					>
						<Message style={{ marginTop: "-21px", textAlign: "left" }} error header="Oops!" content={error} />
						<Form.Field>
							<Form.Input
								name="username"
								value={username}
								onChange={(_, { value }): void => setUsername(value)}
								placeholder="Username / Email"
								disabled={isLoading}
								style={inputStyle}
							/>
						</Form.Field>
						<Form.Field>
							<Form.Input
								name="password"
								placeholder="Password"
								onChange={(_, { value }): void => setPassword(value)}
								value={password}
								type="password"
								disabled={isLoading}
								style={inputStyle}
							/>
						</Form.Field>
						<Button
							primary
							className={clientUI.className}
							type="submit"
							loading={isLoading}
							style={{ marginTop: "10px", backgroundColor: "#7D683B", color: "#FFF" }}
						>
							Login
						</Button>
					</Form>
				</div>
			</div>
		</>
	)
}

export default Login
