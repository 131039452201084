import React, { Component } from "react"
import { Button, Divider, Dropdown, Form, Grid, Radio } from "semantic-ui-react"

import { call, getStoreTypeByName, registerforStateChanges, removeStateChanges } from "../../../store"
import { storedItem } from "../../../store/storedItem"
import Select from "../../../components/Select"

interface nesProps {
	nextClickFunction: any
	onSubmit?: any
	onCancel?: any
}

const emptyStoredArray: storedItem[] = []
class NESFormComponent extends Component<nesProps, {}> {
	state = {
		userType: "forestManager",
		selectedRegion: "",
		dataSource: getStoreTypeByName("boundaries", true),
		listKeys: "",
		regions: [],
		isError: false,
	}
	data = emptyStoredArray
	stateChanged = (type: string, key?: string, data?: storedItem) => {
		if (key === "selected") {
			const theSelectedItems = data?.getValue()
			const firstSelection = theSelectedItems[0]
			if (theSelectedItems.length === 1 && firstSelection) {
				const regionSelected = firstSelection.getRegionISO()

				const selectedRegion = this.state.regions.find((element) => element["value"] === regionSelected)
				// const selectedRegion = this.state.regions.find(element => element["value"] == data?.getRegionISO());
				selectedRegion && this.setState({ selectedRegion: selectedRegion["value"] })
			} else {
			}
		}
	}
	dataLoaded = (type: string) => {
		this.setState({ listKeys: "" })
		if (type === "boundaries/retrieve") {
			this.getData()
		}
	}

	getData() {
		const theDataSource = this.state.dataSource
		if (theDataSource) {
			const theData = theDataSource.allMatchingItems({
				queries: ["levelName === region"],
				sort: { sortField: "english_name" },
			})
			const dataCopy = theData?.map((element) => element.primaryKey().toString()).join(",")
			if (dataCopy && this.state.listKeys !== dataCopy) {
				this.data = theData || []
			}
		}
		const regionsNames = this.data.map((element, index) => ({
			key: index,
			text: element.displayName(),
			value: element.primaryKey().toString(),
		}))
		this.setState({ regions: regionsNames })
	}

	handleChangeUserType = (e: any, { value }: any) => {
		this.setState({ userType: value })
	}

	handlClick = () => {
		if (this.state.selectedRegion) {
			this.props.nextClickFunction(true, this.state.selectedRegion, this.state.userType)
		} else {
			this.setState({ isError: true })
		}
	}

	handleOpen = () => {
		if (!this.state.regions.length) this.getData()
	}

	componentDidMount() {
		if (this.state.dataSource) this.state.dataSource.requestLoad(undefined, this.dataLoaded)
		registerforStateChanges(this.stateChanged)
	}

	handleChangeRegion = (e: any, { value }: any) => {
		this.setState({ isError: false })
		call(
			"setLevelByItem",
			this.data.find((element) => element.primaryKey().toString() === value),
		)
	}

	componentWillUnmount() {
		removeStateChanges(this.stateChanged)
	}

	render() {
		return (
			<Grid textAlign="center" style={{ marginTop: "10%" }}>
				<Grid.Row>
					<Grid.Column>
						<h2 style={{ color: "white" }}>
							{" "}
							To help us set up <br /> who is submitting this form?{" "}
						</h2>
						<div style={{ margin: "auto" }}>
							<Form.Field>
								<Radio
									radio
									label="Forest Manager"
									name="forestManager"
									value="forestManager"
									checked={this.state.userType === "forestManager"}
									onChange={this.handleChangeUserType}
								/>
							</Form.Field>
							<Form.Field>
								<Radio
									radio
									label="Land Owner&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
									name="landOwner"
									value="landOwner"
									checked={this.state.userType === "landOwner"}
									onChange={this.handleChangeUserType}
								/>
							</Form.Field>
						</div>
						<Divider />
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<h2 style={{ color: "white" }}>
							{" "}
							Please tell us which region are <br /> you submitting in? <span style={{ color: "red" }}>{" *"}</span>
						</h2>

						<Select
							placeholder="Region"
							selection
							onOpen={this.handleOpen}
							options={this.state.regions}
							value={this.state.selectedRegion}
							onChange={this.handleChangeRegion}
						/>
						<p style={{ color: "white", paddingTop: "50px" }}>
							{" "}
							*Select the region on the map or select from the <br /> dropdown list below
						</p>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row textAlign="right">
					<Grid.Column>
						<Button
							style={{ marginRight: "9%" }}
							content="Next"
							primary
							disabled={!this.state.selectedRegion}
							onClick={() => this.handlClick()}
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		)
	}
}

export default NESFormComponent
