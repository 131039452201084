import React from "react"
import { Image } from "@react-pdf/renderer"

import { mediaItem } from "../../../classes/mediaItem"
import { getURLAndRotation, getURLAndRotationCached, imageFinalURL } from "../../../URLRewrites"
import { printData } from "../../../../providers/remoteHQ"
import { messageLevels } from "../../../types"
import { FormItemComponentProps } from "../.."
export class ImageUpright extends React.Component<any> {
	render() {
		const { src, rotateStyle } = this.props
		let { style } = this.props

		const rotation = getRotation(src)

		printData(src, messageLevels.verbose, "src")
		printData(rotation, messageLevels.verbose, "rotation")
		if (rotation && rotation !== 0) {
			style = { ...this.props.style, transform: `rotate(${rotation}deg)`, ...rotateStyle }
		}

		return <Image style={style} src={src} />
	}
}

function getRotation(image: string | URL | mediaItem): number {
	return getURLAndRotationCached(image).rotation || 0
}

export class FormImage extends React.Component<FormItemComponentProps> {
	render() {
		const container = this.props.container
		const styles = container.styles
		const value = this.props.item?.getValue(this.props.field?.name)
		printData(value, messageLevels.verbose, "image source")
		return <ImageUpright src={value} style={styles}></ImageUpright>
	}
}
