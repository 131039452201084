import React from "react"
import { Item, Menu } from "semantic-ui-react"

import { printMessage } from "../../providers/remoteHQ"
import { storedItem } from "../storedItem"
import { messageLevels } from "../types"
import { SelectionItemList, selectionItem } from "../managers/SelectionList"

export interface ISelectionItem {
	item: storedItem
	index: number
	selected: boolean
}

interface SelectionListComponentProps {
	data: SelectionItemList
	header?: string
	cellFormatter?: Function
	noWrapper?: boolean
	onlySelected?: boolean
	default?: boolean
}

export default class SelectionListComponent extends React.Component<SelectionListComponentProps> {
	state = {
		selectedItems: [],
	}
	componentDidMount() {
		if (this.props.data) {
			this.props.data.callbacks.registerOnEvent(this.itemsChanged)
		}
	}

	componentWillUnmount() {
		if (this.props.data) {
			this.props.data.callbacks.removeOnEvent(this.itemsChanged)
		}
	}
	itemsChanged = (type: string, key: string) => {
		printMessage("Type: " + type, messageLevels.debug)
		printMessage("Key: " + key, messageLevels.debug)
		const theSelected = this.props.data.selectedFlagsString()
		this.setState({ selected: theSelected })
	}
	renderItem = (item: selectionItem, index: number) => {
		if (this.props.cellFormatter) {
			return <React.Fragment key={index}>{this.props.cellFormatter(item, this.props.data)}</React.Fragment>
		} else {
			return (
				<React.Fragment key={index}>
					<Menu.Item index={item.index}>
						Primary Key: {item.item.primaryKey()}
						<br />
						Region: {item.item.getRegionISO()}
						<br />
						Name: {item.item.getValue("forest_name")}
						<br />
					</Menu.Item>
				</React.Fragment>
			)
		}
	}

	renderItems = (items: selectionItem[]) => {
		return (
			<>
				{items.map((item, index) => {
					if (item.selected || !this.props.onlySelected) {
						return this.renderItem(item, index)
					}
					return null
				})}
			</>
		)
	}
	renderHeader = () => {
		if (this.props.header && this.props.data.length > 0) {
			return <header>{this.props.header}</header>
		}
	}
	render() {
		if (this.props.data) {
			this.props.data.callbacks.registerOnEvent(this.itemsChanged)
		}
		// printMessage("rendering List",messageLevels.none);
		// printData(this.data,messageLevels.none)
		if (this.props.data.length > 0) {
			if (this.props.noWrapper) {
				return <>{this.renderItems(this.props.data.items)}</>
			}
			return (
				<Item.Group>
					{this.renderHeader()}
					{this.renderItems(this.props.data.items)}
				</Item.Group>
			)
		} else {
			return null
		}
	}
}
