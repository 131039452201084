import exifr from "exifr"

import { getConfig, isStoredItem, isUUID, itemByKey } from "."
import { printData, printMessage } from "../providers/remoteHQ"
import { messageLevels } from "./types"
import { mediaItem } from "./classes/mediaItem"

const URLRotationCache: { [url: string]: number } = {}
export type ImageWithRotation = { url: string; rotation: number }

export async function getURLAndRotation(image: string | URL | mediaItem): Promise<ImageWithRotation> {
	let theFetchedRotation
	if (typeof image === "string") {
		image = imageItemOrString(image)
	}
	if (isStoredItem(image)) {
		const theRotation = (image as mediaItem).displayRotation
		const theURL = (image as mediaItem).URL?.toString()
		return { url: theURL || "", rotation: theRotation }
	}
	let url
	if (typeof image !== "string") {
		url = image.toString()
	} else {
		url = image
	}
	if (URLRotationCache[url]) {
		return { url: url, rotation: URLRotationCache[url] }
	}
	// const uuid = UUIDforMediaCollectionURL(url);
	// if (uuid) {
	//     return {url: url, rotation: 0};
	// }
	try {
		theFetchedRotation = await fetchRotationForURL(url)
	} catch (e) {}

	let theRotation = 0
	if (theFetchedRotation) {
		theRotation = await theFetchedRotation.deg
	}
	URLRotationCache[url] = theRotation
	return { url: url, rotation: theRotation }
	// return 0;
}
export function getURLAndRotationCached(image: string | URL | mediaItem): ImageWithRotation {
	if (isStoredItem(image)) {
		const theRotation = (image as mediaItem).displayRotation
		const theURL = (image as mediaItem).URL?.toString()
		return { url: theURL || "", rotation: theRotation }
	}
	let url
	if (typeof image !== "string") {
		url = image.toString()
	} else {
		url = image
	}
	if (URLRotationCache[url]) {
		return { url: url, rotation: URLRotationCache[url] }
	}
	return { url: url, rotation: 0 }
}

export function supportingDocumentURL(forString: string) {
	if (forString && Array.isArray(forString) && forString.length === 1) {
		forString = forString[0]
	}
	if (!forString || forString === undefined || typeof forString !== "string" || (forString.length > 0 && forString.endsWith("/"))) {
		return null
	}

	let theURL

	if (forString.startsWith("https://forestry-monitoring-report-attachments.s3.ap-southeast-2.amazonaws.com/")) {
		const modURL = "attachments/" + forString.toString().substring(79)
		return new URL(modURL, "https://remotehq.co.nz/")
	} else if (forString.startsWith("https://forestry-monitoring-report-attachments-dev.s3.ap-southeast-2.amazonaws.com/")) {
		const modURL = "attachments/" + forString.toString().substring(83)
		return new URL(modURL, "https://dev.remotehq.co.nz/")
	} else if (forString.startsWith("http")) {
		theURL = new URL(forString)
	} else if (isUUID(forString)) {
		theURL = mediaCollectionURLforUUID(forString)
	} else {
		let modItem = forString
		if (forString.startsWith(".")) {
			modItem = forString.substring(1)
		}
		if (!modItem.startsWith("/media/") && !modItem.startsWith("media/")) {
			modItem = "/media/" + forString
		}
		theURL = new URL(modItem, getConfig("cdnUrl"))
	}
	// printData(theURL, messageLevels.verbose, "theURL");

	return theURL
}
export function mediaCollectionURLforUUID(forUUID: string, withParams?: string): URL {
	if (!withParams) {
		return new URL(getConfig("mediaGalleryEndpoint") + forUUID, getConfig("invokeUrl"))
	}

	return new URL(getConfig("mediaGalleryEndpoint") + forUUID + "/" + withParams, getConfig("invokeUrl"))
}
export function UUIDforMediaCollectionURL(forURL: URL | string | null): string {
	if (!forURL) {
		return ""
	}

	let theString = ""
	if (typeof forURL === "string") {
		if (forURL.length === 0) {
			return ""
		}
		theString = forURL.split("?")[0]
	} else {
		theString = (forURL as URL).pathname
	}
	if (isUUID(theString)) {
		return theString
	}
	const theParts = theString.split("/")
	if (theParts.length === 0) {
		return ""
	}
	const lastItem = theParts[theParts.length - 1]

	if (isUUID(lastItem)) {
		return lastItem
	}
	if (theParts.length < 2) {
		return ""
	}
	const nextItem = theParts[theParts.length - 2]

	if (isUUID(nextItem)) {
		return nextItem
	}
	return ""
}
export function imageItemFromUUID(theUUID: string): mediaItem | null {
	const theCollectionItem = itemByKey("media-collection", theUUID)
	printData(theCollectionItem, messageLevels.debug, "theCollectionItem")
	if (theCollectionItem && theCollectionItem !== undefined) {
		return theCollectionItem as mediaItem
	} else {
		const theImageItem = itemByKey("file-upload", theUUID)
		printData(theImageItem, messageLevels.debug, "theImageItem")
		if (theImageItem && theImageItem !== undefined) {
			return theImageItem as mediaItem
		}
	}
	return null
}

export async function imageOrientationForURL(imageLink: URL) {
	await imageOrientationForURLAsync(imageLink)
}
export async function imageOrientationForURLAsync(imageLink: URL) {
	try {
		exifr
			.rotation(imageLink.href)
			.then((output) => {
				return {
					rotation: output?.deg || 0,
				}
			})
			.catch(() => {
				return 0
			})
	} catch (e) {
		return 0
	}
}

export async function readImageData(image: string | Buffer | ArrayBuffer | File) {
	printMessage(image, messageLevels.verbose)
	//TODO read relevant image properties and store them
	// exifr.parse(image, {pick: [ 'ImageWidth', 'ImageHeight']})
	// .then(output => {
	//     printData(output, messageLevels.verbose, "Parse Output");
	//     return {
	//       output
	//     }
	//   })
}

export function imageItemsOrStrings(theImages: string[]): (string | URL | mediaItem)[] {
	const imageLinks: (string | URL | mediaItem)[] = []

	theImages.forEach((item: string) => {
		imageLinks.push(imageItemOrString(item))
	})
	return imageLinks
}
export function imageItemOrString(theImage: string): string | URL | mediaItem {
	let theURL

	if (theImage.length > 0 && !theImage.endsWith("/")) {
		const uuid = UUIDforMediaCollectionURL(theImage)

		if (uuid) {
			const theItem = imageItemFromUUID(uuid)
			if (theItem) {
				return theItem
			}
			if (theImage.startsWith("http")) {
				return theImage
			} else {
				return mediaCollectionURLforUUID(uuid)
			}
		}

		if (theImage.startsWith("http")) {
			return theImage
		} else {
			let modItem = theImage
			if (theImage.startsWith(".")) {
				modItem = theImage.substring(1)
			}
			if (!modItem.startsWith("/media/") && !modItem.startsWith("media/")) {
				modItem = "/media/" + theImage
			}
			theURL = new URL(modItem, getConfig("cdnUrl"))
		}

		if (theURL) {
			return theURL
		}
	}
	return theImage
}

export function imagesAndRotations(theImages: (string | URL | mediaItem)[]): Promise<ImageWithRotation>[] {
	const imageRotations: Promise<ImageWithRotation>[] = []

	theImages.forEach((item: any) => {
		imageRotations.push(getURLAndRotation(item))
	})
	return imageRotations
}
async function fetchRotationForURL(url: string) {
	return await exifr.rotation(url)
}
export function imageRotation(theImage: string | URL | mediaItem): number {
	if (isStoredItem(theImage)) {
		return (theImage as mediaItem).displayRotation || 0
	} else {
		return 0
	}
}

export function imageStringOrObjects(
	theImages: (string | URL | mediaItem)[],
): (string | Buffer | { data: Buffer; format: "png" | "jpg" })[] {
	const imageLinks: (string | Buffer | { data: Buffer; format: "png" | "jpg" })[] = []
	theImages.forEach((item) => {
		imageLinks.push(imageStringOrObject(item))
	})
	return imageLinks
}
export function imageStringOrObject(theImage: string | URL | mediaItem): string | Buffer | { data: Buffer; format: "png" | "jpg" } {
	if (typeof theImage === "string") {
		return theImage
	} else if (isStoredItem(theImage)) {
		const theURL = (theImage as mediaItem).URL
		if (theURL) {
			return theURL
		}
	}

	return theImage.toString()
}

export function imageFinalURL(image: string | URL) {
	if (!image) return ""
	if (typeof image === "string" && image.length < 1) return ""
	return imageStringOrObject(imageItemOrString(image.toString()))
}

export function attachmentImageURL(theImage: string) {
	if (!theImage || theImage === undefined || (theImage.length > 0 && theImage.endsWith("/"))) {
		return null
	}

	let theURL

	if (theImage.startsWith("https://forestry-monitoring-report-attachments.s3.ap-southeast-2.amazonaws.com/")) {
		const modURL = "attachments/" + theImage.toString().substring(79)

		return new URL(modURL, "https://remotehq.co.nz/")
	} else if (theImage.startsWith("https://forestry-monitoring-report-attachments-dev.s3.ap-southeast-2.amazonaws.com/")) {
		const modURL = "attachments/" + theImage.toString().substring(83)

		return new URL(modURL, "https://dev.remotehq.co.nz/")
	} else if (theImage.startsWith("http")) {
		theURL = new URL(theImage)
	} else if (isUUID(theImage)) {
		theURL = mediaCollectionURLforUUID(theImage)
	} else {
		let modItem = theImage
		if (theImage.startsWith(".")) {
			modItem = theImage.substring(1)
		}
		if (!modItem.startsWith("/media/") && !modItem.startsWith("media/")) {
			modItem = "/media/" + theImage
		}
		theURL = new URL(modItem, getConfig("cdnUrl"))
	}
	// printData(theURL, messageLevels.verbose, "theURL");

	return theURL
}
