import React, { useState, useEffect, useRef } from "react"

import ImageLightbox from "./ImageLightbox"

export const images = [
	"https://placehold.co/600x400",
	"https://placehold.co/400x600",
	"https://placehold.co/400x700",
	"https://placehold.co/600x400",
	"https://placehold.co/600x400",
	"https://placehold.co/600x400",
	"https://placehold.co/600x400",
	"https://placehold.co/600x400",
	"https://placehold.co/600x400",
	"https://placehold.co/600x400",
]

type ImageData = {
	url: string
	orientation: "landscape" | "portrait"
}[]

//TODO: define breakpoint to change logic of grid layout.
const ResponsiveImageGrid: React.FC<{ imageUrls: string[]; themeColor?: "string" }> = ({ imageUrls, themeColor }) => {
	const [imageData, setImageData] = useState<ImageData | any>([])
	const [columns, setColumns] = useState<number>(3)
	const [parentWidth, setParentWidth] = useState<number>(0)
	const [lightboxOpen, setLightboxOpen] = useState<boolean>(false)
	const [selectedImageIndex, setSelectedImageIndex] = useState<number | any>(0)
	const parentRef = useRef<HTMLDivElement>(null)

	const padding = 25

	useEffect(() => {
		const fetchImageData = async () => {
			const data = await Promise.all(
				imageUrls.map(async (imageUrl) => {
					const img = new Image()
					img.src = imageUrl

					return new Promise((resolve) => {
						img.onload = () => {
							resolve({
								url: imageUrl,
								orientation: img.width > img.height ? "landscape" : "portrait",
							})
						}
					})
				}),
			)
			setImageData(data)
		}

		fetchImageData()
	}, [imageUrls])

    useEffect(() => {
		const handleResize = () => {
			const parentWidth = parentRef.current?.getBoundingClientRect().width || 0
			const screenWidth = window.innerWidth
			setParentWidth(parentWidth)
			// TODO: add additional logic here to respond to screen size.
			if (parentWidth < screenWidth * 0.3) {
				setColumns(3)
			} else if (parentWidth >= screenWidth * 0.3 && parentWidth < screenWidth * 0.6) {
				setColumns(3)
			} else {
				setColumns(3)
			}
		}

		handleResize()
		window.addEventListener("resize", handleResize)
		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [])

	return (
		<>
			<div ref={parentRef} className="parent-container" style={{ width: "100%", margin: "0 auto", padding }}>
				<div
					className="image-grid"
					style={{
						display: "grid",
						gridTemplateColumns: `repeat(auto-fill, minmax(${100 / columns}%, 1fr))`,
						gap: "10px",
					}}
				>
					{imageData.map((image: any, index: number) => (
						<div
							key={index}
							className={`image-item ${image.orientation}`}
							style={{
								gridColumn: image.orientation === "landscape" ? "span 2" : "span 1",
								height: image.orientation === "landscape" ? (parentWidth - padding) / 1.7 : (parentWidth - padding) * 0.65,
								width: "100%",
								overflow: "hidden",
								position: "relative",
							}}
							onClick={() => {
								setLightboxOpen((bool) => !bool)
								setSelectedImageIndex(index)
							}}
						>
							<img
								src={image.url}
								alt={`Image ${index}`}
								style={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									width: "100%",
									height: "auto",
								}}
							/>
						</div>
					))}
				</div>
			</div>
			<ImageLightbox
				images={imageUrls}
				isOpen={lightboxOpen}
				setOpen={setLightboxOpen}
				currentIndex={selectedImageIndex}
				setCurrentIndex={setSelectedImageIndex}
				themeColor={themeColor}
			/>
		</>
	)
}

export default ResponsiveImageGrid
