import "./styles.css"

import React from "react"
import { Button, Dimmer, Divider, Form, Icon, Loader, Message, Segment, SemanticICONS } from "semantic-ui-react"

import * as Cognito from "../../providers/cognito"
import { getConfig, getUIConfig } from "../../store"
import { messageLevels } from "../../store/types"
import { printMessage } from "../../providers/remoteHQ"

interface LoginFormProps {
	onClose: () => void
}

interface LoginFormState {
	showEmail: boolean
	showAD: boolean
	errorMessage: string | undefined
	isLoading: boolean
	username: string
	password: string
}

export default class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
	state = {
		showEmail: false,
		showAD: false,
		username: "",
		password: "",
		errorMessage: undefined,
		isLoading: false,
		uiConfig: getUIConfig("clientUI"),
		ssoProviders: getConfig()?.ssoProviders || [],
	}

	onLoginSuccess = () => {
		this.setState({ isLoading: false })
	}

	onLoginFail = (err: any) => {
		printMessage("error logging in " + err, messageLevels.error)
		this.setState({ errorMessage: err.message ?? "Unknown error", isLoading: false })
	}

	wrapperRef = React.createRef<HTMLDivElement>()

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside)
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside)
	}

	onKeyUp = (e: KeyboardEvent) => {
		if (e.key === "Enter") {
			this.handleSubmit()
		}
	}

	handleSubmit = () => {
		const { username, password } = this.state
		this.setState({ isLoading: true })
		Cognito.login(username, password).then(this.onLoginSuccess, this.onLoginFail)
	}

	handleClickOutside = (event: MouseEvent) => {
		if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target as HTMLInputElement)) {
			this.props.onClose()
		}
	}

	handleUsernameChange = (_: any, { value }: { value: string }) => this.setState({ username: value })

	handlePasswordChange = (_: any, { value }: { value: string }) => this.setState({ password: value })

	render() {
		const { errorMessage, isLoading, username, password, uiConfig, ssoProviders } = this.state
		const { brandColor } = uiConfig
		return (
			<div ref={this.wrapperRef} id="login-form">
				<Segment>
					<Dimmer active={isLoading}>
						<Loader />
					</Dimmer>
					<Form onSubmit={this.handleSubmit} error={!!errorMessage}>
						<Form.Field>
							<Form.Input
								name="username"
								label="Username"
								value={username}
								placeholder="name@domain.com"
								onChange={this.handleUsernameChange}
							/>
						</Form.Field>
						<Form.Field>
							<Form.Input
								name="password"
								label="Password"
								type="password"
								value={password}
								onChange={this.handlePasswordChange}
							/>
						</Form.Field>
						{errorMessage && <Message data-testid="login-error-msg" error header="Log in failed" content={errorMessage} />}
						<Button type="submit" style={{ backgroundColor: brandColor, color: "white" }}>
							Log In
						</Button>
						{ssoProviders.length ? <Divider horizontal>Or</Divider> : null}
						{ssoProviders.map((provider: string) => (
							<Button data-testid="provider" onClick={Cognito.loginAd}>
								<Icon name={provider as SemanticICONS} />
								{provider.charAt(0).toUpperCase() + provider.slice(1)}
							</Button>
						))}
					</Form>
				</Segment>
			</div>
		)
	}
}
