import React, { Component } from "react"
import { Container, Header, Item } from "semantic-ui-react"

import { storedItem } from "../../storedItem"
import { ReactFormPage } from "./ReactFormPage"
import { FormStepperComponent } from "./Stepper"
import { FormPageItemProps } from "../index"
import { MultipageForm } from "../MultiPageForm"

export class ReactMultipageForm extends MultipageForm {
	state = {
		step: 0,
		availablePagesCount: 0,
	}

	componentDidMount(): void {
		this.setState({
			availablePagesCount: this.props.pages.filter((page) => !page.hidden).length,
		})
	}

	nextPage = () => {
		this.goToPage(this.state.step + 1)
	}
	CancelFlow = () => {
		this.props.onCancel()
	}
	previousPage = () => {
		this.goToPage(this.state.step - 1)
	}

	goToPage = (page: number) => {
		if (page >= 0 && page < this.props.pages.length) {
			this.setState({ step: page })
		}
	}

	renderPage = (page: FormPageItemProps, index: number) => {
		if (this.props.item && this.props.item !== null) {
			return (
				<ReactFormPage
					item={this.props.item}
					title={page.title}
					fields={page.fields}
					container={this.props.container}
					pages={this.state.availablePagesCount}
					page={page}
					index={index}
					active={this.state.step === index}
					onBack={this.previousPage}
					onNext={this.nextPage}
					onCancel={this.CancelFlow}
					onSubmit={this.props.onSubmit}
					setDirty={this.props.setDirty}
					displayMode={this.props.displayMode}
					renderMiddleButtons={() => this.props.renderMiddleButtons(() => this.goToPage(this.props.pages.length - 1))}
				>
					{" "}
				</ReactFormPage>
			)
		}
	}

	renderPages = () => {
		if (this.props.item) {
			return this.props.pages.map((page: any, idx: any) => {
				return this.renderPage(page, idx)
			})
		}
	}

	render() {
		if (this.props.item) {
			return (
				<>
					<Container>
						<Item style={{ border: 0 }}>
							<Header as="h3" style={{ margin: "0 100px 0 0" }}>
								{this.props.title}
							</Header>
							<FormStepperComponent
								step={this.state.step}
								pages={this.props.pages}
								goToPage={this.goToPage}
								item={this.props.item}
							/>
						</Item>
						{this.props.item && this.renderPages()}
					</Container>
				</>
			)
		} else {
			return null
		}
	}
}
