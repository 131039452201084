import React, { useEffect, useState } from "react"

import { registerforStateChanges, removeStateChanges, setGlobalState } from "../../store"
import { storedItem } from "../../store/storedItem"
import { PanoramaFeatureModal } from "./panorama/panorama"
import { panoramaFeature } from "../../store/classes/panoramaFeature"
import { blockFeature } from "../../store/classes/blockFeature"
import { printData, printMessage } from "../../providers/remoteHQ"
import { messageLevels } from "../../store/types"

function InfoModal(): JSX.Element {
	const [modalInfo, setModalInfo] = useState<panoramaFeature | blockFeature | null>(null)

	const handleOpenChange = (type: string, key: string, val: blockFeature | storedItem) => {
		if (key === "modalInfo") {
			const theItem = val.getValue()[0]
			setModalInfo(theItem as blockFeature)
		}
	}

	const handleClose = () => {
		setModalInfo(null)
	}

	useEffect(() => {
		registerforStateChanges(handleOpenChange)

		return () => {
			removeStateChanges(handleOpenChange)
		}
	})

	if (!modalInfo) {
		return <></>
	}
	printData(modalInfo, messageLevels.debug)
	const typeName = modalInfo.getTypeName()
	printData(typeName, messageLevels.debug)
	switch (typeName) {
		case "general-panoramas":
		case "aerial-panoramas":
		case "ground-panoramas":
		case "panoramas":
		case "General":
		case "Aerial":
		case "Ground":
			return <PanoramaFeatureModal feature={modalInfo as panoramaFeature} handleClose={handleClose} />
		default:
			return <></>
	}
}

export default InfoModal
