import { getStoreTypeByName, registerforStateChanges } from "."
import { printData, printMessage } from "../providers/remoteHQ"
import { parseQueries, storedItem } from "./storedItem"
import { storeType } from "./storeType"
import { IParsedQuery, IQueryParams, messageLevels } from "./types"
import { IStats, listStats, listValues, sortItemArray } from "./helpers/ListOperations"
import { EntryList } from "./helpers/EntryList"

interface IDataFeedCallback {
	callback: Function
	queryParams: IQueryParams
}

interface IDataSource {
	name: string
	onChangeClients: Array<IDataFeedCallback>
	dataSourceName: string
	sourceQueryParams: IQueryParams
	variableQueryParams: IQueryParams

	dataSource: storeType | null
	isLoaded: boolean
}

export interface IFeedStorageArray {
	[key: string]: dataFeed
}

interface IStatsArray {
	[key: string]: IStats
}
interface IEntryLists {
	[key: string]: EntryList
}

const dataFeeds: IFeedStorageArray = {}
const emptyStatsArray: IStatsArray = {}

export class dataFeed implements IDataSource {
	name = "new"
	dataSourceName = ""
	dataSource: storeType | null = null
	onChangeClients: Array<IDataFeedCallback> = []

	incomingData: Array<storedItem> = []
	interactiveData: Array<storedItem> = []
	interactiveKeys: Array<any> | null = null
	incomingStats: IStatsArray | null = {}
	interactiveStats: IStatsArray | null = {}
	incomingValues: IEntryLists | null = {}
	interactiveValues: IEntryLists | null = {}
	sourceQueryParams: IQueryParams = {}
	variableQueryParams: IQueryParams = {}
	isLoaded = false

	constructor(name: string) {
		this.name = name
		dataFeeds[name] = this
	}

	setupSource(dataSourceName: string, queryParams: IQueryParams) {
		this.dataSourceName = dataSourceName

		if (this.dataSourceName && this.dataSourceName.length > 0) {
			this.sourceQueryParams = queryParams
			this.dataSource = getStoreTypeByName(this.dataSourceName || "forest-block", true)
			if (this.dataSource) {
				this.dataSource.requestLoad(undefined, this.dataLoaded)
				this.clearIncomingStats()
				this.clearIncomingValues()
			}
			registerforStateChanges(this.stateChanged)
		}
	}

	clearIncomingStats() {
		this.incomingStats = null
		this.incomingStats = { ...emptyStatsArray }
		this.incomingValues = null
		this.incomingValues = {}
	}

	clearInteractiveStats() {
		this.interactiveStats = null
		this.interactiveStats = { ...emptyStatsArray }
	}

	clearIncomingValues() {
		this.incomingValues = null
		this.incomingValues = {}
	}

	clearInteractiveValues() {
		this.interactiveValues = null
		this.interactiveValues = {}
	}
	clearInteractiveKeys() {
		this.interactiveKeys = null
	}
	interactiveSettings(queryParams: IQueryParams) {
		// if(this.variableQueryParams !== queryParams) {

		this.variableQueryParams = queryParams
		this.doInteractiveFilter()

		// }
	}

	stateChanged = (type: string, key?: string) => {
		if ((key === "level" || key === "regions") && this.dataSource) {
			printMessage("Regions/Level state changed in DataFeed", messageLevels.debug)
			setTimeout(() => {
				const newData = this.dataSource!.matchingItems(this.sourceQueryParams)
				const isSame = compareArrays(this.incomingData, newData)
				if (!isSame) {
					printMessage("Data changed", messageLevels.debug)
					this.incomingData = newData
					this.clearIncomingStats()
					this.clearIncomingValues()
					this.doInteractiveFilter()
				}
			})
		}
	}

	readDataFromSource() {
		if (this.dataSource) {
			const newData = this.dataSource.matchingItems(this.sourceQueryParams)

			if (!compareArrays(this.incomingData, newData)) {
				this.incomingData = newData
				this.clearIncomingStats()
				this.clearIncomingValues()
				this.doInteractiveFilter()
			}
			this.isLoaded = true
		}
	}
	dataLoaded = (type: string, key?: string) => {
		if (type.includes(this.dataSourceName) && this.dataSource) {
			if (this.isLoaded === true || !key || key === "") {
				printMessage("dataLoaded in: " + this.dataSourceName, messageLevels.debug)
				const newData = this.dataSource.matchingItems(this.sourceQueryParams)
				if (!compareArrays(this.incomingData, newData)) {
					this.incomingData = newData
					this.clearIncomingStats()
					this.doInteractiveFilter()
				}
				this.isLoaded = true
			}
		}
	}

	statsIncoming = (key: string): IStats => {
		if (this.incomingStats && this.incomingStats[key]) return this.incomingStats[key]
		if (!this.incomingStats) {
			this.clearIncomingStats()
		}
		if (this.incomingStats) {
			this.incomingStats[key] = listStats(this.incomingData, key)
			return this.incomingStats[key]
		}
		return {}
	}

	valuesIncoming = (key: string, split?: boolean): any[] => {
		if (this.incomingValues && this.incomingValues[key]) return this.incomingValues[key].valueArray()
		if (!this.incomingValues) {
			this.clearIncomingValues()
		}
		if (this.incomingValues) {
			this.incomingValues[key] = listValues(this.incomingData, key, split)

			return this.incomingValues[key].valueArray()
		}
		return []
	}

	doInteractiveFilter = () => {
		let newData: storedItem[] = []
		if (this.incomingData.length > 0) {
			printData(this.variableQueryParams, messageLevels.debug, "Doing interactive query")
			newData = sortFilteredArray(this.incomingData, this.variableQueryParams)
			printData(newData, messageLevels.debug, "Interactive Filtered data")
			// copyArray(this.incomingData);
		} else {
			newData = []
		}
		if (!compareArrays(this.interactiveData, newData)) {
			this.interactiveData = newData
			printMessage("Interactive Data changed", messageLevels.debug)
			this.clearInteractiveStats()
			this.clearInteractiveValues()
			this.clearInteractiveKeys()
			this.sendUpdates()
		}
	}

	statsFiltered = (key: string): IStats => {
		if (this.interactiveStats && this.interactiveStats[key]) return this.interactiveStats[key]
		if (!this.interactiveStats) {
			this.clearInteractiveStats()
		}
		if (this.interactiveStats) {
			this.interactiveStats[key] = listStats(this.interactiveData, key)
			return this.interactiveStats[key]
		}
		return {}
	}

	getInteractiveKeys(): (string | number | any)[] {
		if (this.interactiveKeys) {
			return this.interactiveKeys
		}
		const theKeys: any[] = []
		this.interactiveData.forEach((element) => {
			if (element.primaryKey()) {
				theKeys.push(element.primaryKey())
			}
		})
		this.interactiveKeys = theKeys
		return this.interactiveKeys
	}
	sendUpdates = () => {
		for (let x = 0; x < this.onChangeClients.length; x++) {
			this.sendUpdateTo(this.onChangeClients[x].callback, this.onChangeClients[x].queryParams)
		}
	}

	sendUpdateTo = (callFn: Function, queryParams: IQueryParams) => {
		if (callFn) {
			// printMessage("Sending update", messageLevels.none);
			const newData = sortFilteredArray(this.interactiveData, queryParams)
			// printData(newData, messageLevels.none, "client filtered");
			callFn("dataFeed", this.name, newData)
		}
	}

	registerOnChange(callFn: Function, queryParams: IQueryParams) {
		if (callFn) {
			const theClient: IDataFeedCallback = {
				callback: callFn,
				queryParams: queryParams ?? {},
			}
			if (isInCallbackList(this.onChangeClients, callFn) < 0) {
				this.onChangeClients.push(theClient)
				if (this.interactiveData.length > 0) {
					this.sendUpdateTo(callFn, queryParams)
				}
			}
		} else {
			printMessage("Empty call passed to data feed: " + this.name, messageLevels.error)
			// printData(callFn, messageLevels.verbose);
		}
	}

	removeOnChange(callback: Function) {
		const theIndex = isInCallbackList(this.onChangeClients, callback)
		if (theIndex > -1) {
			this.onChangeClients.splice(theIndex, 1)
		}
	}
}

function isInCallbackList(list: IDataFeedCallback[], fn: Function): number {
	for (let x = 0; x < list.length; x++) {
		if (list[x].callback === fn) {
			return x
		}
	}
	return -1
}

function filterArray(sourceData: storedItem[], queryParams: IQueryParams): storedItem[] {
	printData(queryParams, messageLevels.debug, "filterArray")
	let theQueryParams: IQueryParams = {}
	let theParsedQueries: IParsedQuery[] = []
	if (queryParams && queryParams !== undefined) {
		theParsedQueries = parseQueries(queryParams.queries || [])
		theQueryParams = queryParams
	}
	printData(theParsedQueries, messageLevels.debug, "filterArray theParsedQueries")
	const theItems: storedItem[] = []
	printMessage("matchingItems ", messageLevels.debug)
	sourceData.forEach((item) => {
		printMessage("Checking Feed Item: " + item.primaryKey(), messageLevels.debug)
		let theReturn = item.matchItem(theParsedQueries || [], theQueryParams.searchOrMode)
		if (theReturn) {
			if (
				theQueryParams.stringParams &&
				theQueryParams.stringParams.length > 0 &&
				theQueryParams.stringToFind &&
				theQueryParams.stringToFind.trim().length > 0
			) {
				theReturn = theReturn.matchStringInParameters(theQueryParams.stringParams || [], theQueryParams.stringToFind || "")
				if (theReturn) {
					printData(theReturn, messageLevels.debug, "Returned")
					theItems.push(theReturn)
				}
			} else {
				printData(theReturn, messageLevels.debug, "Returned")
				theItems.push(theReturn)
			}
		}
	})
	return theItems
}

export function sortFilteredArray(sourceData: storedItem[], queryParams: IQueryParams): storedItem[] {
	const theList = filterArray(sourceData, queryParams)
	return sortItemArray(theList, queryParams)
}

export function compareArrays(arr1: storedItem[], arr2: storedItem[]) {
	if (arr1.length !== arr2.length) {
		return false
	}
	for (let x = 0; x < arr1.length; x++) {
		if (arr1[x] !== arr2[x]) {
			return false
		}
	}
	return true
}
export function compareKeyArrays(arr1: any[], arr2: any[]) {
	if (arr1.length !== arr2.length) {
		return false
	}
	for (let x = 0; x < arr1.length; x++) {
		if (arr1[x] !== arr2[x]) {
			return false
		}
	}
	return true
}

export function getDataFeedByName(name: string, createMissing?: boolean): dataFeed {
	let theDataFeed = dataFeeds[name]

	if (createMissing && createMissing === true && !theDataFeed) {
		theDataFeed = new dataFeed(name)
	}
	return theDataFeed
}
