import React from "react"
import { Button, Divider, Header, Label, Segment } from "semantic-ui-react"

import { isStoredItem, requestLoad, requestLoadIfExist } from "../../index"
import LayerGroupAccordion from "../../../mapsight/LayerGroupAccordion"
import Documents from "./Documents"
import View from "./View"
import CouncilLogo from "../../../components/CouncilLogo"
import variables from "../../../variables"
import { StoredItemInfoView } from "../StoredItem"
import BlockDetailView from "./Detail"
import { ItemViewProps } from "../Types"
import { forestBlock } from "../../classes/forestBlock"

const { skidComplianceOptions: compliance } = variables

interface IBlockInfoViewProps extends ItemViewProps {
	changeDefaultActiveView?: (defaultActiveView: number) => void
	defaultActiveView?: number
}

export class BlockInfoView extends StoredItemInfoView<IBlockInfoViewProps> {
	state = {
		isAuthoritiesLoaded: false,
		activeView: this.props.defaultActiveView || 0,
	}

	componentDidMount() {
		requestLoadIfExist("authorities", undefined, () => {
			this.setState({ isAuthoritiesLoaded: true })
		})
		requestLoad("forest-monitoring-reports")
	}

	onChangeActiveView = (i: number) => () => {
		this.setState({ activeView: i })
		this.props.changeDefaultActiveView && this.props.changeDefaultActiveView(i)
	}

	render() {
		const { activeView, isAuthoritiesLoaded } = this.state
		const block = this.props.selectedItem as forestBlock
		if (!block || !isStoredItem(block) || !isAuthoritiesLoaded) {
			return <></>
		}
		const { forest_name, compliance_status, authorityItem } = block as any
		return (
			<>
				<Segment inverted basic className="council-logo">
					<CouncilLogo src={process.env.PUBLIC_URL + authorityItem?.logo_url.split("public")[1]} />
				</Segment>
				<Segment inverted basic className="info-tabs">
					<Button
						inverted
						circular
						icon="info"
						onClick={this.onChangeActiveView(0)}
						className={`${activeView === 0 ? "active" : ""}`}
					/>
					<Button
						inverted
						circular
						icon="list"
						onClick={this.onChangeActiveView(1)}
						className={`${activeView === 1 ? "active" : ""}`}
					/>
					<Button
						inverted
						circular
						icon="file alternate outline"
						onClick={this.onChangeActiveView(2)}
						className={`${activeView === 2 ? "active" : ""}`}
					/>
					<Button
						inverted
						circular
						icon="street view"
						onClick={this.onChangeActiveView(3)}
						className={`${activeView === 3 ? "active" : ""}`}
					/>
				</Segment>
				<Segment inverted basic className="basic-info">
					<Header as="h2" inverted>
						{forest_name}
					</Header>
					<Label horizontal className="compliance-status" color={compliance[compliance_status || "NA"].color.name}>
						{compliance[compliance_status || "NA"].definition}
					</Label>
				</Segment>
				<Divider />
				<>
					<BlockDetailView visible={activeView === 0} block={block} />
					{activeView === 1 && <LayerGroupAccordion location="block" block={block} />}
					<Documents visible={activeView === 2} block={block} />
					<View visible={activeView === 3} block={block} />
				</>
			</>
		)
	}
}
