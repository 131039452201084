import { storedItem } from "../../store/storedItem"
import { printMessage } from "../../providers/remoteHQ"
import { messageLevels } from "../types"

export class placeName extends storedItem {
	displayName() {
		const that: any = this
		return that.name
	}
}
