import React, { Component } from "react"
import { Accordion, Icon, Item } from "semantic-ui-react"

import { messageLevels } from "../store/types"
import {
	dumpDataType,
	getGlobalState,
	getStateValue,
	getUIConfig,
	registerforStateChanges,
	removeOnChange,
	removeStateChanges,
	requestLoad,
	setGlobalState,
} from "../store/index"
import { printData, printMessage } from "../providers/remoteHQ"
import ListComponent from "./ListComponent"
import { layerGroup } from "../store/classes/layerGroup"
import { mapLayer } from "../store/classes/mapLayer"
import ArrayListComponent from "./ArrayListComponent"
import LayerControl from "./LayerControl"
import { forestBlock } from "../store/classes/forestBlock"
import { storedItem } from "../store/storedItem"

interface LayerGroupControlProps {
	location?: string
	block?: forestBlock
}

class LayerGroupAccordion extends Component<LayerGroupControlProps> {
	state = {
		level: "country",
		activeIndex: getStateValue("layerActiveIndex") || "regional",
		changed: false,
		uiConfig: getUIConfig("defaultSidebar"),
	}

	componentDidMount() {
		registerforStateChanges(this.dataChanged)
	}
	componentWillUnount() {
		removeStateChanges(this.dataChanged)
	}

	dataChanged = (type: string | undefined, key: string | undefined, data: storedItem) => {
		printMessage("Type in LayerGroup Accordion: " + type, messageLevels.debug)
		printMessage("Key: " + key, messageLevels.debug)
		printMessage("Data: " + data, messageLevels.debug)
		if (key === "loginState") {
			this.setState({ loggedIn: data.getValue("isLoggedIn") })
		}
		if (type === "state" && key === "level") {
			this.setState({ level: data.getValue() })
		}
	}

	handleClick = (id: any) => {
		const { activeIndex } = this.state
		const newIndex = activeIndex === id ? null : id
		setGlobalState("layerActiveIndex", newIndex)
		this.setState({ activeIndex: newIndex })
	}

	layerCellFormatter = (item: storedItem, options?: { [id: string]: any }) => {
		const layer = item as unknown as mapLayer
		return (
			<Item height="50px" key={item.primaryKeySafe()}>
				<LayerControl item={layer} options={options} block={this.props.block!} />
			</Item>
		)
	}

	groupCellFormatter = (item: storedItem) => {
		const theLayerGroup = item as layerGroup
		const active = this.state.activeIndex === item.getValue("id")
		const allowEdit = theLayerGroup?.allowEdit && theLayerGroup?.allowEdit()
		const { fontColor } = this.state.uiConfig

		if (theLayerGroup.inCurrentLevel() && theLayerGroup.inCurrentMode()) {
			return (
				<React.Fragment key={item.primaryKeySafe()}>
					<Accordion.Title
						active={active}
						index={item.getValue("id")}
						onClick={() => this.handleClick(item.getValue("id"))}
						style={{ color: fontColor }}
					>
						<Icon name="dropdown" style={{ color: fontColor }} />
						{item.getValue("name")}
					</Accordion.Title>
					<Accordion.Content index={item.getValue("id")} active={active}>
						<Item.Group divided>
							<ArrayListComponent
								data={theLayerGroup.layerItems}
								cellFormatter={this.layerCellFormatter}
								options={{ allowEdit: allowEdit }}
							></ArrayListComponent>
						</Item.Group>
					</Accordion.Content>
				</React.Fragment>
			)
		}
		return null
	}

	render() {
		let queries: string[] = []

		if (this.props.location) {
			queries = ["locations CONTAINS " + this.props.location]
		}

		return (
			<Accordion key={"layerGroups"} styled fluid inverted>
				<ListComponent
					dataSourceName="layer-groups"
					queryParams={{ queries: queries, sort: { sortField: "name" } }}
					cellFormatter={this.groupCellFormatter}
				/>
			</Accordion>
		)
	}
}

export default LayerGroupAccordion
