import React, { CSSProperties } from "react"
import ImageUploading, { ImageListType } from "react-images-uploading"
import { Button, GridRow, Icon, Item, Segment } from "semantic-ui-react"
import { Style } from "@react-pdf/types"

import ImageList from "../components/Image/ImageListComponent"
import ImageUploadOverLay from "../../components/ImageUploadOverlay"

interface ImageUploadComponentProps {
	maxImages: number
	imageChange: any
	removeImage: any
	imageCapture: any
	imageLinks: URL[]
	allowNonImageType?: boolean
	style?: Style | CSSProperties
}

export interface ImageUploadComponentState {
	isDragging: boolean
	changed: boolean
}

export class ImageUploadComponent extends React.Component<ImageUploadComponentProps, ImageUploadComponentState> {
	state = {
		changed: false,
		isDragging: false,
	}

	render() {
		const { maxImages, imageChange, imageLinks, removeImage, imageCapture, allowNonImageType } = this.props
		const { isDragging } = this.state
		const limitReached = imageLinks.length >= maxImages
		let dragTimeout: string | number | NodeJS.Timeout | undefined
		const style = this.props.style as CSSProperties
		return (
			<ImageUploading multiple value={[]} onChange={imageChange} maxNumber={maxImages} allowNonImageType={allowNonImageType}>
				{({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, dragProps }) => (
					<>
						<div
							style={{
								width: "100%",
								position: "relative",
								minHeight: "100px",
								display: "flex",
								alignItems: "center",
								...style,
							}}
							{...dragProps}
							onDragEnter={(e) => {
								e.preventDefault()
								clearTimeout(dragTimeout)
								this.setState({ isDragging: true })
							}}
							onDragLeave={(e) => {
								e.preventDefault()
								e.stopPropagation()
								dragTimeout = setTimeout(() => this.setState({ isDragging: false }), 1500)
							}}
							onDrop={(e) => {
								e.preventDefault()
								e.stopPropagation()
								this.setState({ isDragging: false })
								if (limitReached) return
								dragProps.onDrop(e)
							}}
						>
							<div style={{ pointerEvents: isDragging ? "none" : "unset" }}>
								{imageLinks.length ? (
									<Item.Group divided>
										<Item>
											<Segment inverted basic>
												<Item.Group divided>
													<ImageList
														imageLinks={imageLinks}
														hidePlaceholder={true}
														edit={true}
														removeImage={removeImage}
													></ImageList>
												</Item.Group>
											</Segment>
										</Item>
									</Item.Group>
								) : null}
								<GridRow>
									<Button
										content={
											limitReached
												? "Maximum Reached"
												: allowNonImageType
												? `Upload Media and Files here`
												: `Click or Drop Images here`
										}
										color={limitReached ? "grey" : isDragging ? "blue" : "blue"}
										disabled={limitReached}
										onClick={onImageUpload}
										icon={limitReached ? null : <Icon name="upload" />}
									/>
									{imageCapture ? (
										<Button
											disabled={limitReached}
											className="image-control"
											color={limitReached ? "grey" : "blue"}
											size="mini"
											onClick={imageCapture}
										>
											<Icon name="camera" />
										</Button>
									) : null}
								</GridRow>
							</div>
							<ImageUploadOverLay isDragging={isDragging} isMaximum={limitReached} />
						</div>
					</>
				)}
			</ImageUploading>
		)
	}
}
