import React, { Component } from "react"
import { Segment } from "semantic-ui-react"
import { View } from "@react-pdf/renderer"

import { printData, printMessage } from "../../../providers/remoteHQ"
import { isStoredItem } from "../.."
import { parseQueries, storedItem } from "../../storedItem"
import { IParsedQuery, messageLevels } from "../../types"
import { FormItemComponent } from "../react/Items/FormItemComponent"
import { FormFieldProperties } from "../index"
import { FormBlock } from "../FormBlock"
import { PDFFormItem } from "./Items/PDFFormItem"
import { PDFBlockComponent } from "./PDFBlockComponent"
// export interface FormBlockComponentProps {
// 	item?: storedItem | null;
// 	index: number;
// 	field: FormFieldProperties;
// 	updateFormData?: any;
// 	errors?: string[];
// 	displayMode?: boolean;
// 	setValue?: any;
// 	setDirty?: any;
// 	changed?: boolean;
// }
// export interface FormBlockComponentState {
// 	subItem: storedItem;
// 	visible: boolean;
// 	queryParsed: IParsedQuery[];
// 	n: number;
// }

export class PDFHorizontalGroup extends FormBlock {
	state = {
		subItem: this.props.item,
		visible: false,
		queryParsed: [],
		n: 0,
	}
	componentDidMount() {}
	prepareConditional = () => {
		const theConditional = this.props.field.conditional
		if (theConditional && theConditional.queries && theConditional.queries.length > 0) {
			const theParsedQueries = parseQueries(theConditional.queries)
			this.setState({ queryParsed: theParsedQueries })
			printData(theParsedQueries, messageLevels.debug, "Parsed queries in prepareConditional")
		}
	}
	checkShouldShow = (): boolean => {
		if (this.state.queryParsed) {
			printData(this.props.field.conditional?.searchOrMode ?? false, messageLevels.debug, "SearchORMode in checkShouldShow")
			const isIn = this.props.item?.matchItem(this.state.queryParsed, this.props.field.conditional?.searchOrMode ?? false)
			printData(isIn, messageLevels.debug, "isIn in checkShouldShow")
			if (isIn !== null) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}
	updateVisible = () => {
		this.setState({ visible: this.checkShouldShow() })
	}
	updateFormData = (field: FormFieldProperties, value: any) => {}
	setValue = (value: any, forKey: string) => {}
	setDirty = () => {}
	renderField(field: FormFieldProperties, index: number) {
		switch (field.type.toLowerCase()) {
			case "block":
				return (
					<PDFBlockComponent
						item={this.state.subItem}
						field={field}
						key={index}
						index={index}
						setDirty={this.setDirty}
						container={this.props.container}
					></PDFBlockComponent>
				)

			default:
				return (
					<PDFFormItem
						item={this.state.subItem}
						field={field}
						key={index}
						updateFormData={this.updateFormData}
						index={index}
						setDirty={this.setDirty}
						container={this.props.container}
					></PDFFormItem>
				)
		}
	}
	renderFieldWrapped(field: FormFieldProperties, index: number) {
		const theSubfields = this.props.field.subfields
		const theCount = theSubfields?.length || 1

		const cellWidth = 100 / theCount + "%"
		const container = this.props.container
		const styles = container.styles
		// printData(cellWidth, messageLevels.verbose, "cellWidth");
		return <View style={{ width: cellWidth }}>{this.renderField(field, theCount)}</View>
	}
	renderFields = () => {
		printMessage("HorizontalGroup renderFields", messageLevels.verbose)
		printData(this.props.field.subfields, messageLevels.verbose, "this.props.field.subfields")
		return this.props.field.subfields?.map((subfield, idx) => {
			return this.renderFieldWrapped(subfield, idx)
		})
	}

	render() {
		const isShow = this.checkShouldShow()
		const container = this.props.container
		const styles = container.styles
		const theStyle = this.props.field.style || {}
		printMessage("HorizontalGroup render", messageLevels.verbose)
		printData(theStyle, messageLevels.verbose, "theStyle")

		if (isShow) {
			return <View style={[theStyle, styles.row]}>{this.renderFields()}</View>
		} else {
			return null
		}
	}
}
