import React from "react"
import { Form, Label } from "semantic-ui-react"
import DatePicker from "react-datepicker"
import moment from "moment"

import { getStoreTypeByName, isStoredItem, registerforStateChanges, removeOnChange, removeStateChanges, requestLoad } from "../../index"
import { storedItem } from "../../storedItem"
import { EditViewProps } from "../Types"
import { printMessage } from "../../../providers/remoteHQ"
import { messageLevels } from "../../types"
import variables from "../../../variables"
const { skidComplianceOptions: compliance } = variables

export interface ReportDetailState {
	officersList: any
	contractorList: any
	forestManagerList: any
	changed: boolean
	changeCount: number
}

export default class ReportDetailEditView extends React.Component<EditViewProps, ReportDetailState> {
	state = {
		officersList: [],
		contractorList: [],
		forestManagerList: [],
		changed: false,
		changeCount: 0,
	}
	componentDidMount() {
		requestLoad("contractors-list", this.stateChanged)
		requestLoad("officers-list", this.stateChanged)
		requestLoad("forest-manager-list", this.stateChanged)
		registerforStateChanges(this.stateChanged)

		this.loadContractors()
		this.loadOfficers()
		this.loadManagers()
	}
	componentDidUpdate(prevProps: EditViewProps) {
		if (prevProps.editingItem !== this.props.editingItem) {
			this.loadContractors()
			this.loadOfficers()
			this.loadManagers()
		}
	}

	componentWillUnmount() {
		removeOnChange("contractors-list", this.stateChanged)
		removeOnChange("officers-list", this.stateChanged)
		removeOnChange("forest-manager-list", this.stateChanged)
		removeStateChanges(this.stateChanged)
	}
	setValue = (value: any, forKey: string) => {
		const theItem: storedItem | null | undefined = this.props.editingItem
		if (theItem && theItem !== undefined) {
			printMessage("setting value: " + value, messageLevels.debug)
			theItem.setValue(value, forKey)
			theItem.validationForKey(forKey)
			this.state.changeCount = theItem._serial
			if (this.props.setDirty) {
				this.props.setDirty()
			}
		}
	}

	errorForKey = (forKey: string): any => {
		const theItem: storedItem | null | undefined = this.props.editingItem
		if (theItem && theItem !== undefined) {
			return theItem.validationErrorForKey(forKey)
		}
		return null
	}

	validForSave = () => {
		return true
	}
	stateChanged = (type: string, key: any, data: any) => {
		let loadContractors = false
		let loadOfficers = false
		let loadManagers = false

		if (type === "officers-list" && key === "") {
			loadOfficers = true
		} else if (type === "contractors-list" && key === "") {
			loadContractors = true
		} else if (type === "forest-manager-list" && key === "") {
			loadManagers = true
		}

		if (loadContractors) {
			this.loadContractors()
		}
		if (loadOfficers) {
			this.loadOfficers()
		}
		if (loadManagers) {
			this.loadManagers()
		}
	}

	loadContractors() {
		const theList = this.loadList("contractors-list")
		if (theList && theList !== undefined) {
			this.setState({ contractorList: theList })
		}
	}

	loadOfficers() {
		const theList = this.loadList("officers-list")
		if (theList && theList !== undefined) {
			this.setState({ officersList: theList })
		}
	}

	loadManagers() {
		const theList = this.loadList("forest-manager-list")
		if (theList && theList !== undefined) {
			this.setState({ forestManagerList: theList })
		}
	}

	loadList(type: string): storedItem[] | undefined {
		const listType = getStoreTypeByName(type)

		const theList = listType?.matchingItems({ sort: { sortField: "displayName" } })
		return theList
	}
	render() {
		const { contractorList, forestManagerList } = this.state

		const { visible, editingItem } = this.props
		if (!editingItem || !isStoredItem(editingItem)) return null

		return (
			<Form>
				<div className={`report-form${visible ? " active" : ""}`}>
					<Form.Input
						label="Save As"
						placeholder="Forest Assessment PDF title"
						name="title"
						value={editingItem?.getValue("title") || ""}
						onChange={(e, data) => this.setValue(e.target.value, "title")}
					/>
					<Form.Select
						label="Compliance Status"
						placeholder="Select a Compliance Status"
						name="complianceStatus"
						labeled
						required
						options={Object.values(compliance).map((value) => ({
							key: value.KEY,
							value: value.KEY,
							text: value.definition,
							label: { color: value.color.name },
						}))}
						onChange={(e, data) => this.setValue(data.value, "compliance_status")}
						value={editingItem?.getValue("compliance_status") || ""}
						error={this.errorForKey("compliance_status")}
					/>
					<div className="required field">
						<label>Inspection Date</label>
						<DatePicker
							isClearable
							required
							value={editingItem.getValue("inspection_date") || ""}
							placeholderText="Select an Inspection Date"
							dateFormat="DD MMMM YYYY"
							onChange={(value) => {
								const date = value ? moment(value).format("DD MMMM yyyy") : null
								this.setValue(date, "inspection_date")
							}}
						/>
					</div>
					<Form.Select
						label="Contact Person/Manager"
						placeholder="Select a Contact Person"
						name="contactPerson"
						options={forestManagerList.map((officer: any) => ({
							key: officer.uuid,
							value: officer.displayName(),
							text: officer.displayName(),
						}))}
						value={editingItem.getValue("contact_person")}
						onChange={(e, data) => this.setValue(data.value, "contact_person")}
						defaultValue={editingItem.getValue("contact_person")}
						required
						error={this.errorForKey("contact_person")}
					/>
					<Form.Select
						label="Earthworks Contractor"
						placeholder="Select an Earthworks Contractor"
						name="earthworksContractor"
						options={contractorList.map((contractor: any) => ({
							key: contractor.uuid,
							value: contractor.displayName(),
							text: contractor.displayName(),
						}))}
						value={editingItem.getValue("earthworks_contractor")}
						onChange={(e, data) => this.setValue(data.value, "earthworks_contractor")}
						defaultValue={editingItem.getValue("earthworks_contractor")}
						required
						error={this.errorForKey("earthworks_contractor")}
					/>
					<Form.Select
						label="Harvesting Contractor"
						placeholder="Select a Harvesting Contractor"
						name="harvestingContractor"
						options={contractorList.map((contractor: any) => ({
							key: contractor.uuid,
							value: contractor.displayName(),
							text: contractor.displayName(),
						}))}
						value={editingItem.getValue("harvesting_contractor")}
						onChange={(e, data) => this.setValue(data.value, "harvesting_contractor")}
						defaultValue={editingItem.getValue("harvesting_contractor")}
						required
						error={this.errorForKey("harvesting_contractor")}
					/>
					<Form.TextArea
						label="Status of work and area inspected"
						maxLength={2000}
						placeholder="Description..."
						name="statusOfWork"
						defaultValue={editingItem.getValue("status_of_work")}
						onChange={(e, data) => this.setValue(e.target.value, "status_of_work")}
						required
						error={this.errorForKey("status_of_work")}
					/>
					<Form.TextArea
						label="Waterways or Ocean in/bordering block"
						maxLength={200}
						placeholder="Description..."
						name="waterOrOcean"
						defaultValue={editingItem.getValue("proximity_to_waterways")}
						onChange={(e, data) => this.setValue(e.target.value, "proximity_to_waterways")}
						required
						error={this.errorForKey("proximity_to_waterways")}
					/>
				</div>
			</Form>
		)
	}
}
