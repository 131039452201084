import "semantic-ui-css/semantic.min.css"
import "./App.css"

import React, { useEffect, createContext } from "react"
import { Dimmer, Loader, Message, Sidebar } from "semantic-ui-react"
import { useNavigate } from "@tanstack/react-router"
import {
	clearAllStoreCache,
	getClassNamePrefix,
	getConfig,
	getGlobalState,
	getItem,
	getPropertyArray,
	getStateValue,
	getStoreTypeByName,
	isNgatiApa,
	isStoredItem,
	itemByKey,
	registerCall,
	registerforStateChanges,
	registerOnChange,
	requestLoad,
	setGlobalState,
} from "./store"
import CapturableArea from "./components/Capture"
import ElevationModel from "./mapsight/ElevationModel"
import InfoLayer from "./containers/sidebars/InfoLayer"
import InfoModal from "./components/InfoModal"
import Map from "./Map"
import LoadingPage from "./components/LoadingPage"
import NgatiApaSidebar from "./containers/sidebars/NgatiApaSidebar"
import RemoteHQSidebar from "./containers/sidebars/RemoteHQSidebar"
import SaveEditModal from "./components/SaveEditModal"
import StatusBar from "./components/StatusBar"
import WelcomeBanner from "./components/WelcomeBanner"
import TopProgressBar from "./components/ProgressBar/TopProgressBar"
import UserPanel from "./components/UserPanel"
import { Levels } from "./definitions"
import * as Cognito from "./providers/cognito"
import { messageLevels } from "./store/types"
import { printData, printMessage } from "./providers/remoteHQ"
import { modeManager } from "./mapsight/ModeManager"

function RedirectToLogin() {
	const navigate = useNavigate({ from: "/" })
	useEffect(() => {
		navigate({ to: "/login" })
	}, [])
	return <></>
}

export const MapReadyContext = createContext(false)

class App extends React.Component {
	mapRef = React.createRef()

	callbacks = {}
	clickHandlers = {}

	user = null
	modeManager = modeManager

	state = {
		activeCaptureKey: null,
		activeMapStyle: {},
		aspectRatio: [9, 16],
		captureCallback: null,
		errorType: null,
		homeLevel: "country",
		homeLevels: [{ level: "country", selected: "NZL" }],
		isConfigLoaded: false,
		isFormsLoaded: getStateValue("isFormsLoaded"),
		isLoggedIn: getGlobalState("loginState").getValue("isLoggedIn"),
		isMapLoading: true,
		isShowError: false,
		isUserLoaded: false,
		layerGroups: [],
		layers: [],
		level: "country",
		levels: [{ level: "country", selected: "NZL" }],
		levelType: "boundaries",
		loggedIn: false,
		mapStyleOptions: [],
		regions: ["NZL"],
		selected: [],
		selectedItems: [],
		sidebar: 1,
		userRegions: ["NZL"],
	}

	onActiveCapture = (captureKey, callback, aspectRatio = [9, 16]) => {
		this.setState({ activeCaptureKey: captureKey, aspectRatio, captureCallback: callback })
		if (captureKey) {
			setGlobalState("hide-markers", false)
		}
	}

	onNetworkError = (type, key, data) => {
		if (key === "linkLayersPermissionError") {
			const isShowError = data.getValue()
			this.setState({ isShowError, errorType: "warning" }, () =>
				setTimeout(() => {
					this.setState({ isShowError: false })
				}, 5000),
			)
		}
	}

	componentDidMount() {
		registerforStateChanges(this.dataChanged)
		requestLoad("boundaries", null, this.boundariesLoaded)
		requestLoad("map-layers")
		requestLoad("layer-groups")
		registerOnChange("user-profile", this.userChanged)
		registerOnChange("data-types", this.dataTypesLoaded)
		registerCall("goToHome", this.goToHome)
		registerCall("setLevelByItem", this.setLevelByItem)
		registerCall("setRolloverByItem", this.setRolloverByItem)
		registerCall("mapIsLoaded", this.mapIsLoaded)
		registerCall("mapIsLoading", this.mapIsLoading)

		registerCall("onActiveCapture", this.onActiveCapture)
		registerforStateChanges(this.onNetworkError)
		registerforStateChanges(this.onConfigLoaded)
		registerforStateChanges(this.onLoginStateLoaded)
		registerforStateChanges(this.onFormsLoaded)
		this.setState({ isConfigLoaded: getStateValue("isConfigLoaded") })
	}

	onLoginStateLoaded = (type, key, data) => {
		if (key === "loginState" && this.state.isLoggedIn !== data.getValue("isLoggedIn")) {
			this.setState({ isLoggedIn: data.getValue("isLoggedIn") })
		}
	}

	onFormsLoaded = (type, key, data) => {
		if (key === "isFormsLoaded") {
			data.getValue() && this.setState({ isFormsLoaded: true })
		}
	}

	onConfigLoaded = (type, key, data) => {
		if (key === "isConfigLoaded") {
			this.setState({
				isConfigLoaded: !!data.getValue(),
			})
			document.getElementById("root").classList.add(getClassNamePrefix())
		}
	}

	onCapture = (src) => {
		const { activeCaptureKey } = this.state

		if (this.state.captureCallback) {
			this.state.captureCallback(src, activeCaptureKey)
		}
		this.setState({ activeCaptureKey: null })
		setGlobalState("hide-markers", false)
	}

	render() {
		const {
			isConfigLoaded,
			isFormsLoaded,
			activeCaptureKey,
			aspectRatio,
			isUserLoaded,
			isMapLoading,
			isShowError,
			errorType,
			isLoggedIn,
		} = this.state

		const Navbar = isNgatiApa() ? NgatiApaSidebar : RemoteHQSidebar

		const showDimmer = isMapLoading || !isUserLoaded

		if (isConfigLoaded && isFormsLoaded && !isLoggedIn && isNgatiApa()) {
			return <RedirectToLogin />
		} else if ((isConfigLoaded && isFormsLoaded && isLoggedIn && isNgatiApa()) || !isNgatiApa()) {
			return (
				<MapReadyContext.Provider value={!this.state.isMapLoading}>
					<WelcomeBanner />
					<TopProgressBar />
					{isShowError ? (
						<Message
							style={{ margin: 0 }}
							error={errorType === "error"}
							warning={errorType === "warning"}
							header={isShowError}
						/>
					) : null}
					<Navbar isCapturing={!!activeCaptureKey} onActiveCapture={this.onActiveCapture}>
						<UserPanel hidden={!!activeCaptureKey} />
						<ElevationModel />
						<InfoLayer visible={true} minimize={!!activeCaptureKey} />
						<Sidebar.Pusher className="map-container">
							<Dimmer active={showDimmer}>
								<Loader />
							</Dimmer>
							<CapturableArea
								onCapture={this.onCapture}
								onCancel={() => this.onActiveCapture(null)}
								active={activeCaptureKey}
								aspectRatio={aspectRatio}
							>
								<Map
									key={4}
									accessToken={getConfig("mapboxToken")}
									ref={this.mapRef}
									level={this.state.level}
									callbacks={this.callbacks}
									setLayerGroups={this.setLayerGroups}
									setLayers={this.setLayers}
									toggleMapLoad={(isMapLoading) => this.setState({ isMapLoading })}
								/>
							</CapturableArea>
						</Sidebar.Pusher>
						<InfoModal />
					</Navbar>
					<StatusBar />
					<SaveEditModal />
				</MapReadyContext.Provider>
			)
		} else {
			return <LoadingPage />
		}
	}

	setStateToLevels = async (levels, level, regions, levelType, selected, selectedItems, skipSetState) => {
		if (selected && !Array.isArray(selected)) {
			selected = [selected]
		}

		if (!selectedItems && selected && selected.length === 1) {
			const theItem = await getItem(levelType, selected[0])
			selectedItems = [theItem]
		}
		setGlobalState({
			level: level,
			levels: levels,
			regions: regions,
			selected: selectedItems || [],
		})
		if (skipSetState) {
			this.setState(
				{
					selected: selected,
					selectedItems: selectedItems || [],
				},
				() => {
					this.stateChanged()
				},
			)
			return
		}
		this.setState(
			{
				levels: levels,
				level: level,
				regions: regions,
				levelType: levelType,
				selected: selected,
				selectedItems: selectedItems || [],
			},
			() => {
				this.stateChanged()
			},
		)
	}

	dataTypesLoaded = (type, key, data) => {
		printMessage("Data types loaded callback in App.js", messageLevels.debug)
	}

	boundariesLoaded = (type, key, data) => {
		Cognito.onLoginStateChange()
	}

	dataChanged = (type, key, data) => {
		printMessage("Type In App: " + type, messageLevels.debug)
		printMessage("Key: " + key, messageLevels.debug)
		printMessage("Data: " + data, messageLevels.debug)
		if (key === "level") {
			printMessage("Key: " + key, messageLevels.debug)
		}
		if (key === "loginState") {
			this.setState({ loggedIn: data.getValue("isLoggedIn") }, () => {
				this.setupHome()
			})
		}
	}

	userChanged = async (type, key, data) => {
		printMessage("Type in App: " + type, messageLevels.debug)
		printMessage("UserData: " + data, messageLevels.debug)

		if (type === "user-profile" && data) {
			let theRegions = ["NZL"]
			if (data.regions && data.regions.length > 0 && data.regions !== "all") {
				theRegions = data.regions.split(",")
			}
			printMessage("The Regions[0] " + theRegions[0], messageLevels.debug)
			const theRegion = await getItem("boundaries", theRegions[0])
			printData(theRegion, messageLevels.debug, "The region in userChanged")
			if (theRegion) {
				const levelName = theRegion.levelName()
				const levelType = theRegion.getTypeName()
				const regionsCopy = Object.assign(theRegions)
				printMessage("Top level : " + levelName, messageLevels.debug)

				this.setState(
					{
						userRegions: theRegions,
						topLevel: levelName,
						topLevelType: levelType,
						regions: regionsCopy,
					},
					() => {
						this.setupHome()
					},
				)
			}
		}
	}

	setupHome = async () => {
		printMessage("Setup Home", messageLevels.debug)
		printData(this.state.userRegions, messageLevels.debug, "this.state.userRegions in setupHome")
		const homeLevels = await this.buildStateArray("boundaries", this.state.userRegions)
		const levelsCopy = Object.assign([], homeLevels)
		setGlobalState("homeLevels", homeLevels)
		this.setState(
			{
				homeLevels: homeLevels,
				levels: levelsCopy,
				homeLevel: this.state.topLevel,
			},
			() => {
				printMessage("State changed to home", messageLevels.debug)
			},
		)
		const regionsCopy = Object.assign([], this.state.userRegions)
		printData(regionsCopy, messageLevels.debug, "regionsCopy in setupHome")
		const homeStateInfo = [levelsCopy, this.state.topLevel, regionsCopy, "boundaries", regionsCopy]
		setGlobalState("homeStateInfo", homeStateInfo)
		this.setStateToLevels(...homeStateInfo)
		this.setState({ isUserLoaded: true })
	}

	goToHome = () => {
		printMessage("Go to Home", messageLevels.debug)
		clearAllStoreCache()
		const homeStateInfo = getStateValue("homeStateInfo")
		if (homeStateInfo) {
			this.setStateToLevels(...homeStateInfo)
		}
	}

	setFiltersToLevel = async () => {
		printMessage("setFiltersToLevel", messageLevels.debug)
		const theRegions = this.stateRegionSelect()
		// const theRegions = this.state.regions;
		// doesnt work because tracking against all levels

		printData(theRegions, messageLevels.debug, "regions in setFiltersToLevel")
		getStoreTypeByName("map-layers")?.forEach((item) => {
			item.setupForLevel()
		})
		getStoreTypeByName("map-sources")?.forEach((item) => {
			item.setupForLevel()
		})
	}
	// Find the selected regions from the levels array
	stateRegionSelect = () => {
		printMessage("stateRegionSelect", messageLevels.debug)
		let theRegions = null
		printData(this.state.levels, messageLevels.debug, "Levels in stateRegionSelect")
		const theLevels = this.state.levels

		printMessage("Type of theLevels: " + typeof theLevels, messageLevels.debug)

		theLevels.forEach((value) => {
			if (value.level === "region" || value.level === "region(s)") {
				theRegions = value.selected
			}
		})
		return theRegions
	}

	// create the array used to store the drill-down state fromm the region(s) iso
	// currently will drop the multi-region state out if not the state passed in
	// need to add the logic to check the user's multi-region status and re-create

	// re-working to accept a type / key pair for any item or type/ key[] pair
	buildStateArray = (selectedType, selectedKey) => {
		printData(selectedType, messageLevels.debug)
		printData(selectedKey, messageLevels.debug)

		const theLevels = []

		if (Array.isArray(selectedKey)) {
			printMessage("Is array", messageLevels.debug)
		}

		let theItem = itemByKey(selectedType, selectedKey)
		printData(theItem, messageLevels.debug, "Top Item")
		if (theItem) {
			let type = theItem.getTypeName()
			let theParent = theItem.getParent()

			let levelName = theItem.levelName()

			let iso = theItem.primaryKey()

			let categoryModifier = ""
			if (Array.isArray(selectedKey) && selectedKey.length > 1) {
				categoryModifier = "(s)"
				iso = selectedKey
			}
			const theLevel = {
				level: levelName + categoryModifier,
				selected: iso,
				type: type,
				selectedItems: [theItem],
			}
			printData(theLevel, messageLevels.debug, "Level 1")
			theLevels.push(theLevel)

			if (levelName !== this.state.topLevel && theParent) {
				if (theParent) {
					const theItem = theParent
					printData(theItem, messageLevels.debug, "Boundary")
					if (theItem) {
						levelName = theItem.levelName()

						theParent = theItem.getParent()
						type = theItem.getTypeName()
						iso = theItem.iso
					} else {
						theParent = null

						type = ""
					}
					const theLevel = {
						level: levelName + categoryModifier,
						selected: iso,
						type: type,
						selectedItems: [theItem],
					}
					printData(theLevel, messageLevels.debug, "Level 2")

					theLevels.unshift(theLevel)
				}
			}

			printData(theLevels, messageLevels.debug, "theLevels in buildStateArray")
			setGlobalState("level", levelName + categoryModifier)
			setGlobalState("levels", theLevels)
			return theLevels
		}
		setGlobalState("levels", theLevels)
		return theLevels
	}

	handleLogOut = async () => {
		Cognito.logOut()
	}
	setMapStyleOptions = (mapStyleOptions) => {
		this.setState({ mapStyleOptions: mapStyleOptions })
	}

	setLayerGroups = (layerGroups) => {
		this.setState({ layerGroups: layerGroups })
	}

	setLayers = (layers) => {
		this.setState({ layers: layers })
	}

	getLayerVisibility = (layerName) => {
		if (this.mapRef && typeof this.mapRef !== "undefined") {
			const theVisibility = this.mapRef.current.getLayerVisibility(layerName)

			return theVisibility
		}
		printMessage("no map", messageLevels.error)

		return ""
	}

	setLayerVisibility = (layerName, visibility) => {
		if (typeof this.mapRef !== "undefined" && typeof this.mapRef.current !== "undefined" && this.mapRef.current) {
			this.mapRef.current.setLayerVisibility(layerName, visibility)
		}
	}

	setLayerFilter = (layerName, filter) => {
		if (typeof this.mapRef !== "undefined" && typeof this.mapRef.current !== "undefined" && this.mapRef.current) {
			this.mapRef.current.setFilter(layerName, filter)
		}
	}

	zoomToFeature = (feature) => {
		if (typeof this.mapRef !== "undefined" && typeof this.mapRef.current !== "undefined" && this.mapRef.current) {
			return this.mapRef.current.zoomToFeature(feature)
		}
	}
	zoomToBounds = (bounds) => {
		if (typeof this.mapRef !== "undefined" && typeof this.mapRef.current !== "undefined" && this.mapRef.current) {
			return this.mapRef.current.zoomToBounds(bounds)
		}
	}
	zoomToBoundsArray = (boundsArray) => {
		if (typeof this.mapRef !== "undefined" && typeof this.mapRef.current !== "undefined" && this.mapRef.current) {
			return this.mapRef.current.zoomToBoundsArray(boundsArray)
		}
	}

	setLevelByTypeKey = async (type, key) => {
		const theItem = getItem(type, key)
		const theRegion = theItem.getRegionISO()
		const theLayers = this.buildStateArray(type, key)
		this.setStateToLevels(theLayers, theItem.levelName(), [theRegion], type, key)
		this.setLevelRegion(theRegion)
	}

	setLevelByItem = (item, skipSetState) => {
		printData(item, messageLevels.debug, "setLeveleByItem")
		const theRegion = item?.getRegionISO() || "NZL"
		const theLayers = this.buildStateArray(item?.getTypeName?.(), item?.primaryKey())
		this.setStateToLevels(theLayers, item?.levelName(), [theRegion], item?.getTypeName?.(), item?.primaryKey(), null, skipSetState)
		this.setLevelRegion(theRegion)
	}
	setLevelByItems = (items) => {
		printData(items, messageLevels.debug, "setLevelByItems")
		if (items) {
			if (!Array.isArray(items)) {
				items = [items] || []
			}
			const theRegions = []
			const theKeys = []
			items.forEach((theItem) => {
				theKeys.push(theItem.primaryKey())
				if (isStoredItem(theItem) && theItem.getRegionISO()) {
					theRegions.push(theItem.getRegionISO())
				}
			})
			const theType = items[0].getTypeName()
			let theLevelName = items[0].levelName()
			if (items.length > 0) {
				theLevelName += "(s)"
			}
			const theLayers = this.buildStateArray(theLevelName, theKeys, items)
			this.setStateToLevels(theLayers, theLevelName, theRegions, theType, theKeys)
			this.setLevelRegion(theRegions)
		}
	}

	setLevelFromRegion = async (theRegion) => {
		const theRegionItem = await getItem("boundaries", theRegion)
		printData(theRegion, messageLevels.debug, "Region:")

		const theLayers = this.buildStateArray("boundaries", theRegion)
		this.setStateToLevels(theLayers, theRegionItem.getValue("iso_subdivision_category"), [theRegion])
	}
	setLevelFromRegions = async (theRegions) => {
		const theRegionItem = await getItem("boundaries", theRegions[0])

		printData(theRegions, messageLevels.debug, "Regions:")

		const theLayers = this.buildStateArray("boundaries", theRegions)
		if (theRegionItem) {
			this.setStateToLevels(theLayers, theRegionItem.getValue("iso_subdivision_category"), theRegions)
		}
	}

	setLevelByFeature = async (layerID, feature) => {
		if (feature && feature.properties) {
			const properties = feature.properties
			if (properties.iso) {
				const theRegion = properties.iso
				const theRegionItem = await getItem("boundaries", theRegion)
				printData(theRegion, messageLevels.debug, "Region:")

				const theLayers = this.buildStateArray("boundaries", theRegion)
				this.setStateToLevels(theLayers, theRegionItem.getValue("iso_subdivision_category"), [theRegion])
				this.setLevelRegion(theRegion)
			}
		}
	}

	stateChanged = () => {
		printData(this.state, messageLevels.debug, "State in State Changed")
		let theRegions = this.stateRegionSelect()
		printData(theRegions, messageLevels.debug, "regions in State changed")
		this.setFiltersToLevel()

		if (!theRegions) {
			theRegions = this.state.regions
			printData(theRegions, messageLevels.debug, "got from state")
		}
		if (!Array.isArray(theRegions)) {
			theRegions = [theRegions]
		}
		const zoomArray = getPropertyArray(this.state.levelType, this.state.selected, "zoomOnSelect")
		printData(zoomArray, messageLevels.debug, "zoomOnSelect array in State changed")
		let zoomOnSelect = false
		if (zoomArray) {
			zoomArray.forEach((item) => {
				if (item === true) {
					zoomOnSelect = true
				}
			})
		}

		printData(zoomOnSelect, messageLevels.debug, "zoomOnSelect in State changed")
		if (zoomOnSelect) {
			const boundsStringArray = getPropertyArray(this.state.levelType, this.state.selected, "getBoundingBox")

			const boundsArray = []
			printData(boundsStringArray, messageLevels.debug, " Array")
			boundsStringArray.forEach((element) => {
				if (element) {
					boundsArray.push(JSON.parse(element))
				}
			})
			printData(boundsArray, messageLevels.debug, "Parsed Array")

			this.zoomToBoundsArray(boundsArray)
		}
	}

	setLevelRegion = async (regionISO) => {
		if (regionISO) {
			printMessage("regionISO: " + regionISO, messageLevels.debug)
			const theRegion = await getItem("boundaries", regionISO)
			printData(theRegion, messageLevels.debug)
			const theState = theRegion?.getValue("parent_iso")
			printMessage("State: " + theState, messageLevels.debug)
			printMessage("Country: " + theState, messageLevels.debug)
		}
		this.setState({ region: [regionISO], levelId: Levels.region, consent: null })
	}

	setLevelState = (layerId, feature) => {
		let levelData = null
		if (feature) {
		} else {
			levelData = this.state.state
		}

		this.setState({ levelId: Levels.state })
		this.mapRef.current.setConsentId(null)
		this.setState({ region: null })
		this.setState({ consent: null })

		this.setFiltersToUserRegions()

		this.handleBounds(feature, levelData)
	}

	setLevelCountry = (layerId, feature) => {
		printMessage("Country: " + layerId, messageLevels.debug)

		let levelData = null

		this.setState({
			levelId: Levels.country,
			levels: [
				{ level: Levels.country, selected: "NZ" },
				{ level: Levels.state, selected: null },
				{ level: Levels.region, selected: null },
				{ level: Levels.consent, selected: null },
			],
		})

		this.mapRef.current.setConsentId(null)

		this.handleBounds(feature, levelData)
	}
	// rollover handling
	setRolloverByItem(item) {
		if (item) {
			setGlobalState("hovers", [item])
		} else {
			setGlobalState("hovers", [])
		}
	}

	consentHeader = () => {
		return null
	}
	regionHeader = () => {
		return null
	}
	stateHeader = () => {
		return null
	}
	homeHeader = () => {
		return null
	}
	countryHeader = () => {
		return null
	}

	mapIsLoaded = () => {
		this.setState({ isMapLoading: false })
	}

	mapIsLoading = () => {
		this.setState({ isMapLoading: true })
	}
}

export default App
