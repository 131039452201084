import React, { Component, ReactNode } from "react"
import { Button, Form, Item, Segment } from "semantic-ui-react"
import { Page } from "@react-pdf/renderer"

import { IQueryParams, messageLevels } from "../../types"
import { storedItem } from "../../storedItem"
import { FormFieldProperties } from ".."
import { printMessage } from "../../../providers/remoteHQ"
import { PDFBlockComponent } from "./PDFBlockComponent"
import { PDFFormItem } from "./Items/PDFFormItem"
import { FormPage } from "../FormPage"
import { PDFHorizontalGroup } from "./HorizontalGroup"

export class PDFPage extends FormPage {
	state = {
		n: 0,
	}

	updateFormData = (field: FormFieldProperties, value: any) => {}
	renderField(field: FormFieldProperties, index: number) {
		switch (field.type.toLowerCase()) {
			case "block":
				return (
					<PDFBlockComponent
						item={this.props.item}
						field={field}
						key={index}
						index={index}
						displayMode={this.props.displayMode}
						setDirty={this.setDirty}
						setValue={this.setValue}
						container={this.props.container}
					></PDFBlockComponent>
				)
			case "horizontalgroup":
				return (
					<PDFHorizontalGroup
						item={this.props.item}
						field={field}
						key={index}
						index={index}
						displayMode={this.props.displayMode}
						setDirty={this.setDirty}
						setValue={this.setValue}
						container={this.props.container}
					></PDFHorizontalGroup>
				)

			default:
				return (
					<PDFFormItem
						item={this.props.item}
						field={field}
						key={index}
						updateFormData={this.updateFormData}
						index={field.name}
						displayMode={this.props.displayMode}
						setDirty={this.setDirty}
						setValue={this.setValue}
						container={this.props.container}
					></PDFFormItem>
				)
		}
	}
	renderFields = () => {
		return this.props.fields.map((field, idx) => {
			return this.renderField(field, idx)
		})
	}
	renderLeftButton = () => {
		if (this.props.index >= 1) {
			return (
				<Button secondary floated="left" onClick={() => this.props.onBack()}>
					Back
				</Button>
			)
		} else {
			return null
		}
	}
	renderRightButton = () => {
		if (this.props.index === 0) {
			return (
				<>
					{/* <Button secondary floated="left" onClick={() => this.props.onCancel()}>
						Cancel
					</Button> */}
					<Button primary floated="right" onClick={() => this.props.onNext()}>
						Next
					</Button>
				</>
			)
		} else if (this.props.index + 1 < this.props.pages) {
			return (
				<Button primary floated="right" onClick={() => this.props.onNext()}>
					Next
				</Button>
			)
		} else if (this.props.displayMode !== true) {
			return null

			// todo - replace this with controls at multi-page level and from a parameter in the form
			// return (
			// 	<Button primary floated="right" onClick={() => this.props.onSubmit()}>
			// 		Submit NES-CF Notification
			// 	</Button>
			// );
		}
	}
	setValue = (value: any, forKey: string) => {}

	setDirty = () => {}
	handleChange = (e: any) => {}
	render() {
		const container = this.props.container
		const styles = container?.styles
		const pageStyle = styles?.page
		const theStyle = this.props.page.style || {}

		if (this.props.item && this.props.item !== null) {
			return (
				<Page size="A4" style={[theStyle, pageStyle]}>
					{this.renderFields()}
				</Page>
			)
		}
		return null
	}
}
