import React from "react"

import { isStoredItem } from ".."
import DefaultSidebar from "./DefaultSidebar"
import { AreaOfEditView, AreaOfInfoView } from "./AreaOf"
import { TaskEditView, TaskInfoView } from "./Task"
import { StreamCrossingEditView, StreamCrossingInfoView } from "./StreamCrossing"
import { SkidSiteEditView, SkidSiteInfoView } from "./SkidSite"
import { EditViewProps, EditViewState, ItemViewProps, ItemViewState } from "./Types"
import { region } from "../classes/region"
import { ForestOwnershipEditView, ForestOwnershipInfoView } from "./ForestOwnership"
import { CatchmentEditView, CatchmentInfoView } from "./Catchment"
import PlaceNameInfoView from "./PlaceName"
import ArchaeologicalInfoView from "./NzaaArchaeologicalSiteInfoView"
import QuarryInfoView from "./Quarry"
import { RegionInfoView } from "./Region"
import { IslandInfoView } from "./Island"

export class EditView extends React.Component<EditViewProps, EditViewState> {
	render() {
		const theItem = this.props.editingItem
		if (!theItem || !isStoredItem(theItem)) {
			return <></>
		}
		let theType = theItem.getTypeName()
		if (!theType) {
			return <></>
		}
		if (theType === "boundaries") {
			theType = (theItem as region).getValue("iso_subdivision_category")
		}
		switch (theType) {
			case "country":
				return <DefaultSidebar />
			case "forest-ownership":
				return <ForestOwnershipEditView {...this.props} />
			case "areas-of-concern":
			case "areas-of-failure":
			case "areas-of-excellence":
				return <AreaOfEditView {...this.props} />
			case "tasks":
				return <TaskEditView {...this.props} />
			case "as-built-stream-crossings": // todo confirm data type
				return <StreamCrossingEditView {...this.props} />
			case "as-built-skid-sites":
				return <SkidSiteEditView {...this.props} />
			case "catchment":
			case "catchments-4":
			case "catchments-5":
			case "catchments-6":
				return <CatchmentEditView {...this.props} />
			default:
				return <div />
		}
	}
}

export class InfoView extends React.Component<ItemViewProps> {
	render() {
		const theItem = this.props.selectedItem
		if (!theItem || !isStoredItem(theItem)) {
			return <></>
		}
		let theType = theItem.getTypeName()
		if (!theType) {
			return <></>
		}
		if (theType === "boundaries") {
			theType = (theItem as region).getValue("iso_subdivision_category")
		}
		switch (theType) {
			case "forest-ownership":
				return <ForestOwnershipInfoView {...this.props} />
			case "country":
			case "state":
				return <DefaultSidebar />
			case "areas-of-concern":
			case "areas-of-failure":
			case "areas-of-excellence":
				return <AreaOfInfoView {...this.props} />
			case "tasks":
				return <TaskInfoView {...this.props} />
			case "as-built-stream-crossings":
				return <StreamCrossingInfoView {...this.props} />
			case "as-built-skid-sites":
				return <SkidSiteInfoView {...this.props} />
			case "catchment":
			case "catchments-4":
			case "catchments-5":
			case "catchments-6":
				return <CatchmentInfoView {...this.props} />
			case "place-names":
				return <PlaceNameInfoView {...this.props} />
			case "nzaa-archaeological-sites":
				return <ArchaeologicalInfoView {...this.props} />
			case "quarries":
				return <QuarryInfoView {...this.props} />
			case "region":
				return <RegionInfoView {...this.props} />
			case "island":
				return <IslandInfoView {...this.props} />
			default:
				return <div />
		}
	}
}

export class OtherView extends React.Component<ItemViewProps, ItemViewState> {
	state = {
		changed: false,
		itemType: "",
	}

	render() {
		const { selectedItem, editingItem, inEdit } = this.props

		if (inEdit) {
			return (
				<div id="feature-info-block">
					<EditView
						editingItem={editingItem}
						selectedItem={selectedItem}
						setValue={this.props.setValue}
						setDirty={this.props.setDirty}
					/>
				</div>
			)
		}
		return (
			<div id="feature-info-block">
				<InfoView selectedItem={selectedItem} />
			</div>
		)
	}
}
