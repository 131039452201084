import { printData, printMessage } from "../../providers/remoteHQ"
import { storedItem } from "../../store/storedItem"
import { ImageSupport, messageLevels } from "../types"
import { UUIDforMediaCollectionURL, imageItemsOrStrings, imageStringOrObjects, mediaCollectionURLforUUID } from "../URLRewrites"
import { fileUpload } from "./fileUpload"
import { mediaItem } from "./mediaItem"
import { fileUploadSequencer } from "../multiFileUploader"
import { getConfig, isUUID } from ".."
import { FormFieldProperties, Validation, createValidation } from "../Forms"
import { debounce } from "../../helpers/commonFunc"

interface IDictionary {
	[index: string]: any
}

export class forestNotification extends storedItem implements ImageSupport {
	_MultiFileUploader: fileUploadSequencer | null = null
	digital_signature = false
	created_date = new Date()

	displayName(): string {
		return "NES-CF form for " + this.getValue("forest_name") || this.primaryKey()
	}
	validation: Validation | undefined
	addFileUpload = (theUpload: fileUpload) => {
		theUpload.setForItem(this)
		// theUpload.addCallbackOnUpload(this.linkedUpdate);
		this.fileUploader.addFileUpload(theUpload)
		this._dirty = true
		this.addImageURL(mediaCollectionURLforUUID(theUpload.getUUID()).toString())
		this.doChangeCallbacks()
	}

	addImageURL = (theURL: string) => {
		const theImages: string[] = this.imageLinksCopy
		if (theImages.indexOf(theURL) < 0) {
			theImages.push(theURL)
			this.setValue(theImages, "image_links")
			this.doChangeCallbacks()
		}
	}
	uploadAll = () => {
		if (this._MultiFileUploader) {
			printMessage("Upload All in: " + this.displayName(), messageLevels.verbose)
			this.fileUploader.uploadAll()
		}
	}

	takeoverUploads = (fileUploader: fileUploadSequencer) => {
		if (fileUploader) {
			this.fileUploader.takeoverUploads(this, fileUploader)
		}
	}

	removeImage = (theURL: string | URL): boolean => {
		let done = false
		const urlString = theURL.toString()
		const imageUUID = UUIDforMediaCollectionURL(urlString)

		const isImageUUID = imageUUID !== ""

		if (isImageUUID) {
			done = this.removeImageUUID(imageUUID)
		} else {
			done = this.removeImageURL(urlString)
		}
		return done
	}

	removeImageURL = (theURL: string): boolean => {
		const theImages: string[] = this.imageLinksCopy

		let foundIndex = theImages.indexOf(theURL)

		if (foundIndex < 0) {
			let index = 0
			theImages.forEach((element) => {
				if (theURL.includes(element)) {
					foundIndex = index
				}
				index += 1
			})
		}

		if (foundIndex > -1) {
			theImages.splice(foundIndex, 1)

			this.setValue(theImages, "image_links")
			this._dirty = true
			this.doChangeCallbacks()
			return true
		}

		return false
	}

	removeImageUUID = (theUUID: string): boolean => {
		if (typeof theUUID !== "string") {
			return false
		}
		const theImages: string[] = this.imageLinksCopy || []
		let foundIndex = -1
		let done = false
		const theLowerUUID = theUUID.toLowerCase()
		let idx = 0

		theImages.forEach((item) => {
			if (item.toString().toLowerCase().includes(theLowerUUID)) {
				foundIndex = idx
			}
			idx += 1
		})

		if (foundIndex > -1) {
			theImages.splice(foundIndex, 1)

			this.setValue(theImages, "image_links")
			this._dirty = true
			this.doChangeCallbacks()
			done = true
		}

		if (this._MultiFileUploader) {
			const isChanged = this._MultiFileUploader.removeFileUploadByUUID(theUUID)
			if (isChanged) {
				this._dirty = true
				done = true
			}
		}
		return done
	}

	getImageURLs = (): URL[] => {
		return this.imageURLs
	}
	getHeroImageURL() {
		return this.heroImageURL
	}
	getHeroImageThumb() {
		return this.getHeroImageThumb
	}

	get heroImageURL() {
		const theImages = this.imageURLs
		if (theImages.length > 0) {
			return theImages[0]
		}
		return null
	}
	get heroImageThumb() {
		const theImages = this.imageThumbs
		if (theImages.length > 0) {
			return theImages[0]
		}
		return null
	}
	get imageURLs(): URL[] {
		return imageURLs(this)
	}

	get ImageItemsOrStrings(): (string | URL | mediaItem)[] {
		const theImages: string[] = this.getValue("image_links") || []
		return imageItemsOrStrings(theImages)
	}

	get imageStringorObjects(): (string | Buffer | { data: Buffer; format: "png" | "jpg" })[] {
		const theImages: (string | URL | mediaItem)[] = this.ImageItemsOrStrings
		return imageStringOrObjects(theImages)
	}

	get allImageURLs() {
		const imageLinks: URL[] = this.imageURLs
		if (this._MultiFileUploader) {
			this._MultiFileUploader.uploads.forEach((upload) => {
				const theURL = upload.URL
				imageLinks.push(theURL)
			})
		}
		return imageLinks
	}

	get imageLinksCopy(): string[] {
		const theImages: string[] = this.getValue("image_links") || []
		const imageLinks: string[] = []
		if (theImages.length > 0) {
			theImages.forEach((item) => {
				imageLinks.push(item)
			})
			return imageLinks
		}
		return []
	}
	get imageThumbs() {
		const theImages: string[] = this.getValue("image_links") || []
		const imageLinks: URL[] = []
		if (theImages.length > 0) {
			theImages.forEach((item) => {
				if (item.length > 0 && !item.endsWith("/")) {
					let theURL
					if (UUIDforMediaCollectionURL(item) !== "") {
						theURL = mediaCollectionURLforUUID(UUIDforMediaCollectionURL(item), "thumb")
					} else if (item.startsWith("http")) {
						theURL = new URL(item)
					} else if (isUUID(item)) {
						theURL = mediaCollectionURLforUUID(item, "thumb")
					} else {
						let modItem = item
						if (item.startsWith(".")) {
							modItem = item.substring(1)
						}
						if (!modItem.startsWith("/media/") && !modItem.startsWith("media/")) {
							modItem = "/media/" + item
						}
						theURL = new URL(modItem, getConfig("cdnUrl"))
					}

					imageLinks.push(theURL)
					printData(theURL, messageLevels.debug, item)
				}
			})
			return imageLinks
		}
		return []
	}

	get fileUploader() {
		if (!this._MultiFileUploader) {
			this._MultiFileUploader = new fileUploadSequencer()
			// this._MultiFileUploader.addCompletionCallback(this.storeEditPrepared);
		}
		return this._MultiFileUploader
	}
	get submittedBy(): storedItem | null {
		if (this.landownerIsSubmitter) {
			return this.landowner
		}
		if (this.managerIsSubmitter) {
			return this.manager
		}
		return null
	}

	keepChanges(callback?: Function): storedItem | null {
		// this.uploadAll();
		if (this.landowner) {
			;(this as any)._landownerItem = this.landowner.keepChanges()
		}
		if (this.manager) {
			;(this as any)._managerItem = this.manager.keepChanges()
		}
		if (this.landownerIsBiller) {
			this.setValue(this.getValue("landowner_id"), "billed_to_id")
		} else if (this.managerIsBiller) {
			this.setValue(this.getValue("manager_id"), "billed_to_id")
		} else if (this.billedTo) {
			this.billedTo.keepChanges()
		}
		const submitter = this.submittedBy
		printData(submitter, messageLevels.verbose, "submitter")
		if (submitter) {
			const email = submitter.getValue("email")
			this.setValue(email, "email_address")
		}

		if (this.managerIsSubmitter) {
			printMessage("managerIsSubmitter", messageLevels.verbose)
			this.setValue(this.manager?.primaryKey(), "submitter_id")
		} else if (this.landownerIsSubmitter) {
			printMessage("landownerIsSubmitter", messageLevels.verbose)
			this.setValue(this.manager?.primaryKey(), "submitter_id")
		}
		const theActivities = this.getValue("activities")
		if (theActivities) {
			const filteredActivities: IDictionary = {}

			Object.entries(theActivities).forEach(([key, value]) => {
				let include = false
				if (value !== undefined && value && typeof value === "object") {
					Object.entries(value).forEach(([key, value]) => {
						if (key === "checked" && value) {
							include = true
						}
					})
					if (include) {
						const valueObj = value as IDictionary
						if (valueObj && valueObj["dates"]) {
							filteredActivities[key] = valueObj["dates"]
						}
					}
				}
			})
			this.setValue(filteredActivities, "activities")
		}

		// const erosionArray = [];
		// if(this.getValue("ErosionSusceptibilityZoneGreen")) {
		// 	erosionArray.push("green");
		// }
		// if(this.getValue("ErosionSusceptibilityZoneYellow")) {
		// 	erosionArray.push("yellow");
		// }
		// if(this.getValue("ErosionSusceptibilityZoneOrange")) {
		// 	erosionArray.push("orange");
		// }
		// if(this.getValue("ErosionSusceptibilityZoneRed")) {
		// 	erosionArray.push("red");
		// }
		// this.setValue(erosionArray,"erosion_susceptibility_zones");

		const originalItem = super.keepChanges(callback)
		if (originalItem) {
			printData(originalItem, messageLevels.debug, "originalItem in keepChanges")
			;(originalItem as unknown as ImageSupport).takeoverUploads(this.fileUploader)
			;(originalItem as unknown as ImageSupport).uploadAll()
		}

		return originalItem
	}

	setActivityItem = (item: string, value: any) => {}

	get activityArray(): string[] {
		return (this.getValue("activity") || "").split(",")
	}
	set activityArray(theArray) {
		if (Array.isArray(theArray)) {
			this.setValue("activity", theArray.join(","))
		} else if (typeof theArray === "string") {
			this.setValue("activity", theArray)
		}
	}

	get managerIsBiller() {
		return this.getValue("ForestManagerIsBillingPerson")
	}
	set managerIsBiller(value: boolean) {
		this.setValue(value, "ForestManagerIsBillingPerson")
		if (value) {
			this.setValue(!value, "LandOwnerIsBillingPerson")
		}
		if (value) {
			this.setValue(this.getValue("managerID"), "billed_to_id")
		}
		printData(this, messageLevels.debug, "after managerIsBiller: " + value)
	}

	get landownerIsSubmitter(): boolean {
		return this.getValue("submitted_by").toLowerCase() === "landowner"
	}

	get managerIsSubmitter(): boolean {
		return this.getValue("submitted_by").toLowerCase() === "manager"
	}

	get landownerIsBiller() {
		return this.getValue("LandOwnerIsBillingPerson")
	}
	set landownerIsBiller(value: boolean) {
		this.setValue(value, "LandOwnerIsBillingPerson")
		if (value) {
			this.setValue(!value, "ForestManagerIsBillingPerson")
		}

		if (value) {
			this.setValue(this.getValue("landownerID"), "billed_to_id")
		}
		printData(this, messageLevels.debug, "after landownerIsBiller: " + value)
	}

	get billerType() {
		if (this.landownerIsBiller) {
			return "Landowner"
		}
		if (this.managerIsBiller) {
			return "Manager"
		}
		return "Other"
	}
	set billerType(type: string) {
		switch (type.toLowerCase()) {
			case "landowner":
				printMessage("Set biller to landowner: " + type, messageLevels.verbose)
				this.landownerIsBiller = true
				this.managerIsBiller = false
				break
			case "manager":
				printMessage("Set biller to manager: " + type, messageLevels.verbose)
				this.landownerIsBiller = false
				this.managerIsBiller = true
				break
			default:
				printMessage("Set biller to other: " + type, messageLevels.verbose)
				this.landownerIsBiller = false
				this.managerIsBiller = false
		}

		printData(this.landownerIsBiller, messageLevels.verbose, "this.landownerIsBiller ")
		printData(this.managerIsBiller, messageLevels.verbose, "this.managerIsBiller ")
	}

	get landowner(): storedItem | null {
		return this.getRelatedItemStored("forest-notification-contacts", this.getValue("landowner_id"), "primaryKey", "landowner")
	}
	get manager(): storedItem | null {
		return this.getRelatedItemStored("forest-notification-contacts", this.getValue("manager_id"), "primaryKey", "manager")
	}

	get billedTo(): storedItem | null {
		if (this.landownerIsBiller) {
			return this.landowner
		}
		if (this.managerIsBiller) {
			return this.manager
		}
		return this.getRelatedItemStored("forest-notification-contacts", this.getValue("billed_to_id"), "primaryKey", "billedTo")
	}

	get landownerOrCreate() {
		return this.getRelatedItemStoredCreate("forest-notification-contacts", "landowner", "landowner_id")
	}

	get managerOrCreate() {
		const theManager = this.getRelatedItemStoredCreate("forest-notification-contacts", "manager", "manager_id")

		return theManager
	}

	activityRelation(activityName: string): storedItem {
		return this.getRelatedItemStoredCreate("forestActivity", "activity" + activityName, "ForestNotificationID")
	}

	get billedToOrCreate() {
		if (this.landownerIsBiller) {
			return this.landownerOrCreate
		}
		if (this.managerIsBiller) {
			return this.managerOrCreate
		}
		const theBiller = this.getRelatedItemStoredCreate("forest-notification-contacts", "billedTo", "billed_to_id")
		printData(theBiller.uuid, messageLevels.debug, "theItem in billedToOrCreate")
		return theBiller
	}

	store(key?: string): storedItem {
		super.store(key)
		const landownerItem = (this as any)._landownerItem
		if (landownerItem) {
			;(landownerItem as storedItem).store()
		}
		const managerItem = (this as any)._managerItem
		if (managerItem) {
			;(managerItem as storedItem).store()
		}
		const billed_to_Item = (this as any)._billerItem
		if (billed_to_Item) {
			;(billed_to_Item as storedItem).store()
		}
		return this
	}

	_createValidation = (...args: FormFieldProperties[][]) => {
		this.validation = createValidation.apply(this, args)
	}

	validForSave = debounce((): boolean => {
		return this.validation?.validateAll(this, false) || false
	}, 500)

	validateBeforeSave = (): boolean => this.validation?.validateAll(this, true) || false

	validationForKey = (key: string): boolean => {
		if (!this.validation) return false
		const field = this.validation.getFieldByKey(key)
		return this.validation.validate(field, this, true) || false
	}

	validationErrorForKey = (key: string): any => {
		return this.validation?.getErrorByKey(key)
	}
}

function imageURLs(theItem: forestNotification) {
	const theImages: string[] = theItem.getValue("image_links") || []
	const imageLinks: URL[] = []
	if (theImages.length > 0) {
		theImages.forEach((item) => {
			if (item.length > 0 && !item.endsWith("/")) {
				let theURL
				if (item.startsWith("http")) {
					theURL = new URL(item)
				} else if (isUUID(item)) {
					theURL = mediaCollectionURLforUUID(item)
				} else {
					let modItem = item
					if (item.startsWith(".")) {
						modItem = item.substring(1)
					}
					if (!modItem.startsWith("/media/") && !modItem.startsWith("media/")) {
						modItem = "/media/" + item
					}
					theURL = new URL(modItem, getConfig("cdnUrl"))
				}

				imageLinks.push(theURL)
				printData(theURL, messageLevels.debug, item)
			}
		})
		return imageLinks
	}
	return []
}

const addColour = function (item: storedItem, field: string, name: string, items: string[]) {
	if (item.getValue(field + name)) {
		items.push(name.toLowerCase())
	}
	return items
}
