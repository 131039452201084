import React from "react"
import { Image } from "semantic-ui-react"

const styles = {
	width: "80%",
	height: "90px",
	objectFit: "contain",
}

const CouncilLogo = ({ src }: any) => {
	return <Image style={styles} src={src} centered />
}

export default CouncilLogo
