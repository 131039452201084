import { useEffect, useState } from "react"

import ProgressBar from "."
import { getUIConfig, processPercentComplete, registerOnProcessChange, removeOnProcessChange } from "../../store"

const TopProgressBar: React.FC = () => {
	const [percent, setPercent] = useState(0)
	const { brandColor } = getUIConfig("clientUI")

	const onProcessChange = (): void => {
		const newPercent = processPercentComplete()
		if (newPercent > percent) setPercent(newPercent)
	}

	useEffect(() => {
		if (!percent) {
			registerOnProcessChange(onProcessChange.bind(this))
			return removeOnProcessChange(onProcessChange.bind(this))
		}
	}, [])

	return <ProgressBar percent={percent} barColor={brandColor} top={0} disappearOnLoad={true} />
}

export default TopProgressBar
