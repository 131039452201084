import React from "react"
import { LngLat } from "mapbox-gl"
import { Form, LabelProps, SemanticShorthandItem } from "semantic-ui-react"

import { call } from "../store"
import { printData, printMessage } from "../providers/remoteHQ"
import { messageLevels } from "../store/types"

interface PinPlaceProps {
	getValue?: any
	setValue?: any
	initialLocation?: LngLat | null
	edit?: boolean
	hideDisplay?: boolean
	hidePin?: boolean
	pinColor?: string
	error?: boolean | string | null | SemanticShorthandItem<LabelProps>
	popupMessage?: string
}

interface PinPlaceState {
	marker: any
	lnglat?: LngLat | null
	editLng?: string
	editLat?: string
	hasError: boolean
}

export default class PinPlace extends React.Component<PinPlaceProps, PinPlaceState> {
	state: PinPlaceState = {
		hasError: false,
		marker: null,
		lnglat: this.props.initialLocation,
	}

	componentDidMount() {
		this.createMarker()
		if (this.state.lnglat) {
			this.setState({
				editLng: this.state.lnglat.lng.toFixed(5),
				editLat: this.state.lnglat.lat.toFixed(5),
			})
		}
	}
	componentDidUpdate(prevProps: PinPlaceProps) {
		if (prevProps.pinColor !== this.props.pinColor) {
			if (this.state.marker !== null) {
				this.removeMarker()
				this.createMarker()
			}
		}
	}
	componentWillUnmount() {
		if (!this.props.hidePin) {
			if (this.state.marker) {
				call("setMarkerEvent", this.state.marker, "dragend", null)
				call("removeMarker", this.state.marker)
			}
		}
	}
	removeMarker() {
		if (this.state.marker) {
			call("removeMarker", this.state.marker)
			this.setState({ marker: null })
		}
	}
	createMarker() {
		if (!this.props.hidePin) {
			const canDrag = this.props.edit
			const pinColor = this.props.pinColor
			printData(canDrag, messageLevels.debug, "canDrag")
			const marker = call(
				"createMarker",
				{
					draggable: canDrag,
					color: pinColor,
				},
				this.state.lnglat,
				this.props.popupMessage,
				{ anchor: "left" },
			)
			call("setMarkerEvent", marker, "dragend", this.onDragEnd)
			this.setState({ marker: marker })
		}
	}

	onDragEnd = () => {
		const lngLat = this.state.marker.getLngLat()
		this.updateLocation(lngLat)
	}

	updateLocation = (lngLat: LngLat) => {
		if (lngLat) {
			this.setState({
				editLng: lngLat.lng.toFixed(5),
				editLat: lngLat.lat.toFixed(5),
				lnglat: lngLat,
			})
			this.sendChange(lngLat)
		}
	}

	updateLocationFromInput(lngLat: LngLat) {
		this.setState(
			{
				lnglat: lngLat,
			},
			() => this.moveMarkerToCurrentLocation(),
		)
		this.sendChange(lngLat)
	}

	moveMarkerToCurrentLocation = () => {
		if (this.state.marker && this.state.lnglat) {
			call("setMarkerLocation", this.state.marker, this.state.lnglat)
		}
	}
	sendChange = (lnglat: LngLat) => {
		if (this.props.setValue && lnglat) {
			this.props.setValue(lnglat)
		}
	}
	renderDisplay() {
		const theLoc = this.props.initialLocation
		if (this.state.marker) {
			call("setMarkerLocation", this.state.marker, theLoc)
		}

		// const theLoc = this.state.lnglat;
		if (theLoc) {
			return (
				<>
					{theLoc.lat.toFixed(6)}, {theLoc.lng.toFixed(6)}
				</>
			)
		} else {
			return <>Not set</>
		}
	}

	latChange = (e: any) => {
		this.setState({ editLat: e.target.value })
		const theLat = parseFloat(e.target.value)
		if (theLat) {
			const theloc = this.state.lnglat
			let newLoc = theloc
			if (theloc) {
				newLoc = new LngLat(theloc.lng, theLat)
			}
			if (newLoc) {
				this.updateLocationFromInput(newLoc)
			}
		}
	}
	lngChange = (e: any) => {
		this.setState({ editLng: e.target.value })
		const theLng = parseFloat(e.target.value)
		const theloc = this.state.lnglat
		if (theloc) {
			const newLoc = new LngLat(theLng, theloc.lat)

			this.updateLocationFromInput(newLoc)
		}
	}
	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}
	componentDidCatch(error: any, errorInfo: any) {
		// You can also log the error to an error reporting service
		printMessage("error in PinPlace", messageLevels.error)
		printData(error, messageLevels.error)
		printData(errorInfo, messageLevels.error)
	}

	renderEdit() {
		const theLoc = this.state.lnglat
		const error = this.props.error
		let errorCode = null
		if (typeof error === "string") {
			errorCode = (
				<div style={{ color: "#e0b4b4" }}>
					Not set
					<br />
					{error}
				</div>
			)
		} else if (error) {
			errorCode = (
				<div style={{ color: "#e0b4b4" }}>
					Not set
					<br />
					(Drag marker to set location)
				</div>
			)
		} else {
			errorCode = (
				<>
					Not set
					<br />
					(Drag marker to set location)
				</>
			)
		}

		if (theLoc) {
			return (
				<>
					<Form.Input value={this.state.editLat} label="Latitude" placeholder={"latitude not set"} onChange={this.latChange} />
					<Form.Input value={this.state.editLng} label="Longitude" placeholder={"longitude not set"} onChange={this.lngChange} />
				</>
			)
		} else {
			return errorCode
		}
	}

	render() {
		if (this.props.hideDisplay) {
			return null
		}
		if (this.props.edit || !this.props.initialLocation) {
			return <>{this.renderEdit()}</>
		} else {
			return <>{this.renderDisplay()}</>
		}
	}
}
