import React from "react"
import { Card, Divider, Grid, Header, Icon, Label, List, Menu, Popup, Segment } from "semantic-ui-react"
import moment from "moment-timezone"

import { isStoredItem } from "../../index"
import { forestBlock } from "../../classes/forestBlock"
import complianceStatus from "../../../helpers/getComplianceStatus"
import { storedItem } from "../../storedItem"
import ArrayListComponent from "../../../mapsight/ArrayListComponent"
import { ReportItem } from "../../classes/report"
import RelationListComponent from "../../components/RelationListComponent"
import { printData } from "../../../providers/remoteHQ"
import { messageLevels } from "../../types"
import { timeZone } from "../../helpers"

interface InfoLayerProps {
	block: forestBlock
	visible: boolean
}

export interface BlockDetailState {
	showNotice: boolean
}

export default class BlockDetailView extends React.Component<InfoLayerProps, BlockDetailState> {
	state = {
		showNotice: false,
	}
	noDataMessage() {
		return (
			<div className="no-doc-text">
				<b>This block has not been assessed yet.</b>
			</div>
		)
	}
	render() {
		// const noDataMessage = this.noDataMessage();
		const { block, visible } = this.props

		if (!block || block === undefined || !isStoredItem(block)) return null

		// const forestMonitoringDocuments = block.reports || [];
		// let filterForestMonitoringDocuments:any = [];
		const managementCompanyName = block?.managementCompanyDisplayName() || ""

		// if (forestMonitoringDocuments.length > 0) {
		// 	filterForestMonitoringDocuments = forestMonitoringDocuments.filter(
		// 		(item: any) => item.compliance_status,
		// 	);

		// 	filterForestMonitoringDocuments.sort((x: any, y: any) => {
		// 		return new Date(y.created_date).getTime() - new Date(x.created_date).getTime();
		// 	});
		// }
		// let reportNumber = filterForestMonitoringDocuments.length;

		const { Activity, start_date, end_date, harvesting_contractor, earthworks_contractor, consent_number } = block.getValues([
			"Activity",
			"start_date",
			"end_date",
			"harvesting_contractor",
			"earthworks_contractor",
			"consent_number",
		])

		return (
			<div style={!visible ? { display: "none" } : {}}>
				<Segment inverted basic style={{ paddingTop: 0 }}>
					<Header as="h4">
						{/* <Icon name="clipboard" /> */}
						<Icon name="hashtag" />
						<Header.Content>Consent No: {consent_number}</Header.Content>
					</Header>
					<Header as="h4">
						<Icon name="group" />
						<Header.Content>{managementCompanyName}</Header.Content>
					</Header>
				</Segment>
				<Segment inverted basic>
					<Header as="h4" inverted>
						Forest Activities
					</Header>
					<Divider />
					<div style={{ position: "relative" }}>
						<Header as="h6">
							<Icon name="play circle" color="green" />
							<Header.Content>
								Start:{" "}
								{moment(start_date).format("DD MMMM YYYY") === "Invalid date"
									? "Unknown"
									: moment(start_date).tz(timeZone).format("DD MMMM YYYY")}
							</Header.Content>
						</Header>
						<Header as="h6">
							<Icon name="remove circle" color="red" />
							<Header.Content>
								End:{" "}
								{moment(end_date).format("DD MMMM YYYY") === "Invalid date"
									? "Unknown"
									: moment(end_date).tz(timeZone).format("DD MMMM YYYY")}
							</Header.Content>
						</Header>
						<List className="activities">
							{((Activity as string) || "").split(", ").map((activity, index) => {
								return (
									<List.Item key={index}>
										<Label horizontal color="black">
											{activity}
										</Label>
									</List.Item>
								)
							})}
						</List>
					</div>
				</Segment>
				<Segment inverted basic>
					<Header as="h4" inverted>
						Managers
					</Header>
					<Divider />
					<Header as="h6">
						<Icon name="user" />
						<Header.Content>Manager: {block.managerDisplayName()}</Header.Content>
					</Header>
					<Header as="h6">
						<Icon name="user" />
						<Header.Content>Harvesting: {harvesting_contractor}</Header.Content>
					</Header>
					<Header as="h6">
						<Icon name="user" size="small" />
						<Header.Content>Earthworks: {earthworks_contractor}</Header.Content>
					</Header>
				</Segment>
				<Segment inverted basic>
					<Header as="h4" inverted>
						Inspection History
						<Icon
							name="question"
							onMouseOver={() => this.setState({ showNotice: true })}
							onMouseOut={() => this.setState({ showNotice: false })}
						/>
					</Header>
					<Divider />
				</Segment>
				<Grid columns={4}>
					{this.state.showNotice && (
						<Card
							className="notice"
							description="Please note the displayed compliance status reflects compliance at the time of assessment and may not be an accurate assessment of the current compliance status."
						/>
					)}
					<RelationListComponent
						item={block}
						relationship={"reports"}
						type={"forest-monitoring-reports"}
						cellFormatter={reportItemCell}
						noWrapper
						noDataMessage={"This block has not been assessed yet."}
						notLoadedMessage={"Loading"}
					/>
					{/* <ArrayListComponent data={filterForestMonitoringDocuments} /> */}
					{/* {filterForestMonitoringDocuments.map((data: any, index: number) => (
						<Grid.Row key={index}>
							<Grid.Column width="1">
								<Icon
									name={
										complianceStatus[data.getValue("compliance_status")]
											? complianceStatus[data.compliance_status].name
											: complianceStatus["NA"].name
									}
									color={
										complianceStatus[data.compliance_status]
											? complianceStatus[data.compliance_status].color
											: complianceStatus["NA"].color
									}
									size="large"
								/>
							</Grid.Column>
							<Grid.Column width="6">
								{moment(data.created_date).format("DD MMMM YYYY h:mm a")}
							</Grid.Column>
							<Grid.Column className="adjust-padding" width="4">
								Report #{reportNumber--}
							</Grid.Column>
							<Grid.Column width="4">
								<Popup
									inverted
									trigger={
										<Menu.Item className="nav-button" as="a">
											<Icon name="eye" disabled />
										</Menu.Item>
									}
									position="top center"
									className="nav-popup"
								>
									Coming Soon
								</Popup>
								<Popup
									inverted
									trigger={<Icon name="download" disabled />}
									position="top center"
									className="nav-popup"
								>
									Coming Soon
								</Popup>
							</Grid.Column>
						</Grid.Row>
					))} */}
					{/* {forestMonitoringDocuments.length === 0 && (
						<div className="no-doc-text">
							<b>This block has not been assessed yet.</b>
						</div>
					)} */}
				</Grid>
			</div>
		)
	}
}

function reportItemCell(item: storedItem, options: any, index: number, count: number) {
	const data = item as ReportItem
	const complianceStatusCode = data.getValue("compliance_status")
	const createdDate = data.getValue("created_date")
	const reportNumber = index + 1
	return (
		<Grid.Row key={index}>
			<Grid.Column width="1">
				<Icon
					name={
						complianceStatus[complianceStatusCode] ? complianceStatus[complianceStatusCode].name : complianceStatus["NA"].name
					}
					color={
						complianceStatus[complianceStatusCode] ? complianceStatus[complianceStatusCode].color : complianceStatus["NA"].color
					}
					size="large"
				/>
			</Grid.Column>
			<Grid.Column width="6">{moment(createdDate).tz(timeZone).format("DD MMMM YYYY h:mm a")}</Grid.Column>
			<Grid.Column className="adjust-padding" width="4">
				Report #{reportNumber}
			</Grid.Column>
			<Grid.Column width="4">
				<Popup
					inverted
					trigger={
						<Menu.Item className="nav-button" as="a">
							<Icon name="eye" disabled />
						</Menu.Item>
					}
					position="top center"
					className="nav-popup"
				>
					Coming Soon
				</Popup>
				<Popup inverted trigger={<Icon name="download" disabled />} position="top center" className="nav-popup">
					Coming Soon
				</Popup>
			</Grid.Column>
		</Grid.Row>
	)
}
