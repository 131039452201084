import { registerforStateChanges } from "../../store"
import { printData, printMessage } from "../../providers/remoteHQ"
import { storedItem } from "../../store/storedItem"
import { messageLevels } from "../types"
import { mapDataSource } from "./mapDataSource"
import { dataFeed, getDataFeedByName } from "../dataFeed"
import { primaryKeys } from "../../mapsight/FeedListComponent"
import { storeType } from "../storeType"

const emptyStoredArray: storedItem[] = []
const emptyGeoJSON = { type: "FeatureCollection", features: [] }

export class mapDataFeedSource extends mapDataSource {
	relatedType: storeType | null | undefined = null

	dataLoaded = (type: string, key?: string, data?: storedItem[]) => {
		printMessage("DataLoaded in mapDataFeedSource " + this.primaryKey(), messageLevels.debug)
		printMessage("type: " + type, messageLevels.debug)
		printMessage("key: " + key, messageLevels.debug)

		if (this.getValue("dataSource") !== undefined) {
			if (key === ("map-" + this.getValue("dataSource") || "forest-blocks")) {
				;(this as any)._dataCache = data || emptyStoredArray
				const theKeys = primaryKeys((this as any)._dataCache)
				const existingKeys = this.getValue("_listKeys")

				if (!compareArrays(existingKeys as string[], theKeys)) {
					this.prepareMapData()
					;(this as any)._listKeys = theKeys
				}
			}
		}
	}

	mounted() {
		super.mounted()
		registerforStateChanges(this.stateChanged)
	}

	setupDataSource = () => {
		this.setValue(emptyStoredArray, "_dataCache")

		if ((this as any).dataSource !== undefined) {
			printMessage("Setting up source ID: " + this.primaryKey(), messageLevels.debug)
			;(this as any)._dataFeed = getDataFeedByName("map-" + (this as any).dataSource || "forest-blocks", true)

			;(this as any)._dataFeed.setupSource((this as any).dataSource, [])

			;((this as any)._dataFeed as dataFeed).registerOnChange(this.dataLoaded, {})
			printData((this as any)._dataFeed, messageLevels.debug)
		} else {
			printMessage("dataFeed source ID: " + this.primaryKey() + " has no dataSource property", messageLevels.error)
		}
		;(this as any)._selectedItem = null
	}

	stateChanged = (type: string, key?: string, data?: storedItem) => {
		printMessage("stateChanged in mapDataFeedSource " + this.primaryKey(), messageLevels.debug)

		printMessage("key: " + key, messageLevels.debug)
		// printData( data ,messageLevels.debug)
		this.stateChangeHandler(key, data)
	}

	stateChangeHandler = (key?: string, data?: storedItem) => {
		if (key === "selected") {
			// if(data !== (this as any)._selectedItem ){
			printMessage("selection changed in mapDataFeedSource " + this.primaryKey(), messageLevels.debug)
			printData(data, messageLevels.debug, "data")
			printData(data?.getValue(), messageLevels.debug, "data?.getValue() " + this.primaryKey())
			if (data && data.getValue() !== undefined) {
				;(this as unknown as any)._selectedItem = data?.getValue()[0] || null
			} else {
				;(this as unknown as any)._selectedItem = null
				printMessage("NO data from state change", messageLevels.warning)
			}
			setTimeout(this.prepareMapData)
			// }
		}
	}

	registerForRelatedDataChanges(relatedData: storedItem[]) {
		if (relatedData && relatedData.length > 0) {
			if (!this.relatedType) {
				this.relatedType = relatedData[0]._type
				if (this.relatedType) {
					printMessage(this.primaryKey() + " Registering for changes on: " + this.relatedType.name, messageLevels.debug)
					this.relatedType.registerOnChange(this.relatedChanges)
				}
			}
		}
	}

	relatedChanges = () => {
		printMessage("relatedChanges in: " + this.primaryKey(), messageLevels.debug)
		this.prepareMapData()
	}
	prepareMapData = () => {
		printMessage("prepareMapData in mapDataFeedSource " + this.primaryKey(), messageLevels.debug)
		if (this.overrideGeoJSON) {
			this.updateMapData(this.alternateGeoData)
		}
		// get the config for properties
		const theProperties = this.getValue("properties")

		let theData = this.getRelationshipData()
		printData(theData, messageLevels.debug, "The data from relationship")

		this.registerForRelatedDataChanges(theData)

		if (!theData) {
			theData = this.getValue("_dataCache")
		}
		if (!theData || theData === undefined || theData.length < 1) {
			printMessage("No data in " + this.primaryKey(), messageLevels.debug)
			this.updateMapData(emptyGeoJSON)
			return
		} else {
			const theFeatures: any = []

			theData.forEach((element: storedItem) => {
				// printMessage("ISO: " +element.getRegionISO(), messageLevels.debug)
				const theGeoJSON = element.getValue("geojson")
				const featureTemplate: {
					type: string
					geometry: any
					properties: { [key: string]: string }
				} = {
					// feature for Mapbox DC
					type: "Feature",
					geometry: {
						type: "Point",
						coordinates: [173.427845933, -41.537825122],
					},
					properties: {
						key: "-",
					},
				}

				if (theGeoJSON) {
					if (typeof theGeoJSON === "string") {
						featureTemplate.geometry = JSON.parse(theGeoJSON)
					} else {
						featureTemplate.geometry = theGeoJSON
					}

					//  run through the properties and add them
					if (theProperties && theProperties.length > 0) {
						theProperties.forEach((property: string) => {
							featureTemplate.properties[property] = element.getValue(property)
						})
					}
					featureTemplate.properties.key = element.primaryKey()
					printData(
						featureTemplate,
						this.primaryKey() === "areas-of-excellence" ? messageLevels.debug : messageLevels.debug,
						"featureTemplate in " + this.primaryKey(),
					)
					theFeatures.push(featureTemplate)
				}

				printData(featureTemplate, messageLevels.debug)
			})

			const theFinalData = {
				type: "FeatureCollection",
				features: theFeatures,
			}
			printData(theFinalData, messageLevels.debug, "theFinalData in " + this.primaryKey())
			this.updateMapData(theFinalData)
		}
	}
}
function compareArrays(arr1: string[], arr2: string[]) {
	if (arr1.length !== arr2.length) {
		return false
	}
	for (let x = 0; x < arr1.length; x++) {
		if (JSON.stringify(arr1[x]) !== JSON.stringify(arr2[x])) {
			return false
		}
	}
	return true
}
