import React, { useState } from "react"
import { Button, Icon, Item, Modal, Popup } from "semantic-ui-react"

import { CustomModal } from "../CustomModal"
import { call, getUIConfig } from "../../store"
import { utf8_to_b64 } from "../../helpers/commonFunc"
import "./styles.css"

interface ShareButtonProps {}

export const ShareButton = (props: ShareButtonProps) => {
	const [viewParams, setViewParams] = useState<any>({})
	const modeURL = new URL(window.location.href)

	const getViewParams = () => {
		const str = JSON.stringify(viewParams)
		const hash = utf8_to_b64(str)
		return hash
	}

	const getViewURL = () => {
		modeURL.hash = getViewParams()
		return modeURL.href
	}

	const viewURLtoClipboard = () => navigator.clipboard.writeText(getViewURL())
	const { backgroundColor, color } = getUIConfig("clientUI") || {}

	return (
		<CustomModal
			trigger={
				<div id="share-button">
					<Button circular icon="send" />
				</div>
			}
			closeIcon={false}
			style={{
				height: "fit-content",
				width: "fit-content",
				minWidth: "300px",
			}}
			handleOpen={() => setViewParams(call("getViewParams"))}
		>
			<div id="share-modal" style={{ backgroundColor, color }}>
				<Modal.Header>
					<h3 style={{ color }}>Share</h3>
				</Modal.Header>
				<Modal.Content>
					<Item.Group divided>
						<Popup
							trigger={
								<div>
									<Popup
										trigger={
											<Item onClick={viewURLtoClipboard}>
												<Item.Content>
													<Item.Header>
														<Icon name="linkify" />
														Copy Link
													</Item.Header>
												</Item.Content>
											</Item>
										}
										content={"Copied to Clipboard!"}
										on="click"
										inverted
									/>
								</div>
							}
							style={{ fontSize: 12 }}
							on="hover"
							inverted
						>
							<span>
								<Icon name="exclamation circle" color="yellow" />
							</span>
							Some shared layers may require user to login or have permission to view.
						</Popup>
						<Item
							as={"a"}
							href={`
							mailto:?subject=RemoteHQ Link&body=Shared a link with you ${getViewURL()}
						`}
						>
							<Item.Content>
								<Item.Header>
									<Icon name="mail" />
									Share via Email
								</Item.Header>
							</Item.Content>
						</Item>
					</Item.Group>
				</Modal.Content>
			</div>
		</CustomModal>
	)
}
