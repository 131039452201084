import React from "react"
import { Document, StyleSheet } from "@react-pdf/renderer"

import { removeStateChanges } from "../.."
import { MultipagePDFForm } from "./MultipagePDFForm"
import ItemContext from "../ItemContext"

export default class PDFContext extends ItemContext {
	styles: any = StyleSheet.create({
		page: {
			fontSize: 9,
		},
		row: {
			flexDirection: "row",
		},
		flexCenter: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
	})
	componentWillUnount() {
		removeStateChanges(this.stateChanged)
		// let oldType: storeType | undefined | null = this.state.selectedItem?.getType();
		// if (oldType) {
		// 	oldType.removeOnChange(this.dataChanged);
		// }
	}

	// This loads a list of available descriptions for the layer:
	// loadCallback = (type: string, key?: string, data?: any) => {
	// 	let arr: any[] = [];
	// 	Array.isArray(data) &&
	// 		data.map(
	// 			(i) =>
	// 			(arr = [
	// 				...arr,
	// 				{
	// 					key: i.id,
	// 					text: i.description,
	// 					value: i.description,
	// 				},
	// 			]),
	// 		);
	// 	this.setState({ approvalStates: arr });
	// };

	stateChanged = (type: string, key: string, data: any) => {
		// if (key === "selected") {
		// 	this.endEditMode();
		// 	const newlySelected = data.getValue();
		// 	let newlySelectedItem: storedItem;
		// 	let oldType: storeType | undefined | null = this.state.selectedItem?.getType();
		// 	if (Array.isArray(newlySelected) && newlySelected.length > 0) {
		// 		newlySelectedItem = newlySelected[0];
		// 		if (newlySelectedItem && isStoredItem(newlySelectedItem)) {
		// 			const type = newlySelectedItem.getType();
		// 			if (type !== oldType) {
		// 				if (type) {
		// 					type.registerOnChange(this.dataChanged);
		// 				}
		// 				if (oldType) {
		// 					oldType.removeOnChange(this.dataChanged);
		// 				}
		// 			}
		// 			this.setState({ selectedItem: newlySelectedItem });
		// 		}
		// 	} else {
		// 		this.setState({ selectedItem: undefined });
		// 	}
		// }
		// if (key === "addItem") {
		// 	const addItems = data.getValue();
		// 	let addItem;
		// 	if (Array.isArray(addItems) && addItems.length) {
		// 		addItem = addItems[0];
		// 		this.setState({ addItem: addItem, addItemMode: true });
		// 	} else {
		// 		this.setState({ addItem: undefined, addItemMode: false });
		// 	}
		// }
	}

	// dataChanged = (type: string, key: any, data: storedItem) => {

	// 	const currentSelected = this.state.selectedItem;
	// 	if (currentSelected) {
	// 		printMessage("selected Data changed in info-layer", messageLevels.debug)
	// 		if (type === currentSelected.getTypeName() && key === currentSelected.primaryKey()) {
	// 			// this.setState({ changed : data });
	// 		}
	// 	}
	// 	const currenEdit = this.state.editingItem;
	// 	if (currenEdit) {
	// 		printMessage("currenEdit Data changed in info-layer", messageLevels.debug)
	// 		if (type === currenEdit.getTypeName() && key === currenEdit.primaryKey()) {
	// 			// this.setState({ editingItem: data });
	// 		}
	// 	}
	// }

	// gotURLCallback = (theUrl: string) => {
	// 	const theItem = (this.getEditingItem() as blockFeature);
	// 	theItem.addImageURL(theUrl);
	// }

	// getItemType() {
	// 	return this.state.selectedItem?.levelName() || null;
	// }

	// getSelectedItem() {
	// 	const { selectedItem, editingItem, inEditMode, addItemMode, addItem } = this.state;
	// 	if (addItemMode) {
	// 		return addItem;
	// 	}
	// 	return inEditMode ? editingItem : selectedItem;
	// }
	// getEditingItem() {
	// 	const { editingItem, addItemMode, addItem } = this.state;
	// 	if (addItemMode) {
	// 		return addItem;
	// 	}
	// 	return editingItem;
	// }

	// changeDefaultActiveView = (defaultActiveView: number) => {
	// 	this.setState({ defaultActiveView })
	// }

	renderForm = () => {
		const { inEditMode, addItemMode } = this.state
		const item = this.props.item

		// const theItem = this.getSelectedItem();

		return (
			<MultipagePDFForm
				pages={this.pages}
				container={this}
				displayMode={this.props.displayMode}
				item={this.props.item}
				title={this.props.title}
				setDirty={this.setDirty}
			></MultipagePDFForm>
		)
		// return <ItemView inEdit={editMode} editingItem={theEditItem} selectedItem={theItem} setValue={this.setValue} setDirty={this.setDirty} />;
	}

	setValue = (value: any, forKey: string) => {}
	setDirty = () => {}

	setEditMode = () => {}
	doCancel = () => {}
	endEditMode = () => {}

	render() {
		const { inEditMode, addItemMode } = this.state
		const item = this.props.item

		// printDataJSON(this.props.pages,messageLevels.verbose);

		// const theItem = this.getSelectedItem();
		const theContainer: ItemContext = this as ItemContext

		// printDataJSON(this.props.pages, messageLevels.verbose);

		return (
			<>
				<Document title={"Catchment Report"}>
					<MultipagePDFForm
						pages={this.pages}
						container={this}
						displayMode={this.props.displayMode}
						item={this.props.item}
						title={this.props.title}
						setDirty={this.setDirty}
					/>
				</Document>
			</>
		)
	}
}
