import React, { Component } from "react"
import { Checkbox, Item } from "semantic-ui-react"

import { StoreControlComponentProps } from ".."
import { getGlobalState, setGlobalState } from "../.."
import { storedItem } from "../../storedItem"
import { GlobalStateControl } from "../StateControl"

interface SwitchControlState {
	value: any
}

export class StateSwitchControl extends GlobalStateControl {
	get dataItem(): storedItem | null | undefined {
		return getGlobalState(this.props.name)
	}

	setValue = (value: any, forKey: string) => {
		setGlobalState(forKey, value)
	}

	getChecked = (): boolean => {
		const theCheck = this.dataItem?.getValue()

		if (!theCheck) {
			return false
		}
		return true
	}

	toggle = () => {
		this.setChecked(!this.state.value)
	}

	setChecked = (value: boolean | undefined) => {
		this.setValue(value, this.props.name)
		this.setState({ value: value })
	}

	render() {
		return (
			<Item height="50px">
				<Checkbox
					toggle={true}
					checked={this.getChecked()}
					style={{ borderWidth: 0, width: "100%" }}
					label={this.props.label}
					onChange={() => this.toggle()}
				/>
				<Item
					style={{
						position: "absolute",
						top: "0.65rem",
						right: 0,
					}}
				></Item>
			</Item>
		)
	}
}
