import React, { useState, useEffect } from "react"
import { useHover } from "../hooks/useHover"
import "./style.css"
import { getConfig } from "../store"

const MapSelector = (props) => {
	const [isOpen, setIsOpen] = useState(false)

	const [hoverRef, isHovered] = useHover()

	useEffect(() => {
		setIsOpen(isHovered)
	}, [isHovered])

	const handleSelect = (mapStyle) => () => {
		setIsOpen(false)
		props.changeState(mapStyle)
	}
	const renderImage = (option) => {
		if (!option.mapStyle.startsWith("local:")) {
			return (
				<img
					className="basemap-selector__image"
					src={
						"https://api.mapbox.com/styles/v1/mapbox/" +
						option.mapStyle +
						"/static/173.2851,-41.277,12.91,0/50x55?access_token=" +
						getConfig("mapboxToken") +
						"&attribution=false&logo=false"
					}
					alt={"Map Style " + option.name}
				/>
			)
		}
		const stylename = option.mapStyle.split(":")[1]
		const imagePath = `/styles/${stylename}/sample.png`
		return <img className="basemap-selector__image" src={imagePath} alt={"Map Style " + option.name} />
	}

	const renderOptions = (option, i) => {
		const currentlySelected = option.mapStyle === props.property

		return (
			<button
				onClick={handleSelect(option.mapStyle)}
				key={i}
				className={`basemap-selector__item ${currentlySelected ? "current" : ""}`}
				disabled={currentlySelected}
			>
				{renderImage(option)}
			</button>
		)
	}

	if (!props.options) {
		return <></>
	}

	return (
		<div>
			<div ref={hoverRef} className={`basemap-selector ${isOpen ? "expanded" : ""}`}>
				{props.options.map(renderOptions)}
			</div>
		</div>
	)
}

export default MapSelector
