import React from "react"
import { Menu } from "semantic-ui-react"

// import { getStoreTypeByName, registerforStateChanges, removeStateChanges, requestLoad } from '../store';
import { storedItem } from "../store/storedItem"
import { printData } from "../providers/remoteHQ"
import { messageLevels } from "../store/types"

interface ArrayListComponentProps {
	data: storedItem[]
	header?: string
	cellFormatter?: Function
	options?: { [id: string]: any }
	noWrapper?: boolean
	noDataMessage?: string
}

export default class ArrayListComponent extends React.Component<ArrayListComponentProps> {
	renderItem = (item: storedItem, index: number, count: number) => {
		if (this.props.cellFormatter) {
			// return this.props.cellFormatter(item, options);
			return this.props.cellFormatter(item, this.props.options, index, count)
		}

		return (
			<Menu.Item key={item.primaryKey()}>
				Primary Key: {item.primaryKey()}
				<br />
				Region: {item.getRegionISO()}
				<br />
				Name: {item.getValue("forest_name")}
				<br />
			</Menu.Item>
		)
	}

	renderItems = (items: storedItem[]) => {
		let index = 0
		const count = items.length
		return items.map((item) => {
			index += 1
			return this.renderItem(item, index - 1, count)
		})
	}

	renderHeader = () => {
		if (this.props.header && this.props.data.length > 0) {
			return <header>{this.props.header}</header>
		}
		return null
	}
	render() {
		const { noWrapper, noDataMessage, data } = this.props

		if (data.length > 0) {
			if (noWrapper) {
				return (
					<>
						{this.renderHeader()}
						{this.renderItems(data || [])}
					</>
				)
			} else {
				return (
					<Menu size="large" vertical fluid={true} borderless={true} inverted={true} className="with-labels">
						{this.renderHeader()}
						{this.renderItems(data || [])}
					</Menu>
				)
			}
		} else {
			return (
				<div className="no-doc-text">
					<b>{noDataMessage}</b>
				</div>
			)
		}
	}
}
