import React, { CSSProperties } from "react"
import { ImageListType } from "react-images-uploading"

import "react-datepicker/dist/react-datepicker.css"

import { Style } from "@react-pdf/types"

import { storedItem } from "../../../storedItem"
import { printData, printMessage } from "../../../../providers/remoteHQ"
import { ImageSupport, messageLevels } from "../../../types"
import { newFileUploaderFromImageListItem } from "../../../FileHandling"
import { FormFieldProperties } from "../.."
import { ImageUploadComponent } from "../../../views/ImageUploadComponent"

export interface ImageUploadFormComponentProps {
	item?: storedItem | null
	field?: FormFieldProperties
	hideHeading?: boolean
	setValue?: any
	setDirty?: any
	changed?: boolean
	maxImages?: number
	allowNonImageType?: boolean
	style?: Style
}
export interface ImageUploadFormComponentState {
	typeName?: string
	item?: storedItem
	changeCount: number
	listItems: any[]
	images: any[]
	currentPage?: number
	isDragging?: boolean
}

export class ImageUploadFormComponent extends React.Component<ImageUploadFormComponentProps, ImageUploadFormComponentState> {
	state: ImageUploadFormComponentState = {
		changeCount: 0,
		listItems: [],
		images: [],
	}

	setImages = (imageArray: []) => {
		this.setState({ images: imageArray })
	}

	setDirty = () => {
		this.setState({ changeCount: (this.state.changeCount || 0) + 1 })
		if (this.props.setDirty) {
			this.props.setDirty()
		}
	}

	imageChange = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
		// const index = addUpdateIndex?.map((i) => i)[0];
		// This prohibits multiple of the same images being uploaded.
		// const isDuplicate = !!(
		// 	index && this.state.images.filter((i: any) => i.dataURL === imageList[index]["dataURL"]).length
		// );
		// const isDuplicate = false;

		printData(imageList, messageLevels.debug, "imageList in onChange")

		imageList.forEach((image) => {
			const theUpload = newFileUploaderFromImageListItem({
				...image,
				name: image.file?.name,
				lastModified: image.file?.lastModified,
				size: image.file?.size,
				type: image.file?.type,
			})
			printData({ theUpload }, messageLevels.debug, "individual image")
			if (this.props.item) {
				this.imageItem.addFileUpload(theUpload, this.props.field?.name)
				theUpload.requestStep("readData")
			}
		})

		this.setDirty()
	}

	get imageItem(): ImageSupport {
		return this.props.item as unknown as ImageSupport
	}

	removeImage = (theImage: string | URL): boolean => {
		if (this.props.item) {
			const didDelete = this.imageItem.removeImage(theImage.toString(), this.props.field?.name)
			if (didDelete) {
				printMessage("Did delete", messageLevels.debug)

				this.setDirty()
			}
			return didDelete
		}
		return false
	}

	itemSupportsImages = () => {
		printData(this.props.item, messageLevels.debug, "TheItem")
		if (!this.props.item) return false

		if (!Object.prototype.hasOwnProperty.call(this.props.item, "getImageURLs")) return false
		printMessage("Item supports Images", messageLevels.debug)
		return true
	}

	getImageList = () => {
		if (!this.props.item) return []
		return this.imageItem.getImageURLs(this.props.field?.name) || []
	}

	render() {
		if (this.itemSupportsImages()) {
			const maxImages = this.props.maxImages || 10

			const imageLinks = this.getImageList()
			return (
				<ImageUploadComponent
					maxImages={maxImages}
					imageChange={this.imageChange}
					removeImage={this.removeImage}
					imageCapture={null}
					imageLinks={this.getImageList()}
					allowNonImageType={!!this.props.allowNonImageType}
					style={this.props.style}
				/>
			)
		}
		return null
	}
}
