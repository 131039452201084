import React, { Component } from "react"
import { Divider, Form, Menu, Segment } from "semantic-ui-react"

import { isStoredItem } from "../.."
import { storedItem } from "../../storedItem"
// import { FormBlock } from "./FormBlock";
import { FormFieldProperties } from "../index"
import { printMessage, printData } from "../../../providers/remoteHQ"
import { messageLevels, ImageSupport } from "../../types"
import { FormItemComponentProps } from "../"

export class FormItem extends Component<FormItemComponentProps> {
	updateFormData(field: FormFieldProperties, value: any) {
		if (this.props.setValue && field.name) {
			this.props.setValue(value, field.name)
		} else {
			this.setValue(value, field.name)
		}
	}
	setValue = (value: any, forKey: string) => {
		const theItem: storedItem | null | undefined = this.props.item
		if (theItem && theItem !== undefined) {
			// printMessage("setting value: " + value,messageLevels.verbose)
			// printMessage("for forKey: " + forKey,messageLevels.verbose)
			theItem.setValue(value, forKey)

			this.setDirty()
		}
	}
	setDirty = () => {
		if (this.props.setDirty) {
			this.props.setDirty()
		}
	}
	getChecked = () => {
		const theCheck = this.props.item?.getValue(this.props.field.name)
		if (theCheck === undefined) {
			return false
		}
		return theCheck
	}

	setChecked = (value: boolean | undefined) => {
		this.setValue(value, this.props.field.name)
	}
	errorForKey = (forKey: string): any => {
		const theItem: storedItem | null | undefined = this.props.item
		if (theItem && theItem !== undefined) {
			return theItem.validationErrorForKey(forKey)
		}
		return null
	}

	render() {
		// if (this.props.item && this.props.item !== null) {
		// 	return <>{this.renderField(this.props.field, this.props.index)}</>;
		// } else {
		return <div>A Form</div>
		// }
	}

	classesForField(field: FormFieldProperties) {
		let classes = "field"
		if (field.mandatory) {
			classes += " required"
		}
		if (this.props.item && this.props.item.validationErrorForKey(field.name) !== null) {
			classes += " error"
		}
		return classes
	}
}
const onClickSelectCoordinateButton = () => {
	// dispatch(NotifyFormStoreHandlers.startSelectingCoordinate());
	// hideSidebar();
}

export function colorForItemKey(item: storedItem, key: string) {
	return item.validationForKey(key) ? undefined : "red"
}
