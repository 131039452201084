import React from "react"
import { Divider, Form, Header, Label, Segment } from "semantic-ui-react"
import DatePicker from "react-datepicker"
import moment from "moment"

import { blockFeature } from "../classes/blockFeature"
import { getGlobalState, isStoredItem, registerOnChange, removeOnChange, setGlobalState } from "../"
import { printData } from "../../providers/remoteHQ"
import { messageLevels } from "../types"
import "react-datepicker/dist/react-datepicker.css"
import PinPlaceGeoJSONPoint from "../../mapsight/PinGeoJSONPoint"
import ImageList from "../components/Image/ImageListComponent"
import Status from "../components/StatusComponent"
import { BlockFeatureEditView, BlockFeatureInfoView } from "./BlockFeature"

export class TaskEditView extends BlockFeatureEditView {
	maxNumber = 10
	componentDidMount() {
		registerOnChange("tasks", this.dataChanged)
	}

	componentWillUnount() {
		removeOnChange("tasks", this.dataChanged)
	}

	handleDateChange = (theDate: any) => {
		const dateValue = theDate
		try {
			const multiEditItems = getGlobalState("addItems")?.getValue()
			if (multiEditItems?.length > 0) {
				const editIdx = getGlobalState("editIdx")?.getValue() ?? 0
				// If we are editing the first in a set
				// Edit idx is already incremented to 1 when editing the initial item
				if (editIdx === 1) {
					for (const item of multiEditItems) {
						item.setValue(dateValue, "due_date")
					}
					setGlobalState("isDirty", true)
				}
			}
			this.setValue(dateValue, "due_date")
		} catch (error) {
			console.error(error)
		}
	}

	theMinimumDate = (): string => {
		return new Date("01/01/2000").toLocaleDateString("en-us")
	}

	theDueDateString = (): string => {
		const theDate = this.props.editingItem?.getDateFormatted("due_date", "DD MMMM yyyy", "")

		printData(theDate, messageLevels.verbose, "theString")
		return theDate || ""
	}

	render() {
		const item = this.props.editingItem as blockFeature
		const pinColour = (item as blockFeature).pinColour
		return (
			<>
				<Form>
					{this.renderImageUpload(
						<>
							{this.renderHeading()}
							<Segment inverted basic>
								<Form.TextArea
									label="Description"
									value={item?.getValue("description") || ""}
									placeholder={"Add a description"}
									onChange={this.handleChange}
									required
									error={this.errorForKey("description")}
								/>
							</Segment>
							<Segment inverted basic>
								<label>Due Date/Completion Date</label>
								<DatePicker
									isClearable
									required
									value={this.theDueDateString()}
									placeholderText="Please select a date"
									dateFormat="DD MMMM YYYY"
									onChange={(value) => {
										const date = value ? moment(value).format("DD MMMM yyyy") : null
										this.handleDateChange(date)
									}}
								/>
							</Segment>

							<Segment inverted basic>
								<div className="field required">
									<label>Location</label>
									<PinPlaceGeoJSONPoint
										edit={true}
										pinColor={pinColour}
										initialLocation={item?.getValue("geojson")}
										error={this.errorForKey("geojson")}
										setValue={this.handleGeoChange}
									></PinPlaceGeoJSONPoint>
								</div>
							</Segment>
						</>,
					)}
				</Form>
			</>
		)
	}
}

export class TaskInfoView extends BlockFeatureInfoView {
	render() {
		const selectedItem = this.props.selectedItem

		if (!selectedItem || !isStoredItem(selectedItem)) {
			return <></>
		}
		const imageLinks = (selectedItem as blockFeature).imageURLs
		const theLocation = selectedItem?.getValue("geojson")
		const pinColour = (selectedItem as blockFeature).pinColour
		return (
			<>
				<Segment inverted basic className="basic-info">
					{this.renderNameHeader()}
					<Status item={selectedItem}></Status>
					{selectedItem?.getValue("status") ? (
						<Label horizontal className="compliance-status" color={"orange"}>
							{selectedItem?.getValue("status")}
						</Label>
					) : null}
				</Segment>

				<Segment inverted basic>
					<Header as="h4" inverted>
						Description
					</Header>
					<Divider />
					{selectedItem?.getValue("description") ? selectedItem?.getValue("description") : "No description available"}
				</Segment>
				<Segment inverted basic>
					<Header as="h4" inverted>
						Due Date/Completion Date
					</Header>
					<Divider />
					{selectedItem?.getDateFormatted("due_date", "DD MMMM yyyy", "No due date or completion date available")}
				</Segment>
				<Segment inverted basic>
					<Divider />
					<ImageList imageLinks={imageLinks}></ImageList>
				</Segment>
				<Segment inverted basic>
					<Header as="h4" inverted>
						Location
					</Header>
					<PinPlaceGeoJSONPoint edit={false} pinColor={pinColour} initialLocation={theLocation}></PinPlaceGeoJSONPoint>
				</Segment>
			</>
		)
	}
}
