import React from "react"

import { call, itemByKey } from "../store"
import { IQueryParams, messageLevels } from "../store/types"
import { printData } from "../providers/remoteHQ"
import { storedItem } from "../store/storedItem"
import { getDataFeedByName } from "../store/dataFeed"
import { mapLayer } from "../store/classes/mapLayer"

export interface ILayerFilter {
	layerName: string
	property: string
}

interface FeedMapComponentProps {
	dataFeedName: string
	queryParams: IQueryParams
	mapFilters: ILayerFilter[]
	castAs?: "string" | "number"
	visualiseBy?: string
}

interface FeedMapComponentState {
	listKeys: (string | number)[]
}

const emptyStoredArray: storedItem[] = []
export default class FeedMapComponent extends React.Component<FeedMapComponentProps, FeedMapComponentState> {
	state = {
		listKeys: [],
	}
	dataFeed = getDataFeedByName(this.props.dataFeedName || "forest-blocks", true)
	data = emptyStoredArray

	dataLoaded = (type: string, key?: string, data?: storedItem[]) => {
		// this.setState({listKeys: ""});
		if (key === (this.props.dataFeedName || "forest-blocks")) {
			this.data = data || emptyStoredArray
			const theKeys = primaryKeys(this.data, this.props.castAs)
			printData(theKeys, messageLevels.debug)
			this.setState({ listKeys: theKeys })
		}
	}

	componentDidMount() {
		// const theDataSource = getStoreTypeByName(this.props.dataSourceName || "forest-blocks", true);
		// this.setState({ dataSource: theDataSource}, () => this.getData())
		this.dataFeed.registerOnChange(this.dataLoaded, this.props.queryParams)
	}
	componentWillUnmount() {
		this.dataFeed.removeOnChange(this.dataLoaded)
		// removeStateChanges(this.stateChanged);
	}
	componentDidUpdate() {
		this.props.mapFilters &&
			this.props.mapFilters.map((layerFilter) => {
				// const theLayer = itemByKey("map-layer",layerFilter.layerName) as mapLayer;
				const theLayer = itemByKey("map-layers", layerFilter.layerName) as mapLayer | undefined
				if (theLayer) {
					// printData(layerFilter.layerName, messageLevels.verbose, "layer name in filter");
					// printData(theLayer, messageLevels.verbose, "layer in filter")
					const theFilters: any[] = []
					if (this.state.listKeys.length > 0 && theLayer) {
						theFilters.push(["match", ["get", layerFilter.property], this.state.listKeys, true, false])
					} else {
						theFilters.push(["match", ["get", layerFilter.property], "-", false, false])
					}
					// theLayer.setAdditionalFilter(["==", "1", "2", "3"]);
					// call("setLayerFilter", layerFilter.layerName, ["==", "1", "2", "3"]);

					// theLayer && theLayer.setDisplayMode(this.props.visualiseBy);
					if (this.props.visualiseBy) {
						call("setPaintProperty", this.props.visualiseBy)
					}
					theLayer.setAdditionalFilter(theFilters)
				}
				return null
			})
	}

	render() {
		return null
	}
}
function primaryKeys(theData: storedItem[], castAs?: "string" | "number"): (string | number | any)[] {
	if (!castAs) {
		const theKeys: any[] = []
		theData.forEach((element) => {
			if (element.primaryKey()) {
				theKeys.push(element.primaryKey())
			}
		})
		return theKeys
	} else if (castAs === "string") {
		const theKeys: string[] = []
		theData.forEach((element) => {
			if (element.primaryKey()) {
				theKeys.push(element.primaryKey().toString())
			}
		})
		return theKeys
	} else if (castAs === "number") {
		const theKeys: number[] = []
		theData.forEach((element) => {
			if (element.primaryKey()) {
				theKeys.push(parseFloat(element.primaryKey()))
			}
		})
		return theKeys
	}
	return []
}
// function copyArray(array1: storedItem[] ):storedItem[] {
//   let newArray: storedItem[] = [];
//   array1.forEach(element => {
//       newArray.push(element);

//   });
//   return newArray;
// }
