import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic"

import { CallbackHandler, storeType } from "./storeType"
import { storedItem } from "./storedItem"
import { editContext } from "./editContext"
import { fileUpload } from "./classes/fileUpload"
import { fileUploadSequencer } from "./multiFileUploader"

export enum actions {
	none = 0,
	create,
	retrieve,
	update,
	delete,
}
export const actionNames = ["none", "create", "retrieve", "update", "delete"]

export function actionNameToNumber(theName: string): number {
	const theItemNumber = actionNames.indexOf(theName)
	return theItemNumber
}
// object IDs for permissions
export enum objects {
	none = 0,
	country,
	state,
	region,
	forest,
	forestBlock,
	consent,
	ortho,
	elevationModel,
	blockBoundary,
	panorama,
	task,
	aoe,
	aof,
	aoc,
	quarry,
	consentedRoad,
	consentedTrack,
	consentedSkid,
	consentedStreamCrossing,
	asBuiltRoad,
	asBuiltTrack,
	asBuiltSkid,
	asBuiltStreamCrossing,
	waterControl,
	forestBlockReport,
	inspectionFeatureEditor,
	dashboard,
	nesForm,
	forestOwnership,
	forestManager,
	nesPFForm,
	training,
	admin = 98,
	debug = 99,
}
export const objectNames: string[] = [
	"none",
	"country",
	"state",
	"region",
	"forest",
	"forestBlock",
	"consent",
	"ortho",
	"elevationModel",
	"blockBoundary",
	"panorama",
	"task",
	"aoe",
	"aof",
	"aoc",
	"quarry",
	"consentedRoad",
	"consentedTrack",
	"consentedSkid",
	"consentedStreamCrossing",
	"asBuiltRoad",
	"asBuiltTrack",
	"asBuiltSkid",
	"asBuiltStreamCrossing",
	"waterControl",
	"forestBlockReport",
	"inspectionFeatureEditor",
	"dashboard",
	"nesForm",
	"forestOwnership",
	"forestManager",
	"nesPFForm",
	"training",
]
objectNames[98] = "admin"
objectNames[99] = "debug"
export enum sortMethod {
	any = 0,
	alpha,
	numeric,
	date,
}
export enum sortDirection {
	ascending = 0,
	descending,
}
export interface ISortParams {
	sortField?: string
	sortDirection?: sortDirection
	sortMethod?: sortMethod
	defaultSortValue?: any
}

export interface IQueryParams {
	queries?: string[]
	searchOrMode?: boolean
	stringToFind?: string
	stringParams?: string[]
	sort?: ISortParams
}

export function objectNameToNumber(theName: string): number {
	const theItemNumber = objectNames.indexOf(theName)
	return theItemNumber
}

// levels at which the message system will report
export enum messageLevels {
	none = 0,
	error,
	warning,
	verbose,
	debug,
}

export interface IPermissions {
	regions?: any
	objects?: any
}
export interface IRelationship {
	to: string
	localKey: string
	foreignKey: string
	many: boolean
}
export interface IManagedItemDict {
	[key: string]: storedItem
}

export interface ISchemaItem {
	name: string
	objectType?: objects
	singleEntry: boolean
	userBased: boolean
	primaryKeyProperty?: string
	objectRegionProperty?: string
	relationships?: Array<IRelationship>
	session?: boolean
	local?: boolean
	localMaxAge?: number | null
	restEndpoint?: URL | null
	clientUnwrap?: any
	create: boolean
	retrieve: boolean
	update: boolean
	delete: boolean
	keyParameter?: string
	onChangeCallHandler: CallbackHandler
	// onChangeCall: Array<Function>;
	loadRequest: boolean
	loaded?: boolean
	// dataStorage: IDataStorageArray;
	managedItemStorage: IManagedItemDict
	hasChanges: boolean
	latestData: number
	_sendCreatesLock: boolean
	_sendRetrievesLock: boolean
}

export interface IDataItem {
	type: ISchemaItem
	key: string
	modifiedDate: Date
	fetchedDate?: Date
	modified?: boolean
	dirty?: boolean
	deleted?: boolean
	hasData: boolean
	requested?: boolean
	data?: any
}

export interface IDataStorageArray {
	[key: string]: IDataItem
}

export interface ISchemaStorageArray {
	[key: string]: storeType
}

export interface IContextStorageArray {
	[key: string]: editContext
}

export interface IManagedItem {
	getType(): storeType | null
	_primaryKey: any
	_modifiedDate?: Date
	_fetchedDate?: Date
	setValue(value: any, forKey: string): any
	getValue(forKey?: any): any
	isDirty(): boolean
	_requested: boolean
	_hasData: boolean
	_deleted: boolean
	_created: boolean
	_isEditCopy: boolean
	_sourceItem?: storedItem | null
	_serial: number
}

export interface ImageSupport {
	addFileUpload(theUpload: fileUpload, listParameter?: string): void
	addImageURL(theURL: string, listParameter?: string): void
	uploadAll(): void
	removeImage(theURL: string | URL, listParameter?: string): boolean
	removeImageURL(theURL: string, listParameter?: string): boolean
	removeImageUUID(theUUID: string, listParameter?: string): boolean
	getImageURLs(listParameter?: string): URL[]
	takeoverUploads(fileUploader: fileUploadSequencer): void
}

export type ChangeCallbackFunction = (type: string, key?: string, data?: any) => void

export interface IColorElement {
	name?: SemanticCOLORS
	code: string
}
export interface IIndexable<T = string> {
	[key: string]: T
	[key: number]: T
}

export interface IComplianceState {
	KEY: string
	definition: string
	legendOrder: number
	color: IColorElement
}

export type IPriority = IComplianceState

export interface IParsedQuery {
	column: string
	operator: string
	value?: any
	values?: any[]
}

export type Comparison =
	| "not"
	| "is"
	| "==="
	| "=="
	| "!=="
	| "!="
	| ">"
	| "<"
	| ">="
	| ">=="
	| "<="
	| "<=="
	| "after"
	| "before"
	| "onorafter"
	| "onorbefore"
	| "between"
	| "in"
	| "contains"
	| "any"
