import React from "react"
//@ts-ignore
import ReactSlider from "react-slider"
import "./styles.css"
import { getUIConfig } from "../../store"

const RangeSlider = (props: any) => {
	const { className } = getUIConfig("clientUI")
	return (
		<ReactSlider
			className={className + " " + "horizontal-slider"}
			thumbClassName={className + " " + "example-thumb"}
			trackClassName={className + " " + "example-track"}
			defaultValue={[0, 100]}
			ariaLabel={["Lower thumb", "Upper thumb"]}
			ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
			renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
			pearling
			minDistance={10}
			{...props}
		/>
	)
}

export default RangeSlider
