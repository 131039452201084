import { getDataFeedByName } from "../dataFeed"
import { sortArray } from "../helpers/ListOperations"
import { storedItem } from "../storedItem"
import { sortDirection, sortMethod } from "../types"
import { forestOwner } from "./forest"

export class catchment extends storedItem {
	_forests: forestOwner[] | null = null
	_forestOverlaps: number[] | null = null

	levelName() {
		return "catchment"
	}

	getParent() {
		return this.getRelatedItem("boundaries", "NZ-SI", "iso", "==", true)
	}

	catchmentOrder() {
		return this.getTypeName().split("-")[1]
	}

	displayName(): string {
		const theName = this.getValue("name")
		if (theName) {
			return theName
		}
		return "Catchment Order " + this.catchmentOrder() + " " + this.primaryKey()
	}
	get mapImageName(): string {
		return "https://api-dev.remotehq.co.nz/static/catchment/" + this.catchmentOrder() + "/" + this.getValue("uuid")
	}

	get forestOverlapArea() {
		const theForests = this.forests()
		const order = this.catchmentOrder().toString()
		let sum = 0
		theForests.forEach((forest) => {
			sum += forest.areaOverlappingReach(this.primaryKey(), order)
		})
		return sum
	}

	get filteredForestArea() {
		const theForests = this.filteredForests()
		const order = this.catchmentOrder().toString()
		let sum = 0
		theForests.forEach((forest) => {
			sum += forest.areaOverlappingReach(this.primaryKey(), order)
		})
		return sum
	}

	// All overlap forests area as percent of total catchment area
	get overlapAreaPercent() {
		const catchmentArea = this.getValue("sum_area")
		let overlapAreaPercent: number | string
		if (catchmentArea !== 0) {
			overlapAreaPercent = parseFloat(((this.forestOverlapArea / catchmentArea) * 100).toPrecision(2))
		} else {
			overlapAreaPercent = "N/A"
		}
		return overlapAreaPercent
	}

	// filtered forests area as percent of total catchment area
	get filteredAreaPercent() {
		const catchmentArea = this.getValue("sum_area")
		let filteredAreaPercent: number | string
		if (catchmentArea !== 0) {
			filteredAreaPercent = parseFloat(((this.filteredForestArea / catchmentArea) * 100).toPrecision(2))
		} else {
			filteredAreaPercent = "N/A"
		}
		return filteredAreaPercent
	}
	// filtered forest area as percent of total forest area
	get filteredForestAreaOfTotalForestPercent() {
		const forestFilteredHa = this.filteredForestArea
		const forestTotalHa = this.forestOverlapArea
		let forestAreaPercent: number | string
		if (forestTotalHa !== 0) {
			forestAreaPercent = parseFloat(((forestFilteredHa / forestTotalHa) * 100).toPrecision(2))
		} else {
			forestAreaPercent = "N/A"
		}
		return forestAreaPercent
	}

	overlapAreaForForest(forest: forestOwner) {
		const order = this.catchmentOrder().toString()
		return forest.areaOverlappingReach(this.primaryKey(), order)
	}

	filteredForests(): forestOwner[] {
		const theForests = this.forests()
		if (theForests.length < 1) {
			return theForests
		}

		const dataFeed = getDataFeedByName("forest-ownership", false)
		if (!dataFeed) {
			return theForests
		}
		const filterList = dataFeed.getInteractiveKeys()

		const filteredList: storedItem[] = []
		theForests.forEach((forest) => {
			if (filterList.indexOf(forest.primaryKey()) > -1) {
				filteredList.push(forest)
			}
		})
		const sortedArray = sortArray(filteredList, {
			sort: {
				sortField: "area_ha",
				sortDirection: sortDirection.descending,
				sortMethod: sortMethod.numeric,
			},
		})
		return sortedArray as forestOwner[]
	}

	forests(): forestOwner[] {
		if (this._forests) {
			return this._forests
		}
		const order = this.catchmentOrder()
		const column = "catchment_" + order

		const forests = this.getRelatedItems("forest-ownership", this.primaryKey(), column, "contains", true, {
			sortField: "area_ha",
			sortDirection: sortDirection.descending,
			sortMethod: sortMethod.numeric,
		}) as forestOwner[]

		return forests
	}

	forestOverlaps(): number[] {
		if (this._forestOverlaps) {
			return this._forestOverlaps
		} else {
			this._forests = this.forests()
		}
		return []
	}
}
