import React from "react"
import { Icon, Popup } from "semantic-ui-react"
import MapboxDraw from "@mapbox/mapbox-gl-draw"

import { MeasurementLine, MeasurementPolygon } from "./Measurements"

interface IMeasurementPopupProps {
	draw: MapboxDraw
	popup: mapboxgl.Popup
	item: MeasurementPolygon | MeasurementLine
	id: string | number
	onRemove: () => void
}

export const MeasurementPopup = ({ draw, popup, item, id, onRemove }: IMeasurementPopupProps) => {
	const { areaInHectares, perimeterInMeters, perimeterInKm } = item as any
	const isPolygon = !!areaInHectares
	return (
		<>
			<Popup.Header>{isPolygon ? "Area Measurement:" : "Distance:"}</Popup.Header>
			<Popup.Content
				content={
					<>
						{isPolygon ? <div>{areaInHectares.toLocaleString("en-US")} ha</div> : null}
						<div>
							{perimeterInMeters.toLocaleString("en-US")} m ({perimeterInKm.toLocaleString("en-US")} km){" "}
							{isPolygon ? "Perimeter" : ""}
						</div>
					</>
				}
			/>
			<Icon
				inverted
				name="trash alternate"
				onClick={() => {
					draw.delete([id.toString()])
					popup.remove()
					onRemove()
				}}
			/>
		</>
	)
}
