import React, { Component } from "react"
import { Icon, Item } from "semantic-ui-react"
import moment from "moment"

import { mapLayer } from "../store/classes/mapLayer"
import { storedItem } from "../store/storedItem"
import {
	call,
	getGlobalState,
	getUIConfig,
	instanceNewItem,
	registerOnChange,
	registerforStateChanges,
	removeOnChange,
	removeStateChanges,
	setGlobalState,
} from "../store/index"
import { printData, printMessage } from "../providers/remoteHQ"
import { messageLevels } from "../store/types"
import { forestBlock } from "./../store/classes/forestBlock"
import { blockFeature } from "../store/classes/blockFeature"
import RangeSlider from "../components/Slider"
import Toggle from "../components/UI/Toggle"

interface ILayerControlProps {
	item: mapLayer
	options?: { [id: string]: any }
	block: forestBlock
}
interface ILayerControlState {
	visible: boolean
	opacity: number
	disabled: boolean
	editing: boolean
	canView: boolean
	isLoggedIn: boolean
	checked: boolean
}

class LayerControl extends Component<ILayerControlProps, ILayerControlState> {
	state = {
		visible: this.getVisibility(),
		opacity: this.getOpacity(),
		disabled: this.getDisabled(),
		editing: this.getEditing(),
		canView: this.getCanView(),
		isLoggedIn: getGlobalState("loginState")?.getValue("isLoggedIn"),
		uiConfig: { ...getUIConfig("clientUI"), ...getUIConfig("navBar"), ...getUIConfig("defaultSidebar") },
		checked: false,
	}

	componentDidMount() {
		registerforStateChanges(this.dataChanged)
		registerOnChange("map-layers", this.dataChanged)
		this.setEditingState()
	}
	componentWillUnount() {
		removeOnChange("map-layers", this.dataChanged)
		removeOnChange("layer-groups", this.dataChanged)
		removeStateChanges(this.dataChanged)
	}

	// TODO add a check on selected layer
	dataChanged = (type: string, key: string, data: storedItem) => {
		if (key === "map-layer-editing") {
			this.setEditingState()
		} else if (type === "map-layers" && data === (this.props.item as storedItem | undefined)) {
			this.setState({
				visible: this.getVisibility(),
				opacity: this.getOpacity(),
				disabled: this.getDisabled(),
				canView: this.getCanView(),
			})
		} else if (key === "loginState" && data) {
			this.setState({ isLoggedIn: data.getValue("isLoggedIn") })
		}
	}

	setEditingState() {
		this.setState({ editing: this.getEditing() })
	}
	getOpacity() {
		printMessage("GetOpacity for " + this.props.item.primaryKey(), messageLevels.debug)
		printData(this.props.item.currentOpacity(), messageLevels.debug)
		return Math.round(this.props.item.currentOpacity() * 100)
	}
	getVisibility() {
		return this.props.item.currentVisibility()
	}
	getDisabled() {
		return this.props.item.isDisabled()
	}

	getEditing() {
		return this.props.item.isEditing()
	}
	getCanView() {
		return this.props.item.canView()
	}

	toggle = () => {
		this.props.item.toggleVisibility()
		this.setState({ visible: this.props.item.currentVisibility() })
	}

	toggleEdit = async (layer: mapLayer) => {
		!this.state.visible && this.toggle()
		call("toggleEditControl", layer.getValue("id"), layer.getValue("dataType"))
	}

	addItem = async (layer: mapLayer) => {
		if (this.addEnabled()) {
			const theItem: storedItem | null = layer.addItem()
			if (theItem) {
				printData(theItem, messageLevels.debug, "theItem in addItem")
				setGlobalState("addItem", [theItem])
			}
		}
	}

	convertToTasks = async (layer: mapLayer, id: string) => {
		const forestBlock = this.props.block
		let features = [] as storedItem[]
		if (id == "areas-of-failure") {
			features = forestBlock.areasOfFailure()
		} else {
			features = forestBlock.areasOfConcern()
		}
		const newTasks = []
		const featuresToEdit = []
		// Don't convert already converted
		for (const feature of features.filter((f) => !f.getSingleValue("converted_task_id"))) {
			const newTask: blockFeature | null = instanceNewItem("tasks") as blockFeature | null
			if (!newTask) {
				console.error("task creation failed.")
				return
			}
			newTask.setValue(newTask._primaryKey, "uuid")
			newTask.setValue(feature.getSingleValue("description"), "description")
			newTask.setValue(feature.getSingleValue("geojson"), "geojson")
			newTask.setValue(feature.getSingleValue("consent_number"), "consent_number")
			newTask.setValue(feature.getSingleValue("region"), "region")
			newTask.setValue(feature.getSingleValue("image_links"), "image_links")
			const today = moment().format("DD-MM-YYYY")
			newTask.setValue(today, "date")
			newTask.setValue(moment(), "created_date")
			newTask.setValue("pending", "status")
			newTasks.push(newTask)
			const editedFeature = feature.editCopy()
			if (!editedFeature) {
				throw "Could not create edited feature"
			}
			editedFeature.setSingleValue(newTask.getSingleValue("uuid"), "converted_task_id")
			featuresToEdit.push(editedFeature)
		}
		if (newTasks.length > 0) {
			const [firstTask, ...otherTasks] = newTasks
			setGlobalState("addItem", [firstTask])
			setGlobalState("addItem", [firstTask])
			setGlobalState("addItems", newTasks)
			setGlobalState("editIdx", 1)
			setGlobalState("featuresToEdit", featuresToEdit)
		}
		const theItem = layer
		if (theItem) {
			printData(theItem, messageLevels.debug, "theITem in addItem")
		}
	}

	// TODO setEditing should be higher up
	showEditControl(item: mapLayer) {
		if (item.canEdit() !== true) {
			return null
		}
		if (this.props.options !== undefined) {
			if (this.props.options.allowEdit !== undefined) {
				if (!this.props.options.allowEdit) {
					return null
				}
			}
		}
		const { brandColor } = this.state.uiConfig
		return (
			<Icon
				name="edit"
				size="small"
				style={{
					position: "absolute",
					top: "0.65rem",
					right: 0,
					fontSize: "1.25rem",
					cursor: "pointer",
					color: this.state.editing ? brandColor : "grey",
				}}
				onClick={() => this.toggleEdit(item)}
			/>
		)
	}
	showAddControl(item: mapLayer) {
		if (item.canAdd() !== true) {
			return null
		}
		if (this.props.options !== undefined) {
			if (this.props.options.allowEdit !== undefined) {
				if (!this.props.options.allowEdit) {
					return null
				}
			}
		}
		const { brandColor } = this.state.uiConfig
		return (
			<Icon
				name="plus"
				size="small"
				style={{
					fontSize: "1.25rem",
					cursor: "pointer",
					color: this.state.visible ? brandColor : "grey",
				}}
				onClick={() => this.addItem(item)}
			/>
		)
	}

	getConvertableFeatures(forestBlock: forestBlock, layerId: string) {
		if (layerId == "areas-of-failure") {
			return forestBlock?.areasOfFailure() ?? []
		}
		return forestBlock?.areasOfConcern() ?? []
	}

	showConvertToTaskControl(item: mapLayer) {
		if (this.props.options !== undefined) {
			if (this.props.options.allowEdit !== undefined) {
				if (!this.props.options.allowEdit) {
					return null
				}
			}
		}
		const { brandColor } = this.state.uiConfig
		const forestBlock = this.props.block
		const layerId = item.getValue("id")
		const canConvertToTasks = layerId == "areas-of-concern" || layerId == "areas-of-failure"
		if (canConvertToTasks && forestBlock) {
			const features = this.getConvertableFeatures(forestBlock, layerId) ?? []
			const convertableItems = features.filter((f) => f.getSingleValue("converted_task_id") === null)
			if (convertableItems.length === 0 || !this.state.isLoggedIn) {
				return null
			}
			return (
				<Icon
					name="tasks"
					size="small"
					style={{
						fontSize: "1.25rem",
						cursor: "pointer",
						color: brandColor,
					}}
					title={`Convert ${convertableItems.length} remaining to tasks`}
					onClick={() => this.convertToTasks(item, layerId)}
				/>
			)
		}
		return null
	}
	setLayerOpacity = (layerOpacity: number) => {
		this.props.item.setOpacity(layerOpacity / 100)
		this.setState({ opacity: this.getOpacity() })
	}
	addEnabled() {
		return this.state.visible && !this.getDisabled()
	}

	render() {
		const item = this.props.item as mapLayer
		const { brandColor, fontColor } = this.state.uiConfig
		return (
			<>
				<Item height="50px">
					<div style={{ display: "flex", alignItems: "center" }}>
						<Toggle
							activeColor={brandColor}
							inactiveColor="black"
							checked={this.state.visible}
							disabled={this.getDisabled() || !this.getCanView()}
							onChange={() => this.toggle()}
						/>
						<label style={{ paddingLeft: "10px", fontSize: "12px", color: fontColor, fontWeight: "bold" }}>
							{item.getValue("name")}
						</label>
					</div>
					<Item
						style={{
							position: "absolute",
							top: "0.65rem",
							right: 0,
							padding: 0,
						}}
					>
						{this.state.visible && this.showConvertToTaskControl(item)}
						{this.showAddControl(item)}
					</Item>
				</Item>
				{this.state.visible && item.allowsOpacity() && (
					<Item height="50px">
						<RangeSlider min={1} max={100} value={this.state.opacity} onAfterChange={this.setLayerOpacity} minDistance={1} />
					</Item>
				)}
			</>
		)
	}
}

export default LayerControl
