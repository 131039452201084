import { lineChunk } from "@turf/turf"
import { Feature, LineString } from "geojson"
import { LngLatLike, Map } from "mapbox-gl"

import { call, registerCall } from "../store"

export interface ElevationProfile {
	elevationProfile: {
		[id: string]: ChartReadable[]
	}
	addProfile: (line: Feature<LineString> | Feature<LineString>[], map: Map) => void
	getProfile: (id: string) => any[]
	remove: (id: string | number) => void
}

export interface ChartReadable {
	x: number
	y: number | null
}

export const segmentInMeters = 1

export const createElevation = (): ElevationProfile => {
	const elevationProfile: { [id: string]: ChartReadable[] } = {}
	let _map: Map
	const addProfile = (line: Feature<LineString> | Feature<LineString>[], map: Map): ChartReadable[] | void => {
		if (!_map) _map = map
		if (Array.isArray(line)) {
			line.forEach((l) => addProfile(l, map))
			return
		}
		let isValid = false
		const chunks = lineChunk(line, segmentInMeters, { units: "meters" }).features
		const elevations = [
			...chunks.map((feature, i) => {
				const value = map.queryTerrainElevation(feature.geometry.coordinates[0] as LngLatLike)
				if (value) isValid = true
				return {
					x: i,
					y: value,
				}
			}),
			{
				x: chunks.length - 1,
				y: map.queryTerrainElevation(chunks[chunks.length - 1].geometry.coordinates[1] as LngLatLike),
			},
		]
		if (!line?.id) return
		if (!isValid) return
		elevationProfile[line.id] = elevations
		return elevations
	}

	const getProfile = (id: string) => {
		let profile = elevationProfile[id]
		if (!profile) {
			const line: Feature<LineString> | Feature<LineString>[] = call("getLine", id)
			if (!line) return []
			profile = addProfile(line, _map) || []
		}
		return profile
	}

	const remove = (id: string | number) => {
		delete elevationProfile[id]
	}

	registerCall("getElevationProfile", getProfile)

	return {
		elevationProfile,
		addProfile,
		getProfile,
		remove,
	}
}
