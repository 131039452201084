import React, { Suspense, useEffect, useMemo, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { Canvas, useFrame, useLoader, useThree } from "@react-three/fiber"
import { Environment, OrbitControls } from "@react-three/drei"
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { Icon } from "semantic-ui-react"
import {
	AnimationMixer,
	Clock,
	Color,
	Fog,
	Material,
	Mesh,
	MeshBasicMaterial,
	MeshStandardMaterial,
	Object3D,
	PerspectiveCamera,
	Scene,
	Texture,
	WebGLRenderer,
} from "three"

import { getClassNamePrefix } from "../../store"

interface ModelViewerProps {
	fbxFile?: string
	isOpen: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const FBXModel: React.FC = () => {
	const fbxRef = useRef<Object3D>()

	const fbx = useLoader(FBXLoader, "culvertSection_06.fbx")

	// Create materials from loaded textures
	const createMaterials = (textures: Texture[]): MeshBasicMaterial[] => {
		return textures.map((texture) => {
			const material = new MeshBasicMaterial({ map: texture })
			return material
		})
	}

	// Set the original materials to the model
	useMemo(() => {
		if (fbx && fbx.children.length > 0) {
			const model = fbx.children[0] // Accessing the first child assuming it's the main model

			if (model instanceof Object3D) {
				model.traverse((obj: Object3D) => {
					if (obj instanceof Mesh) {
						const mesh = obj as Mesh & { material: MeshBasicMaterial }
						if (mesh.material && mesh.material.map) {
							const texture = mesh.material.map as Texture
							mesh.material = createMaterials([texture])[0]
							mesh.material.lightMapIntensity = 1 // Adjust the light intensity as needed
							mesh.material.needsUpdate = true
							mesh.material.transparent = false // Ensure materials are not transparent
							mesh.castShadow = true
							mesh.receiveShadow = true
						}
					}
				})
			}
		}
	}, [fbx])

	return (
		<primitive
			ref={fbxRef}
			object={fbx} // Render the loaded model directly
			dispose={null}
			scale={1}
		/>
	)
}

const GLBModel: React.FC = () => {
	const glbRef = useRef<Object3D>()

	const glb = useLoader(GLTFLoader, "culvert.glb")

	// Create materials from loaded textures
	const createMaterials = (textures: Texture[]): MeshStandardMaterial[] => {
		return textures.map((texture) => {
			const material = new MeshStandardMaterial({ map: texture })
			return material
		})
	}

	// Set the original materials to the model
	useMemo(() => {
		if (glb && glb.scene.children.length > 0) {
			const model = glb.scene.children[0] // Assuming the main model is the first child
			if (model instanceof Object3D) {
				model.traverse((obj: Object3D) => {
					if (obj instanceof Mesh) {
						const mesh = obj as Mesh & { material: MeshStandardMaterial }
						if (mesh.material && mesh.material.map) {
							const texture = mesh.material.map as Texture
							mesh.material = createMaterials([texture])[0]
							mesh.material.lightMapIntensity = 1 // Adjust the light intensity as needed
							mesh.material.needsUpdate = true
							mesh.material.transparent = false // Ensure materials are not transparent
							mesh.castShadow = true
							mesh.receiveShadow = true
						}
					}
				})
			}
		}
	}, [glb])

	return (
		<primitive
			ref={glbRef}
			object={glb.scene} // Render the loaded model directly
			dispose={null}
			scale={1}
		/>
	)
}

const ModelViewer: React.FC<ModelViewerProps> = ({ fbxFile, isOpen, setOpen }) => {
	const iconStyle = {
		width: "40px",
		height: "40px",
		position: "fixed",
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		color: "white",
	}

	const closeLightbox = (): void => {
		setOpen(false)
	}

	const Scene = () => {
		const fbx = useLoader(FBXLoader, "Culvert_Diagram.fbx")
		return (
			<>
				<primitive object={fbx} scale={0.005} />
			</>
		)
	}

	// const CameraController = () => {
	// 	const { camera, gl } = useThree()
	// 	useEffect(() => {
	// 		const controls = new OrbitControls(camera, gl.domElement)

	// 		controls.minDistance = 3
	// 		controls.maxDistance = 500
	// 		return () => {
	// 			controls.dispose()
	// 		}
	// 	}, [camera, gl])
	// 	return null
	// }

	function CameraHelper() {
		const camera = new PerspectiveCamera(60, 1, 1, 3)
		return <cameraHelper args={[camera]} />
	}

	return (
		<>
			{isOpen &&
				ReactDOM.createPortal(
					<>
						<div
							className={getClassNamePrefix()}
							style={{
								...(iconStyle as React.CSSProperties),
								top: "50px",
								right: "50px",
								transform: "translate(-50%, -50%)",
								zIndex: 9999,
							}}
							onClick={closeLightbox}
						>
							<Icon size="large" name="close" style={{ color: iconStyle.color }} />
						</div>
						<div
							style={{
								position: "fixed",
								top: 0,
								left: 0,
								width: "100%",
								height: "100%",
								backgroundColor: "rgba(0, 0, 0, 0.8)",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								zIndex: 9900,
								transition: "opacity 0.7s ease-in-  out",
							}}
						>
							<div style={{ height: "650px", width: "1300px", backgroundColor: "white" }}>
								<Canvas shadows camera={{ position: [5, 5, 10], fov: 90 }}>
									{/* <CameraController /> */}
									<Suspense fallback={null}>
										{/* <ambientLight intensity={0.1} /> */}
										<directionalLight position={[10, 10, 5]} intensity={5} />
										{/* <pointLight position={[11, 10, 10]} intensity={3000} color="#fff" /> */}
										{/* <FBXModel /> */}
										<OrbitControls />
										<Environment preset="forest" background />
										<GLBModel />
										<CameraHelper />
										{/* <Test /> */}
										{/* <Scene /> */}
									</Suspense>
								</Canvas>
							</div>
						</div>
					</>,
					document.body,
				)}
		</>
	)
}

export default ModelViewer

// const FBXViewer: React.FC<{ fbxPath: string }> = ({ fbxPath }) => {
// 	const containerRef = useRef<HTMLDivElement>(null)
// 	const controlsRef = useRef<OrbitControls>()
// 	const clock = new Clock()
// 	let camera: PerspectiveCamera, scene: Scene, renderer: WebGLRenderer, stats: Stats | undefined, mixer: THREE.AnimationMixer | undefined

// 	const init = () => {
// 		const container = containerRef.current!
// 		camera = new PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 2000)
// 		camera.position.set(100, 200, 300)

// 		scene = new Scene()
// 		scene.background = new Color(0xa0a0a0)
// 		scene.fog = new Fog(0xa0a0a0, 200, 1000)

// 		// Add lights, ground, and grid...

// 		renderer = new WebGLRenderer({ antialias: true })
// 		renderer.setPixelRatio(window.devicePixelRatio)
// 		renderer.setSize(window.innerWidth, window.innerHeight)
// 		renderer.shadowMap.enabled = true
// 		container.appendChild(renderer.domElement)

// 		stats = new Stats()
// 		container.appendChild(stats.dom)

// 		loadFBXModel()
// 	}

// 	const onWindowResize = (): void => {
// 		camera.aspect = window.innerWidth / window.innerHeight
// 		camera.updateProjectionMatrix()
// 		renderer.setSize(window.innerWidth, window.innerHeight)
// 	}

// 	const loadFBXModel = (): void => {
// 		const loader = new FBXLoader()
// 		loader.load(fbxPath, (object) => {
// 			mixer = new AnimationMixer(object)

// 			const action = mixer.clipAction(object.animations[0])
// 			action.play()

// 			object.traverse((child) => {
// 				if ((child as Mesh).isMesh) {
// 					const mesh = child as Mesh
// 					mesh.castShadow = true
// 					mesh.receiveShadow = true
// 				}
// 			})

// 			scene.add(object)
// 		})
// 	}

// 	const animate = (): void => {
// 		requestAnimationFrame(animate)

// 		const delta = clock.getDelta()

// 		if (mixer) mixer.update(delta)

// 		renderer.render(scene, camera)

// 		if (stats) stats.update()
// 	}

// 	useEffect(() => {
// 		init()
// 		animate()

// 		controlsRef.current = new OrbitControls(camera, renderer.domElement)
// 		controlsRef.current.target.set(0, 100, 0)
// 		controlsRef.current.update()

// 		window.addEventListener("resize", onWindowResize)

// 		return () => {
// 			window.removeEventListener("resize", onWindowResize)
// 			// Cleanup logic if needed
// 		}
// 	}, [])

// 	return <div ref={containerRef} />
// }

// const Scene: React.FC = () => {
// 	const fontLoader = new THREE.FontLoader()
// 	const containerRef = useRef<HTMLDivElement>(null)

// 	fontLoader.load("fonts/gentilis_regular.typeface.json", (font) => {
// 		const scene = new THREE.Scene()
// 		scene.background = new THREE.Color(0x444488)

// 		const camera = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight, 1, 2500)
// 		camera.position.set(0.0, 400, 400 * 3.5)

// 		const renderer = new THREE.WebGLRenderer({ antialias: true })
// 		renderer.setPixelRatio(window.devicePixelRatio)
// 		renderer.setSize(window.innerWidth, window.innerHeight)

// 		if (containerRef.current) {
// 			containerRef.current.appendChild(renderer.domElement)
// 		}

// 		const ambientLight = new THREE.AmbientLight(0xc1c1c1, 3)
// 		scene.add(ambientLight)

// 		const particleLight = new THREE.Mesh(new THREE.SphereGeometry(4, 8, 8), new THREE.MeshBasicMaterial({ color: 0xffffff }))
// 		scene.add(particleLight)

// 		const pointLight = new THREE.PointLight(0xffffff, 2, 800, 0)
// 		particleLight.add(pointLight)

// 		const controls = new OrbitControls(camera, renderer.domElement)
// 		controls.minDistance = 200
// 		controls.maxDistance = 2000

// 		const stats = new Stats()
// 		if (containerRef.current) {
// 			containerRef.current.appendChild(stats.dom)
// 		}

// 		const addLabel = (name: string, location: THREE.Vector3) => {
// 			const textGeo = new THREE.TextGeometry(name, {
// 				font: font,
// 				size: 20,
// 				height: 1,
// 				curveSegments: 1,
// 			})

// 			const textMaterial = new THREE.MeshBasicMaterial()
// 			const textMesh = new THREE.Mesh(textGeo, textMaterial)
// 			textMesh.position.copy(location)
// 			scene.add(textMesh)
// 		}

// 		addLabel("-gradientMap", new THREE.Vector3(-350, 0, 0))
// 		addLabel("+gradientMap", new THREE.Vector3(350, 0, 0))
// 		addLabel("-diffuse", new THREE.Vector3(0, 0, -300))
// 		addLabel("+diffuse", new THREE.Vector3(0, 0, 300))

// 		const animate = () => {
// 			requestAnimationFrame(animate)

// 			const timer = Date.now() * 0.00025
// 			particleLight.position.x = Math.sin(timer * 7) * 300
// 			particleLight.position.y = Math.cos(timer * 5) * 400
// 			particleLight.position.z = Math.cos(timer * 3) * 300

// 			renderer.render(scene, camera)
// 			stats.update()
// 		}

// 		window.addEventListener("resize", () => {
// 			camera.aspect = window.innerWidth / window.innerHeight
// 			camera.updateProjectionMatrix()
// 			renderer.setSize(window.innerWidth, window.innerHeight)
// 		})

// 		animate()
// 	})

// 	return <div ref={containerRef} />
// }

// const Test: React.FC = () => {
// 	const modelRef = useRef<Object3D>()

// 	// Load the model using FBXLoader within the useEffect hook
// 	const model = useLoader(FBXLoader, "Culvert_Diagram.fbx")

// 	if (model) {
// 		model.traverse((child) => {
// 			if ((child as Mesh).isMesh) {
// 				const mesh = child as Mesh
// 				mesh.castShadow = true
// 				mesh.receiveShadow = true
// 			}
// 		})
// 	}

// 	return (
// 		<primitive
// 			object={model}
// 			ref={modelRef}
// 			scale={0.5} // Adjust the scale as needed
// 		/>
// 	)
// }

// const FBXModel: React.FC = () => {
// 	const fbxRef = useRef<Object3D>()

// 	const fbx = useLoader(FBXLoader, "Culvert_Diagram.fbx")

// 	// Extract the group containing the loaded model
// 	const group = useMemo(() => {
// 		const group = new Object3D()
// 		if (fbx) {
// 			group.add(fbx)
// 		}
// 		return group
// 	}, [fbx])

// 	// Set the original lighting for the model
// 	useMemo(() => {
// 		if (fbx && fbx.children.length > 0) {
// 			const model = fbx.children[0] // Accessing the first child assuming it's the main model
// 			if (model instanceof Object3D) {
// 				model.traverse((obj: Object3D) => {
// 					if ((obj as any).isMesh) {
// 						const material = (obj as any).material as Material
// 						if (material) {
// 							;(material as any).lightMapIntensity = 1 // Adjust the light intensity as needed
// 							material.needsUpdate = true
// 						}
// 					}
// 				})
// 			}
// 		}
// 	}, [fbx])

// 	return (
// 		<primitive
// 			ref={fbxRef}
// 			object={group} // Use the group containing the loaded model
// 			dispose={null}
// 		/>
// 	)
// }

// const FBXModel: React.FC = () => {
// 	const fbxRef = useRef<Object3D>()

// 	const fbx = useLoader(FBXLoader, "Culvert_Diagram.fbx")

// 	// Set the original materials to the model
// 	useMemo(() => {
// 		if (fbx && fbx.children.length > 0) {
// 			const model = fbx.children[0] // Accessing the first child assuming it's the main model
// 			if (model instanceof Object3D) {
// 				model.traverse((obj: Object3D) => {
// 					if ((obj as any).isMesh) {
// 						const mesh = obj as Object3D & { material: Material }
// 						if (mesh.material) {
// 							mesh.material.transparent = false // Ensure materials are not transparent
// 						}
// 					}
// 				})
// 			}
// 		}
// 	}, [fbx])

// 	return (
// 		<primitive
// 			ref={fbxRef}
// 			object={fbx} // Render the loaded model directly
// 			dispose={null}
// 		/>
// 	)
// }
