import React, { Component } from "react"

import { StoreControlComponentProps } from "."
import { getGlobalState, setGlobalState } from ".."
import { storedItem } from "../storedItem"

interface SwitchControlState {
	value: any
}

export class GlobalStateControl extends React.Component<StoreControlComponentProps, SwitchControlState> {
	state: SwitchControlState = {
		value: null,
	}

	get dataItem(): storedItem | null | undefined {
		return getGlobalState(this.props.name)
	}

	setValue = (value: any, forKey: string) => {
		setGlobalState(forKey, value)
	}

	getChecked = () => {
		const theCheck = this.dataItem?.getValue()

		if (!theCheck) {
			return false
		}
		return theCheck
	}

	setChecked = (value: boolean | undefined) => {
		this.setValue(value, this.props.name)
	}

	render() {
		// if (this.props.item && this.props.item !== null) {
		// 	return <>{this.renderField(this.props.field, this.props.index)}</>;
		// } else {
		return <div>A Form</div>
		// }
	}
}
