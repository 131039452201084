import React, { useState } from "react"
//@ts-ignore
import ReactSlider from "react-slider"
import { Form } from "semantic-ui-react"

import "./styles.css"
import { getUIConfig } from "../../store"

const NLRangeSlider = (props: any) => {
	const { className } = getUIConfig("clientUI")
	const { max, min, minDistance, onChange, value, onBeforeChange, onAfterChange, remainingProps, minLabel, maxLabel, hideInput } = props
	const distance = max - min
	let actualMax = max
	let actualMin = min
	const [inputValue, setInputValue] = useState(false)

	let positionValues: number[] = []
	if (distance > 200) {
		actualMax = 200
		actualMin = 1
	}
	const minv = Math.log(min || 1)
	const maxv = Math.log(max || 200)

	const scale = (maxv - minv) / (actualMax - actualMin)

	const scaledValue = (position: number): number => {
		const theValue = Math.exp(minv + scale * (position - actualMin))
		if (inputValue) {
			return Math.max(roundToRangeValue(theValue), 1)
		}
		return Math.max(Math.round(theValue), 1)
	}
	const scaledValueOrInput = (position: number, i: number): number => {
		if (inputValue) {
			return value[i]
		}
		return scaledValue(position)
	}

	const valueToPosition = (value: number): number => {
		const x = Math.log(value) - minv
		const y = x / scale
		const position = y + actualMin
		return Math.max(position, 1)
	}

	const valuesToPosition = (values: number[]) => {
		positionValues = []
		value.forEach((val: any) => {
			positionValues.push(valueToPosition(val))
		})
		return positionValues
	}

	const onBeforeChangeModify = (value: number[], i: number) => {
		setInputValue(false)

		if (onBeforeChange) {
			const theValues: number[] = []

			value.forEach((val) => {
				theValues.push(scaledValue(val))
			})

			onBeforeChange(theValues, i)
		}
	}
	const onAfterChangeModify = (value: number[], i: number) => {
		if (onAfterChange) {
			const theValues: number[] = []

			value.forEach((val) => {
				theValues.push(scaledValue(val))
			})

			onAfterChange(theValues, i)
		}
	}

	const onChangeModify = (value: number[], i: number) => {
		if (onChange) {
			const theValues: number[] = []

			value.forEach((val) => {
				theValues.push(scaledValue(val))
			})

			onChange(theValues, i)
		}
	}
	const onInputChange = (valueChange: any, i: number) => {
		setInputValue(false)
		value[i] = parseInt(valueChange) || 0
		positionValues[i] = Math.round(valueToPosition(valueChange))

		if (onAfterChange) {
			onAfterChange(value, i)
		}
	}

	const ariaValueTextFn = (state: { index: number; value: number; valueNow: number }): string => {
		const thumbName = ["From", "To"][state.index]
		return `${thumbName} value ${scaledValueOrInput(state.valueNow, state.index)}`
	}
	let values

	if (value) {
		values = valuesToPosition(value)
	}
	return (
		<>
			<Form size={"tiny"} inverted>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						height: "100%",
					}}
				>
					{hideInput ? null : (
						<Form.Field
							className="min-label"
							control="input"
							value={value[0]}
							onChange={({ target: { value } }: { target: any; value: any }) => onInputChange(value, 0)}
						/>
					)}
					<ReactSlider
						className={className + " horizontal-slider range"}
						thumbClassName="example-thumb"
						trackClassName="example-track"
						value={positionValues}
						ariaLabel={["From thumb", "To thumb"]}
						ariaValuetext={ariaValueTextFn}
						renderThumb={(props, state) => {
							const value = scaledValueOrInput(state.valueNow, state.index)
							return (
								<div {...props}>
									<div style={{ position: "absolute", top: "-30px" }}>{state.index ? "Max" : "Min"}</div>
									<div>{value}</div>
								</div>
							)
						}}
						pearling
						max={actualMax}
						min={actualMin}
						minDistance={minDistance}
						onBeforeChange={onBeforeChangeModify}
						onAfterChange={onAfterChangeModify}
						onChange={onChangeModify}
						{...remainingProps}
					/>
					{hideInput ? null : (
						<Form.Field
							className="max-label"
							control="input"
							value={value[1]}
							onChange={({ target: { value } }: { target: any; value: any }) => onInputChange(value, 1)}
						/>
					)}
				</div>
			</Form>
		</>
	)
}
function roundNearest(num: number, roundTo: number) {
	return Math.round(num / roundTo) * roundTo
}
function roundToRangeValue(sliderValue: number) {
	if (sliderValue >= 10000) {
		return roundNearest(sliderValue, 500)
	}
	if (sliderValue >= 1000) {
		return roundNearest(sliderValue, 50)
	}
	if (sliderValue >= 100) {
		return roundNearest(sliderValue, 10)
	}
	if (sliderValue >= 50) {
		return roundNearest(sliderValue, 5)
	}
	return roundNearest(sliderValue, 1)
}
export default NLRangeSlider
