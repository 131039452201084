import React from "react"
import { Button, Divider, Form, Grid, Icon, Label, Loader, Menu, Modal, Popup, Segment, TextAreaProps } from "semantic-ui-react"
import { BlobProvider, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer"
import ReactDOM from "react-dom"

import ArrayListComponent from "../../mapsight/ArrayListComponent"
import { storedItem } from "../storedItem"
import Section from "../../components/Section"
import { forestOwner } from "../classes/forest"
import { catchment } from "../classes/catchment"
import { dataFeed, getDataFeedByName } from "../dataFeed"
import { ItemViewProps } from "./Types"
import { FormFieldProperties, FormPageItemProps, formFormatNumber } from "../Forms"
import PDFContext from "../Forms/PDF/PDFContext"
import { call } from ".."
import { StoredItemEditView } from "./StoredItem"
import { CustomModal } from "../../components/CustomModal"

export interface CatchmentInfoLayerState {
	changed: boolean
	dataFeed: dataFeed | null | undefined
}

export class CatchmentEditView extends StoredItemEditView {
	onNameChange = (item: storedItem) => (event: React.SyntheticEvent<HTMLElement, Event>, data: TextAreaProps) => {
		this.setValue(data.value, "name")
	}

	render() {
		const item = this.props.editingItem
		if (!item) return null
		return (
			<Form>
				<>
					{this.renderHeading()}
					<Segment inverted basic>
						<Form.TextArea
							label="Name"
							maxLength={128}
							placeholder="Name..."
							name="name"
							defaultValue={item.getValue("name")}
							onChange={this.onNameChange(item)}
						/>
					</Segment>
				</>
			</Form>
		)
	}
}
export class CatchmentInfoView extends React.Component<ItemViewProps, CatchmentInfoLayerState> {
	state = {
		changed: false,
		dataFeed: getDataFeedByName("forest-ownership", false),
	}
	componentDidMount() {
		if (!this.state.dataFeed) {
			this.setState({ dataFeed: getDataFeedByName("forest-ownership", false) })
		}
		if (this.state.dataFeed) {
			this.state.dataFeed.registerOnChange(this.onDataChange, {})
		}
	}

	componentWillUnmount() {
		if (this.state.dataFeed) {
			this.state.dataFeed.removeOnChange(this.onDataChange)
		}
	}

	onDataChange = () => {
		const isChanged = this.state.changed || false
		this.setState({ changed: !isChanged })
	}
	forestCellFormatter = (item: storedItem, index: any, hovered: boolean) => {
		const { selectedItem } = this.props
		const ownerItem = item as forestOwner
		const theCatchment = selectedItem as catchment
		const theTotalHa = ownerItem.getValue("area_ha")
		const insideHa: number = formFormatNumber(theCatchment.overlapAreaForForest(ownerItem), {
			maximumFractionDigits: 2,
			unit: "hectare",
			style: "unit",
		})
		let ofHa = ""
		if (theTotalHa - insideHa > 0.01) {
			ofHa =
				" / " +
				formFormatNumber(theTotalHa, {
					maximumFractionDigits: 2,
					unit: "hectare",
					style: "unit",
				})
		}
		let ageFromStart = ownerItem.ageFromStart()
		ageFromStart = formFormatNumber(
			ageFromStart,
			{
				maximumFractionDigits: 0,
				unit: "year",
				style: "unit",
			},
			undefined,
			"unknown",
		)
		return (
			<>
				<Menu.Item
					key={`block_${ownerItem.primaryKey()}`}
					onMouseOver={() => call("setRolloverByItem", ownerItem)}
					onMouseLeave={() => call("setRolloverByItem", null)}
					onClick={() => {
						ownerItem.selectOnMap()
					}}
					active={ownerItem.isSelected()}
				>
					{ownerItem.getValue("owner")}
					<small>
						&nbsp;({insideHa}
						{ofHa})
					</small>
					{ownerItem.ageFromStart() ? <small>&nbsp;({ageFromStart})</small> : null}
					<Label color={ownerItem.getComplianceStatus().color.name}>{ownerItem.ageFromStart() ? "Planted" : "Planting"}</Label>
				</Menu.Item>
			</>
		)
	}

	renderInfoSection(theCatchment: catchment) {
		const catchmentArea = theCatchment.getValue("sum_area")
		const forestOverlapArea = theCatchment.forestOverlapArea
		const filteredForestArea = theCatchment.filteredForestArea
		const overlapAreaPercent = theCatchment.overlapAreaPercent
		const filteredAreaPercent = theCatchment.filteredAreaPercent
		const forestAreaPercent = theCatchment.filteredForestAreaOfTotalForestPercent

		return (
			<>
				<Grid columns={15}>
					<Grid.Row>
						<Grid.Column width={9}>
							<Icon name={"object ungroup outline"} size="small" />
							Total Catchment Area:
						</Grid.Column>
						<Grid.Column width={4}>{catchmentArea} ha</Grid.Column>
						<Grid.Column>
							<Popup
								inverted
								position="right center"
								content="This is the total area of this catchment in Hectares"
								trigger={<Icon circular name="question circle" />}
							></Popup>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={9}>
							<Icon name={"object ungroup outline"} size="small" />
							Total Forest Area:
						</Grid.Column>
						<Grid.Column width={4}>{forestOverlapArea.toFixed(2)} ha</Grid.Column>
						<Grid.Column>
							<Popup
								inverted
								position="right center"
								content="This is the total area of plantation forests within this catchment."
								trigger={<Icon circular name="question circle" />}
							></Popup>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={9}>
							<Icon name={"object ungroup outline"} size="small" />
							Percentage of Total Catchment:
						</Grid.Column>
						<Grid.Column width={4}>{overlapAreaPercent}%</Grid.Column>
						<Grid.Column>
							<Popup position="right center" trigger={<Icon circular name="question circle" />} flowing inverted>
								<p>This is the percentage of the catchment that the filtered forest area covers.</p>
								Forest area / Total Catchment Area
							</Popup>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={9}>
							<Divider />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={9}>Filtered Forest Area:</Grid.Column>
						<Grid.Column width={4}>{filteredForestArea.toFixed(2)} ha</Grid.Column>
						<Grid.Column>
							<Popup position="right center" inverted trigger={<Icon circular name="question circle" />}>
								This is the total area of plantation forest in this catchment with the current filters applied
								<br />
								or the total if no filters applied.
							</Popup>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={9}>Percentage of Total Forest Area:</Grid.Column>
						<Grid.Column width={4}>{forestAreaPercent}%</Grid.Column>
						<Grid.Column>
							<Popup position="right center" trigger={<Icon circular name="question circle" />} flowing inverted>
								<p>This is the percentage of the plantation forest in this catchment that filtered forest area covers.</p>
								Filtered Forest area / Total Forest Area
							</Popup>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</>
		)
	}
	renderReportLink(selectedItem: storedItem) {
		if (!selectedItem) return null
		const title = (selectedItem.displayName() || "Catchment") + " Report"
		const fileName = title
		const pdfDocument = (
			<PDFContext
				key={selectedItem.primaryKey()}
				// pages={CatchmentPDFForm}
				formID="catchment-report"
				item={selectedItem}
				title={title}
			/>
		)
		return (
			<>
				<CustomModal
					trigger={
						<Button primary>
							<Icon name="file pdf" />
							View Catchment Report
						</Button>
					}
					closeIcon={false}
				>
					<Loader style={{ zIndex: "unset" }} />
					<div style={{ textAlign: "center", height: "100%", zIndex: 1 }}>
						<PDFViewer showToolbar={false}>{pdfDocument}</PDFViewer>
						<BlobProvider document={pdfDocument}>
							{({ url, ...rest }) => {
								return (
									<Button as="a" primary href={url} target="_blank">
										<Icon name="window restore outline" />
										Open in new tab
									</Button>
								)
							}}
						</BlobProvider>
						<PDFDownloadLink key={selectedItem.primaryKey()} document={pdfDocument} fileName={fileName}>
							{({ blob, url, loading, error }) =>
								loading ? (
									"Generating Report PDF..."
								) : (
									<>
										<Button primary>
											<Icon name="download" />
											Download
										</Button>
										<br />
										{error}
									</>
								)
							}
						</PDFDownloadLink>
					</div>
				</CustomModal>
			</>
		)
	}

	render() {
		const { selectedItem } = this.props

		if (!selectedItem) {
			return <></>
		}
		const theCatchment = selectedItem as catchment

		return (
			<div id="catchment">
				<Section title={theCatchment.displayName()}>{this.renderInfoSection(theCatchment)}</Section>
				<Section textAlign="center">{this.props.print ? <></> : this.renderReportLink(selectedItem)}</Section>
				<Section title={"Contained Forests"}>
					<Menu size="large" vertical fluid={true} borderless={true} inverted={true} className="with-labels">
						<ArrayListComponent
							data={theCatchment.filteredForests()}
							cellFormatter={this.forestCellFormatter}
							noWrapper
						></ArrayListComponent>
					</Menu>
				</Section>
			</div>
		)
	}
}
// const catchmentFields: FormFieldProperties[] = [
// 	{
// 		name: "",
// 		label: "",
// 		type: "block",
// 		subfields: [{ name: "", label: "leftSide", type: "heading" }],
// 	},
// ];
// const leftSide: FormFieldProperties = {
// 	name: "mapImageName",
// 	label: "Catchment Map",
// 	type: "image",
// };
// const rightSide: FormFieldProperties = {
// 	name: "",
// 	label: "",
// 	type: "block",
// 	style: { color: "white", backgroundColor: "#363D4D" },
// 	subfields: [
// 		{ name: "", label: "Catchment Statistics", type: "banner" },
// 		{
// 			name: "",
// 			label: "",
// 			type: "block",
// 			style: { color: "white", backgroundColor: "#363D4D" },
// 			subfields: [
// 				{
// 					name: "sum_area",
// 					label: "Total Catchment Area",
// 					type: "display",
// 					formatOptions: { maximumFractionDigits: 2, style: "unit", unit: "hectare" },
// 					style: { fontSize: 15 },
// 				},
// 				{
// 					name: "forestOverlapArea",
// 					label: "Total Forest Area",
// 					type: "display",
// 					formatOptions: { maximumFractionDigits: 2, style: "unit", unit: "hectare" },
// 					style: { fontSize: 15 },
// 				},
// 				{
// 					name: "filteredAreaPercent",
// 					label: "Percentage of total catchment",
// 					type: "display",
// 					scale: 0.01,
// 					formatOptions: { maximumFractionDigits: 1, style: "percent" },
// 					style: { fontSize: 15 },
// 				},
// 				{ name: "", label: "", type: "separator" },
// 				{
// 					name: "filteredForestArea",
// 					label: "Filtered Forest Area",
// 					type: "display",
// 					formatOptions: { maximumFractionDigits: 2, style: "unit", unit: "hectare" },
// 					style: { fontSize: 15 },
// 				},
// 				{
// 					name: "filteredAreaPercent",
// 					label: "Percentage of total forest area",
// 					type: "display",
// 					scale: 0.01,
// 					formatOptions: { maximumFractionDigits: 2, style: "percent" },
// 					style: { fontSize: 15 },
// 				},
// 			],
// 		},
// 	],
// };

// // const leftSide: FormFieldProperties = { name: "", label: "Left Side", type: "banner" };
// // const rightSide: FormFieldProperties = { name: "", label: "Right Side", type: "banner" };
// const titlepageFields: FormFieldProperties[] = [
// 	{ name: "displayName", label: "Catchment Report for Plantation Forests", type: "reportHeader" },
// 	{
// 		name: "",
// 		label: "",
// 		type: "horizontalGroup",
// 		subfields: [leftSide, rightSide],
// 	},
// ];

// const titlepage: FormPageItemProps = {
// 	title: "Catchment Report",
// 	fields: titlepageFields,
// 	style: { color: "white", backgroundColor: "#363D4D" },
// };

// const listPageFields: FormFieldProperties[] = [
// 	{ name: "", label: "Contained Forests", type: "banner" },
// 	{
// 		name: "filteredForests",
// 		label: "",
// 		type: "list",
// 		style: { color: "white", backgroundColor: "#363D4D" },
// 		subfields: [
// 			{ name: "displayName", label: "", type: "display" },
// 			{
// 				name: "areaOverlappingTypeKey(parentItem)",
// 				label: "Area in catchment",
// 				type: "display",
// 				formatOptions: { maximumFractionDigits: 2, style: "unit", unit: "hectare" },
// 			},
// 			{
// 				name: "ageFromStart",
// 				label: "Age",
// 				type: "display",
// 				formatOptions: { maximumFractionDigits: 0, style: "unit", unit: "year" },
// 				defaultValue: "unknown",
// 			},
// 			{ name: "owner", label: "Owner", type: "display" },
// 		],
// 	},
// ];
// const listPage: FormPageItemProps = {
// 	title: "Catchment Report",
// 	fields: listPageFields,
// 	style: { color: "white", backgroundColor: "#363D4D" },
// };
// const CatchmentPDFForm: FormPageItemProps[] = [titlepage, listPage];
