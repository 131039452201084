import React from "react"
import { Divider, Header, Image, Segment } from "semantic-ui-react"
import { StoredItemInfoView } from "./StoredItem"
import { ItemViewProps } from "./Types"

export class IslandInfoView extends StoredItemInfoView<ItemViewProps> {
	state = {
		userName: "",
		isLoggedIn: false,
	}

	// htmlMarkup() {
	// 	const theItem = this.props.item;
	// 	const descriptionFragment = { __html: theItem.getValue("info_box") || ""};
	// 	return {__html: descriptionFragment};
	//   }

	// htmlDIV() {
	// 	return <div dangerouslySetInnerHTML={this.htmlMarkup()} />;
	//   }
	render() {
		const isLoggedIn = this.state.isLoggedIn
		const userName = this.state.userName || ""
		const theItem = this.props.selectedItem
		// const descriptionFragment = theItem.getValue("info_box") || "";
		const descriptionFragment = { __html: theItem?.getValue("info_box") || "" }
		const formattedDIV = () => <div dangerouslySetInnerHTML={descriptionFragment} />
		return (
			<div id="Island-block">
				<Segment inverted basic>
					<Image src={process.env.PUBLIC_URL + "/media/remotehq-logo-white.png"} size="medium" />
				</Segment>
				<Segment inverted basic>
					<Header as="h2" inverted>
						{theItem?.displayName()}
					</Header>
					<Divider />
					<div>Kia ora {isLoggedIn ? this.state.userName : ""} and Welcome!</div>
					<div>
						RemoteHQ helps Regional Councils, the forestry industry and the public get better insight into forestry activity in
						their Regions.
					</div>
					<Divider />
					<div dangerouslySetInnerHTML={descriptionFragment} />
				</Segment>
			</div>
		)
	}
}
