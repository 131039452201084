import { Icon } from "semantic-ui-react"

export const Legend = (props: any) => {
	const renderLegendKeys = (stop: [string, string, number], i: number) => {
		// let width = stop[2] || 3;
		return (
			<div key={i} className="txt-s">
				<span className="mr6 w9 inline-block align-middle " style={{ backgroundColor: stop[1], height: stop[2] || 3 }} />
				<span>{`${stop[0].toLocaleString()}`}</span>
			</div>
		)
	}

	return (
		<div className="bg-white mb24 py12 px12 shadow-darken10 round z1">
			<div className="mb6">
				<h2
					className="txt-bold txt-s block"
					style={{ float: "right", cursor: "pointer", color: "#1b1c1d" }}
					onClick={() => props.showRemoteHqBox(false)}
				>
					<Icon name="close" />
				</h2>
				{/*<p className="txt-s color-gray">{props.active.description}</p>*/}
			</div>
			{props.labels.map(renderLegendKeys)}
		</div>
	)
}
