import React from "react"
import { Divider, Header, Segment } from "semantic-ui-react"

import { blockFeature } from "../classes/blockFeature"
import PinPlaceGeoJSONPoint from "../../mapsight/PinGeoJSONPoint"
import { BlockFeatureInfoView } from "./BlockFeature"
import ImageList from "../components/Image/ImageListComponent"
import { AreaOfEditView } from "./AreaOf"

export class StreamCrossingEditView extends AreaOfEditView {}
export class StreamCrossingInfoView extends BlockFeatureInfoView {
	render() {
		const { selectedItem } = this.props

		if (!selectedItem) {
			return <></>
		}
		const imageLinks = (selectedItem as blockFeature).imageURLs
		const theLocation = selectedItem?.getValue("geojson")
		return (
			<div>
				<Segment inverted basic>
					<Header as="h2" inverted>
						{selectedItem.friendlyDisplayName}
					</Header>
					<Divider style={{ margin: 0 }} />
				</Segment>
				<Segment inverted basic>
					<ImageList imageLinks={imageLinks}></ImageList>
				</Segment>
				<Segment inverted basic>
					<Header as="h4" inverted>
						Description
					</Header>
					<Divider />
					{selectedItem.getValue("description") ? selectedItem.getValue("description") : "No description available"}
				</Segment>
				<Segment inverted basic>
					<Header as="h4" inverted>
						Location
					</Header>
					<PinPlaceGeoJSONPoint edit={false} pinColor={"#10e0e0"} initialLocation={theLocation}></PinPlaceGeoJSONPoint>
				</Segment>
			</div>
		)
	}
}
