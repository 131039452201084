import React from "react"
import { Divider, Grid } from "semantic-ui-react"

import { FieldLabel } from "./FieldLabel"
import { FormItemComponentProps } from "../.."

export class DisplayInputComponent extends React.Component<FormItemComponentProps> {
	render() {
		const { field, item } = this.props
		return (
			<>
				<Grid>
					<Grid.Column width={6}>
						<FieldLabel item={item} container={this.props.container} field={field} />
					</Grid.Column>
					<Grid.Column width={6}>{item?.getValue(field.name)}</Grid.Column>
				</Grid>
				<Divider />
			</>
		)
	}
}
