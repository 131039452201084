import React, { Component } from "react"
import { Button, Divider, Form, Header, Icon, Menu, Radio } from "semantic-ui-react"

import { isStoredItem } from "../../.."
import { storedItem } from "../../../storedItem"
import { FormFieldProperties, FormItemComponentProps } from "../../index"
import CheckboxDateRangeItem from "../CheckboxDateRangeItem"
import { printData, printMessage } from "../../../../providers/remoteHQ"
import { messageLevels } from "../../../types"
import PinPlaceGeoJSONPoint from "../../../../mapsight/PinGeoJSONPoint"
import { DisplayTextComponent } from "./DisplayTextComponent"
import { DisplayInputComponent } from "./DisplayInputComponent"
import { ImageUploadFormComponent } from "./ImageUploadFormComponent"
import { ReactFormBlock } from "../FormBlock"

export class FormItemComponent extends Component<FormItemComponentProps> {
	state = {
		isGeoSet: false,
	}

	componentDidMount(): void {
		const { page, field } = this.props
		const key = `${page?.title}:${field?.name}`
		let value = localStorage.getItem(key)
		if (value) {
			value = JSON.parse(value)
			this.updateFormData(field, value)
		}
	}

	updateFormData(field: FormFieldProperties, value: any) {
		if (this.props.setValue && field.name) {
			this.props.setValue(value, field.name)
		} else {
			this.setValue(value, field.name)
		}
	}
	setValue = (value: any, forKey: string) => {
		const theItem: storedItem | null | undefined = this.props.item
		if (theItem && theItem !== undefined) {
			// printMessage("setting value: " + value,messageLevels.verbose)
			// printMessage("for forKey: " + forKey,messageLevels.verbose)
			theItem.setValue(value, forKey)

			this.setDirty()
		}
	}
	setDirty = () => {
		if (this.props.setDirty) {
			this.props.setDirty()
		}
	}
	getChecked = () => {
		const theCheck = this.props.item?.getValue(this.props.field.name)
		if (theCheck === undefined) {
			return false
		}
		return theCheck
	}

	setChecked = (value: boolean | undefined) => {
		this.setValue(value, this.props.field.name)
	}
	errorForKey = (forKey: string): any => {
		const theItem: storedItem | null | undefined = this.props.item
		if (theItem && theItem !== undefined) {
			return theItem.validationErrorForKey(forKey)
		}
		return null
	}

	render() {
		if (this.props.item && this.props.item !== null) {
			return <>{this.renderField(this.props.field, this.props.index)}</>
		} else {
			return null
		}
	}

	renderField(field: FormFieldProperties, index: number) {
		const displayMode = this.props.displayMode || false
		const showInDisplay = field.showInDisplay || true

		if (!displayMode || showInDisplay) {
			switch (field.type.toLowerCase()) {
				case "heading":
					return this.renderHeading(field)
				case "display":
					return this.renderDisplay(field)

				case "input":
					return this.renderInput(field)
				case "number":
					return this.renderNumber(field)
				case "phone":
					return this.renderPhone(field)
				case "text":
					return this.renderText(field)
				case "separator":
				case "seperator":
					return this.renderSeparator()

				case "checkbox":
					return this.renderCheckbox(field)

				case "checkboxdaterange":
					return this.renderCheckboxDateRange(field, index)

				case "options":
					return this.renderOptions(field)

				case "locationpicker":
					return this.renderLocationPicker(field)

				case "upload":
					return this.renderUpload(field, index, false)

				case "uploadfile":
					return this.renderUpload(field, index, true)

				case "block":
					return this.renderBlock(field, index)
				case "point":
					return this.renderPoint(field, index)
				case "displaytext":
					return this.renderDisplayText(field, index)
				case "displayinput":
					return this.renderDisplayText(field, index)
				case "clear":
					return this.renderClear(field, index)
				case "icon":
					return this.renderIcon(field, index)
				case "cancel":
					return this.renderCancel(field, index)
				default:
					return null
			}
		} else {
			return null
		}
	}

	renderIcon(field: FormFieldProperties, index: number) {
		return <Icon name={field.icon} color={field.color} size={field.size} style={field.style} />
	}

	renderClear(field: FormFieldProperties, index: number) {
		const { page } = this.props
		return (
			<Form.Button
				className="reset-button"
				onClick={() => page?.fields.forEach((field) => this.updateFormData(field, ""))}
				type="reset"
				icon="trash"
				content={field.label}
				color="orange"
			/>
		)
	}

	renderCancel(field: FormFieldProperties, index: number) {
		return <Button onClick={this.props.onCancel} content={field.label} color={field.color} style={field.style} />
	}

	renderBlock(field: FormFieldProperties, index: number) {
		let theItem = this.props.item
		const theValue = theItem?.getValue(field.name)
		if (theValue && isStoredItem(theValue)) {
			theItem = theValue
		}
		return (
			<ReactFormBlock
				item={theItem}
				field={field}
				key={index}
				index={index}
				displayMode={this.props.displayMode}
				setValue={this.props.setValue}
				container={this.props.container}
			></ReactFormBlock>
		)
	}
	renderInput(field: FormFieldProperties) {
		const error = this.errorForKey(field.name)
		printMessage("DisplayMode: " + this.props.displayMode, messageLevels.debug)
		if (this.props.displayMode) {
			return this.renderDisplay(field)
		} else {
			return (
				// <div className={this.classesForField(field)}>
				<Form.Input
					className={this.classesForField(field)}
					label={field.label}
					autoComplete="no"
					value={this.props.item?.getValue(field.name) || ""}
					error={
						error
							? {
									pointing: "left",
									content: error,
							  }
							: null
					}
					onChange={(e, { value }) => this.updateFormData(field, value)}
				/>
				// </div>
			)
		}
	}

	renderNumber(field: FormFieldProperties) {
		const error = this.errorForKey(field.name)
		printMessage("DisplayMode: " + this.props.displayMode, messageLevels.debug)
		if (this.props.displayMode) {
			return this.renderDisplay(field)
		} else {
			return (
				<Form.Input
					className={this.classesForField(field)}
					type="text"
					label={field.label}
					autoComplete="no"
					value={this.props.item?.getValue(field.name) || ""}
					error={
						error
							? {
									pointing: "left",
									content: error,
							  }
							: null
					}
					onChange={(e, { value }) => this.updateFormData(field, value)}
				/>
			)
		}
	}

	renderPhone(field: FormFieldProperties) {
		const error = this.errorForKey(field.name)
		printMessage("DisplayMode: " + this.props.displayMode, messageLevels.debug)
		if (this.props.displayMode) {
			return this.renderDisplay(field)
		} else {
			return (
				<Form.Input
					className={this.classesForField(field)}
					type="tel"
					label={field.label}
					autoComplete="no"
					value={this.props.item?.getValue(field.name) || ""}
					error={
						error
							? {
									pointing: "left",
									content: error,
							  }
							: null
					}
					onChange={(e, { value }) => this.updateFormData(field, value)}
				/>
			)
		}
	}

	renderDisplay(field: FormFieldProperties) {
		return (
			<div className="form-item-display">
				<div>{field.label}: </div>
				<div>{this.props.item?.getValue(field.name)}</div>
			</div>
		)
	}

	renderPoint(field: FormFieldProperties, index: any) {
		const error = this.errorForKey(field.name)
		const theItem = this.props.item
		return (
			<div className={this.classesForField(field)}>
				<PinPlaceGeoJSONPoint
					edit={field.allowEdit}
					pinColor={theItem?.pinColour}
					initialLocation={theItem?.getValue(field.name)}
					error={
						error
							? {
									pointing: "left",
									content: error,
							  }
							: null
					}
					setValue={(value: any) => this.updateFormData(field, value)}
				></PinPlaceGeoJSONPoint>
			</div>
		)
	}

	renderText(field: FormFieldProperties) {
		const error = this.errorForKey(field.name)
		return (
			<div className={this.classesForField(field)}>
				<Form.TextArea
					label={field.label}
					autoComplete="no"
					value={this.props.item?.getValue(field.name)}
					error={
						error
							? {
									pointing: "left",
									content: error,
							  }
							: null
					}
					onChange={(e, { value }) => this.updateFormData(field, value)}
					// onBlur={() => dispatch(NotifyFormStoreHandlers.setFormData({activityNotes: activityNotes}))}
				/>
			</div>
		)
	}
	renderDisplayText(field: FormFieldProperties, index: any) {
		const item = this.props.item
		return <DisplayTextComponent item={this.props.item} container={this.props.container} field={field} index={index} />
	}
	renderDisplayInput(field: FormFieldProperties, index: any) {
		const item = this.props.item
		return (
			<div className={this.classesForField(field)}>
				<DisplayInputComponent item={this.props.item} field={field} index={index} container={this.props.container} />
			</div>
		)
	}

	renderCheckbox(field: FormFieldProperties) {
		const error = this.errorForKey(field.name)
		return (
			<Form.Checkbox
				key={field.name}
				label={field.label}
				checked={this.getChecked()}
				onChange={(e, data) => this.setChecked(data.checked)}
				error={
					error
						? {
								pointing: "left",
								content: error,
						  }
						: null
				}
			/>
		)
	}
	renderCheckboxDateRange(field: FormFieldProperties, index: number) {
		const error = this.errorForKey(field.name)
		return (
			<div className={this.classesForField(field)}>
				<CheckboxDateRangeItem
					item={this.props.item}
					container={this.props.container}
					field={field}
					updateFormData={this.updateFormData}
					index={index}
					setValue={this.setValue}
					error={error}
				></CheckboxDateRangeItem>
			</div>
		)
	}

	renderUpload(field: FormFieldProperties, index: number, allowNonImageType: boolean) {
		return (
			// <ImageUploadComponent  item={this.props.item} field={field}></ImageUploadComponent>
			<ImageUploadFormComponent
				setDirty={this.setDirty}
				item={this.props.item}
				field={field}
				allowNonImageType={!!allowNonImageType}
				style={field.style}
			></ImageUploadFormComponent>
		)
	}

	renderSeparator() {
		return <Divider />
	}
	renderHeading(field: FormFieldProperties) {
		let theValue = this.props.item?.getValue(field.name)
		if (field.customGetHeader && this.props.item) {
			theValue = field.customGetHeader(this.props.item, field)
		}
		return (
			<Header as="h3" style={field.style} inverted>
				{field.label}
				{theValue && theValue}
				<Header.Subheader>{field.description}</Header.Subheader>
			</Header>
		)
	}
	renderOptions(field: FormFieldProperties) {
		if (field.label && field.label.length > 0) {
			return (
				<div className={this.classesForField(field)} style={{ display: "flex", justifyContent: "space-between" }}>
					<text style={{ marginTop: 1 }}>{field.label}: </text>
					{field.choices?.map((element, index) => this.renderOption(field, index, element))}
				</div>
			)
		} else {
			return (
				<div className={this.classesForField(field)} style={{ display: "flex", justifyContent: "space-between" }}>
					{field.choices?.map((element, index) => this.renderOption(field, index, element))}
				</div>
			)
		}
	}
	renderOption(field: FormFieldProperties, index: number, choice: string) {
		return (
			<Radio
				key={field.name + choice}
				label={choice}
				checked={this.props.item?.getValue(field.name) === choice}
				onClick={() => this.updateFormData(field, choice)}
				className="blue-radio"
			/>
		)
	}

	renderLocationPicker(field: FormFieldProperties) {
		const theLocation = this.props.item?.getValue(field.name)
		const theClasses = this.classesForField(field)
		const error = this.errorForKey(field.name)
		return (
			<div className={theClasses}>
				<>
					{this.renderHeading}
					<PinPlaceGeoJSONPoint
						edit={field.allowEdit || true}
						pinColor={this.state.isGeoSet ? this.props.item?.activePinColour : this.props.item?.pinColour}
						initialLocation={theLocation}
						error={
							error
								? {
										pointing: "left",
										content: error,
								  }
								: null
						}
						setValue={(value: any) => {
							this.setState({ isGeoSet: true })
							this.updateFormData(field, value)
						}}
						popupMessage={"<H4>Drag marker to set location</H4>"}
					></PinPlaceGeoJSONPoint>
				</>
			</div>
		)
	}
	classesForField(field: FormFieldProperties) {
		let classes = "field"
		if (field.mandatory) {
			classes += " required"
		}
		if (this.props.item && this.props.item.validationErrorForKey(field.name) !== null) {
			classes += " error"
		}
		return classes
	}
}
const onClickSelectCoordinateButton = () => {
	// dispatch(NotifyFormStoreHandlers.startSelectingCoordinate());
	// hideSidebar();
}

export function colorForItemKey(item: storedItem, key: string) {
	return item.validationForKey(key) ? undefined : "red"
}
