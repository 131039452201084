import React, { Component } from "react"
import { Button, Grid, GridColumn, GridRow, Icon, Ref, Segment, Sidebar } from "semantic-ui-react"

import { isStoredItem, itemByKey, removeStateChanges, requestLoad, save, setGlobalState } from ".."

import "./style.css"
import { storedItem } from "../storedItem"
import { printData, printDataJSON, printMessage } from "../../providers/remoteHQ"
import { messageLevels } from "../types"
import { blockFeature } from "../classes/blockFeature"
import { MultipageForm } from "./MultiPageForm"
import { FormPageItemProps, ItemContextProps } from "."

export interface ItemContextState {
	selectedItem?: storedItem
	editingItem?: storedItem | null
	addItem?: storedItem | null
	defaultActiveView: number
	isMinimized: boolean
	level: string
	pages: FormPageItemProps[]
	inEditMode: boolean
	addItemMode: boolean
	isDirty: boolean
	isValid: boolean
	isConfirming: boolean
	approvalStates: any[]
}

export default class ItemContext extends React.Component<ItemContextProps, ItemContextState> {
	state: ItemContextState = {
		selectedItem: undefined,
		editingItem: undefined,
		defaultActiveView: 0,
		level: "country",
		isMinimized: false,
		inEditMode: false,
		addItemMode: false,
		isDirty: false,
		isValid: true,
		isConfirming: false,
		approvalStates: [],
		pages: [],
	}

	get target(): any {
		return this.props.target
	}

	componentDidMount() {
		printMessage("did mount", messageLevels.verbose)
		requestLoad("forms", this.formsLoaded, this.formsLoaded)
		this.getForm()
		// this.setState({ inEditMode: false });
	}

	getForm() {
		printDataJSON(this.props.formID, messageLevels.verbose, "this.props.formID")
		if (this.props.formID) {
			const theItem = itemByKey("forms", this.props.formID)
			if (theItem) {
				this.setState({ pages: theItem.getValue("definition") })
			}
		}
	}
	formsLoaded = (type: string, key: any, data: storedItem) => {
		printData(type, messageLevels.verbose, "type")
		if (type === "forms") {
			// printData(key, messageLevels.verbose, "key");
			// printData(data, messageLevels.verbose, "data");
			if (key === this.props.formID) {
				printData(data, messageLevels.verbose, "Form definition data loaded")
				// printDataJSON(data.getValue("definition"), messageLevels.verbose);
				this.setState({ pages: data.getValue("definition") })
			}
		}
	}

	get pages(): FormPageItemProps[] {
		return this.props.pages || this.state.pages
	}

	componentWillUnount() {
		removeStateChanges(this.stateChanged)
		// let oldType: storeType | undefined | null = this.state.selectedItem?.getType();
		// if (oldType) {
		// 	oldType.removeOnChange(this.dataChanged);
		// }
	}

	// This loads a list of available descriptions for the layer:
	loadCallback = (type: string, key?: string, data?: any) => {
		let arr: any[] = []
		Array.isArray(data) &&
			data.map(
				(i) =>
					(arr = [
						...arr,
						{
							key: i.id,
							text: i.description,
							value: i.description,
						},
					]),
			)
		this.setState({ approvalStates: arr })
	}

	stateChanged = (type: string, key: string, data: any) => {
		// if (key === "selected") {
		// 	this.endEditMode();
		// 	const newlySelected = data.getValue();
		// 	let newlySelectedItem: storedItem;
		// 	let oldType: storeType | undefined | null = this.state.selectedItem?.getType();
		// 	if (Array.isArray(newlySelected) && newlySelected.length > 0) {
		// 		newlySelectedItem = newlySelected[0];
		// 		if (newlySelectedItem && isStoredItem(newlySelectedItem)) {
		// 			const type = newlySelectedItem.getType();
		// 			if (type !== oldType) {
		// 				if (type) {
		// 					type.registerOnChange(this.dataChanged);
		// 				}
		// 				if (oldType) {
		// 					oldType.removeOnChange(this.dataChanged);
		// 				}
		// 			}
		// 			this.setState({ selectedItem: newlySelectedItem });
		// 		}
		// 	} else {
		// 		this.setState({ selectedItem: undefined });
		// 	}
		// }
		// if (key === "addItem") {
		// 	const addItems = data.getValue();
		// 	let addItem;
		// 	if (Array.isArray(addItems) && addItems.length) {
		// 		addItem = addItems[0];
		// 		this.setState({ addItem: addItem, addItemMode: true });
		// 	} else {
		// 		this.setState({ addItem: undefined, addItemMode: false });
		// 	}
		// }
	}

	// dataChanged = (type: string, key: any, data: storedItem) => {

	// 	const currentSelected = this.state.selectedItem;
	// 	if (currentSelected) {
	// 		printMessage("selected Data changed in info-layer", messageLevels.debug)
	// 		if (type === currentSelected.getTypeName() && key === currentSelected.primaryKey()) {
	// 			// this.setState({ changed : data });
	// 		}
	// 	}
	// 	const currenEdit = this.state.editingItem;
	// 	if (currenEdit) {
	// 		printMessage("currenEdit Data changed in info-layer", messageLevels.debug)
	// 		if (type === currenEdit.getTypeName() && key === currenEdit.primaryKey()) {
	// 			// this.setState({ editingItem: data });
	// 		}
	// 	}
	// }

	gotURLCallback = (theUrl: string) => {
		const theItem = this.getEditingItem() as blockFeature
		theItem.addImageURL(theUrl)
	}

	getItemType() {
		return this.state.selectedItem?.levelName() || null
	}

	getSelectedItem() {
		const { selectedItem, editingItem, inEditMode, addItemMode, addItem } = this.state
		if (addItemMode) {
			return addItem
		}
		return inEditMode ? editingItem : selectedItem
	}
	getEditingItem() {
		const { editingItem, addItemMode, addItem } = this.state
		if (addItemMode) {
			return addItem
		}
		return editingItem
	}

	// back = () => {
	// 	const selectedItem = this.getSelectedItem();
	// 	const parent: storedItem | null | undefined = this.state.inEditMode ? null : selectedItem?.getParent();
	// 	call("setLevelByItem", parent)
	// 	this.scrollToTop()
	// }

	// scrollToTop = () => this.sidebar.current?.scroll({top: 0})

	changeDefaultActiveView = (defaultActiveView: number) => {
		this.setState({ defaultActiveView })
	}

	renderForm = () => {
		const { inEditMode, addItemMode } = this.state
		const item = this.props.item

		const theType = item?.levelName() || null

		const editMode = inEditMode || addItemMode
		printData(editMode, messageLevels.debug, "editMode in renderInfoLayer")
		printData(item, messageLevels.debug, "item in renderInfoLayer")

		// const theItem = this.getSelectedItem();

		if (!theType) {
			return null
		}
		return (
			<>
				<MultipageForm
					pages={this.props.pages || []}
					container={this.props.container}
					displayMode={this.props.displayMode}
					item={this.props.item}
					title={this.props.title}
					setDirty={this.setDirty}
				></MultipageForm>
			</>
		)
		// return <ItemView inEdit={editMode} editingItem={theEditItem} selectedItem={theItem} setValue={this.setValue} setDirty={this.setDirty} />;
	}

	// renderBackButton = () => {
	// 	if (this.state.addItemMode) {
	// 		return null;
	// 	}
	// 	const selectedItem = this.getSelectedItem();
	// 	if (selectedItem && isStoredItem(selectedItem)) {
	// 		const parent: storedItem | null | undefined = this.state.inEditMode ? null : selectedItem?.getParent();
	// 		if (!parent) return null;
	// 		if (parent === selectedItem) return null;
	// 		return (
	// 			<Button className="back-btn" onClick={this.back}>
	// 				<Icon name="chevron left" />
	// 				{parent.displayName()}
	// 			</Button>
	// 		);
	// 	}
	// 	return null;
	// };

	renderEditButton = () => {
		const selectedItem = this.getSelectedItem()

		if (selectedItem && isStoredItem(selectedItem) && !this.state.inEditMode && !this.state.addItemMode) {
			const theItem = selectedItem as storedItem
			const isEditable = theItem.checkUpdateAuthority(undefined, false)
			printData(isEditable, messageLevels.debug, "isEditable")
			if (isEditable) {
				return (
					<Button className="edit-btn" onClick={this.setEditMode}>
						Edit
					</Button>
				)
			}
		} else if (selectedItem && isStoredItem(selectedItem) && (this.state.inEditMode || this.state.addItemMode)) {
			// return (
			// 	<Button className="edit-btn" onClick={this.saveItem}>
			// 		Done
			// 	</Button>
			// );
			return null
		}
		return null
	}

	saveItem = async () => {
		const theItem: storedItem | null | undefined = this.props.item
		printData(theItem, messageLevels.debug, "theItem im saveItem")
		if (theItem) {
			printMessage("about to keep changes", messageLevels.verbose)

			const theOriginal = theItem.keepChanges(this.keepItemCallback)
			printData(theOriginal, messageLevels.verbose, "storedItem in saveItem")
			// this.keepItemCallback(theOriginal);
			// this.setState({selectedItem: theItem})
		}
	}

	keepItemCallback = (theItem: storedItem) => {
		printData(theItem, messageLevels.verbose, "theItem im keepItemCallback")

		save()
		if (this.state.addItem) {
			this.setState({
				isDirty: false,
				selectedItem: theItem,
				editingItem: null,
				addItem: null,
			})
		} else {
			this.setState({
				isDirty: false,
				editingItem: null,
				addItem: null,
			})
		}

		this.endEditMode()
		printData(this.state, messageLevels.debug, "state in infoLayer")
		// save();
		if (!this.state.addItemMode) {
			printData(theItem, messageLevels.debug, "selecting Item:")
			theItem.select()
		} else {
			printData(theItem, messageLevels.debug, "theItem after edit mode when added")
		}
		if (this.props.onSubmit) {
			this.props.onSubmit()
		}
	}

	handleDelete = () => {
		printMessage("Handle Deleted", messageLevels.debug)
		const theItem: storedItem | null | undefined = this.getEditingItem()
		if (theItem) {
			theItem.delete()
			theItem.keepChanges()
			save()
		}
		// const theParent = theItem?.getParent();

		this.setState({
			isDirty: false,
			isValid: false,
		})
		this.endEditMode()
	}

	setValue = (value: any, forKey: string) => {
		const theItem: storedItem | null | undefined = this.props.item
		if (theItem && theItem !== undefined) {
			printMessage("setting value: " + value, messageLevels.debug)
			theItem.setValue(value, forKey)
			theItem.validationForKey(forKey)
			this.setDirty()
			// this.setState({isDirty: theItem.isDirty(), });
		}
	}
	setDirty: () => void | Promise<void> = async () => {
		const theItem: storedItem | null | undefined = this.props.item
		printData(theItem, messageLevels.debug, "item in setDirty")
		if (theItem && theItem !== undefined) {
			const isValid = await theItem.validForSave()
			this.setState({ isDirty: theItem.isDirty(), isValid })
			// if (theItem.isDirty()) {
			// 	setGlobalState("edit-infoLayer", [theItem]);
			// }
		}
	}

	setEditMode = () => {
		const theEditItem = this.state.selectedItem?.editCopy()

		this.setState({
			inEditMode: true,
			editingItem: theEditItem,
		})
	}
	doCancel = () => {
		this.endEditMode()
		if (this.props.onCancel) {
			this.props.onCancel()
		}
	}
	endEditMode = () => {
		if (this.state.inEditMode || this.state.addItemMode) {
			this.setState({
				inEditMode: false,
				addItemMode: false,
				editingItem: null,
				addItem: null,
				isDirty: false,
				isValid: false,
			})
		}
		// this.scrollToTop()
	}

	renderChangeButtons() {
		// let canSave = false;
		let canDelete = false
		// let saveEnable = false;
		if (this.props.displayMode) {
			return null
		}
		if (this.state.addItemMode) {
			canDelete = false
		} else {
			canDelete = true
			canDelete = this.getEditingItem()?.checkDeleteAuthority() || false
		}
		return (
			<Grid columns={2}>
				<GridRow>
					<GridColumn textAlign="center">{this.renderSaveButton()}</GridColumn>
					<GridColumn textAlign="center">{this.renderCancelButton()}</GridColumn>
				</GridRow>
				<GridRow>
					<GridColumn width={2} textAlign="center">
						{canDelete && this.renderDeleteButton()}
					</GridColumn>
				</GridRow>
			</Grid>
		)
	}
	renderSaveButton() {
		const isDirty = this.state.isDirty
		const isValid = this.state.isValid
		const canSave = isDirty && isValid
		return (
			<Button
				color={!canSave ? "grey" : "green"}
				disabled={!canSave}
				icon={"save"}
				onClick={this.saveItem}
				content="Submit"
				style={{ width: "48%" }}
			/>
		)
	}
	renderCancelButton() {
		const isDirty = this.state.isDirty
		return (
			<Button color={isDirty ? "black" : "grey"} icon={"cancel"} onClick={this.doCancel} content="Cancel" style={{ width: "48%" }} />
		)
	}

	renderDeleteButton() {
		return (
			<Button color={"red"} icon={"delete"} onClick={this.handleDelete} content="Delete" style={{ width: "98%", marginTop: "4px" }} />
		)
	}
	renderSubmitModal() {
		return null
		// return (
		//    <SubmitFormModal disabled={!this.state.isConfirming} ></SubmitFormModal>
		// )
	}

	render() {
		const { isMinimized } = this.state
		const { minimize } = this.props
		const minimized = isMinimized || minimize
		if (!this.props.pages) {
			return null
		}
		return (
			<>
				<div style={{ height: "52" }}>
					<Grid verticalAlign={"top"}>
						<GridRow columns={2}>
							<GridColumn width={10} textAlign={"left"}>
								{/* {this.renderBackButton()} */}
							</GridColumn>
							<GridColumn width={2} textAlign={"right"}>
								{this.renderEditButton()}
							</GridColumn>
						</GridRow>
					</Grid>
				</div>
				<div style={{ position: "relative" }}>
					{this.renderForm()}
					{this.renderChangeButtons()}
					{this.renderSubmitModal()}
				</div>
			</>
		)
	}
}
