import React, { useState } from "react"
import { Image, Modal, Placeholder } from "semantic-ui-react"
import { LazyLoadImage } from "react-lazy-load-image-component"

const PlaceholderImage = () => {
	return (
		<Placeholder inverted style={{ height: 200, width: 300, position: "absolute" }}>
			<Placeholder.Image rectangular />
		</Placeholder>
	)
}

function ModalExampleContentImage(props: any) {
	const [open, setOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [isError, setIsError] = useState(false)
	const className = props.childClass ? props.childClass : "all-img-popup"
	return (
		<Modal
			onClose={() => setOpen(false)}
			onOpen={() => !isError && setOpen(true)}
			open={open}
			key={props.src}
			closeOnDimmerClick
			trigger={
				<div style={{ minHeight: 210, position: "relative" }}>
					{isLoading ? <PlaceholderImage /> : null}
					<LazyLoadImage
						afterLoad={() => setIsLoading(false)}
						className={className}
						alt={props.alt}
						src={props.thumb || props.src}
						style={{
							objectFit: "contain",
							visibility: isLoading ? "hidden" : "visible",
						}}
						onError={() => {
							setIsLoading(false)
							setIsError(true)
						}}
					/>
					{isError ? (
						<div style={{ position: "absolute", top: 7, left: 25, fontStyle: "italic" }}>Image link may be broken.</div>
					) : null}
				</div>
			}
		>
			<Image size="massive" src={props.src} />
		</Modal>
	)
}

export default ModalExampleContentImage
