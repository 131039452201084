import { Dimmer, Loader } from "semantic-ui-react"

const LoadingPage = (): JSX.Element => {
	return (
		<div
			style={{
				height: "100vh",
				width: "100vw",
				backgroundColor: "black",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Dimmer active inverted>
				<Loader size="large">Loading Map Data</Loader>
			</Dimmer>
		</div>
	)
}

export default LoadingPage
