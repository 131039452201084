import { mapLayer } from "./mapLayer"
import { storeType } from "../storeType"
import { getStoreTypeByName, putTablesIntoSchemaItems, registerforStateChanges } from "../"
import { printData, printMessage } from "../../providers/remoteHQ"
import { mapMultiLayer } from "./mapMultiLayer"
import { messageLevels } from "../types"

export class mapSelectedLayer extends mapMultiLayer {
	_overItems: any[] = []

	constructor(type: storeType, data?: any, doNotStore?: boolean) {
		super(type, data, doNotStore)
		// printMessage("Constructor in "+ this.getValue("id"),messageLevels.verbose);
	}
	addToMap = () => {
		this.addToMapLocal()
		this.registerForChanges()
		this.setLayerFilters(blankFilter)
	}

	registerForChanges() {
		// printMessage("Registering for changes in " + this.getValue("id"), messageLevels.verbose);
		registerforStateChanges(this.stateChanged)
	}

	stateChanged = async (type: string, key: string, data: any) => {
		// printMessage("stateChanged in "+ this.getValue("id"),messageLevels.verbose);
		if (key === "selected") {
			const theItemIDs: any[] = []
			const theItems = data.getValue()
			let theFilter = blankFilter
			// const featureProperty = this.getValue("mapFeatureKey").toString();
			const featureProperty = this.getValue("mapFeatureKey")
			if (theItems) {
				// printData(theItems,messageLevels.verbose);

				if (Array.isArray(theItems)) {
					const objectType = this.getValue("objectType")

					if (objectType && featureProperty) {
						theItems.forEach((item) => {
							if (item.getTypeName() === objectType) {
								theItemIDs.push(item.getValue(featureProperty))
							}
						})
						// printMessage("hovers in "+ this.getValue("id"),messageLevels.verbose);
						// printData(theItemIDs,messageLevels.verbose);
					}
				}
			}

			if (theItemIDs.length) {
				theFilter = [["match", ["get", featureProperty], theItemIDs, true, false]]
			}
			if (theItemIDs.length === 0 && this._overItems?.length !== 0) {
				this.setLayerFilters(theFilter)
			} else if (theItemIDs.length > 0) {
				this.setLayerFilters(theFilter)
			}

			this._overItems = theItemIDs
		} else if (this._watchedGlobalStateItems.includes(key)) {
			this.setLevelFilters()
		}
	}
	setLayerFilters(filters: any) {
		// printData(filters, messageLevels.verbose);
		const theFill = this.fillLayer
		if (theFill) {
			theFill.setAdditionalFilter(filters)
		}
		const theLine = this.lineLayer
		if (theLine) {
			theLine.setAdditionalFilter(filters)
		}
	}
}
const blankFilter: any[] = [["==", "1", "0"]]
