import React from "react"
import { Divider, Header, Icon, Image, Segment } from "semantic-ui-react"

import { storedItem } from "../storedItem"
import { blockFeature } from "../classes/blockFeature"
import PinPlaceGeoJSONPoint from "../../mapsight/PinGeoJSONPoint"

interface QuarryFeatureInfoProps {
	selectedItem?: storedItem | null
	inEdit?: boolean
}

export interface QuarryFeatureInfoState {}

export default class QuarryInfoView extends React.PureComponent<QuarryFeatureInfoProps, QuarryFeatureInfoState> {
	state = {}

	render() {
		const { selectedItem } = this.props

		if (!selectedItem) {
			return <></>
		}
		const imageLinks = (selectedItem as blockFeature).heroImageURL
		const pinColour = (selectedItem as blockFeature).pinColour
		const theLocation = selectedItem?.getValue("geojson")
		return (
			<div>
				<Segment inverted basic>
					<Header as="h2" inverted>
						{selectedItem.friendlyDisplayName}
					</Header>
					<Divider />
					{imageLinks ? (
						// Todo: this needs to be more elegant
						<Image src={imageLinks} size="huge" centered />
					) : (
						<div>
							<Icon name="image" size="large" />
							No image available
						</div>
					)}
				</Segment>
				<Segment inverted basic>
					<Header as="h4" inverted>
						Description
					</Header>
					<Divider />
					{selectedItem.getValue("description") ? selectedItem.getValue("description") : "No description available"}
				</Segment>
				<Segment inverted basic>
					<Header as="h4" inverted>
						Location
					</Header>
					<PinPlaceGeoJSONPoint edit={false} pinColor={pinColour} initialLocation={theLocation}></PinPlaceGeoJSONPoint>
				</Segment>
			</div>
		)
	}
}
