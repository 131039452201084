import { printData, printMessage } from "../providers/remoteHQ"
import { getConfig, itemByKey, registerOnChange, requestLoad, setGlobalState } from "../store"
import { appMode } from "../store/classes/Mode"
import { storedItem } from "../store/storedItem"
import { messageLevels } from "../store/types"

class ModeManager {
	private static instance: ModeManager
	currentModeName: string
	currentMode: appMode | null

	private constructor() {
		this.currentModeName = ""
		this.currentMode = null
		printMessage("Mode manager constructor", messageLevels.verbose)
		registerOnChange("user-profile", this.userChanged)
		requestLoad("modes")
	}

	public static getInstance(): ModeManager {
		if (!ModeManager.instance) {
			ModeManager.instance = new ModeManager()
		}

		return ModeManager.instance
	}

	userChanged = (type: string, key: any, value: storedItem) => {
		printData(value, messageLevels.verbose, "User value on user changed")
		if (value && value !== undefined) {
			let setModeErrorCount = 0
			const setMode = () => {
				const theModeName =
					setModeErrorCount >= 3 ? getConfig("fallbackMode") : (value as any)["default_mode"] || getConfig("fallbackMode")
				if (theModeName !== this.currentModeName) {
					const theMode = itemByKey("modes", theModeName) as appMode
					if (theMode) {
						this.currentMode?.disableMode()
						this.currentMode = theMode
						this.currentModeName = theModeName
						setGlobalState("mode", [theMode])
						setGlobalState("mode-name", [theModeName])
						theMode.setupMode()
					} else {
						// TODO: write a proper way to wait until theMode is not undefined
						setModeErrorCount += 1
						setModeErrorCount <= 3 && setTimeout(setMode, 500)
					}
				}
			}
			setMode()
		}
	}
}

export const modeManager: ModeManager = ModeManager.getInstance()
