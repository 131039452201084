import React, { Fragment, useEffect } from "react"
import { Accordion, AccordionTitleProps, Button, Icon, Message, Popup, Progress } from "semantic-ui-react"
import { a } from "aws-amplify"

import {
	completedMessages,
	failedMessages,
	flushStatusMessages,
	processPercentComplete,
	registerOnProcessChange,
	removeOnProcessChange,
	toSendMessages,
} from "../../store"
import { statusMessage } from "../../store/ProcessStatusManager"

interface MessageTypes {
	title: string
	messages: statusMessage[]
	warning?: boolean
	positive?: boolean
	negative?: boolean
}

const StatusPopup = () => {
	const [messages, setMessages] = React.useState({
		toSendMessages: toSendMessages(),
		completedMessages: completedMessages(),
		failedMessages: failedMessages(),
	})

	const [activeIndexList, setActiveIndexList] = React.useState(new Set([0, 1, 2]))

	useEffect(() => {
		registerOnProcessChange(onProcessChange.bind(this))
		return removeOnProcessChange(onProcessChange.bind(this))
	}, [])

	const onProcessChange = () => {
		setMessages({
			toSendMessages: toSendMessages(),
			completedMessages: completedMessages(),
			failedMessages: failedMessages(),
		})
	}

	const onChangeAccordion = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, { index }: AccordionTitleProps) => {
		const i = Number(index)
		if (activeIndexList.has(i)) {
			setActiveIndexList((prev) => {
				const newSet = new Set(prev)
				newSet.delete(i)
				return newSet
			})
		} else {
			setActiveIndexList((prev) => new Set(prev).add(i))
		}
	}

	const getMessages = (): MessageTypes[] => {
		return [
			{
				title: "In Progress",
				messages: messages.toSendMessages,
				warning: true,
			},
			{
				title: "Completed",
				messages: messages.completedMessages,
				positive: true,
			},
			{
				title: "Failed",
				messages: messages.failedMessages,
				negative: true,
			},
		]
	}

	const renderAccordions = () => getMessages().map(renderAccordion)

	const renderAccordion = (messageType: MessageTypes, i: number) => {
		const { title, messages, ...other } = messageType
		return (
			<Fragment key={`message_accordion_${i}`}>
				<Accordion.Title active={activeIndexList.has(i)} index={i} onClick={onChangeAccordion}>
					<Icon name="dropdown" />
					{messageType.title}
				</Accordion.Title>
				<Accordion.Content active={activeIndexList.has(i)}>
					{messages.map((message, j) => {
						return (
							<Message key={`message_${i}_${j}`} size="mini" {...other}>
								{message.message}
							</Message>
						)
					})}
					{messages.length === 0 ? <div style={{ marginLeft: 20, opacity: 0.5 }}>None</div> : null}
				</Accordion.Content>
			</Fragment>
		)
	}

	return (
		<div id="status-popup">
			<Popup
				on={"click"}
				// Leaving this here for now in case we want to change the trigger UI to respond to the status and overall health of the fetches in progress.
				// trigger={
				// 	messages.toSendMessages.length > 0 ? (
				// 		<Progress size="tiny" style={{ height: "0.5rem" }} autoSuccess inverted active></Progress>
				// 	) : (
				// 		<Icon name="check circle outline" size="small" />
				// 	)
				// }
				trigger={
					<div style={{ cursor: "pointer" }}>
						<Icon name="info circle" size="large" />
					</div>
				}
				inverted
				size="mini"
				position="top right"
				style={{ height: "50vh", width: "30vw", overflow: "scroll" }}
			>
				<Popup.Content>
					<Icon className="status-popup-trash" name="trash alternate" inverted onClick={flushStatusMessages} />
					<Accordion inverted>{renderAccordions()}</Accordion>
				</Popup.Content>
			</Popup>
		</div>
	)
}

export { StatusPopup }
