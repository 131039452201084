import moment from "moment"

import { checkLoadedAuthority, printData, printMessage } from "../../providers/remoteHQ"
import { call, getPropertyArrayFromItemArray, getPropertyArrayFromItemArrayUnique } from "../../store"
import { storedItem } from "../../store/storedItem"
import { actions, messageLevels, objects, sortDirection, sortMethod } from "../types"
import { IComplianceState, IIndexable, IPriority } from "../property-types"
import { supportingDocumentURL } from "../URLRewrites"
import { blockFeature } from "./blockFeature"

export class forestBlock extends storedItem {
	levelName = () => {
		return this.getTypeName()
	}

	// getBoundingBox  ()  {
	// 	const theBoundingBox = this.getValue("mapbox_bounding_box");
	// 	if (theBoundingBox) {
	// 		return this.getValue("mapbox_bounding_box");
	// 	}
	// 	const parent = this.getParent();
	// 	if (parent) {
	// 		return parent.getBoundingBox();
	// 	}
	// 	return null;
	// };

	displayName() {
		return this.getValue("forest_name")
	}

	managerDisplayName = () => {
		const canShow = checkLoadedAuthority(actions.retrieve, objects.forestManager, this.primaryKey(), this.getRegionISO())
		if (canShow === true) {
			return this.getValue("Manager")
		}
		return "Available on request to Council"
	}
	managementCompanyDisplayName = () => {
		// const canShow = checkLoadedAuthority(actions.retrieve, objects.forestOwernship,this.primaryKey(),this.getRegionISO());
		// if(canShow) {
		return this.getValue("management_company")
		// }
		// return "Available on request to Council";
	}
	age = (): string => {
		const end_date = this.getValue("end_date")
		let age
		if (end_date) {
			age = Number(moment().diff(end_date, "years").toFixed())
			if (age < 0) age = 0
		} else {
			age = -1
		}
		return age.toString()
	}
	formattedConsentNum = (): string => {
		if (this.getRegionISO() === "NZ-MBH") {
			return "U" + this.getValue("consent_number")
		}

		return this.getValue("consent_number")
	}
	get forest_block_uuid() {
		return this.getValue("uuid")
	}
	getComplianceStatus = () => {
		return ComplianceOptions[(this as any).compliance_status] || ComplianceOptions.NA
	}
	getComplianceStatusCode = () => {
		return this.getComplianceStatus().KEY
	}
	getPriority = () => {
		return PriorityOptions[(this as any).priority] || PriorityOptions.NA
	}
	getPriorityCode = () => {
		return this.getPriority().KEY
	}

	getParent = () => {
		const theParent = this.getRelatedItems("boundaries", this.getRegionISO(), "iso")[0]
		printData(theParent, messageLevels.debug)
		return theParent
	}
	get forestblock() {
		return this
	}

	areasOfConcern = (): storedItem[] => {
		return this.getRelatedItems("areas-of-concern", this.getValue("consent_number"), "consent_number")
	}
	areasOfFailure = (): storedItem[] => {
		return this.getRelatedItems("areas-of-failure", this.getValue("consent_number"), "consent_number")
	}
	areasOfExcellence = (): blockFeature[] => {
		return this.getRelatedItems("areas-of-excellence", this.getValue("consent_number"), "consent_number") as blockFeature[]
	}
	get consentedSkidSites() {
		return this.getRelatedItems("consented-skid-sites", this.getValue("consent_number"), "consent_number", "==", false, {
			sortField: "local_id",
			sortDirection: sortDirection.ascending,
			sortMethod: sortMethod.numeric,
		})
	}
	get asBuiltSkidSites(): storedItem[] {
		return this.getRelatedItems("as-built-skid-sites", this.getValue("consent_number"), "consent_number", "==", false, {
			sortField: "local_id",
			sortDirection: sortDirection.ascending,
			sortMethod: sortMethod.numeric,
		})
	}
	get consentedRoads() {
		return this.getRelatedItems("consented-roads", this.getValue("consent_number"), "consent_number")
	}
	get asBuiltRoads(): storedItem[] {
		return this.getRelatedItems("as-built-roads", this.getValue("consent_number"), "consent_number")
	}
	get consentedTracks() {
		return this.getRelatedItems("consented-tracks", this.getValue("consent_number"), "consent_number")
	}
	get asBuiltTracks(): storedItem[] {
		return this.getRelatedItems("as-built-tracks", this.getValue("consent_number"), "consent_number")
	}
	get generalPanoramas(): storedItem[] {
		return this.getRelatedItems("general-panoramas", this.getValue("consent_number"), "consent_number")
	}
	get groundPanoramas(): storedItem[] {
		return this.getRelatedItems("ground-panoramas", this.getValue("consent_number"), "consent_number")
	}
	get aerialPanoramas(): storedItem[] {
		return this.getRelatedItems("aerial-panoramas", this.getValue("consent_number"), "consent_number")
	}
	get streams(): storedItem[] {
		return this.getRelatedItems("streams", this.getValue("consent_number"), "consent_number")
	}
	get consentedStreamCrossings(): storedItem[] {
		return this.getRelatedItems("consented-stream-crossings", this.getValue("consent_number"), "consent_number")
	}
	get asBuiltStreamCrossings(): storedItem[] {
		return this.getRelatedItems("as-built-stream-crossings", this.getValue("consent_number"), "consent_number")
	}
	get quarries(): storedItem[] {
		return this.getRelatedItems("quarries", this.getValue("consent_number"), "consent_number")
	}
	get tasks(): storedItem[] {
		return this.getRelatedItems("tasks", this.getValue("consent_number"), "consent_number", "==", false, {
			sortField: "local_id",
			sortDirection: sortDirection.ascending,
			sortMethod: sortMethod.numeric,
		})
	}
	get waterControls(): storedItem[] {
		return this.getRelatedItems("water-controls", this.getValue("consent_number"), "consent_number")
	}
	get reports(): storedItem[] {
		return this.getRelatedItems("forest-monitoring-reports", this.getValue("consent_number"), "consent_number", "==", false, {
			sortField: "created_date",
			sortDirection: sortDirection.ascending,
			sortMethod: sortMethod.date,
		})
	}
	get reportAttachments(): storedItem[] {
		return this.getRelatedItems("forest-monitoring-report-attachments", this.getValue("consent_number"), "consent_number")
	}
	get supportingDocumentURLs() {
		const theSupportingDocuments = this.getValue("supporting_document") || []

		const theURLs: URL[] = []

		theSupportingDocuments.forEach((item: string) => {
			const theURL = supportingDocumentURL(item)
			if (theURL) {
				theURLs.push(theURL)
			}
		})

		return theURLs
	}
	get hasDroneImagery(): boolean {
		const theVal = this.getValue("orthomosaic_filepath")

		if (theVal === null || theVal === undefined) {
			return false
		}
		if (typeof theVal !== "string") {
			return false
		}
		const hasImagery = theVal.length > 15

		return hasImagery
	}
	get hasElevationModel(): boolean {
		const theVal = this.getValue("digital_elevation_model_filepath")

		if (theVal === null || theVal === undefined) {
			return false
		}
		if (typeof theVal !== "string") {
			return false
		}
		const hasImagery = theVal.length > 15

		return hasImagery
	}

	get isActive(): boolean {
		const startDate = this.getValue("start_date")
		const endDate = this.getValue("end_date")
		if (startDate && endDate) {
			const theStartDate = new Date(startDate)
			const theEndDate = new Date(endDate)
			const now = new Date()
			return now >= theStartDate && now <= theEndDate
		}
		return false
	}

	reportData(column: string): string[] {
		return getPropertyArrayFromItemArray(this.reports, column)
	}
	reportDataUnique(column: string): string[] {
		return getPropertyArrayFromItemArrayUnique(this.reports, column)
	}

	highlightOnMap = (): void => {}
	clearHighlightOnMap = (): void => {}
	selectOnMap = (skipSetState?: boolean): void => {
		printMessage("forest block select on map", messageLevels.debug)
		call("setLevelByItem", this, skipSetState)
	}

	static styleByPriority = () => {}
	static styleByCompliance = () => {}
	//   getSortedBlockProperties().map((forestBlock, idx) => {
	//
	//     return (<Menu.Item link={true} key={`block_${forestBlock.consentNumber}_${idx}`}
	//         onMouseOver={() => highlightForstBlock(forestBlock)}
	//         onMouseLeave={() => highlightForstBlock()}
	//         onClick={() => selectForestBlock(forestBlock)}
	//     >
	//         {forestBlock.forestName} <small>({consentNumberFormatted(forestBlock)})</small>
	//         {
	//             forestBlock.complianceStatus && (<Label color={complianceStatus.color.name}>{complianceStatus.definition}</Label>)
	//         }
	get authorityItem() {
		return this.getRelatedItems("authorities", this.getRegionISO(), "getRegionISO")[0]
	}
}

const ComplianceOptions: IIndexable<IComplianceState> = {
	NA: {
		KEY: "NA",
		definition: "Not Assessed",
		legendOrder: 4,
		color: {
			name: "blue",
			code: "#0074FF",
		},
	},
	C: {
		KEY: "C",
		definition: "Compliant",
		legendOrder: 0,
		color: {
			name: "green",
			code: "#21ba45", //'#00ff00'
		},
	},
	TNC: {
		KEY: "TNC",
		definition: "Technically Non-Compliant",
		legendOrder: 1,
		color: {
			name: "yellow",
			code: "#ffff00",
		},
	},
	NC: {
		KEY: "NC",
		definition: "Non-Compliant",
		legendOrder: 2,
		color: {
			name: "orange",
			code: "#ff8000",
		},
	},
	SNC: {
		KEY: "SNC",
		definition: "Significantly Non-Compliant",
		legendOrder: 3,
		color: {
			name: "red",
			code: "#ff0000",
		},
	},
	RWR: {
		KEY: "RWR",
		definition: "Remedial Works Required",
		legendOrder: 4,
		color: {
			name: "purple",
			code: "#904EE3",
		},
	},
}
const PriorityOptions: IIndexable<IPriority> = {
	High: {
		KEY: "High",
		definition: "High",
		legendOrder: 0,
		color: {
			name: "red",
			code: "#ff0000",
		},
	},
	Medium: {
		KEY: "Medium",
		definition: "Medium",
		legendOrder: 1,
		color: {
			name: "yellow",
			code: "#ffff00",
		},
	},
	Low: {
		KEY: "Low",
		definition: "Low",
		legendOrder: 2,
		color: {
			name: "green",
			code: "#21ba45",
		},
	},
	NA: {
		KEY: "NA",
		definition: "None",
		legendOrder: 2,
		color: {
			name: "black",
			code: "#ffffff",
		},
	},
}
