import React from "react"
import { Button, Form, Segment, Sidebar, Table } from "semantic-ui-react"

import StoreListComponent from "../../../mapsight/StoreListComponent"
import { printData } from "../../../providers/remoteHQ"
import { dumpCompletedActions, dumpMapLayers, dumpMapSources, dumpToDo, getSchemaArrayAlpha, getStoreTypeByName, save } from "../../../store"
import { storedItem } from "../../../store/storedItem"
import { storeType } from "../../../store/storeType"
import { messageLevels } from "../../../store/types"

interface DataToolboxProps {
	visible: boolean
}

interface DataToolboxState {
	changed: boolean
	type: storeType | null
	isFullWidth: boolean
}
const itemCellFormatter = (item: storedItem, key: any) => {
	return (
		<>
			<Table.Row negative={item.getValue("_created")}>
				<Table.Cell> {key?.toString()}</Table.Cell>
				<Table.Cell> {item.getValue("_primaryKey")}</Table.Cell>
				<Table.Cell>{item.primaryKey()}</Table.Cell>
				<Table.Cell>{item.getValue()?.toString()}</Table.Cell>
				<Table.Cell>
					{item.getParent()?.getTypeName()} {item.getParent()?.primaryKey()}
				</Table.Cell>
				<Table.Cell> {item.getValue("_created") ? "+" : <>&nbsp;</>}</Table.Cell>
				<Table.Cell>{item.isDirty() ? "Dirty" : <>&nbsp;</>}</Table.Cell>
				<Table.Cell>{itemDeleteButton(item)}</Table.Cell>
				<Table.Cell>{itemLogButton(item)}</Table.Cell>
			</Table.Row>
		</>
	)
}
const itemDeleteButton = (item: storedItem) => {
	if (item._deleted) {
		return "deleted"
	}
	return (
		<Button size={"tiny"} value={item} onClick={(event, data) => deleteAction(event, data)}>
			Delete
		</Button>
	)
}
const itemLogButton = (item: storedItem) => {
	return (
		<Button size={"tiny"} value={item} onClick={(event, data) => logItem(event, data)}>
			Log
		</Button>
	)
}
const deleteAction = (event: any, data: any) => {
	if (data.value) {
		;(data.value as storedItem).delete()
	}
}
const logItem = (event: any, data: any) => {
	if (data.value) {
		printData(data.value, messageLevels.none)
	}
}

export default class DataToolbox extends React.Component<DataToolboxProps, DataToolboxState> {
	state = {
		changed: true,
		type: null as storeType | null,
		isFullWidth: true,
	}

	dataChanged = () => {
		this.setState({ changed: !this.state.changed })
	}

	setType = (typeName: any) => {
		if (typeof typeName === "string") {
			const theType = getStoreTypeByName(typeName)
			this.setState({ type: theType })
		} else {
			this.setState({ type: null })
		}
	}
	loadAction = () => {
		const theType = this.state.type
		if (theType) {
			theType.requestLoad()
		}
	}

	render() {
		const theSchema = getSchemaArrayAlpha()
		const theType = this.state.type

		const currentTypeName = theType !== null ? theType.name : ""
		printData(currentTypeName, messageLevels.debug)
		return (
			<Sidebar
				id="store-explorer"
				animation="overlay"
				icon="labeled"
				width="very wide"
				visible={this.props.visible}
				style={
					this.state.isFullWidth ? { width: "calc(100vw - 58px)", minWidth: 473, overflowX: "scroll" } : { overflowX: "scroll" }
				}
			>
				<Segment inverted={true} basic>
					<Button
						style={{
							position: "absolute",
							right: 0,
							top: 10,
							borderRadius: 0,
							padding: 8,
						}}
						onClick={() => this.setState({ isFullWidth: !this.state.isFullWidth })}
					>
						{this.state.isFullWidth ? "<< View Map" : "Expand >>"}
					</Button>
					<Button size={"tiny"} onClick={() => save()}>
						Save
					</Button>
					<Button size={"tiny"} onClick={() => dumpCompletedActions()}>
						Completed Actions
					</Button>
					<Button size={"tiny"} onClick={() => dumpToDo()}>
						To Do
					</Button>
					<Button size={"tiny"} onClick={() => dumpMapLayers()}>
						Mapbox Layers
					</Button>
					<Button size={"tiny"} onClick={() => dumpMapSources()}>
						Mapbox Sources
					</Button>
				</Segment>
				<Segment inverted={true} basic>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<Form.Select
							label="Data Type "
							placeholder="Select a data type"
							name="dataTypeSelect"
							options={Object.values(theSchema).map((value: any) => ({
								key: value.name,
								value: value.name,
								text: value.name,
							}))}
							onChange={(e, { value }) => this.setType(value)}
							value={currentTypeName}
						/>

						<Button size={"tiny"} onClick={() => this.loadAction()}>
							Request Load
						</Button>
					</div>
					<Table celled>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Key</Table.HeaderCell>
								<Table.HeaderCell>_primaryKey </Table.HeaderCell>
								<Table.HeaderCell>.primaryKey()</Table.HeaderCell>
								<Table.HeaderCell>.getValue()</Table.HeaderCell>
								<Table.HeaderCell>.getParent()</Table.HeaderCell>
								<Table.HeaderCell>_created</Table.HeaderCell>
								<Table.HeaderCell>Dirty</Table.HeaderCell>
								<Table.HeaderCell>Deleted</Table.HeaderCell>
								<Table.HeaderCell>Log</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							<StoreListComponent
								dataSourceName={currentTypeName}
								cellFormatter={itemCellFormatter}
								allowAllItems={true}
								header={currentTypeName}
								nowrap
							></StoreListComponent>
						</Table.Body>
					</Table>

					{/* <Dropdown text='Data Type'> */}
					{/* <Dropdown.Menu>
          { theSchema.map((value) => 
          <Dropdown.Item text={value.name} value={value.name} active={value.name === currentTypeName} onClick={() => this.setType(value)}/>) }        
          </Dropdown.Menu>
        </Dropdown> */}
					{/* {visible ? (
           <AnyArrayListComponent  data={theSchema} cellFormatter={this.storeTypeCellFormatter}></AnyArrayListComponent>
          ) : null} */}
				</Segment>
			</Sidebar>
		)
	}
}
