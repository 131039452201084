const getMarkerColor = (type: string) => {
	let markerColor = ""
	switch (type) {
		case "general-panoramas":
			markerColor = "yellow-icon"
			break
		case "aerial-panoramas":
			markerColor = "dark-blue-icon"
			break
		case "ground-panoramas":
			markerColor = "light-green-icon"
			break
		default:
			markerColor = "dark-purple-icon"
	}
	return markerColor
}
export default getMarkerColor
