import React from "react"
import { Form, Header, Segment, Label } from "semantic-ui-react"

import { storedItem } from "../storedItem"
import { blockFeature } from "../classes/blockFeature"

import { ImageListType } from "react-images-uploading"

import { isStoredItem, registerOnChange, removeOnChange, dateStripTimezone } from "../"

import { printData, printMessage } from "../../providers/remoteHQ"
import { messageLevels } from "../types"

import "react-datepicker/dist/react-datepicker.css"

import Status from "../components/StatusComponent"
import { newFileUploaderFromImageListItem } from "../FileHandling"
import { ItemViewProps, EditViewState, EditViewProps } from "./Types"

export class StoredItemEditView extends React.Component<EditViewProps, EditViewState> {
	state = {
		changeCount: 0,
		listItems: [],
		images: [],
		isDragging: false,
		typeName: "",
		currentPage: 0,
	}
	getTypeName(): string {
		if (this.props.editingItem) {
			return this.props.editingItem.getTypeName() || ""
		}
		return ""
	}
	maxNumber = 10
	setImages(imageArray: []) {
		this.setState({ images: imageArray })
	}
	componentDidMount() {
		this.updateTypeStatus()
	}
	componentDidUpdate(oldProps: EditViewProps) {
		if (oldProps.editingItem !== this.props.editingItem) {
			this.updateTypeStatus()
		}
	}

	componentWillUnount() {
		this.deregisterForDataForType(this.state.typeName)
	}
	errorForKey = (forKey: string): any => {
		const theItem: storedItem | null | undefined = this.props.editingItem
		if (theItem && theItem !== undefined) {
			return theItem.validationErrorForKey(forKey)
		}
		return null
	}

	setValue = (value: any, forKey: string) => {
		const theItem: storedItem | null | undefined = this.props.editingItem
		if (theItem && theItem !== undefined) {
			printMessage("setting value: " + value, messageLevels.debug)
			theItem.setValue(value, forKey)

			if (this.props.setDirty) {
				this.props.setDirty()
			}
		}
	}

	updateTypeStatus = () => {
		const newTypeName = this.getTypeName()
		const currentTypeName = this.state.typeName || ""
		if (currentTypeName !== newTypeName) {
			if (currentTypeName) {
				this.deregisterForDataForType(currentTypeName)
			}
			this.registerForDataForType(newTypeName)
			this.setState({ typeName: newTypeName })
		}
	}

	registerForDataForType(typeName: string) {
		printMessage("registering for: " + typeName, messageLevels.debug)
		registerOnChange(typeName, this.dataChanged)
	}

	deregisterForDataForType(typeName: string) {
		removeOnChange(typeName, this.dataChanged)
	}

	dataChanged = (type: string, key: any, data: storedItem) => {
		if (data === this.props.editingItem) {
			printMessage("Item data changed ", messageLevels.debug)
			this.setDirty()
		}
	}

	imageChange = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
		// const index = addUpdateIndex?.map((i) => i)[0];
		// This prohibits multiple of the same images being uploaded.
		// const isDuplicate = !!(
		// 	index && this.state.images.filter((i: any) => i.dataURL === imageList[index]["dataURL"]).length
		// );
		const isDuplicate = false
		printData(imageList, messageLevels.debug, "imageList in onChange")
		if (!isDuplicate) {
			// if (index !== undefined) {

			// const imageData = imageList.map((i) => {
			// 	return {
			// 		...i,
			// 		name: i.file?.name,
			// 		lastModified: i.file?.lastModified,
			// 		size: i.file?.size,
			// 		type: i.file?.type,
			// 	};
			// });
			imageList.forEach((image) => {
				const theUpload = newFileUploaderFromImageListItem({
					...image,
					name: image.file?.name,
					lastModified: image.file?.lastModified,
					size: image.file?.size,
					type: image.file?.type,
				})
				printData({ theUpload }, messageLevels.debug, "individual image")
				if (this.props.editingItem) {
					;(this.props.editingItem as blockFeature).addFileUpload(theUpload)
				}
			})
			this.setDirty()
		}
	}
	incrementChangeCount = () => {
		printData(this.state, messageLevels.debug, "state in StoredItem view")
		this.setState({ changeCount: this.state.changeCount + 1 })
	}
	setDirty = () => {
		if (this.props.setDirty) {
			this.props.setDirty()
		}
		this.incrementChangeCount()
	}
	removeImage = (theID: string): any => {
		printData(theID, messageLevels.debug, "removeImage storedItem View")
		const didDelete = (this.props.editingItem as blockFeature).removeImage(theID)
		if (didDelete) {
			printMessage("Did delete", messageLevels.debug)
			this.setDirty()
		}
	}

	// setValue = (value: any, forKey: string) => {
	// 	if(this.props.setValue) {
	// 		this.props.setValue(value, forKey);
	// 		this.setDirty();
	// 	}
	// };

	handleChange = (e: any) => {
		this.setValue(e.target.value, "description")
		this.setDirty()
	}

	handleDropdownChange = (e: any, d: any) => {
		this.setValue(d.value, "description")
		this.setDirty()
	}

	handleDateChange = (date: Date | null) => {
		const theDate = dateStripTimezone(date)
		this.setValue(theDate, "due_date")
		this.setDirty()
	}

	handleGeoChange = (geoJSON: any) => {
		printData(geoJSON, messageLevels.debug, "geojson in task")
		this.setValue(geoJSON, "geojson")
		this.setDirty()
	}

	clearEditingItem = () => {
		// call("clearEditingState");
	}
	theDueDate = () => {
		const theDate = this.props.editingItem?.getValue("due_date")
		if (typeof theDate === "string") {
			const dateObject = new Date(theDate)
			printData(dateObject, messageLevels.debug, "dateObject in theDueDate")
			return new Date(theDate)
		}

		return theDate
	}
	renderHeading() {
		if (this.props.hideHeading) {
			return null
		}
		const item = this.props.editingItem
		return (
			<Segment inverted basic>
				<Header as="h2" inverted>
					{item && item.displayName()}
				</Header>
			</Segment>
		)
	}
	render() {
		const item = this.props.editingItem

		if (!item || !isStoredItem(item)) {
			return null
		}

		return (
			<>
				<Form>{this.renderHeading()}</Form>
			</>
		)
	}
}

export class StoredItemInfoView<Props extends ItemViewProps> extends React.PureComponent<Props> {
	getTypeName(): string {
		if (this.props.selectedItem) {
			return this.props.selectedItem.getTypeName() || ""
		}
		return ""
	}
	renderNameHeader(asType?: string) {
		const headerAs = asType || "h2"
		const selectedItem = this.props.selectedItem
		return (
			<Header as={headerAs} inverted>
				{selectedItem?.displayName()}
			</Header>
		)
	}
	render() {
		const { selectedItem } = this.props

		if (!selectedItem || !isStoredItem(selectedItem)) {
			return <></>
		}

		// const imageLinks = (selectedItem as blockFeature).imageURLs;
		// const theLocation = selectedItem?.getValue("geojson");

		return (
			<>
				<Segment inverted basic className="basic-info">
					{this.renderNameHeader()}
					<Status item={selectedItem}></Status>
					{selectedItem?.getValue("status") ? (
						<Label horizontal className="compliance-status" color={"orange"}>
							{selectedItem?.getValue("status")}
						</Label>
					) : null}
				</Segment>
			</>
		)
	}
}
