import React from "react"
import { Segment, Header, Divider, SemanticTEXTALIGNMENTS } from "semantic-ui-react"

import "./styles.css"

interface SectionProps {
	title?: string | React.ReactNode
	children?: string | React.ReactNode
	size?: string
	textAlign?: SemanticTEXTALIGNMENTS
}

const Section = (props: SectionProps) => {
	return (
		<Segment inverted basic textAlign={props.textAlign || "left"}>
			<Header as={props.size || "h2"} inverted>
				{props.title}
			</Header>
			<Divider />
			{props.children}
		</Segment>
	)
}

export default Section
