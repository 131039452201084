import React, { useEffect, useState } from "react"
import { Container, Header, Icon, Image } from "semantic-ui-react"
import { useCookies } from "react-cookie"

import { getUIConfig } from "../../store"
import lightenDarkenColor from "../../helpers/lightenDarkenColor"
import { Sections } from "../../store/views/DefaultSidebar"

type WelcomeBannerProps = {
	onClose?: () => void
}

const WelcomeBanner: React.FC<WelcomeBannerProps> = ({ onClose }) => {
	const [showBanner, setShowBanner] = useState(false)
	const [checked, setChecked] = useState(false)
	const [cookies, setCookie, removeCookie] = useCookies(["welcome-banner-shown"])
	const { brandColor, logo } = getUIConfig("clientUI")
	const { header, content, duration, backgroundColor, fontColor, sections } = getUIConfig("welcomeBanner")

	useEffect(() => {
		//TODO add proper typing
		let timer: any
		if (cookies && !cookies["welcome-banner-shown"]) {
			setShowBanner(true)
			timer = setTimeout(() => {
				setShowBanner(false)
			}, duration * 1000)
			return () => clearTimeout(timer)
		} else {
			showBanner && setShowBanner(false)
			timer && clearTimeout(timer)
		}
	}, [cookies])

	const handleClose = () => {
		setShowBanner(false)
		setTimeout(() => {
			onClose && onClose()
		}, 300)
	}

	const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if ((event.target as HTMLElement).classList.contains("welcome-banner-overlay")) {
			handleClose()
		}
	}

	const handleSetClose = () => {
		setChecked(!checked)
		setTimeout(() => {
			setShowBanner(false)
			setCookie("welcome-banner-shown", true)
		}, 2000)
	}
	const bannerStyle: React.CSSProperties = {
		position: "fixed",
		zIndex: 1000,
		top: "50%",
		left: "50%",
		height: "60vh",
		width: "60vw",
		transform: showBanner ? "translate(-50%, -50%) scale(1)" : "translate(-50%, -50%) scale(0)",
		transition: "transform 0.3s ease, opacity 0.3s ease",
		opacity: showBanner ? 1 : 0,
		backgroundColor,
		padding: "50px",
		boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		cursor: "default",
		border: `1.5px solid ${brandColor}`,
	}

	const overlayStyle: React.CSSProperties = {
		position: "fixed",
		zIndex: 900,
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		backgroundColor: "rgba(0, 0, 0, 0.2)",
		display: showBanner ? "block" : "none",
		transition: "opacity 0.3s ease",
		opacity: showBanner ? 1 : 0,
		cursor: "pointer",
	}

	return showBanner ? (
		<div className="welcome-banner-overlay" style={overlayStyle} onClick={handleOutsideClick}>
			<div className="welcome-banner" style={bannerStyle}>
				<Icon
					size="large"
					name="close"
					style={{ color: brandColor, position: "absolute", top: "10px", right: "10px", cursor: "pointer" }}
					onClick={handleClose}
				/>
				<Container
					style={{
						height: "100%",
						width: "100%",
						position: "relative",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						cursor: "default",
					}}
				>
					<Container textAlign="center">
						<Image src={process.env.PUBLIC_URL + "/assets/" + logo} size="large" style={{ margin: "auto" }} />
						<Header
							style={{
								paddingTop: "10px",
								color: brandColor,
								fontWeight: "bold",
								letterSpacing: "1px",
							}}
						>
							{header}
						</Header>
						<Sections sections={sections} color={fontColor} brandColor={brandColor} />
						<Container style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "20px" }}>
							<div
								className="checkbox-welcome"
								style={{
									height: 10,
									width: 10,
									borderRadius: 5,
									border: `1px solid ${lightenDarkenColor(backgroundColor, 50, "darken")}`,
									backgroundColor: checked ? brandColor : lightenDarkenColor(backgroundColor, 50, "lighten"),
									cursor: "pointer",
								}}
								onClick={() => handleSetClose()}
							></div>
							<label style={{ color: lightenDarkenColor(fontColor, 50, "lighten"), paddingLeft: "10px" }}>
								Don't show this message again
							</label>
						</Container>
					</Container>
				</Container>
			</div>
		</div>
	) : null
}

export default WelcomeBanner
