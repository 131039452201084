import { ReactNode, useEffect, useState } from "react"
import { Menu, Sidebar } from "semantic-ui-react"

import DataToolbox from "../DataToolbox"
import Layer from "../LayersLayer"
import { call, getUIConfig, registerOnChange, registerforStateChanges, removeOnChange, removeStateChanges } from "../../../store/index"
import "../styles.css"
import { checkLoadedAuthority, printData } from "../../../providers/remoteHQ"
import { actions, messageLevels, objects } from "../../../store/types"
import { MenuItem } from "./MenuItem"

interface NgatiSideBarProps {
	onActiveCapture: (key: string) => void
	onClearImage: (key: string) => void
	isCapturing: boolean
	capturedImagesByKey: { [key: string]: string }
	children: ReactNode
}

const NgatiApaSidebar = (props: NgatiSideBarProps): JSX.Element => {
	const [sidebar, setSidebar] = useState(0)
	const [loggedIn, setLoggedIn] = useState(false)
	const [hasDebugPrivileges, setHasDebugPrivileges] = useState(false)
	const [uiConfig, setUiConfig] = useState({ ...getUIConfig("clientUI"), ...getUIConfig("navBar") })

	const mapClicked = (type: string, key: string, data: any): void => {
		if (type === "state" && key === "selected") {
			sidebar !== 0 ? setSidebar(0) : null
		}
	}

	const renderContent = () => {
		return (
			<>
				<Layer visible={sidebar === 1} />
				<DataToolbox visible={sidebar === 9} />
			</>
		)
	}

	const setSidebarHandler = (i: number): void => {
		setSidebar((n) => (n === i ? 0 : i))
	}

	const userChanged = (type: string, key: string, data: any): void => {
		if (type === "user-profile" && data) {
			const debugPrivileges = checkLoadedAuthority(actions.create, objects.debug)
			printData(hasDebugPrivileges, messageLevels.debug, "hasDebugPrivileges")
			printData(data, messageLevels.debug, "user-profile")
			setHasDebugPrivileges(debugPrivileges)
		}
		if (key === "loginState") {
			setSidebar(0)
			setLoggedIn(data.getValue("isLoggedIn"))
		}
	}

	const homeButtonAction = (): void => {
		setSidebarHandler(0)
		call("goToHome", null)
	}

	const layerButtonAction = (): void => {
		setSidebarHandler(1)
	}

	useEffect(() => {
		registerforStateChanges(userChanged)
		registerforStateChanges(mapClicked)
		registerOnChange("user-profile", userChanged)

		return (): void => {
			removeStateChanges(userChanged)
			removeStateChanges(mapClicked)
			removeOnChange("user-profile", userChanged)
		}
	}, [])

	const { brandColor, className, fontColor, backgroundColor } = uiConfig
	const { children } = props

	return (
		<Sidebar.Pushable id="nav">
			<Sidebar as={Menu} animation="overlay" icon="labeled" visible vertical width="very thin" inverted style={{ backgroundColor }}>
				<MenuItem
					className={className}
					active={sidebar === 0}
					activeColor={brandColor}
					baseColor={fontColor}
					onClick={homeButtonAction}
					label="Home"
					icon="home"
				/>
				<MenuItem
					className={className}
					active={sidebar === 1}
					activeColor={brandColor}
					baseColor={fontColor}
					onClick={layerButtonAction}
					label="Layers"
					icon="clone outline"
				/>
				<MenuItem
					className={className}
					active={sidebar === 2}
					activeColor={brandColor}
					baseColor={fontColor}
					onClick={(): void => setSidebar(2)}
					label="Search"
					icon="search"
					disabled
				/>
				<MenuItem
					className={className}
					active={sidebar === 6}
					activeColor={brandColor}
					baseColor={fontColor}
					onClick={(): void => setSidebar(6)}
					label="Users"
					icon="users"
					disabled
				/>
				{hasDebugPrivileges ? (
					<MenuItem
						className={className}
						active={sidebar === 9}
						activeColor={brandColor}
						baseColor={fontColor}
						onClick={(): void => setSidebarHandler(9)}
						label="Debug"
						icon="setting"
						disabled={!hasDebugPrivileges}
					/>
				) : null}
			</Sidebar>
			<Sidebar.Pusher className="map-container">
				<Sidebar.Pushable>
					{renderContent()}
					{children}
				</Sidebar.Pushable>
			</Sidebar.Pusher>
		</Sidebar.Pushable>
	)
}

export default NgatiApaSidebar
