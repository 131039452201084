import React from "react"
import { Button, Divider, Dropdown, Form, Header, Segment } from "semantic-ui-react"

import { requestLoad, isStoredItem, removeOnRetrieve, setGlobalState, getStoreTypeByName, getPropertiesArrayFromItemArray } from "../"
import { blockFeature } from "../classes/blockFeature"

import ImageList from "../components/Image/ImageListComponent"
import PinPlaceGeoJSONPoint from "../../mapsight/PinGeoJSONPoint"
import { BlockFeatureEditView, BlockFeatureInfoView } from "./BlockFeature"
import { printMessage, printData } from "../../providers/remoteHQ"
import { messageLevels } from "../types"
import { storedItem } from "../storedItem"

export class AreaOfEditView extends BlockFeatureEditView {
	maxNumber = 4

	componentDidMount() {
		super.componentDidMount()
		if (this.state.listItems.length < 0) {
			this.listCallback()
		}
	}

	registerForDataForType(typeName: string) {
		super.registerForDataForType(typeName)
		const theDataType = requestLoad(typeName + "-list", undefined, this.listCallback)
	}

	deregisterForDataForType(typeName: string) {
		super.deregisterForDataForType(typeName)
		removeOnRetrieve(this.state.typeName + "-list", this.listCallback)
	}

	listCallback = (type?: string, key?: string, data?: any) => {
		if (!type || type === undefined) {
			printMessage("no type name", messageLevels.verbose)
			this.setState({ listItems: [] })

			return
		}
		const theType = type.split("/")[0]

		const theItems: storedItem[] | undefined = getStoreTypeByName(theType)?.allMatchingItems({})

		if (!theItems || theItems === undefined) {
			printMessage("no items", messageLevels.verbose)
			this.setState({ listItems: [] })

			return
		}

		const theDescriptions = getPropertiesArrayFromItemArray(theItems, ["id", "description"])

		let arr: any[] = []

		Array.isArray(theDescriptions) &&
			theDescriptions.map(
				(i: any) =>
					(arr = [
						...arr,
						{
							key: i.id,
							text: i.description,
							value: i.description,
						},
					]),
			)
		this.setState({ listItems: arr })
	}

	render() {
		const item = this.props.editingItem as blockFeature
		const pinColour = (item as blockFeature).pinColour
		const hidePin = this.props.hidePin || false
		if (!item || !isStoredItem(item)) {
			return <></>
		}
		return (
			<>
				<Form>
					{this.renderImageUpload(
						<>
							{this.renderHeading()}
							<Divider />
							<Segment inverted basic>
								{/* <label> */}
								<Header as="h4" inverted>
									Description
								</Header>
								<Divider />
								{this.state.listItems.length ? (
									<Dropdown
										placeholder="Select a description"
										style={{ width: "100%" }}
										onChange={this.handleDropdownChange}
										value={item?.getValue("description")}
										search
										selection
										closeOnChange
										options={this.state.listItems}
									/>
								) : (
									<Form.Input
										type="textarea"
										value={item?.getValue("description")}
										placeholder={"Add a description"}
										onChange={this.handleChange}
										required
										error={this.errorForKey("description")}
									/>
								)}
								{/* </label> */}
							</Segment>
							<Segment inverted basic>
								<div className="field required">
									<label>Location</label>
									{!hidePin ? (
										<PinPlaceGeoJSONPoint
											edit={true}
											pinColor={pinColour}
											initialLocation={item?.getValue("geojson")}
											error={this.errorForKey("geojson")}
											setValue={this.handleGeoChange}
										></PinPlaceGeoJSONPoint>
									) : (
										<></>
									)}
								</div>
							</Segment>
						</>,
					)}
				</Form>
			</>
		)
	}
}

export class AreaOfInfoView extends BlockFeatureInfoView {
	render() {
		const { selectedItem } = this.props
		const imageLinks = (selectedItem as blockFeature).imageURLs
		const theLocation = selectedItem?.getValue("geojson")
		const pinColour = (selectedItem as blockFeature).pinColour
		return (
			<>
				<Segment inverted basic>
					<Header as="h2" inverted>
						{selectedItem?.displayName()}
					</Header>
					<Divider />
					<ImageList imageLinks={imageLinks}></ImageList>
				</Segment>
				<Segment inverted basic>
					<Header as="h4" inverted>
						Description
					</Header>
					<Divider />
					{selectedItem?.getValue("description") ? selectedItem?.getValue("description") : "No description available"}
				</Segment>
				<Segment inverted basic>
					<Header as="h4" inverted>
						Location
					</Header>
					<PinPlaceGeoJSONPoint edit={false} pinColor={pinColour} initialLocation={theLocation}></PinPlaceGeoJSONPoint>
				</Segment>
				{(selectedItem as blockFeature).canConvertToTask() && (
					<Button className="hover-blue" onClick={() => convertToTaskAndSelect(selectedItem as blockFeature)}>
						Convert to task
					</Button>
				)}
			</>
		)
	}
}

const convertToTaskAndSelect = (item: blockFeature | undefined) => {
	if (!item) {
		printMessage("no item to create task for", messageLevels.error)
		return
	}
	const newTask: blockFeature | undefined | null = item.convertToTask()
	if (newTask) {
		setGlobalState("addItem", [newTask])
	}
}
