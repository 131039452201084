import React from "react"
import { Button, Grid, GridColumn, GridRow, Icon, Ref, Segment, Sidebar } from "semantic-ui-react"

import { isStoredItem, removeStateChanges, save, setGlobalState } from "../.."

import "./style.css"
import { storedItem } from "../../storedItem"
import { printData, printMessage } from "../../../providers/remoteHQ"
import { messageLevels } from "../../types"
import { blockFeature } from "../../classes/blockFeature"
import { ReactMultipageForm } from "./MultiPageForm"
import { FormFieldProperties, FormPageItemProps, ItemContextProps } from ".."
import SubmitFormModal from "./Items/submit-form-modal"
import ItemContext, { ItemContextState } from "../ItemContext"

export default class ReactItemContext extends ItemContext {
	state: ItemContextState = {
		selectedItem: undefined,
		editingItem: undefined,
		defaultActiveView: 0,
		level: "country",
		isMinimized: false,
		inEditMode: false,
		addItemMode: false,
		isDirty: false,
		isValid: true,
		isConfirming: false,
		approvalStates: [],
		pages: [],
	}

	width = -1
	height = -1

	componentDidMount() {
		this.setState({ inEditMode: false })
	}

	componentWillUnount() {
		removeStateChanges(this.stateChanged)
	}

	// This loads a list of available descriptions for the layer:
	loadCallback = (type: string, key?: string, data?: any) => {
		let arr: any[] = []
		Array.isArray(data) &&
			data.map(
				(i) =>
					(arr = [
						...arr,
						{
							key: i.id,
							text: i.description,
							value: i.description,
						},
					]),
			)
		this.setState({ approvalStates: arr })
	}

	stateChanged = (type: string, key: string, data: any) => {}

	gotURLCallback = (theUrl: string) => {
		const theItem = this.getEditingItem() as blockFeature
		theItem.addImageURL(theUrl)
	}

	getItemType() {
		return this.state.selectedItem?.levelName() || null
	}

	getSelectedItem() {
		const { selectedItem, editingItem, inEditMode, addItemMode, addItem } = this.state
		if (addItemMode) {
			return addItem
		}
		return inEditMode ? editingItem : selectedItem
	}
	getEditingItem() {
		const { editingItem, addItemMode, addItem } = this.state
		if (addItemMode) {
			return addItem
		}
		return editingItem
	}

	// back = () => {
	// 	const selectedItem = this.getSelectedItem();
	// 	const parent: storedItem | null | undefined = this.state.inEditMode ? null : selectedItem?.getParent();
	// 	call("setLevelByItem", parent)
	// 	this.scrollToTop()
	// }

	// scrollToTop = () => this.sidebar.current?.scroll({top: 0})

	changeDefaultActiveView = (defaultActiveView: number) => {
		this.setState({ defaultActiveView })
	}

	renderForm = () => {
		const { inEditMode, addItemMode } = this.state
		const item = this.props.item

		const theType = item?.levelName() || null

		const editMode = inEditMode || addItemMode
		printData(editMode, messageLevels.debug, "editMode in renderInfoLayer")
		printData(item, messageLevels.debug, "item in renderInfoLayer")

		// const theItem = this.getSelectedItem();

		if (!theType) {
			return null
		}
		return (
			<ReactMultipageForm
				pages={this.pages}
				container={this.props.container}
				displayMode={this.props.displayMode}
				item={this.props.item}
				title={this.props.title}
				setDirty={this.setDirty}
				renderMiddleButtons={this.renderChangeButtons.bind(this)}
				onCancel={this.doCancel}
			></ReactMultipageForm>
		)
	}

	renderEditButton = () => {
		const selectedItem = this.getSelectedItem()

		if (selectedItem && isStoredItem(selectedItem) && !this.state.inEditMode && !this.state.addItemMode) {
			const theItem = selectedItem as storedItem
			const isEditable = theItem.checkUpdateAuthority(undefined, false)
			printData(isEditable, messageLevels.debug, "isEditable")
			if (isEditable) {
				return (
					<Button className="edit-btn" onClick={this.setEditMode}>
						Edit
					</Button>
				)
			}
		} else if (selectedItem && isStoredItem(selectedItem) && (this.state.inEditMode || this.state.addItemMode)) {
			return null
		}
		return null
	}

	saveItem = async (onSaveCallback?: () => void) => {
		const theItem: storedItem | null | undefined = this.props.item
		const isValid = await theItem?.validateBeforeSave()
		this.setState({ isValid })
		if (!isValid) return
		printData(theItem, messageLevels.debug, "theItem im saveItem(onSaveCallback)")
		if (theItem) {
			this.props.pages && this.saveToLocalStorage(theItem, this.props.pages)
			onSaveCallback?.()
			printMessage("about to keep changes", messageLevels.verbose)
			const theOriginal = theItem.keepChanges(this.keepItemCallback)
			printData(theOriginal, messageLevels.verbose, "storedItem in saveItem(onSaveCallback)")
		}
	}

	saveToLocalStorage = (item: storedItem, pages: FormPageItemProps[]) => {
		const saveFieldsToLocalStorage = (fields: FormFieldProperties[], page: FormPageItemProps) => {
			fields.forEach((field) => {
				const key = `${page.title}:${field.name}`
				const value = item.getValue(field.name)
				if (field.subfields) saveFieldsToLocalStorage(field.subfields, page)
				if (!value) return
				localStorage.setItem(key, JSON.stringify(value))
			})
		}
		pages.forEach((page) => {
			if (page.remember && item.getValue("remember")) {
				saveFieldsToLocalStorage(page.fields, page)
			}
		})
	}

	keepItemCallback = (theItem: storedItem) => {
		printData(theItem, messageLevels.verbose, "theItem im keepItemCallback")

		save()
		if (this.state.addItem) {
			this.setState({
				isDirty: false,
				selectedItem: theItem,
				editingItem: null,
				addItem: null,
			})
		} else {
			this.setState({
				isDirty: false,
				editingItem: null,
				addItem: null,
			})
		}

		this.endEditMode()
		printData(this.state, messageLevels.debug, "state in infoLayer")
		// save();
		if (!this.state.addItemMode) {
			printData(theItem, messageLevels.debug, "selecting Item:")
			theItem.select()
		} else {
			printData(theItem, messageLevels.debug, "theItem after edit mode when added")
		}
		if (this.props.onSubmit) {
			this.props.onSubmit()
		}
	}

	handleDelete = () => {
		printMessage("Handle Deleted", messageLevels.debug)
		const theItem: storedItem | null | undefined = this.getEditingItem()
		if (theItem) {
			theItem.delete()
			theItem.keepChanges()
			save()
		}

		this.setState({
			isDirty: false,
			isValid: false,
		})
		this.endEditMode()
	}

	setValue = (value: any, forKey: string) => {
		const theItem: storedItem | null | undefined = this.props.item
		if (theItem && theItem !== undefined) {
			printMessage("setting value: " + value, messageLevels.debug)
			theItem.setValue(value, forKey)
			theItem.validationForKey(forKey)
			this.setDirty()
		}
	}
	setDirty = async () => {
		const theItem: storedItem | null | undefined = this.props.item
		printData(theItem, messageLevels.debug, "item in setDirty")
		if (theItem && theItem !== undefined) {
			const isValid = await theItem.validForSave()
			this.setState({ isDirty: theItem.isDirty(), isValid })
		}
	}

	setEditMode = () => {
		const theEditItem = this.state.selectedItem?.editCopy()

		this.setState({
			inEditMode: true,
			editingItem: theEditItem,
		})
	}
	doCancel = () => {
		this.endEditMode()
		if (this.props.onCancel) {
			this.props.onCancel()
		}
	}
	endEditMode = () => {
		if (this.state.inEditMode || this.state.addItemMode) {
			this.setState({
				inEditMode: false,
				addItemMode: false,
				editingItem: null,
				addItem: null,
				isDirty: false,
				isValid: false,
			})
		}
	}

	renderChangeButtons(onSaveCallback?: () => void) {
		let canDelete = false
		if (this.props.displayMode) {
			return null
		}
		if (this.state.addItemMode) {
			canDelete = false
		} else {
			canDelete = true
			canDelete = this.getEditingItem()?.checkDeleteAuthority() || false
		}
		return (
			<span>
				{this.renderCancelButton()}
				{this.renderSaveButton(onSaveCallback)}
				{canDelete && this.renderDeleteButton()}
			</span>
		)
	}
	renderSaveButton(onSaveCallback?: () => void) {
		const isDirty = this.state.isDirty
		return (
			<Button
				color={!isDirty ? "grey" : "green"}
				disabled={!isDirty}
				icon={"save"}
				onClick={() => this.saveItem(onSaveCallback)}
				content="Submit"
				type="submit"
			/>
		)
	}
	renderCancelButton() {
		return <Button color={"red"} icon={"cancel"} onClick={this.doCancel} content="Cancel" />
	}

	renderDeleteButton() {
		return (
			<Button
				color={"red"}
				icon={"trash alternate"}
				onClick={this.handleDelete}
				style={{ height: "100%", width: "40px", margin: 0 }}
			/>
		)
	}
	renderSubmitModal() {
		return null
	}

	render() {
		return (
			<>
				<div style={{ height: "52" }}>
					<Grid verticalAlign={"top"}>
						<GridRow columns={2}>
							<GridColumn width={10} textAlign={"left"}></GridColumn>
							<GridColumn width={2} textAlign={"right"}>
								{this.renderEditButton()}
							</GridColumn>
						</GridRow>
					</Grid>
				</div>
				<div style={{ position: "relative" }}>
					{this.renderForm()}
					{this.renderSubmitModal()}
				</div>
			</>
		)
	}
}
