import React from "react"
import { Icon } from "semantic-ui-react"

import CollectionImage from "../../../mapsight/CollectionImage"

interface ImageListProps {
	setValue?: any
	imageLinks: any
	removeImage?: any
	edit?: boolean
	hidePlaceholder?: boolean
}

interface ImageListState {
	changed: boolean
}

export default class ImageList extends React.Component<ImageListProps, ImageListState> {
	state: ImageListState = {
		changed: false,
	}

	render() {
		const imageLinks = this.props.imageLinks
		const edit = this.props.edit
		const hidePlaceholder = !!this.props.hidePlaceholder
		if (imageLinks && imageLinks.length > 0) {
			return imageLinks.map((imageLink: URL) => {
				return (
					<CollectionImage
						imageLink={imageLink}
						edit={this.props.edit}
						removeImage={this.props.removeImage}
						key={imageLink.toString()}
					/>
				)
			})
		} else {
			if (hidePlaceholder) {
				return null
			}
			return (
				<div>
					<Icon name="image" size="large" />
					{edit ? "no existing images" : "No image available"}
				</div>
			)
		}
	}
}
