import { getConfig, itemIsSent, itemSendFailed, itemSendSucceeed, itemWillSend, sendDataAuthorised } from ".."
import { printData, printMessage } from "../../providers/remoteHQ"
import { storedItem } from "../../store/storedItem"
import { messageLevels } from "../types"
import { sequenceHandler } from "../sequenceHandler"
import { mediaItem } from "./mediaItem"
import { readImageData } from "../URLRewrites"

export class fileUpload extends mediaItem {
	_stageHandler: sequenceHandler = new sequenceHandler()

	isMounted = false

	mounted() {
		super.mounted()
		if (!this.isMounted) {
			this.isMounted = true
			this._stageHandler.addStage("fileInfo", this.fileInfo)
			this._stageHandler.addStage("readData", this.readData)
			this._stageHandler.addStage("requestURL", this.requestURLStep)
			this._stageHandler.addStage("sendUpload", this.sendFileStep)
			this._stageHandler.itemToSend = this
		}
	}
	setForItem = (item: storedItem) => {
		if (item.getRegionISO()) {
			this.setValue(item.getRegionISO(), "region")
		}

		if (item.getValue("forestblock")) {
			const forestBlock = (item as any).getValue("forestblock")
			if (forestBlock && forestBlock !== undefined) {
				const theUUID = forestBlock.getValue("uuid")
				this.setValue(theUUID, "forestBlock")
			}
		}
	}

	addCallbackOnURL(callback: Function) {
		this._stageHandler.addCallbackForStage("requestURL", callback)
	}

	addCallbackOnUpload(callback: Function) {
		this._stageHandler.addCallbackForStage("sendUpload", callback)
	}

	removeCallbackOnUpload(callback: Function) {
		this._stageHandler.removeCallbackForStage("sendUpload", callback)
	}

	requestStep = (stageName: string) => {
		this._stageHandler.requestStep(stageName)
	}
	requestUploadURL = (callback?: Function) => {
		if (callback) {
			this._stageHandler.addCallbackForStage("requestURL", callback)
		}

		this.requestStep("requestURL")
	}

	requestURL = () => {
		this.requestStep("requestURL")
	}

	sendFile = (callback?: Function) => {
		if (callback) {
			this._stageHandler.addCallbackForStage("sendUpload", callback)
		}
		this.requestStep("sendUpload")
	}

	// file_type, filename, destination_path, is_uploaded, created_date, modified_date, file_created_date, file_modified_date, region, forest_block, metadata, upload_user, upload_email, upload_url
	set file(theFile: File) {
		this.setValue(theFile, "_fileRef")
		this.setValue(theFile.name, "filename")
		this.setValue(new Date(theFile.lastModified), "file_modified_date")
		this.setValue(theFile.size, "file_size")
		this.setValue(theFile.type, "file_type")
		const theImageData = readImageData(theFile)
		printData(theImageData, messageLevels.verbose)
		this._stageHandler.stepFinished("fileInfo", true)
	}

	fileInfo = () => {
		if (this.getValue("filename") && this.getValue("file_size") && this.getValue("file_type")) {
			this._stageHandler.stepFinished("fileInfo", true)
		}
	}
	get dataObject(): {} | null {
		printMessage("dataObject in fileUpload", messageLevels.debug)
		let theData = this.data
		if (!theData) {
			theData = this.data()
		}
		printData(typeof theData, messageLevels.debug, "typeof this.data")
		return { data: this.data, format: this.fileType() }
	}

	set dataURI(theURI: any) {
		this.dataFromURI(theURI)
	}

	get data() {
		let theData = this.getValue("fileData")
		if (theData === undefined) {
			theData = this.readData()
		}
		return theData
	}
	set data(theData: any) {
		this.setValue(theData, "fileData")
	}
	get rotation() {
		const theRotation = this.getValue("rotation")
		if (theRotation && theRotation !== undefined) {
			return theRotation
		}
		// ToDo calculate rotation and store

		return 0
	}
	dataFromURI = async (theURI: any) => {
		this.setValue(theURI, "_dataURI")
		const theBlob: Blob = await (await fetch(theURI)).blob()
		const theData = await theBlob.arrayBuffer()
		printData(theBlob, messageLevels.debug, "BLOB")
		printData(theData, messageLevels.debug)
		this.setValue(theData, "fileData")
		this.setValue(theBlob.type, "file_type")
		this.setValue(theBlob.size, "file_size")
		const theImageData = await readImageData(theURI)
		printData(theImageData, messageLevels.verbose)
		printData(this, messageLevels.debug)
		this._stageHandler.stepFinished("readData", true)
	}

	requestURLStep = async (callback?: any) => {
		const theUploadURL = this.getValue("upload_url")
		if (theUploadURL && theUploadURL !== undefined && theUploadURL.length > 0) {
			return
		}

		if ((this as any)._URLRequestInProgress) {
			return
		} else {
			;(this as any)._URLRequestInProgress = true
			// send request and set callback to processURLResponse()
			printData(this, messageLevels.debug, "This in requestUploadURL")
			printData(this.urlRequestParams, messageLevels.debug, "this.urlRequestParams in requestUploadURL")
			sendDataAuthorised(
				new URL(getConfig("invokeUrl") + getConfig("requestUploadURL")),
				"POST",
				JSON.stringify(this.urlRequestParams),
				this.processURLResponse,
				this.primaryKey(),
			)
		}
	}

	get urlRequestParams() {
		const theParams = {
			uuid: this.primaryKey(),
			fileName: this.getValue("filename"),
			file_type: this.getValue("file_type"),
			fileSize: this.getValue("file_size"),
			fileCreated: this.getValue("fileCreated"),
			fileModified: this.getValue("file_modified_date"),
			region: this.getValue("region"),
			forestBlock: this.getValue("forestBlock"),
			metadata: this.getValue("metadata"),
			userEmail: this.getValue("userEmail"),
		}
		return theParams
	}

	processURLResponse = (returned: Promise<any>) => {
		returned.then((result) => {
			// const decodedData = JSON.parse(result);
			printMessage("processURLResponse", messageLevels.debug)
			printData(result, messageLevels.debug)
			if (result.uploadUrl) {
				this.setValue(result.uploadUrl, "upload_url")
			}
			if (result.finalURL) {
				this.setValue(result.finalURL, "destination_path")
			}

			this._stageHandler.stepFinished("requestURL", true)
			//    this.dumpData(messageLevels.verbose)
		})
	}

	readData = async () => {
		const theData = this.getValue("fileData")
		if (!theData || theData === undefined) {
			printMessage("no data", messageLevels.verbose)
			const theFile = this.getValue("_fileRef")
			if (theFile && theFile !== undefined && theFile.arrayBuffer) {
				printMessage("has File", messageLevels.debug)
				const theData = new Uint8Array(await theFile.arrayBuffer())

				printData(theData, messageLevels.debug, "Data from file")
				this.data = theData
				this._stageHandler.stepFinished("readData", true)
				return this.data
			}
		}
	}

	sendFileStep = async (callback?: any) => {
		const theUploadURL = this.getValue("upload_url")
		// send file to endpoint and set callback to deal with outcome
		const inProgress = (this as any)._SendInProgress
		if (inProgress && inProgress === true) {
			printMessage("Send already in progress", messageLevels.debug)
			return
		}
		this.readData()
		if (!theUploadURL) {
			printMessage("No upload URL", messageLevels.debug)
			return
		}
		;(this as any)._SendInProgress = true
		printMessage("Set in progress", messageLevels.debug)

		printMessage("About to fetch", messageLevels.debug)
		itemWillSend(this, "Uploading " + this.displayName())

		fetch(theUploadURL, {
			method: "PUT",
			headers: new Headers({
				"Content-Type": this.getValue("file_type"),
			}),
			body: this.data,
		})
			.then((response) => {
				if (response.ok) {
					printData(response, messageLevels.debug)
					;(this as any)._SendInProgress = false
					;(this as any)._sent = true
					;(this as any).is_uploaded = true
					this._dirty = false
					this._stageHandler.stepFinished("sendUpload", true)
					//   return response.json();
					itemSendSucceeed(this, this.displayName() + " uploaded")
				} else {
					printMessage("Failed to upload " + theUploadURL, messageLevels.error)
					printData(response, messageLevels.error)
					this._stageHandler.stepFinished("sendUpload", false)
					itemSendFailed(this, this.displayName() + " upload failed")
				}
			})
			.catch((err) => {
				printMessage("Failed to upload " + theUploadURL, messageLevels.error)
				this._stageHandler.stepFinished("sendUpload", false)
				itemSendFailed(this, this.displayName() + err?.message)
			})

		// sendDataAuthorised(new URL( theUploadURL ),"POST",this.data, this.notifyAPIUploadComplete, this.primaryKey());
	}
	get isUploaded() {
		return (this as any).is_uploaded === true
	}

	get URL() {
		const fileRef = this.getValue("_fileRef")

		if (fileRef && fileRef !== undefined) {
			if (fileRef.dataURL && fileRef.dataURL !== undefined) {
				return fileRef.dataURL
			}
		}

		const dataURI = (this as any)._dataURI

		if (dataURI) {
			return dataURI
		}
		return this.getValue("destination_path")
	}

	fileType = (): string | null => {
		const theName = this.getValue("filename")
		const extension = theName.split(".").pop()
		if (extension.length > 1 && extension.length < 5) {
			return extension.toLowerCase()
			// this.setValue(extension.toLowerCase(),"type");
		}
		return null
	}
}
