import { Divider, Header, Image, Segment } from "semantic-ui-react"

import { BlockFeatureInfoView } from "./BlockFeature"
import ResponsiveImageGrid from "../components/Image/ImageGrid"
import { AudioPlayer } from "../../components/AudioPlayer"
import { getUIConfig } from "../../store"

export default class PlaceNameInfoView extends BlockFeatureInfoView {
	render() {
		const { fontColor, logo } = getUIConfig("defaultSidebar")
		const { selectedItem } = this.props
		const theLocation = selectedItem?.getValue("geojson")
		const { brandColor } = getUIConfig("clientUI")
		const images =
			selectedItem?.displayName() == "Te Moana o Ngā Raukawakawa"
				? ["/images/sea-1.jpg", "/images/sea-2a.jpg", "/images/sea-3a.jpg", "/images/sea-3.jpg"]
				: []
		return (
			<>
				<Segment inverted basic style={{ padding: "3em", paddingTop: "3em", paddingBottom: "2em" }}>
					<Image src={process.env.PUBLIC_URL + "/assets/" + logo} size="medium" />
				</Segment>
				<Segment inverted basic>
					<Header as="h2" inverted style={{ color: fontColor }}>
						{selectedItem?.displayName()}
					</Header>
					<Divider style={{ backgroundColor: fontColor }} />
				</Segment>
				<Segment inverted basic style={{ color: fontColor }}>
					<Header as="h4" inverted style={{ color: fontColor }}>
						Description
					</Header>
					<Divider style={{ backgroundColor: fontColor }} />
					{selectedItem?.getValue("description") ? selectedItem?.getValue("description") : "No description available"}
				</Segment>
				<Segment inverted basic>
					<Header as="h4" inverted style={{ color: fontColor }}>
						Location {theLocation?.coordinates}
					</Header>
					{selectedItem?.displayName() == "Cape Foulwind" ? (
						<AudioPlayer
							cursorColor={brandColor}
							height={80}
							barWidth={2}
							barHeight={2}
							barRadius={2}
							barGap={1}
							url="/assets/wind.mp3"
							id="wind"
							waveColor={brandColor}
							progressColor="RGBA(245, 163, 58, 0.7)"
							playButton
						/>
					) : images.length > 0 ? (
						<ResponsiveImageGrid imageUrls={images} />
					) : (
						<div />
					)}
				</Segment>
			</>
		)
	}
}
