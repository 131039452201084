import React from "react"
import { Checkbox, Container, Icon, Popup } from "semantic-ui-react"
import DatePicker from "react-datepicker"
import moment from "moment"

import CheckboxDateRangeItem from "../../react/CheckboxDateRangeItem"
export class PDFCheckboxDateRangeItem extends CheckboxDateRangeItem {
	state = {
		dateRange: "",
		checked: false,
	}
	getChecked = () => {
		const theCheck = this.props.item?.getValue(this.props.field.name + "{checked}")
		if (theCheck === undefined) {
			return false
		}
		return theCheck
	}

	getDateRange = () => {
		const theDateRange = this.props.item?.getValue(this.props.field.name + "{dates}")
		if (!theDateRange) return ""
		return theDateRange
	}

	render() {
		const { field, item } = this.props
		return (
			<Container className="checkbox-date-range-item" style={{ padding: "10px 0" }}>
				<Checkbox
					label={
						<label style={{ float: "left" }}>
							{field.label}
							<p style={{ color: "gray", fontSize: "0.8em" }}>{field.description}</p>
						</label>
					}
					checked={this.getChecked()}
					onChange={(e, data) => this.setChecked(data.checked)}
				/>
				<Popup
					pinned
					position="top right"
					offset={[10, 0]}
					content={field.tooltip}
					trigger={<Icon style={{ float: "right" }} name="question circle" />}
				/>
				<DatePicker
					isClearable
					required
					value={this.getDateRange()}
					placeholderText="Start Date - End Date"
					dateFormat="DD MMMM YYYY"
					onChange={(value) => {
						const date = value ? moment(value).format("DD MMMM yyyy") : ""
						this.setDateRange(date)
					}}
				/>
			</Container>
		)
	}
}

export default CheckboxDateRangeItem
