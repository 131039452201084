import { printData } from "../../providers/remoteHQ"
import { getGlobalState } from "../../store"
import { storedItem } from "../../store/storedItem"
import { messageLevels } from "../types"

export class layerGroup extends storedItem {
	get layerItems() {
		return this.getRelatedItems("map-layers", this.getValue("layers"), "primaryKey", "in")
	}

	inCurrentLevel(): boolean {
		const theLevels = this.getValue("levels") || this.getValue("contexts")

		if (theLevels) {
			let theLevel = getGlobalState("level")?.getSingleValue()
			// printData(theLevel,messageLevels.verbose,"theLevel")
			if (theLevel) {
				theLevel = theLevel.toLowerCase()

				let found = (theLevels as string[]).includes(theLevel)

				return found
			}
		}
		return true
	}
	inCurrentMode(): boolean {
		const theModes = this.getValue("modes")

		if (theModes) {
			let theMode = getGlobalState("mode-name")?.getSingleValue()
			// printData(theMode,messageLevels.verbose,"theMode");
			// printData(typeof theMode,messageLevels.verbose,"typeof theMode");
			if (theMode) {
				theMode = theMode[0].toLowerCase()
				let found = false
				;(theModes as string[]).forEach((element) => {
					if (theMode && element && element.toLowerCase() === theMode) {
						found = true
					}
				})
				return found
			}
		}
		return true
	}

	allowEdit = (): boolean => {
		const editDisable = this.getValue("editDisable")

		if (editDisable) {
			return !editDisable
		}
		// const allowEdit =  this.getValue("allowEdit");

		// if (allowEdit ) {
		// 	return allowEdit;
		// }
		return true
	}
	highlightOnMap = (): void => {}
	clearHighlightOnMap = (): void => {}
	selectOnMap = (): void => {}
}
