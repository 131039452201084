import React from "react"
import { Divider, Form, Menu, Segment } from "semantic-ui-react"
import { Text, View } from "@react-pdf/renderer"

import { isStoredItem } from "../../.."
import { storedItem } from "../../../storedItem"
import { FormFieldProperties, formFormatNumber } from "../../index"
import { printData, printMessage } from "../../../../providers/remoteHQ"
import { ImageSupport, messageLevels } from "../../../types"
import { DisplayTextComponent } from "./DisplayTextComponent"
import { FormItem } from "../../Items/FormItem"
import { PDFBlockComponent } from "../PDFBlockComponent"
import { PDFCheckboxDateRangeItem } from "./PDFCheckboxDateRangeItem"
import { PDFDisplayInputComponent } from "./PDFDisplayInputComponent"
import PinPlaceGeoJSONPoint from "../../../../mapsight/PinGeoJSONPoint"
import { PDFImageListItem } from "./PDFImageListItem"
import { ReportHeader } from "./ReportHeader"
import { PDFHorizontalGroup } from "../HorizontalGroup"
import { Banner } from "./Banner"
import { PDFFormList } from "./PDFFormList"
import { FormImage } from "./ImageUpright"
export class PDFFormItem extends FormItem {
	// updateFormData(field: FormFieldProperties, value: any) {}
	// setValue = (value: any, forKey: string) => {};
	// setDirty = () => {};
	getChecked = () => {
		const theCheck = this.props.item?.getValue(this.props.field.name)
		if (theCheck === undefined) {
			return false
		}
		return theCheck
	}

	setChecked = (value: boolean | undefined) => {
		this.setValue(value, this.props.field.name)
	}
	errorForKey = (forKey: string): any => {
		const theItem: storedItem | null | undefined = this.props.item
		if (theItem && theItem !== undefined) {
			return theItem.validationErrorForKey(forKey)
		}
		return null
	}

	render() {
		if (this.props.item && this.props.item !== null) {
			return <>{this.renderField(this.props.field, this.props.index)}</>
		} else {
			return <></>
		}
	}

	renderField(field: FormFieldProperties, index: number) {
		const displayMode = this.props.displayMode || false
		const showInDisplay = field.showInDisplay || true

		if (displayMode !== true || showInDisplay === true) {
			switch (field.type.toLowerCase()) {
				case "heading":
					return this.renderHeading(field)

				case "display":
					return this.renderDisplay(field)
				case "number":
					return this.renderNumber(field)

				case "input":
					return this.renderInput(field)

				case "text":
					return this.renderText(field)

				case "separator":
				case "seperator":
					return this.renderSeparator()

				case "checkbox":
					return this.renderCheckbox(field)

				case "checkboxdaterange":
					return this.renderCheckboxDateRange(field, index)

				case "options":
					return this.renderOptions(field)

				case "locationpicker":
					return this.renderLocationPicker(field)

				case "upload":
					return this.renderUpload(field, index)

				case "block":
					return this.renderBlock(field, index)
				case "horizontalgroup":
					return this.renderHorizontalGroup(field, index)
				case "point":
					return this.renderPoint(field, index)
				case "displaytext":
					return this.renderDisplayText(field, index)
				case "displayinput":
					return this.renderDisplayText(field, index)
				case "reportheader":
					return this.renderReportHeader(field, index)
				case "banner":
					return this.renderBanner(field, index)
				case "list":
					return this.renderList(field, index)
				case "image":
					return this.renderImage(field, index)

				default:
					return null
			}
		} else {
			return null
		}
	}
	renderHorizontalGroup(field: FormFieldProperties, index: number) {
		let theItem = this.props.item
		const theValue = theItem?.getValue(field.name)
		if (theValue && isStoredItem(theValue)) {
			theItem = theValue
		}
		return (
			<PDFHorizontalGroup
				item={this.props.item}
				field={field}
				key={index}
				index={index}
				displayMode={this.props.displayMode}
				setDirty={this.setDirty}
				setValue={this.setValue}
				container={this.props.container}
			></PDFHorizontalGroup>
		)
	}
	renderList(field: FormFieldProperties, index: number) {
		let theItem = this.props.item
		const theValue = theItem?.getValue(field.name)
		if (theValue && isStoredItem(theValue)) {
			theItem = theValue
		}
		return (
			<PDFFormList
				item={theItem}
				field={field}
				key={index}
				index={index}
				displayMode={this.props.displayMode}
				setValue={this.props.setValue}
				container={this.props.container}
			></PDFFormList>
		)
	}
	renderImage(field: FormFieldProperties, index: number) {
		let theItem = this.props.item
		const theValue = theItem?.getValue(field.name)
		if (theValue && isStoredItem(theValue)) {
			theItem = theValue
		}
		return (
			<FormImage
				item={theItem}
				field={field}
				key={index}
				index={index}
				displayMode={this.props.displayMode}
				setValue={this.props.setValue}
				container={this.props.container}
			></FormImage>
		)
	}
	renderBlock(field: FormFieldProperties, index: number) {
		let theItem = this.props.item
		const theValue = theItem?.getValue(field.name)
		if (theValue && isStoredItem(theValue)) {
			theItem = theValue
		}
		return (
			<PDFBlockComponent
				item={theItem}
				field={field}
				key={index}
				index={index}
				displayMode={this.props.displayMode}
				setValue={this.props.setValue}
				container={this.props.container}
			></PDFBlockComponent>
		)
	}
	renderInput(field: FormFieldProperties) {
		printMessage("DisplayMode: " + this.props.displayMode, messageLevels.debug)
		if (this.props.displayMode === true) {
			return this.renderDisplay(field)
		} else {
			return (
				// <div className={this.classesForField(field)}>
				<Form.Input
					className={this.classesForField(field)}
					label={field.label}
					autoComplete="no"
					value={this.props.item?.getValue(field.name) || ""}
					error={this.errorForKey(field.name)}
					onChange={(e, { value }) => this.updateFormData(field, value)}
				/>
				// </div>
			)
		}
	}

	renderDisplay(field: FormFieldProperties) {
		const container = this.props.container
		const styles = container.styles
		let theValue = this.props.item?.getValue(field.name)
		const theStyle = field.style || {}
		theValue = formFormatNumber(theValue, field.formatOptions, field.scale, field.defaultValue)
		return (
			<View style={[theStyle, styles.row, { marginTop: "4mm", marginBottom: "2mm" }]}>
				<Text style={{ width: "40%", fontWeight: "bold" }}>{field.label}:</Text>
				<Text style={{ width: "60%", textAlign: "right" }}>{theValue}</Text>
			</View>
		)
	}

	renderNumber(field: FormFieldProperties) {
		const container = this.props.container
		const styles = container.styles
		let theValue = this.props.item?.getValue(field.name)

		theValue = formFormatNumber(theValue, field.formatOptions, field.scale, field.defaultValue)

		if (field.label && field.label.length > 0) {
			return (
				<View style={[styles.row, { marginTop: "4mm", marginBottom: "2mm" }]}>
					<Text style={{ width: "40%", fontWeight: "bold" }}>{field.label}:</Text>
					<Text style={{ width: "60%" }}>{theValue}</Text>
				</View>
			)
		}
		return (
			<View style={{ width: "90%", height: 18 }}>
				<Text>{theValue}</Text>
			</View>
		)
	}

	renderPoint(field: FormFieldProperties, index: any) {
		const theItem = this.props.item
		return (
			<PinPlaceGeoJSONPoint
				edit={false}
				hidePin
				pinColor={theItem?.pinColour}
				initialLocation={theItem?.getValue(field.name)}
				error={this.errorForKey(field.name)}
				setValue={(value: any) => this.updateFormData(field, value)}
			></PinPlaceGeoJSONPoint>
		)
	}

	renderText(field: FormFieldProperties) {
		return (
			<>
				<Text>{field.label}</Text>
				<Text>value={this.props.item?.getValue(field.name)}</Text>
			</>
		)
	}
	renderDisplayText(field: FormFieldProperties, index: any) {
		const item = this.props.item
		return <DisplayTextComponent item={this.props.item} field={field} index={index} container={this.props.container} />
	}
	renderDisplayInput(field: FormFieldProperties, index: any) {
		const item = this.props.item
		return (
			<div className={this.classesForField(field)}>
				<PDFDisplayInputComponent item={this.props.item} field={field} index={index} container={this.props.container} />
			</div>
		)
	}
	renderReportHeader(field: FormFieldProperties, index: any) {
		const item = this.props.item
		return <ReportHeader item={this.props.item} field={field} index={index} container={this.props.container} />
	}
	renderBanner(field: FormFieldProperties, index: any) {
		const item = this.props.item
		return <Banner item={this.props.item} field={field} index={index} container={this.props.container} />
	}

	renderCheckbox(field: FormFieldProperties) {
		return (
			<Form.Checkbox
				key={field.name}
				label={field.label}
				checked={this.getChecked()}
				onChange={(e, data) => this.setChecked(data.checked)}
				error={this.errorForKey(field.name)}
			/>
		)
	}
	renderCheckboxDateRange(field: FormFieldProperties, index: number) {
		return (
			<div className={this.classesForField(field)}>
				<PDFCheckboxDateRangeItem
					item={this.props.item}
					field={field}
					updateFormData={this.updateFormData}
					index={index}
					setValue={this.setValue}
					container={this.props.container}
				></PDFCheckboxDateRangeItem>
			</div>
		)
	}

	renderUpload(field: FormFieldProperties, index: number) {
		const item = this.props.item
		// const theData = item.getValue(this.props.field.name);

		return <PDFImageListItem item={item} container={this.props.container} field={field}></PDFImageListItem>
	}
	renderSeparator() {
		return <Divider />
	}
	renderHeading(field: FormFieldProperties) {
		const theValue = this.props.item?.getValue(field.name)
		return (
			<>
				<Text>{field.label}</Text>
				<Text>{theValue && theValue}</Text>
			</>
		)
	}
	renderOptions(field: FormFieldProperties) {
		// if (field.label && field.label.length > 0) {
		// 	return (
		// 		<Text>{field.label}</Text>
		// 		<Menu key={field.name} inverted size='large'>
		// 			<Menu.Item header>{field.label}</Menu.Item>
		// 			{field.choices?.map((element, index) =>
		// 				this.renderOption(field, index, element),
		// 			)}
		// 		</Menu>
		// 	);
		// } else {
		// 	return (
		// 		<Menu>
		// 			{field.choices?.map((element, index) =>
		// 				this.renderOption(field, index, element),
		// 			)}
		// 		</Menu>
		// 	);
		// }
	}
	renderOption(field: FormFieldProperties, index: number, choice: string) {
		// return (
		// 	<Menu.Item
		// 		key={field.name + choice}
		// 		name={choice}
		// 		active={this.props.item.getValue(field.name) === choice}
		// 		onClick={() => this.updateFormData(field, choice)}
		// 	/>
		// );
	}

	renderLocationPicker(field: FormFieldProperties) {
		const theLocation = this.props.item?.getValue(field.name)
		const theClasses = this.classesForField(field)
		return (
			<div className={theClasses}>
				<>
					{this.renderHeading}
					<PinPlaceGeoJSONPoint
						edit={false}
						hidePin
						pinColor={this.props.item?.pinColour}
						initialLocation={theLocation}
						error={this.errorForKey(field.name)}
						setValue={(value: any) => this.updateFormData(field, value)}
					></PinPlaceGeoJSONPoint>
				</>
			</div>
		)
	}
	classesForField(field: FormFieldProperties) {
		let classes = "field"
		if (field.mandatory) {
			classes += " required"
		}
		if (this.props.item && this.props.item.validationErrorForKey(field.name) !== null) {
			classes += " error"
		}
		return classes
	}
}
const onClickSelectCoordinateButton = () => {
	// dispatch(NotifyFormStoreHandlers.startSelectingCoordinate());
	// hideSidebar();
}

export function colorForItemKey(item: storedItem, key: string) {
	return item.validationForKey(key) ? undefined : "red"
}
