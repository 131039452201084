import React from "react"
import { Button, Dropdown, Icon, Loader } from "semantic-ui-react"

import {
	getGlobalState,
	getUIConfig,
	registerOnChange,
	registerforStateChanges,
	removeOnChange,
	removeStateChanges,
} from "../../store/index"
import { logOut } from "../../providers/cognito"
import LoginForm from "./LoginForm"
import { printData, printMessage } from "../../providers/remoteHQ"
import { messageLevels } from "../../store/types"
import "./styles.css"

interface LoginButtonProps {}

export default class LoginButton extends React.Component<LoginButtonProps, {}> {
	state = {
		userName: "",
		loggedIn: false,
		showLogInForm: false,
		uiConfig: getUIConfig("clientUI"),
	}

	componentDidMount() {
		this.setState({ loggedIn: getGlobalState("loginState")?.getValue("isLoggedIn") })
		registerforStateChanges(this.userChanged)
		registerforStateChanges(this.onLoginStateLoaded)
		registerOnChange("user-profile", this.userChanged)
	}
	componentWillUnmount() {
		removeStateChanges(this.userChanged)
		removeStateChanges(this.onLoginStateLoaded)
		removeOnChange("user-profile", this.onLoginStateLoaded)
	}

	userChanged = (type: string, key: string, data: any) => {
		printMessage("Type in LoginButton: " + type, messageLevels.debug)
		printData(data, messageLevels.debug)

		if (type === "user-profile" && data) {
			let theName = data.email
			if (data.first_name && data.last_name) {
				theName = (data.first_name || "") + " " + (data.last_name || "")
			}
			this.setState({ userName: theName })

			if (key.startsWith("000")) {
			} else {
				printData(key, messageLevels.debug, "key in userChanged in loginButton")
				printData(data, messageLevels.debug, "data in userChanged in loginButton")
			}
		} else if (type === "state" && key === "loginState") {
			this.setState({ loggedIn: data.getValue("isLoggedIn") })
		}
	}

	onLoginStateLoaded = (type: string, key: string, data: any): void => {
		if (key === "loginState") {
			this.setState({ loggedIn: data.getValue("isLoggedIn") })
		}
	}

	loginControl = () => {
		return (
			<Dropdown.Item
				text="Logout"
				onClick={() => {
					logOut()
				}}
			/>
		)
	}

	renderLoginButton = () => {
		return (
			<>
				<Button
					id="login-button"
					className="not-logged-in"
					style={{ backgroundColor: this.state.uiConfig.brandColor }}
					onClick={() => this.setState({ showLogInForm: true })}
				>
					LOG IN
				</Button>
				{this.state.showLogInForm && <LoginForm onClose={() => this.setState({ showLogInForm: false })} />}
			</>
		)
	}

	render() {
		const { loggedIn } = this.state
		if (!loggedIn) {
			return this.renderLoginButton()
		}
		return (
			<Dropdown
				id="login-button"
				style={{ backgroundColor: this.state.uiConfig.backgroundColor, opacity: 0.9, color: this.state.uiConfig.color }}
				trigger={
					<>
						{/* TODO: attach username and avatar from store */}
						{/* <Image src="https://ca.slack-edge.com/T6JNAU92N-U6J345T96-f214fb95434b-72" circular size="mini" /> */}
						<Icon name="user circle" />
						{this.state.userName ? <div>{this.state.userName}</div> : <Loader active inline size="tiny" />}
					</>
				}
			>
				<Dropdown.Menu direction="right">{this.loginControl()}</Dropdown.Menu>
			</Dropdown>
		)
	}
}
