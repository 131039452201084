import lightenDarkenColor from "../../helpers/lightenDarkenColor"

interface ProgressBarProps {
	percent?: number // Percent loaded
	barColor?: string // Color for the progress bar
	left?: number | string // Left position if fixed
	right?: number | string // Right position if fixed
	top?: number | string // Top position if fixed
	bottom?: number | string // Bottom position if fixed
	width?: string // Optional width of the progress bar
	disappearOnLoad?: boolean // Disappear when percent is 100
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percent, barColor, left, right, top, bottom, width = "100%", disappearOnLoad }) => {
	const isVertical = left || right
	const isHorizontal = top || bottom

	const progressStyle: React.CSSProperties = {
		position: left || right || top || bottom ? "fixed" : "relative",
		left: isVertical ? left ?? 0 : undefined,
		right: isVertical ? right ?? 0 : undefined,
		top: isHorizontal ? top ?? 0 : undefined,
		bottom: isHorizontal ? bottom ?? 0 : undefined,
		width: isVertical ? "0.2rem" : width,
		height: isVertical ? "100vh" : "0.2rem",
		backgroundColor: lightenDarkenColor(barColor || "#81BF37", 65, "lighten"),
		overflow: "hidden",
		display: isVertical ? "flex" : "block",
		flexDirection: isVertical ? "column" : undefined,
		alignItems: isVertical ? (left ? "flex-start" : "flex-end") : undefined,
		transition: "width 0.3s ease-in-out, height 0.3s ease-in-out",
	}

	const barStyle: React.CSSProperties = {
		width: isVertical ? "100%" : `${percent}%`,
		height: isVertical ? `${percent}%` : "100%",
		backgroundColor: barColor || "green",
	}

	if (!disappearOnLoad || (disappearOnLoad && percent !== 100)) {
		return (
			<div style={progressStyle}>
				<div style={barStyle}></div>
			</div>
		)
	} else {
		return <></>
	}
}

export default ProgressBar
