import { call } from ".."
import { storedItem } from "../../store/storedItem"

export class region extends storedItem {
	getParent() {
		return this.getRelatedItem("boundaries", this.getValue("parent_iso"), "iso", "==", true)
	}

	getBoundingBox = () => {
		return this.getValue("mapbox_bounding_box")
	}
	displayName(): string {
		return this.getValue("english_name")
	}
	levelName = (): string => {
		const theLevel = this.getValue("iso_subdivision_category")
		if (theLevel) {
			return theLevel
		}
		return "region"
	}
	get authorityItem() {
		return this.getRelatedItems("authorities", this.getRegionISO(), "getRegionISO")[0]
	}
	get statistics() {
		return this.getRelatedItem("dashboard-metrics", this.getRegionISO(), "getRegionISO")
	}
	areasOfConcern = (): storedItem[] => {
		return this.getRelatedItems("areas-of-concern", this.getRegionISO(), "getRegionISO")
	}
	areasOfFailure = (): storedItem[] => {
		return this.getRelatedItems("areas-of-failure", this.getRegionISO(), "getRegionISO")
	}
	areasOfExcellence = (): storedItem[] => {
		return this.getRelatedItems("areas-of-excellence", this.getRegionISO(), "getRegionISO")
	}

	highlightOnMap = (): void => {}
	clearHighlightOnMap = (): void => {}
	selectOnMap = (skipSetState?: boolean): void => {
		call("setLevelByItem", this, skipSetState)
	}
}
