import { LngLatLike } from "mapbox-gl"
import { area, centroid, length, lineString, points } from "@turf/turf"
import { convertArea, convertLength } from "@turf/helpers"
import { Feature, LineString, Polygon, Position } from "geojson"

import { registerCall } from "../store"

export interface Measurement {
	polygons: {
		[id: string]: MeasurementPolygon
	}
	lines: {
		[id: string]: MeasurementLine
	}
	addPolygon: (data: Feature<Polygon> | Feature<Polygon>[]) => void
	addLine: (data: Feature<LineString> | Feature<LineString>[]) => void
	remove: (id: string | number) => void
}

export interface MeasurementPolygon extends Feature<Polygon> {
	center: LngLatLike
	areaInHectares: number
	perimeterInMeters: number
	perimeterInKm: number
}

export interface MeasurementLine extends Feature<LineString> {
	center: LngLatLike
	perimeterInMeters: number
	perimeterInKm: number
}

export const createMeasurement = (): Measurement => {
	const polygons: { [id: string]: MeasurementPolygon } = {}
	const lines: { [id: string]: MeasurementLine } = {}

	const addPolygon = (data: Feature<Polygon> | Feature<Polygon>[]) => {
		if (Array.isArray(data)) {
			data.forEach((d) => addPolygon(d))
			return
		}
		if (!data?.id) return
		polygons[data.id] = createPolygon(data)
	}

	const addLine = (data: Feature<LineString> | Feature<LineString>[]) => {
		if (Array.isArray(data)) {
			data.forEach((d) => addLine(d))
			return
		}
		if (!data?.id) return
		lines[data.id] = createLine(data)
	}

	const remove = (id: string | number) => {
		delete polygons[id]
		delete lines[id]
	}

	const getLine = (id: string) => lines[id]

	registerCall("getLine", getLine)

	return {
		polygons,
		lines,
		addPolygon,
		addLine,
		remove,
	}
}

export const createPolygon = (data: Feature<Polygon>): MeasurementPolygon => {
	const _center = centroid(points(data.geometry.coordinates[0])).geometry.coordinates as LngLatLike
	const perimeterInMeters = length(lineString(data.geometry.coordinates[0]), { units: "meters" })
	const areaInHectares = convertArea(area(data), "meters", "hectares")
	const perimeterInKm = convertLength(perimeterInMeters, "meters", "kilometers")

	return {
		...data,
		center: _center,
		perimeterInMeters: Math.round(perimeterInMeters),
		areaInHectares: Math.round(areaInHectares * 100) / 100,
		perimeterInKm: Math.round(perimeterInKm * 100) / 100,
	}
}

export const createLine = (data: Feature<LineString>): MeasurementLine => {
	const _center = centroid(points(data.geometry.coordinates)).geometry.coordinates as LngLatLike
	const perimeterInMeters = length(lineString(data.geometry.coordinates), { units: "meters" })
	const perimeterInKm = convertLength(perimeterInMeters, "meters", "kilometers")

	return {
		...data,
		center: _center,
		perimeterInMeters: Math.round(perimeterInMeters * 100) / 100,
		perimeterInKm: Math.round(perimeterInKm * 100) / 100,
	}
}
