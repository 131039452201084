import React, { FC } from "react"
import { Divider, Header, Image, Loader, Segment } from "semantic-ui-react"

import { getUIConfig, registerOnChange, registerforStateChanges, removeOnChange, removeStateChanges, requestLoad } from "../index"
import { ItemViewProps } from "./Types"

export interface CountryInfoLayerState {
	userName: string
	isLoggedIn: boolean
}

interface TextProps {
	content: string
	color?: string
	style?: any
}

const FormTextBlock: FC<TextProps> = ({ content, color, style }) => <div style={{ ...style, color, paddingTop: "10px" }}>{content}</div>

const HeaderTextBlock: FC<TextProps> = ({ content, color, style }) => (
	<Header as="h2" inverted style={{ ...style, color }}>
		{content}
	</Header>
)

const formComponent = {
	divider: (props: any): JSX.Element => <Divider {...props} style={{ backgroundColor: props.brandColor }} />,
	header: (props: any): JSX.Element => <HeaderTextBlock {...props} />,
	text: (props: any): JSX.Element => <FormTextBlock {...props} />,
}

export function Sections({ sections, color, brandColor }: { sections: any; color: string; brandColor: string }): JSX.Element {
	return (
		<>
			{sections?.map((props: any) => {
				const Component = formComponent[props.type as keyof typeof formComponent]
				if (Component !== undefined) {
					return <Component {...props} color={color} brandColor={brandColor} />
				} else {
					return <div>Section type does not exist. Check DB or JSON</div>
				}
			})}
		</>
	)
}

export default class DefaultSidebar extends React.Component<ItemViewProps, CountryInfoLayerState> {
	state = {
		userName: "",
		isLoggedIn: false,
		uiConfig: { ...getUIConfig("clientUI"), ...getUIConfig("defaultSidebar") },
	}

	componentDidMount() {
		registerforStateChanges(this.userChanged)
		registerOnChange("user-profile", this.userChanged)
	}
	componentWillUnmount() {
		removeOnChange("user-profile", this.userChanged)
		removeStateChanges(this.userChanged)
	}

	userChanged = (type: string, key: string, data: any) => {
		if (type === "user-profile" && data) {
			let theName = data.email
			if (data.first_name && data.last_name) {
				theName = (data.first_name || "") + " " + (data.last_name || "")
			}
			this.setState({ userName: theName })
		} else if (key === "loginState" && data) {
			this.setState({ isLoggedIn: data.getValue("isLoggedIn") })
		}
	}

	render() {
		const { uiConfig } = this.state
		if (uiConfig) {
			const { brandColor, logo, title, sections, fontColor } = uiConfig
			return (
				<div id="country-block">
					<Segment inverted basic style={{ paddingTop: "3em", paddingBottom: "2em" }}>
						<Image src={process.env.PUBLIC_URL + "/assets/" + logo} size="medium" />
					</Segment>
					<Segment inverted basic>
						<Header as="h2" inverted style={{ color: fontColor }}>
							{title}
						</Header>
						<Sections sections={sections} color={fontColor} brandColor={brandColor} />
					</Segment>
				</div>
			)
		} else {
			return (
				<Segment
					style={{
						width: "100%",
						height: "100vh",
						backgroundColor: "transparent",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Loader />
				</Segment>
			)
		}
	}
}
