import React, { createRef } from "react"
import { Button, Divider, Grid, GridColumn, GridRow, Header, Icon, Placeholder, Ref, Segment, Sidebar } from "semantic-ui-react"

import {
	call,
	getGlobalState,
	getUIConfig,
	isStoredItem,
	registerforStateChanges,
	removeStateChanges,
	save,
	setGlobalState,
} from "../../../store"
import "./styles.css"
import { storedItem } from "../../../store/storedItem"
import { printData, printMessage } from "../../../providers/remoteHQ"
import { messageLevels } from "../../../store/types"
import { blockFeature } from "../../../store/classes/blockFeature"
import { forestBlock } from "../../../store/classes/forestBlock"
import { storeType } from "../../../store/storeType"
import { OtherView } from "../../../store/views"
import { BlockInfoView } from "../../../store/views/Block"
import lightenDarkenColor from "../../../helpers/lightenDarkenColor"
import DefaultSidebar from "../../../store/views/DefaultSidebar"

interface InfoLayerProps {
	visible: boolean
	minimize: boolean
}

export interface InfoLayerState {
	selectedItem?: storedItem
	editingItem?: storedItem | null
	addItem?: storedItem | null
	defaultActiveView: number
	isMinimized: boolean
	level: string
	inEditMode: boolean
	addItemMode: boolean
	isDirty: boolean
	isValid: boolean
	approvalStates: any[]
}

export default class InfoLayer extends React.Component<InfoLayerProps, InfoLayerState> {
	state: InfoLayerState = {
		selectedItem: undefined,
		editingItem: undefined,
		defaultActiveView: 0,
		level: "country",
		isMinimized: false,
		inEditMode: false,
		addItemMode: false,
		isDirty: false,
		isValid: true,
		approvalStates: [],
	}

	sidebar = createRef<HTMLDivElement>()

	componentDidMount() {
		registerforStateChanges(this.stateChanged)
		const currentlySelected = getGlobalState("selected")
		this.setState({ selectedItem: currentlySelected })
		this.setState({ inEditMode: false })
	}

	componentWillUnount() {
		removeStateChanges(this.stateChanged)
		const oldType: storeType | undefined | null = this.state.selectedItem?.getType()
		if (oldType) {
			oldType.removeOnChange(this.dataChanged)
		}
	}

	// This loads a list of available descriptions for the layer:
	loadCallback = (type: string, key?: string, data?: any) => {
		let arr: any[] = []
		Array.isArray(data) &&
			data.map(
				(i) =>
					(arr = [
						...arr,
						{
							key: i.id,
							text: i.description,
							value: i.description,
						},
					]),
			)
		this.setState({ approvalStates: arr })
	}

	stateChanged = (type: string, key: string, data: any) => {
		if (key === "selected") {
			this.endEditMode()
			const newlySelected = data.getValue()
			let newlySelectedItem: storedItem
			const oldType: storeType | undefined | null = this.state.selectedItem?.getType()
			if (Array.isArray(newlySelected) && newlySelected.length > 0) {
				newlySelectedItem = newlySelected[0]
				if (newlySelectedItem && isStoredItem(newlySelectedItem)) {
					const type = newlySelectedItem.getType()

					if (type !== oldType) {
						if (type) {
							type.registerOnChange(this.dataChanged)
						}
						if (oldType) {
							oldType.removeOnChange(this.dataChanged)
						}
					}
					this.setState({ selectedItem: newlySelectedItem })
				}
			} else {
				this.setState({ selectedItem: undefined })
			}
		}
		if (key === "addItem") {
			const addItems = data.getValue()
			let addItem

			if (Array.isArray(addItems) && addItems.length) {
				addItem = addItems[0]

				this.setState({ addItem: addItem, addItemMode: true })
			} else {
				this.setState({ addItem: undefined, addItemMode: false })
			}
		}
	}

	dataChanged = (type: string, key: any, data: storedItem) => {}

	gotURLCallback = (theUrl: string) => {
		const theItem = this.getEditingItem() as blockFeature
		theItem.addImageURL(theUrl)
	}

	getItemType() {
		return this.state.selectedItem?.levelName() || null
	}

	getSelectedItem() {
		const { selectedItem, editingItem, inEditMode, addItemMode, addItem } = this.state
		if (addItemMode) {
			return addItem
		}
		return inEditMode ? editingItem : selectedItem
	}
	getEditingItem() {
		const { editingItem, addItemMode, addItem } = this.state
		if (addItemMode) {
			return addItem
		}
		return editingItem
	}

	getSidebarWidth() {
		const { selectedItem } = this.state
		const theType = selectedItem?.levelName() || null
		switch (theType) {
			case "catchment":
			case "catchments-4":
			case "catchments-5":
			case "catchments-6":
				return "very wide"
			default:
				return "wide"
		}
	}

	renderOneOfMany = () => {
		const items = getGlobalState("addItems")?.getValue()
		const editIdx = getGlobalState("editIdx")?.getValue() ?? 0
		const itemLength = items?.length
		if (!items || editIdx > itemLength) {
			return null
		}
		return (
			<>
				<Segment inverted basic>
					<GridRow>
						<Segment inverted basic>
							<Header as="h2" inverted>
								{editIdx} of {itemLength}
							</Header>
							<Divider />
						</Segment>
					</GridRow>
				</Segment>
			</>
		)
	}

	back = () => {
		const selectedItem = this.getSelectedItem()
		const parent: storedItem | null | undefined = this.state.inEditMode ? null : selectedItem?.getParent()
		call("setLevelByItem", parent)
		this.scrollToTop()
	}

	scrollToTop = () => this.sidebar.current?.scroll({ top: 0 })

	changeDefaultActiveView = (defaultActiveView: number) => {
		this.setState({ defaultActiveView })
	}

	renderInfoLayer = () => {
		const { inEditMode, addItemMode, editingItem, selectedItem, addItem } = this.state

		let theType = selectedItem?.levelName() || null

		const editMode = inEditMode || addItemMode
		printData(editMode, messageLevels.debug, "editMode in renderInfoLayer")
		printData(addItem, messageLevels.debug, "addItem in renderInfoLayer")
		if (addItemMode) {
			theType = addItem?.levelName() || null
		}
		const theEditItem = addItem || editingItem

		if (!selectedItem || !isStoredItem(selectedItem)) {
			return <DefaultSidebar />
		}
		const theItem = this.state.selectedItem
		printData(theEditItem, messageLevels.debug, "editItem in render")
		printData(theItem, messageLevels.debug, "theItem in render")
		if (!theType) {
			return null
		}
		switch (theType) {
			case "forest-blocks":
				return (
					<BlockInfoView
						selectedItem={theItem as forestBlock}
						changeDefaultActiveView={this.changeDefaultActiveView}
						defaultActiveView={this.state.defaultActiveView}
					/>
				)
			default:
				return (
					<OtherView
						inEdit={editMode}
						editingItem={theEditItem}
						selectedItem={theItem}
						setValue={this.setValue}
						setDirty={this.setDirty}
					/>
				)
		}
	}

	renderBackButton = () => {
		if (this.state.addItemMode) {
			return null
		}
		const selectedItem = this.getSelectedItem()
		if (selectedItem && isStoredItem(selectedItem)) {
			const parent: storedItem | null | undefined = this.state.inEditMode ? null : selectedItem?.getParent()
			if (!parent) return null
			if (parent === selectedItem) return null
			return (
				<Button className="back-btn" onClick={this.back}>
					<Icon name="chevron left" />
					{parent.displayName()}
				</Button>
			)
		}
		return null
	}

	renderEditButton = () => {
		const selectedItem = this.getSelectedItem()

		if (selectedItem && isStoredItem(selectedItem) && !this.state.inEditMode && !this.state.addItemMode) {
			const theItem = selectedItem as storedItem
			const isEditable = theItem.checkUpdateAuthority(undefined, false)
			printData(isEditable, messageLevels.debug, "isEditable")
			if (isEditable) {
				return (
					<Button className="edit-btn" onClick={this.setEditMode}>
						Edit
					</Button>
				)
			}
		} else if (selectedItem && isStoredItem(selectedItem) && (this.state.inEditMode || this.state.addItemMode)) {
			return null
		}
		return null
	}

	saveItem = () => {
		const theItem: storedItem | null | undefined = this.getEditingItem()
		printData(theItem, messageLevels.debug, "theItem im saveItem")
		if (theItem) {
			printMessage("about to keep changes", messageLevels.debug)
			// Pull back the linked AOC or AOF
			const featuresToEdit = getGlobalState("featuresToEdit")?.getValue() ?? []
			const editIdx = getGlobalState("editIdx")?.getValue() ?? 0
			if (featuresToEdit.length > 0 && editIdx > 0) {
				const editedFeature = featuresToEdit[editIdx - 1] as storedItem
				editedFeature.setValue(theItem.getValue("uuid"), "converted_task_id")
				editedFeature.keepChanges()
			}
			const theOriginal = theItem.keepChanges(this.keepItemCallback)
			printData(theOriginal, messageLevels.debug, "storedItem in saveItem")
			this.back()
		}
	}

	keepItemCallback = (theItem: storedItem) => {
		printData(theItem, messageLevels.verbose, "theItem im keepItemCallback")

		save()
		if (this.state.addItem) {
			this.setState({
				isDirty: false,
				selectedItem: theItem,
				editingItem: null,
				addItem: null,
			})
		} else {
			this.setState({
				isDirty: false,
				editingItem: null,
				addItem: null,
			})
		}

		this.endEditMode()
		printData(this.state, messageLevels.debug, "state in infoLayer")
		if (!this.state.addItemMode) {
			printData(theItem, messageLevels.debug, "selecting Item:")
			theItem.select()
		} else {
			printData(theItem, messageLevels.debug, "theItem after edit mode when added")
		}
	}

	clearLinkedFeatures = (theItem: storedItem) => {
		// A task could be linked to either AOC or AOF but we don't now which one in advance
		;["areas-of-concern", "areas-of-failure"].forEach((sourceLink) => {
			const linkedFeature = theItem.getRelatedItem(sourceLink, theItem.getSingleValue("uuid"), "converted_task_id", "===", true)
			if (linkedFeature) {
				linkedFeature.setValue(null, "converted_task_id")
				linkedFeature.keepChanges()
			}
		})
	}

	handleCancel = () => {
		this.endEditMode()
	}

	handleDelete = () => {
		printMessage("Handle Deleted", messageLevels.debug)
		const theItem: storedItem | null | undefined = this.getEditingItem()
		if (theItem) {
			const theType = theItem?.levelName()
			if (theType == "tasks") {
				this.clearLinkedFeatures(theItem)
			}
			theItem.delete()
			theItem.keepChanges()
			save()
		}
		this.setState({
			isDirty: false,
			isValid: false,
		})
		this.endEditMode()
	}

	setValue = (value: any, forKey: string) => {
		const theItem: storedItem | null | undefined = this.getEditingItem()
		if (theItem && theItem !== undefined) {
			printMessage("setting value: " + value, messageLevels.debug)
			theItem.setValue(value, forKey)
			this.setDirty()
		}
	}

	setDirty = async () => {
		const theItem: storedItem | null | undefined = this.getEditingItem()
		printData(theItem, messageLevels.debug, "item in setDirty")
		if (theItem && theItem !== undefined) {
			const isValid = await theItem.validForSave()
			this.setState({ isDirty: theItem.isDirty(), isValid })
			if (theItem.isDirty()) {
				setGlobalState("edit-infoLayer", [theItem])
			}
		}
	}

	setEditMode = () => {
		const theEditItem = this.state.selectedItem?.editCopy()

		this.setState({
			inEditMode: true,
			editingItem: theEditItem,
		})
	}

	endEditMode = () => {
		const { inEditMode, addItemMode } = this.state
		let newItem = null
		const addItems = getGlobalState("addItems")?.getValue() ?? []
		const editIdx = getGlobalState("editIdx")?.getValue() ?? 0
		const featuresToEdit = getGlobalState("featuresToEdit")?.getValue() ?? []
		if (inEditMode || addItemMode) {
			if (addItems.length > 0 && editIdx <= addItems.length) {
				newItem = addItems[editIdx]
			}
			const isDirty = getGlobalState("isDirty")?.getValue() ?? false
			this.setState({
				inEditMode: false,
				addItemMode: addItemMode,
				editingItem: null,
				addItem: null,
				isDirty: isDirty,
				isValid: newItem?.validForSave() ?? false,
			})
			setGlobalState("addItem", newItem ? [newItem] : null)
			setGlobalState("edit-infoLayer", [])
			setGlobalState("editIdx", editIdx + 1)
		}
		this.scrollToTop()
	}

	setMinimize = (isMinimized: boolean) => () => {
		this.setState({ isMinimized })
		setGlobalState("InfoMinimized", isMinimized)
	}

	renderChangeButtons() {
		// let canSave = false;
		let canDelete = false
		// let saveEnable = false;
		if (!this.state.inEditMode && !this.state.addItemMode) {
			return null
		}
		if (this.state.addItemMode) {
			canDelete = false
		} else {
			canDelete = true
			canDelete = this.getEditingItem()?.checkDeleteAuthority() || false
		}
		return (
			<>
				<Segment inverted basic>
					<GridRow>
						{this.renderSaveButton()}
						{this.renderCancelButton()}
						{canDelete && this.renderDeleteButton()}
					</GridRow>
				</Segment>
			</>
		)
	}
	renderSaveButton() {
		const isDirty = this.state.isDirty
		const isValid = this.state.isValid
		const canSave = isDirty && isValid
		return (
			<Button
				color={!canSave ? "grey" : "green"}
				disabled={!canSave}
				icon={"save"}
				onClick={this.saveItem}
				content="Save"
				style={{ width: "48%" }}
			/>
		)
	}
	renderCancelButton() {
		const isDirty = this.state.isDirty
		return (
			<Button
				color={isDirty ? "black" : "grey"}
				icon={"cancel"}
				onClick={this.handleCancel}
				content="Cancel"
				style={{ width: "48%" }}
			/>
		)
	}

	renderDeleteButton() {
		return (
			<Button color={"red"} icon={"delete"} onClick={this.handleDelete} content="Delete" style={{ width: "98%", marginTop: "4px" }} />
		)
	}

	render() {
		const { isMinimized } = this.state
		const { minimize } = this.props
		const minimized = isMinimized || minimize
		const { backgroundColor } = getUIConfig("defaultSidebar")
		const { backgroundColor: sidebarColor } = getUIConfig("navBar")
		const { brandColor } = getUIConfig("clientUI")
		return (
			<>
				<Ref innerRef={this.sidebar}>
					<Sidebar style={{ backgroundColor }} id="info" animation="overlay" width={this.getSidebarWidth()} visible={!minimized}>
						<div style={{ marginTop: "10px" }}>
							<Grid verticalAlign={"top"}>
								<GridRow columns={2}>
									<GridColumn width={10} textAlign={"left"}>
										{this.renderBackButton()}
									</GridColumn>
									<GridColumn width={2} textAlign={"right"}>
										{this.renderEditButton()}
									</GridColumn>
								</GridRow>
							</Grid>
						</div>
						<div style={{ position: "relative" }}>
							{this.renderOneOfMany()}
							{this.renderInfoLayer()}
							<div className="minimized-info-container" style={minimized ? { display: "none" } : {}}>
								<div
									className="minimized-info minimized-info-expanded"
									onClick={this.setMinimize(true)}
									style={{ backgroundColor: lightenDarkenColor(backgroundColor, 20, "darken") }}
								>
									<Icon name="angle left" size="large" inverted style={{ brandColor }} />
								</div>
							</div>
						</div>
						{this.renderChangeButtons()}
					</Sidebar>
				</Ref>
				<div
					style={!minimized ? { display: "none" } : { backgroundColor: sidebarColor }}
					className="minimized-info minimized-info-collapsed"
					onClick={this.setMinimize(false)}
				>
					<Icon name="angle right" size="large" inverted />
				</div>
			</>
		)
	}
}
