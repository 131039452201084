import { storedItem } from "../storedItem"
import { CallbackHandler } from "../storeType"

import { SelectionItemList, selectionItem } from "./SelectionList"
import { printMessage } from "../../providers/remoteHQ"
import { messageLevels } from "../types"

export class SelectionEditList extends SelectionItemList {
	addItem(newItem: storedItem) {
		if (newItem.isEditCopy) {
			const item: selectionItem = new selectionItem(newItem, this.items.length, this.selectionDefault ?? false)
			this.items.push(item)
		} else {
			const theCopy = newItem.editCopy()
			if (theCopy) {
				const item: selectionItem = new selectionItem(theCopy, this.items.length, this.selectionDefault ?? false)
				this.items.push(item)
			}
		}
		return this
	}

	keepChangesOnSelected = (): storedItem[] => {
		const itemArray: storedItem[] = []
		this.items.forEach((element) => {
			if (element.selected) {
				const theNewItem = element.item.keepChanges()
				if (theNewItem) {
					element.item = theNewItem
				}
				itemArray.push(element.item)
			}
		})
		this.callbacks.doEventCallbacks("", null)
		return itemArray
	}

	keepChangesOnAll = (): storedItem[] => {
		const itemArray: storedItem[] = []
		this.items.forEach((element) => {
			const theNewItem = element.item.keepChanges()
			if (theNewItem) {
				element.item = theNewItem
			}
			itemArray.push(element.item)
		})
		this.callbacks.doEventCallbacks("", null)
		return itemArray
	}

	keepChangesOnSelectedMaintainEdit = (): storedItem[] => {
		const itemArray: storedItem[] = []
		this.items.forEach((element) => {
			if (element.selected) {
				const theNewItem = element.item.keepChanges()
				if (theNewItem) {
					const editItem = theNewItem.editCopy()
					if (editItem && theNewItem) {
						element.item = editItem
					} else {
						element.item = theNewItem
					}
				}
				itemArray.push(element.item)
			}
		})
		this.callbacks.doEventCallbacks("", null)
		return itemArray
	}

	keepChangesOnAllMaintainEdit = (): storedItem[] => {
		const itemArray: storedItem[] = []
		this.items.forEach((element) => {
			const theNewItem = element.item.keepChanges()
			if (theNewItem) {
				const editItem = theNewItem.editCopy()
				if (editItem && theNewItem) {
					element.item = editItem
				} else {
					element.item = theNewItem
				}
			}
			itemArray.push(element.item)
		})
		this.callbacks.doEventCallbacks("", null)
		return itemArray
	}
}
