import React from "react"
import { Menu } from "semantic-ui-react"

import { getStoreTypeByName, registerOnChange, registerforStateChanges, removeOnChange, removeStateChanges } from "../store"
import { storedItem } from "../store/storedItem"
import { printData, printMessage } from "../providers/remoteHQ"
import { IManagedItemDict, messageLevels } from "../store/types"
import { IQueryParams } from "../store/types"

interface StoreListComponentProps {
	dataSourceName: string
	queryParams?: IQueryParams
	header?: string
	cellFormatter?: Function
	allowAllItems?: boolean
	nowrap?: boolean
}

interface StoreListComponentState {
	listKeys: string
	regions: string[]
}
const emptyStorage: IManagedItemDict = {}
export default class StoreListComponent extends React.Component<StoreListComponentProps, StoreListComponentState> {
	state = {
		listKeys: "",
		regions: [],
	}
	data = emptyStorage
	dataSource = getStoreTypeByName(this.props.dataSourceName)
	cellFormatterInUse: Function = this.props.cellFormatter || (() => {})

	dataLoaded = (type: string) => {
		if (type === this.props.dataSourceName) {
			this.setState({ listKeys: "" })
			this.getData()
		}
	}
	stateChanged = (type: string, key?: string, data?: storedItem) => {
		if (key === "regions") {
			if (data) {
				// printData(data, messageLevels.error, "Region update in listcomponent")
				this.setState({ regions: data.getValue() }, () => {
					this.getData()
				})
			}
		}
	}

	componentDidMount() {
		if (!this.props.cellFormatter) {
			this.cellFormatterInUse = this.defaultCellFormatter
		}

		// const theDataSource = getStoreTypeByName(this.props.dataSourceName || "forest-blocks", true);
		// this.setState({ dataSource: theDataSource}, () => this.getData())
		// this.dataSource?.requestLoad(this.dataLoaded);
		registerOnChange(this.props.dataSourceName, this.dataLoaded)
		registerforStateChanges(this.stateChanged)
	}
	componentDidUpdate(prevProps: any) {
		if (prevProps.dataSourceName !== this.props.dataSourceName) {
			removeOnChange(prevProps.dataSourceName, this.dataLoaded)
			const newDataSource = getStoreTypeByName(this.props.dataSourceName, false)
			this.data = emptyStorage
			registerOnChange(this.props.dataSourceName, this.dataLoaded)
			// newDataSource?.requestLoad(this.dataLoaded);
			this.dataSource = newDataSource
			this.setState({ listKeys: "" })
			this.getData()
		}
	}

	componentWillUnmount() {
		removeStateChanges(this.stateChanged)
	}

	getData = () => {
		// let theItems:storedItem[] = [];
		// let sortField = this.state.sortBy;
		printMessage("getData", messageLevels.debug)
		const theDataSource = this.dataSource
		if (theDataSource && theDataSource !== undefined) {
			printData(theDataSource, messageLevels.debug, "theDataSource in getData")
			const theData: IManagedItemDict = theDataSource.managedItemStorage
			// if(this.props.allowAllItems) {
			//   theData = theDataSource.allMatchingItems(this.props.queryParams || {});
			// } else {
			//   theData = theDataSource.matchingItems(this.props.queryParams || {});
			// }

			printData(theData, messageLevels.debug, "theData in getData")

			const dataCopy = Object.keys(theData)
				.map((element) => element.toString())
				.join(",")
			if (dataCopy && this.state.listKeys !== dataCopy) {
				this.data = theData || {}
				this.setState({ listKeys: "" })
				// this.data = theData;
				// printData(theData,messageLevels.none);
				// let updateState = !this.state.update;
				// this.setState({update: updateState})
			}

			// this.setState({data: theData});
		}
	}

	defaultCellFormatter = (item: storedItem) => {
		return (
			<Menu.Item>
				Primary Key: {item.primaryKey()}
				<br />
				Region: {item.getRegionISO()}
				<br />
				Name: {item.getValue("forest_name")}
				<br />
			</Menu.Item>
		)
	}
	renderItem = (item: storedItem) => {
		return this.cellFormatterInUse(item)
	}
	renderHeader = () => {
		if (this.props.header && this.data) {
			return <header>{this.props.header}</header>
		}
	}
	renderItems = (items: IManagedItemDict) => {
		return (
			<>
				{Object.keys(items).map((key) => {
					const item = items[key]

					return this.cellFormatterInUse(item, key)
				})}
			</>
		)
	}

	render() {
		// printMessage("rendering List",messageLevels.none);
		// printData(this.data,messageLevels.none)
		if (this.props.nowrap) {
			return <>{this.renderItems(this.data || [])}</>
		} else
			return (
				<>
					<Menu size="large" vertical fluid={true} borderless={true} inverted={true} className="with-labels">
						{this.renderHeader()}
						{this.renderItems(this.data || [])}
					</Menu>
				</>
			)
	}
}
