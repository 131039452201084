import React from "react"
import { Menu } from "semantic-ui-react"

// import { getStoreTypeByName, registerforStateChanges, removeStateChanges, requestLoad } from '../store';

import { storedItem } from "../storedItem"
import ArrayListComponent from "../../mapsight/ArrayListComponent"
import { getStateValue, requestLoad, removeOnRetrieve } from ".."
import { printData, printMessage } from "../../providers/remoteHQ"
import { messageLevels } from "../types"
import { storeType } from "../storeType"

interface RelationListComponentProps {
	item: storedItem
	relationship: string
	type: string
	header?: string
	cellFormatter?: Function
	options?: { [id: string]: any }
	noWrapper?: boolean
	notLoadedMessage?: string
	noDataMessage?: string
}
interface RelationListComponentState {
	data: storedItem[] | null
}
const emptyStoredItemList: storedItem[] = []

export default class RelationListComponent extends React.Component<RelationListComponentProps, RelationListComponentState> {
	state = {
		data: null,
	}

	componentDidMount() {
		this.registerForNewType()
		this.getData()
	}
	componentDidUpdate(oldProps: RelationListComponentProps) {
		if (this.props.type !== oldProps.type) {
			this.unregisterForType(oldProps.type)
			this.registerForNewType()
		}
		if (this.props.item !== oldProps.item) {
			this.getData()
		}
	}

	componentWillUnmount() {
		this.unregisterForType(this.props.type)
	}

	registerForNewType = () => {
		if (this.props.type) {
			const theType = requestLoad(this.props.type, undefined, this.dataChanged)
		}
	}

	unregisterForType = (type: string) => {
		if (type) {
			removeOnRetrieve(type, this.dataChanged)
		}
	}

	dataChanged = (type: string, key: any, data: any) => {
		printMessage("data changed", messageLevels.debug)
		this.getData()
	}
	getData = () => {
		const { item, relationship } = this.props

		if (item) {
			const theData = item.getValue(relationship)

			this.setState({ data: theData || [] })
		} else {
			this.setState({ data: [] })
		}
	}

	render() {
		const { header, cellFormatter, options, noWrapper, noDataMessage, notLoadedMessage } = this.props

		const theMissingMessage = this.state.data ? noDataMessage : notLoadedMessage

		return (
			<>
				<ArrayListComponent
					data={this.state.data || []}
					header={header}
					cellFormatter={cellFormatter}
					options={options}
					noWrapper={noWrapper}
					noDataMessage={theMissingMessage}
				/>
			</>
		)
	}
}
