import { useEffect } from "react"

import { isNgatiApa } from "../store"

const Favicon: React.FC = () => {
	useEffect(() => {
		const favicon = isNgatiApa() ? "ngati-apa-fav.ico" : "remotehq-fav.ico"
		const link: HTMLLinkElement = document.querySelector("link[rel~='icon']") || document.createElement("link")

		if (!(link instanceof HTMLLinkElement)) {
			return
		}

		link.href = favicon
		link.rel = "shortcut icon"
		document.title = isNgatiApa() ? "Ngāti Apa ki te Rā Tō" : "RemoteHQ"
		document.head.appendChild(link)
	}, [])

	return null
}

export default Favicon
