import React from "react"

import LoginButton from "../Login/LoginButton"
import NotificationButton from "../NotificationButton"

import "./styles.css"
import { ShareButton } from "../ShareButton"

interface UserPanelProps {
	hidden: boolean
}

export default class UserPanel extends React.Component<UserPanelProps, {}> {
	render() {
		return (
			<div id="user-panel" style={{ visibility: this.props.hidden ? "hidden" : "visible" }}>
				<ShareButton />
				<NotificationButton />
				<LoginButton />
			</div>
		)
	}
}
