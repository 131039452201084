import React from "react"
import { Accordion, Icon, Menu } from "semantic-ui-react"

import { storedItem } from "../store/storedItem"
import { printData } from "../providers/remoteHQ"
import { IQueryParams, messageLevels } from "../store/types"
import { getDataFeedByName } from "../store/dataFeed"
import { registerforStateChanges, removeStateChanges } from "../store"

interface FeedListComponentProps {
	dataFeedName: string
	queryParams: IQueryParams
	header?: string
	cellFormatter?: Function
	noWrapper?: boolean
	globalHover?: boolean
}

interface FeedListComponentState {
	listKeys: string[]
	hover: any[]
	active: boolean
}

const emptyStoredArray: storedItem[] = []
const emptyArray: any[] = []
export default class FeedListComponent extends React.Component<FeedListComponentProps, FeedListComponentState> {
	state = {
		listKeys: [],
		hover: emptyArray,
		active: true,
	}
	dataFeed = getDataFeedByName(this.props.dataFeedName || "forest-blocks", true)
	data = emptyStoredArray

	dataLoaded = (type: string, key?: string, data?: storedItem[]) => {
		// this.setState({listKeys: ""});
		if (key === (this.props.dataFeedName || "forest-blocks")) {
			// if (key === "forest-ownership") {
			// 	printData(data, messageLevels.verbose);
			// }
			this.data = data || emptyStoredArray
			const theKeys = primaryKeys(this.data)

			this.setState({ listKeys: theKeys })
		}
	}
	stateChanged = (type: string, key?: string, data?: storedItem) => {
		if (this.props.globalHover && key === "hovers") {
			const value: any[] | null = data?.getValue()
			const theListKeys: string[] = this.state.listKeys
			if (value && Array.isArray(value)) {
				const theItems: any[] = []

				const theDataSourceName = this.props.dataFeedName

				value.forEach((item) => {
					if (item.getTypeName() === theDataSourceName) {
						const theKey: string = item.primaryKey().toString()

						if (theListKeys.includes(theKey)) {
							theItems.push(theKey)
						}
					}
				})

				if (theItems.length > 0) {
					this.setState({ hover: theItems })
				} else {
					if (this.state.hover && this.state.hover.length > 0) {
						this.setState({ hover: emptyArray })
					}
				}
			} else {
				if (this.state.hover && this.state.hover.length > 0) {
					this.setState({ hover: emptyArray })
				}
			}
		}
	}
	componentDidMount() {
		// const theDataSource = getStoreTypeByName(this.props.dataSourceName || "forest-blocks", true);
		// this.setState({ dataSource: theDataSource}, () => this.getData())
		this.dataFeed.registerOnChange(this.dataLoaded, this.props.queryParams)
		registerforStateChanges(this.stateChanged)
	}

	componentWillUnmount() {
		this.dataFeed.removeOnChange(this.dataLoaded)
		removeStateChanges(this.stateChanged)
	}

	renderItem = (item: storedItem, index: any) => {
		const hovers: any[] = this.state.hover

		const hovered = hovers.includes(item.primaryKey())

		if (this.props.cellFormatter) {
			return this.props.cellFormatter(item, index, hovered)
		}

		return (
			<>
				<Menu.Item key={item.primaryKeySafe()}>
					Primary Key: {item.primaryKey()}
					<br />
					Region: {item.getRegionISO()}
					<br />
					Name: {item.getValue("name")}
					<br />
				</Menu.Item>
			</>
		)
	}

	renderItems = (items: storedItem[]) => {
		return items.map((item, index) => this.renderItem(item, index))
	}

	renderHeader = () => {
		if (this.props.header && this.data && this.data.length > 0) {
			return <header>{this.props.header}</header>
		}
	}
	render() {
		// printMessage("rendering List",messageLevels.none);
		// printData(this.data,messageLevels.none)
		if (this.data.length > 0) {
			if (this.props.noWrapper) {
				return (
					<>
						{this.renderHeader()}
						{this.renderItems(this.data || [])}
					</>
				)
			}
			return (
				<Menu size="large" vertical fluid={true} borderless={true} inverted={true} className="with-labels">
					<Accordion inverted>
						<Accordion.Title
							active={this.state.active}
							onClick={() => this.setState({ active: !this.state.active })}
							style={{ display: "flex", alignItems: "center" }}
						>
							{this.renderHeader()}
							<Icon name="dropdown" />
						</Accordion.Title>
						<Accordion.Content active={this.state.active}>{this.renderItems(this.data || [])}</Accordion.Content>
					</Accordion>
				</Menu>
			)
		} else {
			return null
		}
	}
}
export function primaryKeys(theData: storedItem[]): string[] {
	const theKeys: string[] = []
	theData.forEach((element) => {
		theKeys.push(element.primaryKey())
	})
	return theKeys
}
// function copyArray(array1: storedItem[] ):storedItem[] {
//   let newArray: storedItem[] = [];
//   array1.forEach(element => {
//       newArray.push(element);

//   });
//   return newArray;
// }
