import { getGlobalState, instanceNewItem } from "."
import { fileUpload } from "./classes/fileUpload"

export interface IDocument {
	name: string
	fileData?: Uint8Array
	fileType?: string
	location?: string
}

export const newFileUploader = (theFile?: File, theData?: any): fileUpload => {
	// file_type, filename, destination_path, is_uploaded, created_date, modified_date, file_created_date, file_modified_date, region, forest_block, metadata, upload_user, upload_email, upload_url
	const theUploadItem = instanceNewItem("file-upload") as fileUpload
	theUploadItem.setValue(theUploadItem.getValue("_primaryKey"), "uuid")
	const theRegions = getGlobalState("regions")?.getValue()
	if (theRegions && theRegions.length === 1) {
		theUploadItem.setValue(theRegions[0], "region")
	}

	if (theRegions && theRegions.length === 1) {
		theUploadItem.setValue(theRegions[0], "region")
	}

	if (theFile) {
		theUploadItem.file = theFile
	}

	if (theData) {
		theUploadItem.data = theData
	}

	theUploadItem.store()
	return theUploadItem
}

export const newFileUploaderFromURI = (theURI?: any, filename?: string, modifiedDate?: Date): fileUpload => {
	// file_type, filename, destination_path, is_uploaded, created_date, modified_date, file_created_date, file_modified_date, region, forest_block, metadata, upload_user, upload_email, upload_url
	const theUploadItem = instanceNewItem("file-upload") as fileUpload
	theUploadItem.setValue(theUploadItem.getValue("_primaryKey"), "uuid")
	const theRegions = getGlobalState("regions")?.getValue()
	if (theRegions && theRegions.length === 1) {
		theUploadItem.setValue(theRegions[0], "region")
	}

	if (theRegions && theRegions.length === 1) {
		theUploadItem.setValue(theRegions[0], "region")
	}

	if (theURI) {
		theUploadItem.dataURI = theURI
	}
	if (filename) {
		theUploadItem.setValue(filename, "filename")
	}
	if (modifiedDate) {
		theUploadItem.setValue(modifiedDate, "file_modified_date")
	}

	theUploadItem.store()
	return theUploadItem
}

export const newFileUploaderFromImageListItem = (theItem?: any): fileUpload => {
	// file_type, filename, destination_path, is_uploaded, created_date, modified_date, file_created_date, file_modified_date, region, forest_block, metadata, upload_user, upload_email, upload_url
	const theUploadItem = instanceNewItem("file-upload") as fileUpload
	theUploadItem.setValue(theUploadItem.getValue("_primaryKey"), "uuid")
	const theRegions = getGlobalState("regions")?.getValue()
	if (theRegions && theRegions.length === 1) {
		theUploadItem.setValue(theRegions[0], "region")
	}

	if (theRegions && theRegions.length === 1) {
		theUploadItem.setValue(theRegions[0], "region")
	}
	if (theItem) {
		theUploadItem.file = theItem
	}
	if (theItem && theItem.dataURL) {
		theUploadItem.dataURI = theItem.dataURL
	}
	theUploadItem.store()
	return theUploadItem
}

// takes array of image objects
// calls newFileUploaderFromImageListItem
// requestUploadUrl with callback that adds item to array on each returned item
// return array of url

// const uploadFile: (file: File) => Promise<any> = (file: File) => new Promise(resolve => {
//   const fileReader = new FileReader();
//   fileReader.onloadend = ({ target }) => {
//       if (target) {
//           resolve(new Uint8Array(target.result as ArrayBuffer));
//       }
//   }
//   fileReader.readAsArrayBuffer(file);
// });

// interface IUploadHandler {
//     filesUploaded: File[];
//     supportedTypes: string[];
// }
