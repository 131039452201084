import React, { useEffect, useState } from "react"
import { ActiveElement, Chart, ChartEvent, TooltipItem } from "chart.js"
import { Line } from "react-chartjs-2"
import { Icon, Popup } from "semantic-ui-react"
import "chart.js/auto"
import annotationPlugin, { AnnotationPluginOptions } from "chartjs-plugin-annotation"
import { _DeepPartialObject } from "chart.js/dist/types/utils"
import ReactDOM from "react-dom"

import { call, getGlobalState, getUIConfig, registerforStateChanges } from "../store"
import { storedItem } from "../store/storedItem"
import { ChartReadable, segmentInMeters } from "./Elevation"
import { replaceColorWithOpacity, replaceRBGAWtihNewOpacity } from "../helpers/commonFunc"
Chart.register(annotationPlugin)

interface Unit {
	label: string
	multiplier: number
	round: (length: number) => number
	shouldDisplay: (length: number) => boolean
}

interface WindowSize {
	width: number
	height: number
}

const Meter: Unit = {
	label: "m",
	multiplier: 1,
	round: (l: number) => Math.round(l),
	shouldDisplay: (l: number) => l % 10 === 0,
}

const Kilometer: Unit = {
	label: "km",
	multiplier: 1 / 1000,
	round: (l: number) => Math.round(l / 100) * 100,
	shouldDisplay: (l: number) => l % 100 === 0,
}

const getXAxisUnit = (length: number): Unit => {
	if (length < 1000) {
		return Meter
	} else {
		return Kilometer
	}
}

const ElevationModel = () => {
	const [screenSize, setScreenSize] = useState<WindowSize>({
		width: window.innerWidth,
		height: window.innerHeight,
	})
	useEffect(() => {
		registerforStateChanges(dataChanged)
		const updateDimension = () => setScreenSize(getCurrentDimension())
		window.addEventListener("resize", updateDimension)
		return () => {
			window.removeEventListener("resize", updateDimension)
		}
	}, [])

	const [profile, setProfile] = useState<ChartReadable[] | undefined>()
	const [isMinimized, setIsMinimized] = useState(false)
	const [unit, setUnit] = useState<Unit>(Kilometer)
	const [positions, setPosition] = useState<(number | null)[][]>([])

	const getCurrentDimension = () => {
		return {
			width: window.innerWidth,
			height: window.innerHeight,
		}
	}

	const animation = (length: number) => (length < 3000 ? { animations: { tension: { easing: "linear" } } } : { animation: false })

	const dataChanged = (type: string, key: string, data: storedItem) => {
		if (key === "selectedDraw") {
			const profile = call("getElevationProfile", data.getValue())
			setProfile(profile)
			if (!profile || profile.length == 0) return
			setUnit(getXAxisUnit(profile.length))
			setPosition([Object.values(profile[0]), Object.values(profile[profile.length - 1])])
		}
	}

	const handlePointClick = (e: ChartEvent, ele: ActiveElement[], chart: Chart) => {
		let _positions = [...positions]
		if (_positions.length === 2) _positions = []
		const x = chart.tooltip?.dataPoints[0].parsed.x || 0
		const y = chart.tooltip?.dataPoints[0].parsed.y || 0
		_positions.push([x, y])
		setPosition(_positions)
	}

	const getAnnotation = (): _DeepPartialObject<AnnotationPluginOptions> | undefined => {
		const _positions = [...positions]
		if (positions.length === 0) return
		if (positions.length < 2) {
			return {
				annotations: [
					{
						type: "point",
						xValue: _positions[0][0] || 0,
						yValue: _positions[0][1] || 0,
						backgroundColor: "yellow",
					},
				],
			}
		}
		const distance = Math.abs(_positions[1][0]! - _positions[0][0]!)
		const elevation = Math.abs(_positions[1][1]! - _positions[0][1]!)
		const degree = (Math.atan2(elevation, distance) * 180) / Math.PI
		return {
			annotations: [
				{
					type: "line",
					borderColor: "yellow",
					borderWidth: 3,
					xScaleID: "x",
					yScaleID: "y",
					label: {
						content: `${Math.round(degree * 10) / 10}°`,
						display: true,
						color: "yellow",
					},
					xMin: _positions?.[0]?.[0] || 0,
					xMax: _positions?.[1]?.[0] || 0,
					yMin: _positions?.[0]?.[1] || 0,
					yMax: _positions?.[1]?.[1] || 0,
				},
			],
		}
	}

	const getModelWidth = () => {
		const InfoMinimized = getGlobalState("InfoMinimized")?.getValue()
		if (!screenSize) return 0
		if (InfoMinimized) {
			return screenSize?.width - 58
		} else {
			return screenSize?.width - 350 - 58
		}
	}

	if (!profile) return null

	const { backgroundColor = "#000", color = "#fff" } = getUIConfig("clientUI") || {}

	const colorList = {
		background07: replaceColorWithOpacity(backgroundColor, 0.7),
		background03: replaceColorWithOpacity(backgroundColor, 0.3),
		background09: replaceColorWithOpacity(backgroundColor, 0.9),
		color07: replaceRBGAWtihNewOpacity(color, 0.7),
		color03: replaceRBGAWtihNewOpacity(color, 0.3),
	}

	return (
		<>
			{ReactDOM.createPortal(
				<div className="chart-btn" onClick={() => setIsMinimized(!isMinimized)}>
					<Icon name="chart area" size="large" />
				</div>,
				document.body,
			)}
			<div
				className="elevation-model"
				style={{ width: getModelWidth(), display: isMinimized ? "none" : "flex", backgroundColor: colorList.background09, color }}
			>
				<Icon name="window minimize" size="small" style={{ color }} onClick={() => setIsMinimized(true)} />
				<Line
					data={{
						labels: profile.map(() => ""),
						datasets: [
							{
								data: profile,
								borderColor: colorList.color07,
								backgroundColor: colorList.color07,
							},
						],
					}}
					options={{
						...(animation(profile.length) as {}),
						normalized: true,
						parsing: false,
						interaction: {
							intersect: false,
							mode: "nearest",
							axis: "x",
						},
						aspectRatio: getModelWidth() / (screenSize.height / 3),
						plugins: {
							decimation: {
								enabled: true,
								algorithm: "lttb",
								samples: 100,
							},
							title: {
								display: true,
								text: "Elevation Profile (Beta)",
								align: "center",
								color: colorList.color07,
							},
							legend: {
								display: false,
							},
							tooltip: {
								displayColors: false,
								callbacks: {
									label: (data: TooltipItem<"line">) =>
										`Elevation: ${Math.round(Number(data.formattedValue)).toLocaleString("en-US")} m`,
									afterLabel: (data: TooltipItem<"line">) => {
										const position = (data.parsed.x * segmentInMeters).toLocaleString("en-US")
										return `Position: ${position} m`
									},
								},
								backgroundColor: colorList.background09,
								bodyColor: color,
							},
							annotation: getAnnotation(),
						},
						scales: {
							x: {
								ticks: {
									color: colorList.color07,
									callback: function (val, index) {
										const position = unit.round(index * segmentInMeters)
										return unit.shouldDisplay(position)
											? `${(position * unit.multiplier).toLocaleString("en-US")} ${unit.label}  `
											: ""
									},
									display: true,
									maxRotation: 0,
									autoSkip: true,
								},
								grid: {
									display: false,
								},
								title: {
									text: "Distance",
									display: true,
									color: colorList.color07,
								},
							},
							y: {
								ticks: {
									color: colorList.color07,
								},
								grid: {
									color: colorList.color03,
								},
								title: {
									text: "Elevation (m)",
									display: true,
									color: colorList.color07,
								},
							},
						},
						elements: {
							point: {
								radius: 0,
							},
						},
						onClick: handlePointClick,
					}}
				/>
				<Popup
					trigger={<Icon name="info circle" style={{ color }} size="small" />}
					inverted
					size="small"
					position="top right"
					onHide={() => setIsMinimized(false)}
					wide="very"
				>
					<Popup.Content>
						The line measure tool allows users to measure the distance between two points on the map, along with providing
						information on elevation and slope along that line.
						<br />
						<br />
						When using the line measure tool, users can select two points on the map by clicking on the desired locations. The
						tool will then calculate the distance between those points. This tool provides a straight-line measurement, also
						known as the "as-the-crow-flies" distance, rather than taking into account the terrain or any curved paths that may
						exist. Additionally, the tool provides elevation data, showing the change in height between the two points.
						Furthermore, it calculates the average slope along the line (shown in yellow), giving users an indication of how
						steep or gradual the terrain is. The user can also select two points along the elevation profile line to calculate a
						new slope angle.
						<br />
						<br />
						Disclaimer: Please note that the distance, elevation, and slope measurements provided by the line measure tool are
						based on available data and calculations (provided by Mapbox from various open data sources such as OpenStreetMap
						and NASA). While efforts are made to ensure accuracy, these measurements may have limitations or inaccuracies due to
						the quality and resolution of the underlying maps and elevation data. GeoInsight is not liable for the tool's
						accuracy or consequences of its use. Users accept any risks associated with its application and should consult
						professionals for critical decisions.
					</Popup.Content>
				</Popup>
			</div>
		</>
	)
}

export default ElevationModel
