import React from "react"
import { Divider, Icon, Item, Label, Segment } from "semantic-ui-react"

import ImagePopup from "../components/ImagePopup"
import { mediaItem } from "../store/classes/mediaItem"
import { printData, printMessage } from "../providers/remoteHQ"
import { messageLevels } from "../store/types"
import { fileUpload } from "../store/classes/fileUpload"

import "./CollectionImage.css"
import { UUIDforMediaCollectionURL, imageItemFromUUID } from "../store/URLRewrites"

interface CollectionImageProps {
	setValue?: any
	removeImage?: any
	imageLink: any
	edit?: boolean
	hidePlaceholder?: boolean
}

interface CollectionImageState {
	changed: boolean
	imageItem?: mediaItem | null
	uploading: boolean
	finalURL?: string | Buffer | { data: Buffer; format: "png" | "jpg" } | null
	theUUID?: string
}

export default class CollectionImage extends React.Component<CollectionImageProps, CollectionImageState> {
	state: CollectionImageState = {
		changed: false,
		uploading: false,
	}

	componentDidMount() {
		if (!this.props.imageLink || this.props.imageLink === undefined) {
			return null
		}
		this.readImageDetails()
	}

	readImageDetails() {
		printData(this.props.imageLink, messageLevels.debug, "imageLink")
		const imageUUID = UUIDforMediaCollectionURL(this.props.imageLink)

		const isImageUUID = imageUUID !== ""

		if (isImageUUID) {
			const theItem = imageItemFromUUID(imageUUID)

			if (theItem) {
				const uploading = this.imageIsUploading(theItem)
				let theURL: string | Buffer | { data: Buffer; format: "png" | "jpg" } | null = null
				if (theItem) {
					theURL = theItem.URL
				}
				if (this.state.imageItem && theItem !== this.state.imageItem) {
					;(this.state.imageItem as fileUpload).removeCallbackOnUpload(this.uploadCompleted)
				}
				if (theItem && theItem !== this.state.imageItem) {
					;(theItem as fileUpload).addCallbackOnUpload(this.uploadCompleted)
				}
				this.setState({
					imageItem: theItem,
					uploading: uploading,
					finalURL: theURL,
					theUUID: imageUUID,
				})
			}
		} else {
			this.setState({
				imageItem: null,
				uploading: false,
				finalURL: this.props.imageLink,
				theUUID: undefined,
			})
		}
	}

	uploadCompleted = () => {
		printMessage("upload complete callback", messageLevels.debug)
		this.readImageDetails()
	}

	componentDidUpdate(prevProps: CollectionImageProps) {
		if (prevProps.imageLink !== this.props.imageLink) {
			this.readImageDetails()
		}
	}
	componentWillUnmount() {
		if (this.state.imageItem && this.state.imageItem.getTypeName() === "file-upload") {
			;(this.state.imageItem as fileUpload).removeCallbackOnUpload(this.uploadCompleted)
		}
	}

	getURL() {
		if (this.state.imageItem) {
			const theURL = this.state.imageItem.URL
			if (theURL) {
				this.setState({ finalURL: theURL })
				return theURL
			}
		}
		return null
	}

	imageIsUploading(theItem: mediaItem | null): boolean {
		if (theItem) {
			const isUploading = !theItem.isUploaded
			return isUploading
		}
		return false
	}

	onImageRemove = () => {
		const removeImage = this.props.removeImage
		if (removeImage) {
			removeImage(this.state.theUUID || this.props.imageLink)
		}
	}

	onImageUpdate = () => {}

	renderPlaceholder() {
		const edit = this.props.edit
		return (
			<div>
				<Icon name="image" size="large" />
				{edit ? "no existing images" : "No image available"}
			</div>
		)
	}
	renderDeleteControl() {
		return (
			<Item.Extra
				onClick={() => this.onImageRemove()}
				style={{
					cursor: "pointer",
					color: "whitesmoke",
				}}
				color="red"
			>
				<Icon color="red" name="delete" /> Delete
			</Item.Extra>
		)
	}

	renderFilename() {
		if (this.state.imageItem) {
			return (
				<Item.Extra
					style={{
						color: "whitesmoke",
					}}
				>
					{this.state.imageItem.displayName()}
				</Item.Extra>
			)
		}
	}

	renderFilePreview() {
		const { imageItem, finalURL } = this.state
		if (!imageItem) return null
		const fileType = imageItem.fileType()
		if (["png", "jpg", "jpeg"].some((type) => fileType?.includes(type))) {
			const imageLink = this.props.imageLink
			const _finalURL = finalURL || imageLink
			return <Item.Image size="small" src={_finalURL} style={{ objectFit: "contain" }} />
		} else if (fileType?.includes("pdf")) {
			return (
				<div style={{ marginBottom: "15px" }}>
					<Icon name="file pdf" size="big" inverted />
				</div>
			)
		} else {
			return (
				<div style={{ marginBottom: "15px" }}>
					<Icon name="file" size="big" inverted />
				</div>
			)
		}
	}

	render() {
		const imageLink = this.props.imageLink
		const hidePlaceholder = !!this.props.hidePlaceholder
		const finalURL = this.state.finalURL || imageLink
		printData(this.state, messageLevels.debug, "state")
		printData(finalURL, messageLevels.debug, "finalURL")
		if (this.props.edit) {
			return (
				<Item>
					{/* {this.renderFilePreview()} */}
					<ImagePopup src={finalURL} childClass="layer-filter-popup collection-image" />
					<Item.Content verticalAlign="middle">
						{this.renderFilename()}
						{this.props.removeImage ? this.renderDeleteControl() : null}
					</Item.Content>
					<Divider />
				</Item>
			)
		}
		if (this.state.uploading) {
			if (hidePlaceholder) {
				return null
			} else {
				return (
					<Segment className={"collection-image"}>
						<Label as="a" ribbon>
							Image saving...
						</Label>
						<ImagePopup src={finalURL} childClass="layer-filter-popup">
							{" "}
						</ImagePopup>
					</Segment>
				)
			}
		} else if (finalURL) {
			return <ImagePopup src={finalURL} childClass="layer-filter-popup collection-image" />
		} else {
			if (hidePlaceholder) {
				return null
			}
			return this.renderPlaceholder()
		}
	}
}
