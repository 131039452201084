import React from "react"
import { Button, Form, Grid, GridRow, Header, Icon, Loader, Message, Modal, Segment, Sidebar } from "semantic-ui-react"
import { PDFDownloadLink } from "@react-pdf/renderer"

import ReportPdfGenClass from "./PdfGenClass"
import "./styles.css"
import { printData, printMessage } from "../../../providers/remoteHQ"
import { messageLevels } from "../../../store/types"
import {
	instanceNewItem,
	isItemType,
	registerOnChange,
	registerOnSave,
	registerforStateChanges,
	removeOnChange,
	removeOnSave,
	removeStateChanges,
	requestLoad,
	save,
	setGlobalState,
} from "../../../store"
import { ReportItem } from "../../../store/classes/report"
import { forestBlock } from "../../../store/classes/forestBlock"
import ReportDetailEditView from "../../../store/views/Report/Detail"
import ReportSummaryView from "../../../store/views/Report/Summary"
import ReportAoesEditView from "../../../store/views/Report/AOEs"
import ReportSkidsEditView from "../../../store/views/Report/Skids"
import ReportTaskEditView from "../../../store/views/Report/Tasks"
import ReportMapsEditView from "../../../store/views/Report/Maps"
import { FormStepperComponent } from "../../../store/Forms/react/Stepper"
import { FormPageItemProps } from "../../../store/Forms/index"
import { storedItem } from "../../../store/storedItem"

interface ReportProps {
	visible: boolean
}

interface ReportState {
	step: number
	isShowError: boolean
	isExpand: boolean
	isConfirm: boolean
	isDirty: boolean
	isValid: boolean
	block: forestBlock | null
	userName: string
	selectedForestBlock: forestBlock | null
	reportItem: ReportItem | null
	pdfOutdated: boolean
	pdfChangeKey: string
	data: any
	saveButtonLabel: string
	saveButtonActive: boolean
	saveButtonEnabled: boolean
}

const steps = ["Details", "AOE", "Maps", "Tasks", "Skid sites", "Confirm"]

const detailPage: FormPageItemProps = {
	title: "Details",
	validIcon: "check",
	invalidIcon: "exclamation",
	fields: [
		{ name: "compliance_status", type: "input", mandatory: true },
		{ name: "inspection_date", type: "checkboxDateRange", mandatory: true, validator: "checkboxDateRange" },
		{ name: "contact_person", type: "input", mandatory: true },
		{ name: "earthworks_contractor", type: "input", mandatory: true },
		{ name: "harvesting_contractor", type: "input", mandatory: true },
		{ name: "status_of_work", type: "input", mandatory: true },
		{ name: "proximity_to_waterways", type: "input", mandatory: true },
	],
}

const aoePage: FormPageItemProps = {
	title: "AOE",
	fields: [],
}

const mapsPage: FormPageItemProps = {
	title: "Maps",
	validIcon: "check",
	invalidIcon: "exclamation",
	fields: [{ name: "reportBody.maps.context", type: "input", mandatory: true }],
}

const tasksPage: FormPageItemProps = {
	title: "Tasks",
	fields: [],
}

const skidsPage: FormPageItemProps = {
	title: "Skid sites",
	fields: [],
}

const confirmationPage: FormPageItemProps = {
	title: "Confirm",
	fields: [],
}

const pages = [detailPage, aoePage, mapsPage, tasksPage, skidsPage, confirmationPage]
const pageFieldsWithValidation = pages.map((page) => page.fields)
export default class Report extends React.Component<ReportProps, ReportState> {
	constructor(props: ReportProps) {
		super(props)

		this.state = {
			step: 0,
			isConfirm: false,
			isShowError: false,
			isExpand: false,
			block: null,
			userName: "",
			isDirty: false,
			isValid: false,
			selectedForestBlock: null,
			saveButtonLabel: "Save",
			saveButtonActive: false,
			saveButtonEnabled: false,
			reportItem: null,
			data: null,
			pdfOutdated: true,
			pdfChangeKey: "-1:-1:-1:-1",
		}
	}

	componentDidMount() {
		registerforStateChanges(this.stateChanged)
		registerOnChange("user-profile", this.stateChanged)
		registerOnSave("tasks", this.dataChanged)
		registerOnSave("forest-monitoring-reports", this.itemSaved)
		requestLoad("areas-of-excellence")
		requestLoad("tasks")
		this.setDirty()
	}
	// componentDidUpdate() {
	// 	printData(this.props,messageLevels.verbose,"props in report");
	// }
	componentWillUnmount() {
		removeStateChanges(this.stateChanged)
		removeOnChange("user-profile", this.stateChanged)
		removeOnSave("forest-monitoring-reports", this.itemSaved)
		removeOnSave("tasks", this.dataChanged)
	}

	itemSaved = async (type: string, key: string, data: any) => {
		if (type === "forest-monitoring-reports") {
			this.setDirty()
		}
	}

	dataChanged = (type: string, key: string, data: any) => {
		if (type === "tasks-save") {
			this.updateTaskList()
			this.setDirty()
		}
	}

	stateChanged = async (type: string, key: string, data: any) => {
		if (!this.state.isConfirm && key === "selected") {
			const theItems = data.getValue()

			let theItem = theItems
			if (Array.isArray(theItems) && theItems.length > 0) {
				theItem = theItems[0]
			}
			if (isItemType(theItem, "forest-blocks") && !this.state.isConfirm) {
				this.setState({ selectedForestBlock: theItem })
			}
		}
		if (type === "user-profile" && data) {
			let theName = data.email
			if (data.first_name && data.last_name) {
				theName = (data.first_name || "") + " " + (data.last_name || "")
			}
			this.setState({ userName: theName })
			this.endEditMode()
		}
	}

	onChangeStep = (newStep: number) => {
		if (newStep > 5 || newStep < 0) {
			return
		}

		if (newStep === 5) {
			this.setState({ step: newStep })
		} else {
			this.setState({ step: newStep })
		}
	}

	saveReport = () => {
		// generate new report item
		const theReportItem = this.state.reportItem

		if (theReportItem) {
			// this should be handled by the store() function with uuids - or should find a way to do it on instance

			const forestBlock = this.state.block
			const theComplianceStatus = theReportItem.getValue("compliance_status")

			if (forestBlock) {
				if (theComplianceStatus) {
					forestBlock.setValue(theComplianceStatus, "compliance_status")
				}
			}

			// tell item to add itself to the store's data
			theReportItem.listsToReport()
			theReportItem.fieldsToReport()
			theReportItem.uploadAll()
			theReportItem.willSave()
			theReportItem.keepChanges()

			// save the store to persistent storage (in this case the remote DB)
			save()
		}

		setGlobalState("edit-report", [])
	}

	currentSerial = (): string => {
		const theItem: ReportItem | null | undefined = this.state.reportItem

		if (theItem && theItem !== undefined) {
			return (
				theItem.changeCount.toString() +
				":" +
				theItem.aoeSelectionList?.changeCount.toString() +
				":" +
				theItem.taskSelectionList?.changeCount.toString() +
				":" +
				theItem.skidSelectionList?.changeCount.toString()
			)
		}
		return "0"
	}

	setDirty = () => {
		const theItem: ReportItem | null | undefined = this.state.reportItem
		let saveButtonLabel = ""
		let saveButtonActive = false
		let saveButtonEnabled = false
		printData(theItem, messageLevels.debug, "item in setDirty")
		if (theItem && theItem !== undefined) {
			if (theItem.isDirty() || theItem._created) {
				saveButtonLabel = "Save"
				saveButtonEnabled = theItem.validForSave()
				if (theItem.isDirty()) {
					saveButtonActive = true
				} else {
					saveButtonActive = false
				}
			} else {
				saveButtonLabel = "Saved"
			}

			const pdfChanged = this.state.pdfChangeKey !== this.currentSerial()
			printData(pdfChanged, messageLevels.debug, "pdfChanged")
			if (pdfChanged) {
				printMessage("PDF should update", messageLevels.debug)
				this.setState({ pdfOutdated: true })
			}
			this.setState({
				isDirty: theItem.isDirty(),
				isValid: theItem.validForSave(),
				saveButtonLabel,
				saveButtonActive,
				saveButtonEnabled,
				isShowError: false,
			})
			if (theItem.isDirty()) {
				setGlobalState("edit-infoLayer", [theItem])
			}
		}
	}

	endEditMode = async () => {
		this.setState({
			isConfirm: false,
			block: null,
			reportItem: null,
			step: 0,
			pdfOutdated: false,
			pdfChangeKey: "0",
			data: null,
		})

		setGlobalState("edit-report", [])
	}

	setupReportItem = () => {
		const theBlock = this.state.block
		let initialData: { [key: string]: any } = {}
		if (theBlock) {
			initialData = {
				compliance_status: theBlock?.getValue("compliance_status"),
				contact_person: theBlock.getValue("Manager"),
				earthworks_contractor: theBlock.getValue("earthworks_contractor"),
				harvesting_contractor: theBlock.getValue("harvesting_contractor"),
			}
		}
		initialData.monitoring_officer = this.state.userName
		printData(initialData, messageLevels.verbose, "InitialData")

		const theReportItem: ReportItem | null = instanceNewItem("forest-monitoring-reports", initialData) as ReportItem | null
		theReportItem?._createValidation(...pageFieldsWithValidation)
		this.setState(
			{
				reportItem: theReportItem,
			},
			() => this.setDirty(),
		)
	}

	updateTaskList = () => {
		const theReportItem = this.state.reportItem
		if (theReportItem) {
			theReportItem.updateTaskSelectionList()
		}
	}

	handleBeginClick = () => {
		const currentBlock = this.state.selectedForestBlock
		const setupParams = { block: currentBlock }
		const theReportItem: ReportItem | null = instanceNewItem("forest-monitoring-reports", setupParams) as ReportItem | null
		this.setState({ isConfirm: true, block: currentBlock, reportItem: theReportItem }, () => this.setupReportItem())

		setGlobalState("edit-report", [currentBlock])
	}

	renderForm = () => {
		const { step } = this.state

		return (
			<div style={{ flexGrow: 1 }}>
				<ReportDetailEditView hideHeading visible={step === 0} setDirty={this.setDirty} editingItem={this.state.reportItem} />
				<ReportAoesEditView hideHeading visible={step === 1} setDirty={this.setDirty} editingItem={this.state.reportItem} />
				<ReportMapsEditView hideHeading visible={step === 2} setDirty={this.setDirty} editingItem={this.state.reportItem} />
				<ReportTaskEditView hideHeading visible={step === 3} setDirty={this.setDirty} editingItem={this.state.reportItem} />
				<ReportSkidsEditView hideHeading visible={step === 4} setDirty={this.setDirty} editingItem={this.state.reportItem} />
				<ReportSummaryView hideHeading visible={step === 5} setDirty={this.setDirty} selectedItem={this.state.reportItem} />
			</div>
		)
	}
	startPDF = () => {
		printMessage("startPDF", messageLevels.debug)
		const theReportItem = this.state.reportItem
		if (this.state.isValid) {
			if (theReportItem) {
				// this should be handled by the store() function with uuids - or should find a way to do it on instance
				theReportItem.listsToReport()
				theReportItem.fieldsToReport()
				const data = { ...theReportItem.reportBody }

				this.setState({
					pdfOutdated: false,
					pdfChangeKey: this.currentSerial(),
					data: data,
				})
			}
		} else {
			this.setState({ isShowError: true })
		}
	}

	renderPDFGenerate = () => {
		return <Button onClick={() => this.startPDF()}>Create PDF</Button>
	}

	renderPDFDownload = () => {
		const { data, pdfChangeKey: pdfSerial } = this.state
		printData(data.details, messageLevels.debug, "details in renderPDFDownload")

		return (
			<PDFDownloadLink
				key={pdfSerial}
				document={<ReportPdfGenClass key={pdfSerial} data={data} />}
				fileName={this.state.reportItem?.getValue("title") || "Forest Report"}
			>
				{({ blob, url, loading, error }) => {
					return loading ? (
						<Button loading></Button>
					) : (
						// @ts-ignore
						<div className="ui active inverted button">
							<Icon name="download" />
							Report
						</div>
					)
				}}
			</PDFDownloadLink>
		)
	}

	renderPDFButtons = () => {
		// @ts-ignore
		printData(this.state.pdfOutdated, messageLevels.verbose, "pdfOutdated")
		if (this.state.pdfOutdated) {
			return this.renderPDFGenerate()
		} else {
			return this.renderPDFDownload()
		}
	}

	render() {
		const { step, isExpand, selectedForestBlock, saveButtonActive, saveButtonEnabled, saveButtonLabel } = this.state

		return (
			<Sidebar
				id="report-layer"
				animation="overlay"
				icon="labeled"
				width="very wide"
				visible={this.props.visible}
				style={isExpand ? { width: "calc(100vw - 58px)" } : {}}
			>
				{!this.state.isConfirm ? (
					<Grid textAlign="center" style={{ marginTop: "20%" }}>
						<Grid.Row>
							<Grid.Column>
								{selectedForestBlock ? (
									<h2 style={{ color: "white" }}>
										{" "}
										To start the Reporting Tool <br /> for {selectedForestBlock.displayName()}, click begin.{" "}
									</h2>
								) : (
									<h2 style={{ color: "white" }}>
										{" "}
										Please Select a <br /> forest block on the map <br />
										then click begin to start the <br /> Reporting Tool.{" "}
									</h2>
								)}
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Button
								primary
								onClick={() => this.handleBeginClick()}
								disabled={this.state.selectedForestBlock === null ? true : false}
							>
								Begin
							</Button>
						</Grid.Row>
					</Grid>
				) : (
					<>
						<Segment inverted basic>
							<Header as="h3" inverted>
								Generate a new report for:
							</Header>
							<Header as="h4" inverted>
								{(this.state.block as any)?.forest_name}
							</Header>
						</Segment>
						<Segment inverted basic>
							<FormStepperComponent
								pages={pages}
								goToPage={this.onChangeStep}
								step={this.state.step}
								item={this.state.reportItem as storedItem}
							/>
						</Segment>
						<Segment inverted basic style={{ flexGrow: 1 }}>
							{this.renderForm()}
							{this.state.isShowError ? <Message error header="Error" content="Please fill in all required blanks." /> : null}
							<Form onSubmit={() => {}}>
								<GridRow className="submit-row">
									<Button primary disabled={step === 0} onClick={() => this.onChangeStep(step - 1)}>
										Prev
									</Button>
									<span>
										<Modal
											trigger={<Button color="red">Cancel</Button>}
											header="Confirmation"
											content="Are you sure you want to discard all the changes?"
											actions={[
												"Cancel",
												{
													key: "done",
													content: "Confirm",
													positive: true,
													onClick: this.endEditMode,
												},
											]}
										/>
										<Button
											inverted
											disabled={!saveButtonEnabled}
											active={saveButtonActive}
											onClick={() => this.saveReport()}
										>
											<Icon name="save" />
											{saveButtonLabel}
										</Button>
									</span>
									{step >= steps.length - 1 ? this.renderPDFButtons() : null}
									{step === steps.length - 1 ? null : (
										<Button primary onClick={() => this.onChangeStep(step + 1)}>
											Next
										</Button>
									)}
								</GridRow>
							</Form>
						</Segment>
						<Button className="expandBtn" floated="right" onClick={() => this.setState({ isExpand: !isExpand })}>
							{isExpand ? "<< View Map" : "Expand >>"}
						</Button>
					</>
				)}
			</Sidebar>
		)
	}
}
