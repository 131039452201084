import { storedItem } from "../store/storedItem"
import { fileUpload } from "./classes/fileUpload"
import { IStage, sequenceHandler } from "./sequenceHandler"
import { printMessage } from "../providers/remoteHQ"
import { messageLevels } from "./types"

export class fileUploadSequencer {
	uploads: fileUpload[] = []
	_stageHandler: sequenceHandler = new sequenceHandler()
	completedCallbacks: Function[] = []

	addCompletionCallback(callback: Function) {
		if (!this.completedCallbacks || this.completedCallbacks === undefined) {
			this.completedCallbacks = [callback]
		} else {
			this.completedCallbacks.push(callback)
		}
	}

	addFileUpload(theUpload: fileUpload) {
		// if file not in list
		const isInList = this.uploads.includes(theUpload)

		if (!isInList) {
			this.uploads.push(theUpload)
			theUpload.addCallbackOnUpload(this.stageCallback)
			this._stageHandler.addStage(theUpload.primaryKey(), theUpload.sendFile)
		}
	}
	doCompletionCallbacks = (stageName: string, stage: IStage) => {
		if (this.completedCallbacks) {
			this.completedCallbacks.forEach((call) => {
				call(stageName, stage)
			})
		}
	}

	removeFileUpload(theUpload: fileUpload) {
		// remove from list
		const itemIndex = this.uploads.indexOf(theUpload)
		if (itemIndex > -1) {
			this.uploads.splice(itemIndex)
			// remove stage doesnt exist
			// todo create remove stage
		}
	}

	removeFileUploadByUUID(theUUID: string): boolean {
		// remove from list
		let idx = -1
		let foundIndex = -1
		this.uploads.forEach((item) => {
			if (item.getUUID() === theUUID) {
				foundIndex = idx
			}
			idx += 1
		})

		if (foundIndex > -1) {
			this.uploads.splice(foundIndex, 1)
			return true
		}
		return false
	}

	takeoverUploads(item: storedItem, addedUploads: fileUploadSequencer) {
		addedUploads.uploads.forEach((upload) => {
			this.addFileUpload(upload)
			upload.setForItem(item)
		})
	}

	requestURLs() {
		// call request URL stage on each file in list
	}

	uploadAll = () => {
		this._stageHandler.addCompletionCallback(this.doCompletionCallbacks)
		this._stageHandler.requestCompletion()
	}

	stageCallback = (stageName: string, itemToSend: storedItem) => {
		// callback for the sequencers to use
		if (stageName === "sendUpload") {
			if (itemToSend.primaryKey()) {
				if (this._stageHandler) {
					this._stageHandler.stepFinished(itemToSend.primaryKey(), true)
				}
			}
		}
	}
	getUploadedFileURLs(): string[] {
		let fileURLs: string[] = []
		this.uploads.forEach((element: fileUpload) => {
			if (element.getValue("_sent") === true) {
				fileURLs.push(element.getValue("destination_path"))
			}
		})
		return fileURLs
	}
	getProgressArray() {}
}
