import { Divider, Header, Image, Segment } from "semantic-ui-react"

import { BlockFeatureInfoView } from "./BlockFeature"
import ResponsiveImageGrid from "../components/Image/ImageGrid"
import { getUIConfig } from ".."

export default class ArchaeologicalInfoView extends BlockFeatureInfoView {
	render() {
		const { fontColor, logo } = getUIConfig("defaultSidebar")
		const { selectedItem } = this.props
		const theLocation = selectedItem?.getValue("geojson")
		return (
			<>
				<Segment inverted basic style={{ padding: "3em", paddingTop: "3em", paddingBottom: "2em" }}>
					<Image src={process.env.PUBLIC_URL + "/assets/" + logo} size="medium" />
				</Segment>
				<Segment inverted basic>
					<Header as="h2" inverted style={{ color: fontColor }}>
						{selectedItem?.displayName()}
					</Header>
					<Divider style={{ backgroundColor: fontColor }} />
				</Segment>
				<Segment inverted basic style={{ color: fontColor }}>
					<Header as="h4" inverted style={{ color: fontColor }}>
						Description
					</Header>
					<Divider style={{ backgroundColor: fontColor }} />
					{selectedItem?.getValue("description") ? selectedItem?.getValue("description") : "No description available"}
				</Segment>
				<Segment inverted basic>
					<Header as="h4" inverted style={{ color: fontColor }}>
						Location {theLocation?.coordinates}
					</Header>
					<ResponsiveImageGrid imageUrls={[]} />
				</Segment>
			</>
		)
	}
}
