import React, { ReactNode } from "react"
import { Button, Dimmer, Loader } from "semantic-ui-react"

import "./styles.css"
import { reportError } from "../../providers/remoteHQ"
import { call } from "../../store"

const minOutline = 20
const minSize = 0

type direction = "top-left" | "top-right" | "bottom-left" | "bottom-right" | "move"

type ICapturableArea = {
	width: number
	height: number
	y: number
	x: number
}
type MoveData = {
	direction: direction
	originalHeight: number
	originalWidth: number
	originalClientX: number
	origianalClientY: number
}

export type ICapturableAreaProps = {
	children: ReactNode
	onCapture: (dataUrl: string) => void
	onCancel: () => void
	aspectRatio: number[]
	active: boolean
}

interface IShortcut {
	key: number
	altKey?: boolean
	ctrlKey?: boolean
	action: () => void
}

const useShortcuts = (shortcuts: IShortcut[]) => {
	React.useEffect(() => {
		const registerShortcuts = (event: KeyboardEvent) => {
			shortcuts.forEach(({ altKey, ctrlKey, key, action }: IShortcut) => {
				if ((altKey ?? false) === event.altKey && (ctrlKey ?? false) === event.ctrlKey && event.keyCode === key) {
					event.preventDefault()
					action()
				}
			})
		}
		document.addEventListener("keydown", registerShortcuts)

		return () => document.removeEventListener("keydown", registerShortcuts)
	}, [shortcuts])
}

const CapturableArea: React.FC<ICapturableAreaProps> = ({ children, active, aspectRatio, onCapture, onCancel }) => {
	// const dispatch = useDispatch();
	const container = React.useRef<HTMLDivElement>(null)

	const [maxBounds, setMaxBounds] = React.useState<DOMRect>()
	const [mouseMoveData, setMouseMoveData] = React.useState<MoveData>()
	const [capturableArea, setCapturableArea] = React.useState<ICapturableArea>({
		width: aspectRatio[0],
		height: aspectRatio[1],
		y: 0,
		x: 0,
	})
	const [isLoading, setLoading] = React.useState(false)

	React.useEffect(() => {
		const mouseUpEvent = () => {
			setMouseMoveData(undefined)
		}
		window.addEventListener("mouseup", mouseUpEvent)

		return () => {
			window.removeEventListener("mouseup", mouseUpEvent)
		}
	}, [])

	const setBounds = React.useCallback(
		(currentWidth: number, currentHeight: number, xCalc: (newWidth: number) => number, yCalc: (newHeight: number) => number) => {
			if (maxBounds) {
				let width = Math.min(Math.max(minSize, currentWidth), maxBounds?.width - 2 * minOutline)
				let height = Math.min(Math.max(minSize, currentHeight), maxBounds?.height - 2 * minOutline)
				const maxWidth = width
				const ar = aspectRatio[0] / aspectRatio[1]
				width = Math.min(height * ar, maxWidth)
				height = width / ar
				const data = { width, height, x: xCalc(width), y: yCalc(height) }
				setCapturableArea(data)
			}
		},
		[aspectRatio, maxBounds, setCapturableArea],
	)

	React.useEffect(() => {
		if (maxBounds) {
			setBounds(
				maxBounds.width - 2 * minOutline,
				maxBounds.height - 2 * minOutline,
				(width) => (maxBounds.width - width) / 2,
				(height) => (maxBounds.height - height) / 2,
			)
		}
	}, [maxBounds, setBounds, aspectRatio, active])

	React.useEffect(() => {
		const boundingRef = container.current?.getBoundingClientRect()
		if (boundingRef) {
			setMaxBounds(boundingRef)
		}
	}, [setMaxBounds, active])

	const onMouseDown = React.useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>, direction: direction) => {
			e.preventDefault()
			setMouseMoveData({
				originalHeight: capturableArea.height,
				originalWidth: capturableArea.width,
				originalClientX: e.clientX,
				origianalClientY: e.clientY,
				direction,
			})
		},
		[setMouseMoveData, capturableArea],
	)

	const onMouseMove = React.useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			if (mouseMoveData) {
				const { clientX, clientY } = e
				let width: number = mouseMoveData.originalWidth
				let height: number = mouseMoveData.originalHeight
				switch (mouseMoveData.direction) {
					case "top-left":
						width = width - (clientX - mouseMoveData.originalClientX)
						height = height - (clientY - mouseMoveData.origianalClientY)
						setBounds(
							width,
							height,
							(newWidth) => capturableArea.x + capturableArea.width - newWidth,
							(newHeight) => capturableArea.y + capturableArea.height - newHeight,
						)
						break
					case "top-right":
						width = width + (clientX - mouseMoveData.originalClientX)
						height = height - (clientY - mouseMoveData.origianalClientY)
						setBounds(
							width,
							height,
							(_) => capturableArea.x,
							(newHeight) => capturableArea.y + capturableArea.height - newHeight,
						)
						break
					case "bottom-left":
						width = width - (clientX - mouseMoveData.originalClientX)
						height = height + (clientY - mouseMoveData.origianalClientY)
						setBounds(
							width,
							height,
							(newWidth) => capturableArea.x + capturableArea.width - newWidth,
							(_) => capturableArea.y,
						)
						break
					case "bottom-right":
						width = width + (clientX - mouseMoveData.originalClientX)
						height = height + (clientY - mouseMoveData.origianalClientY)
						setBounds(
							width,
							height,
							() => capturableArea.x,
							() => capturableArea.y,
						)
						break
					case "move":
						const maxPosX = (maxBounds?.width ?? 0) - width
						const maxPosY = (maxBounds?.height ?? 0) - height
						setBounds(
							width,
							height,
							() => Math.min(Math.max(0, capturableArea.x + e.movementX), maxPosX),
							() => Math.min(Math.max(0, capturableArea.y + e.movementY), maxPosY),
						)
						break
				}
			}
		},
		[mouseMoveData, setBounds, maxBounds, capturableArea],
	)

	const capture = React.useCallback(() => {
		if (active) {
			setLoading(true)
			const { width, height, x, y } = capturableArea
			if (container.current && maxBounds) {
				const canvas = call("getCanvas")
				const croppedCanvas = document.createElement("canvas")
				const croppedCanvasContext = croppedCanvas.getContext("2d")
				let theDataURL
				if (croppedCanvasContext) {
					const modX = canvas.width / maxBounds.width
					const modY = canvas.height / maxBounds.height
					croppedCanvas.width = width * modX
					croppedCanvas.height = height * modY

					croppedCanvasContext.drawImage(
						canvas,
						x * modX,
						y * modY,
						width * modX,
						height * modY,
						0,
						0,
						width * modX,
						height * modY,
					)
					try {
						theDataURL = croppedCanvas.toDataURL()
					} catch (e) {
						reportError(e)
					}
					onCapture(theDataURL || "")
					setLoading(false)
				}
			}
		}
	}, [capturableArea, active, maxBounds, onCapture])

	useShortcuts([
		{ key: 13, action: capture },
		{ key: 27, action: onCancel },
	])

	return (
		<>
			<Dimmer style={{ zIndex: 9999 }} active={isLoading}>
				<Loader />
			</Dimmer>
			<div className="capturable-area" ref={container} onMouseMove={onMouseMove}>
				{children}
				{active && (
					<>
						<div
							className="exclude-area top"
							data-html2canvas-ignore="true"
							style={{
								height: capturableArea.y,
							}}
						/>
						<div
							className="exclude-area left"
							data-html2canvas-ignore="true"
							style={{
								width: capturableArea.x,
								top: capturableArea.y,
								height: capturableArea.height,
							}}
						/>
						<div
							className="exclude-area bottom"
							data-html2canvas-ignore="true"
							style={{
								top: capturableArea.y + capturableArea.height,
							}}
						/>
						<div
							className="exclude-area right"
							data-html2canvas-ignore="true"
							style={{
								left: capturableArea.x + capturableArea.width,
								top: capturableArea.y,
								height: capturableArea.height,
							}}
						/>
						<div
							className="include-area"
							style={{
								width: capturableArea.width,
								height: capturableArea.height,
								top: capturableArea.y,
								left: capturableArea.x,
								pointerEvents: mouseMoveData ? "all" : "none",
							}}
						>
							<div style={{ pointerEvents: "all", margin: "5px" }} data-html2canvas-ignore="true">
								<Button
									floated="right"
									style={{ cursor: "move" }}
									onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => onMouseDown(e, "move")}
									size="mini"
									color="black"
									icon={{ name: "move" }}
								/>
								<Button
									floated="right"
									size="mini"
									onClick={onCancel}
									color="black"
									icon={{ name: "cancel", color: "red" }}
								/>
								<Button floated="right" size="mini" onClick={capture} color="black" icon={{ name: "camera" }} />
							</div>
						</div>

						<div
							className="drag-point top-left"
							data-html2canvas-ignore="true"
							style={{
								top: capturableArea.y - 5,
								left: capturableArea.x - 5,
							}}
							onMouseDown={(e) => onMouseDown(e, "top-left")}
						/>
						<div
							className="drag-point top-right"
							data-html2canvas-ignore="true"
							style={{
								top: capturableArea.y - 5,
								left: capturableArea.x + capturableArea.width - 5,
							}}
							onMouseDown={(e) => onMouseDown(e, "top-right")}
						/>
						<div
							className="drag-point bottom-left"
							data-html2canvas-ignore="true"
							style={{
								top: capturableArea.y + capturableArea.height - 5,
								left: capturableArea.x - 5,
							}}
							onMouseDown={(e) => onMouseDown(e, "bottom-left")}
						/>
						<div
							className="drag-point bottom-right"
							data-html2canvas-ignore="true"
							style={{
								top: capturableArea.y + capturableArea.height - 5,
								left: capturableArea.x + capturableArea.width - 5,
							}}
							onMouseDown={(e) => onMouseDown(e, "bottom-right")}
						/>
					</>
				)}
			</div>
		</>
	)
}

export default CapturableArea
