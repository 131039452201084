import React from "react"
import { Button, Divider, DropdownItemProps, Grid, Icon, Segment, Sidebar } from "semantic-ui-react"

import { getGlobalState, itemByKey, registerforStateChanges, setGlobalState } from "../../../store"
import Section from "../../../components/Section"
import Select from "../../../components/Select"
import NLRangeSlider from "../../../components/Slider/NLRangeSlider"
import FeedMapComponent from "../../../mapsight/FeedMapComponent"
import { printData } from "../../../providers/remoteHQ"
import { mapLayer } from "../../../store/classes/mapLayer"
import { getDataFeedAllByName } from "../../../store/dataFeedAll"
import { IStats } from "../../../store/helpers/ListOperations"
import { IQueryParams, messageLevels } from "../../../store/types"
import ForestSearchList from "./ForestSearchList"
import { StateSwitchControl } from "../../../store/Controls/Items/SwitchControl"
import { storedItem } from "../../../store/storedItem"

import "./styles.css"
import "../styles.css"
interface OwnershipSearchProps {
	visible: boolean
}

interface OwnershipSearchState {
	catchmentType: any
	speciesType: string[]
	exoticSpecies: any
	nativeSpecies: any
	owner: string[]
	ageRange: number[]
	sizeRange: number[]
	areaStats: IStats
	ownerOptions: DropdownItemProps[]
	isShowFilter: boolean
}

const emptyStats: IStats = {}
const catchmentOptions = [
	{
		key: "any",
		text: "Any",
		value: "any",
	},
	{
		key: "4",
		text: "Order 4",
		value: "catchments-4",
	},
	{
		key: "5",
		text: "Order 5",
		value: "catchments-5",
	},
	{
		key: "6",
		text: "Order 6",
		value: "catchments-6",
	},
	{
		key: "-",
		text: "Other",
		value: "other",
	},
]

export default class OwnershipSearch extends React.Component<OwnershipSearchProps, OwnershipSearchState> {
	state = {
		catchmentType: "Any",
		speciesType: [],
		exoticSpecies: "",
		nativeSpecies: "",
		owner: [],
		ageRange: [0, 70],
		sizeRange: [0, 75000],
		areaStats: emptyStats,
		ownerOptions: [],
		speciesOptions: [
			{
				key: "Any",
				text: "Any",
				value: "Any",
			},
		],
		isShowFilter: true,
	}
	queryParams: IQueryParams = {}
	dataFeed = getDataFeedAllByName("forest-ownership", true)
	areaStats = this.dataFeed.statsIncoming("area_ha")
	loadedFromHash: any

	componentDidMount() {
		registerforStateChanges(this.onStateChange)
		this.setState({ areaStats: this.dataFeed.statsIncoming("area_ha") })
		if (this.dataFeed) {
			this.dataFeed.setupSource("forest-ownership", {})
			this.dataFeed.registerOnChange(this.onDataChange, {})
		}
		this.retrieveFromHash()
		this.getStandOwners()
	}

	componentWillUnmount() {
		if (this.dataFeed) {
			this.dataFeed.removeOnChange(this.onDataChange)
		}
	}
	onDataChange = () => {
		if (this.dataFeed) {
			const areaStats = this.dataFeed.statsIncoming("area_ha")
			this.getStandOwners()
		}
	}

	onStateChange = (type: string, key?: string, data?: storedItem) => {
		if (key === "forestOwnershipFilterState") {
			this.retrieveFromHash(data)
		}
	}

	retrieveFromHash = (data?: any) => {
		const isFromHash = getGlobalState("isFromHash")?.getValue()
		let state = data?.getValue() || getGlobalState("forestOwnershipFilterState")?.getValue()
		if (isFromHash && !this.loadedFromHash && typeof state === "string") {
			this.loadedFromHash = true
			state = JSON.parse(state)
			this.setState(state, () => this.getStandProperties())
		}
	}

	getStandOwners = () => {
		const theOwnerValues: string[] = this.dataFeed.valuesIncoming("owner")
		const theOptions: DropdownItemProps[] = []
		theOwnerValues.forEach((value) => {
			theOptions.push({
				key: value,
				text: value,
				value: value,
			})
		})

		this.setState({ ownerOptions: theOptions })
	}

	ownerDropdownValues() {
		const theOwnerValues: string[] = this.dataFeed.valuesIncoming("owner")
		const theOptions: DropdownItemProps[] = []
		theOwnerValues.forEach((value) => {
			theOptions.push({
				key: value,
				text: value,
				value: value,
			})
		})
		return theOptions
	}

	speciesDropdownValues() {
		const theSpeciesValues: string[] = this.dataFeed.valuesIncoming("species", true)
		printData(theSpeciesValues, messageLevels.debug, "theSpeciesValues")
		const theOptions: DropdownItemProps[] = []
		theSpeciesValues.forEach((value) => {
			theOptions.push({
				key: value,
				text: value,
				value: value,
			})
		})
		return theOptions
	}

	onSliderChange = (key: keyof OwnershipSearchState) => (value: number[], i: number) => {
		this.setState({ [key]: value } as unknown as Pick<OwnershipSearchState, keyof OwnershipSearchState>, () =>
			this.getStandProperties(),
		)
	}

	ownerChange = (value: any) => {
		this.setState({ owner: value }, () => this.getStandProperties())
	}

	catchmentChange = (value: any) => {
		this.setState({ catchmentType: value }, () => this.getStandProperties())
	}
	speciesChange = (value: any) => {
		this.setState({ speciesType: value }, () => this.getStandProperties())
	}

	getStandProperties = (saveToStore = true) => {
		uniqueCatchmentLayer(this.state.catchmentType)
		// let sort: ISortParams = this.queryParams.sort || {};

		// sort.sortField = this.state.sortByName ? "forest_name" : "consent_number";
		// sort.sortDirection = this.state.sortAsc ? sortDirection.ascending : sortDirection.descending;
		// sort.sortMethod = this.state.sortByName ? sortMethod.alpha : sortMethod.numeric

		// this.queryParams.sort = sort;
		this.queryParams.queries = []
		const { ageRange, sizeRange, speciesType, exoticSpecies, nativeSpecies } = this.state

		if (ageRange) {
			if (ageRange[0] > 0) {
				this.queryParams.queries.push(`ageFromEnd >== ${ageRange[0]}`)
			}
			this.queryParams.queries.push(`ageFromEnd <== ${ageRange[1]}`)
		}
		if (sizeRange) {
			if (sizeRange[0] > 0) {
				this.queryParams.queries.push(`area_ha >== ${sizeRange[0]}`)
			}
			this.queryParams.queries.push(`area_ha <== ${sizeRange[1]}`)
		}
		const owner = this.state.owner
		if (owner && owner.length > 0 && owner[0] !== "Any") {
			this.queryParams.queries.push(`owner in ${this.state.owner}`)
		}

		if (speciesType.length > 0) {
			this.queryParams.queries.push(`species any ${speciesType}`)
		}

		if (this.state.catchmentType !== "" && this.state.catchmentType !== "any") {
			if (this.state.catchmentType === "other") {
				this.queryParams.queries.push("catchment_4 IS NULL")
				this.queryParams.queries.push("catchment_5 IS NULL")
				this.queryParams.queries.push("catchment_6 IS NULL")
			} else {
				const catchmentOrder = this.state.catchmentType.replace("catchments-", "catchment_")
				if (catchmentOrder) {
					this.queryParams.queries.push(catchmentOrder + " NOT NULL")
				}
			}
		}
		this.dataFeed.interactiveSettings(this.queryParams)
		const { ownerOptions, ...others } = this.state
		saveToStore && setGlobalState("forestOwnershipFilterState", JSON.stringify(others))
	}
	onSelect() {}

	sizeChange(value: any, i: number) {
		printData(value, messageLevels.debug, "value in sizeChange")
		printData(i, messageLevels.debug, "i in sizeChange")
		const theSize = [...this.state.sizeRange]
		theSize[i] = value
		this.setState({ sizeRange: theSize })
	}
	renderIncludeToggle() {
		return <StateSwitchControl name="showOutsideSelect" label="Show Items ouside selection" destination="state"></StateSwitchControl>
	}

	renderFilter() {
		const { isShowFilter, catchmentType, speciesType, owner, ageRange, sizeRange } = this.state
		const areaStats = this.dataFeed.statsIncoming("area_ha")
		const endAgeStats = this.dataFeed.statsIncoming("ageFromEnd")
		const ownerOptions = this.ownerDropdownValues()
		const speciesOptions = this.speciesDropdownValues()
		const maxArea = Math.ceil(areaStats.max || 500)
		const maxAge = Math.ceil(endAgeStats.max || 30)
		const minArea = Math.floor(areaStats.min || 0)
		const minAge = Math.floor(endAgeStats.min || 0)
		if (ageRange[1] > maxAge) {
			ageRange[1] = maxAge
		}
		if (sizeRange[1] > maxArea) {
			sizeRange[1] = maxArea
		}
		if (ageRange[0] < minAge) {
			ageRange[0] = minAge
		}
		if (sizeRange[0] < minArea) {
			sizeRange[0] = minArea
		}
		if (!isShowFilter) return null
		return (
			<>
				<Divider />

				<Section
					title={
						<label>
							<Icon name={"clock outline"} />
							Stand Age
							<div>(Years)</div>
						</label>
					}
				>
					<NLRangeSlider
						min={minAge}
						max={maxAge}
						value={ageRange}
						onAfterChange={this.onSliderChange("ageRange")}
						minDistance={1}
						minLabel="Min"
						maxLabel="Max"
					/>
				</Section>
				<Divider />
				<Section
					title={
						<label>
							<Icon name={"area chart"} />
							Stand Size
							<div>(Hectares)</div>
						</label>
					}
				>
					<NLRangeSlider
						min={Math.max(minArea, 1)}
						max={maxArea}
						value={sizeRange}
						onAfterChange={this.onSliderChange("sizeRange")}
						minDistance={2}
						minLabel="Min"
						maxLabel="Max"
					/>
				</Section>

				<Divider />
				<Grid columns={3} divided inverted>
					<Grid.Row>
						<Grid.Column>
							<Select
								label={
									<label>
										<Icon name="tree" />
										Catchment Type
									</label>
								}
								placeholder="-"
								name="catchmentType"
								options={catchmentOptions}
								onChange={(e, data) => this.catchmentChange(data.value)}
								value={catchmentType}
							/>
						</Grid.Column>
						<Grid.Column>
							<Select
								multiple
								label={
									<label>
										<Icon name="bug" />
										Species
									</label>
								}
								placeholder="-"
								name="species"
								options={speciesOptions}
								onChange={(e, data) => this.speciesChange(data.value)}
								value={speciesType}
							/>
						</Grid.Column>
						<Grid.Column>
							<Select
								multiple
								label={
									<label>
										<Icon name="user" />
										Owner
									</label>
								}
								placeholder="-"
								name="owner"
								options={ownerOptions}
								onChange={(e, data) => this.ownerChange(data.value)}
								value={owner}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider />
			</>
		)
	}

	render() {
		const { isShowFilter } = this.state
		return (
			<Sidebar id="ownership-filter" animation="overlay" icon="labeled" width="very wide" visible={this.props.visible}>
				{/* <SearchBar /> */}
				<Segment inverted basic>
					<Button id="filter-toggle" primary onClick={() => this.setState({ isShowFilter: !isShowFilter })}>
						<Icon name={isShowFilter ? "angle up" : "angle down"} />
						6 filters
						<Icon name="sliders horizontal" />
					</Button>
				</Segment>
				{this.renderIncludeToggle()}
				{this.renderFilter()}
				<ForestSearchList visible onSelect={this.onSelect}></ForestSearchList>
				<FeedMapComponent
					key={"ownerShipMapHandler"}
					dataFeedName="forest-ownership"
					castAs="string"
					mapFilters={[
						{ layerName: "forest-ownership-line", property: "uuid" },
						{ layerName: "forest-ownership-fill", property: "uuid" },

						{ layerName: "forests-catchment-4-line", property: "uuid" },
						{ layerName: "forests-catchment-4-fill", property: "uuid" },

						{ layerName: "forests-catchment-5-line", property: "uuid" },
						{ layerName: "forests-catchment-5-fill", property: "uuid" },

						{ layerName: "forests-catchment-6-line", property: "uuid" },
						{ layerName: "forests-catchment-6-fill", property: "uuid" },
					]}
					queryParams={{}}
				/>
			</Sidebar>
		)
	}
}

function uniqueCatchmentLayer(layer: string) {
	setMapLayer("catchments-4", layer === "catchments-4")
	setMapLayer("catchments-5", layer === "catchments-5")
	setMapLayer("catchments-6", layer === "catchments-6")
	setMapLayer("forests-catchment-4", layer === "catchments-4")
	setMapLayer("forests-catchment-5", layer === "catchments-5")
	setMapLayer("forests-catchment-6", layer === "catchments-6")
	// setMapLayer(
	// 	"forest-ownership",
	// 	!(layer === "catchments-4" || layer === "catchments-5" || layer === "catchments-6"),
	// );
}

function getMapLayer(layer: string): mapLayer {
	return itemByKey("map-layers", layer) as mapLayer
}

function setMapLayer(layerName: string, visibility: boolean) {
	const theLayer = getMapLayer(layerName) as mapLayer
	if (theLayer) {
		theLayer.setVisibility(visibility)
	}
}
