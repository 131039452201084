import React from "react"

type ToggleSwitchProps = {
	checked: boolean
	activeColor: string
	inactiveColor: string
	onChange: (isChecked: boolean) => void
	disabled?: boolean
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ checked, activeColor, inactiveColor, onChange, disabled = false }) => {
	const toggleSwitch = () => {
		if (!disabled) {
			onChange(!checked)
		}
	}

	const containerStyle: React.CSSProperties = {
		backgroundColor: checked ? activeColor : inactiveColor,
		opacity: disabled ? 0.6 : 1, // Set opacity for disabled state
		width: "50px",
		height: "22px",
		borderRadius: "15px",
		display: "flex",
		alignItems: "center",
		cursor: disabled ? "not-allowed" : "pointer", // Change cursor for disabled state
		padding: "2px",
	}

	const switchStyle: React.CSSProperties = {
		width: "20px",
		height: "20px",
		borderRadius: "50%",
		transition: "transform 0.3s ease",
		transform: checked ? "translateX(26px)" : "translateX(0)",
		backgroundColor: "white",
	}

	return (
		<div className="toggle-switch" style={containerStyle} onClick={toggleSwitch}>
			<div style={switchStyle} />
		</div>
	)
}

export default ToggleSwitch
