import { getConfig, setGlobalState } from "../../store"
import { blockFeature } from "./blockFeature"

export class panoramaFeature extends blockFeature {
	// labels
	displayName() {
		const type = this.getValue("type")
		if (type !== null && type !== undefined && type !== "" && type !== "null") {
			return type + " Panorama"
		}

		return "Panorama"
	}
	imageLinks = () => {
		const imageLinks: URL[] = []

		const item = this.getValue("link")

		if (item.length > 0 && !item.endsWith("/")) {
			let theURL
			if (item.startsWith("http")) {
				theURL = new URL(item)
			} else {
				let modItem = item
				if (item.startsWith(".")) {
					modItem = item.substring(1)
				}
				if (!modItem.startsWith("/media/") && !modItem.startsWith("media/")) {
					modItem = "/media/" + item
				}

				theURL = new URL(modItem, getConfig("cdnUrl"))
			}

			imageLinks.push(theURL)

			return imageLinks
		}
	}
	// Relationships

	// Event handling
	mapClick = (e: any): void => {
		setGlobalState("modalActive", true)
		setGlobalState("modalInfo", [this])
	}
}
