import { storedItem } from "../../store/storedItem"

export class optionList extends storedItem {
	displayName() {
		return this.getValue("Contractor") || this.getValue("ForestManager") || this.getValue("ComplianceOfficer") || ""
	}
}

// case "management-company-list":

// 		case "contractors-list":
