export default function lightenDarkenColor(color = "#000", percent: number, action: "darken" | "lighten"): string {
	const isValidHex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color)
	const isValidRgb = /^rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)$/.test(color)

	let match: RegExpMatchArray | null
	let [r, g, b]: number[] = []

	if (isValidRgb) {
		match = color.match(/^rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)$/)
		if (match) [, r, g, b] = match.map(Number)
	} else if (isValidHex) {
		let hex = color.replace(/^#/, "")
		if (hex.length === 3) {
			hex = hex.replace(/(.)/g, "$1$1")
		}
		;[r, g, b] = hex.match(/.{2}/g)!.map((x) => parseInt(x, 16))
	} else {
		throw new Error("Invalid color format. Please use hex or rgb format.")
	}

	if (action === "lighten") {
		r += Math.round((255 - r) * (percent / 100))
		g += Math.round((255 - g) * (percent / 100))
		b += Math.round((255 - b) * (percent / 100))
	} else if (action === "darken") {
		r -= Math.round(r * (percent / 100))
		g -= Math.round(g * (percent / 100))
		b -= Math.round(b * (percent / 100))
	}

	r = Math.min(255, Math.max(0, r))
	g = Math.min(255, Math.max(0, g))
	b = Math.min(255, Math.max(0, b))

	if (isValidRgb) {
		return `rgb(${r}, ${g}, ${b})`
	} else {
		return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, "0")}`
	}
}
