import { useEffect, useState, useRef } from "react"

export const useHover = () => {
	const [value, setValue] = useState(false)
	const ref = useRef(null)

	const handleMouseOver = () => setValue(true)
	const handleMouseOut = () => setValue(false)

	useEffect(
		() => {
			const node = ref.current
			if (node) {
				node.addEventListener("mouseover", handleMouseOver)
				node.addEventListener("mouseleave", handleMouseOut)
				return () => {
					node.removeEventListener("mouseover", handleMouseOver)
					node.removeEventListener("mouseleave", handleMouseOut)
				}
			}
		},
		[ref.current], // Recall only if ref changes
	)

	return [ref, value]
}
