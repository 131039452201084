import React from "react"
import { Divider, Segment } from "semantic-ui-react"
import moment from "moment"
import { Text, View } from "@react-pdf/renderer"

import { FieldLabel } from "./FieldLabel"
import { FormBlockProps, FormFieldProperties, FormItemComponentProps, FormItemProps, formFormatNumber } from "../.."
import { printData, printMessage } from "../../../../providers/remoteHQ"
import { IParsedQuery, messageLevels } from "../../../types"
import { storedItem } from "../../../storedItem"

export interface PDFFormBlockState {
	subItem?: storedItem | null
	visible: boolean
	queryParsed: IParsedQuery[]
	n: number
}

export class PDFFormList extends React.Component<FormBlockProps, PDFFormBlockState> {
	renderFieldHeading(field: FormFieldProperties, index: number, count: number) {
		const cellWidth = 100 / count + "%"
		let align: "left" | "right" | "center" | "justify" | undefined = "right"
		if (index === 0) {
			align = "left"
		}
		return (
			<Text
				style={{
					width: cellWidth,
					fontWeight: "bold",
					fontSize: 14,
					padding: 2,
					textAlign: align,
				}}
			>
				{field.label}
			</Text>
		)
	}

	renderField(item: storedItem, field: FormFieldProperties, index: number, count: number) {
		const cellWidth = 100 / count + "%"
		let fieldName = field.name
		const parentPos = fieldName.indexOf("(parentItem)")
		const parentItem = this.props.item

		if (parentItem && parentPos > -1) {
			fieldName = fieldName.replace("(parentItem)", "(" + parentItem.getTypeName() + "," + parentItem.primaryKey() + ")")
			// printData(fieldName, messageLevels.verbose);
		}

		let theValue = item?.getValue(fieldName)
		const isNumber = !isNaN(theValue)
		let align: "left" | "right" | "center" | "justify" | undefined = "left"
		if (index === 0) {
			align = "left"
		} else if (index > 0) {
			align = "right"
		}
		theValue = formFormatNumber(theValue, field.formatOptions, field.scale, field.defaultValue)

		return <Text style={{ width: cellWidth, fontSize: 12, padding: 2, textAlign: align }}>{theValue}</Text>
	}

	renderItemRow(item: storedItem, index: any) {
		const theSubfields = this.props.field.subfields
		const container = this.props.container
		const styles = container.styles
		if (theSubfields) {
			const theCount = theSubfields?.length
			return (
				<View
					style={[
						styles.row,
						{
							paddingTop: "1mm",
							paddingBottom: "1mm",
							backgroundColor: index % 2 === 0 ? "rgba(0, 0, 0, 0.2)" : "transparent",
						},
					]}
				>
					{theSubfields?.map((subfield, idx) => {
						return this.renderField(item, subfield, idx, theCount)
					})}
				</View>
			)
		}
	}
	renderItemRows(items: storedItem[]) {
		const theSubfields = this.props.field.subfields
		if (theSubfields) {
			const theCount = theSubfields?.length

			return items?.map((item, idx) => {
				return this.renderItemRow(item, idx)
			})
		}
	}

	renderHeaderRow() {
		const theSubfields = this.props.field.subfields
		if (theSubfields) {
			const theCount = theSubfields?.length

			return theSubfields?.map((subfield, idx) => {
				return this.renderFieldHeading(subfield, idx, theCount)
			})
		}
		return <></>
	}

	renderHeading() {
		const theLabel = this.props.field.label
		const container = this.props.container
		const styles = container.styles
		if (theLabel && theLabel.length > 0) {
			return (
				<View style={[styles.row, { marginTop: "1mm", marginBottom: "1mm" }]}>
					<Text style={{ fontWeight: "bold", fontSize: 16 }}>{theLabel}</Text>
				</View>
			)
		}
	}

	render() {
		const { field, item } = this.props
		const theItems = item?.getValue(field.name)
		const container = this.props.container
		const styles = container.styles
		const theStyle = this.props.field.style || {}

		return (
			<View style={[theStyle, { width: "100%", padding: "3mm", position: "relative" }]}>
				{this.renderHeading()}
				<View
					fixed
					style={[
						styles.row,
						{
							paddingTop: "1mm",
							paddingBottom: "1mm",
							backgroundColor: "rgba(0, 0, 0, 0.8)",
						},
					]}
				>
					{this.renderHeaderRow()}
				</View>
				{this.renderItemRows(theItems)}
			</View>
		)
	}
}
