import { useState } from "react"
import { Icon, Menu, SemanticICONS } from "semantic-ui-react"

interface MenuItemProps {
	className?: string
	label?: string
	icon: SemanticICONS | undefined
	activeColor?: string
	baseColor?: string
	active?: boolean
	disabled?: boolean
	popupContent?: JSX.Element | string
	hide?: boolean
	secondaryIcon?: SemanticICONS | undefined
	onClick?: () => void
}

export const MenuItem = (props: MenuItemProps): JSX.Element => {
	const [hovered, setHovered] = useState(false)
	const { className, label, activeColor, baseColor, active, disabled } = props
	const { onClick, icon, ...others } = props

	const handleHover = (isHovering: boolean) => {
		disabled || setHovered(isHovering)
	}

	const isActiveOrHovered = !disabled && (active || hovered)

	return (
		<div
			className={`${className ? className + "-" : ""}nav-button ${disabled ? "disabled" : ""}`}
			onMouseEnter={(): void => handleHover(true)}
			onMouseLeave={(): void => handleHover(false)}
			style={{ opacity: disabled ? 0.7 : 1, marginBottom: 10 }}
			onClick={onClick}
		>
			<Menu.Item {...others} as="a" active={active} style={{ ...(label ? { paddingBottom: 0 } : {}) }}>
				<Icon name={icon} style={{ color: isActiveOrHovered ? activeColor : baseColor }} />
			</Menu.Item>
			<div
				style={{
					color: isActiveOrHovered ? activeColor : baseColor,
					fontSize: "9px",
					lineHeight: "9px",
					paddingLeft: hovered ? "3px" : "0",
				}}
			>
				{label}
			</div>
		</div>
	)
}
