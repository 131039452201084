import React from "react"
import { Divider, Form, Item } from "semantic-ui-react"

import { EditViewProps } from "../Types"
import variables from "../../../variables"
import SelectionListComponent from "../../components/SelectionListComponent"
import { blockFeature } from "../../classes/blockFeature"
import PinPlaceGeoJSONPoint from "../../../mapsight/PinGeoJSONPoint"

import ImageList from "../../components/Image/ImageListComponent"
import { ReportItem } from "../../classes/report"
import { selectionItem, SelectionItemList } from "../../managers/SelectionList"

export interface ReportAoesState {
	changed: boolean
}

export default class ReportAoesEditView extends React.Component<EditViewProps, ReportAoesState> {
	state = {
		changed: false,
	}
	valueChanged = (item: selectionItem, list: SelectionItemList) => {
		list.toggle(item)
		if (this.props.setDirty) {
			this.props.setDirty()
		}
	}
	aoeCellFormatter = (item: selectionItem, list: SelectionItemList) => {
		const imageURLs = (item.item as blockFeature).imageURLs
		// const hidePins = this.state.hidePins;
		const hidePins = true
		return (
			<React.Fragment key={item.item.primaryKey()}>
				<Item>
					{/* <Item.Header>{item.item.displayName()}</Item.Header> */}
					<Item.Description>
						<Form.Radio
							label="Include AOE in report?"
							toggle
							checked={item.selected}
							onClick={() => this.valueChanged(item, list)}
						/>
					</Item.Description>
					<Item.Content className="aoe-content">
						<ImageList imageLinks={imageURLs}></ImageList>
					</Item.Content>
					{/* {item.selected && !hidePins ? <PinPlaceGeoJSONPoint hideDisplay={true} pinColor={"#11ee11"} initialLocation={ item.item?.getValue("geojson") } ></PinPlaceGeoJSONPoint> : <></>} */}
				</Item>
				<Divider />
			</React.Fragment>
		)
	}

	render() {
		const { visible, editingItem } = this.props
		const theAOEs = (editingItem as ReportItem).aoeSelectionList
		if (!theAOEs) return null
		return (
			<Form>
				<div className={`report-form${visible ? " active" : ""}`}>
					{theAOEs.items.length ? (
						<SelectionListComponent data={theAOEs} cellFormatter={this.aoeCellFormatter}></SelectionListComponent>
					) : (
						<div className="empty-form">
							There are no Areas of Excellence present for this block. Please create an Area of Excellence using the editing
							tool.
						</div>
					)}
				</div>
			</Form>
		)
	}
}
