import React, { Component } from "react"
import { Grid, Icon, Segment } from "semantic-ui-react"

import { FormPageItemProps } from "../index"
import { printData } from "../../../providers/remoteHQ"
import { messageLevels } from "../../types"
import { storedItem } from "../../storedItem"

export interface INotifyFormStepperProps {
	step: number
	pages: FormPageItemProps[]
	goToPage: any
	item?: storedItem
}

export class FormStepperComponent extends Component<INotifyFormStepperProps> {
	stepStyle = { paddingLeft: "27px" }
	stepTitleStyle = { position: "relative", left: "-5px" }

	goToPage = (page: number) => {
		printData(page, messageLevels.verbose, "page in goToPage")
		if (this.props.goToPage) {
			this.props.goToPage(page)
		}
	}

	renderStep = (theStep: FormPageItemProps, index: number) => {
		const { item } = this.props
		const isStepValid = theStep.validIcon && item?.validation?.getValidateGroups()[index]
		const hasError = theStep.invalidIcon && isStepValid === false
		const isNeutral = !isStepValid && !hasError
		const hasMandatoryField = theStep.fields?.some((field) => field.mandatory)
		return (
			<Grid.Column
				key={`step-${index}`}
				className={`step-control${this.props.step === index ? " active" : ""}${isStepValid ? " valid" : ""}${
					hasError ? " error" : ""
				}${isNeutral ? " neutral" : ""}`}
				onClick={() => {
					this.goToPage(index)
				}}
			>
				<div className="step-indicator">
					{isStepValid ? <Icon style={{ marginLeft: 5 }} name={theStep.validIcon} /> : null}
					{hasError ? <Icon style={{ marginLeft: 5 }} name={theStep.invalidIcon} /> : null}
					{isNeutral ? index + 1 : null}
				</div>
				<span className="step-name">
					{theStep.title.charAt(0).toUpperCase() + theStep.title.slice(1)}{" "}
					<span style={{ color: "red" }}>{hasMandatoryField ? "*" : ""}</span>
				</span>
			</Grid.Column>
		)
	}
	renderSteps = () => {
		return this.props.pages.map((page, idx) => {
			if (page.hidden) return null
			return this.renderStep(page, idx)
		})
	}
	render() {
		if (this.props.pages[this.props.step].type === "submit") return null
		return (
			<Segment inverted basic>
				<Grid columns={3} padded>
					{this.renderSteps()}
				</Grid>
			</Segment>
		)
	}
}
