import { call } from "../../store"
import { storedItem } from "../../store/storedItem"
import { mapLayer } from "./mapLayer"
import { printMessage } from "../../providers/remoteHQ"
import { messageLevels } from "../types"

export class mapSource extends storedItem {
	// getParent  ()  {
	//     return this.getRelatedItems ("boundaries",this.getValue("parent_iso"), "region_iso" )[0];
	// }

	// getBoundingBox = () => {
	//     return this.getValue("mapbox_bounding_box");
	// }

	// levelName = ():string => {
	//     const theLevel = this.getValue("iso_subdivision_category");
	//     if(theLevel) {
	//         return theLevel;
	//     }
	//     return "region";
	// }
	toggleVisibility() {
		this.layers.forEach((layer) => {
			layer.toggleVisibility()
		})
	}
	currentVisibility(): boolean {
		return call("getLayerVisibility", this.getValue("id"))
	}
	get defaultVisibility() {
		const theLayout = this.getValue("layout")
		if (theLayout) {
			if (theLayout.visibility) {
				if (theLayout.visibility === "visible") {
					return true
				} else {
					return false
				}
			}
		}
		return true
	}
	get isOnMap(): boolean {
		return (this as any)._isOnMap
	}
	set isOnMap(value: boolean) {
		;(this as any)._isOnMap = value
	}
	get tilesURLs(): string[] {
		return this.getValue("tiles")
	}
	// inCurrentLevel(): boolean {

	//   const theLevel = getGlobalState("level")?.getValue();
	//   let found = false;
	//   (this.getValue("contexts") as string[]).forEach(element => {
	//     if(element.toLowerCase() == theLevel.toLowerCase()) {
	//       found = true;
	//     }
	//   });
	//   return found;
	// }
	// areasOfConcern = (): storedItem[] => {
	//   return this.getRelatedItems ("areas-of-concern",this.getRegionISO(), "getRegionISO" );
	// }
	// areasOfFailure = (): storedItem[] => {
	//   return this.getRelatedItems ("areas-of-failure",this.getRegionISO(), "getRegionISO" );
	// }
	// areasOfExcellence = (): storedItem[] => {
	//   return this.getRelatedItems ("areas-of-excellence",this.getRegionISO(), "getRegionISO" );
	// }
	setupForLevel() {}
	get mapSource() {
		return 1
	}
	setBounds(bounds: any[]) {
		if (this.getValue("type") === "raster") {
			printMessage("SetBounds in source " + this.primaryKey(), messageLevels.debug)
			call("setSourceBounds", this.primaryKey(), bounds)
		}
	}
	get layers(): mapLayer[] {
		return this.getRelatedItems("map-layers", this.getValue("id"), "source") as mapLayer[]
	}
	hide() {
		this.layers.forEach((layer) => {
			layer.hide()
		})
	}
	show() {
		this.layers.forEach((layer) => {
			layer.show()
		})
	}
	isEditable() {
		return false
	}
}
