import React from "react"
import { Divider, Grid, Segment, Form } from "semantic-ui-react"

import { EditViewProps } from "../Types"
import { printMessage } from "../../../providers/remoteHQ"
import { messageLevels } from "../../types"
import SelectionListComponent from "../../components/SelectionListComponent"
import { blockFeature } from "../../classes/blockFeature"
import PinPlaceGeoJSONPoint from "../../../mapsight/PinGeoJSONPoint"

import { ReportItem } from "../../classes/report"
import ImageList from "../../components/Image/ImageListComponent"
import { selectionItem, SelectionItemList } from "../../managers/SelectionList"

export interface ReportTaskState {
	changed: boolean
}

export default class ReportTaskEditView extends React.Component<EditViewProps, ReportTaskState> {
	state = {
		changed: false,
	}
	valueChanged = (item: selectionItem, list: SelectionItemList) => {
		list.toggle(item)
		if (this.props.setDirty) {
			this.props.setDirty()
		}
	}
	taskCellFormatter = (item: selectionItem, list: SelectionItemList) => {
		const taskId: string = item.item.displayName()
		const description = item.item.getValue("description") || "No description available"
		const due_date = item.item.getDateFormatted("due_date", "DD MMMM yyyy", "No date assigned")

		const imageLink = (item.item as blockFeature).heroImageURL
		const imageLinks = imageLink ? [imageLink] : []
		// const hidePins = this.state.hidePins;
		const hidePins = true
		return (
			<>
				<Segment inverted key={item.index}>
					<Form.Radio toggle label="Include in report?" checked={item.selected} onChange={() => this.valueChanged(item, list)} />
					<Grid>
						<Grid.Column width={6}>
							<div>{taskId}</div>
						</Grid.Column>
						<Grid.Column width={10}>
							<div>Due Date: {due_date}</div>
						</Grid.Column>
					</Grid>
					<Grid>
						<Grid.Column width={6}>
							<ImageList imageLinks={imageLinks} />{" "}
						</Grid.Column>
						<Grid.Column width={10}>
							<div>{description}</div>
						</Grid.Column>
					</Grid>
					{/* {item.selected && !hidePins ? <PinPlaceGeoJSONPoint hideDisplay={true} pinColor={"#8f2e2d"} initialLocation={ item.item?.getValue("geojson") } ></PinPlaceGeoJSONPoint> : <></>} */}
				</Segment>
				<Divider></Divider>
			</>
		)
	}

	render() {
		const { visible, editingItem } = this.props
		const tasks: SelectionItemList | null = (editingItem as ReportItem).taskSelectionList
		if (!tasks) return null

		return (
			<Form>
				<div className={`report-form${visible ? " active" : ""} report-form-tasks`}>
					{tasks.length ? (
						<SelectionListComponent data={tasks} cellFormatter={this.taskCellFormatter}></SelectionListComponent>
					) : (
						<div className="empty-form">
							There are no tasks present for this block. Please create tasks using the editing tool.
						</div>
					)}
				</div>
			</Form>
		)
	}
}
