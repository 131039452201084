import React from "react"

import { Label } from "semantic-ui-react"
import { FormItemComponentProps } from "../.."

export class FieldLabel extends React.Component<FormItemComponentProps> {
	render() {
		const { field, item } = this.props
		const error = item?.validationErrorForKey(field.name)
		const classes = "fieldLabel" + (field.mandatory ? " required" : "") + (error ? " error" : "")
		// const color = undefined;
		// if(error ) {
		//     return (
		//         <span style={{color: "red"}}>
		//             {field.label}
		//         </span>
		//     )
		// } else {
		return (
			<>
				<div className={classes}>{field.label}</div>
			</>
		)
		// }
	}
}
