import React from "react"
import { Segment, Divider } from "semantic-ui-react"
import moment from "moment"
import { FieldLabel } from "./FieldLabel"
import { FormItemComponentProps } from "../.."
import { printMessage } from "../../../../providers/remoteHQ"
import { messageLevels } from "../../../types"
import { Text, View } from "@react-pdf/renderer"
export class Banner extends React.Component<FormItemComponentProps> {
	render() {
		const container = this.props.container
		const styles = container.styles
		let lineheight = 0
		const { field, item } = this.props
		const theLabel = field.label
		const theValue = item?.getValue(field.name)

		return (
			<View
				style={[
					{ width: "100%", padding: "3mm", position: "relative", backgroundColor: "black", color: "white", textAlign: "center" },
				]}
			>
				<Text style={{ fontWeight: "bold", fontSize: 18, color: "white" }}>{theLabel}</Text>
				<Text style={{ fontWeight: "bold", fontSize: 18, color: "white" }}>{theValue}</Text>
			</View>
		)
	}
}
