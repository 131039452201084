import { Auth } from "@aws-amplify/auth"
import AmplifyCache from "@aws-amplify/cache"

import { getItem, setGlobalState, storeData, userChanged } from "../store/index"
import { messageLevels } from "../store/types"
import { printData, printMessage } from "./remoteHQ"

export interface IAuthenticationState {
	isLoading: boolean
	isAuthenticated: boolean
	authToken?: string
	userName?: string
	sub: string
}
let wasLoggedIn = false
let intervalId: NodeJS.Timer | null = null

export const loginAd = async () => {
	try {
		await Auth.federatedSignIn({ provider: "azure-b2c-geoinsight-dev" } as any)
	} catch (error) {
		printMessage("error signing in" + error, messageLevels.error)
	}
}

const refreshAccessToken = async () => {
	try {
		printMessage("Refreshing access token", messageLevels.none)
		const cognitoUser = await Auth.currentAuthenticatedUser()
		const currentSession = cognitoUser.signInUserSession
		cognitoUser.refreshSession(currentSession.refreshToken, (err: any, session: any) => {
			if (err) {
				printMessage("Unable to refresh access token: " + err, messageLevels.error)
			} else {
				printMessage("Refreshed access token", messageLevels.none)
				setGlobalState("loginState", { isLoggedIn: true, accessToken: session.getAccessToken().getJwtToken() })
			}
		})
	} catch (e) {
		printMessage("Unable to refresh access token: " + e, messageLevels.error)
	}
}

export const login = async (email: string, password: string) => {
	await Auth.signIn(email.replace("@", "-at-"), password)
	await onLoginStateChange()
}

export const onLoginStateChange = async (): Promise<boolean> => {
	printMessage("onLoginStateChange", messageLevels.debug)
	try {
		const session = await Auth.currentSession()
		if (session.isValid()) {
			const user = await Auth.currentAuthenticatedUser()
			// const jwtToken = session.getIdToken().getJwtToken();
			const userAttr = user.attributes
			if (!wasLoggedIn) {
				printMessage("Was not logged in 1", messageLevels.debug)
				userChanged(userAttr.sub)
				setGlobalState("loginState", { isLoggedIn: true, accessToken: session.getAccessToken().getJwtToken() })
			}

			const theProfile = await getItem("user-profile", userAttr.sub)
			storeData("user-profile", "", theProfile)

			wasLoggedIn = true

			if (!intervalId) {
				intervalId = setInterval(refreshAccessToken, 59 * 60 * 1000)
			}

			return true
		} else {
			printMessage("User not logged in 2", messageLevels.debug)
			if (wasLoggedIn) {
				printMessage("Was logged in 3", messageLevels.debug)
				userChanged("")

				setGlobalState("loginState", { isLoggedIn: false, accessToken: null })
				printMessage("get profile on logout", messageLevels.debug)

				const theProfile = await getItem("user-profile", "0000")
				storeData("user-profile", "", theProfile)
				printData(theProfile, messageLevels.debug)
			}
			wasLoggedIn = false
		}
	} catch (error) {
		printMessage(error, messageLevels.verbose)
		printMessage("User not logged in - error", messageLevels.debug)

		if (intervalId) {
			clearInterval(intervalId)
			intervalId = null
		}

		if (wasLoggedIn) {
			printMessage("Was logged in 4", messageLevels.debug)
			userChanged("")
			setGlobalState("loginState", { isLoggedIn: false, accessToken: null })

			const theProfile = await getItem("user-profile", "0000")
			storeData("user-profile", "", theProfile)
			printData(theProfile, messageLevels.debug)
		} else if (error === "No current user") {
			printMessage("No current user - error", messageLevels.debug)
			setGlobalState("loginState", { isLoggedIn: false, accessToken: null })
			const theProfile = await getItem("user-profile", "0000")
			storeData("user-profile", "", theProfile)
			printData(theProfile, messageLevels.debug)
		} else {
			const retried = localStorage.getItem("retried")
			if (!retried) {
				window.location.reload()
				localStorage.setItem("retried", "true")
			} else {
				setGlobalState("networkError", "Network issue, please try again shortly.")
				localStorage.removeItem("retried")
			}
		}
		wasLoggedIn = false
		return false
	}
	return false
}

export const logOut = async () => {
	await Auth.signOut({ global: true })
	await AmplifyCache.clear()
	await onLoginStateChange()
	printMessage("User has been signed out of application", messageLevels.debug)
}

export async function getAccessToken() {
	const session = await Auth.currentSession()
	const jwtToken = session.getAccessToken().getJwtToken()
	return jwtToken
}
