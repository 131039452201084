import React from "react"
import moment from "moment"
import { Image, Text, View } from "@react-pdf/renderer"

import { FormItemComponentProps } from "../.."

// eslint-disable-next-line
const cover = require("../../../../containers/sidebars/Report/report-cover-min.jpeg")
export class ReportHeader extends React.Component<FormItemComponentProps> {
	render() {
		const container = this.props.container
		const styles = container.styles

		const { field, item } = this.props
		const theLabel = field.label
		const theValue = item?.getValue(field.name)

		return (
			<View style={[{ height: "30%", position: "relative", color: "white" }]}>
				<View style={[styles.flexCenter, { width: "100%", height: "100%", overflow: "hidden" }]}>
					<Image style={{ width: "100%", position: "absolute" }} src={cover} />
				</View>
				<View
					style={[
						styles.flexCenter,
						{
							position: "absolute",
							width: "40vw",
							height: "20%",
							backgroundColor: "white",
						},
					]}
				>
					<Image
						style={{ transform: "scale(0.6)" }}
						src={{
							uri: process.env.PUBLIC_URL + "/assets/remotehq-logo-colour.png",
							method: "GET",
							headers: { "Cache-Control": "no-cache" },
							body: "",
						}}
					/>
				</View>
				<View style={{ position: "absolute", bottom: "1cm", left: "1cm" }}>
					<Text style={{ fontSize: 13, marginBottom: "0.3cm" }}>{moment().format("DD MMMM YYYY")}</Text>
					<Text style={{ fontWeight: "extrabold", fontSize: 30, marginBottom: "0.3cm" }}>{theValue}</Text>
					<Text style={{ fontSize: 15 }}>{theLabel}</Text>
				</View>
			</View>
		)
	}
}
