import React from "react"
import { Button, Divider, Form, Icon, Popup, Segment } from "semantic-ui-react"
import { ImageListType } from "react-images-uploading"

import { ReportItem } from "../../classes/report"
import LayerGroupAccordion from "../../../mapsight/LayerGroupAccordion"
import { call, setGlobalState } from "../.."
import { messageLevels } from "../../types"
import { printData, printMessage } from "../../../providers/remoteHQ"
import { StoredItemEditView } from "../StoredItem"
import { newFileUploaderFromImageListItem, newFileUploaderFromURI } from "../../FileHandling"
import { ImageUploadComponent } from "../ImageUploadComponent"
import { storedItem } from "../../storedItem"

export default class ReportMapsEditView extends StoredItemEditView {
	errorForKey = (forKey: string): any => {
		const theItem: storedItem | null | undefined = this.props.editingItem
		if (theItem && theItem !== undefined) {
			return theItem.validationErrorForKey(forKey)
		}
		return null
	}

	doCapture = (key: string, ratio: number[]) => {
		setGlobalState("hide-markers", true)
		call("onActiveCapture", key, this.imageCapturedForKey, ratio)
	}

	imageCapturedForKey = (image: string, key: string) => {
		if (image && image.length > 0) {
			const theItem = this.props.editingItem as ReportItem
			const theDate = new Date()
			const theFilename = ("Map Capture " + theDate.toLocaleDateString() + " at " + theDate.toLocaleTimeString())
				.replaceAll("/", "-")
				.replaceAll(":", ".")
			const theUpload = newFileUploaderFromURI(image, theFilename, theDate)

			theItem.addUploadForKey(theUpload, key)
			this.setDirty()
		}
	}

	imageChangeForKey = (key: string, imageList: ImageListType) => {
		imageList.forEach((image) => {
			const theUpload = newFileUploaderFromImageListItem({
				...image,
				name: image.file?.name,
				lastModified: image.file?.lastModified,
				size: image.file?.size,
				type: image.file?.type,
			})
			printData({ theUpload }, messageLevels.debug, "individual image")
			if (this.props.editingItem) {
				;(this.props.editingItem as ReportItem).addUploadForKey(theUpload, key)
				theUpload.requestStep("readData")
			}
		})
		this.setDirty()
	}

	removeImageForKey = (key: string): boolean => {
		printMessage("removeImageForKey", messageLevels.verbose)
		const didDelete = (this.props.editingItem as ReportItem).removeImageForKey(key)
		this.setDirty()
		return didDelete
	}

	setOrientationForKey = (key: string, orientation: number) => {
		printData(orientation, messageLevels.verbose, "orientation")
		;(this.props.editingItem as ReportItem).setMapOrientationForKey(orientation, key)
		this.setState({ changeCount: this.state.changeCount + 1 })
	}

	renderFileInput = (key: string, ratio: number[], marginTop = 0, error?: boolean) => {
		const { editingItem } = this.props

		const theURL = (editingItem as ReportItem).getMapURLByKey(key)
		const src = theURL ? [theURL] : []
		const theOrientation = (editingItem as ReportItem).getMapOrientationByKey(key)
		const theRatio = ratio || [9, 16]
		const errorCode = error ? <div style={{ color: "#e0b4b4" }}>Image Required</div> : null
		return (
			<>
				{errorCode}
				<div className="file-input">
					<ImageUploadComponent
						maxImages={1}
						imageChange={(imageList: ImageListType) => this.imageChangeForKey(key, imageList)}
						removeImage={() => {
							this.removeImageForKey(key)
						}}
						imageLinks={src}
						imageCapture={() => {
							this.doCapture(
								key,
								theOrientation
									? [theRatio[1], theRatio[0] * (1 - marginTop)]
									: [theRatio[0], theRatio[1] * (1 - marginTop)],
							)
						}}
					/>
					<Divider></Divider>
				</div>
			</>
		)
	}

	renderMapOrientationRadio = (key: string) => {
		const theOrientation = (this.props.editingItem as ReportItem).getMapOrientationByKey(key)

		return (
			<div className="orientation-btn">
				<Popup
					content="Portrait"
					trigger={
						<Button
							className={`portrait inverted${theOrientation === 0 ? " active" : ""}`}
							onClick={() => {
								this.setOrientationForKey(key, 0)
							}}
						>
							<Icon name="file" />
						</Button>
					}
				/>
				<Popup
					content="Landscape"
					trigger={
						<Button
							className={`landscape inverted${theOrientation === 1 ? " active" : ""}`}
							onClick={() => {
								this.setOrientationForKey(key, 1)
							}}
						>
							<Icon name="file" />
						</Button>
					}
				/>
			</div>
		)
	}

	renderMapFileSelect = (
		key: string,
		label: string,
		ratio: number[],
		marginTop: number,
		showOrientation: boolean,
		required?: boolean,
		error?: boolean,
	) => {
		const classes = "field" + (required ? " required" : "") + (error ? " error" : "")
		return (
			<React.Fragment key={key}>
				<div className={classes}>
					<label>{label}</label>
					{showOrientation ? this.renderMapOrientationRadio(key) : null}
				</div>
				{this.renderFileInput(key, ratio || [210, 297], marginTop, error)}
			</React.Fragment>
		)
	}

	render() {
		const { visible } = this.props
		return (
			<Form>
				<div className={`report-form${visible ? " active" : ""}`}>
					{this.renderMapFileSelect(
						"context",
						"Context Map (cover page)",
						[117.6, 207.9],
						0,
						false,
						true,
						this.errorForKey("context"),
					)}
					{this.renderMapFileSelect("task", "Task Map", [210, 297], 0.07, true)}
					{this.renderMapFileSelect("consentedVsBuilt", "Consented Map vs Built Map", [210, 297], 0.07, true)}
					{this.renderMapFileSelect("skidSite", "Skid Site Map", [210, 297], 0.07, true)}
					<Segment inverted>
						<LayerGroupAccordion location="block" />
					</Segment>
				</div>
			</Form>
		)
	}
}
