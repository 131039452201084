import moment from "moment"

import { call, getConfig, getStoreTypeByName, instanceNewItem, isUUID } from "../../store"
import { printData, printMessage } from "../../providers/remoteHQ"
import { storedItem } from "../../store/storedItem"
import { ImageSupport, messageLevels } from "../types"
import { fileUploadSequencer } from "../multiFileUploader"
import { fileUpload } from "./fileUpload"
import { forestBlock } from "./forestBlock"
import { attachment } from "./attachment"
import {
	UUIDforMediaCollectionURL,
	imageItemsOrStrings,
	imageStringOrObjects,
	imagesAndRotations,
	mediaCollectionURLforUUID,
} from "../URLRewrites"
import { mediaItem } from "./mediaItem"
import { keyNotSet } from "../helpers"

export class blockFeature extends storedItem implements ImageSupport {
	_MultiFileUploader: fileUploadSequencer | null = null
	zoomOnSelect() {
		return false
	}
	willStoreEdit(): boolean {
		this.uploadAll()
		this.storeEditPrepared()
		return true
	}

	addFileUpload = (theUpload: fileUpload) => {
		theUpload.setForItem(this)
		// theUpload.addCallbackOnUpload(this.linkedUpdate);
		this.fileUploader.addFileUpload(theUpload)
		this._dirty = true
		this.addImageURL(mediaCollectionURLforUUID(theUpload.getUUID()).toString())
		this.doChangeCallbacks()
	}

	addImageURL = (theURL: string) => {
		const theImages: string[] = this.imageLinksCopy
		if (theImages.indexOf(theURL) < 0) {
			theImages.push(theURL)
			this.setValue(theImages, "image_links")
			this.doChangeCallbacks()
		}
	}
	uploadAll = () => {
		if (this._MultiFileUploader) {
			printMessage("Upload All in: " + this.displayName(), messageLevels.verbose)
			this.fileUploader.uploadAll()
		}
	}

	takeoverUploads = (fileUploader: fileUploadSequencer) => {
		if (fileUploader) {
			this.fileUploader.takeoverUploads(this, fileUploader)
		}
	}

	removeImage = (theURL: string | URL): boolean => {
		let done = false
		const urlString = theURL.toString()
		const imageUUID = UUIDforMediaCollectionURL(urlString)

		const isImageUUID = imageUUID !== ""

		if (isImageUUID) {
			done = this.removeImageUUID(imageUUID)
		} else {
			done = this.removeImageURL(urlString)
		}
		return done
	}

	removeImageURL = (theURL: string): boolean => {
		const theImages: string[] = this.imageLinksCopy

		let foundIndex = theImages.indexOf(theURL)

		if (foundIndex < 0) {
			let index = 0
			theImages.forEach((element) => {
				if (theURL.includes(element)) {
					foundIndex = index
				}
				index += 1
			})
		}

		if (foundIndex > -1) {
			theImages.splice(foundIndex, 1)

			this.setValue(theImages, "image_links")
			this._dirty = true
			this.doChangeCallbacks()
			return true
		}

		return false
	}

	removeImageUUID = (theUUID: string): boolean => {
		if (typeof theUUID !== "string") {
			return false
		}
		const theImages: string[] = this.imageLinksCopy || []
		let foundIndex = -1
		let done = false
		const theLowerUUID = theUUID.toLowerCase()
		let idx = 0

		theImages.forEach((item) => {
			if (item.toString().toLowerCase().includes(theLowerUUID)) {
				foundIndex = idx
			}
			idx += 1
		})

		if (foundIndex > -1) {
			theImages.splice(foundIndex, 1)

			this.setValue(theImages, "image_links")
			this._dirty = true
			this.doChangeCallbacks()
			done = true
		}

		if (this._MultiFileUploader) {
			const isChanged = this._MultiFileUploader.removeFileUploadByUUID(theUUID)
			if (isChanged) {
				this._dirty = true
				done = true
			}
		}
		return done
	}

	getImageURLs(): URL[] {
		return this.imageURLs
	}
	getHeroImageURL() {
		return this.heroImageURL
	}
	getHeroImageThumb() {
		return this.getHeroImageThumb
	}

	get heroImageURL() {
		const theImages = this.imageURLs
		if (theImages.length > 0) {
			return theImages[0]
		}
		return null
	}
	get heroImageThumb() {
		const theImages = this.imageThumbs
		if (theImages.length > 0) {
			return theImages[0]
		}
		return null
	}
	get imageURLs(): URL[] {
		return imageURLs(this)
	}

	get ImageItemsOrStrings(): (string | URL | mediaItem)[] {
		const theImages: string[] = this.getValue("image_links") || []
		return imageItemsOrStrings(theImages)
	}

	get imageStringorObjects(): (string | Buffer | { data: Buffer; format: "png" | "jpg" })[] {
		const theImages: (string | URL | mediaItem)[] = this.ImageItemsOrStrings
		return imageStringOrObjects(theImages)
	}

	get allImageURLs() {
		const imageLinks: URL[] = this.imageURLs
		if (this._MultiFileUploader) {
			this._MultiFileUploader.uploads.forEach((upload) => {
				const theURL = upload.URL
				imageLinks.push(theURL)
			})
		}
		return imageLinks
	}

	get imageLinksCopy(): string[] {
		const theImages: string[] = this.getValue("image_links") || []
		const imageLinks: string[] = []
		if (theImages.length > 0) {
			theImages.forEach((item) => {
				imageLinks.push(item)
			})
			return imageLinks
		}
		return []
	}
	get imageThumbs() {
		const theImages: string[] = this.getValue("image_links") || []
		const imageLinks: URL[] = []
		if (theImages.length > 0) {
			theImages.forEach((item) => {
				if (item.length > 0 && !item.endsWith("/")) {
					let theURL
					if (UUIDforMediaCollectionURL(item) !== "") {
						theURL = mediaCollectionURLforUUID(UUIDforMediaCollectionURL(item), "thumb")
					} else if (item.startsWith("http")) {
						theURL = new URL(item)
					} else if (isUUID(item)) {
						theURL = mediaCollectionURLforUUID(item, "thumb")
					} else {
						let modItem = item
						if (item.startsWith(".")) {
							modItem = item.substring(1)
						}
						if (!modItem.startsWith("/media/") && !modItem.startsWith("media/")) {
							modItem = "/media/" + item
						}
						theURL = new URL(modItem, getConfig("cdnUrl"))
					}

					imageLinks.push(theURL)
					printData(theURL, messageLevels.debug, item)
				}
			})
			return imageLinks
		}
		return []
	}

	get fileUploader() {
		if (!this._MultiFileUploader) {
			this._MultiFileUploader = new fileUploadSequencer()
			// this._MultiFileUploader.addCompletionCallback(this.storeEditPrepared);
		}
		return this._MultiFileUploader
	}

	// validForSave = () => {
	// 	const geo = this.getValue("geojson");
	// 	if(!geo) return false;

	// 	return true;
	// }

	keepChanges(callback?: Function): storedItem | null {
		// this.uploadAll();

		printData(this.fileUploader, messageLevels.debug, "fileUploader in keepChanges")
		const originalItem = super.keepChanges(callback) as blockFeature
		if (originalItem) {
			printData(originalItem, messageLevels.debug, "originalItem in keepChanges")
			;(originalItem as blockFeature).takeoverUploads(this.fileUploader)
			originalItem.uploadAll()
		}

		return originalItem
	}

	getParent() {
		return this.getRelatedItems("forest-blocks", this.getValue("consent_number"), "consent_number", "==", true)[0]
	}
	getRegionISO = () => {
		if (this._type && this._type?.objectRegionProperty) {
			return (this as any)[this._type.objectRegionProperty]
		}
		return this.getValue("iso") || this.getParent()?.getRegionISO() || "NZL"
	}
	get forestblock(): forestBlock {
		if (this.getValue("forest_block_id")) {
			return this.getRelatedItem("forest-blocks", this.getValue("forest_block_id"), "uuid") as forestBlock
		}
		return this.getRelatedItem("forest-blocks", this.getValue("consent_number"), "consent_number") as forestBlock
	}

	get mapSources(): forestBlock {
		return this.getRelatedItem("map-sources", this.getTypeName(), "dataSource") as forestBlock
	}
	get pinColour(): string {
		const theType = this.getTypeName()
		return pinColour(theType)
	}
	imagesAndRotations = () => {
		const theImages: string[] = this.getValue("image_links") || []
		return imagesAndRotations(theImages)
	}
	get attachments(): attachment[] {
		// return this.forestblock.reportAttachments || [];
		if (!this.forestblock || this.forestblock === undefined) {
			return []
		}
		const theList = this.forestblock?.reportAttachments || []
		const localID = this.getValue("local_id")
		const items: attachment[] = []

		const type = "as built skid site"
		theList.forEach((item) => {
			if (item.getValue("type") === type && item.getValue("feature_id") === localID) {
				items.push(item as attachment)
			}
		})
		return items
	}

	get forest_block_uuid() {
		let theBlockID = this.forestblock?.getValue("forest_block_id")
		if (theBlockID && theBlockID !== undefined) {
			return this.forestblock?.getValue("forest_block_id")
		}
		theBlockID = this.forestblock?.getValue("uuid")
		return theBlockID
	}

	levelName() {
		return this.getTypeName()
	}

	displayName(): string {
		const localID = this.getValue("local_id")
		if (localID && localID !== undefined) {
			return this.friendlyDisplayName + " " + localID
		} else {
			return this.friendlyDisplayName
		}
	}

	highlightOnMap = (): void => {}
	clearHighlightOnMap = (): void => {}
	selectOnMap = (skipSetState?: boolean): void => {
		printMessage("feature block select on map", messageLevels.debug)
		call("setLevelByItem", this, skipSetState)
	}
	static styleByPriority = () => {}
	static styleByCompliance = () => {}

	canConvertToTask = () => {
		const theType = this.getTypeName()
		if (theType === "areas-of-concern" || theType === "areas-of-failure") {
			const tasks = getStoreTypeByName("tasks")
			if (tasks && tasks.checkCreateAuthority(this.getRegionISO())) {
				return true
			}
		}
		return false
	}
	convertToTask = () => {
		return convertToTask(this)
	}
	validForSave = (): boolean => {
		if (keyNotSet(this, "geojson")) return false
		if (keyNotSet(this, "description")) return false
		return true
	}

	validationErrorForKey = (key: string): any => {
		switch (key) {
			case "description":
			case "geojson":
				return keyNotSet(this, key)

			default:
				return null
		}
	}
}

// function imageIsUploading(theItem: mediaItem | null): boolean {
// 	if (theItem) {
// 		const isUploading = !theItem.isUploaded;
// 		return isUploading;
// 	}
// 	return false;
// }

// function imageItemFromUUID(theUUID: string): mediaItem | null {
// 	const theCollectionItem = itemByKey("media-collection", theUUID);
// 	printData(theCollectionItem, messageLevels.debug, "theCollectionItem");
// 	if (theCollectionItem && theCollectionItem !== undefined) {

// 		return theCollectionItem as mediaItem;
// 	} else {
// 		const theImageItem = itemByKey("file-upload", theUUID);
// 		printData(theImageItem, messageLevels.debug, "theImageItem");
// 		if (theImageItem && theImageItem !== undefined) {

// 			return theImageItem as mediaItem;
// 		}
// 	}
// 	return null;
// };

function imageURLs(theItem: blockFeature) {
	const theImages: string[] = theItem.getValue("image_links") || []
	const imageLinks: URL[] = []
	if (theImages.length > 0) {
		theImages.forEach((item) => {
			if (item.length > 0 && !item.endsWith("/")) {
				let theURL
				if (item.startsWith("http")) {
					theURL = new URL(item)
				} else if (isUUID(item)) {
					theURL = mediaCollectionURLforUUID(item)
				} else {
					let modItem = item
					if (item.startsWith(".")) {
						modItem = item.substring(1)
					}
					if (!modItem.startsWith("/media/") && !modItem.startsWith("media/")) {
						modItem = "/media/" + item
					}
					theURL = new URL(modItem, getConfig("cdnUrl"))
				}

				imageLinks.push(theURL)
				printData(theURL, messageLevels.debug, item)
			}
		})
		return imageLinks
	}
	return []
}

function pinColour(type: string): string {
	printData(type, messageLevels.debug, "pinColour")
	switch (type) {
		case "areas-of-concern":
			return "#EE8A3B"
		case "areas-of-excellence":
			return "#408F3F"
		case "areas-of-failure":
			return "#D4322C"
		case "tasks":
			return "#9B2327"
		case "as-built-stream-crossings":
			return "#40A7DC"
		case "as-built-skid-sites":
			return "#10d0f0"
		case "general-panorama":
			return "#F5B944"
		case "aerial-panorama":
			return "#2373BB"
		case "ground-panorama":
			return "#62AB2E"
		case "quarries":
			return "#502D62"
	}

	return "#202020"
}
const convertToTask = (item: storedItem | undefined) => {
	if (!item) {
		printMessage("no item to create task for", messageLevels.error)
		return
	}
	const newTask: blockFeature | null = instanceNewItem("tasks") as blockFeature | null
	if (!newTask) {
		printMessage("task creation failed", messageLevels.error)
		return
	}
	newTask.setValue(item.getValue("description"), "description")
	newTask.setValue(item.getValue("geojson"), "geojson")
	newTask.setValue(item.getValue("consent_number"), "consent_number")
	newTask.setValue(item.getValue("region"), "region")
	newTask.setValue(item.getValue("image_links"), "image_links")
	const today = moment().format("DD-MM-YYYY")
	newTask.setValue(today, "date")
	return newTask
}
