import React from "react"
import { Divider, DropdownProps, Form, Header, Label, List, Segment, TextAreaProps } from "semantic-ui-react"

import { storedItem } from "../storedItem"
import { blockFeature } from "../classes/blockFeature"
import PinPlaceGeoJSONPoint from "../../mapsight/PinGeoJSONPoint"
import variables from "../../variables"
import ImageList from "../components/Image/ImageListComponent"
import { BlockFeatureEditView, BlockFeatureInfoView } from "./BlockFeature"
import { isStoredItem } from ".."
import { skidSite } from "../classes/skidSite"
const { skidComplianceOptions: compliance, skidSiteQuestions } = variables

export class SkidSiteEditView extends BlockFeatureEditView {
	onComplianceChange = (item: storedItem, question: string) => (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		this.setValue(data.value, question)
	}

	renderSkidSiteForm = (item: skidSite) => {
		const options: typeof compliance = JSON.parse(JSON.stringify(compliance))
		delete options.RWR
		return Object.entries(skidSiteQuestions).map(([key, question]) => {
			return (
				<Form.Select
					key={key}
					label={question}
					placeholder="Select a Compliance Status"
					name={`question-${item.primaryKey()}`}
					options={Object.values(options).map((value) => ({
						key: value.KEY,
						value: value.KEY,
						text: value.definition,
						label: { color: value.color.name },
					}))}
					onChange={this.onComplianceChange(item, key)}
					defaultValue={item.getDefaultComplianceObject(key)?.KEY}
					required
					error={this.errorForKey(key)}
				/>
			)
		})
	}

	onDescriptionChange = (item: storedItem) => (event: React.SyntheticEvent<HTMLElement, Event>, data: TextAreaProps) => {
		this.setValue(data.value, "description")
	}

	render() {
		const item = this.props.editingItem
		const pinColour = (item as blockFeature).pinColour
		const hidePin = this.props.hidePin || false
		if (!item || !isStoredItem(item)) {
			return <></>
		}
		return (
			<Form>
				{this.renderImageUpload(
					<>
						{this.renderHeading()}
						<Segment inverted basic>
							{this.renderSkidSiteForm(item as skidSite)}
							<Form.TextArea
								label="Description"
								maxLength={2000}
								placeholder="Description..."
								name="description"
								defaultValue={item.getValue("description")}
								onChange={this.onDescriptionChange(item)}
							/>
						</Segment>
						<Segment inverted basic>
							<div className="field required">
								<label>Location</label>
								{!hidePin ? (
									<PinPlaceGeoJSONPoint
										edit={true}
										pinColor={pinColour}
										initialLocation={item?.getValue("geojson")}
										error={this.errorForKey("geojson")}
										setValue={this.handleGeoChange}
									></PinPlaceGeoJSONPoint>
								) : (
									<></>
								)}
							</div>
						</Segment>
					</>,
				)}
			</Form>
		)
	}
}

export class SkidSiteInfoView extends BlockFeatureInfoView {
	render() {
		const { selectedItem } = this.props

		if (!selectedItem) {
			return <></>
		}
		const imageLinks = (selectedItem as blockFeature).imageURLs
		const theLocation = selectedItem?.getValue("geojson")
		const compliance_status =
			selectedItem.getValue("status") === "null" || !selectedItem.getValue("status") ? "NA" : selectedItem.getValue("status")
		const pinColour = (selectedItem as blockFeature).pinColour
		return (
			<div>
				<Segment inverted basic>
					{this.renderNameHeader()}
					{/* <Header as="h2" inverted>
						{selectedItem.friendlyDisplayName} #{selectedItem.getValue('local_id')}
					</Header> */}
					<Label horizontal className="compliance-status" color={compliance[compliance_status].color.name}>
						{compliance[compliance_status].definition}
					</Label>
					<Divider />
					<ImageList imageLinks={imageLinks}></ImageList>
				</Segment>
				<Segment inverted basic>
					<List as="ol">
						{Object.entries(skidSiteQuestions).map(([key, question], i) => {
							if (key === "Skid_Compliant") return null
							const colorCode = (selectedItem as skidSite).colorForStatusField(key).code
							const definition = (selectedItem as skidSite).definitionForStatusField(key)
							return (
								<List.Item as="li" className="skid-questions">
									{question}
									<div style={{ color: colorCode }}>{definition}</div>
								</List.Item>
							)
						})}
					</List>
				</Segment>
				<Segment inverted basic>
					<Header as="h4" inverted>
						Description
					</Header>
					<Divider />
					{selectedItem.getValue("description") ? selectedItem.getValue("description") : "No description available"}
				</Segment>
				<Segment inverted basic>
					<Header as="h4" inverted>
						Location
					</Header>
					<PinPlaceGeoJSONPoint edit={false} pinColor={pinColour} initialLocation={theLocation}></PinPlaceGeoJSONPoint>
				</Segment>
			</div>
		)
	}
}
