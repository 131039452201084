import React from "react"
import { DropdownProps, Form, Grid, Icon, InputOnChangeData, TextAreaProps } from "semantic-ui-react"
import DatePicker from "react-datepicker"
import moment from "moment"

import { storedItem } from "../storedItem"
import variables from "../../variables"
import { isStoredItem } from ".."
import { skidSite } from "../classes/skidSite"
import { StoredItemEditView, StoredItemInfoView } from "./StoredItem"
import Section from "../../components/Section"
import { forestOwner } from "../classes/forest"
import { ItemViewProps } from "./Types"

export class ForestOwnershipEditView extends StoredItemEditView {
	onDescriptionChange = (item: storedItem) => (event: React.SyntheticEvent<HTMLElement, Event>, data: TextAreaProps) => {
		item.setValue(data.value, "description")
	}

	onChange = (name: string) => (event: React.SyntheticEvent<HTMLElement, Event> | null, data: InputOnChangeData) => {
		this.setValue(data.value, name)
	}

	onYearChange = (startYear: string, endYear: string) => {
		const plantYear = `${startYear}-${endYear}`
		this.setValue(plantYear, "plant_year")
	}

	render() {
		const item = this.props.editingItem
		const theForest = item as forestOwner
		let startYear: any
		let endYear: any
		const thePlantYear = theForest.getValue("plant_year")
		if (thePlantYear && typeof thePlantYear === "string") {
			;[startYear, endYear] = thePlantYear.split("-")
		}

		if (!item || !isStoredItem(item)) {
			return <></>
		}
		return (
			<div id="forest">
				<Section title={theForest.displayName()} size="h3">
					<Grid columns={2} padded>
						<>
							<Grid.Column width={6} className="icon-column">
								<Icon name={"expand"} size={"big"} />
							</Grid.Column>
							<Grid.Column width={10} className="details">
								<div className="title">Area:</div>
								<Form.Input defaultValue={theForest.getValue("area_ha")} disabled />
							</Grid.Column>
							<Grid.Column width={6} className="icon-column">
								<Icon name={"user"} size={"big"} />
							</Grid.Column>
							<Grid.Column width={10} className="details">
								<div className="title">Owner:</div>
								<Form.Input defaultValue={theForest.getValue("owner")} onChange={this.onChange("owner")} />
							</Grid.Column>
							<Grid.Column width={6} className="icon-column">
								<Icon name={"bug"} size={"big"} />
							</Grid.Column>
							<Grid.Column width={10} className="details">
								<div className="title">Species:</div>
								<Form.Input defaultValue={theForest.getValue("species")} onChange={this.onChange("species")} />
							</Grid.Column>
							<Grid.Column width={6} className="icon-column">
								<Icon name={"calendar alternate outline"} size={"big"} />
							</Grid.Column>
							<Grid.Column width={10} className="details">
								<div className="title">Years Planted:</div>
								<Grid columns={3}>
									<>
										<Grid.Column width={8} className="details" style={{ paddingLeft: 0, paddingRight: 0 }}>
											<DatePicker
												showYearPicker
												isClearable
												placeholderText="Start Year"
												dateFormat="yyyy"
												value={startYear}
												onChange={(year) => {
													this.onYearChange(moment(year).format("yyyy"), endYear)
												}}
												customInput={<Form.Input />}
											/>
										</Grid.Column>
										<div
											style={{
												top: "50%",
												left: "50%",
												padding: 0,
												position: "absolute",
											}}
										>
											-
										</div>
										<Grid.Column width={8} className="details" style={{ paddingLeft: 0, paddingRight: 0 }}>
											<DatePicker
												showYearPicker
												isClearable
												placeholderText="End Year"
												dateFormat="yyyy"
												value={endYear}
												onChange={(year) => this.onYearChange(startYear, moment(year).format("yyyy"))}
												customInput={<Form.Input />}
											/>
										</Grid.Column>
									</>
								</Grid>
							</Grid.Column>
							<Grid.Column width={6} className="icon-column">
								<Icon name={"database"} size={"big"} />
							</Grid.Column>
							<Grid.Column width={10} className="details">
								<div className="title">Source:</div>
								<Form.Input defaultValue={theForest.getValue("image_source")} onChange={this.onChange("image_source")} />
							</Grid.Column>
							{this.render}
						</>
					</Grid>
				</Section>
			</div>
		)
	}
}

export class ForestOwnershipInfoView extends StoredItemInfoView<ItemViewProps> {
	render() {
		const { selectedItem } = this.props

		if (!selectedItem) {
			return <></>
		}
		const theForest = selectedItem as forestOwner
		return (
			<div id="forest">
				<Section
					title={
						<>
							<div>
								{theForest.displayName()} ({theForest.formattedArea()} ha)
							</div>
						</>
					}
				>
					<Grid columns={2} padded>
						<Grid.Column width={6} className="icon-column">
							<Icon name={"tree"} size={"big"} />
						</Grid.Column>
						<Grid.Column width={10} className="details">
							<div className="title">Stand Age:</div>
							<div>
								{theForest.ageFromEnd()}-{theForest.ageFromStart()} years old
							</div>
						</Grid.Column>
						<Grid.Column width={6} className="icon-column">
							<Icon name={"user"} size={"big"} />
						</Grid.Column>
						<Grid.Column width={10} className="details">
							<div className="title">Owner:</div>
							<div>{theForest.getValue("owner") || "Unknown"}</div>
						</Grid.Column>
						<Grid.Column width={6} className="icon-column">
							<Icon name={"bug"} size={"big"} />
						</Grid.Column>
						<Grid.Column width={10} className="details">
							<div className="title">Species:</div>
							<div>{theForest.getValue("species") || "Unknown"}</div>
						</Grid.Column>
						<Grid.Column width={6} className="icon-column">
							<Icon name={"calendar alternate outline"} size={"big"} />
						</Grid.Column>
						<Grid.Column width={10} className="details">
							<div className="title">Years Planted:</div>
							<div>{theForest.getValue("plant_year") || "Unknown"}</div>
						</Grid.Column>
						<Grid.Column width={6} className="icon-column">
							<Icon name={"database"} size={"big"} />
						</Grid.Column>
						<Grid.Column width={10} className="details">
							<div className="title">Source:</div>
							<div>{theForest.getValue("image_source") || "Unknown"}</div>
						</Grid.Column>
					</Grid>
				</Section>
			</div>
		)
	}
}
