import React from "react"
import "./styles.css"

interface ImageUploadOverLayProps {
	isDragging: boolean
	isMaximum?: boolean
}

const ImageUploadOverLay = (props: ImageUploadOverLayProps) => {
	const { isDragging, isMaximum } = props
	return (
		<div
			className={`image-upload-overlay ${isMaximum ? "image-upload-overlay-warn" : "image-upload-overlay-primary"}`}
			style={{ display: isDragging ? "flex" : "none" }}
		>
			<div className={`image-upload-overlay-bg ${isMaximum ? "image-upload-overlay-warn" : "image-upload-overlay-primary"}`} />
		</div>
	)
}

export default ImageUploadOverLay
