import React from "react"

import ValueOnly from "../../../components/info-box/ValueOnly"

interface IDashboardStatisticComponentProps {
	name: string
	value?: number
}

interface IStatistics {
	[key: string]: {
		fetch: () => any
		render: (value: string | number | undefined, isLoading: boolean) => JSX.Element
	}
}

const statistic: IStatistics = {
	notification: {
		fetch: () => {},
		// fetch: DashboardHandlers.requestNotifications,
		render: (value, isLoading) => (
			<ValueOnly name="Total Notifications" value={value || 0} isLoading={isLoading} icon="sticky note" color="grey" />
		),
	},
	roadsKms: {
		fetch: () => {},
		// fetch: DashboardHandlers.requestRoadsKms,
		render: (value, isLoading) => <ValueOnly name="KM Road Assessed" value={value} isLoading={isLoading} icon="road" color="grey" />,
	},
	tracksKms: {
		fetch: () => {},
		// fetch: DashboardHandlers.requestTracksKms,
		render: (value, isLoading) => <ValueOnly name="KM Tracks Assessed" value={value} isLoading={isLoading} icon="road" color="grey" />,
	},
	aof: {
		fetch: () => {},
		// fetch: DashboardHandlers.requestAof,
		render: (value, isLoading) => (
			<ValueOnly name="Areas of Failure" value={value} isLoading={isLoading} icon="thumbs down" color="red" />
		),
	},
	aoc: {
		fetch: () => {},
		// fetch: DashboardHandlers.requestAoc,
		render: (value, isLoading) => (
			<ValueOnly name="Areas of Concern" value={value} isLoading={isLoading} icon="circle exclamation" color="orange" />
		),
	},
	compliant: {
		fetch: () => {},
		// fetch: DashboardHandlers.requestCompliant,
		render: (value, isLoading) => (
			<ValueOnly name="Compliant Blocks" value={value} isLoading={isLoading} icon="sticky note" color="green" />
		),
	},
	nonCompliant: {
		fetch: () => {},
		// fetch: DashboardHandlers.requestNonCompliant,
		render: (value, isLoading) => (
			<ValueOnly name="Non Compliant" value={value} isLoading={isLoading} icon="sticky note" color="orange" />
		),
	},
	significantlyNonCompliant: {
		fetch: () => {},
		// fetch: DashboardHandlers.requestSignificantNonCompliant,
		render: (value, isLoading) => (
			<ValueOnly name="Significantly Non Compliant" value={value} isLoading={isLoading} icon="sticky note" color="red" />
		),
	},
	technicallyNonCompliant: {
		fetch: () => {},
		// fetch: DashboardHandlers.requestTechnicallyNonCompliant,
		render: (value, isLoading) => (
			<ValueOnly name="Technically Non Compliant" value={value} isLoading={isLoading} icon="sticky note" color="yellow" />
		),
	},
	notAssessed: {
		fetch: () => {},
		// fetch: DashboardHandlers.requestNotAssessed,
		render: (value, isLoading) => (
			<ValueOnly name="Not Yet Assessed" value={value} isLoading={isLoading} icon="question" color="blue" />
		),
	},
	remedialWorksRequired: {
		fetch: () => {},
		// fetch: DashboardHandlers.requestNotifications,
		render: (value, isLoading) => (
			<ValueOnly name="Remedial Works Required" value={value || 0} isLoading={isLoading} icon="sticky note" color="purple" />
		),
	},
	areaOfExcellence: {
		fetch: () => {},
		// fetch: DashboardHandlers.requestAreaOfExcellence,
		render: (value, isLoading) => (
			<ValueOnly name="Areas of Excellence" value={value} isLoading={isLoading} icon="thumbs up" color="green" />
		),
	},
}

const DashboardStatisticComponent: React.FC<IDashboardStatisticComponentProps> = ({ name, value }) => {
	// const { value, loading } = useSelector(
	//   (state: IAppState) => state.dashboardStore[name]
	// );
	// const dispatch = useDispatch();
	// const getStatistic = () => dispatch(statistic[name].fetch());

	// useEffect(getStatistic, []);

	// return statistic[name].render(value, loading);
	if (statistic[name]) {
		return statistic[name].render(value || 0, false)
	}
	return null
}

export default DashboardStatisticComponent
