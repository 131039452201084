import React from "react"
import { Divider, Grid, Icon, Label, Form, Accordion, Segment } from "semantic-ui-react"
import { ItemViewProps } from "../Types"
import { printData } from "../../../providers/remoteHQ"
import { messageLevels } from "../../types"
import variables from "../../../variables"
import CollectionImage from "../../../mapsight/CollectionImage"
import SelectionListComponent from "../../components/SelectionListComponent"
import { blockFeature } from "../../classes/blockFeature"
import ImageList from "../../components/Image/ImageListComponent"
import { attachment } from "../../classes/attachment"
import { ReportItem } from "../../classes/report"
import { skidSite } from "../../classes/skidSite"
import { selectionItem, SelectionItemList } from "../../managers/SelectionList"
import { storedItem } from "../../storedItem"
import { DisplayTextComponent } from "../../Forms/react/Items/DisplayTextComponent"
import { DisplayInputComponent } from "../../Forms/react/Items/DisplayInputComponent"

const { skidComplianceOptions: compliance, skidSiteQuestions } = variables

export interface ReportSummaryState {
	activeAccordion: number | null
	activeSkid: number | null
}

export default class ReportSummaryView extends React.Component<ItemViewProps, ReportSummaryState> {
	state = { activeAccordion: null, activeSkid: null }

	renderContent = (key: string) => {
		switch (key) {
			case "details":
				return this.renderDetailContent()
			case "aoe":
				return this.renderAreaContent()
			case "maps":
				return this.renderMapsContent()
			case "tasks":
				return this.renderTaskContent()
			case "skidSites":
				return this.renderSkidSitesContent()
		}
	}

	renderDetailContent = () => {
		const { selectedItem } = this.props

		if (!selectedItem) return null

		return (
			<>
				<Form>
					<DisplayInputComponent
						item={selectedItem}
						field={{
							name: "title",
							label: "Save As:",
							type: "displayinput",
						}}
						index={"title"}
					/>
					<DisplayInputComponent
						item={selectedItem}
						field={{
							name: "compliance_status",
							label: "Compliance Status:",
							type: "displayinput",
							mandatory: true,
						}}
						index={"compliance_status"}
					/>
					<DisplayInputComponent
						item={selectedItem}
						field={{
							name: "contact_person",
							label: "Contact Person / Manager:",
							type: "displayinput",
							mandatory: true,
						}}
						index={"contact_person"}
					/>
					<DisplayInputComponent
						item={selectedItem}
						field={{
							name: "earthworks_contractor",
							label: "Earthworks Contractor:",
							type: "displayinput",
							mandatory: true,
						}}
						index={1}
					/>
					<DisplayInputComponent
						item={selectedItem}
						field={{
							name: "harvesting_contractor",
							label: "Harvesting Contractor:",
							type: "displayinput",
							mandatory: true,
						}}
						index={1}
					/>
					<DisplayTextComponent
						item={selectedItem}
						field={{
							name: "status_of_work",
							label: "Status of work and area inspected:",
							type: "displaytext",
							mandatory: true,
						}}
						index={1}
					/>
					<DisplayTextComponent
						item={selectedItem}
						field={{
							name: "proximity_to_waterways",
							label: "Waterways or Ocean in/bordering block:",
							type: "displaytext",
							mandatory: true,
						}}
						index={1}
					/>
				</Form>
			</>
		)
	}
	aoeCellFormatter = (item: selectionItem, list: SelectionItemList) => {
		const imageLinks = (item.item as blockFeature).imageURLs
		return (
			<Grid columns={4} padded key={item.item.primaryKey()}>
				<ImageList imageLinks={imageLinks}></ImageList>
			</Grid>
		)
	}

	renderAreaContent = () => {
		const { selectedItem } = this.props
		const aoes = (selectedItem as ReportItem).aoeSelectionList
		if (!aoes) {
			return null
		}
		return (
			<Grid columns={4} padded>
				<SelectionListComponent data={aoes} cellFormatter={this.aoeCellFormatter} noWrapper onlySelected></SelectionListComponent>
			</Grid>
		)
	}

	renderMapsContent = () => {
		// const getOrientation = (value: string | number | undefined) => {
		// 	if (value === undefined) return "";
		// 	return Number(value) === 0 ? "Portrait" : "Landscape";
		// };

		const getSrc = (data: File | string | null | undefined) => {
			if (data && Array.isArray(data)) {
				return data![0]
			} else if (data) {
				return data && (typeof data === "string" ? data : URL.createObjectURL(data))
			}
		}
		return (
			<>
				{this.renderMap("Context Map (cover page)", "context")}
				<Divider />
				{this.renderMap("Task Map", "task")}
				<Divider />
				{this.renderMap("Consented Map vs Built Map", "consentedVsBuilt")}
				<Divider />
				{this.renderMap("Skid Site Map", "skidSite")}
			</>
		)
	}
	getOrientation = (value: string | number | undefined) => {
		if (value === undefined) return ""
		return Number(value) === 0 ? "Portrait" : "Landscape"
	}

	getSrc = (data: File | string | null | undefined) => {
		if (data && Array.isArray(data)) {
			return data![0]
		} else if (data) {
			return data && (typeof data === "string" ? data : URL.createObjectURL(data))
		}
	}
	renderMap = (label: string, key: string) => {
		const theURL = (this.props.selectedItem as ReportItem).getMapURLByKey(key)
		const theOrientation = (this.props.selectedItem as ReportItem).getMapOrientationByKey(key)
		const orientation = this.getOrientation(theOrientation)

		return (
			<React.Fragment key={key}>
				<div className="field">
					<label>{label}</label>
				</div>
				<Grid>
					<Grid.Column width={4} verticalAlign="middle">
						<CollectionImage imageLink={theURL}></CollectionImage>
					</Grid.Column>
					<Grid.Column width={4} verticalAlign="middle">
						{label}
					</Grid.Column>
					<Grid.Column width={4} verticalAlign="middle">
						{orientation}
					</Grid.Column>
				</Grid>
			</React.Fragment>
		)
	}
	taskCellFormatter = (item: selectionItem) => {
		// const { data } = this.props;
		// const taskId: string = item.item.displayName();
		// const imageLinkThumb = (item.item as blockFeature).heroImageThumb;
		const imageLink = (item.item as blockFeature).heroImageURL
		// const getSrc = (data: File | string | null | undefined) =>
		// 	data && (typeof data === "string" ? data : URL.createObjectURL(data));
		return (
			<React.Fragment key={item.item.primaryKey()}>
				<Grid.Column width={8} verticalAlign="middle">
					<div className="field">
						<label>{item.item.getValue("description")?.substring(0, 50)}…</label>
					</div>
				</Grid.Column>
				<Grid.Column width={4} verticalAlign="middle">
					<CollectionImage imageLink={imageLink} />
				</Grid.Column>
				<Grid.Column width={4} verticalAlign="middle">
					{item.item.getValue("name")}
				</Grid.Column>
			</React.Fragment>
		)
	}
	skidCellFormatter = (item: selectionItem) => {
		const { activeSkid } = this.state
		const skidSite = item.item as skidSite
		const skidKey = skidSite.primaryKey()
		const skidDisplayName = skidSite.displayName()
		const skidThumbs = (skidSite as blockFeature).imageThumbs
		return (
			<Accordion fluid styled key={`skidSite-${skidKey}`} inverted>
				<Accordion.Title
					active={activeSkid === skidKey}
					index={0}
					onClick={() =>
						this.setState({
							activeSkid: activeSkid === skidKey ? null : skidKey,
						})
					}
				>
					{skidDisplayName}
					<Icon name="dropdown" />
				</Accordion.Title>
				<Accordion.Content active={activeSkid === skidKey}>
					{Object.entries(skidSiteQuestions).map(([key, question]) => {
						const theColor = skidSite.labelColorForStatusField(key)
						return (
							<>
								<div className="field">
									<label>{question}</label>
								</div>
								<Label circular color={theColor} empty /> {skidSite.definitionForStatusField(key)}
								<Divider />
							</>
						)
					})}

					<div className="field">
						<label>Description</label>
					</div>
					{skidSite.getValue("description")}
					<Divider />
					<div className="field">
						<label>Images</label>
					</div>
					<Grid columns={4} padded>
						<ImageList imageLinks={skidThumbs}></ImageList>
					</Grid>
				</Accordion.Content>
			</Accordion>
		)
	}

	renderTaskContent = () => {
		const { selectedItem } = this.props
		const tasks = (selectedItem as ReportItem)?.taskSelectionList
		if (!tasks) return null
		return (
			<Grid>
				<SelectionListComponent data={tasks} cellFormatter={this.taskCellFormatter} noWrapper onlySelected></SelectionListComponent>
			</Grid>
		)
	}

	renderSkidSitesContent = () => {
		const { selectedItem } = this.props
		const skids = (selectedItem as ReportItem)?.skidSelectionList
		if (!skids) return null
		return (
			<Grid>
				<SelectionListComponent data={skids} cellFormatter={this.skidCellFormatter} noWrapper onlySelected></SelectionListComponent>
			</Grid>
		)
	}

	renderSkidImages = (attachments: attachment[]) => {
		printData(attachments, messageLevels.debug, "attachments in confirm")
		// return <ImageList imageLinks={} >

		// </ImageList>;

		return attachments.map((attachment) => {
			const image = attachment.imageURL
			if (!image) return null
			return <CollectionImage imageLink={image} />
		})
	}

	renderSection(i: number, key: string, title: string) {
		const { activeAccordion } = this.state
		return (
			<Accordion fluid styled inverted key={key}>
				<Accordion.Title
					active={activeAccordion === i}
					index={0}
					onClick={() =>
						this.setState({
							activeAccordion: activeAccordion === i ? null : i,
						})
					}
				>
					{title === "Aoe" ? "Areas of Excellence (AOE)" : title}
					<Icon name="dropdown" />
				</Accordion.Title>
				<Accordion.Content active={activeAccordion === i}>{this.renderContent(key)}</Accordion.Content>
			</Accordion>
		)
	}

	render() {
		const { visible } = this.props

		return (
			<Form>
				<div className={`report-form${visible ? " active" : ""}`}>
					{this.renderSection(0, "details", "Details")}
					{this.renderSection(1, "aoe", "Areas of Excellence")}
					{this.renderSection(2, "maps", "Maps")}
					{this.renderSection(3, "tasks", "Tasks")}
					{this.renderSection(4, "skidSites", "Skid Sites")}
				</div>
			</Form>
		)
	}
}
function colorForItemKey(item: storedItem, key: string) {
	return item.validationForKey(key) ? undefined : "red"
}
