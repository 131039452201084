import React from "react"
import { Breadcrumb, Grid, Icon, Label, Popup, Progress } from "semantic-ui-react"

import {
	call,
	getGlobalState,
	getUIConfig,
	lastProcessMessage,
	processPercentComplete,
	registerOnProcessChange,
	registerforStateChanges,
	removeOnProcessChange,
	removeStateChanges,
	sendsInProgress,
	sendsToGo,
} from "../../store"
import { printData } from "../../providers/remoteHQ"
import { messageLevels } from "../../store/types"
import { storedItem } from "../../store/storedItem"
import { StatusPopup } from "../StatusPopup"
import "./styles.css"

interface IStatusBarState {
	levelStep: number
	region: any
	forestBlock: any
	lat: string
	lng: string
	zoom: string
	levels: storedItem[]
	hovers: storedItem[]
	statusMessage: string
	sendsInProgress: boolean
	sendsToGo: number
}

export default class StatusBar extends React.Component<{}, IStatusBarState> {
	state = {
		levelStep: 0,
		region: {},
		forestBlock: {},
		lat: "",
		lng: "",
		zoom: "",
		levels: getGlobalState("levels")?.getValue(),
		hovers: getGlobalState("hovers")?.getValue(),
		statusMessage: lastProcessMessage(),
		sendsInProgress: sendsInProgress(),
		sendsToGo: sendsToGo(),
	}

	componentDidMount(): void {
		registerforStateChanges(this.stateChanged)
		registerOnProcessChange(this.processStateChanged)
	}
	componentWilllUnmount(): void {
		removeStateChanges(this.stateChanged)
		removeOnProcessChange(this.processStateChanged)
	}

	stateChanged = async (type: string, key: string, data: any) => {
		if (key === "levels") {
			this.setState({ levels: data?.getValue() })
		}
		if (key === "hovers") {
			this.setState({ hovers: data?.getValue() })
		}
		if (key === "map-current-view") {
			const { lat, lng, zoom } = data
			this.setState({ lat, lng, zoom })
		}
	}
	processStateChanged = (type: string, key: string, data: any) => {
		const theMessage = lastProcessMessage()
		printData(theMessage, messageLevels.debug, "lastProcessMessage in procesStateChanged in statusBar")
		this.setState({
			statusMessage: lastProcessMessage(),
			sendsInProgress: sendsInProgress(),
			sendsToGo: sendsToGo(),
		})
	}

	handleClick = (item: storedItem) => {
		printData(item, messageLevels.debug)
		// TODO - following layer specific logic should be in config and not in code
		// if(item.getTypeName() !== 'water-controls')
		call("setLevelByItem", item)
	}

	homeButtonAction = () => {
		call("goToHome", null)
	}
	hoversCleaned = (): storedItem[] => {
		if (!this.state.hovers) {
			return []
		}
		if (!this.state.levels) {
			return this.state.hovers
		}
		const hoversClean: storedItem[] = []

		const hovers: storedItem[] = [...this.state.hovers]
		const levels = this.state.levels
		const levelItems: storedItem[] = []
		levels.forEach((level: any) => {
			const { selectedItems } = level
			selectedItems.forEach((item: storedItem) => {
				levelItems.push(item)
			})
		})

		hovers.forEach((item: storedItem) => {
			if (levelItems.indexOf(item) < 0) {
				hoversClean.push(item)
			}
		})
		return hoversClean
	}
	// TODO Wire home up to home function
	render() {
		const { lat, lng, zoom, levels, statusMessage, sendsInProgress } = this.state
		const { brandColor } = getUIConfig("clientUI")
		const hovers: storedItem[] = this.hoversCleaned()

		// const sendsToGoMessage = sendsToGo > 0 ? sendsToGo + " items to send" : "";
		// const statusDivider = sendsToGoMessage !== "" && statusMessage !== "" ? " - " : "";
		const breadcrumbSize = levels && levels.length > 5 ? "mini" : "small"
		return (
			<div id="status-bar">
				<Grid columns={3} divided>
					<Grid.Row>
						<Grid.Column width={8}>
							<Breadcrumb size={breadcrumbSize}>
								<Breadcrumb.Section onClick={() => this.homeButtonAction()} style={{ color: brandColor }} link>
									Home
								</Breadcrumb.Section>
								{levels && levels.length > 0 ? <Breadcrumb.Divider icon="right chevron" /> : null}

								{levels
									? levels.map((level: any, index: number) => {
											const { selectedItems } = level
											if (selectedItems) {
												return (
													<span key={index}>
														{selectedItems.map((item: any, index: number) => {
															// TODO - following layer specific logic should be in config and not in code

															return (
																<Breadcrumb.Section
																	key={index}
																	style={{ color: brandColor, fontWeight: "bold" }}
																	onClick={() => this.handleClick(item)}
																	link={true}
																>
																	{item.displayName()}
																</Breadcrumb.Section>
															)
														})}
														{index < levels.length - 1 && <Breadcrumb.Divider icon="right chevron" />}
													</span>
												)
											}
											return null
									  })
									: null}
								{hovers && hovers.length > 0 ? <Breadcrumb.Divider icon="right arrow" /> : null}
								{hovers
									? hovers.map((level: any, index: number) => {
											// const { selectedItems } = level;
											if (hovers) {
												return (
													<span key={index}>
														{hovers.map((item: any, index: number) => {
															// TODO - following layer specific logic should be in config and not in code
															const blinker = "blink"
															return (
																<Breadcrumb.Section key={index} className={blinker}>
																	{item.displayName()}
																</Breadcrumb.Section>
															)
														})}
														{index < hovers.length - 1 && <Breadcrumb.Divider icon="right chevron" />}
													</span>
												)
											}
											return null
									  })
									: null}
								{}
							</Breadcrumb>
						</Grid.Column>
						<Grid.Column width={7}>
							<span style={{ color: brandColor }}>
								Zoom: {zoom}, Lat: {lat}, Lng: {lng}
							</span>
						</Grid.Column>
						<Grid.Column width={1}>
							<StatusPopup />
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		)
	}
}
