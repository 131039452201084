import { call } from "../../store"
import { printData, printMessage } from "../../providers/remoteHQ"
import { storedItem } from "../../store/storedItem"
import { messageLevels } from "../types"
import { mapSource } from "./mapSource"
import { dataFeed, getDataFeedByName } from "../dataFeed"
import { primaryKeys } from "../../mapsight/FeedListComponent"

const emptyStoredArray: storedItem[] = []
const emptyGeoJSON = { type: "FeatureCollection", features: [] }

export class mapDataSource extends mapSource {
	get feedName() {
		return "map-" + (this.dataSourceType || "forest-blocks")
	}
	get dataSourceType() {
		return this.getValue("dataSource") || this.getValue("sourceType") || this.getValue("storeType")
	}
	get relationshipName() {
		return this.getValue("sourceRelationship")
	}
	get overrideGeoJSON() {
		return (this as any)._useOverride
	}
	set overrideGeoJSON(state: boolean) {
		;(this as any)._useOverride = state
		this.prepareMapData()
	}
	set alternateStoredData(theData: storedItem[]) {
		;(this as any)._alternateData = theData
	}
	set alternateGeoData(theData: any[]) {
		;(this as any)._alternateGeoData = theData
		if (this.overrideGeoJSON) {
			this.prepareMapData()
		}
	}
	dataLoaded = (type: string, key?: string, data?: storedItem[]) => {
		printMessage("DataLoaded in mapDataFeedSource " + this.primaryKey(), messageLevels.debug)
		printMessage("type: " + type, messageLevels.debug)
		printMessage("key: " + key, messageLevels.debug)

		if (this.getValue("dataSource") !== undefined) {
			if (key === this.feedName) {
				;(this as any)._dataCache = data || emptyStoredArray
				const theKeys = primaryKeys((this as any)._dataCache)
				const existingKeys = this.getValue("_listKeys")

				if (!compareArrays(existingKeys as string[], theKeys)) {
					this.prepareMapData()
					;(this as any)._listKeys = theKeys
				}
			}
		}
	}

	mounted() {
		super.mounted()
		;(this as any).geoData = emptyGeoJSON
		;(this as any)._listKeys = []
		;(this as any)._dataCache = emptyStoredArray
		;(this as any)._useOverride = false
		;(this as any)._alternateData = emptyStoredArray
		;(this as any)._alternateGeoData = emptyGeoJSON
		this.setupDataSource()
	}
	setupDataSource = () => {
		this.setValue(emptyStoredArray, "_dataCache")

		if ((this as any).dataSource !== undefined) {
			printMessage("Setting up source ID: " + this.primaryKey(), messageLevels.debug)
			;(this as any)._dataFeed = getDataFeedByName("map-" + (this as any).dataSource || "forest-blocks", true)
			;(this as any)._dataFeed.setupSource(this.dataSourceType, [])
			;((this as any)._dataFeed as dataFeed).registerOnChange(this.dataLoaded, {})
			printData((this as any)._dataFeed, messageLevels.debug)
		} else {
			printMessage("dataFeed source ID: " + this.primaryKey() + " has no dataSource property", messageLevels.error)
		}
		;(this as any)._selectedItem = null
	}

	stateChanged = (type: string, key?: string, data?: storedItem) => {
		printMessage("stateChanged in mapDataFeedSource " + this.primaryKey(), messageLevels.debug)

		printMessage("key: " + key, messageLevels.debug)
		// printData( data ,messageLevels.debug)
		this.stateChangeHandler(key, data)
	}

	stateChangeHandler = (key?: string, data?: storedItem) => {
		if (key === "selected") {
			// if(data !== (this as any)._selectedItem ){
			printMessage("selection changed in mapDataFeedSource " + this.primaryKey(), messageLevels.debug)
			;(this as any)._selectedItem = data?.getValue()[0] || null
			this.prepareMapData()
			// }
		}
	}

	prepareMapData = () => {
		printMessage("prepareMapData in mapDataSource " + this.primaryKey(), messageLevels.debug)

		// get the config for properties
		if (this.overrideGeoJSON) {
			this.updateMapData(this.alternateGeoData)
		}
		const theProperties = this.getValue("properties")
		const theData = this.getValue("_dataCache")

		if (!theData || theData === undefined || theData.length < 1) {
			printMessage("no data in " + this.primaryKey(), messageLevels.debug)
			this.updateMapData(emptyGeoJSON)
			return
		} else {
			const theFeatures: any = []

			theData.forEach((element: storedItem) => {
				// printMessage("ISO: " +element.getRegionISO(), messageLevels.debug)
				const theGeoJSON = element.getValue("geojson")
				const featureTemplate: {
					type: string
					geometry: any
					properties: { [key: string]: string }
				} = {
					// feature for Mapbox DC
					type: "Feature",
					geometry: {
						type: "Point",
						coordinates: [173.427845933, -41.537825122],
					},
					properties: {
						key: "-",
					},
				}

				if (theGeoJSON) {
					featureTemplate.geometry = JSON.parse(theGeoJSON)

					//  run through the properties and add them
					if (theProperties && theProperties.length > 0) {
						theProperties.forEach((property: string) => {
							featureTemplate.properties[property] = element.getValue(property)
						})
					}
					featureTemplate.properties.key = element.primaryKey()

					theFeatures.push(featureTemplate)
				}

				printData(featureTemplate, messageLevels.debug)
			})

			printData(theFeatures, messageLevels.debug)

			const theFinalData = {
				type: "FeatureCollection",
				features: theFeatures,
			}

			this.updateMapData(theFinalData)
		}
	}

	get geoData(): any {
		return (this as any)._geoData || emptyGeoJSON
	}

	set geoData(value) {
		;(this as any)._geoData = value
	}

	updateMapData(data: any) {
		printMessage(`Updating map data for ${this.primaryKey()}`, messageLevels.debug)
		printData(data, messageLevels.debug, "the data")
		this.geoData = data
		call("updateSourceData", this, data || emptyGeoJSON)
	}

	getRelationshipData() {
		const theItem = (this as any)._selectedItem

		printData(theItem, messageLevels.debug, "theItem in getRelationshipData")

		const theRelationshipName = this.relationshipName

		printData(theRelationshipName, messageLevels.debug, "theRelationshipName in getRelationshipData")

		if (theItem && theRelationshipName) {
			const theItemsArray = (theItem as any).getValue(theRelationshipName)
			return theItemsArray
		}
		return null
	}
}
function compareArrays(arr1: string[], arr2: string[]) {
	if (arr1.length !== arr2.length) {
		return false
	}
	for (let x = 0; x < arr1.length; x++) {
		if (arr1[x] !== arr2[x]) {
			return false
		}
	}
	return true
}
