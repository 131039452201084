import React, { Component, ReactNode } from "react"
import { Button, Form, Item, Segment } from "semantic-ui-react"

import { IQueryParams, messageLevels } from "../types"
import { storedItem } from "../storedItem"
// import { FormBlockComponent } from "./FormBlock";
import { FormItem } from "./Items/FormItem"
import { FormFieldProperties, FormPageItemProps, validateField } from "."
import { printMessage } from "../../providers/remoteHQ"
import ItemContext from "./ItemContext"

export interface FormPageProps {
	item?: storedItem | null
	index: number
	pages: number
	container?: any
	title: string
	page: FormPageItemProps
	fields: FormFieldProperties[]
	active: boolean
	onNext?: any
	onBack?: any
	onCancel?: any
	onClear?: any
	hideSidebar?: any
	onSubmit?: any
	displayMode?: boolean
	children?: ReactNode
	setValue?: Function
	setDirty?: Function
	changed?: boolean
	renderMiddleButtons?: any
}

export class FormPage extends Component<FormPageProps> {
	state = {
		n: 0,
	}

	updateFormData = (field: FormFieldProperties, value: any) => {
		let valid = true
		if (field.validator) {
			const theError = validateField(field.validator, value, field.name, field.errorMessage || "")
			if (theError !== field.error) {
				this.setState({ n: this.state.n + 1 })
			}
			if (theError) {
				valid = false
				field.error = theError
			}
		}
		if (valid) {
			this.setValue(value, field.name)
			this.setState({ n: this.state.n + 1 })
		}
	}
	renderField(field: FormFieldProperties, index: number) {
		switch (field.type.toLowerCase()) {
			// case "block":
			// 	return (
			// 		<FormBlockComponent
			// 			item={this.props.item}
			// 			field={field}
			// 			key={index}
			// 			index={index}
			// 			displayMode={this.props.displayMode}
			// 			setDirty={this.setDirty}
			// 			setValue={this.setValue}
			// 		></FormBlockComponent>
			// 	);

			default:
				return (
					<FormItem
						item={this.props.item}
						field={field}
						key={index}
						updateFormData={this.updateFormData}
						index={field.name}
						displayMode={this.props.displayMode}
						setDirty={this.setDirty}
						setValue={this.setValue}
						container={this.props.container}
					></FormItem>
				)
		}
	}
	renderFields = () => {
		return this.props.fields.map((field, idx) => {
			return this.renderField(field, idx)
		})
	}
	renderLeftButton = () => {
		if (this.props.index >= 1) {
			return (
				<Button primary floated="left" onClick={() => this.props.onBack()}>
					Back
				</Button>
			)
		} else {
			return null
		}
	}
	renderRightButton = () => {
		if (this.props.index === 0) {
			return (
				<>
					{/* <Button secondary floated="left" onClick={() => this.props.onCancel()}>
						Cancel
					</Button> */}
					<Button primary floated="right" onClick={() => this.props.onNext()}>
						Next
					</Button>
				</>
			)
		} else if (this.props.index + 1 < this.props.pages) {
			return (
				<Button primary floated="right" onClick={() => this.props.onNext()}>
					Next
				</Button>
			)
		} else if (this.props.displayMode !== true) {
			return null

			// todo - replace this with controls at multi-page level and from a parameter in the form
			// return (
			// 	<Button primary floated="right" onClick={() => this.props.onSubmit()}>
			// 		Submit NES-CF Notification
			// 	</Button>
			// );
		}
	}
	setValue = (value: any, forKey: string) => {
		const theItem: storedItem | null | undefined = this.props.item
		if (theItem && theItem !== undefined) {
			theItem.setValue(value, forKey)

			this.setDirty()
		}
	}

	setDirty = () => {
		this.setState({ n: this.state.n + 1 })
		if (this.props.setDirty) {
			printMessage("setDirty in FormPageComponent", messageLevels.verbose)

			this.props.setDirty()
		}
	}
	handleChange = (e: any) => {
		this.setValue(e.target.value, "description")
	}
	render() {
		if (this.props.item && this.props.item !== null) {
			return (
				<Segment inverted basic>
					<Form style={{ display: this.props.active ? "block" : "none" }}>
						{this.renderFields()}
						<Item>
							{this.renderLeftButton()}
							{this.renderRightButton()}
							{/* <ClearNotifyFormModal onClear={onClear} /> */}
						</Item>
					</Form>
				</Segment>
			)
		}
		return null
	}
}
