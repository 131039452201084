import React from "react"
import { Segment, Divider } from "semantic-ui-react"

import { colorForItemKey } from "./FormItemComponent"
import { FieldLabel } from "./FieldLabel"
import { FormItemComponentProps } from "../.."

export class DisplayTextComponent extends React.Component<FormItemComponentProps> {
	render() {
		const { field, item } = this.props
		return (
			<>
				<Segment inverted>
					<FieldLabel item={item} field={field} container={this.props.container} />
					{item?.getValue(field.name)}
				</Segment>
				<Divider />
			</>
		)
	}
}
