import { storedItem } from "../storedItem"
import { forestBlock } from "./forestBlock"
import { fileUploadSequencer } from "../multiFileUploader"
import { SelectionItemList } from "../managers/SelectionList"
import { printData, printMessage } from "../../providers/remoteHQ"
import { messageLevels } from "../types"
import { UUIDforMediaCollectionURL, mediaCollectionURLforUUID } from "../URLRewrites"
import { fileUpload } from "./fileUpload"
import { blockFeature } from "./blockFeature"
import { SelectionEditList } from "../managers/SelectionEditList"
import { keyNotSet } from "../helpers"
import { FormFieldProperties, createValidation } from "../Forms"

export class ReportItem extends storedItem {
	_PDF = null
	_MultiFileUploader: fileUploadSequencer | null = null
	_aoeSelect: SelectionItemList | null = null
	_skidSelect: SelectionEditList | null = null
	_taskSelect: SelectionItemList | null = null

	getParent() {
		return this.forestblock
	}

	get reportBody(): { [id: string]: any } {
		let theReport: { [id: string]: any } | undefined = this.getValue("report")
		if (!theReport) {
			theReport = {}
			this.setValue(theReport, "report")
		}
		return theReport
	}
	areasOfConcern = (): { [id: string]: any } => {
		return this.reportBody["aoc"] || []
	}

	areasOfFailure = (): { [id: string]: any } => {
		return this.reportBody["aof"] || []
	}

	areasOfExcellence = (): { [id: string]: any } => {
		return this.reportBody["aoe"] || []
	}
	maps = (): { [id: string]: any } => {
		let theMaps: { [id: string]: any } | null | undefined = this.reportBody["maps"]
		if (!theMaps) {
			theMaps = {}
			this.reportBody.maps = theMaps
		}
		return theMaps
	}

	mapKeys = (): string[] => {
		const maps = this.maps
		return Object.keys(maps)
	}
	getMapByKey = (key: string): { [id: string]: any } => {
		let theMap: { [id: string]: any } | undefined = this.maps()[key]
		if (!theMap) {
			theMap = {}
			this.maps()[key] = theMap
		}
		return theMap
	}
	getMapURLByKey = (key: string): URL | null => {
		const theMap = this.maps()[key]
		if (!theMap) return null
		return theMap["url"] || null
	}
	getMapOrientationByKey = (key: string): number => {
		const theMap = this.maps()[key]
		if (!theMap) return 0
		return theMap["orientation"] || 0
	}

	setMapURLForKey = (url: URL | null, key: string) => {
		const mapKey = this.getMapByKey(key)
		if (this.maps()[key].url !== url) {
			if (this) this.maps()[key].url = url
			this._dirty = true
		}
		this.updateImageList()
		return this
	}

	setMapOrientationForKey = (orientation: number, key: string) => {
		const theMaps = this.maps()

		this.getMapByKey(key).orientation = orientation

		return this
	}

	setMapForKey = (map: { [id: string]: any }, key: string) => {
		this.maps()[key] = map
		this.updateImageList()
		return this
	}
	setMapDetailsByKey = (url: URL, orientation: number, key: string) => {
		const mapDetails = { orientation: orientation, url: url }
		this.maps()[key] = mapDetails
		this.updateImageList()
		return this
	}

	updateImageList = () => {
		const keys = this.mapKeys()
		const URLs: string[] = []
		keys.forEach((key) => {
			const theURL = this.getMapURLByKey(key)
			if (theURL) {
				URLs.push(theURL.toString())
			}
		})
		this.setValue(URLs, "image_links")
	}

	get aoeSelectionList(): SelectionItemList | null {
		if (this._aoeSelect) {
			return this._aoeSelect
		}
		const block = this.forestblock
		let aoes
		if (!block) {
			const emptyStoredItems: forestBlock[] = []
			aoes = new SelectionItemList(emptyStoredItems, true)
		} else {
			const theAoes = (block as forestBlock).areasOfExcellence() as blockFeature[]
			theAoes.forEach((aoe) => aoe.imagesAndRotations())
			aoes = new SelectionItemList(theAoes, false)
		}
		this._aoeSelect = aoes

		return this._aoeSelect
	}

	updateAoeSelectionList = () => {
		const theList = this.aoeSelectionList
		if (theList) {
			const block = this.forestblock
			const theNewItems = (block as forestBlock).areasOfExcellence()
			theList.mergeItems(theNewItems)
		}
	}
	get skidSelectionList(): SelectionEditList | null {
		if (this._skidSelect) {
			return this._skidSelect
		}
		const block = this.forestblock
		let skids
		if (!block) {
			const emptyStoredItems: forestBlock[] = []
			skids = new SelectionEditList(emptyStoredItems, false)
		} else {
			const theSkids = (block as forestBlock).asBuiltSkidSites as blockFeature[]

			skids = new SelectionEditList(theSkids, false)
			theSkids.forEach((skid) => skid.imagesAndRotations())
		}
		this._skidSelect = skids

		return this._skidSelect
	}
	updateSkidSelectionList = () => {
		const theList = this.skidSelectionList
		if (theList) {
			const block = this.forestblock
			const theNewItems = (block as forestBlock).asBuiltSkidSites as blockFeature[]
			theList.mergeItems(theNewItems)
		}
	}
	get newSkidSelectionList() {
		this._skidSelect = null
		return this.skidSelectionList
	}
	get taskSelectionList(): SelectionItemList | null {
		if (this._taskSelect) {
			return this._taskSelect
		}
		const block = this.forestblock
		let tasks: SelectionItemList
		if (!block) {
			const emptyStoredItems: forestBlock[] = []
			tasks = new SelectionItemList(emptyStoredItems, true)
		} else {
			const theTasks = (block as forestBlock).tasks as blockFeature[]

			tasks = new SelectionItemList(theTasks, true)
			theTasks.forEach((task) => task.imagesAndRotations())
		}
		this._taskSelect = tasks

		return this._taskSelect
	}

	updateTaskSelectionList = () => {
		const theList = this.taskSelectionList
		if (theList) {
			const block = this.forestblock
			const theNewItems = (block as forestBlock).tasks as blockFeature[]
			theList.mergeItems(theNewItems)
		}
	}

	get forestblock() {
		if (this.getValue("forest_block_id")) {
			return this.getRelatedItem("forest-blocks", this.getValue("forest_block_id"), "uuid")
		}
		return this.getRelatedItem("forest-blocks", this.getValue("consent_number"), "consent_number")
	}

	displayName() {
		const theForestBlock = this.forestblock?.displayName() || ""
		const theDate = this.getValue("date", "")

		return this.friendlyDisplayName + " " + theForestBlock + " " + theDate
	}

	public static omitItems(): string[] {
		return [...super.omitItems(), "_aoeSelect", "_skidSelect", "_imageData"]
	}

	public static mapTypes() {
		const theTypes: { [id: string]: any } = {
			context: "Context Map (cover page)",
			task: "Task Map",
			consented: "Consented Map vs Built Map",
			skid: "Skid Site Map",
		}
		return theTypes
	}

	willStoreEdit(): boolean {
		this.storeEditPrepared()
		return true
	}

	fieldsToReport = () => {
		const theReport = this.reportBody
		const thefields = [
			"title",
			"inspection_date",
			"compliance_status",
			"monitoring_officer",
			"consent_number",
			"forest_name",
			"contact_person",
			"earthworks_contractor",
			"harvesting_contractor",
			"proximity_to_waterways",
			"status_of_work",
			"created_date",
		]

		const theValues = this.getValues(thefields)
		const theAOECount = (this.forestblock as forestBlock).areasOfExcellence().length
		const theAOCCount = (this.forestblock as forestBlock).areasOfConcern().length
		const theAOFCount = (this.forestblock as forestBlock).areasOfFailure().length
		const theTaskCount = (this.forestblock as forestBlock).tasks.length

		theReport["details"] = {
			...theValues,
			areas_of_excellence_count: theAOECount,
			areas_of_concern_count: theAOCCount,
			areas_of_failure_count: theAOFCount,
			tasks_count: theTaskCount,
		}

		theReport["forest_block"] = this.forestblock
		const reportConfig = (this.forestblock as forestBlock)?.authorityItem.getValue("report_config")
		if (typeof reportConfig === "string") {
			try {
				theReport["report_config"] = JSON.parse(reportConfig)
			} catch (e) {
				printMessage("error decoding report config", messageLevels.error)
			}
		} else {
			theReport["report_config"] = reportConfig
		}
	}

	listsToReport = () => {
		const theReport = this.reportBody

		const skidList = this.skidSelectionList?.keepChangesOnAllMaintainEdit()
		const theSelectedSkidSites = this.skidSelectionList?.selectedStoredItems()
		theReport["skidSites"] = theSelectedSkidSites

		const aoeList = this.aoeSelectionList?.keepChangesOnAllMaintainEdit()
		const theSelectedAOEs = this.aoeSelectionList?.selectedStoredItems()
		theReport["areasOfExcellence"] = theSelectedAOEs

		const taskList = this.taskSelectionList?.keepChangesOnAllMaintainEdit()
		const theSelectedTasks = this.taskSelectionList?.selectedStoredItems()
		theReport["tasks"] = theSelectedTasks
	}

	// image handling
	get fileUploader() {
		if (!this._MultiFileUploader) {
			this._MultiFileUploader = new fileUploadSequencer()
		}
		return this._MultiFileUploader
	}

	addUploadForKey = (theUpload: fileUpload, key: string) => {
		const currentImageURL = this.getMapURLByKey(key)
		theUpload.setForItem(this)
		// theUpload.addCallbackOnUpload(this.linkedUpdate);
		this.fileUploader.addFileUpload(theUpload)
		this._dirty = true
		this.setMapURLForKey(mediaCollectionURLforUUID(theUpload.getUUID()), key)
		this.doChangeCallbacks()
	}

	uploadAll = () => {
		if (this._MultiFileUploader) {
			this.fileUploader.uploadAll()
		}
	}

	takeoverUploads = (fileUploader: fileUploadSequencer) => {
		if (fileUploader) {
			this.fileUploader.takeoverUploads(this, fileUploader)
		}
	}

	removeImage = (theURL: string | URL): boolean => {
		let done = false
		const urlString = theURL.toString()
		const imageUUID = UUIDforMediaCollectionURL(urlString)

		const isImageUUID = imageUUID !== ""
		printData(isImageUUID, messageLevels.debug, "isImageUUID")

		if (isImageUUID) {
			done = this.removeImageUUID(imageUUID)
		}
		return done
	}
	removeImageForKey = (key: string): boolean => {
		const url = this.getMapURLByKey(key)

		if (!url) return false
		this.setMapURLForKey(null, key)
		return this.removeImage(url)
	}

	removeImageUUID = (theUUID: string): boolean => {
		let done = false
		if (typeof theUUID !== "string") {
			return false
		}

		if (this._MultiFileUploader) {
			const isChanged = this._MultiFileUploader.removeFileUploadByUUID(theUUID)
			if (isChanged) {
				this._dirty = true
				done = true
			}
		}
		return done
	}

	_createValidation = (...args: FormFieldProperties[][]) => {
		this.validation = createValidation.apply(this, args)
	}

	validForSave = (): boolean => {
		if (!this.validation?.validateAll(this, false)) return false
		if (!this.getMapURLByKey("context")) return false
		if (keyNotSet(this, "proximity_to_waterways")) return false
		if (keyNotSet(this, "compliance_status")) return false
		if (keyNotSet(this, "inspection_date")) return false
		if (keyNotSet(this, "contact_person")) return false
		if (keyNotSet(this, "earthworks_contractor")) return false
		if (keyNotSet(this, "harvesting_contractor")) return false
		if (keyNotSet(this, "status_of_work")) return false
		return true
	}

	validateBeforeSave = (): boolean => this.validation?.validateAll(this, true) || false

	validationForKey = (key: string): boolean => {
		if (!this.validation) return false
		const field = this.validation.getFieldByKey(key)
		return this.validation.validate(field, this, true) || false
	}

	validationErrorForKey = (key: string): any => {
		switch (key) {
			case "proximity_to_waterways":
			case "compliance_status":
			case "inspection_date":
			case "status_of_work":
			case "contact_person":
				return keyNotSet(this, key)
			case "earthworks_contractor":
			case "harvesting_contractor":
				return keyNotSet(this, key)
			case "context":
				const theValue = this.getMapURLByKey(key)
				return !theValue || theValue === undefined
			default:
				return null
		}
	}
}

export class reportGenerator {}
