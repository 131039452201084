import React, { Component } from "react"
import { Segment } from "semantic-ui-react"

import { printData, printMessage } from "../../providers/remoteHQ"
import { parseQueries, storedItem } from "../storedItem"
import { IParsedQuery, messageLevels } from "../types"
import { isStoredItem } from "../index"
import { FormBlockProps, FormFieldProperties, validateField } from "."
import { FormItem } from "./Items/FormItem"

export interface FormBlockState {
	subItem?: storedItem | null
	visible: boolean
	queryParsed: IParsedQuery[]
	n: number
}

export class FormBlock extends React.Component<FormBlockProps, FormBlockState> {
	state = {
		subItem: this.props.item,
		visible: false,
		queryParsed: [],
		n: 0,
	}
	componentDidMount() {
		if (this.props.field.name && this.props.field.name.length > 0) {
			const theValue = this.props.item?.getValue(this.props.field.name)
			if (theValue && isStoredItem(theValue)) {
				this.setState({ subItem: theValue })
			}
		}
		this.prepareConditional()
		this.updateVisible()
	}
	prepareConditional = () => {
		const theConditional = this.props.field.conditional
		if (theConditional && theConditional.queries && theConditional.queries.length > 0) {
			const theParsedQueries = parseQueries(theConditional.queries)
			this.setState({ queryParsed: theParsedQueries })
			printData(theParsedQueries, messageLevels.debug, "Parsed queries in prepareConditional")
		}
	}
	checkShouldShow = (): boolean => {
		if (this.state.queryParsed) {
			printData(this.props.field.conditional?.searchOrMode ?? false, messageLevels.debug, "SearchORMode in checkShouldShow")
			const isIn = this.props.item?.matchItem(this.state.queryParsed, this.props.field.conditional?.searchOrMode ?? false)
			printData(isIn, messageLevels.debug, "isIn in checkShouldShow")
			if (isIn !== null) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}
	updateVisible = () => {
		this.setState({ visible: this.checkShouldShow() })
	}
	updateFormData = (field: FormFieldProperties, value: any) => {
		let valid = true
		if (field.validator) {
			const theError = validateField(field.validator, value, field.name, field.errorMessage || "")
			if (theError !== field.error) {
				this.setState({ n: this.state.n + 1 })
			}
			if (theError) {
				valid = false
				field.error = theError
			}
		}
		if (valid) {
			if (this.state.subItem) {
				this.state.subItem.setValue(value, field.name)
			}
			this.setState({ n: this.state.n + 1 })
		}
	}
	setValue = (value: any, forKey: string) => {
		const theItem: storedItem | null | undefined = this.props.item
		if (theItem && theItem !== undefined) {
			printMessage("setting value: " + value, messageLevels.debug)
			theItem.setValue(value, forKey)

			if (this.props.setDirty) {
				this.props.setDirty()
			}
		}
	}
	setDirty = () => {
		if (this.props.setDirty) {
			this.props.setDirty()
		}
	}
	renderField(field: FormFieldProperties, index: number) {
		switch (field.type.toLowerCase()) {
			case "block":
				return (
					<FormBlock
						item={this.state.subItem}
						field={field}
						container={this.props.container}
						key={index}
						index={index}
						setDirty={this.setDirty}
					></FormBlock>
				)
			default:
				return (
					<FormItem
						item={this.state.subItem}
						field={field}
						container={this.props.container}
						key={index}
						updateFormData={this.updateFormData}
						index={index}
						setDirty={this.setDirty}
					></FormItem>
				)
		}
	}
	renderFields = () => {
		return this.props.field.subfields?.map((subfield, idx) => {
			return this.renderField(subfield, idx)
		})
	}
	render() {
		const isShow = this.checkShouldShow()
		if (this.state.visible && isShow) {
			return (
				<Segment inverted basic className={"form-block"}>
					{this.renderFields()}
				</Segment>
			)
		} else {
			return null
		}
	}
}
