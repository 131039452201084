import React from "react"
import { Label } from "semantic-ui-react"
import { printMessage } from "../../providers/remoteHQ"
import { messageLevels } from "../types"

import { storedItem } from "../storedItem"

interface StatusProps {
	item?: storedItem | null
	prefix?: string
}

interface StatusState {
	changed: boolean
	seconds: number
}

export default class Status extends React.Component<StatusProps, StatusState> {
	state: StatusState = {
		changed: false,
		seconds: 0,
	}
	interval: any = null
	componentDidUpdate(prevProps: StatusProps) {
		if (this.originalItem(prevProps.item) !== this.originalItem(this.props.item)) {
			const prevType = prevProps.item?.getType()
			const newType = this.props.item?.getType()
			if (prevType !== newType) {
				if (prevProps.item) {
					this.deregisterForChanges(prevProps.item)
				}
				if (this.props.item) {
					this.registerForChanges(this.props.item)
				}
			}
		}
	}

	originalItem(theItem: storedItem | null | undefined): storedItem | null {
		if (!theItem) {
			return null
		}
		if (!theItem._isEditCopy) {
			return theItem
		}
		return theItem._sourceItem
	}
	tick() {
		this.setState((state) => ({
			seconds: this.state.seconds + 5,
		}))
	}
	componentDidMount() {
		if (this.props.item) {
			this.registerForChanges(this.props.item)
		}
		this.interval = setInterval(() => this.tick(), 5000)
	}

	statusChangeCallback = (type: String, key: any, data: storedItem | null) => {
		if (data === this.props.item) {
			this.setState({ changed: !this.state.changed })
		}
	}

	registerForChanges(theItem: storedItem) {
		if (theItem) {
			const theType = theItem.getType()
			if (theType) {
				theType.registerOnSave(this.statusChangeCallback)
				theType.registerOnDelete(this.statusChangeCallback)
				theType.registerOnChange(this.statusChangeCallback)
			}
		}
	}
	deregisterForChanges(theItem: storedItem) {
		if (theItem) {
			const theType = theItem.getType()
			if (theType) {
				theType.removeOnSave(this.statusChangeCallback)
				theType.removeOnDelete(this.statusChangeCallback)
				theType.removeOnChange(this.statusChangeCallback)
			}
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval)
		const theItem = this.props.item
		if (theItem) {
			this.deregisterForChanges(theItem)
		}
	}
	render() {
		const { item } = this.props
		const theItem = this.originalItem(item)
		const itemName = theItem?.friendlyDisplayName

		if (item?.shouldSendDelete()) {
			return (
				<Label as="a" color="orange">
					{itemName} Deleting
				</Label>
			)
		}
		if (theItem?.shouldSendCreate()) {
			return (
				<Label as="a" style-={{ backgroundColor: "rgb(28, 128, 122)" }}>
					{itemName} Saving
				</Label>
			)
		}
		if (theItem?.shouldSendUpdate()) {
			return (
				<Label as="a" style-={{ backgroundColor: "rgb(28, 128, 122)" }}>
					{itemName} Updating
				</Label>
			)
		}
		if (theItem?.getValue("_deleted")) {
			return (
				<Label as="a" color="red">
					{itemName} Deleted
				</Label>
			)
		}

		return null
	}
}
