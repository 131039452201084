import moment from "moment"

import { messageLevels } from "../../store/types"
import {
	call,
	getGlobalState,
	getPropertyArrayFromItemArray,
	getStoreTypeByName,
	instanceNewItem,
	isStoredItem,
	setGlobalState,
} from "../../store"
import { printData, printMessage } from "../../providers/remoteHQ"
import { storedItem } from "../../store/storedItem"
import { storeType } from "../../store/storeType"
import { mapSource } from "./mapSource"

export class mapControl extends storedItem {
	_additionalFilter: any[]
	_levelFilters: any[]
	_isEditing = false

	constructor(type: storeType, data?: any, doNotStore?: boolean) {
		super(type, data, doNotStore)
		this._additionalFilter = []
		this._levelFilters = []
	}

	getParent(): storedItem | null {
		const parentID = this.getValue("parentID")
		if (!parentID) {
			return null
		}
		return this.getRelatedItem("map-controls", parentID, "id", "==", true)
	}

	get childControlNames() {
		const childControls = this.getValue("children")
		if (Array.isArray(childControls)) {
			return childControls
		}
		if (childControls) {
			const childControlArray = childControls.split(",")
			return childControlArray
		}
		return []
	}

	get childControls(): mapControl[] {
		const theSubcontrols: mapControl[] = []
		const mapControls = getStoreTypeByName("map-controls")
		if (mapControls) {
			this.childControlNames.forEach((item: string) => {
				const theControl = mapControls.itemByKey(item)
				if (theControl) {
					theSubcontrols.push(theControl as mapControl)
				}
			})
		}

		return theSubcontrols
	}

	toggleVisibility() {
		if (!this.isDisabled()) {
			const theVisibility = !this.currentVisibility()
			// printData(theVisibility,messageLevels.verbose,"Toggle visbility to: ");
			this.setVisibility(theVisibility)

			// this.childControls.forEach(element => {
			// 	element.setVisibility(theVisibility)
			// });
		}
	}

	disableControl() {
		this.setValue(true, "_disabled")
		call("setControlVisibility", this.primaryKey(), false)
		this.childControls.forEach((element) => {
			element.disableControl()
		})
	}

	enableControl() {
		this.setValue(false, "_disabled")
		call("setControlVisibility", this.primaryKey(), this.storedVisibility())
		this.childControls.forEach((element) => {
			element.setValue(false, "_disabled")
		})
	}

	setEnableControl(enable: boolean) {
		if (enable) {
			this.enableControl()
		} else {
			this.disableControl()
		}
	}
	isDisabled() {
		return this.getValue("_disabled") || false
	}
	storedVisibility() {
		if (this.getValue("_visibility") !== undefined) {
			return this.getValue("_visibility")
		}
		return this.defaultVisibility
	}
	currentVisibility(): boolean {
		if (!this.isDisabled()) {
			const theVisibility = call("getControlVisibility", this.primaryKey())
			this.setValue(theVisibility, "_visibility")
			return theVisibility
		}
		return this.storedVisibility()
	}
	setVisibility(visibililty: boolean) {
		if (!this.isDisabled()) {
			call("setControlVisibility", this.primaryKey(), visibililty)
			this.childControls.forEach((element) => {
				element.setVisibility(visibililty)
			})
		}
		this.setValue(visibililty, "_visibility")
	}

	get defaultVisibility() {
		const theLayout = this.getValue("layout")
		if (theLayout) {
			if (theLayout.visibility) {
				if (theLayout.visibility === "visible") {
					return true
				} else {
					return false
				}
			}
		}
		return true
	}
	resetToDefault() {
		if (this.defaultVisibility) {
			this.show()
		} else {
			this.hide()
		}
	}

	showInCurrentLevel(): boolean {
		if (!this.getValue("showInLevels")) {
			return false
		}

		const theLevel = getGlobalState("level")?.getValue()

		if (!theLevel) {
			return false
		}
		const found = this.matchesLevelList(this.getValue("showInLevels"))

		return found
	}

	hideInCurrentLevel(): boolean {
		if (!this.getValue("hideInLevels")) {
			return false
		}

		const theLevel = getGlobalState("level")?.getValue()

		if (!theLevel) {
			return false
		}
		const found = this.matchesLevelList(this.getValue("hideInLevels"))

		return found
	}

	matchesLevelList = (levelList: string[]): boolean => {
		let matched = false
		const theLevel = getGlobalState("level")?.getValue()
		if (!theLevel) {
			return false
		}
		// printMessage("Level: "+ theLevel , messageLevels.verbose);
		levelList.forEach((element) => {
			if (element === "*") {
				matched = true
			} else if (element.endsWith("*")) {
				const theMatch = element.substring(0, element.length - 1)
				// printMessage("TheMatch: "+ theMatch + " wildcard", messageLevels.verbose);
				if (theLevel.startsWith(theMatch)) {
					matched = true
				}
			} else {
				// printMessage("TheMatch: "+ element, messageLevels.verbose)
				if (element === theLevel) {
					matched = true
				}
			}
		})
		printMessage("Matched: " + matched, messageLevels.debug)
		return matched
	}
	setupForLevel() {
		if (this.showInCurrentLevel() && !this.hideInCurrentLevel()) {
			this.show()
		} else if (this.hideInCurrentLevel() && !this.showInCurrentLevel()) {
			this.hide()
		}
	}

	get sourceExists(): boolean {
		const theSource = this.sourceItem
		if (!theSource) {
			return false
		}
		return theSource.isOnMap
	}

	get isOnMap(): boolean {
		return (this as any)._isOnMap
	}
	set isOnMap(value: boolean) {
		;(this as any)._isOnMap = value
	}
	get mapControl() {
		return 1
	}

	checkReadAuthority(): boolean {
		// ToDo - get object type and get authority to read it in current region
		const dataType = this.dataSource

		if (dataType && dataType !== undefined && dataType !== null) {
			const theAuthority = dataType.checkReadAuthority()

			return theAuthority
		}
		return true
	}

	checkCreateAuthority(): boolean {
		// ToDo - get object type and get authority to create it in current region
		const dataType = this.dataSource

		if (dataType && dataType !== undefined && dataType !== null) {
			printData(dataType, messageLevels.debug, "dataType in checkCreateAuthority in mapControl")
			const theAuthority = dataType.checkCreateAuthority()
			printData(theAuthority, messageLevels.debug, "theAuthority in checkCreateAuthority in mapControl")
			return theAuthority
		}

		return true
	}

	get sourceItem(): mapSource {
		return this.getRelatedItems("map-sources", this.getValue("source"), "id")[0] as mapSource
	}
	get dataSource(): storeType | null {
		const objectType = this.getValue("objectType")
		// printData(objectType, messageLevels.verbose, "objectType in dataSource")
		if (objectType && objectType !== undefined) {
			return getStoreTypeByName(objectType) ?? null
		}
		return null
	}

	hide() {
		this.setVisibility(false)
		// call("hideControl", this.primaryKey());
	}
	show() {
		this.setVisibility(true)
		// call("showControl", this.primaryKey());
	}

	addToMap = () => {
		if (!this.isOnMap) {
			// printMessage("Adding to map", messageLevels.verbose);
			call("addControl", this)
		}
	}

	addItem = (): storedItem | null => {
		const dataType = this.dataSource
		// const theUser = ( getUserDataSync()) as any;
		// printData(theUser,messageLevels.verbose,"theUser");

		if (dataType && dataType !== undefined && dataType !== null) {
			const theItem = instanceNewItem(dataType.name)

			return theItem
		}
		return null
	}
}
