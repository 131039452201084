import React, { Component } from "react"
import ReactDOM from "react-dom"
import mapboxgl, { FillExtrusionPaint, LngLatBoundsLike, LngLatLike, Map, RequestParameters, TransformRequestFunction } from "mapbox-gl"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import { Icon } from "semantic-ui-react"
import { Feature, LineString, Polygon } from "@turf/helpers"
import { GeoJsonProperties } from "geojson"

import { Legend } from "./Legend"
import Optionsfield from "./Optionsfield"
import MapSelector from "./mapSelector"
import { Pulse, createPulse } from "../helpers/pulse"

import "../Map.css"

import {
	call,
	getConfig,
	getGlobalState,
	getItem,
	getStoreTypeByName,
	isNgatiApa,
	isStoredItem,
	registerCall,
	removeOnChange,
	removeOnRetrieve,
	requestLoad,
	setGlobalState,
} from "../store/index"
import { ChangeCallbackFunction, messageLevels, sortDirection, sortMethod } from "../store/types"
import { printData, printMessage } from "../providers/remoteHQ"
import legendLabels from "../helpers/getLegendLabels"
import getMarkerColor from "../helpers/getMarkerColor"
import { base64Decode, removeHash, throttle } from "../helpers/commonFunc"
import { createMeasurement } from "./Measurements"
import { MeasurementPopup } from "./MeasurementPopup"
import { createElevation } from "./Elevation"
import { storedItem } from "../store/storedItem"
import { LayerConfig } from "./LayerConfig"
import { mapLayer } from "../store/classes/mapLayer"
import { mapSource } from "../store/classes/mapSource"
import { mapControl } from "../store/classes/mapControl"
import { forestBlock } from "../store/classes/forestBlock"
import { region } from "../store/classes/region"
import { forestOwner } from "../store/classes/forest"

interface IState {
	map: mapboxgl.Map | null
	allLayersMode: boolean
	activeMapStyle: any
	layerVisibility: any[]
	layerEditing: any
	lng: number
	lat: number
	bearing: number
	zoom: number
	pitch: number
	wireframe: boolean
	consentID: number
	isShowRemoteHqBox: boolean
	visualiseBy: string
	isThreeD: boolean
	activeStyle: any
	hasMeasurement: boolean
	isDrawing: boolean
	activeLayerClicks: any[]
	inactiveLayerClicks: any[]
	mapBusy: boolean
}

interface IProps {
	clickHandlers: any[]
	level: any
	toggleMapLoad: (toggle: boolean) => void
	accessToken: any
}

class MapSight extends Component<IProps> {
	effectLayerTypes = ["terrain", "sky", "fog", "buildings"]

	controls = []
	styleOptions: any[] = []
	mapTypes: any[] = []
	mapShuffleTypes: any[] = []
	layers = []
	sources = []
	pulse: Pulse[] = []
	_pausePulse = false
	state: IState = {
		allLayersMode: true,
		activeMapStyle: null,
		layerVisibility: [],
		layerEditing: null,
		lng: 0,
		lat: 0,
		bearing: 0,
		zoom: 10,
		pitch: 0,
		wireframe: false,
		map: null,
		consentID: 0,
		isShowRemoteHqBox: false,
		visualiseBy: "Compliance",
		isThreeD: false,
		activeStyle: { labels: legendLabels },
		hasMeasurement: false,
		isDrawing: false,
		activeLayerClicks: [],
		inactiveLayerClicks: [],
		mapBusy: false,
	}

	addControlFailCount = 0
	mapBusy = true
	doLoadLayers = false

	editControl = null

	mapContainerRef = React.createRef<HTMLDivElement>()
	// tooltipRef = React.createRef();
	lastPositionUpdate = Date.now()

	callBackMoveFn: any = null
	layerClicks: any = {}
	config: any
	flyToTryCount = 0

	Draw = new MapboxDraw({
		displayControlsDefault: false,
		// Select which mapbox-gl-draw control buttons to add to the map.
		controls: {
			polygon: true,
			line_string: true,

			// trash: true
		},
	})

	Measurements = createMeasurement()
	Elevation = createElevation()

	isUIAdded = false
	lineStyles: any

	isWaitingIdle = false

	removeLayerIfExists = (layerName: string) => {
		if (this.state.map && this.state.map?.getLayer(layerName)) {
			this.state.map.removeLayer(layerName)
		}
	}

	removeSourceIfExists = (sourceName: string) => {
		if (!this.state.map) return
		const theLayer = this.state.map?.getSource(sourceName)
		printMessage(sourceName + ": " + theLayer, messageLevels.debug)

		if (theLayer && typeof theLayer !== "undefined") {
			this.state.map?.removeSource(sourceName)
		}
	}

	setConsentId = (consentID: string) => {
		printMessage("Set Consent ID: " + consentID, messageLevels.debug)
	}

	transformRequest: TransformRequestFunction = (url: string, resourceType: string): RequestParameters => {
		if (resourceType === "Source" && url.indexOf("https://api") > -1 && url.indexOf("remotehq") > -1) {
			// printMessage("Adding params: ",messageLevels.debug);
			return {
				url: this.replaceParams(url),
			}
		} else if (
			resourceType === "Tile" &&
			((url.startsWith("https://authtiles") && url.indexOf("remotehq") > -1) || (isNgatiApa() && url.indexOf("mapsight") > -1))
		) {
			const theAuthToken = getGlobalState("loginState")?.getValue("accessToken")
			if (theAuthToken) {
				return {
					method: "GET",
					url: this.replaceParams(url),
					headers: { Authorization: "Bearer " + theAuthToken },
				}
			} else return { url }
		} else if (resourceType === "Tile" && url.includes("remotehq") && url.includes("$")) {
			printMessage("rewriting URL for remoteHQ", messageLevels.verbose)
			const newURL = this.replaceParams(url)
			return {
				url: newURL,
			}
		} else if (resourceType === "Tile" && url.includes("linz")) {
			// const regex = /(?<=.WebMercatorQuad\/)./gm
			// const zoomIndex = url.search(regex)
			// const zoom = parseInt(url[zoomIndex]) + 1
			// const newUrl = url.replace(regex, zoom)
			// return {
			// 	url: newUrl
			// }
		}
		return {
			url,
		}
	}

	replaceParam = (url: string, param: string, sourceParam?: string) => {
		const wrappedParam = "$" + param + "$"
		if (url.includes(wrappedParam)) {
			printMessage("URL includes: " + wrappedParam, messageLevels.debug)
			const theValue = this.getSelectionValue(sourceParam || param)
			printMessage("New value " + theValue, messageLevels.debug)
			if (!theValue) {
				return ""
			}
			const modifiedURL = url.replace(wrappedParam, theValue)
			return modifiedURL
		}
		return url
	}

	replaceParams = (url: string) => {
		// printMessage(url.replace('$consentNum$',this.state.consentID),messageLevels.debug);
		if (url.includes("$")) {
			let modURL = this.replaceParam(url, "consentNum", "consent_number")
			modURL = this.replaceParam(modURL, "orthomosaic_filepath")
			modURL = this.replaceParam(modURL, "digital_elevation_model_filepath")

			return modURL
		}
		return url
	}

	getSelectionValue(param: string) {
		const selected = getGlobalState("selected")?.getValue()
		if (selected && selected.length > 0) {
			printData(selected, messageLevels.debug, "selected")
			return selected[0]?.getValue(param)
		}
		return null
	}

	flyTo: (options: mapboxgl.FlyToOptions, eventData?: mapboxgl.EventData | undefined) => void = (...params) => {
		this.state.map?.flyTo(...params)
	}

	zoomToBoundsArray = (boundsArray: [LngLatLike, LngLatLike][]) => {
		if (boundsArray && boundsArray.length > 0) {
			const bounds = new mapboxgl.LngLatBounds(boundsArray[0][0], boundsArray[0][1])
			boundsArray.forEach((element: [LngLatLike, LngLatLike]) => {
				const newBounds = new mapboxgl.LngLatBounds(element[0], element[1])
				bounds.extend(newBounds)
			})
			printData(bounds, messageLevels.debug, "bounds:")
			this.zoomToBounds(bounds)
		}
	}

	zoomToBounds = (bounds: LngLatBoundsLike) => {
		this.state.map?.fitBounds(bounds, {
			padding: 80,
			animate: false,
		})
	}

	zoomToView = (view: mapboxgl.EaseToOptions) => {
		this.state.map?.easeTo(view)
	}

	getLayerVisibility = (layerName: string) => {
		if (this.state.map) {
			const layer = this.state.map?.getLayer(layerName)
			if (layer) {
				return this.state.map?.getLayoutProperty(layerName, "visibility") !== "none"
			} else {
				console.error("Layer not found: " + layerName)
			}
		}
		// return undefined when map is not loaded
		return
	}

	setLayerVisibility = (layerName: string, visibility: any) => {
		if (this.state.map) {
			const layer = this.state.map?.getLayer(layerName)
			let visibillityString = visibility
			printData(layer + " to " + visibillityString, messageLevels.debug, "setting visibility on " + layerName)
			if (typeof visibility === "boolean" || typeof visibility === "number") {
				visibillityString = visibility ? "visible" : "none"
			}
			if (layer) {
				printMessage("setting visibility on " + layerName + " to " + visibillityString, messageLevels.debug)
				if (this.effectLayerTypes.includes(layer.id)) {
					if (visibillityString === "visible") {
						this.addLayerIfInStyle(this.state.map, layer)
					}
				} else {
					return this.state.map?.setLayoutProperty(layerName, "visibility", visibillityString)
				}
			}
		}
	}

	hideLayer = (layerName: string) => {
		this.setLayerVisibility(layerName, false)
	}

	showLayer = (layerName: string) => {
		this.setLayerVisibility(layerName, true)
	}

	setSourceBounds = (sourceName: string, bounds: string) => {
		printMessage("Mapsight: SetBounds in source " + sourceName, messageLevels.debug)
		printMessage("bounds " + bounds, messageLevels.debug)
		if (!this.mapBusy) {
			const theSource = this.state.map?.getSource(sourceName)

			if (theSource) {
				printMessage("Mapsight: SetBounds in source " + sourceName, messageLevels.debug)
				//@ts-ignore
				theSource.bounds = bounds
			}
		}
	}

	setSourceQueryString(source: string, queryString: string) {
		const theSource = this.state.map?.getSource(source)
		let theSourceConfig = null
		if (typeof source === "string") {
			theSourceConfig = this.config[source]
		} else {
			theSourceConfig = source
		}

		if (theSource && theSourceConfig) {
			//@ts-ignore
			theSource.url = theSourceConfig + "?" + queryString
		}
	}

	addbuildings = (map: Map, layer: LayerConfig) => {
		map.addLayer({
			id: layer.getValue("id"),
			source: "composite",
			"source-layer": "building",
			filter: layer.filter,
			type: "fill-extrusion",
			minzoom: layer.minzoom,
			paint: layer.paint as FillExtrusionPaint,
		})
	}

	addTerrain = (map: mapboxgl.Map | null, layer: any) => {
		// Add 3D Terrain
		map?.addSource("mapbox-dem", {
			type: "raster-dem",
			url: "mapbox://mapbox.mapbox-terrain-dem-v1",
			tileSize: 512,
			maxzoom: 17,
		})
		map?.setTerrain({ source: "mapbox-dem", exaggeration: layer.exaggeration })
	}

	addSky = (map: Map, layer: any) => {
		!map.getLayer("sky") &&
			map.addLayer({
				id: "sky",
				type: "sky",
				paint: layer.paint,
			})
	}

	addFog = (map: Map, layer: any) => {
		map.setFog(layer.params)
	}

	// TODO: To build upon this, we will need to store each marker in a ref and use the markerRef.remove() method to remove it from the map. I would suggest storing these in an object and use an array to do a look up.

	addImageMarker = (map: Map | null, feature: GeoJsonProperties): void => {
		const el = document.createElement("div")
		const width = (feature?.properties.iconSize && feature?.properties.iconSize[0]) || 70
		const height = (feature?.properties.iconSize && feature?.properties.iconSize[1]) || 70
		const baseColor = feature?.properties.color || "#F9B710"

		el.className = "marker"
		el.style.width = `${width}px`
		el.style.height = `${height}px`
		el.style.borderRadius = "50%"
		el.style.backgroundColor = baseColor

		const markerImage = document.createElement("div")
		markerImage.className = "marker-image"
		markerImage.style.backgroundImage = feature?.properties.image || `url(https://placekitten.com/g/${width}/${height}/)`
		markerImage.style.width = "100%"
		markerImage.style.height = "100%"
		markerImage.style.borderRadius = "50%"
		markerImage.style.backgroundSize = "100%"
		markerImage.style.border = `${3}px solid ${baseColor}`

		const markerTip = document.createElement("div")
		markerTip.className = "marker-tip"
		markerTip.style.backgroundColor = baseColor
		markerTip.style.width = height / 2 + "px"
		markerTip.style.height = height / 2 + "px"
		markerTip.style.top = "85%"
		markerTip.style.left = "50%"
		markerTip.style.transform = "translate(-50%, -50%) rotate(45deg)"

		el.appendChild(markerTip)
		el.appendChild(markerImage)

		// el.addEventListener("click", () => {
		// 	window.alert(feature?.properties.message)
		// })

		// Add markers to the map.
		// Push this to a particular ref array stored in state i.e imageMarkerLayer[markerRef]
		map && new mapboxgl.Marker(el).setLngLat(feature?.geometry.coordinates).addTo(map)
	}

	addActiveLayerClick = (layerConfig: LayerConfig) => {
		const _activeLayerClicks = [...this.state.activeLayerClicks]
		_activeLayerClicks.push(layerConfig.id)
		if (this.state.inactiveLayerClicks.includes(layerConfig.id)) {
			const _inactiveLayerClicks = [...this.state.inactiveLayerClicks]
			_inactiveLayerClicks.splice(this.state.inactiveLayerClicks.indexOf(layerConfig.id), 1)
			this.setState({ inactiveLayerClicks: _inactiveLayerClicks })
		}
		this.setState({ activeLayerClicks: _activeLayerClicks })
	}

	removeActiveLayerClick = (layerConfig: LayerConfig) => {
		const _inactiveLayerClicks = [...this.state.inactiveLayerClicks]
		_inactiveLayerClicks.push(layerConfig.id)
		if (this.state.activeLayerClicks.includes(layerConfig.id)) {
			const _activeLayerClicks = [...this.state.activeLayerClicks]
			_activeLayerClicks.splice(this.state.activeLayerClicks.indexOf(layerConfig.id), 1)
			this.setState({ activeLayerClicks: _activeLayerClicks })
		}
		this.setState({ inactiveLayerClicks: _inactiveLayerClicks })
	}

	layerAddClick = (layerConfig: LayerConfig) => {
		if (!layerConfig) return
		if (this.state.activeLayerClicks.includes(layerConfig.id)) return true
		printData(layerConfig, messageLevels.debug, "layerAddClick")
		if (!this.state.map || this.state.mapBusy) {
			return false
		}
		if (typeof layerConfig.getValue("clickAction") !== "undefined") {
			printMessage("Adding: " + layerConfig.clickAction, messageLevels.debug)
			this.mapOnClick(layerConfig)
			return true
		} else {
			return false
		}
	}

	layerRemoveClick = (layerConfig: LayerConfig) => {
		if (!layerConfig) return
		if (!this.state.map || this.state.mapBusy) {
			return false
		}
		const map = this.state.map

		printMessage("Removing click: ", messageLevels.debug)

		map.off("click", layerConfig.getValue("id"), layerConfig.mapClick)
		this.removeActiveLayerClick(layerConfig)
		return true
	}

	layerSetCursor = (layerConfig: LayerConfig) => {
		printData(layerConfig, messageLevels.debug, "layerSetCursor")
		if (!this.state.map || this.state.mapBusy) {
			return false
		}
		const map = this.state.map
		if (typeof layerConfig.cursor !== "undefined") {
			printMessage("Adding: " + layerConfig.cursor, messageLevels.debug)
			map.on("mouseenter", layerConfig.getValue("id"), (e) => {
				map.getCanvas().style.cursor = layerConfig.cursor || ""
			})
			map.on("mouseleave", layerConfig.getValue("id"), (e) => {
				map.getCanvas().style.cursor = ""
			})
			return true
		} else {
			return false
		}
	}

	layerRemoveCursor = (layerConfig: LayerConfig) => {
		printData(layerConfig, messageLevels.debug, "layerSetCursor")
		if (!this.state.map || this.state.mapBusy) {
			return false
		}
		const map = this.state.map

		printMessage("Removing: " + layerConfig.cursor, messageLevels.debug)

		map.off("mouseenter", layerConfig.getValue("id"), () => {})
		map.off("mouseleave", layerConfig.getValue("id"), () => {})
		return true
	}

	layerAddRollover = (layerConfig: LayerConfig) => {
		printData(layerConfig, messageLevels.debug, "layerAddClick")
		if (!this.state.map || this.state.mapBusy) {
			return false
		}
		const map = this.state.map
		if (typeof layerConfig.getValue("rollover") !== "undefined") {
			printMessage("Adding: " + layerConfig.rollover, messageLevels.debug)

			// map.on("mousemove", layerConfig.id, layerConfig.rollover);
			return true
		} else {
			return false
		}
	}

	mapOnClick = (layerConfig: LayerConfig) => {
		this.state.map?.on("click", layerConfig.getValue("id"), layerConfig.mapClick)
		this.addActiveLayerClick(layerConfig)
	}

	layerSetupClick = (layerConfig: any, mapRef: any) => {
		let map: mapboxgl.Map | null = null
		if (mapRef) {
			map = mapRef
		} else if (this.state.map) {
			map = this.state.map
		}
		if (map) {
			if (typeof layerConfig.clickAction !== "undefined") {
				printMessage("Adding: " + layerConfig.clickAction, messageLevels.debug)
				if (!this.state.activeLayerClicks.includes(layerConfig.id)) {
					this.mapOnClick(layerConfig)
					this.layerClicks[layerConfig.id] = layerConfig
					layerConfig.setValue(true, "_clickActive")
				}
			}
			if (typeof layerConfig.rollover !== "undefined") {
				printMessage("Adding: " + layerConfig.rollover, messageLevels.debug)

				map.on("mousemove", layerConfig.getValue("id"), (e: any) => {
					layerConfig.mapMouseEnter(e)
				})
			}
			if (typeof layerConfig.cursor !== "undefined") {
				printMessage("Adding: " + layerConfig.cursor, messageLevels.debug)

				map.on("mouseenter", layerConfig.id, (e: any) => {
					map!.getCanvas().style.cursor = layerConfig.cursor!
					layerConfig.setValue(layerConfig.cursor, "_cursorActive")
				})
				map.on("mouseleave", layerConfig.id, (e: any) => {
					layerConfig.mapMouseLeave(e)
					map!.getCanvas().style.cursor = ""
				})
			}
		}
	}

	addLayerToMap = (layer: any) => {
		if (!this.mapBusy) {
			printMessage("addLayers not busy", messageLevels.debug)
			this.addLayerIfInStyle(this.state.map, layer)
		}
	}

	addLayerToMapAtPrevious = (layer: any) => {
		if (!this.mapBusy) {
			printMessage("addLayers not busy", messageLevels.debug)
			this.addLayerIfInStyle(this.state.map, layer)
		}
	}

	removeLayerFromMap = async (layerConfig: mapLayer | string) => {
		if (typeof layerConfig === "string") {
			layerConfig = (await getItem("map-layers", layerConfig)) as mapLayer
		}
		if (isStoredItem(layerConfig)) {
			const layerName = layerConfig.getValue("id")

			this.removeLayerIfExists(layerName)
		}
	}

	addLayer = async (map: Map, layerConfig: any) => {
		if (typeof layerConfig === "string") {
			layerConfig = (await getItem("map-layers", layerConfig)) as mapLayer
		}
		printMessage("Adding layer: " + layerConfig.id + " Type: " + layerConfig.type, messageLevels.debug)
		switch (layerConfig.type) {
			case "fill":
			case "line":
			case "geojson":
				this.addDataLayer(map, layerConfig)
				break
			case "tile":
				this.addDataLayer(map, layerConfig)
				break
			case "raster":
				this.addRasterLayer(map, layerConfig)
				break
			case "terrain":
				// this.addTerrain(map, layerConfig);
				break
			case "sky":
				this.addSky(map, layerConfig)
				break
			case "fog":
				this.addFog(map, layerConfig)
				break
			case "buildings":
				this.addbuildings(map, layerConfig)
				break
			default:
				break
		}
		this.layerSetupClick(layerConfig, map)
		layerConfig.setupForLevel?.()
	}

	layerClickHandler = (action: any, layerId: any, features: any[]) => {
		const theFunction = this.props.clickHandlers[action]
		if (typeof theFunction !== "undefined") {
			theFunction(layerId, features[0])
		}
	}

	layerRolloverHandler = (action: string, layerId: string, features: any) => {
		printMessage("Action: " + action, messageLevels.debug)
		printMessage("layerId: " + layerId, messageLevels.debug)
		printData(features, messageLevels.debug)
	}

	addVectorLayer = (
		map: {
			addLayer: (arg0: { id: any; type: any; source: any; "source-layer": any; paint: any; layout: any }) => void
			setFilter: (arg0: any, arg1: any) => void
		},
		layerConfig: { getValue: (arg0: string) => any; filter: any },
	) => {
		map?.addLayer({
			id: layerConfig.getValue("id"),
			type: layerConfig.getValue("dataType"),
			source: layerConfig.getValue("source"),
			"source-layer": layerConfig.getValue("sourcelayer") || "",
			paint: layerConfig.getValue("paint"),
			layout: layerConfig.getValue("layout"),
		})
		if (typeof layerConfig.filter !== "undefined") {
			map?.setFilter(layerConfig.getValue("id"), layerConfig.getValue("filter"))
		}
	}

	addDataLayer = (map: mapboxgl.Map, layer: mapLayer) => {
		const beforeLayer = layer.getValue("before")

		const mapParams: any = {
			id: layer.getValue("id"),
			type: layer.getValue("dataType"),
			source: layer.getValue("source"),
			tolerance: 4,
		}
		if (layer.getValue("minzoom")) {
			mapParams.minzoom = layer.getValue("minzoom")
		}
		if (layer.getValue("maxzoom")) {
			mapParams.maxzoom = layer.getValue("maxzoom")
		}

		if (layer.getValue("paint")) {
			mapParams.paint = layer.getValue("paint")
		}

		if (layer.getValue("layout")) {
			mapParams.layout = layer.getValue("layout")
			if (
				layer.getValue("id") === "general-panoramas" ||
				layer.getValue("id") === "aerial-panoramas" ||
				layer.getValue("id") === "ground-panoramas"
			) {
				mapParams.layout["icon-image"] = getMarkerColor(layer.getValue("id"))
			}
		}

		if (layer.getValue("sourcelayer")) {
			mapParams["source-layer"] = layer.getValue("sourcelayer")
		}

		if (layer.getValue("pulse")) {
			this.pulse.push(createPulse(map, layer))
		}

		printData(mapParams, messageLevels.debug, "mapParams")
		if (beforeLayer) {
			map?.addLayer(mapParams, beforeLayer)
		} else {
			map?.addLayer(mapParams)
		}

		layer.isOnMap = true

		printData(map.getLayer(layer.getValue("id")), messageLevels.debug)

		if (typeof layer.getValue("filter") !== "undefined") {
			map?.setFilter(layer.getValue("id"), layer.getValue("filter"))
		}
	}

	addRasterLayer = (map: Map, layerConfig: mapLayer) => {
		const beforeId = layerConfig.getValue("beforeId")
		const theSourceName = layerConfig.getValue("source")
		const theSource = map.getSource(theSourceName)

		if (theSource) {
			try {
				if (!beforeId) {
					map?.addLayer({
						id: layerConfig.getValue("id"),
						type: layerConfig.getValue("type"),
						source: theSourceName,
						"source-layer": layerConfig.getValue("sourcelayer") || "",
						layout: layerConfig.getValue("layout"),
					})
				} else {
					map?.addLayer(
						{
							id: layerConfig.getValue("id"),
							type: layerConfig.getValue("type"),
							source: theSourceName,
							"source-layer": layerConfig.getValue("sourcelayer") || "",
							layout: layerConfig.getValue("layout"),
						},
						beforeId,
					)
				}
			} catch (e) {
				printMessage(layerConfig.primaryKey(), messageLevels.error)
				printData(layerConfig, messageLevels.error)
			}
		} else {
			printMessage("Source: " + theSourceName, messageLevels.verbose)
			printMessage("doesnt exist for: " + layerConfig.primaryKey(), messageLevels.verbose)
		}
	}

	updateSourceData = async (source: mapSource, data: any) => {
		if (!this.state.mapBusy && this.state.map) {
			if (typeof source === "string") {
				source = (await getItem("source-layer", source)) as mapSource
			}
			if (source && source.getValue("type") === "dataFeed") {
				const theSource = this.state.map?.getSource(source.getValue("id"))
				if (theSource) {
					printData(data, messageLevels.debug)
					//@ts-ignore
					theSource.setData(data)
				}
			}
		}
	}
	addSourceToMap = (source: any) => {
		if (this.state && !this.state.mapBusy && this.state.map) {
			this.addSource(this.state.map, source)
		}
	}

	addSource = async (map: mapboxgl.Map | null, source: string | mapSource) => {
		if (!map) return
		if (typeof source === "string") {
			source = (await getItem("source-layer", source)) as mapSource
		}
		if (source.getValue("level") && source.getValue("level") !== this.props.level) {
		} else {
			const theSource = map?.getSource(source.getValue("id"))
			if (theSource) {
				return
			}
			printMessage("Adding source: " + source.getValue("id"), messageLevels.debug)
			if (source.getValue("type") === "dataFeed") {
				printData(source.getValue("geoData"), messageLevels.debug, "GeoJSON in addSource " + source.getValue("id"))

				map?.addSource(source.getValue("id"), {
					type: "geojson",
					data: source.getValue("geoData"),
				})
				printData(map?.getSource(source.getValue("id")), messageLevels.debug, "Source in addSource")
			} else if (source.getValue("type") === "dynamicTiles") {
				if (source.tilesURLs) {
					map?.addSource(source.getValue("id"), {
						type: source.getValue("tilesType"),
						tiles: source.tilesURLs,
						minzoom: source.getValue("minzoom") || 0,
						maxzoom: source.getValue("maxzoom") || 22,
					})
				}
			} else if (source.getValue("tiles")) {
				const tilesUrls = source
					.getValue("tiles")
					.map((tilesUrl: string) =>
						tilesUrl.startsWith("https://") || tilesUrl.startsWith("http://") ? tilesUrl : getConfig("invokeUrl") + tilesUrl,
					)

				map.addSource(source.getValue("id"), {
					type: source.getValue("type"),
					tiles: tilesUrls,
					minzoom: source.getValue("minzoom") || 0,
					maxzoom: source.getValue("maxzoom") || 22,
					tileSize: source.getValue("id") === "linz-basemap" ? 256 : 512,
				})
			} else if (source.getValue("type") === "geojson") {
				map?.addSource(source.getValue("id"), {
					type: source.getValue("type"),
					data: source.getValue("data"),
				})
			} else {
				map?.addSource(source.getValue("id"), {
					type: source.getValue("type"),
					data: source.getValue("url"),
				})
			}
		}
	}

	setFilter = (layerId: string, filter: boolean | any[] | null | undefined) => {
		printMessage("Layer: " + layerId, messageLevels.debug)
		printData(filter, messageLevels.debug)
		if (!this.mapBusy) {
			if (this.state.map?.getLayer(layerId)) {
				this.state.map?.setFilter(layerId, filter)
			}
		}
	}

	setLayerOpacity = (layerId: string, opacity: any) => {
		if (this.state.map && !this.mapBusy) {
			const layer = this.state.map?.getLayer(layerId)
			if (typeof layer !== "undefined") {
				let layerType = layer.type
				if (layerType === "symbol") {
					// @ts-ignore
					layerType = "icon"
				}
				printData(layerType, messageLevels.debug, "layerType in setLayerOpacity")
				this.state.map?.setPaintProperty(layerId, layerType + "-opacity", opacity)
			}
		}
	}

	setPulseOpacity = (id: any, opacity: any) => {
		const pulse = this.pulse.find((pulse) => pulse.id === id)
		pulse?.setOpacity(opacity)
	}

	getLayerOpacity = (layerId: string) => {
		if (!!this.state.map && !this.mapBusy) {
			const layer = this.state.map?.getLayer(layerId)

			if (typeof layer !== "undefined") {
				let layerType = layer.type
				if (layerType === "symbol") {
					// @ts-ignore
					layerType = "icon"
				}
				printData(layerType, messageLevels.debug, "layerType in getLayerOpacity")
				const theValue = this.state.map?.getPaintProperty(layerId, layerType + "-opacity")
				if (layerType === "raster" && theValue === undefined) {
					return 1
				}
				return theValue
			}
		}
		return 0
	}

	setLayerColor = (layerId: string, color: any) => {
		if (!this.mapBusy) {
			const layer = this.state.map?.getLayer(layerId)
			if (typeof layer !== "undefined") {
				let layerType = layer.type
				if (layerType === "symbol") {
					// @ts-ignore
					layerType = "icon"
				}

				this.state.map?.setPaintProperty(layerId, layerType + "-color", color)
			}
		}
	}
	/****************Selection Markers****************************/

	createMarker = (
		props: mapboxgl.MarkerOptions | undefined,
		location: mapboxgl.LngLatLike,
		popupText: string,
		popupProps: mapboxgl.PopupOptions | undefined,
	) => {
		if (!this.mapBusy) {
			const map = this.state.map
			if (!map) return
			printMessage("Creating marker", messageLevels.debug)
			printData(location, messageLevels.debug)
			if (!location) {
				location = map?.getCenter()
			}
			if (popupText) {
				printData(popupText, messageLevels.verbose)
				const popup = new mapboxgl.Popup(popupProps).setHTML(popupText)
				printData(popup, messageLevels.verbose)
				const marker = new mapboxgl.Marker(props).setLngLat(location).setPopup(popup).addTo(map).togglePopup()
				printData(marker, messageLevels.verbose)
				return marker
			}
			const marker = new mapboxgl.Marker(props).setLngLat(location).addTo(map)

			printData(marker, messageLevels.debug)

			return marker
		}
	}

	removeMarker = (marker: { remove: () => void }) => {
		if (!this.mapBusy) {
			marker.remove()
		}
	}

	setMarkerEvent = (marker: { on: (arg0: any, arg1: any) => void }, event: any, callback: any) => {
		if (!this.mapBusy) {
			printMessage("Setting marker event", messageLevels.debug)
			marker.on(event, callback)
		}
	}
	setMarkerLocation = (marker: { setLngLat: (arg0: any) => void }, location: any) => {
		if (!this.mapBusy) {
			printMessage("Setting marker location", messageLevels.debug)
			marker.setLngLat(location)
		}
	}
	setMarkerColor = (marker: { setMarkerColor: (arg0: any) => void }, color: any) => {
		if (!this.mapBusy) {
			printMessage("Setting marker location", messageLevels.debug)
			marker.setMarkerColor(color)
		}
	}
	setMarkerDraggable = (marker: { setDraggable: (arg0: any) => void }, draggable: any) => {
		if (!this.mapBusy) {
			printMessage("Setting marker draggable", messageLevels.debug)
			marker.setDraggable(draggable)
		}
	}

	/****************Popups****************************/

	createPopup = (props: mapboxgl.PopupOptions | undefined, location: mapboxgl.LngLatLike, html: string) => {
		if (!this.mapBusy) {
			const map = this.state.map
			if (!map) return
			printMessage("Creating popup", messageLevels.debug)
			printData(location, messageLevels.debug)
			if (!location) {
				location = map?.getCenter()
			}
			const popup = new mapboxgl.Popup(props).setLngLat(location).setHTML(html).addTo(map)

			printData(popup, messageLevels.debug)

			return popup
		}
	}

	removePopup = (popup: { remove: () => void }) => {
		if (!this.mapBusy) {
			popup.remove()
		}
	}

	setPopupEvent = (popup: { on: (arg0: any, arg1: any) => void }, event: any, callback: any) => {
		if (!this.mapBusy) {
			printMessage("Setting popup event", messageLevels.debug)
			popup.on(event, callback)
		}
	}
	setPopupLocation = (popup: { setLngLat: (arg0: any) => void }, location: any) => {
		if (!this.mapBusy) {
			printMessage("Setting popup location", messageLevels.debug)
			popup.setLngLat(location)
		}
	}

	/****************SET PITCH****************************/

	setPitch = (value: number, toggleLoad: boolean) => {
		if (this.state.map) {
			toggleLoad && this.props.toggleMapLoad(true)
			this.state.map?.setPitch(value, { duration: 0 })
			this.setState({
				pitch: value,
			})
		}
	}
	getPitch = () => {
		if (this.state.map) {
			const value = this.state.map?.getPitch()
			return value
		}
		return 0
	}

	/****************CONTROL RELATED****************************/
	addControl = (control: any) => {
		this.addControlToMap(this.state.map, control)
	}
	addControlToMap = (map: mapboxgl.Map | null, control: { position: string | any[]; type: any; countries: any }) => {
		if (!map) return
		let thePosition
		if (control.position && Array.isArray(control.position)) {
			if (control.position.length > 1) {
				thePosition = control.position.join("-")
			} else {
				thePosition = control.position[0]
			}
		} else {
			thePosition = control.position
		}
		switch (control.type) {
			case "geocoder":
				map?.addControl(
					new MapboxGeocoder({
						accessToken: mapboxgl.accessToken,
						mapboxgl: mapboxgl,
						countries: control.countries,
						placeholder: "search for an address",
					}),
				)
				break
			case "navigation":
				map?.addControl(new mapboxgl.NavigationControl(), thePosition)
				break
			case "measurement":
				map?.addControl(this.Draw, thePosition)
				this.setState({ hasMeasurement: true })
				map?.on("draw.modechange", this.handleDrawModeChange)
				map?.on("draw.selectionchange", this.handleDrawSelect)
				map?.on("draw.create", this.handleDrawUpdate)
				map?.on("draw.update", this.handleDrawUpdate)
				document?.querySelector(".mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon")?.setAttribute("title", "Area Measure Tool")
				document?.querySelector(".mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_line")?.setAttribute("title", "Line Measure Tool")
				break
			default:
				break
		}
	}

	addControls() {
		// Add Mapbox controls
		if (!this.mapBusy) {
			const theStoreType = getStoreTypeByName("map-controls")
			if (!theStoreType) return
			const theControls = theStoreType.matchingItems({}) as mapControl[]
			if (theControls?.length) {
				theControls.forEach((control) => control.addToMap())
				this.addControlFailCount = 0
			} else {
				if (this.addControlFailCount >= 10) return
				this.addControlFailCount += 1
				setTimeout(this.addControls, 1000)
			}
		} else {
			if (this.addControlFailCount >= 10) return
			this.addControlFailCount += 1
			setTimeout(this.addControls, 1000)
		}
	}

	addSourcesAfterLoad = (type: any, key: any, data: any) => {
		removeOnRetrieve("map-sources", this.addSourcesAfterLoad)

		this.addSources()
		requestLoad("map-layers", undefined, this.addLayersAfterLoad)
	}

	addLayersAfterLoad = (type: any, key: any, data: any) => {
		removeOnRetrieve("map-layers", this.addSourcesAfterLoad)
		this.addLayers()
	}

	addSources() {
		// add sources
		if (!this.state.map) return
		if (!this.mapBusy) {
			printMessage("addSources not busy", messageLevels.debug)
			const thePlane = {
				type: "geojson",
				data: {
					type: "FeatureCollection",
					features: [
						{
							type: "Feature",
							geometry: {
								type: "Point",
								coordinates: [0, 0],
							},
						},
					],
				},
			}
			//@ts-ignore
			this.state.map?.addSource("groundplane", thePlane)

			const theStoreType = getStoreTypeByName("map-sources")
			const theSources = theStoreType?.matchingItems({}) as mapSource[]

			if (theSources) {
				theSources.map((source) => {
					return this.addSource(this.state.map, source)
				})
			}
		} else {
			printMessage("addSources map busy", messageLevels.verbose)
		}
	}

	addLayers() {
		// Add Layers
		if (!this.mapBusy) {
			printMessage("addLayers not busy", messageLevels.verbose)

			const theLayer = {
				id: "groundplane",
				type: "line",
				name: "groundplane",
				source: "groundplane",
				paint: {
					"line-color": "#888",
					"line-width": 8,
				},
				layout: {
					visibility: "none",
				},
			}
			//@ts-ignore
			this.state.map?.addLayer(theLayer)
			const theStoreType = getStoreTypeByName("map-layers")

			const theLayers = theStoreType?.matchingItems({
				sort: {
					sortField: "mapOrder",
					sortDirection: sortDirection.ascending,
					sortMethod: sortMethod.numeric,
					defaultSortValue: 2,
				},
			}) as mapLayer[]
			if (theLayers) {
				theLayers.map((layer) => {
					return layer.addToMap?.()
				})
			}
		} else {
			printMessage("addLayers map busy", messageLevels.verbose)
		}
	}

	removeLayers() {
		if (!this.mapBusy) {
			printMessage("removeLayers not busy", messageLevels.verbose)
			const theStoreType = getStoreTypeByName("map-layers")

			const theLayers = theStoreType?.matchingItems({
				sort: {
					sortField: "mapOrder",
					sortDirection: sortDirection.ascending,
					sortMethod: sortMethod.numeric,
					defaultSortValue: 2,
				},
			}) as mapLayer[]

			if (theLayers) {
				theLayers.map((layer) => {
					return layer.removeFromMap()
				})
			}
		} else {
			printMessage("removeLayers map busy", messageLevels.verbose)
			setTimeout(this.removeLayers, 100)
		}
	}

	addUI() {
		const map = this.state.map
		if (this.mapBusy) {
			setTimeout(this.addUI.bind(this), 500)
			return
		}
		if (this.isUIAdded) return
		this.isUIAdded = true
		requestLoad("map-sources", undefined, this.addSourcesAfterLoad)
		this.addControls()
		// set wastewater style - should not be hard coded - but in for demo

		map?.on("mousemove", (e) => {
			const now = Date.now()
			if (now - this.lastPositionUpdate > 500) {
				const mapZoom = map?.getZoom()
				const bearing = map?.getBearing()
				const pitch = map?.getPitch()
				const mapLocation = {
					lng: e.lngLat.lng.toFixed(5),
					lat: e.lngLat.lat.toFixed(5),
					zoom: mapZoom.toFixed(1),
					pitch: pitch.toFixed(1),
					bearing: bearing.toFixed(2),
				}

				printData(mapLocation, messageLevels.debug)
				this.setState(mapLocation)
				setGlobalState("map-current-view", mapLocation)
				this.lastPositionUpdate = now
			}
		})

		map?.on("wheel", () => {
			const mapZoom = map?.getZoom()
			const bearing = map?.getBearing()
			const pitch = map?.getPitch()
			const lng = getGlobalState("map-current-view")?.getValue("lng")
			const lat = getGlobalState("map-current-view")?.getValue("lat")
			const mapLocation = {
				lng,
				lat,
				zoom: mapZoom.toFixed(1),
				pitch: pitch.toFixed(1),
				bearing: bearing.toFixed(2),
			}

			printData(mapLocation, messageLevels.debug)
			this.setState(mapLocation)
			setGlobalState("map-current-view", mapLocation)
		})

		// reset cursor to default when user is no longer hovering over a clickable feature
		map?.on("mouseleave", "clicklayer", () => {
			map.getCanvas().style.cursor = ""
		})
		// map.on("styledata", () => {
		// 	printMessage("Map style load", messageLevels.debug);
		// });
		map?.on("style.load", () => {
			printMessage("Map style load", messageLevels.debug)
			this.awaitStyleLoad()
		})
	}
	awaitStyleLoad = () => {
		printData(this.state.map?.isStyleLoaded(), messageLevels.debug, "isStyleLoaded")
		if (this.state.map?.isStyleLoaded()) {
			this.mapBusy = false
			this.loadFeatureSymbols() // icons for marker
			this.addSources()
			this.addLayers()
			this.setStyleGroup()
			this.resetLayersToCurrentVisibility()
			this.resetLayersToCurrenttFilter()
			this.setPaint(this.state.visualiseBy)
			this.resumeDraw()
			printData(this.state.isThreeD, messageLevels.verbose, "isThreeD")
			this.state.isThreeD && this.addTerrainFromConfig()
		} else {
			setTimeout(() => {
				this.awaitStyleLoad()
			}, 200)
		}
	}

	goToLink = async (): Promise<any> => {
		if (this.flyToTryCount > 3) return
		this.flyToTryCount += 1
		if (!this.state.map || this.mapBusy) return setTimeout(this.goToLink, 500)
		const loginParams = decodeURIComponent(window.location.hash.substring(1))
		let decodedParams = loginParams
		let parsedParams: any = {}
		if (loginParams) {
			setGlobalState("isFromHash", true)
			if (!loginParams.startsWith("{") && !loginParams.startsWith("[")) {
				decodedParams = base64Decode(loginParams)
			}
			parsedParams = JSON.parse(decodedParams)
			printData(parsedParams, messageLevels.debug)
			const {
				level,
				selected,
				lat,
				lng,
				zoom,
				bounds,
				pitch,
				bearing,
				dimension,
				layers,
				polygons,
				lines,
				mapStyle,
				searchLayerState,
				selectedType,
				forestOwnershipFilterState,
			} = parsedParams

			if (level && selected) {
				const storeType = requestLoad(selectedType)
				const matchingItems = storeType?.matchingItems({
					queries: [`uuid == ${selected}`],
				}) as (forestBlock | region | forestOwner)[]
				const matchingItem = matchingItems?.[0]
				if (!matchingItem) return setTimeout(this.goToLink, 500)
				call("setLevelByItem", matchingItem, true)
			}
			dimension === "3D" && this.changeDimension(dimension)

			if (typeof lat !== "undefined" && typeof lng !== "undefined") {
				// move to lat/lng /zoom
				this.flyTo({
					// These options control the ending camera position: centered at
					// the target, at zoom level 9, and north up.
					center: [lng, lat],
					zoom: zoom || 9,
					bearing: bearing || 0,
					pitch: pitch || 0,

					// These options control the flight curve, making it move
					// slowly and zoom out almost completely before starting
					// to pan.
					speed: 3, // make the flying slow
					curve: 1, // change the speed at which it zooms out

					// This can be any easing function: it takes a number between
					// 0 and 1 and returns another number between 0 and 1.
					easing: (t) => {
						return Math.sin((t * Math.PI) / 2)
					},

					// this animation is considered essential with respect to prefers-reduced-motion
					essential: false,
				})
			} else if (bounds) {
				// move to lat/lng /zoom
				this.zoomToBounds(bounds)
			}
			await this.onceIdle()
			if (layers && Object.keys(layers).length > 0) {
				Object.entries(layers).forEach(([layer, visibility]) => {
					const _layer = getItem("map-layers", layer) as Promise<mapLayer>
					_layer.then((item) => {
						const canView = item.checkReadAuthority ? item.checkReadAuthority() : true
						if (!canView) {
							setGlobalState("linkLayersPermissionError", "Some layers will not be shown due to permission issue.")
							return
						}
						const isVisible = typeof visibility === "number" || visibility === true
						const opacity = Number(visibility)
						item.setValue(isVisible, "oneOffToggle")
						this.setLayerVisibility(layer, isVisible)
						if (typeof visibility === "number") {
							item.setOpacity(opacity)
						}
					})
				})
			}

			mapStyle && mapStyle != "satellite-v9" && this.changeMapState(mapStyle)

			const addMeasurement = (): any => {
				if (!this.state.hasMeasurement) return setTimeout(addMeasurement, 500)
				if (polygons && Object.keys(polygons).length) {
					Object.entries(polygons).forEach(([id, polygon]) => {
						const _polygon = polygon as Feature<Polygon, GeoJsonProperties>
						this.Measurements.addPolygon(_polygon)
						this.Draw.add(_polygon)
					})
				}
				if (lines && Object.keys(lines).length) {
					Object.entries(lines).forEach(([id, line]) => {
						const _line = line as Feature<LineString, GeoJsonProperties>
						this.Measurements.addLine(_line)
						this.Draw.add(_line)
						setTimeout(() => this.Elevation.addProfile(_line, this.state.map!))
					})
				}
			}
			addMeasurement()
			searchLayerState && setGlobalState("searchLayerState", searchLayerState)
			forestOwnershipFilterState && setGlobalState("forestOwnershipFilterState", forestOwnershipFilterState)
			removeHash()
			this.flyToTryCount = 0
		}
	}

	removeDraw = () => {
		this.state.map?.off("draw.create", this.handleDrawUpdate)
		this.state.map?.removeControl(this.Draw)
	}

	resumeDraw = () => {
		const theStoreType = getStoreTypeByName("map-controls")
		const theControls = theStoreType?.matchingItems({}) as mapControl[]
		const drawControl = theControls.find((control) => control.getValue("type") === "measurement")
		if (!drawControl) return
		this.state.map?.addControl(this.Draw, drawControl.getValue("position").join("-"))
		Object.values(this.Measurements.polygons).map((polygon) => this.Draw.add(polygon))
		Object.values(this.Measurements.lines).map((line) => this.Draw.add(line))
	}

	resetLayersToCurrentVisibility() {
		const theStoreType = getStoreTypeByName("map-layers")
		const theLayers = theStoreType?.matchingItems({}) as mapLayer[]
		if (theLayers) {
			theLayers.map((layer) => {
				if (layer.currentVisibility()) {
					return this.setLayerVisibility(layer.getValue("id"), layer.currentVisibility())
				}
				return null
			})
		}
	}
	resetLayersToCurrenttFilter() {
		const theStoreType = getStoreTypeByName("map-layers")
		const theLayers = theStoreType?.matchingItems({}) as mapLayer[]
		if (theLayers) {
			theLayers.map((layer) => {
				return layer.setFilters()
			})
		}
	}
	addLayerIfInStyle = (map: mapboxgl.Map | null, layer?: any) => {
		if (typeof layer === "string") {
			layer = getItem("map-layers", layer)
		}
		if (!map) return

		// check layer is within the list of layers the style will show.
		if (this.state.allLayersMode && !this.effectLayerTypes.includes(layer.type)) {
			this.addLayer(map, layer)
		} else {
			const index = this.state.activeMapStyle.layers.indexOf(layer.id)
			if (index >= 0) {
				this.addLayer(map, layer)
			}
		}
	}

	setStyle = (style: any) => {
		printMessage("Set Style: " + style, messageLevels.verbose)
		if (this.state.map) {
			this.state.map.setPaintProperty(style.layer, style.paint, {
				property: style.property,
				stops: style.stops,
			})
		}
	}

	setStyleGroup = () => {
		if (typeof this.state.map !== "undefined") {
			if (this.state.activeStyle && this.state.activeStyle.styles) {
				this.state.activeStyle.styles.map((style: any) => {
					return this.setStyle(style)
				})
			}
		}
	}

	paint = () => {
		this.setStyleGroup()
	}

	changeStyleState = (i: number) => {
		this.setState({ activeStyle: this.styleOptions[i] }, () => {
			this.setStyleGroup()
		})
	}

	changeMapState = (mapStyle: any) => {
		const currentStyle = this.mapTypes.filter((item) => item.mapStyle === mapStyle)
		if (this.state.activeMapStyle !== currentStyle[0]) {
			this.state.map?.removeLayer("groundplane")
			this.removeLayers()
			this.removeDraw()
		}

		this.setState({ activeMapStyle: currentStyle[0] }, () => {
			this.mapBusy = true

			if (currentStyle[0].mapStyle.startsWith("local:")) {
				const stylename = currentStyle[0].mapStyle.split(":")[1]
				this.state.map?.setStyle(`/styles/${stylename}/style.json`)
			} else {
				this.state.map?.setStyle("mapbox://styles/mapbox/" + currentStyle[0].mapStyle)
			}
		})
	}

	layersChanged = (type: string, key: string | undefined, data: any) => {
		this.layers = []
		if (key === "") {
			printMessage("Loaded: " + type, messageLevels.debug)
			printData(JSON.stringify(data), messageLevels.debug, "Data in layersChanged in Mapsight")
		}
	}

	configChanged: ChangeCallbackFunction = (type: string, key: string | undefined, data: storedItem) => {
		printMessage("Config changed Type: " + type + " " + key, messageLevels.debug)
		switch (key) {
			case "view":
				printData(data, messageLevels.debug, "view")
				this.setState({
					lng: data.getValue("lng"),
					lat: data.getValue("lat"),
					bearing: data.getValue("bearing"),
					zoom: data.getValue("zoom"),
					pitch: data.getValue("pitch"),
					wireframe: data.getValue("wireframe"),
				})
				break
			case "lineStyles":
				this.lineStyles = data
				break
			case "baseLayer":
				this.mapTypes = data.getValue("styleOptions")
				this.setState(
					{
						activeMapStyle: this.mapTypes[data.getValue("selectedStyle") || 0],
					},
					() => this.createMap(),
				)
				break
			case "sources":
				this.sources = data.getValue()

				break
			case "controls":
				this.controls = data.getValue()
				break
			default:
				printMessage("No matching control", messageLevels.debug)
		}
		if (!key) {
			this.doLoadLayers = true
			this.addUI()
		}
	}

	loadFeatureSymbols() {
		if (this.state.map) {
			const map = this.state.map

			const addImage = (
				image: string,
				imageKey:
					| HTMLImageElement
					| ImageData
					| ImageBitmap
					| ArrayBufferView
					| { width: number; height: number; data: Uint8Array | Uint8ClampedArray }
					| undefined,
			) => {
				if (!imageKey) return
				map.addImage(image, imageKey)
			}

			map.loadImage("/images/map/dark-purple-icon@0.75x.png", (error, image) => {
				if (!error) addImage("dark-purple-icon", image)
			})
			map.loadImage("/images/map/green-icon@0.75x.png", (error, image) => {
				if (!error) addImage("green-icon", image)
			})
			map.loadImage("/images/map/light-blue-icon@0.75x.png", (error, image) => {
				if (!error) addImage("light-blue-icon", image)
			})
			map.loadImage("/images/map/maroon-icon@0.75x.png", (error, image) => {
				if (!error) addImage("maroon-icon", image)
			})
			map.loadImage("/images/map/red-icon@0.75x.png", (error, image) => {
				if (!error) addImage("red-icon", image)
			})
			map.loadImage("/images/map/orange-icon@0.75x.png", (error, image) => {
				if (!error) addImage("orange-icon", image)
			})
			map.loadImage("/images/map/light-green-icon@0.75x.png", (error, image) => {
				if (!error) addImage("light-green-icon", image)
			})
			map.loadImage("/images/map/dark-blue-icon@0.75x.png", (error, image) => {
				if (!error) addImage("dark-blue-icon", image)
			})
			map.loadImage("/images/map/yellow-icon@0.75x.png", (error, image) => {
				if (!error) addImage("yellow-icon", image)
			})
		}
	}

	createMap() {
		// this.tooltipRef.current = new mapboxgl.Popup({
		// 	offset: 15,
		// 	maxWidth: "none",
		// });
		mapboxgl.accessToken = this.props.accessToken
		let styleName = "satellite-v9"
		if (this.state.activeMapStyle && this.state.activeMapStyle.activeStyle) {
			styleName = this.state.activeMapStyle.mapStyle
		}
		const mapStyle =
			styleName === "local-linz"
				? "/styles/linz.json" // Dark: /dark-v10, Outdoors: /outdoors-v11, Street: /streets-v11 , Satellite: satellite-v9
				: "mapbox://styles/mapbox/" + styleName + "?optimize=true" // Dark: /dark-v10, Outdoors: /outdoors-v11, Street: /streets-v11 , Satellite: satellite-v9

		const map = new mapboxgl.Map({
			container: this.mapContainerRef.current!,
			style: mapStyle,
			center: [this.state.lng, this.state.lat],
			bearing: this.state.bearing,
			zoom: this.state.zoom,
			pitch: this.state.pitch,
			transformRequest: this.transformRequest,
			preserveDrawingBuffer: true,
			antialias: false,
			minZoom: 4,
		})
		map.showTerrainWireframe = this.state.wireframe || false
		this.setState({ map: map })
		map.on("load", () => {
			this.mapBusy = false
			this.loadFeatureSymbols()
			call("mapIsLoaded")
			this.goToLink()
		})
	}

	getCanvas = () => {
		return this.state.map?.getCanvas()
	}

	componentDidMount() {
		printMessage("Map component Did mount", messageLevels.debug)

		registerCall("setLayerVisibility", this.setLayerVisibility, true)
		registerCall("getLayerVisibility", this.getLayerVisibility, true)
		registerCall("setLayerFilter", this.setFilter, true)
		registerCall("setLayerColor", this.setLayerColor, true)
		registerCall("setPaintProperty", this.setPaint, true)
		registerCall("setSourceBounds", this.setSourceBounds, true)
		registerCall("setLayerOpacity", this.setLayerOpacity, true)
		registerCall("getLayerOpacity", this.getLayerOpacity, true)
		registerCall("hideLayer", this.hideLayer, true)
		registerCall("showLayer", this.showLayer, true)
		registerCall("addLayer", this.addLayerToMap, true)
		registerCall("addLayerAtPrevious", this.addLayerToMapAtPrevious, true)
		registerCall("removeLayer", this.removeLayerFromMap, true)
		registerCall("addSource", this.addSourceToMap, true)
		registerCall("removeSource", this.removeSourceIfExists, true)
		registerCall("updateSourceData", this.updateSourceData, true)
		registerCall("layerAddClick", this.layerAddClick, true)
		registerCall("layerRemoveClick", this.layerRemoveClick, true)
		registerCall("layerSetCursor", this.layerSetCursor, true)
		registerCall("layerRemoveCursor", this.layerRemoveCursor, true)
		registerCall("layerAddRollover", this.layerAddRollover, true)
		registerCall("addControl", this.addControl, true)
		registerCall("setPitch", this.setPitch, true)
		registerCall("changeDimension", this.changeDimension, true)
		registerCall("createMarker", this.createMarker, true)
		registerCall("removeMarker", this.removeMarker, true)
		registerCall("setMarkerEvent", this.setMarkerEvent, true)
		registerCall("setMarkerLocation", this.setMarkerLocation, true)
		registerCall("setMarkerColor", this.setMarkerColor, true)
		registerCall("setMarkerDraggable", this.setMarkerDraggable, true)
		registerCall("createPopup", this.createPopup, true)
		registerCall("removePopup", this.removePopup, true)
		registerCall("setPopupEvent", this.setPopupEvent, true)
		registerCall("setPopupLocation", this.setPopupLocation, true)
		registerCall("changeMapState", this.changeMapState, true)
		registerCall("setConsentID", this.setConsentId)
		registerCall("zoomToBoundsArray", this.zoomToBoundsArray, true)
		registerCall("zoomToBounds", this.zoomToBounds, true)
		registerCall("getCanvas", this.getCanvas, true)
		registerCall("waitTilesLoaded", this.waitTilesLoaded, true)
		registerCall("getViewParams", this.getViewParams, true)
		registerCall("pausePulse", this.pausePulse, true)
		registerCall("setPulseOpacity", this.setPulseOpacity, true)
		registerCall("dumpMapLayers", this.dumpMapLayers, true)
		registerCall("dumpMapSources", this.dumpMapSources, true)

		requestLoad("config", this.configChanged)
		requestLoad("map-sources", this.layersChanged)
		requestLoad("map-layers", this.layersChanged)
		requestLoad("map-controls", this.layersChanged)
		requestLoad("map-view", this.configChanged)
	}

	pausePulse = () => {
		this._pausePulse = true
		this.pulse.forEach((pulse) => {
			pulse.stop()
		})
	}

	restartPulse = () => {
		this._pausePulse = false
		this.pulse.forEach((pulse) => {
			pulse.start()
		})
	}

	setPaint = (visualiseBy: string) => {
		this.setState({ visualiseBy: visualiseBy })
		const theStoreType = getStoreTypeByName("map-layers")
		const theLayers = theStoreType?.matchingItems({}) as mapLayer[]

		// ToDo Remove the hard coded references to layers and move this to layer class
		if (theLayers) {
			theLayers.map((layer) => {
				if (layer.getValue("id") === "forest-blocks-fill") {
					return layer.setDisplayMode(visualiseBy)
				}
				if (layer.getValue("id") === "forest-blocks-line") {
					return layer.setDisplayMode(visualiseBy)
				}
				return null
			})
		}
	}

	componentWillUnmount() {
		if (this.state.map) {
			this.state.map.remove()
		}
		removeOnChange("config", this.configChanged)
		removeOnChange("map-sources", this.layersChanged)
		removeOnChange("map-layers", this.layersChanged)
		removeOnChange("map-controls", this.layersChanged)
		removeOnChange("map-view", this.configChanged)
	}

	getViewParams = () => {
		if (typeof this.state.map !== "undefined" && this.state.map) {
			const zoom = this.state.map.getZoom()
			const { lng, lat } = this.state.map.getCenter()
			const pitch = this.state.map.getPitch()
			const bearing = this.state.map.getBearing()
			const dimension = this.state.isThreeD ? "3D" : "2D"
			const selected = getGlobalState("selected")?.getValue()?.[0]
			const level = getGlobalState("level")?.getValue()
			const mapLayers = getStoreTypeByName("map-layers")?.matchingItems({}) as mapLayer[]
			const layers = mapLayers.reduce((acc: any, layer) => {
				if (layer.getValue("_visibility") || layer.showInCurrentLevel()) {
					if (layer.getValue("_visibility")) {
						acc[layer.getValue("id")] = layer.currentOpacity() ?? layer.getValue("_visibility")
					} else {
						acc[layer.getValue("id")] = layer.getValue("_visibility")
					}
				}
				return acc
			}, {})
			const polygons = this.Measurements.polygons
			const lines = this.Measurements.lines
			const mapStyle = this.state.activeMapStyle.mapStyle
			const searchLayerState = getGlobalState("searchLayerState")?.getValue()
			const forestOwnershipFilterState = getGlobalState("forestOwnershipFilterState")?.getValue()
			const viewParams = {
				lng: (lng * 1000000) / 1000000,
				lat: (lat * 1000000) / 1000000,
				zoom: (zoom * 100) / 100,
				pitch: (pitch * 10) / 10,
				bearing: (bearing * 10) / 10,
				dimension,
				selected: selected?.uuid,
				level,
				selectedType: selected?.getTypeName(),
				layers,
				polygons,
				lines,
				mapStyle,
				searchLayerState,
				forestOwnershipFilterState,
			}
			return viewParams
		}
		return {}
	}

	mapTitleReducer = (previousValue: { mapStyle: any }, currentValue: { mapStyle: any }) => {
		if (previousValue && previousValue.mapStyle !== this.state.activeMapStyle.mapStyle) {
			this.mapShuffleTypes.push(previousValue)
		}
		if (currentValue && currentValue.mapStyle !== this.state.activeMapStyle.mapStyle) {
			this.mapShuffleTypes.push(currentValue)
		}
	}
	removeTerrain = () => {
		if (this.state.map) this.state.map.setTerrain(null)
	}
	addTerrainFromConfig = () => {
		const terrainLayer = this.state.map?.getLayer("terrain")
		if (terrainLayer) {
			this.showLayer("terrain")
		} else {
			const theStoreType = getStoreTypeByName("map-layers")
			const theLayers = theStoreType?.matchingItems({})
			this.showLayer("terrain")
			if (theLayers) {
				const layerConfig = theLayers.filter((item) => item.getValue("type") === "terrain")
				if (layerConfig && layerConfig.length > 0) {
					this.addTerrain(this.state.map, layerConfig[0])
				}
			}
		}
	}

	waitTilesLoaded = async () => {
		await this.onceIdle()
		call("mapIsLoaded")
	}

	onceIdle = async () => {
		if (!this.isWaitingIdle) {
			this.isWaitingIdle = true
			this.pausePulse()
		}
		await this.state.map?.once("idle")
		if (this.isWaitingIdle) {
			this.restartPulse()
			this.isWaitingIdle = false
		}
	}

	/********************CHANGE DIMENSION***********************/
	changeDimension = throttle((type: string) => {
		if (this.state.isThreeD === (type === "3D")) return
		if (type === "3D") {
			this.addTerrainFromConfig()
			if (this.getPitch() < 5) {
				this.setPitch(30, true)
			}
		} else {
			this.hideLayer("terrain")
			this.setPitch(0, true)
			this.removeTerrain()
			this.removeSourceIfExists("mapbox-dem")
		}
		this.waitTilesLoaded()
		this.setState({
			isThreeD: type === "3D" ? true : false,
		})
	}, 250)

	/********************CHANGE DIMENSION**********************/
	generateControl = (control: { position: any[]; id: any; type: any }, i: any) => {
		let classes = "absolute m12"
		if (Object.prototype.hasOwnProperty.call(control, "position")) {
			control.position.map((arg: string) => {
				classes = classes + " " + arg
				return classes
			})
		}
		const key = `${control.id}:${i}`

		switch (control.type) {
			case "legend":
				if (!isNgatiApa() && typeof this.state.activeStyle.labels !== "undefined") {
					return (
						<div key={key} className={classes}>
							{!this.state.isShowRemoteHqBox && (
								<span className="adjust-bottom" onClick={() => this.showRemoteHqBox(true)}>
									<Icon style={{ cursor: "pointer", color: "#1b1c1d" }} name="key" />
								</span>
							)}
							{this.state.isShowRemoteHqBox && (
								<Legend
									active={this.state.activeStyle}
									labels={this.state.activeStyle.labels}
									showRemoteHqBox={this.showRemoteHqBox}
								/>
							)}
						</div>
					)
				}
				break
			case "terrain":
				if (typeof this.state.activeStyle.labels !== "undefined") {
					return (
						<div key={key} className={classes}>
							{this.state.isThreeD && (
								<div className="adjust-bottom-div" onClick={() => this.changeDimension("2D")}>
									2D
								</div>
							)}
							{!this.state.isThreeD && (
								<div className="adjust-bottom-div" onClick={() => this.changeDimension("3D")}>
									3D
								</div>
							)}
						</div>
					)
				}
				break

			case "options":
				return (
					<div key={key} className={classes}>
						<Optionsfield
							options={this.styleOptions}
							property={this.state.activeStyle.id}
							changeState={this.changeStyleState}
						/>
					</div>
				)

			case "maptiles":
				this.mapShuffleTypes = []
				const currentStyle = this.mapTypes.filter((item: { mapStyle: any }) => item.mapStyle === this.state.activeMapStyle.mapStyle)
				this.mapTypes.reduce(this.mapTitleReducer)
				this.mapShuffleTypes.push(currentStyle[0])

				return (
					<div key={key} className={classes}>
						<MapSelector
							options={this.mapShuffleTypes}
							property={this.state.activeMapStyle.mapStyle}
							changeState={this.changeMapState}
						/>
					</div>
				)

			default:
				return <React.Fragment key={i}> </React.Fragment>
		}
	}
	dumpMapLayers = () => {
		if (this.state.map) {
			printData(this.state.map.getStyle().layers, messageLevels.none)
		} else {
			printMessage("No map", messageLevels.none)
		}
	}
	dumpMapSources = () => {
		if (this.state.map) {
			printData(this.state.map.getStyle().sources, messageLevels.none)
		} else {
			printMessage("No map", messageLevels.none)
		}
	}
	showRemoteHqBox = (isOpen: any) => {
		this.setState({ isShowRemoteHqBox: isOpen })
	}

	handleDrawModeChange = (e: { mode: any }) => {
		switch (e.mode) {
			case "draw_line_string":
			case "draw_polygon":
				this.setState({ isDrawing: true })
				this.state.activeLayerClicks.forEach((id: string | number) => this.layerRemoveClick(this.layerClicks[id]))
				break
			case "simple_select":
				this.setState({ isDrawing: false })
				this.state.inactiveLayerClicks.forEach((id: string | number) => this.layerAddClick(this.layerClicks[id]))
				break
			default:
				return
		}
	}

	handleDrawSelect = async (e: { features: { id: any }[] }) => {
		this.changeDimension("3D")
		await this.onceIdle()
		const id = e.features?.[0]?.id
		this.renderPopup(id)
		setGlobalState("selectedDraw", id)
	}

	handleDrawUpdate = async (e: { features: Feature[] }) => {
		if (!this.state.map) return
		this.changeDimension("3D")
		const type = e.features[0].geometry.type
		switch (type) {
			case "Polygon":
				this.Measurements.addPolygon(e.features as Feature<Polygon>[])
				break
			case "LineString":
				await this.onceIdle()
				this.Measurements.addLine(e.features as Feature<LineString>[])
				this.Elevation.addProfile(e.features as Feature<LineString>[], this.state.map)
				break
			default:
				return
		}
		setGlobalState("selectedDraw", e.features?.[0]?.id)
	}

	renderPopup = (id: string | number) => {
		if (!this.state.map) return
		if (this.Measurements.polygons[id] || this.Measurements.lines[id]) {
			const item = this.Measurements.polygons[id] || this.Measurements.lines[id]
			const popupNode = document.createElement("div")
			const { center } = item
			const popup = new mapboxgl.Popup({
				closeButton: false,
			})
			const handleRemove = () => {
				this.Measurements.remove(id)
				this.Elevation.remove(id)
				setGlobalState("selectedDraw", null)
			}
			ReactDOM.render(<MeasurementPopup id={id} item={item} popup={popup} draw={this.Draw} onRemove={handleRemove} />, popupNode)
			popup.setLngLat(center).setDOMContent(popupNode).addTo(this.state.map)
		}
	}

	render() {
		return (
			<div>
				<div ref={this.mapContainerRef} className="map-container" />
				{this.controls.map((control: any, i: any) => {
					return this.generateControl(control, i)
				}, this)}
			</div>
		)
	}
}

export default MapSight
