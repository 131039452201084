import { Icon } from "semantic-ui-react"

const ErrorPage = (): JSX.Element => {
	return (
		<div
			style={{
				height: "100vh",
				width: "100vw",
				backgroundColor: "#ffcccc",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div style={{ textAlign: "center" }}>
				<Icon name="exclamation triangle" size="large" color="red" />
				<h2>Error Occurred</h2>
				<p>Sorry, there was an error.</p>
			</div>
		</div>
	)
}

export default ErrorPage
