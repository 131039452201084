import React from "react"
import { Icon, Form, Accordion, DropdownProps } from "semantic-ui-react"

import { storedItem } from "../../storedItem"
import { EditViewProps } from "../Types"

import variables from "../../../variables"
import SelectionListComponent from "../../components/SelectionListComponent"

import PinPlaceGeoJSONPoint from "../../../mapsight/PinGeoJSONPoint"

import { ReportItem } from "../../classes/report"
import { SkidSiteEditView } from "../SkidSite"
import { selectionItem, SelectionItemList } from "../../managers/SelectionList"

const { skidCompliancedropdownOptionsFormatted, skidSiteQuestions } = variables

export interface ReportSkidsEditState {
	activeSkid: number | null
	changed: boolean
}

export default class ReportSkidsEditView extends React.Component<EditViewProps, ReportSkidsEditState> {
	state = {
		activeSkid: null,
		changed: false,
	}

	onComplianceChange = (item: storedItem, question: string) => (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		item.setValue(data.value, question)
	}
	valueChanged = (item: selectionItem, list: SelectionItemList) => {
		list.toggle(item)
		if (this.props.setDirty) {
			this.props.setDirty()
		}
	}

	renderSkidSiteForm = (item: storedItem) => {
		return Object.entries(skidSiteQuestions).map(([key, question]) => {
			return (
				<Form.Select
					key={key}
					label={question}
					placeholder="Select a Compliance Status"
					name={`question-${item.primaryKey()}`}
					options={skidCompliancedropdownOptionsFormatted}
					onChange={this.onComplianceChange(item, key)}
					defaultValue={item.getValue(key)}
				/>
			)
		})
	}

	skidCellFormatter = (item: selectionItem, list: SelectionItemList) => {
		const { activeSkid } = this.state
		const as_built_skid_site_id = item.item.getValue("local_id")
		const skidDisplay = item.item.displayName()
		const hidePins = true
		return (
			<Accordion fluid styled inverted>
				<Accordion.Title
					style={item.selected ? { backgroundColor: "#4084CA" } : {}}
					active={activeSkid === as_built_skid_site_id}
					index={0}
					onClick={() =>
						this.setState({
							activeSkid: activeSkid === as_built_skid_site_id ? null : as_built_skid_site_id,
						})
					}
				>
					{skidDisplay}
					<Icon name="dropdown" />
				</Accordion.Title>
				<Accordion.Content active={activeSkid === as_built_skid_site_id}>
					<Form.Radio toggle label="Include in report?" checked={item.selected} onChange={() => this.valueChanged(item, list)} />
					<SkidSiteEditView editingItem={item.item} hideHeading={true} hidePin></SkidSiteEditView>

					{/* {item.selected && !hidePins ? <PinPlaceGeoJSONPoint hideDisplay={true}  pinColor={"#1111dd"} initialLocation={ item.item?.getValue("geojson") } ></PinPlaceGeoJSONPoint> : <></>} */}
				</Accordion.Content>
			</Accordion>
		)
	}
	render() {
		const { visible, editingItem } = this.props
		const skidSites: SelectionItemList | null = (editingItem as ReportItem).skidSelectionList
		if (!skidSites) return null
		return (
			<div className={`report-form${visible ? " active" : ""}`}>
				<SelectionListComponent data={skidSites} cellFormatter={this.skidCellFormatter}></SelectionListComponent>
			</div>
		)
	}
}
