import React from "react"
import { IconProps, Label, Loader, SemanticCOLORS, SemanticShorthandItem } from "semantic-ui-react"

import "./styles.css"

interface IValueOnlyInfoBoxComponentProps {
	name: string
	value?: string | number
	size?: "lg" | "md" | "sm"
	isLoading?: boolean
	color?: SemanticCOLORS
	icon?: SemanticShorthandItem<IconProps>
}

const ValueOnlyInfoBoxComponent: React.FC<IValueOnlyInfoBoxComponentProps> = ({ name, value, size = "md", isLoading, icon, color }) => {
	return (
		<div className={`info-box-wrapper`}>
			{icon && color ? <Label as="a" color={color} ribbon icon={icon} /> : null}
			<div className={`info-box info-box-${size}`}>
				<div className={`info-box-number`}>{isLoading ? <Loader active inline /> : value}</div>
				<div className={`info-box-title`}>{name}</div>
			</div>
		</div>
	)
}

export default ValueOnlyInfoBoxComponent
