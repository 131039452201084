import { call } from "../../store"
import { storeType } from "../../store/storeType"
import { mapLayer } from "./mapLayer"
import { classInit } from "."

export class mapMultiLayer extends mapLayer {
	_fillLayer: mapLayer | null = null
	_lineLayer: mapLayer | null = null
	_highlightLayer: mapLayer | null = null
	_selectedLayer: mapLayer | null = null
	_pulseLayer: mapLayer | null = null
	_circleLayer: mapLayer | null = null
	_shadeLayer: mapLayer | null = null
	_symbolLayer: mapLayer | null = null

	constructor(type: storeType, data?: any, doNotStore?: boolean) {
		super(type, data, doNotStore)
	}

	addToMap = () => this.addToMapLocal()

	removeFromMap = () => this.removeFromMapLocal()

	addToMapLocal() {
		this.fillLayer?.addToMap()
		this.lineLayer?.addToMap()
		this.highlightLayer?.addToMap()
		this.selectedLayer?.addToMap()
		this.shadeLayer?.addToMap()
		this.pulseLayer?.addToMap()
		this.circleLayer?.addToMap()
		this.symbolLayer?.addToMap()
	}
	removeFromMapLocal() {
		this.fillLayer?.removeFromMap()
		this.lineLayer?.removeFromMap()
		this.highlightLayer?.removeFromMap()
		this.selectedLayer?.removeFromMap()
		this.pulseLayer?.removeFromMap()
		this.shadeLayer?.removeFromMap()
		this.circleLayer?.removeFromMap()
		this.symbolLayer?.removeFromMap()
	}

	get fillLayer(): mapLayer | null {
		if (this._fillLayer) {
			return this._fillLayer
		}
		const fillParams = this.getValue("fill")
		if (fillParams) {
			const type = this.getType()
			if (type) {
				const paramsArray = this.getValues([
					"type",
					"source",
					"sourcelayer",
					"beforeId",
					"objectType",
					"mapFeatureKey",
					"rollover",
					"clickAction",
					"cursor",
					"layout",
					"filterToRegionMode",
					"showInLevels",
				])

				const fillLayer = new mapLayer(type, {
					id: this.getValue("id") + "-fill",
					name: this.getValue("name") + " Fill",
					...paramsArray,
					dataType: "fill",
					...fillParams,
				})

				this._fillLayer = fillLayer
			}
			return this._fillLayer
		}
		return null
	}

	get lineLayer(): mapLayer | null {
		if (this._lineLayer) {
			return this._lineLayer
		}
		const lineParams = this.getValue("line")

		if (lineParams) {
			const type = this.getType()
			if (type) {
				const paramsArray = this.getValues([
					"type",
					"source",
					"sourcelayer",
					"beforeId",
					"objectType",
					"mapFeatureKey",
					"rollover",
					"clickAction",
					"cursor",
					"layout",
					"filterToRegionMode",
					"showInLevels",
				])

				const lineLayer = new mapLayer(type, {
					id: this.getValue("id") + "-line",
					name: this.getValue("name") + " Outline",
					...paramsArray,
					dataType: "line",
					...lineParams,
				})

				this._lineLayer = lineLayer
			}
			return this._lineLayer
		}
		return null
	}

	get highlightLayer(): mapLayer | null {
		if (this._highlightLayer) {
			return this._highlightLayer
		}
		const highlightParams = this.getValue("highlight")

		if (highlightParams) {
			const type = this.getType()
			if (type) {
				const paramsArray = this.getValues([
					"type",
					"source",
					"sourcelayer",
					"objectType",
					"mapFeatureKey",
					"clickAction",
					"cursor",
					"layout",
					"showInLevels",
				])

				const highlightLayer = classInit(type, {
					id: this.getValue("id") + "-highlight",
					name: this.getValue("name") + " Highlight",
					...paramsArray,
					dataType: "highlight",
					...highlightParams,
				}) as mapLayer

				this._highlightLayer = highlightLayer
			}
			return this._highlightLayer
		}
		return null
	}

	get selectedLayer(): mapLayer | null {
		if (this._selectedLayer) {
			return this._selectedLayer
		}
		const selectedParams = this.getValue("selected")

		if (selectedParams) {
			const type = this.getType()
			if (type) {
				const paramsArray = this.getValues([
					"type",
					"source",
					"sourcelayer",
					"objectType",
					"mapFeatureKey",
					"clickAction",
					"cursor",
					"layout",
					"showInLevels",
				])

				const selectedLayer = classInit(type, {
					id: this.getValue("id") + "-selected",
					name: this.getValue("name") + "  Selected",
					...paramsArray,
					dataType: "selected",
					...selectedParams,
				}) as mapLayer

				this._selectedLayer = selectedLayer
			}
			return this._selectedLayer
		}
		return null
	}

	get pulseLayer(): mapLayer | null {
		if (this._pulseLayer) {
			return this._pulseLayer
		}
		const pulseParams = this.getValue("pulse")
		if (pulseParams) {
			const type = this.getType()
			if (type) {
				const paramsArray = this.getValues(["type", "source", "sourcelayer", "objectType", "mapFeatureKey", "layout"])

				const pulseLayer = classInit(type, {
					id: this.getValue("id") + "-pulse",
					name: this.getValue("name") + " Pulse",
					...paramsArray,
					dataType: "line",
					...pulseParams,
					...pulseParams?.line,
				}) as mapLayer
				this._pulseLayer = pulseLayer
			}
			return this._pulseLayer
		}
		return null
	}

	get shadeLayer(): mapLayer | null {
		if (this._shadeLayer) {
			return this._shadeLayer
		}
		const shadeParams = this.getValue("shade")
		if (shadeParams) {
			const type = this.getType()
			if (type) {
				const paramsArray = this.getValues(["type", "source", "sourcelayer", "objectType", "mapFeatureKey", "layout"])

				const shadeLayer = classInit(type, {
					id: this.getValue("id") + "-shade",
					name: this.getValue("name") + " Shading",
					...paramsArray,
					dataType: "fill",
					...shadeParams,
				}) as mapLayer

				this._shadeLayer = shadeLayer
			}
			return this._shadeLayer
		}
		return null
	}

	get circleLayer(): mapLayer | null {
		if (this._circleLayer) {
			return this._circleLayer
		}
		const circleParams = this.getValue("circle")
		if (circleParams) {
			const type = this.getType()
			if (type) {
				const paramsArray = this.getValues([
					"type",
					"source",
					"sourcelayer",
					"beforeId",
					"objectType",
					"mapFeatureKey",
					"rollover",
					"clickAction",
					"cursor",
					"layout",
					"filterToRegionMode",
					"showInLevels",
				])

				const circleLayer = classInit(type, {
					id: this.getValue("id") + "-circle",
					name: this.getValue("name") + " circle",
					...paramsArray,
					dataType: "circle",
					...circleParams,
				}) as mapLayer
				this._circleLayer = circleLayer
			}
			return this._circleLayer
		}
		return null
	}

	get symbolLayer(): mapLayer | null {
		if (this._symbolLayer) return this._symbolLayer

		const symbolParams = this.getValue("symbol")
		if (symbolParams) {
			const type = this.getType()
			if (type) {
				const paramsArray = this.getValues(["type", "source", "sourcelayer", "objectType", "mapFeatureKey", "layout"])

				const symbolLayer = classInit(type, {
					id: this.getValue("id") + "-symbol",
					name: this.getValue("name") + " symbol",
					...paramsArray,
					dataType: "symbol",
					...symbolParams,
				}) as mapLayer
				this._symbolLayer = symbolLayer
			}
			return this._symbolLayer
		}
		return null
	}

	allowsOpacity = (): boolean => false

	currentVisibility(): boolean {
		if (!this.isDisabled()) {
			return this.getValue("_visibility")
		}
		return this.storedVisibility()
	}

	setVisibility(visibililty: boolean): void {
		if (!this.isDisabled()) {
			call("setLayerVisibility", this.primaryKey(), visibililty)
			this.childLayers.forEach((element) => element.setVisibility(visibililty))
			this.fillLayer?.setVisibility(visibililty)
			this.lineLayer?.setVisibility(visibililty)
			this.highlightLayer?.setVisibility(visibililty)
			this.selectedLayer?.setVisibility(visibililty)
			this.pulseLayer?.setVisibility(visibililty)
			this.circleLayer?.setVisibility(visibililty)
			this.symbolLayer?.setVisibility(visibililty)
			this.shadeLayer?.setVisibility(visibililty)
		}
		this.setValue(visibililty, "_visibility")
	}
}
