import { ReactNode, useEffect, useState } from "react"
import { Icon, Menu, SemanticICONS, Sidebar } from "semantic-ui-react"

import SearchLayer from "../SearchLayer"
import DashboardLayer from "../DashboardLayer"
import DataToolbox from "../DataToolbox"
import NoticeLayer from "../NoticeLayer"
import Layer from "../LayersLayer"
import Report from "../Report"
import OwnershipSearch from "../OwnershipFilter"
import ModelViewer from "../../../components/ModelViewer"
import {
	call,
	getGlobalState,
	getUIConfig,
	isItemType,
	registerOnChange,
	registerforStateChanges,
	removeOnChange,
	removeStateChanges,
} from "../../../store/index"
import "../styles.css"
import { checkLoadedAuthority, printData, printMessage } from "../../../providers/remoteHQ"
import { actions, messageLevels, objects } from "../../../store/types"

interface RemoteHQSidebarProps {
	onActiveCapture: (key: string) => void
	onClearImage: (key: string) => void
	isCapturing: boolean
	capturedImagesByKey: { [key: string]: string }
	children: ReactNode
}

interface MenuItemProps {
	title?: string
	className?: string
	label?: string
	icon: SemanticICONS | undefined
	activeColor?: string
	baseColor?: string
	active?: boolean
	disabled?: boolean
	hide?: boolean
	secondaryIcon?: SemanticICONS | undefined
	onClick?: () => void
}

const MenuItem = (props: MenuItemProps): JSX.Element => {
	const [hovered, setHovered] = useState(false)
	const { className, label, icon, activeColor, baseColor, active, disabled, title, hide, secondaryIcon, onClick } = props
	if (!hide) {
		return (
			<div
				className={`${className ? className + "-" : ""}nav-button ${disabled ? "disabled" : ""}`}
				onMouseEnter={() => disabled || setHovered(true)}
				onMouseLeave={() => disabled || setHovered(false)}
				style={{ opacity: disabled ? 0.7 : 1 }}
				title={title}
			>
				<Menu.Item {...props} onClick={onClick} active={active}>
					{secondaryIcon ? (
						<Icon.Group size="big">
							<Icon name={icon} style={{ color: !disabled && (active || hovered) ? activeColor : baseColor }} />

							<Icon
								corner
								name={secondaryIcon}
								color="green"
								style={{ color: !disabled && (active || hovered) ? activeColor : baseColor }}
							/>
						</Icon.Group>
					) : (
						<Icon name={icon} style={{ color: !disabled && (active || hovered) ? activeColor : baseColor }} />
					)}
					{label && (
						<div style={{ color: !disabled && (active || hovered) ? activeColor : baseColor, fontSize: "8px" }}>{label}</div>
					)}
				</Menu.Item>
			</div>
		)
	} else {
		return <></>
	}
}

const RemoteHQSidebar = (props: RemoteHQSidebarProps): JSX.Element => {
	const [sidebar, setSidebar] = useState(0)
	const [hasDebugPrivileges, setHasDebugPrivileges] = useState(false)
	const [block, setBlock] = useState(null)
	const [reset, setReset] = useState(false)
	const [hasReportPrivileges, setHasReportPrivileges] = useState(false)
	const [hasNesPFFormPrivileges, setHasNesPFFormPrivileges] = useState(false)
	const [hasOwnershipPrivileges, setHasOwnershipPrivileges] = useState(false)
	const [hasTrainingPrivileges, setHasTrainingPrivileges] = useState(false)
	const [modelViewerVisible, setModelViewerVisible] = useState(false)
	const [uiConfig, setUiConfig] = useState({ ...getUIConfig("clientUI"), ...getUIConfig("navBar") })

	const setSidebarHandler = (i: number): void => {
		setSidebar((n) => (n === i ? 0 : i))
	}

	const mapClicked = (type: string, key: string, data: any): void => {
		if (type === "state" && key === "selected") {
			sidebar !== 0 ? setSidebar(0) : null
		}
	}

	const userChanged = (type: string, key: string, data: any): void => {
		if (type === "user-profile" && data) {
			const debugPrivileges = checkLoadedAuthority(actions.create, objects.debug)
			const nesPFFormPrivileges = checkLoadedAuthority(actions.create, objects.nesPFForm)
			const ownershipPrivileges = checkLoadedAuthority(actions.create, objects.forestOwnership)
			const trainingPrivileges = checkLoadedAuthority(actions.create, objects.training)
			printData(hasDebugPrivileges, messageLevels.debug, "hasDebugPrivileges")
			printData(data, messageLevels.debug, "user-profile")
			setHasDebugPrivileges(debugPrivileges)
			setHasNesPFFormPrivileges(nesPFFormPrivileges)
			setHasOwnershipPrivileges(ownershipPrivileges)
			setHasTrainingPrivileges(trainingPrivileges)
		}
		if (key === "loginState") {
			setSidebarHandler(0)
		}
		if (key === "selected") {
			const theItems = data.getValue()
			let theItem = theItems
			if (Array.isArray(theItems) && theItems.length > 0) {
				theItem = theItems[0]
			}
			if (isItemType(theItem, "forest-blocks")) {
				const reportPrivileges = checkLoadedAuthority(actions.create, objects.forestBlockReport, null, theItem.getRegionISO())
				setBlock(theItem)
				setHasReportPrivileges(reportPrivileges)
			}
			if (isItemType(theItem, "catchment", true)) {
				setSidebarHandler(0)
			}
		}
	}

	const renderContent = (): JSX.Element => {
		const { isCapturing } = props
		if (reset) return <></>
		return (
			<>
				<ModelViewer isOpen={modelViewerVisible} setOpen={setModelViewerVisible} />
				<SearchLayer visible={sidebar === 1} onSelect={(): void => setSidebarHandler(0)} />
				<Layer visible={sidebar === 2} />
				<NoticeLayer visible={sidebar === 3} />
				<DashboardLayer visible={sidebar === 4} />
				<DataToolbox visible={sidebar === 9} />
				<Report visible={sidebar === 7 && !isCapturing} />
				{hasOwnershipPrivileges ? <OwnershipSearch visible={sidebar === 10} /> : null}
			</>
		)
	}

	const homeButtonAction = (): void => {
		setSidebarHandler(0)
		printMessage("Home pressed", messageLevels.debug)
		call("goToHome", null)
		call("changeDimension", "2D")
	}

	useEffect(() => {
		registerforStateChanges(userChanged)
		registerforStateChanges(mapClicked)
		registerOnChange("user-profile", userChanged)

		return (): void => {
			removeStateChanges(userChanged)
			removeStateChanges(mapClicked)
			removeOnChange("user-profile", userChanged)
		}
	}, [])

	const { brandColor, className, fontColor, backgroundColor } = uiConfig
	const { children } = props
	const isLoggedIn = getGlobalState("loginState")?.getValue("isLoggedIn") || false
	return (
		<Sidebar.Pushable id="nav">
			<Sidebar as={Menu} animation="overlay" icon="labeled" visible={true} vertical width="very thin">
				<MenuItem
					className={className}
					onClick={(): void => homeButtonAction()}
					icon="home"
					active={sidebar === 0}
					activeColor={brandColor}
					baseColor={fontColor}
					title="Go to home"
				/>
				<MenuItem
					className={className}
					onClick={(): void => setSidebarHandler(1)}
					icon="search"
					active={sidebar === 1}
					activeColor={brandColor}
					baseColor={fontColor}
					title="Search for a forest block"
				/>
				<MenuItem
					hide={!hasOwnershipPrivileges}
					className={className}
					onClick={() => setSidebarHandler(10)}
					icon="tree"
					active={sidebar === 10}
					activeColor={brandColor}
					baseColor={fontColor}
					title="Search for a forest"
				/>
				<MenuItem
					className={className}
					onClick={(): void => setSidebarHandler(4)}
					icon="chart bar"
					active={sidebar === 4}
					activeColor={brandColor}
					baseColor={fontColor}
					title="View dashboard"
				/>
				<MenuItem
					className={className}
					disabled={!isLoggedIn || !block || !hasReportPrivileges}
					onClick={(): void => setSidebarHandler(7)}
					icon="clipboard check"
					active={sidebar === 7}
					activeColor={brandColor}
					baseColor={fontColor}
					title="Please login and select a block"
				/>
				<MenuItem
					className={className}
					disabled={true}
					hide={!hasNesPFFormPrivileges}
					onClick={(): void => setSidebarHandler(3)}
					icon="file alternate"
					active={sidebar === 3}
					activeColor={brandColor}
					baseColor={fontColor}
					title="Notifications"
				/>
				<MenuItem
					className={className}
					hide={!hasTrainingPrivileges}
					onClick={(): void => setModelViewerVisible((bool) => !bool)}
					icon="users"
					active={sidebar === 6}
					activeColor={brandColor}
					baseColor={fontColor}
					title="Training and User reference guides"
				/>
				<MenuItem
					className={className}
					hide={!hasDebugPrivileges}
					onClick={(): void => setSidebarHandler(9)}
					icon="setting"
					active={sidebar === 9}
					activeColor={brandColor}
					baseColor={fontColor}
					title="Settings"
				/>
			</Sidebar>
			<Sidebar.Pusher className="map-container">
				<Sidebar.Pushable>
					{renderContent()}
					{children}
				</Sidebar.Pushable>
			</Sidebar.Pusher>
		</Sidebar.Pushable>
	)
}

export default RemoteHQSidebar
