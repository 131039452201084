import { forestBlock } from "../classes/forestBlock"
import { blockFeature } from "../classes/blockFeature"
import { getStateFirstValue, isStoredItem } from ".."
import { storedItem } from "../storedItem"

export function selectedForestBlock(): forestBlock | null {
	const theSelected: forestBlock | blockFeature | null = getStateFirstValue("selected")
	if (!theSelected) return null
	const theBlock = theSelected.forestblock
	if (theBlock && isStoredItem(forestBlock)) {
		return theBlock
	}
	return null
}

export function keyNotSet(item: storedItem, key: string) {
	const theValue = item.getValue(key)
	if (!theValue || theValue === undefined || theValue === "") {
		return true
	}
	return false
}

export const timeZone = "Pacific/Auckland"
