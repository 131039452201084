import { call, registerforStateChanges } from "../../store"
import { printData, printMessage } from "../../providers/remoteHQ"
import { storedItem } from "../../store/storedItem"
import { messageLevels } from "../types"
import { mapSource } from "./mapSource"
import { dataFeed, getDataFeedByName } from "../dataFeed"
import { primaryKeys } from "../../mapsight/FeedListComponent"

const emptyStoredArray: storedItem[] = []
const emptyGeoJSON = { type: "FeatureCollection", features: [] }

export class mapDynamicTileSource extends mapSource {
	get tilesURLs(): string[] {
		return (this as any)._tiles
	}

	mounted() {
		super.mounted()
		;(this as any).lastItem = null
		;(this as any).lastValue = null
		this.registerForChanges()
	}
	registerForChanges() {
		// printMessage("Registering for changes in " + this.getValue("id"), messageLevels.verbose);
		registerforStateChanges(this.stateChanged)
	}
	stateChanged = (type: string, key?: string, data?: storedItem) => {
		printMessage("key: " + key, messageLevels.debug)
		// printData( data ,messageLevels.debug)
		this.stateChangeHandler(key, data)
	}

	stateChangeHandler = (key?: string, data?: storedItem) => {
		if (key === "selected") {
			// if(data !== (this as any)._selectedItem ){

			;(this as any)._selectedItem = data?.getValue()[0] || null
			if ((this as any).lastItem !== (this as any)._selectedItem) {
				;(this as any).lastItem = (this as any)._selectedItem

				if ((this as any)._selectedItem) {
					const theRelationship = this.getValue("sourceRelationship")
					const theValue = (this as any)._selectedItem.getValue(theRelationship)
					if (theValue) {
						const theURL = this.getValue("tilesTemplate")

						;(this as any).lastValue = theValue

						const theTiles: string[] = []
						const theTemplates = this.getValue("tilesTemplate") as string[]

						theTemplates.forEach((url) => {
							const theURL = url?.toString().replace("$value$", theValue)
							printData(theURL, messageLevels.verbose)
							theTiles.push(theURL)
						})
						;(this as any)._tiles = theTiles
						this.removeSource()
						this.addSource()
						this.addLayers()
					} else {
						this.removeSource()
					}
				}
			}
			// }
		}
	}
	removeSource() {
		this.removeLayers()
		call("removeSource", this.getValue("id"))
	}
	addSource() {
		call("addSource", this)
	}
	removeLayers() {
		const theLayers = this.layers
		theLayers.forEach((mapLayer) => {
			mapLayer.removeFromMap()
		})
	}
	addLayers() {
		const theLayers = this.layers
		theLayers.forEach((mapLayer) => {
			mapLayer.addToMap()
		})
	}
	// prepareTileURL = () => {
	// 	printMessage("prepareTileURL in mapDynamicTileSource " + this.primaryKey(), messageLevels.debug);

	// 	// get the config for properties

	// 	this.updateMapURL(theFinalData);
	// 	this.dropOldSourceID();
	// 	this.addNewSourceID();
	// 	this.updateRelatedLayers();
	// }
}

function compareArrays(arr1: string[], arr2: string[]) {
	if (arr1.length !== arr2.length) {
		return false
	}
	for (let x = 0; x < arr1.length; x++) {
		if (arr1[x] !== arr2[x]) {
			return false
		}
	}
	return true
}
