import React from "react"
import { Divider, Dropdown, DropdownItemProps, Grid, Segment, Sidebar } from "semantic-ui-react"

import Statistic from "./Statistic"
import {
	call,
	getStoreTypeByName,
	isStoredItem,
	itemByKey,
	registerforStateChanges,
	removeOnRetrieve,
	removeStateChanges,
	requestLoad,
} from "../../../store"
import { messageLevels } from "../../../store//types"
import { storedItem } from "../../../store/storedItem"

import "./style.css"

import { printData, printMessage } from "../../../providers/remoteHQ"
import { region } from "../../../store/classes/region"
import CouncilLogo from "../../../components/CouncilLogo"
import Select from "../../../components/Select"

interface DashboardLayerProps {
	visible: boolean
}

interface DashboardLayerState {
	selectedItems: storedItem[]
	regions: DropdownItemProps
	selectedRegions: string[]
}

// interface IRegionName {
// 		key: string,
// 		text: string,
// 		value: string,
// }

const emptyStoredArray: storedItem[] = []
// const emptyItemDict: IManagedItemDict = {};

export default class DashboardLayer extends React.Component<DashboardLayerProps, DashboardLayerState> {
	state = {
		selectedItems: [],
		regions: [],
		selectedRegions: [],
	}

	data = emptyStoredArray

	stateChanged = (type: string, key: string, data: any) => {
		// If a feature has been selected:
		if (key === "selected") {
			const newlySelected = data.getValue()

			if (Array.isArray(newlySelected) && newlySelected.length) {
				const selectedRegions: string[] = []
				newlySelected.forEach((item) => {
					if (isStoredItem(item)) {
						if (!selectedRegions.includes(item.getRegionISO())) {
							selectedRegions.push(item.getRegionISO())
						}
					}
				})
				this.setState({
					selectedRegions: selectedRegions,
				})
			} else {
				this.setState({
					selectedRegions: [],
				})
			}
		}
	}

	getData() {
		const theType = getStoreTypeByName("boundaries", false)

		if (theType && theType !== undefined) {
			const theData = theType.allMatchingItems({
				queries: ["levelName === region"],
				sort: {
					sortField: "english_name",
				},
			})

			const regionNames: DropdownItemProps = theData.map((element, index) => ({
				key: index,
				text: element.displayName(),
				value: element.primaryKey().toString(),
			}))

			this.setState({ regions: regionNames })
		}
	}

	dataLoaded = (type: string) => {
		if (type === "boundaries/retrieve") {
			this.getData()
		}
	}

	componentDidMount() {
		requestLoad("boundaries", undefined, this.dataLoaded)

		registerforStateChanges(this.stateChanged)

		requestLoad("dashboard-metrics", undefined, this.dataLoaded)
	}

	componentWillUnmount() {
		removeStateChanges(this.stateChanged)
		removeOnRetrieve("boundaries", this.dataLoaded)
		removeOnRetrieve("dashboard-metrics", this.dataLoaded)
	}

	handleChangeRegion = (e: any, { value }: any) => {
		call("setLevelByItem", itemByKey("boundaries", value))
	}

	renderSelectRegion = () => {
		let selected = ""
		if (this.state.selectedRegions.length > 0) {
			selected = this.state.selectedRegions[0]
		}

		return (
			<Grid.Row>
				<h2 style={{ color: "white" }}> To open the dashboard, select a region.</h2>

				<Select placeholder="Region" selection options={this.state.regions} value={selected} onChange={this.handleChangeRegion} />

				{/* {this.state.isError && (
					<h3 style={{ color: "red" }}>Please select a region</h3>
				)} */}
				<p style={{ color: "white", paddingTop: "50px" }}> *You can select a region from the map or the dropdown list above.</p>
			</Grid.Row>
		)
	}

	renderRegions = () => {
		if (this.state.selectedRegions.length > 0) {
			return this.state.selectedRegions.map((iso, i) => {
				printMessage("ISO: " + iso, messageLevels.debug)
				const theRegion: storedItem | undefined = itemByKey("boundaries", iso)
				printData(theRegion, messageLevels.debug, "theRegion")
				if (theRegion && theRegion !== undefined) {
					const stats = (theRegion as region).statistics
					if (stats && stats !== undefined) {
						return this.renderRegion(theRegion as region, i)
					}
				}
				return null
			})
		}
	}

	renderRegion = (boundary: region, i: number) => {
		const statistics = boundary.statistics
		if (statistics === undefined || statistics === null) {
			printData(boundary, messageLevels.debug, "in renderRegion")
			printData(boundary.statistics, messageLevels.debug, "in renderRegion")
			return null
		} else {
			const theAuthority = boundary.authorityItem
			let logoURL = ""
			if (theAuthority && theAuthority !== undefined) {
				const partialURL = theAuthority?.getValue("logo_url")
				if (typeof partialURL === "string") {
					logoURL = process.env.PUBLIC_URL + theAuthority?.getValue("logo_url").split("public")[1]
				}
			}

			return (
				<React.Fragment key={i}>
					<CouncilLogo src={logoURL} />
					<Grid.Row></Grid.Row> {/* for set the devider */}
					<Divider inverted horizontal>
						Regional Compliance
					</Divider>
					<Grid.Row>
						<Statistic name="notification" value={Math.floor(statistics?.getValue("forest_block_count"))} />
						<Statistic name="notAssessed" value={Math.floor(statistics?.getValue("NA_count"))} />
					</Grid.Row>
					<Grid.Row>
						<Statistic name="compliant" value={Math.floor(statistics.getValue("C_count"))} />
						<Statistic name="nonCompliant" value={Math.floor(statistics.getValue("NC_count"))} />
					</Grid.Row>
					<Grid.Row>
						<Statistic name="significantlyNonCompliant" value={Math.floor(statistics.getValue("SNC_count"))} />
						<Statistic name="technicallyNonCompliant" value={Math.floor(statistics.getValue("TNC_count"))} />
					</Grid.Row>
					<Grid.Row>
						<Statistic name="remedialWorksRequired" value={Math.floor(statistics.getValue("RWR_count"))} />
					</Grid.Row>
					<Divider inverted horizontal>
						Industry Performance
					</Divider>
					<Grid.Row>
						<Statistic name="areaOfExcellence" value={Math.floor(statistics.getValue("aoe_count"))} />
						<Statistic name="aoc" value={Math.floor(statistics.getValue("aoc_count"))} />
					</Grid.Row>
					<Grid.Row>
						<Statistic name="aof" value={Math.floor(statistics.getValue("aof_count"))} />
					</Grid.Row>
					<Divider inverted horizontal>
						Earthworks
					</Divider>
					<Grid.Row>
						<Statistic name="roadsKms" value={Math.floor(statistics.getValue("as_built_road_total_km"))} />
						<Statistic name="tracksKms" value={Math.floor(statistics.getValue("as_built_track_total_km"))} />
					</Grid.Row>
				</React.Fragment>
			)
		}
	}

	render() {
		return (
			<Sidebar id="dashboard" animation="overlay" icon="labeled" width="very wide" visible={this.props.visible}>
				<Segment inverted={true} basic={true}>
					<Grid columns={2}>
						{this.state.selectedRegions.length > 0 && this.state.selectedRegions[0] !== "NZL"
							? this.renderRegions()
							: this.renderSelectRegion()}
					</Grid>
				</Segment>
			</Sidebar>
		)
	}
}
