import React from "react"
import { Form, FormSelectProps } from "semantic-ui-react"

import "./styles.css"

const Select = (props: FormSelectProps) => {
	return <Form.Select data-testid="custom-select" className="select-dropdown" {...props} />
}

export default Select
