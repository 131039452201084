import React, { useEffect, useRef } from "react"
import { Input, InputProps, Segment } from "semantic-ui-react"

import "./styles.css"

const SearchBar = ({ active, ...props }: InputProps) => {
	const input = useRef<any>()

	useEffect(() => {
		active && input.current.focus()
	}, [active])

	return (
		<Segment basic className="search-bar">
			<Input ref={input} fluid icon="search" placeholder="Search..." {...props} />
		</Segment>
	)
}

export default SearchBar
