import React from "react"

import { ImageListType } from "react-images-uploading"

import "react-datepicker/dist/react-datepicker.css"

import { storedItem } from "../../../storedItem"
import { printMessage, printData } from "../../../../providers/remoteHQ"
import { messageLevels, ImageSupport } from "../../../types"
import { FormFieldProperties } from "../.."
import { ImageUpright } from "./ImageUpright"
import ItemContext from "../../ItemContext"
import { Text, View } from "@react-pdf/renderer"

export interface PDFImageListItemProps {
	item?: storedItem | null
	field?: FormFieldProperties
	container?: any
	hideHeading?: boolean
	setValue?: any
	setDirty?: any
	changed?: boolean
	maxImages?: number
}
export interface PDFImageListItemState {
	typeName?: string
	item?: storedItem
	changeCount: number
	listItems: any[]
	images: any[]
	currentPage?: number
	isDragging?: boolean
}

export class PDFImageListItem extends React.Component<PDFImageListItemProps, PDFImageListItemState> {
	state: PDFImageListItemState = {
		changeCount: 0,
		listItems: [],
		images: [],
	}

	get imageItem(): ImageSupport {
		return this.props.item as unknown as ImageSupport
	}

	getImageList = () => {
		if (!this.props.item) return []
		return this.imageItem.getImageURLs(this.props.field?.name) || []
	}

	renderSingle = (item: storedItem, imageLink: URL, index: any) => {
		const container = this.props.container
		const styles = container?.styles

		return (
			<View
				style={[styles.row, { width: "100%", height: "22%", justifyContent: "space-evenly", marginBottom: "2mm" }]}
				key={item.primaryKey()}
			>
				<View style={{ width: "40%" }}>
					<View style={[styles.row, { fontSize: 13, justifyContent: "space-between", marginBottom: "2mm" }]}>
						<Text style={{ fontWeight: "bold" }}>{item.displayName()}</Text>
						<Text>Due: {item.getDateFormatted("due_date", "DD MMMM yyyy", "No date assigned")}</Text>
					</View>
					<Text>{item.getValue("description") || "No description available"}</Text>
				</View>
				<View style={[styles.flexCenter, { width: "50%", height: "100%", overflow: "hidden" }]}>
					{imageLink && <ImageUpright src={imageLink} style={{ height: "100%", position: "absolute" }} />}
					{/* {imageLink && 
				<Image style={{height: '100%', position: 'absolute'}} src={imageLink} />} */}
				</View>
			</View>
		)
	}

	render() {
		const { item, container } = this.props

		// const styles = container?.styles;

		if (item) {
			const imageLinks = this.getImageList()

			return (
				<>
					{imageLinks.map((image, i) => {
						this.renderSingle(item, image, i)
					})}
				</>
			)
		}
		return null
	}
}
