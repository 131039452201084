import React, { useState } from "react"

import { call, getGlobalState, instanceNewItem, setGlobalState } from "../../../store"
import { forestNotification } from "../../../store/classes/forestNotification"
import { FormFieldProperties, FormPageItemProps } from "../../../store/Forms/index"
import { messageLevels } from "../../../store/types"
import NESFormComponent from "./nes-form"
import { storedItem } from "../../../store/storedItem"
import { printData, printMessage } from "../../../providers/remoteHQ"
import ReactItemContext from "../../../store/Forms/react/ItemContext"

interface CallbackFn {
	(): any
}

export const contactFieldsLandowner: FormFieldProperties[] = [
	{ name: "", label: "Landowner", type: "heading" },
	{ name: "", label: "Clear", type: "clear" },
	{
		name: "landownerOrCreate.name",
		label: "Name",
		type: "input",
		mandatory: true,
	},
	{ name: "landownerOrCreate.company_name", label: "Company", type: "input" },
	{
		name: "landownerOrCreate.phone",
		label: "Phone Number",
		type: "number",
		mandatory: true,
		validator: "phone",
	},
	{ name: "landownerOrCreate.email", label: "Email", type: "input", mandatory: true },
	{ name: "landownerOrCreate.postal_address", label: "Postal Address", type: "input" },
	{ name: "", label: "Forest Manager", type: "heading" },
	{ name: "managerOrCreate.name", label: "Name", type: "input" },
	{ name: "managerOrCreate.company_name", label: "Management Company", type: "input" },
	{ name: "managerOrCreate.phone", label: "Phone Number", type: "phone", validator: "phone" },
	{ name: "managerOrCreate.email", label: "Email", type: "input" },
	{ name: "managerOrCreate.postal_address", label: "Postal Address", type: "input" },
	{ name: "", label: "Contractors", type: "heading" },
	{ name: "harvesting_contractor", label: "Harvesting Contractor", type: "input" },
	{ name: "earthworks_contractor", label: "Earthworks Contractor", type: "input" },
	{ name: "planting_contractor", label: "Planting Contractor", type: "input" },

	{
		name: "billerType",
		label: "Bill to",
		type: "options",
		choices: ["Landowner", "Manager", "Other"],
	},
	{
		name: "",
		label: "Billing Contact",
		type: "block",
		conditional: { queries: ["billerType == Other"] },
		subfields: [
			{
				name: "billedToOrCreate.name",
				label: "Name",
				type: "input",
				mandatory: true,
			},
			{
				name: "billedToOrCreate.company_name",
				label: "Billing Company",
				type: "input",
				mandatory: true,
			},
			{
				name: "billedToOrCreate.phone",
				label: "Phone Number",
				type: "phone",
				mandatory: true,
				validator: "phone",
			},
			{
				name: "billedToOrCreate.email",
				label: "Email",
				type: "input",
				validator: "email",
				mandatory: true,
			},
			{
				name: "billedToOrCreate.postal_address",
				label: "Postal Address",
				type: "input",
				mandatory: true,
			},
		],
	},
]
export const contactFieldsManager: FormFieldProperties[] = [
	{ name: "remember", label: "Save this information for next time", type: "checkbox" },
	{ name: "", label: "Forest Manager", type: "heading" },
	{ name: "", label: "Clear", type: "clear" },
	{
		name: "managerOrCreate.name",
		label: "Name",
		type: "input",
		mandatory: true,
	},
	{ name: "managerOrCreate.company_name", label: "Management Company", type: "input" },
	{
		name: "managerOrCreate.phone",
		label: "Phone Number",
		type: "phone",
		mandatory: true,
		validator: "phone",
	},
	{
		name: "managerOrCreate.email",
		label: "Email",
		type: "input",
		mandatory: true,
		validator: "email",
	},
	{ name: "managerOrCreate.postal_address", label: "Postal Address", type: "input" },
	{ name: "", label: "Landowner", type: "heading" },
	{ name: "landownerOrCreate.name", label: "Name", type: "input" },
	{ name: "landownerOrCreate.company_name", label: "Company", type: "input" },
	{ name: "landownerOrCreate.phone", label: "Phone Number", type: "phone", validator: "number" },
	{ name: "landownerOrCreate.email", label: "Email", type: "input", validator: "email" },
	{ name: "landownerOrCreate.postal_address", label: "Postal Address", type: "input" },
	{ name: "", label: "Contractors", type: "heading" },
	{ name: "harvesting_contractor", label: "Harvesting Contractor", type: "input" },
	{ name: "earthworks_contractor", label: "Earthworks Contractor", type: "input" },
	{ name: "planting_contractor", label: "Planting Contractor", type: "input" },

	{
		name: "billerType",
		label: "Bill to",
		type: "options",
		choices: ["Manager", "Landowner", "Other"],
	},
	{
		name: "",
		label: "Billing Contact",
		type: "block",
		conditional: { queries: ["billerType == Other"] },
		subfields: [
			{
				name: "billedToOrCreate.name",
				label: "Name",
				type: "input",
			},
			{
				name: "billedToOrCreate.company_name",
				label: "Company",
				type: "input",
			},
			{
				name: "billedToOrCreate.phone",
				label: "Phone Number",
				type: "phone",
				validator: "phone",
			},
			{
				name: "billedToOrCreate.email",
				label: "Email",
				type: "input",
				validator: "email",
			},
			{
				name: "billedToOrCreate.postal_address",
				label: "Postal Address",
				type: "input",
			},
		],
	},
]
export const locationFields: FormFieldProperties[] = [
	{ name: "", label: "Forest Location", type: "heading" },
	{ name: "geom", label: "Forest Location", type: "locationPicker", mandatory: true },
	{
		name: "region",
		label: "Council: ",
		type: "heading",
		customGetHeader: (item: storedItem, field: FormFieldProperties) => {
			return getGlobalState("selected")?.getValue()?.[0].displayName()
		},
	},
	{ name: "forest_name", label: "Forest Name", type: "input", mandatory: true },
	{ name: "legal_description", label: "Legal Description", type: "input" },
	{ name: "physical_address", label: "Physical Address", type: "input", mandatory: true },
	{
		name: "council_identifier",
		label: "Unique Council identifier",
		type: "input",
		mandatory: true,
	},
	{ name: "", label: "Erosion Susceptibility Zones", type: "heading" },
	{ name: "erosion_susceptibility_zones[green]", label: "Green", type: "checkbox" },
	{ name: "erosion_susceptibility_zones[yellow]", label: "Yellow", type: "checkbox" },
	{ name: "erosion_susceptibility_zones[orange]", label: "Orange", type: "checkbox" },
	{ name: "erosion_susceptibility_zones[red]", label: "Red", type: "checkbox" },
]
export const activitiesFields: FormFieldProperties[] = [
	{
		name: "forestActivities",
		label: "Forestry Activities",
		type: "block",
		mandatory: "some",
		subfields: [
			{
				name: "activities{Harvesting}",
				type: "checkboxDateRange",
				label: "Harvesting",
				tooltip: "NES-CF Regulations 62 - 71",
				validator: "checkboxDateRange",
			},
			{
				name: "activities{Earthworks}",
				type: "checkboxDateRange",
				label: "Earthworks",
				tooltip: "NES-CF Regulations 22 - 35",
				validator: "checkboxDateRange",
			},
			{
				name: "activities{Afforestation}",
				type: "checkboxDateRange",
				label: "Afforestation",
				tooltip: "NES-CF Regulations 8 - 17",
				validator: "checkboxDateRange",
			},
			{
				name: "activities{RiverCrossing}",
				type: "checkboxDateRange",
				label: "River Crossings",
				tooltip: "NES-CF Regulations 36 - 49",
			},
			{
				name: "activities{Quarrying}",
				type: "checkboxDateRange",
				label: "Forestry Quarrying",
				validator: "checkboxDateRange",
				tooltip: "NES-CF Regulations 50 - 61",
			},
			{
				name: "activities{Pruning}",
				type: "checkboxDateRange",
				label: "Pruning and Thinning to Waste",
				validator: "checkboxDateRange",
				tooltip: "NES-CF Regulations 18 - 21",
			},
			{
				name: "activities{MechanicalPreparation}",
				type: "checkboxDateRange",
				label: "Mechanical Land Preperation",
				tooltip: "NES-CF Regulations 72 - 75",
				validator: "checkboxDateRange",
			},
			{
				name: "activities{Replanting}",
				type: "checkboxDateRange",
				label: "Replanting",
				validator: "checkboxDateRange",
				tooltip: "NES-CF Regulations 76 - 81",
			},
			{
				name: "activities{AncillaryActivities}",
				type: "checkboxDateRange",
				label: "Ancillary Activities",
				tooltip: "NES-CF Regulations 82 - 95",
				validator: "checkboxDateRange",
				description: "e.g. Slash Traps",
			},
			{
				name: "activities{GeneralActivities}",
				type: "checkboxDateRange",
				label: "General Activities",
				tooltip: "NES-CF Regulations 96 - 105",
				validator: "checkboxDateRange",
				description: "e.g. Discharges, Fuel Storage, Refuelling",
			},
		],
	},
	{ name: "notes", label: "Additional notes", type: "text" },
]

export const uploadSupportingDocuments: FormFieldProperties[] = [
	{
		name: "",
		label: "Supporting Media, Files and Documents",
		type: "heading",
		description: "e.g. Harvest Plans and Earthworks Management Plans",
	},
	{
		name: "image_links",
		label: "Supporting Documents",
		type: "uploadFile",
		style: {
			minHeight: "calc(100vh - 330px)",
			justifyContent: "center",
		},
	},
]

export const confirmationFieldsLandowner: FormFieldProperties[] = [
	{ name: "Contact Person (Landowner)", label: " ", type: "heading" },
	{ name: "landownerOrCreate.name", label: "Name", type: "display" },
	{ name: "landownerOrCreate.company_name", label: "Company", type: "display" },
	{ name: "landownerOrCreate.phone", label: "Phone Number", type: "display" },
	{ name: "landownerOrCreate.email", label: "Email", type: "display" },
	{ name: "landownerOrCreate.postal_address", label: "Postal Address", type: "display" },
	{ name: "", label: "Manager", type: "heading" },
	{ name: "managerOrCreate.name", label: "Management Company Contact", type: "display" },
	{ name: "managerOrCreate.company_name", label: "Management Company", type: "display" },
	{ name: "managerOrCreate.phone", label: "Phone Number", type: "display" },
	{ name: "managerOrCreate.email", label: "Email", type: "display" },
	{ name: "managerOrCreate.postal_address", label: "Postal Address", type: "display" },
	{ name: "", label: "Contractors", type: "heading" },
	{ name: "harvesting_contractor", label: "Harvesting Contractor", type: "display" },
	{ name: "earthworks_contractor", label: "Earthworks Contractor", type: "display" },
	{ name: "planting_contractor", label: "Planting Contractor", type: "display" },
	{ name: "", label: "Billing Contact", type: "heading" },
	{ name: "billerType", label: "Bill to", type: "display" },
	{ name: "billedToOrCreate.name", label: "Billing Contact", type: "display" },
	{ name: "billedToOrCreate.company_name", label: "Billing Company", type: "display" },
	{ name: "billedToOrCreate.phone", label: "Phone Number", type: "display" },
	{ name: "billedToOrCreate.email", label: "Email", type: "display" },
	{ name: "billedToOrCreate.postal_address", label: "Postal Address", type: "display" },
]
export const confirmationFieldsManager: FormFieldProperties[] = [
	{ name: "", label: "Contact Person (Manager)", type: "heading" },
	{ name: "managerOrCreate.name", label: "Management Company Contact", type: "display" },
	{ name: "managerOrCreate.company_name", label: "Management Company", type: "display" },
	{ name: "managerOrCreate.phone", label: "Phone Number", type: "display" },
	{ name: "managerOrCreate.email", label: "Email", type: "display" },
	{ name: "managerOrCreate.postal_address", label: "Postal Address", type: "display" },
	{ name: "Landowner", label: " ", type: "heading" },
	{ name: "landownerOrCreate.name", label: "Name", type: "display" },
	{ name: "landownerOrCreate.company_name", label: "Company", type: "display" },
	{ name: "landownerOrCreate.phone", label: "Phone Number", type: "display" },
	{ name: "landownerOrCreate.email", label: "Email", type: "display" },
	{ name: "landownerOrCreate.postal_address", label: "Postal Address", type: "display" },
	{ name: "", label: "Contractors", type: "heading" },
	{ name: "harvesting_contractor", label: "Harvesting Contractor", type: "display" },
	{ name: "earthworks_contractor", label: "Earthworks Contractor", type: "display" },
	{ name: "planting_contractor", label: "Planting Contractor", type: "display" },
	{ name: "", label: "Billing Contact", type: "heading" },
	{ name: "billerType", label: "Bill to", type: "display" },
	{ name: "billedToOrCreate.name", label: "Billing Contact", type: "display" },
	{ name: "billedToOrCreate.company_name", label: "Billing Company", type: "display" },
	{ name: "billedToOrCreate.phone", label: "Phone Number", type: "display" },
	{ name: "billedToOrCreate.email", label: "Email", type: "display" },
	{ name: "billedToOrCreate.postal_address", label: "Postal Address", type: "display" },
]

const contactPageLandowner: FormPageItemProps = {
	title: "contact",
	fields: contactFieldsLandowner,
	showInDisplay: false,
	validIcon: "check",
	invalidIcon: "exclamation",
	remember: true,
}

const contactPageManager: FormPageItemProps = {
	title: "contact",
	fields: contactFieldsManager,
	validIcon: "check",
	invalidIcon: "exclamation",
	remember: true,
}
const locationPage: FormPageItemProps = {
	title: "location",
	fields: locationFields,
	validIcon: "check",
	invalidIcon: "exclamation",
}
const activitiesPage: FormPageItemProps = {
	title: "activities",
	fields: activitiesFields,
	validIcon: "check",
	invalidIcon: "exclamation",
}

const supportingDocumentsPage: FormPageItemProps = {
	title: "documents",
	fields: uploadSupportingDocuments,
}

const confirmationPageLandowner: FormPageItemProps = {
	title: "confirmation",
	fields: confirmationFieldsLandowner,
}
const confirmationPageManager: FormPageItemProps = {
	title: "confirmation",
	fields: confirmationFieldsManager,
}

const donePage: FormPageItemProps = {
	title: "Complete",
	description: "",
	fields: [
		{
			name: "",
			label: "",
			type: "icon",
			color: "green",
			size: "large",
			icon: "check circle",
			style: {
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				width: "100%",
				fontSize: "100px",
				margin: "50px 0",
			},
		},
		{
			name: "",
			label: "Thank you! ",
			type: "heading",
			style: { textAlign: "center" },
		},
		{
			name: "",
			label: "Within the next couple of minutes, you will receive a confirmation email. This email confirms that the submission was successfully received by Marlborough District Council.",
			type: "heading",
			style: { textAlign: "left", fontSize: "13px" },
		},
		{
			name: "",
			label: "Please keep a copy of the confirmation email and contact Marlborough District Council if an email is not received. We appreciate your cooperation!",
			type: "heading",
			style: { textAlign: "left", fontSize: "13px" },
		},
		{
			name: "",
			label: "If you have any questions, feel free to reach out to Council's Compliance team. Have a great day!",
			type: "heading",
			style: { textAlign: "left", fontSize: "13px" },
		},
		{ name: "", label: "Ok", type: "cancel", style: { display: "flex", margin: "20px auto" } },
	],
	hidden: true,
	type: "submit",
}

const formPagesLandowner: FormPageItemProps[] = [
	contactPageLandowner,
	locationPage,
	activitiesPage,
	supportingDocumentsPage,
	confirmationPageLandowner,
	donePage,
]
const formPagesManager: FormPageItemProps[] = [
	contactPageManager,
	locationPage,
	activitiesPage,
	supportingDocumentsPage,
	confirmationPageManager,
	donePage,
]

interface INotifyFormComponentProps {
	hideSidebar?: CallbackFn
	onSubmit?: any
	onCancel?: any
}

const NotifyFormComponent: React.FC<INotifyFormComponentProps> = () => {
	const [isNEScomplete, setIsNEScomplete] = useState(false)
	const [formPages, setFormPages] = useState(formPagesLandowner)
	const [notification, setNotification] = useState<storedItem | null>(null)

	const handleNextClick = (value: any, selectedRegion: string, userType: string) => {
		if (value) {
			const newItem: forestNotification | null = instanceNewItem("forest-notification") as unknown as forestNotification
			if (newItem) {
				newItem.setValue(selectedRegion, "iso")
				printData(selectedRegion, messageLevels.debug)
				printData(userType, messageLevels.debug)
				if (userType.toLowerCase() === "landowner") {
					newItem.landownerIsBiller = true
					newItem.setValue("landowner", "submitted_by")
					newItem._createValidation(contactFieldsLandowner, locationFields, activitiesFields)
					setFormPages(formPagesLandowner)
				} else {
					newItem.managerIsBiller = true
					newItem.setValue("manager", "submitted_by")
					setFormPages(formPagesManager)
					newItem._createValidation(contactFieldsManager, locationFields, activitiesFields)
				}
				printData(newItem.getValue("billerType"), messageLevels.debug, "Biller Type")

				setNotification(newItem)
				setGlobalState("NES-CF-Edit_Item", newItem)
			}
			setIsNEScomplete(true)
		}
	}

	const onSubmit = () => {
		printMessage("onSubmit in ", messageLevels.verbose)
	}

	const onCancel = () => {
		printMessage("onCancel in ", messageLevels.verbose)
		setIsNEScomplete(false)
		call("goToHome", null)
	}

	return (
		<>
			{isNEScomplete ? (
				<ReactItemContext
					item={notification}
					container={null}
					title="Submit Notice of a Commercial Forestry Activity under the NES-CF"
					pages={formPages}
					onSubmit={onSubmit}
					onCancel={onCancel}
				></ReactItemContext>
			) : (
				<NESFormComponent onSubmit={onSubmit} onCancel={onCancel} nextClickFunction={handleNextClick}></NESFormComponent>
			)}
		</>
	)
}

export default NotifyFormComponent
