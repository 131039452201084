import { getStoreTypeByName, registerforStateChanges } from "."
import { printData, printMessage } from "../providers/remoteHQ"
import { parseQueries, storedItem } from "./storedItem"
import { storeType } from "./storeType"
import { IParsedQuery, IQueryParams, messageLevels } from "./types"
import { IStats, listStats, listValues, sortItemArray } from "./helpers/ListOperations"
import { EntryList } from "./helpers/EntryList"
import { compareArrays, dataFeed } from "./dataFeed"

interface IDataFeedCallback {
	callback: Function
	queryParams: IQueryParams
}

interface IDataSource {
	name: string
	onChangeClients: Array<IDataFeedCallback>
	dataSourceName: string
	sourceQueryParams: IQueryParams
	variableQueryParams: IQueryParams

	dataSource: storeType | null
	isLoaded: boolean
}

export interface IFeedStorageArray {
	[key: string]: dataFeed
}

interface IStatsArray {
	[key: string]: IStats
}
interface IEntryLists {
	[key: string]: EntryList
}

const dataFeeds: IFeedStorageArray = {}
const emptyStatsArray: IStatsArray = {}

export class dataFeedAll extends dataFeed {
	dataLoaded = (type: string, key?: string) => {
		if (type === this.dataSourceName + "/retrieve" && this.dataSource) {
			if (this.isLoaded === true || !key || key === "") {
				this.readDataFromSource()
			}
		}
	}

	readDataFromSource() {
		if (this.dataSource) {
			const newData = this.dataSource.allMatchingItems(this.sourceQueryParams)

			if (!compareArrays(this.incomingData, newData)) {
				this.incomingData = newData
				this.clearIncomingStats()
				this.clearIncomingValues()
				this.doInteractiveFilter()
			}
			this.isLoaded = true
		}
	}

	stateChanged = (type: string, key?: string) => {
		// if ((key === "level" || key === "regions") && this.dataSource) {
		// 	printMessage("Regions/Level state changed in DataFeed", messageLevels.debug);
		// 	this.readDataFromSource();
		// }
	}
}

export function getDataFeedAllByName(name: string, createMissing?: boolean): dataFeed {
	let theDataFeed = dataFeeds[name]

	if (createMissing && createMissing === true && !theDataFeed) {
		theDataFeed = new dataFeedAll(name)
	}
	return theDataFeed
}
