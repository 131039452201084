import { ICompletionPeriod, IComplianceState, IIndexable, IPriority } from "./store/property-types"

const skidComplianceOptions: IIndexable<IComplianceState> = {
	NA: {
		KEY: "NA",
		definition: "Not Assessed",
		legendOrder: 4,
		color: {
			name: "blue",
			code: "#0074FF",
		},
	},
	C: {
		KEY: "C",
		definition: "Compliant",
		legendOrder: 0,
		color: {
			name: "green",
			code: "#21ba45", //'#00ff00'
		},
	},
	TNC: {
		KEY: "TNC",
		definition: "Technically Non-Compliant",
		legendOrder: 1,
		color: {
			name: "yellow",
			code: "#ffff00",
		},
	},
	NC: {
		KEY: "NC",
		definition: "Non-Compliant",
		legendOrder: 2,
		color: {
			name: "orange",
			code: "#ff8000",
		},
	},
	SNC: {
		KEY: "SNC",
		definition: "Significantly Non-Compliant",
		legendOrder: 3,
		color: {
			name: "red",
			code: "#ff0000",
		},
	},
	RWR: {
		KEY: "RWR",
		definition: "Remedial Works Required",
		legendOrder: 4,
		color: {
			name: "purple",
			code: "#904EE3",
		},
	},
}

const skidSiteQuestions: IIndexable = {
	Constructed_To_Plan: "Constructed and maintained as per management plan or AEE?",
	Birdsnest_Slash_Stable: "Birdsnest and slash stable and contained?",
	Batters_Stable: "Batters, cuts and side cast stable?",
	Adequate_Water_Controls: "Adequate water controls installed and maintained?",
	Adequate_Erosion_Controls: "Adequate erosion and sediment controls installed and maintained?",
	Exposed_Soil_Stabalised: "Where sediment may enter water, is exposed soil stabilised?",
	Ephemeral_Erosion_Avoided:
		"Where soil disturbance is in an ephemeral flow path, is accelerated erosion, obstruction and diversion of water flow avoided?",
	status: "Skid Compliant?",
}

const getComplianceStatus = (status = "") => skidComplianceOptions[status] || skidComplianceOptions.NA

export const LayerTypes = {
	ORTHOMOSAIC: "ORTHOMOSAIC",
	ELEVATION_MODEL: "ELEVATION_MODEL",
	HARVEST_AREA_BOUNDARY: "HARVEST_AREA_BOUNDARY",
	GENERAL_PANORAMAS: "GENERAL_PANORAMAS",
	SKID_SITE_GROUND_PANORAMAS: "SKID_SITE_GROUND_PANORAMAS",
	SKID_SITE_AERIAL_PANORAMAS: "SKID_SITE_AERIAL_PANORAMAS",
	TASKS: "TASKS",
	AREAS_OF_EXCELLENCE: "AREAS_OF_EXCELLENCE",
	AREAS_OF_CONCERN: "AREAS_OF_CONCERN",
	AREAS_OF_FAILURE: "AREAS_OF_FAILURE",
	QUARIES_AND_SLASH_TRAPS: "QUARIES_AND_SLASH_TRAPS",
	CONSENTED_ROADS: "CONSENTED_ROADS",
	AS_BUILT_ROADS: "AS_BUILT_ROADS",
	CONSENTED_TRACKS: "CONSENTED_TRACKS",
	AS_BUILT_TRACKS: "AS_BUILT_TRACKS",
	CONSENTED_SKID_SITES: "CONSENTED_SKID_SITES",
	AS_BUILT_SKID_SITES: "AS_BUILT_SKID_SITES",
	STREAM_CROSSINGS: "STREAM_CROSSINGS",
	WATER_CONTROLS: "WATER_CONTROLS",
}

// Refactor to variables
// const default_image_layers = new Map<string, ILayerFilter>([
//     [LayerTypes.ORTHOMOSAIC, { type: LayerTypes.ORTHOMOSAIC, checked: false, enabled: false, label: 'Orthomosaic', enableOpacity: true, opacity: 100, tooltip: 'Orthomosaic image captured by UAV' }],
//     [LayerTypes.ELEVATION_MODEL, { type: LayerTypes.ELEVATION_MODEL, checked: false, enabled: false, label: 'Elevation Model', enableOpacity: true, opacity: 100, tooltip: 'Digital Elevation Model built from the Orthomosaic imagery of a subsection of the block' }],
// ]);

// // Refactor to variables
// const default_feature_layers = new Map<string, ILayerFilter>([
//     [LayerTypes.HARVEST_AREA_BOUNDARY, { type: LayerTypes.HARVEST_AREA_BOUNDARY, checked: false, enabled: false, enableOpacity: false, label: 'Harvest Area Boundary', tooltip: "''" }],
//     [LayerTypes.GENERAL_PANORAMAS, { type: LayerTypes.GENERAL_PANORAMAS, checked: false, enabled: false, label: 'General Panoramas', enableOpacity: false, tooltip: '' }],
//     [LayerTypes.SKID_SITE_GROUND_PANORAMAS, { type: LayerTypes.SKID_SITE_GROUND_PANORAMAS, checked: false, enabled: false, enableOpacity: false, label: 'Skid site (Ground) Panoramas', tooltip: '' }],
//     [LayerTypes.SKID_SITE_AERIAL_PANORAMAS, { type: LayerTypes.SKID_SITE_AERIAL_PANORAMAS, checked: false, enabled: false, enableOpacity: false, label: 'Skid site (Aerial) Panoramas', tooltip: '' }],
// ]);

// // Refactor to variables
// const default_forest_assessments = new Map<string, ILayerFilter>([
//     [LayerTypes.TASKS, { type: LayerTypes.TASKS, checked: false, enabled: true, enableOpacity: false, label: 'Tasks', tooltip: '' }],
//     [LayerTypes.AREAS_OF_EXCELLENCE, { type: LayerTypes.AREAS_OF_EXCELLENCE, checked: false, enabled: false, enableOpacity: false, label: 'AOE (Areas of Excellence)', tooltip: '' }],
//     [LayerTypes.AREAS_OF_CONCERN, { type: LayerTypes.AREAS_OF_CONCERN, checked: false, enabled: false, enableOpacity: false, label: 'AOC (Areas of Concern)', tooltip: '' }],
//     [LayerTypes.AREAS_OF_FAILURE, { type: LayerTypes.AREAS_OF_FAILURE, checked: false, enabled: false, enableOpacity: false, label: 'AOF (Areas of Failure)', tooltip: '' }],
//     [LayerTypes.QUARIES_AND_SLASH_TRAPS, { type: LayerTypes.QUARIES_AND_SLASH_TRAPS, checked: false, enabled: false, enableOpacity: false, label: 'Quarries & Slash traps', tooltip: '' }],
//     [LayerTypes.CONSENTED_ROADS, { type: LayerTypes.CONSENTED_ROADS, checked: false, enabled: false, enableOpacity: true, label: 'Roads (Consented)', tooltip: '', opacity: 100 }],
//     [LayerTypes.AS_BUILT_ROADS, { type: LayerTypes.AS_BUILT_ROADS, checked: false, enabled: false, enableOpacity: true, label: 'Roads (As Built)', tooltip: '', opacity: 100 }],
//     [LayerTypes.CONSENTED_TRACKS, { type: LayerTypes.CONSENTED_TRACKS, checked: false, enabled: false, enableOpacity: true, label: 'Tracks (Consented)', tooltip: '', opacity: 100 }],
//     [LayerTypes.AS_BUILT_TRACKS, { type: LayerTypes.AS_BUILT_TRACKS, checked: false, enabled: false, enableOpacity: true, label: 'Tracks (As Built)', tooltip: '', opacity: 100 }],
//     [LayerTypes.CONSENTED_SKID_SITES, { type: LayerTypes.CONSENTED_SKID_SITES, checked: false, enabled: false, enableOpacity: false, label: 'Skid sites (Consented)', tooltip: '' }],
//     [LayerTypes.AS_BUILT_SKID_SITES, { type: LayerTypes.AS_BUILT_SKID_SITES, checked: false, enabled: false, enableOpacity: false, label: 'Skid sites (As Built)', tooltip: '' }],
//     [LayerTypes.STREAM_CROSSINGS, { type: LayerTypes.STREAM_CROSSINGS, checked: false, enabled: false, enableOpacity: false, label: 'Stream crossings', tooltip: '' }],
//     [LayerTypes.WATER_CONTROLS, { type: LayerTypes.WATER_CONTROLS, checked: false, enabled: false, enableOpacity: true, label: 'Water Controls', tooltip: '', opacity: 100 }],
// ]);

export const VISUALISE_BY = [
	{
		name: "Compliance",
		needAuth: false,
	},
	{
		name: "Completion",
		needAuth: false,
	},
	{
		name: "Priority",
		needAuth: true,
	},
]

const CompletionPeriods: IIndexable<ICompletionPeriod> = {
	WP: {
		KEY: "WP",
		definition: "Works Pending",
		legendOrder: 0,
		color: {
			code: "#ffffff",
		},
	},
	CO: {
		KEY: "CO",
		definition: "Current Operations",
		legendOrder: 1,
		color: {
			name: "blue",
			code: "#2185d0",
		},
	},
	Y1: {
		KEY: "Y1",
		definition: "1 year",
		legendOrder: 2,
		color: {
			name: "red",
			code: "#99000d",
		},
	},
	Y2: {
		KEY: "Y2",
		definition: "2 years",
		legendOrder: 3,
		color: {
			name: "red",
			code: "#cb181d",
		},
	},
	Y3: {
		KEY: "Y3",
		definition: "3 years",
		legendOrder: 4,
		color: {
			name: "red",
			code: "#ef3b2c",
		},
	},
	Y4: {
		KEY: "Y4",
		definition: "4 years",
		legendOrder: 5,
		color: {
			name: "red",
			code: "#fb6a4a",
		},
	},
	Y5: {
		KEY: "Y5",
		definition: "5 years",
		legendOrder: 6,
		color: {
			name: "red",
			code: "#fc9272",
		},
	},
	Y6: {
		KEY: "Y6",
		definition: "6 years",
		legendOrder: 7,
		color: {
			name: "red",
			code: "#fcbba1",
		},
	},
	Y7: {
		KEY: "Y7",
		definition: "7 years",
		legendOrder: 8,
		color: {
			name: "red",
			code: "#fee0d2",
		},
	},
	Y8: {
		KEY: "Y8",
		definition: "8 years",
		legendOrder: 9,
		color: {
			name: "red",
			code: "#fff5f0",
		},
	},
}

const Priority: IIndexable<IPriority> = {
	High: {
		KEY: "High",
		definition: "High",
		legendOrder: 0,
		color: {
			name: "red",
			code: "#ff0000",
		},
	},
	Medium: {
		KEY: "Medium",
		definition: "Medium",
		legendOrder: 1,
		color: {
			name: "yellow",
			code: "#ffff00",
		},
	},
	Low: {
		KEY: "Low",
		definition: "Low",
		legendOrder: 2,
		color: {
			name: "green",
			code: "#21ba45",
		},
	},
	NA: {
		KEY: "NA",
		definition: "None",
		legendOrder: 2,
		color: {
			name: "black",
			code: "#ffffff",
		},
	},
}

// const getPriority = (status: string = '') => Priority[status] || Priority.NA;

const { RWR, ...skidComplianceDropOptions } = skidComplianceOptions

const skidCompliancedropdownOptionsFormatted = Object.values(skidComplianceDropOptions).map((value) => ({
	key: value.KEY,
	value: value.KEY,
	text: value.definition,
	label: { color: value.color.name },
}))

export default {
	getComplianceStatus,
	LayerTypes,
	Priority,
	CompletionPeriods,
	VISUALISE_BY,
	skidComplianceOptions,
	skidSiteQuestions,
	skidCompliancedropdownOptionsFormatted,
}
// export default {
//     skidComplianceOptions,
//     skidSiteQuestions,
//     getComplianceStatus,
//     LayerTypes,
//     default_image_layers,
//     default_feature_layers,
//     default_forest_assessments,
//     VisualiseBy,
//     CompletionPeriods,
//     Priority,
//     getPriority
// }
