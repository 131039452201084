import React from "react"
import { Divider, Header, Image, Segment } from "semantic-ui-react"
import "chart.js/auto"
import { Bar, Doughnut } from "react-chartjs-2"

import { getStoreTypeByName, requestLoad, requestLoadIfExist } from "../index"
import { IManagedItemDict } from "../types"
import { BlockFeatureInfoView } from "./BlockFeature"
import { ItemViewProps } from "./Types"

export interface RegionState {
	allRegionMetrics: IManagedItemDict | null
	doughnutData: any
	barData: any
	isAuthoritiesLoaded: boolean
}

const barOptions = {
	plugins: {
		legend: {
			display: false,
		},
	},
	scales: {
		x: {
			ticks: {
				color: "rgba(255,255,255,0.7)",
			},
			grid: {
				display: false,
				drawBorder: false,
			},
		},
		y: {
			ticks: {
				color: "rgba(255,255,255,0.7)",
			},
			grid: {
				color: "rgba(255,255,255,0.3",
				drawBorder: false,
			},
		},
	},
}
const doughnutOptions = {
	plugins: {
		legend: {
			labels: {
				color: "rgba(255,255,255,0.7)",
			},
			position: "right" as "chartArea" | "left" | "top" | "right" | "bottom" | "center",
		},
	},
}

// this will map the key to the correct data in dataset
function chartDataMapper(
	key: string,
	dataSet: {
		[key: string]: any
	},
): any {
	const mapping: {
		[key: string]: string
	} = {
		SNC: "SNC_count",
		Compliant: "C_count",
		NC: "NC_count",
		RWR: "RWR_count",
		TNC: "TNC_count",
		NA: "NA_count",
		Quarrying: "quarrying_count",
		"River Crossings": "river_crossing_count",
		Afforestation: "afforestation_count",
		Earthworks: "earthworks_count",
		Harvesting: "harvesting_count",
	}

	return dataSet[mapping[key]]
}
export class RegionInfoView extends BlockFeatureInfoView {
	state = {
		allRegionMetrics: null,
		doughnutData: {
			labels: ["SNC", "Compliant", "NC", "RWR", "TNC", "NA"],
			datasets: [
				{
					data: [0, 0, 0, 0, 0, 0],
					backgroundColor: ["#ff0000", "#21ba45", "#ff8000", "#904EE3", "#ffff00", "#045ff6"],
					borderWidth: 0,
				},
			],
		},
		barData: {
			labels: ["Quarrying", "River Crossings", "Afforestation", "Earthworks", "Harvesting"],
			datasets: [
				{
					data: [0, 0, 0, 0, 0],
					backgroundColor: ["#31366A", "#3A5F94", "#478AB6", "#62B6D2", "#8AE2E6"],
				},
			],
		},
		isAuthoritiesLoaded: false,
	}

	updateCharts(regionStats: any) {
		const doughnutChartValues = this.state.doughnutData.labels.map((key) => chartDataMapper(key, regionStats))
		const barChartValues = this.state.barData.labels.map((key) => chartDataMapper(key, regionStats))

		this.setState({
			doughnutData: {
				...this.state.doughnutData,
				datasets: [
					{
						...this.state.doughnutData.datasets[0],
						data: doughnutChartValues,
					},
				],
			},

			barData: {
				...this.state.barData,
				datasets: [{ ...this.state.barData.datasets[0], data: barChartValues }],
			},
		})
	}

	componentDidMount() {
		requestLoad("dashboard-metrics", (type) => {
			const dataStore = getStoreTypeByName(type)
			if (!dataStore?.managedItemStorage) return

			const selectedRegionIso = this.props.selectedItem?.getRegionISO()
			if (selectedRegionIso) {
				this.updateCharts(dataStore.managedItemStorage[selectedRegionIso])
			}

			this.setState({ allRegionMetrics: dataStore.managedItemStorage })
		})

		requestLoadIfExist("authorities", undefined, () => {
			this.setState({ isAuthoritiesLoaded: true })
		})
	}

	componentDidUpdate(prevProps: ItemViewProps) {
		if (this.state.allRegionMetrics && prevProps?.selectedItem?.getRegionISO() !== this.props?.selectedItem?.getRegionISO()) {
			const selectedRegionIso = this.props.selectedItem?.getRegionISO()
			if (selectedRegionIso) {
				this.updateCharts(this.state.allRegionMetrics[selectedRegionIso])
			}
		}
	}

	render() {
		const { selectedItem: regionBlock } = this.props
		if (!regionBlock) return <></>

		const { authorityItem, english_name } = regionBlock! as any
		if (!this.state.isAuthoritiesLoaded || !authorityItem) return <></>

		return (
			<div id="region-block">
				<Segment className="council-logo" inverted basic>
					<Image src={process.env.PUBLIC_URL + (authorityItem.getValue("logo_url") as string).split("public")[1]} centered />
				</Segment>
				<Segment inverted basic>
					<Header as="h2" inverted>
						{english_name} Region
					</Header>
					<Divider />
					<div>
						{authorityItem.getValue("council_name")} administers all forestry activity in the {english_name} Region. Select a
						forestry block in the region to explore more details.
					</div>
				</Segment>
				<Segment inverted basic style={{ marginTop: "-40px" }}>
					<Doughnut data={this.state.doughnutData} options={doughnutOptions} />
					<Bar data={this.state.barData} options={barOptions} />
				</Segment>
				<Segment inverted basic className="region-info-links">
					<div className="link-wrapper">
						<span>Website:&nbsp;</span>
						<a target="_blank" rel="noopener noreferrer" href={authorityItem.getValue("website_url")}>
							{authorityItem.getValue("website_url")}
						</a>
					</div>
					<div className="link-wrapper">
						<span>Forestry Page:&nbsp;</span>
						<a target="_blank" rel="noopener noreferrer" href={authorityItem.getValue("website_forestry_page")}>
							{authorityItem.getValue("website_forestry_page")}
						</a>
					</div>
					<div className="link-wrapper">
						<span>Contact:&nbsp;</span>
						<a target="_blank" rel="noopener noreferrer" href={"mailto:" + authorityItem.getValue("email")}>
							{authorityItem.getValue("email")}
						</a>
					</div>
				</Segment>
			</div>
		)
	}
}
