import React, { useContext } from "react"
import { Image, Segment, Sidebar } from "semantic-ui-react"

import LayerGroupAccordion from "../../mapsight/LayerGroupAccordion"
import { call, getUIConfig } from "../../store"
import { MapReadyContext } from "../../App"

interface ILayersPanelProps {
	visible: boolean
}

const LayersPanel = ({ visible }: ILayersPanelProps) => {
	const { backgroundColor } = getUIConfig("defaultSidebar")
	const { logo } = getUIConfig("clientUI")
	const mapReady = useContext(MapReadyContext)
	return (
		<Sidebar id="layers-layer" animation="overlay" icon="labeled" width="wide" visible={visible} style={{ backgroundColor }}>
			<Segment inverted basic style={{ padding: "3em", paddingTop: "3em", paddingBottom: "2em" }}>
				<Image src={process.env.PUBLIC_URL + "/assets/" + logo} size="medium" />
			</Segment>
			<div style={{ backgroundColor: "#E1E1E1" }}>{mapReady && <LayerGroupAccordion location="layer-side-panel" />}</div>
		</Sidebar>
	)
}

export default LayersPanel
