export const Levels = { global: 0, country: 1, state: 2, region: 3, consent: 4, organisation: 5 }
export const LevelNames = {
	global: "World",
	country: "Country",
	state: "Island",
	region: "Region",
	consent: "Consent",
	organisation: "Company",
}

export interface IView {
	center?: Array<number>
	zoom?: number
	bearing?: number
	pitch?: number
}
export interface IAddress {
	street?: Array<string>
	suburb?: string
	town?: string
	postcode?: string
	country?: string
}
export interface IContacts {
	telephone?: string
	fax?: string
	email?: string
	url?: string
}
export interface IReportConfig {
	blocks?: Array<string>
	disclaimer?: Array<string>
}

export interface ILevelData {
	idISO?: string
	label: string
	view?: IView
	bounds?: any
	logo?: string
	url?: string
	address?: IAddress
	contact?: IContacts
	reportConfig?: IReportConfig
}

export const reportBlocks = [
	"coverPage",
	"forestDetails",
	"description",
	"AOESection",
	"maps",
	"tasks",
	"skids",
	"mapKey",
	"legal",
	"footer",
]
