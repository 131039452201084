import React, { Component } from "react"

import { Container, Item, Header } from "semantic-ui-react"

import { FormPageItemProps } from "../index"

import { PDFPage } from "./PDFPage"

import { MultipageForm } from "../MultiPageForm"

// export interface MultipageFormComponentProps {
// 	item: storedItem | null;
// 	title: string;
// 	container: ItemContext;
// 	pages: FormPageProps[];
// 	onSubmit?: any;
// 	onCancel?: any;
// 	displayMode?: boolean;
// 	setDirty?: Function;
// }

export class MultipagePDFForm extends MultipageForm {
	state = {
		step: 0,
	}
	nextPage = () => {
		this.goToPage(this.state.step + 1)
		// if (this.state.step < this.props.pages.length) {
		// 	this.setState({ step: this.state.step + 1 });
		// }
	}
	CancelFlow = () => {
		this.props.onCancel()
	}
	previousPage = () => {
		this.goToPage(this.state.step - 1)
		// if (this.state.step > 0) {
		// 	this.setState({ step: this.state.step - 1 });
		// }
	}

	goToPage = (page: number) => {
		if (page >= 0 && page < this.props.pages.length) {
			this.setState({ step: page })
		}
	}

	renderPage = (page: FormPageItemProps, index: number) => {
		return (
			<PDFPage
				item={this.props.item}
				title={page.title}
				fields={page.fields}
				container={this.props.container}
				pages={this.props.pages.length}
				page={page}
				index={index}
				active={this.state.step === index}
				onBack={this.previousPage}
				onNext={this.nextPage}
				onCancel={this.CancelFlow}
				onSubmit={this.props.onSubmit}
				setDirty={this.props.setDirty}
				displayMode={this.props.displayMode}
			></PDFPage>
		)
	}

	renderPages = () => {
		return this.props.pages.map((page: any, idx: any) => {
			return this.renderPage(page, idx)
		})
	}

	render() {
		return <>{this.renderPages()}</>
	}
}
