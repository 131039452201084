import { getStoreTypeByName, requestLoad } from ".."
import { printData, printMessage } from "../../providers/remoteHQ"
import { storedItem } from "../../store/storedItem"
import { actionNameToNumber, actionNames, messageLevels, objectNameToNumber } from "../types"
import { mapLayer } from "./mapLayer"

export class appMode extends storedItem {
	disableMode() {
		const layers = this.getValue("layers")
		const mapLayersType = getStoreTypeByName("map-layers")

		if (layers) {
			for (const [key, value] of Object.entries(layers)) {
				const theLayer = mapLayersType?.itemByKey(key)

				if (theLayer && value === true) {
					;(theLayer as mapLayer).setVisibility(false)
				}
			}
		}
	}

	setupMode() {
		const dataRequests = this.getValue("data_requests")
		const layers = this.getValue("layers")
		const mapLayersType = getStoreTypeByName("map-layers")
		if (dataRequests) {
			dataRequests.forEach((type: string) => {
				requestLoad(type)
			})
		}

		if (layers) {
			// Temporary solution for highlighting regional layers
			layers["regional-boundaries"] = true
			layers["regional-boundaries-light-line"] = true
			for (const [key, value] of Object.entries(layers)) {
				const theLayer = mapLayersType?.itemByKey(key)
				if (theLayer) {
					;(theLayer as mapLayer).setVisibility(value === true)
				}
			}
		}
	}
}
